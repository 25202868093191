import { ClientCustomVoucherInfo } from '../../../shared/models/client/client-custom-voucher-info.model';
import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { IClientModel } from '@dida-shopping/dida-services/membership';

@Injectable()
export class ClientService {

    constructor(private _httpClient: HttpClient) { }

    getUserClientInfo(isReload: boolean) {
        return this._httpClient.post<IClientModel>('/ClientApi/GetUserClientInfo', {
            fromCache: !isReload
        });
    }

    getCustomVoucherInfo() {
        return this._httpClient.get<ClientCustomVoucherInfo>('/ClientApi/GetCustomVoucherInfo');
    }

    updateCustomVoucherInfo(info: ClientCustomVoucherInfo) {
        return this._httpClient.post('/ClientApi/UpdateCustomVoucherInfo', { info });
    }
}
