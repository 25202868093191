import { HotelItemInfo, CustomableHotelItemInfo } from '../../promotion.models';
import { ImageLinkModel } from '../../../shared/models/shared-models';

export const DEFAULT_ACTIVITY_CONFIG: Partial<ActivityConfigModel> = {
  daySpan: 7,
  // dayOffset: 30,
  expired: '2018-09-31',

  bgColor: 'beige',
  bgPrice: '#ad1bea',
  bgOrder: '#fe5f59',
  banner: '',
  showPromotion: false,
  widgets: [],
  navBgColor: '#ad1bea',
  navShadow: '0 2px 20px 6px rgba(168, 33, 28, 0.4)',
  navColor: 'white',
  ruleColor: 'reset',
  customInfoDict: {},
};

export class HotelItemConfigModel {
  alias: string;
  bgPrice: string;
  bgOrder: string;
  orderColor: string;
  nameColor: string;
  priceColor: string;
  bgColor: string;
  withPoster: boolean;
  editing: boolean;
  hideQrcode: boolean;
}

export class BannerModel extends ImageLinkModel {
  BannerType: string;
  DefaultReferenceUrl: string;
}

export class ActivityConfigModel extends HotelItemConfigModel {
  banner: string;
  banner_EN: string;
  // dayOffset: number;
  daySpan: number;
  startDate: string;
  expired: string;
  navBgColor: string;
  navColor: string;
  navShadow: string;
  navTopImg: string;
  navTitle: string;
  navTitle_EN: string;
  ruleColor: string;
  rules: string[];
  rules_EN: string[];
  rulesMD: string;
  rulesMD_EN: string;
  showPromotion: boolean;
  title: string;
  title_EN: string;
  widgets: any[];
  regionNameColor: string;
  regionHeaderImg: string;
  customInfoDict: { [key: string]: CustomableHotelItemInfo };
  // 指定报价币种
  currency: string;
}

export class ActivityRegionModel {
  name: string;
  name_EN: string;
  nameColor: string;
  desc: string;
  headerImg: string;
  hotelIdList: number[];
  customInfoList: CustomableHotelItemInfo[];
}
