import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { Angulartics2Module } from 'angulartics2';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { HotelSearchContextService } from './core/services/hotel/hotel-search-context.service';
import { RateSearchContextService } from './core/services/rates/rate-search-context.service';
import { JdmarketService } from './core/services/pointsmarket/jdmarket.service';

import {
  NgxDidaSiteCoreModule,
  Angulartics2DidaBaiduAnalyticsProvider,
  Angulartics2DidaGoogleAnalyticsProvider
} from '@dida-shopping/ngx-dida-site-core';

import { DidaUITKDebugLoginModule } from '@dida-shopping/ngx-dida-uitk/debug-login';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular & 3rd Parties Modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    Angulartics2Module.forRoot(
      {
        pageTracking: {
          clearIds: true,
          clearQueryParams: true,
          idsRegExp: new RegExp('^\\d+$|^(DHB|STM)\\d+$', 'i')
        }
      }
    ),
    // Routing
    AppRoutingModule,

    // Dida Modules
    CoreModule,
    SharedModule,

    // NgxDidaSiteCore
    NgxDidaSiteCoreModule.forRoot({
      appName: 'main-app',defaultPath: '/'
    }),

    // NgxDidaUITK -- DEBUG LOGIN
    // (required if this app does not have login entrance)
    DidaUITKDebugLoginModule,
  ],
  providers: [
    HotelSearchContextService,
    RateSearchContextService,
    Angulartics2DidaBaiduAnalyticsProvider,
    Angulartics2DidaGoogleAnalyticsProvider,
    JdmarketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
