export enum Icons {
  address = 'nd:address',
  address2 = 'nd:address2',
  applets = 'nd:applets',
  area = 'nd:area',
  arrowTop = 'nd:arrow-top',
  ban = 'nd:ban',
  bill = 'nd:bill',
  bill_active = 'nd:bill_active',
  businessDistrict = 'nd:business-district',
  chinese = 'nd:chinese',
  clean = 'nd:clean',
  close = 'nd:close',
  close_active = 'nd:close_active',
  close_inherit = 'nd:close_inherit',
  close2 = 'nd:close2',
  close2_active = 'nd:close2_active',
  collect = 'nd:collect',
  collect_active = 'nd:collect_active',
  collect1 = 'nd:collect1',
  collect2 = 'nd:collect2',
  collect2_active = 'nd:collect2_active',
  collect3 = 'nd:collect3',
  collect3_active = 'nd:collect3_active',
  collect4 = 'nd:collect4',
  commonProblem = 'nd:common-problem',
  cut = 'nd:cut',
  cut_active = 'nd:cut_active',
  cut_disabled = 'nd:cut_disabled',
  data = 'nd:data',
  data_active = 'nd:data_active',
  data2 = 'nd:data2',
  data2_active = 'nd:data2_active',
  details = 'nd:details',
  directSign = 'nd:direct-sign',
  distance = 'nd:distance',
  emptyResult = 'nd:empty-result',
  english = 'nd:english',
  expandCollapse = 'nd:expand-collapse',
  expandCollapse_active = 'nd:expand-collapse_active',
  failure = 'nd:failure',
  failure1 = 'nd:failure1',
  feedback = 'nd:feedback',
  feedback_active = 'nd:feedback_active',
  feedback2 = 'nd:feedback2',
  feedback2_active = 'nd:feedback2_active',
  fire = 'nd:fire',
  fire2 = 'nd:fire2',
  fitness = 'nd:fitness',
  floor = 'nd:floor',
  freeze = 'nd:freeze',
  frown = 'nd:frown',
  historyNext_active = 'nd:history-next_active',
  historyNext_default = 'nd:history-next_default',
  hotelBrandBackground = 'nd:hotel-brand-background',
  hotel = 'nd:hotel',
  immediate = 'nd:immediate',
  immediate1 = 'nd:immediate1',
  increase = 'nd:increase',
  increase_active = 'nd:increase_active',
  increase_disabled = 'nd:increase_disabled',
  install = 'nd:install',
  install_active = 'nd:install_active',
  invoice = 'nd:invoice',
  invoice_active = 'nd:invoice_active',
  japanese = 'nd:japanese',
  location = 'nd:location',
  location_active = 'nd:location_active',
  location1 = 'nd:location1',
  location1_active = 'nd:location1_active',
  location10 = 'nd:location10',
  location10_active = 'nd:location10_active',
  location2 = 'nd:location2',
  location2_active = 'nd:location2_active',
  location3 = 'nd:location3',
  location3_active = 'nd:location3_active',
  location4 = 'nd:location4',
  location4_active = 'nd:location4_active',
  location5 = 'nd:location5',
  location5_active = 'nd:location5_active',
  location6 = 'nd:location6',
  location6_active = 'nd:location6_active',
  location7 = 'nd:location7',
  location7_active = 'nd:location7_active',
  location8 = 'nd:location8',
  location8_active = 'nd:location8_active',
  location9 = 'nd:location9',
  location9_active = 'nd:location9_active',
  locking = 'nd:locking',
  loudspeaker = 'nd:loudspeaker',
  mechanism = 'nd:mechanism',
  mechanism_active = 'nd:mechanism_active',
  meetingRoom = 'nd:meeting-room',
  news = 'nd:news',
  news_active = 'nd:news_active',
  news1 = 'nd:news1',
  news2 = 'nd:news2',
  news2_active = 'nd:news2_active',
  news3 = 'nd:news3',
  news3_active = 'nd:news3_active',
  news4 = 'nd:news4',
  news4_active = 'nd:news4_active',
  noSmoking = 'nd:no-smoking',
  number = 'nd:number',
  order = 'nd:order',
  order_active = 'nd:order_active',
  parking = 'nd:parking',
  pause = 'nd:pause',
  periphery = 'nd:periphery',
  personalCenter = 'nd:personal-center',
  personalCenter_active = 'nd:personal-center_active',
  phone = 'nd:phone',
  phone_active = 'nd:phone_active',
  phone1 = 'nd:phone1',
  phone2 = 'nd:phone2',
  phone2_active = 'nd:phone2_active',
  phone3 = 'nd:phone3',
  play = 'nd:play',
  positioning = 'nd:positioning',
  processing = 'nd:processing',
  prompt = 'nd:prompt',
  qq = 'nd:qq',
  qq_active = 'nd:qq_active',
  qq2 = 'nd:qq2',
  qq2_active = 'nd:qq2_active',
  qrcode = 'nd:qrcode',
  quit = 'nd:quit',
  quit_active = 'nd:quit_active',
  remind = 'nd:remind',
  restaurant = 'nd:restaurant',
  restaurant2 = 'nd:restaurant2',
  share = 'nd:share',
  shopping = 'nd:shopping',
  shuttle = 'nd:shuttle',
  sights = 'nd:sights',
  sights2 = 'nd:sights2',
  smile = 'nd:smile',
  success = 'nd:success',
  success1 = 'nd:success1',
  swimmingPool = 'nd:swimming-pool',
  timeLimit = 'nd:time-limit',
  timeLimit1 = 'nd:time-limit1',
  tool = 'nd:tool',
  tool_active = 'nd:tool_active',
  tool2 = 'nd:tool2',
  tool2_active = 'nd:tool2_active',
  top = 'nd:top',
  top_active = 'nd:top_active',
  top2 = 'nd:top2',
  top2_active = 'nd:top2_active',
  traffic = 'nd:traffic',
  upCircle = 'nd:up-circle',
  url = 'nd:url',
  wechat = 'nd:wechat',
  wifi = 'nd:wifi',
  wifi2 = 'nd:wifi2',
  window = 'nd:window',
  workOrder = 'nd:work-order',
  workOrder_active = 'nd:work-order_active',
}

export enum IconStatuses {
  active = 'active',
  inherit = 'inherit',
  disabled = 'disabled',
  default = 'default',
}