<label [attr.for]="inputId" class="dida-checkbox" [class.dida-checkbox_disabled]="disabled">
    <div class="dida-checkbox__wrapper"  >
        <!-- 这是个隐藏的checkbox -->
        <input class="dida-checkbox__input" type="checkbox" [id]="inputId" [required]="required" [checked]="checked"
            [attr.value]="value" [disabled]="disabled" [attr.name]="name" [indeterminate]="indeterminate" (change)="_onInteractionEvent($event)"
            (click)="_onInputClick($event)" #input>
        <!-- 通过图标来展示checkbox状态 -->
        <div class="icon icon-checkbox checkbox__icon" #checkboxIcon></div>
    </div>
    <div class="dida-checkbox__text-container">
        <span class="dida-checkbox__label" #checkboxLabel>
            <span style="display:none">&nbsp;</span>
            <ng-content></ng-content>
        </span>
        <div class="dida-checkbox__hint-wrapper" *ngIf="_hints&&_hints.length">
            <ng-content select="dida-form-hint"></ng-content>
        </div>
    </div>
</label>