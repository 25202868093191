import { DidaUITKEventBase } from './uitk-event.base';

class DidaUITKDocumentEvent<Event extends UIEvent> extends DidaUITKEventBase<Event> {
  constructor(eventName: string
  ) {
    super('document', eventName, document);
  }
}


export const DocumentMouseMoveEvent = new DidaUITKDocumentEvent<MouseEvent>('mousemove');

