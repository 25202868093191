<div class="side-bar-container">
  <div class="container" id="side_bar_container">
    <div class="side-bar pull-right">
      <ul class="list-group">
        <li
          class="list-group-item icon-wrapper highlight"
          *ngIf="isLogin"
          [popover]="messageTemplate"
          #messagePopover="bs-popover"
          triggers="click"
          placement="left"
          outsideClick="true"
          container="body"
          containerClass="sidebar-popover"
          i18n-popoverTitle="@@Label_MessageCenter"
          popoverTitle="消息中心"
          i18n-title="@@Label_MessageCenter"
          title="消息中心"
        >
          <div class="icon icon-sidebar icon-sidebar_message animated" [class.swing]="unreadMessageList.length"></div>
          <app-badge
            class="message-badge"
            mode="round"
            [value]="unreadMessageList.length"
            *ngIf="!messagePopover.isOpen"
          >
          </app-badge>
        </li>
        <li
          class="list-group-item icon-wrapper highlight"
          *ngIf="isLogin"
          title="酒店收藏"
          i18n-title="@@Text_SideNav_FavoriteHotel"
          #favoritePopover="bs-popover"
          [popover]="favoriteHotelTemplate"
          popoverTitle="酒店收藏"
          i18n-popoverTitle="@@Text_SideNav_FavoriteHotel"
          triggers="click"
          placement="left"
          outsideClick="true"
          container="body"
          containerClass="sidebar-popover"
        >
          <div class="icon icon-sidebar icon-sidebar_favorite" #favorIcon></div>
          <div style="position: absolute; top: 12px; left: 14px; z-index: 100000">
            <div style="opacity: 0" #shadowFavorIcon class="icon icon-favorite active"></div>
          </div>
        </li>
        <!-- <li
          class="list-group-item icon-wrapper highlight"
          *ngIf="customServiceSetting"
          [title]="customServiceSetting.Title"
          [id]="customServiceSetting.Reference"
        >
          <a
            href="javascript:;"
            (click)="callCustomerService($event)"
            target="_blank"
            class="icon icon-sidebar icon-sidebar_online"
          >
          </a>
        </li> -->
        <!-- <li class="list-group-item icon-wrapper highlight" title="问卷调查" i18n-title="@@Text_SideNav_Survey"
                    *ngIf="isLogin &&
                    showSurveyEntrance">
                    <a href="https://www.wenjuan.com/s/VZneU3/" target="_blank"
                        class="icon icon-sidebar icon-sidebar_survey">
                    </a>
                    <app-feature-popover [config]="{identity:'SB_Srvy2018',
                        expired: '2018-10-07'}" i18n-title="@@Title_FillingSurveyGainPointsRewards"
                        title="填问卷，免费得积分！！！" i18n-content="@@Text_FillingSurveyGainPointsRewards_Description"
                        content="为了更好地为您提供服务，我们特意设计了这一问卷，希望您能拨冗参与，稍后我们会送上相应积分以表感谢~">
                    </app-feature-popover>
                </li> -->
        <!-- <li
          class="list-group-item icon-wrapper highlight"
          title="客服中心"
          i18n-title="@@Text_SideNav_CustomerCenter"
          #customerCenterPopover="bs-popover"
          [popover]="customerCenterTemplate"
          containerClass="call-center-popover"
          triggers="click"
          placement="left"
          outsideClick="true"
          container="body"
        >
          <div class="icon icon-sidebar icon-sidebar_telephone"></div>
        </li> -->
        <li
          class="list-group-item icon-wrapper highlight"
          title="建议反馈"
          i18n-title="@@Text_SideNav_Feedback"
          (click)="feedbackModal.show()"
        >
          <div class="icon icon-sidebar icon-sidebar_typing"></div>
          <!-- <app-feature-popover [config]="{identity:'SB_FiBk', expired: '2018-10-07'}"
                            i18n-title title="~~我们上线建议反馈啦~~！您对旅赞网站的任何建议需求，都可通过这里告诉我们，来来来，小窗戳起来~~！ "></app-feature-popover> -->
        </li>
        <li
          class="list-group-item icon-wrapper highlight"
          *ngIf="false"
          title="常见问题"
          i18n-title="@@Text_SideNav_FAQ"
        >
          <div class="icon icon-sidebar icon-sidebar_faq"></div>
        </li>
        <li class="list-group-item icon-wrapper highlight" title="回到顶部" i18n-title="@@Text_SideNav_BackToTop">
          <a href="javascript:;" (click)="scrollToTop()" class="icon icon-sidebar icon-sidebar_arrowup"> </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #messageTemplate>
  <div class="message-container scroll-box">
    <ul>
      <li class="message-item" *ngFor="let message of unreadMessageList">
        <a [routerLink]="['/message', message.Category, message.MessageID]" (click)="messagePopover.hide()">
          <label class="label label-highlight" for="">
            <app-message-category-type [type]="message.Category" [ticketOperation]="getTicketOperation(message)">
            </app-message-category-type>
          </label>
          <span class="message-content">
            <app-message-title [message]="message"> </app-message-title>
          </span>
        </a>
      </li>
    </ul>
    <app-not-found *ngIf="unreadMessageList.length < 1">
      <p i18n="@@Text_Notice_NoNewMessage">暂无新消息</p>
    </app-not-found>
  </div>
  <div class="popover-footer">
    <a routerLink="/message" class="btn btn-link" i18n="@@Text_ViewMore"> 查看更多 </a>
    <a
      href="javascript:;"
      class="btn btn-link"
      (click)="markAllMessageAsReaded()"
      *ngIf="unreadMessageList.length > 0"
      i18n="@@Text_MarkAllAsRead_WithAmount"
    >
      全部标记已读（{{ unreadMessageList.length }}条）
    </a>
  </div>
</ng-template>
<ng-template #favoriteHotelTemplate>
  <section class="favorite-hotel-container scroll-box" scrollable>
    <app-not-found *ngIf="favoriteHotelList.length < 1">
      <p i18n="@@Text_Notice_NoFavoriteHotel">您还没收藏任何酒店哦!</p>
    </app-not-found>
    <ul>
      <li class="hotel-item" *ngFor="let hotel of favoriteHotelList">
        <a href="javascript:;" class="icon-dismiss-wrapper pull-right" (click)="removeFavoriteHotel(hotel)">
          <i class="icon" [ngClass]="hotel.processing ? 'icon-loading' : 'icon-dismiss'"></i>
        </a>
        <a [routerLink]="['/hotel/detail', hotel.Hotel.HotelID]" (click)="favoritePopover.hide()">
          <div
            class="hotel-name link-inner text-ellipsis"
            *ngIf="hotel.Hotel.Name_EN !== hotel.Hotel.Name_CN"
            [title]="hotel.Hotel.Name"
          >
            {{ hotel.Hotel.Name }}
          </div>
          <div
            class="hotel-name link-inner text-ellipsis"
            *ngIf="hotel.Hotel.Name_EN === hotel.Hotel.Name_CN"
            [title]="hotel.Hotel.Name"
          >
            <ng-container *ngIf="hotel.Hotel.Name_EN">{{ hotel.Hotel.Name_EN }}</ng-container>
            <ng-container *ngIf="!hotel.Hotel.Name_EN">{{ hotel.Hotel.Name }}</ng-container>
          </div>
          <div class="hotel-dest link-inner text-ellipsis" [title]="hotel.Hotel.DestinationName">
            {{ hotel.Hotel.DestinationName }}
          </div>
        </a>
      </li>
    </ul>
  </section>
</ng-template>
<ng-template #customerCenterTemplate>
  <div class="call-center-container">
    <p class="title" i18n="@@Title_SideNav_CustomerCenter">客服中心（7*24小时）</p>
    <ul>
      <!-- <li>
            <span class="title" i18n="@@Title_SideNav_CustomerCenter"> 客服中心（7*24小时）</span>
        </li> -->
      <li>
        <span>QQ：</span>
        <a href="http://wpa.b.qq.com/cgi/wpa.php?ln=2&uin=4008006055" target="_blank" class="h5"> 4008006055 </a>
      </li>
      <li class="text-muted" *ngIf="isCN">
        <p i18n="@@Notice_CustomerTelephonePrioritize">00:00-8:00优先处理紧急到店问题</p>
        <p>
          <span i18n="@@Notice_PreSaleServiceTime" class="colon">售前服务时段</span>
          <span>9:00-21:00</span>
          <span i18n="@@Label_BeijingTime" class="parenthesis">北京时间</span>
        </p>
        <p>
          <span i18n="@@Notice_ComplaintServiceTime" class="colon">投诉处理时段</span>
          <span>9:00-18:00</span>
          <span i18n="@@Label_BeijingTime" class="parenthesis">北京时间</span>
        </p>
      </li>
      <li>
        <label i18n="@@Label_HotelRelBizService">酒店业务</label>
        <ng-container *ngIf="isCN">
          <p>
            <span class="text-muted colon" i18n="@@Label_MainlandChinaCalls">中国大陆拨打</span>
            <a class="h5" href="tel:0755-82628520">0755-82628520</a>
          </p>
          <p>
            <span class="text-muted colon" i18n="@@Label_NonMainlandChinaCalls">非中国大陆拨打</span>
            <a class="h5" href="tel:+86-755-82628520"> +86-755-82628520 </a>
          </p>
          <p>
            <span class="text-muted colon" i18n="@@Label_PostSale">国内售后邮箱</span>
            <a class="h5" href="mailto:mktservice@didatravel.com"> mktservice@didatravel.com </a>
          </p>
          <p>
            <span class="text-muted colon" i18n="@@Text_Label_DomesticComplaintEmail">国内投诉邮箱</span>
            <a class="h5" href="mailto:complaints.mktservice@didatravel.com"> complaints.mktservice@didatravel.com </a>
          </p>
        </ng-container>
        <ng-container *ngIf="!isCN">
          <p>
            <span class="text-muted colon" i18n="@@Label_MainlandChinaCalls">中国大陆拨打</span>
            <a class="h5" href="tel:021-60555598">021-60555598</a>
          </p>
          <p>
            <span class="text-muted colon" i18n="@@Label_NonMainlandChinaCalls">非中国大陆拨打</span>
            <a class="h5" href="tel:+86-21-60555598">+86-21-60555598</a>
          </p>
          <p>
            <span class="text-muted colon" i18n="@@Label_Overseas">海外客户</span>
            <a class="h5" href="mailto:intlservice@didatravel.com">intlservice@didatravel.com</a>
          </p>
        </ng-container>
      </li>
      <li *didaExperiment="ExperimentControls.DidaTransferEntranceFeatureControl_Temp">
        <label i18n="@@Text_Label_TransferService"> 接送机业务 </label>
        <p>
          <span class="text-muted colon" i18n="@@Label_Phone">电话</span>
          <a class="h5" href="tel:021-60663508"> 021-60663508 </a>
        </p>
        <p>
          <span class="text-muted colon" i18n="@@Label_Email">邮箱</span>
          <a class="h5" href="mailto:trservice@didatravel.com"> trservice@didatravel.com </a>
        </p>
      </li>
      <li *ngIf="srInfo">
        <label>
          <span i18n="@@Label_SaleManager"> 专属销售经理 </span>
          <strong class="parenthesis">{{ srInfo.UserName }}</strong>
        </label>
        <div *ngIf="srInfo.Cellphone">
          <span class="text-muted colon" i18n="@@Label_Phone">电话</span>

          <a class="h5" href="tel:{{ srInfo.Cellphone }}">
            {{ srInfo.Cellphone }}
          </a>
        </div>
        <div *ngIf="srInfo.Email">
          <span class="text-muted colon" i18n="@@Label_Email">邮箱</span>

          <a class="h5" href="mailto:{{ srInfo.Email }}">
            {{ srInfo.Email }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
<div bsModal #feedbackModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog feedback-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title">
          <ng-container i18n="@@Text_Title_FeedbackModal">建议反馈</ng-container>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="feedbackModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form
        class="modal-body"
        #feedbackForm="ngForm"
        *ngIf="ProcessStepType.Success !== ticketProcessStep"
        (submit)="submitFeedbackTicket(feedbackForm)"
      >
        <div class="form-group">
          <label for="" class="control-label" i18n="@@Label_FeedbackType">反馈类型</label>
          <nd-tag-group
            class="feedback-type"
            [required]="true"
            ndSize="large"
            [ndCheckable]="true"
            name="Type"
            #feedbackType="ngModel"
            [(ngModel)]="feedbackTicket.Type"
          >
            <nd-tag [ndValue]="FeedbackType.Encourage">
              <i class="icon icon-emoji icon-emoji_smile feedback-type__icon"></i>
              <ng-container i18n="@@Text_FeebackType_Encourage">给旅赞好评点赞</ng-container>
            </nd-tag>
            <nd-tag [ndValue]="FeedbackType.Complain">
              <i class="icon icon-emoji icon-emoji_sad feedback-type__icon"></i>
              <ng-container i18n="@@Text_FeebackType_Complain">我要吐槽给差评</ng-container>
            </nd-tag>
            <nd-tag [ndValue]="FeedbackType.NewFearture">
              <ng-container i18n="@@Text_FeebackType_NewFearture">我要提功能需求</ng-container>
            </nd-tag>
            <nd-tag [ndValue]="FeedbackType.Resource" *ngIf="IsHotelDetail">
              <ng-container i18n="@@Text_FeebackType_Resource">酒店的信息出错/缺失了</ng-container>
            </nd-tag>
            <nd-tag [ndValue]="FeedbackType.Others">
              <ng-container i18n="@@Text_FeebackType_Others">其他</ng-container>
            </nd-tag>
          </nd-tag-group>
        </div>
        <div class="animated" *ngIf="feedbackTicket.Type">
          <ng-container *ngIf="FeedbackType.Resource === feedbackTicket.Type">
            <div class="form-group">
              <label class="control-label" i18n="@@Label_FeedbackSubtype">哪里错了</label>
              <dida-select
                name="SubType"
                required
                #feedbackSubtype="ngModel"
                [(ngModel)]="feedbackTicket.SubType"
                [container]="''"
                i18n-placeholder="@@Text_Placeholder_FeedbackType"
                placeholder="告诉旅赞，酒店哪个信息出错了"
              >
                <dida-select-option [value]="FeedbackSubType.HotelNameError">
                  <ng-container i18n="@@Text_FeedbackSubtype_HotelNameError">酒店中/英文名称错误</ng-container>
                </dida-select-option>
                <dida-select-option [value]="FeedbackSubType.RateStarError">
                  <ng-container i18n="@@Text_FeedbackSubtype_RateStarError">星级错误</ng-container>
                </dida-select-option>
                <dida-select-option [value]="FeedbackSubType.AddressError">
                  <ng-container i18n="@@Text_FeedbackSubtype_AddressError">地址信息错误</ng-container>
                </dida-select-option>
                <dida-select-option [value]="FeedbackSubType.TelephoneError">
                  <ng-container i18n="@@Text_FeedbackSubtype_TelephoneError">酒店电话错误</ng-container>
                </dida-select-option>
                <dida-select-option [value]="FeedbackSubType.WebsiteError">
                  <ng-container i18n="@@Text_FeedbackSubtype_WebsiteError">酒店官网错误</ng-container>
                </dida-select-option>
                <dida-select-option [value]="FeedbackSubType.Others">
                  <ng-container i18n="@@Text_FeedbackSubtype_Others">其他</ng-container>
                </dida-select-option>
              </dida-select>
              <div
                class="form-control-error"
                *ngIf="(feedbackForm.submitted || feedbackSubtype.touched) && feedbackSubtype.errors"
                i18n="@@Text_Error_FeedbackSubtypeRequired"
              >
                请告诉我们哪个信息出错了~
              </div>
            </div>

            <div class="form-group">
              <label for="" class="control-label">
                <ng-container i18n="@@Label_Feedback_ShouldModify2">应修改为</ng-container>
                <dida-label [type]="'success'" [bold]="false">
                  <ng-container i18n="选填@@Label_Optional">选填</ng-container>
                </dida-label>
              </label>

              <p></p>
              <textarea
                name="Content"
                cols="30"
                rows="4"
                class="form-control"
                [maxlength]="500"
                i18n-placeholder="@@Text_TheCorrectInfoIs"
                placeholder="正确的是..."
                [(ngModel)]="feedbackTicket.Content"
              >
              </textarea>
            </div>
          </ng-container>
          <div class="form-group" *ngIf="FeedbackType.Resource !== feedbackTicket.Type">
            <label for="" class="control-label" i18n="@@Label_Feedback_MyOpinion">我想说</label>
            <p></p>
            <textarea
              class="form-control"
              name="Content"
              id=""
              cols="30"
              rows="4"
              [required]="isFeedbackContentRequired"
              [maxlength]="500"
              [(ngModel)]="feedbackTicket.Content"
              #feedbackContent="ngModel"
              i18n-placeholder="@@Text_Placeholder_FeedbackContent"
              placeholder="请填写具体的建议反馈吧~"
            >
            </textarea>
            <div
              class="form-control-error"
              *ngIf="(feedbackForm.submitted || feedbackContent.touched) && feedbackContent.errors"
              i18n="@@Text_Error_FeedbackContentRequired"
            >
              这里要填写哦~~
            </div>
          </div>
          <div class="form-group">
            <label for="" class="control-label">
              <ng-container i18n="@@Label_Email">邮箱</ng-container>
              <dida-label [type]="'success'" [bold]="false">
                <ng-container i18n="选填@@Label_Optional">选填</ng-container>
              </dida-label>
            </label>
            <input
              name="UserEmail"
              [(ngModel)]="feedbackTicket.UserEmail"
              class="form-control"
              type="email"
              maxlength="255"
              i18n-placeholder="@@Text_Placeholder_FeedbackEmail"
              placeholder="请填写联系邮箱，仅是为了同步反馈处理进度给您"
            />
          </div>
          <div class="text-right">
            <button class="btn btn-primary" disabled *ngIf="ProcessStepType.Processing === ticketProcessStep">
              <ng-container i18n="@@Label_Submitting">提交中...</ng-container>
            </button>
            <button
              class="btn btn-primary"
              [disabled]="feedbackForm.submitted && feedbackForm.invalid"
              *ngIf="ProcessStepType.Processing !== ticketProcessStep"
            >
              <ng-container i18n="@@Label_Submit">提交</ng-container>
            </button>
            <div class="form-control-error" *ngIf="feedbackTicketError">{{ feedbackTicketError }}</div>
          </div>
        </div>
      </form>
      <div class="modal-body" *ngIf="ProcessStepType.Success === ticketProcessStep">
        <app-alert type="success" [inline]="true">
          <ng-container i18n="@@Text_Title_FeedbackSuccess">您的建议已提交，感谢！</ng-container>
        </app-alert>
        <p i18n="@@Text_Desc_FeedbackSuccess">
          我们可能无法回复您的每一条建议，但您的建议定将帮助我们逐步完善平台网站，祝您生活愉快！
        </p>
        <div class="text-right">
          <button class="btn btn-primary" (click)="feedbackModal.hide(); resetFeedbackTicket()">
            <ng-container i18n="@@Label_Confirm">确定</ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
