import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination'



@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [
    CommonModule,
    NzPaginationModule
  ]
})
export class DidaUITKPaginationModule { }
