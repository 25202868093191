import { GeoCoordinate } from './geo-coordinate.model';
import { GeographyService } from './geography.service';

export interface IGeoBoundary2D {
  center: GeoCoordinate;
  northeast: GeoCoordinate;
  southwest: GeoCoordinate;
}

export class GeoBoundary2D implements IGeoBoundary2D {

  get center(): GeoCoordinate {
    return this._center;
  }

  get northeast(): GeoCoordinate {
    return this._ne;
  }
  get southwest(): GeoCoordinate {
    return this._sw;
  }

  constructor(
    private _ne: GeoCoordinate,
    private _sw: GeoCoordinate,
    private readonly _center: GeoCoordinate = null,
  ) {
    if (!_center) {
      this._center = GeographyService.calculateMidPos(_ne, _sw);
    }
  }
}
