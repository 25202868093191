export enum ExperimentID {
  ForbidDirectExportOrder = -815,
  ForbidSaleToOTA = -809,
  ForbidWorkOrder = -807,
  ForbidCancelBooking = -806,
  ForbidBookingExport = -805,
  ForbidBookingView = -804,
  ForbidOrder = -803,
  ForbidPriceSearch = -802,
  ForbidHotelView = -801,
  ForbidInsurance = -705,
  ForbidSettlementPayChannel_BaoFoo = -703,
  ForbidSettlementPayChannel_Alipay = -702,
  ForbidBookingSettlement = -701,
  DidaShoppingV1View = 20,
  MaximizeRoomCount = 33,
  ForbidUserPoints = 42,
  UserBookingNoRestriction = 60,
  HideInvoiceApply = 71,
  BookingLowerPriceNotice = 101,
  PostPayBookingAutoCancelNotice = 102,
  // DidaActivity_5thAnniversary = 201,
  DidaActivity_NewBeginning2018 = 203,
  DidaActivity_OkinawaSakura = 204,
  PaymentChannel_BaoFoo_Forbidden = 302,
  PaymentChannel_Balance_Forbidden = 304,
  PaymentChannel_Alipay_Forbidden = 306,
  PaymentChannel_Oceanpayment = 307,
  PaymentChannel_Oceanpayment_Forbidden = 308,

  PaymentChannel_DeferredPayment = 310,
  PaymentChannel_DeferredPayment_Forbidden = 311,

  PaymentChannel_WeChat = 312,
  PaymentChannel_WeChat_Forbidden = 313,

  // ShowAccountBindingInfo = 401,
  // AllowUserModifyBindingInfo = 402,
  // AllowDisplayExternalLinks = 403,
  BillTracker = 501,
  // AllowRootAccountModifyClientInfo = 601,
  // ForbidRootAccountModifyClientInfo = 602,
  AllowInsurance = 705,
  AllowBalanceDetail = 706,

  //
  // RolledOutExperiments
  //
  DidaShoppingV3View = 21,
  DidaShoppingV3Inner = 22,
  HotSellAndPromotions = 31,
  SameDayBooking = 35,
  SpecialRequest = 36,
  GuestNationaliy = 37,
  BookingGroupFeature = 38,
  AdvancedMealInfoDisplay = 39,
  AllowUserPoints = 41,
  HotelKeywordSearch = 55,
  SuggestionTypeahead = 56,
  InvoiceApply = 70,
  RoomGradeCategorization = 80,

  // 允许查看RatePlanName
  /**
   * @deprecated 已rollout
   */
  DidaShoppingMapV2 = 25,
  AllowViewRatePlanName = 81,

  HotelDetailNearbyHotel = 91,
  HotelDetailRegionSearch = 92,
  HotelDetailCancellationFilter = 93,
  HotelDetailBreakfastFilter = 94,
  UserHotelFavoriteFeature = 95,
  HotelDetailOrderInNewTab = 96,
  HotelDetailQuotationAssistant = 97,
  HotelDetailH5QRCode = 99,
  DidaShoppingMessageBox = 100,
  PointsExchange = 103,

  BookingFreeCancellationNotice = 107,
  InvoiceStatusChange = 104,
  WorkOrderStatusChange = 105,
  BookingHotelConfirmationNotice = 108,
  CouponNearExpirationNotice = 109,

  DisableBookingLowerPriceNotice = 121,
  DisablePostPayBookingAutoCancelNotice = 122,
  DisablePointsExchange = 123,
  DisableInvoiceStatusChange = 124,
  DisableWorkOrderStatusChange = 125,
  DisableBillStatusChange = 126,
  DisableBookingFreeCancellationNotice = 127,
  DisableBookingHotelConfirmationNotice = 128,
  DisableCouponNearExpirationNotice = 129,

  BillStatusChange = 106,
  DidaActivity_DragonBoatFest = 202,
  PaymentChannel_BaoFoo = 301,
  PaymentChannel_Balance = 303,
  PaymentChannel_Alipay = 305,

  PaymentChannel_WeChatMinAppPay = 314,
  PaymentChannel_WeChatMinAppPay_Forbidden = 315,


  PaymentChannel_Nuvei = 316,
  PaymentChannel_Nuvei_Forbidden = 317,

  ExternalAuth = 400,
  // 可自定义入住凭证
  AllowCustomVoucherInfo = 603,
  BookingSettlement = 701,
  AllowSettlementPayChannel_Alipay = 702,
  AllowSettlementPayChannel_BaoFoo = 703,
  WorkOrder = 704,
  AllowPreviewVoucher = 707,
  AllowEditHotline = 708,
  ExposeSurveyEntrance = 709,
  AllowDisplayHotelRadar = 712,
  /**
   * @deprecated 已rollout
   */
  AllowLoadRateWithSettlementCurrency = 710,
  ForbidChangePreOrderRoomCount = -711,
  AllowFindHotelByMap = 808,

  AllowViewTestingFeature = 810,

  TransfersFeature = 900,
  TransfersFeature_Forbidden = -900,

  CouponFeature = 1000,
  CouponFeature_Forbidden = -1000,

  DataStatisticsFeature = 1100,
  DataStatisticsFeature_Forbidden = -1100,

  SpecialRatePlanFeature = 1200,
  SpecialRatePlanFeature_Forbidden = -1200,

  DoubleUserPointsIncentive = 1210,
  DoubleUserPointsIncentive_Forbidden = -1210,

  TicketStandardizationFeature = 1300,
  TicketStandardizationFeature_Forbidden = -1300,

  HotelPackageFeature = 1400
}
