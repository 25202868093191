
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2BaiduAnalytics } from 'angulartics2/baidu';
import { DidaBehaviorEventTrackingOption } from '@dida-shopping/dida-services/bm';
import { Angulartics2DidaProviderBase } from './angulartics2-dida.base';

declare let _hmt: any;

@Injectable()
export class Angulartics2DidaBaiduAnalyticsProvider extends Angulartics2BaiduAnalytics implements Angulartics2DidaProviderBase {

  constructor(
    private angulartics2_: Angulartics2
  ) {
    super(angulartics2_);
  }

  /**
   * Track Event in Baidu Analytics
   *
   * @param action Name associated with the event
   * @param properties Comprised of:
   *  - 'category' (string)
   *  - 'opt_label' (string)
   *  - 'opt_value' (string)
   *
   * @link http://tongji.baidu.com/open/api/more?p=ref_trackEvent
   */
  eventTrack(action: string, properties: any) {
    // baidu analytics requires category
    if (!properties || !properties.category) {
      properties = properties || {};
      properties.category = 'Event';
      properties.label = 'default';
      properties.value = 'default';
    }

    if (typeof _hmt !== 'undefined' && _hmt) {
      _hmt.push([
        '_trackEvent',
        properties.category,
        action,
        properties.label,
        properties.value,
      ]);
    }
  }

  track(option: DidaBehaviorEventTrackingOption): void {

    this.eventTrack(option.action.toString(), {
      category: option.category,
      label: option.label,
      value: option.value,
      noninteraction: option.noninteraction,
      hitCallback: option.callback,
      page: option.page,
    });
    if (typeof (option.callback) === 'function') {
      window.setTimeout(() => {
        option.callback();
      }, 50); // trigger 50ms later
    }
  }


  setUsername(userId: string) {
    // set default custom variables name to 'identity' and 'value'
    console.error(`Should not call 'setUsername', use 'setUserInfo' instead.`);
  }

  setUserProperties(properties: any) {
    _hmt.push(['_setCustomVar', 3, 'userprop', JSON.stringify(properties)]);
  }

  setUserInfo(username: string, clientId?: string): void {
    _hmt.push(['_setCustomVar', 1, 'identity', username]);
    if (clientId != null) {
      _hmt.push(['_setCustomVar', 2, 'client', clientId]);
    }
  }
}
