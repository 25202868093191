import { Route } from '@angular/router';
import { IExperimentControls } from '@dida-shopping/dida-services/experiment';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { ILayoutSettingModel, INavBarSettingModel } from '@dida-shopping/dida-services/common';

export interface UserContextConditions {
  needAuth?: boolean;
  needClientInfo?: boolean;
  needConfirmedEmail?: boolean;
  mainAccountOnly?: boolean;
  devOnly?: boolean;
  devClients?: string[];
}

export const PRESERVED_DEV_CLIENTS = ['roman_test', 'exia_test', 'Hikari_test', 'liyingtest', 'adam_test'];

export const UserNeedAuthCondition: UserContextConditions = {
  needAuth: true,
  needClientInfo: false,
  needConfirmedEmail: false
};


export const UserNeedFullInfoCondition: UserContextConditions = {
  needAuth: true,
  needClientInfo: true,
  needConfirmedEmail: true
};

export const RestrictedDevOnlyFeature: UserContextConditions = {
  needAuth: true,
  needClientInfo: true,
  needConfirmedEmail: true,
  // devOnly: false,
  devClients: PRESERVED_DEV_CLIENTS
};


export interface IRouteDataModel extends ILayoutSettingModel, INavBarSettingModel {
  // old values - should removed after migration
  name?: string;
  preload?: boolean;
  hideNav?: boolean;
  homePage?: boolean;
  hideLayout?: boolean;
  responsive?: boolean;
  promotionType?: string;

  // permission settings
  condition?: UserContextConditions;
  experimentCtrl?: IExperimentControls;

  // lang restrictions
  langRestriction?: LanguageType[];

  // layout settings: see ILayoutSettingModel
  // nav-bar settings: see INavBarSettingModel

  // others
  ignoreAccountLocked?: boolean;
}

export interface IAppRoute extends Route {
  children?: AppRoutes;
  data?: IRouteDataModel
}

export type AppRoutes = IAppRoute[];
