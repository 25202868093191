import { ExperimentID } from '@dida-shopping/dida-services/experiment';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { ImageLinkI18nModel } from './image-link-i18n.model';

const EnabledLocaleList = [LanguageType.en_US, LanguageType.zh_CN, LanguageType.ja_JP];

const dash2Underline = (raw: string) => raw.replace('-', '_');

export class ImageLinkModel extends ImageLinkI18nModel {
  Id: string;
  StartDate: Date;
  Expired: Date;
  ReferenceUrl: string;
  Controls: ExperimentID[];
  ClientIds: string[];
  IsCNOnly: boolean;
  Inactive: boolean;
  /**
   * @description key值以下滑线分割的localeID, 例如zh_CN, 主要是为了方便代码里写
   */
  I18n: { [key: string]: ImageLinkI18nModel };
  get IsValid() {
    if (this.Inactive) {
      return false;
    }
    return !this.IsExpired && !this.IsIneffective;
  }

  get IsExpired() {
    if (!this.Expired) {
      return false;
    }

    if (+this.Expired < Date.now()) {
      return true;
    }
    return false;
  }

  get IsIneffective() {
    if (!this.StartDate) {
      return false;
    }

    if (+this.StartDate > Date.now()) {
      return true;
    }
    return false;
  }

  switchLanguage(lang: LanguageType) {
    const capableLang = dash2Underline(lang);
    Object.assign(this, this.I18n[capableLang]);
    return this;
  }

  constructor(payload?: ImageLinkModel) {
    super();
    Object.assign(this, payload);

    if (!this.I18n) {
      this.I18n = {};
    }

    if (!payload) {
      this.IsCNOnly = true;
    }

    // let defaultI18nInfo = this.toI18nModel();
    EnabledLocaleList.map((lang) => {
      const capableLang = dash2Underline(lang);
      if (!this.I18n[capableLang]) {
        const deprecatedLocale = this[lang] || this[capableLang];
        this.I18n[capableLang] = new ImageLinkI18nModel(deprecatedLocale);
        delete this[lang];
        delete this[capableLang];
      }
    });

    this.switchLanguage(LanguageType.zh_CN);

    if (typeof this.Expired === 'string' && this.Expired) {
      this.Expired = new Date(this.Expired);
    }

    if (typeof this.StartDate === 'string' || this.StartDate) {
      this.StartDate = new Date(this.StartDate);
    }

    if (!this.Id) {
      this.Id = this.Title;
    }
  }

  toI18nModel() {
    const translation = new ImageLinkI18nModel();
    translation.Desc = this.Desc;
    translation.ImgUrl = this.ImgUrl;
    // translation.ImgPreview = this.ImgPreview;
    translation.ImgUrl2 = this.ImgUrl2;
    translation.Reference = this.Reference;
    translation.Title = this.Title;
    translation.SubTitle = this.SubTitle;
    return translation;
  }
}
