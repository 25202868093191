import { CouponTypeEnum } from '../../../enums/campaigns/coupons/coupon-type.enum';
import { DateTimeRangeRestrictionModel } from './date-time-range-restriction.model';
import { CouponRewardSettingModel } from './coupon-reward-setting.model';
import { ShoppingCouponStatusEnum } from '../../../enums/campaigns/coupons/shopping-coupon-status';
import { CouponRestrictionRuleModel } from './coupon-restriction-rule.model';
import { ShoppingCouponPageTypeEnum } from '../../../enums/campaigns/coupons/shopping-coupon-page-type.enum';

export class CouponModel {
    CouponID: string;
    CouponType: CouponTypeEnum;
    CampaignID: number;
    Name: string;
    DisplayName_EN: string;
    DisplayName_CN: string;
    EffectiveTimeSetting: DateTimeRangeRestrictionModel;
    RewardSetting: CouponRewardSettingModel;
    Expired: boolean;
    PendingEffect: boolean;
    Status: ShoppingCouponStatusEnum;
    RestrictionRuleModel: CouponRestrictionRuleModel;
    CreateTime: Date;
    UpdateTime: Date;
}

export class UserCouponCacheModel {
    UserID: string;
    TotalAmount: number;
    CouponModels: CouponModel[];
}

export class UserCouponCountModel {
    Status: ShoppingCouponPageTypeEnum;
    Count: number;
}


export class HotelCouponModel extends CouponModel {

}

