import { Injectable } from '@angular/core';

import { LoggerService } from '../../logger/logger.service';
import { HttpClient } from '../../http/http-client';
import { Observable } from 'rxjs';
import { googleMapService, IPlaceSearchResponse, IPlaceSearchResultItem } from '@dida-shopping/dida-services/maps';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
import { ngxHttpCall } from '@dida-shopping/ngx-dida-site-core';

/**
 * 地圖相关信息数据服务
 *
 * @export
 * @class GoogleMapService
 */
@Injectable()
export class GoogleMapService {

  constructor(
    private logger: LoggerService,
    private httpClient: HttpClient
  ) {
  }

  // subscribe(...args) {
  //     this.mapSubject.subscribe(...args);
  // }

  /**
   * 獲取酒店地理信息
   *
   * @returns {Observable<Array<HotelCoordinations>>}
   * @memberof GoogleMapService
   */
  // observeHotelCoordinationsInfo(): Observable<AppMessage> {
  //     return this.mapSubject.asObservable();
  // }

  // hoverListItem(coordination, enter = true) {
  //   this.mapEventHub.broadcast(new HotelItemHoverEvent(
  //     coordination, enter, `[GoogleMapService::hoverListItem]`, new Date()
  //   ));
  //   // this.mapSubject.next({
  //   //   type: MapRelativeMessageTypeEnum.HotelItemHover,
  //   //   data: { coordination, enter }
  //   // });
  // }
  //
  // selectListItem(coordination) {
  //   this.mapSubject.next({
  //     type: MapRelativeMessageTypeEnum.HotelItemSelect,
  //     data: { coordination }
  //   });
  // }
  //
  // selectMapItem(coordination) {
  //   this.mapSubject.next({
  //     type: MapRelativeMessageTypeEnum.MapItemSelect,
  //     data: { coordination }
  //   });
  // }


  searchRegionPlaces(regionId: string, pageToken: string = null) {
    return ngxHttpCall(
      googleMapService.searchRegionPlaces(regionId, pageToken)
    );
    // return this.httpClient.post<{
    //   RegionCentralLocation: GeoCoordinate,
    //   Response: IPlaceSearchResponse
    //
    // }>('/GoogleMapProxyApi/GetRegionPlaces', {
    //   regionId, pageToken
    // }, null, (resp) => {
    //   if (resp && resp.Response && resp.Response.results) {
    //     resp.Response.results.forEach(r => this.processPlaceData(r));
    //   }
    // });
  }

  searchHotelPlaces(hotelId: number, location: GeoCoordinate, type: string, radius: number) {
    return ngxHttpCall(
      googleMapService.searchHotelPlaces(hotelId, location, type, radius)
    );
    // return this.httpClient.post<IPlaceSearchResponse>('/GoogleMapProxyApi/SearchNearby', {
    //   hotelId, location, type, radius
    // }, null, (resp) => {
    //   if (resp && resp.results) {
    //     resp.results.forEach(r => this.processPlaceData(r));
    //   }
    // }, (payload) => {
    //   payload.location = {
    //     Latitude: location.Latitude,
    //     Longitude: location.Longitude
    //   };
    // });
  }

  // private processPlaceData(item: IPlaceSearchResultItem) {
  //   item.id = item.id || item.place_id;
  // }
}
