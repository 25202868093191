/**
 *  高德地图点标记的动画效果
 */
export enum AmapMarkerAnimationEnum {
  /**
   *  无动画效果
   */
  AMAP_ANIMATION_NONE = 'AMAP_ANIMATION_NONE',
  /**
   *  点标掉落效果
   */
  AMAP_ANIMATION_DROP = 'AMAP_ANIMATION_DROP',
  /**
   *  点标弹跳效果
   */
  AMAP_ANIMATION_BOUNCE = 'AMAP_ANIMATION_BOUNCE'
}

export const AmapsPOITypeCollection: string[] = [
  '110000', // 风景名胜 - Tourist Attraction
  '52e81612bcbc57f1066b7a21', // National Park
  '52e81612bcbc57f1066b7a13', // Nature Preserve
  '140100', '4bf58dd8d48988d181941735', // 博物馆 - Museum
  '140300', '56aa371be4b08b9a8d573532', '4bf58dd8d48988d1ff931735', // 会展中心 - Convention & Exhibition Center
  '140400', '4bf58dd8d48988d1e2931735', // 美术馆 - Art Gallery
  '140500', '4bf58dd8d48988d12f941735', // 图书馆 - Library
  '140600', // 科技馆 - Science & Technology Museum
  '190600', '4bf58dd8d48988d12d941735' // 标志性建筑物 - Landmark Buildings
];

export const AmapsCommercialAreaTypeCollection: string[] = [
  '190700', // 热点地名 - The hot names
  '56aa371be4b08b9a8d573517', // Business Center
  '52e81612bcbc57f1066b7a32' // Cultural Center
];

export type GaodeMapLanguageType = 'zh_cn' | 'en' | 'zh_en';

export type GaodeMapVectorMapForeignType = 'cn' | 'en' | 'local';

export const GaodeMapVectorForeignMapping = {
  '1.4.3': {
    'cn': 'style_zh_cn',
    'en': 'style_en',
    'local': 'style_local'
  },
  '1.4.15': {
    'cn': 'Chinese_Simplified',
    'en': 'English',
    'local': 'Local'
  }
};

export interface IGaodeMapOptionModel {
  lang: GaodeMapLanguageType;
  [key: string]: any;
}



