import { HttpServiceBase } from '../http';
import { ISiteRouteConfigModel } from './models';

class SiteRouteService extends HttpServiceBase {

  constructor() {
    super('SiteRouteService');
  }

  getApplicationRouteConfig(appName: string) {
    return this.httpClientWrapper.get<ISiteRouteConfigModel>(
      '/ApplicationContextApi/GetApplicationRoute', { appName });
  }

}

export default new SiteRouteService();
