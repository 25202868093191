import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaRadioComponent } from './dida-radio.component';
import { DidaRadioGroupDirective } from './dida-radio-group.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    DidaRadioComponent, 
    DidaRadioGroupDirective
  ],
  declarations: [
    DidaRadioComponent, 
    DidaRadioGroupDirective
  ]
})
export class DidaRadioModule { }
