import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { HotelBookingStatusType } from '../../enums/hotel-booking-status-type';
import { BookingStatusTypeEnum } from '../../enums/booking-status-type';

export class HotelBooking {
  BookingID: string;
  ClientID: string;
  UserID: string;
  Status: number;
  StatusEnum: BookingStatusTypeEnum;
  HotelID: number;
  RatePlanID: string;
  HotelName: string;
  RatePlanName: string;
  CityCode: string;
  BedTypeID: number;
  BreakfastID: number;
  BookType?: number;
  MaxOccupancy?: number;
  CheckInDate: Date;
  CheckOutDate: Date;
  OrderDate: Date;
  TotalPrice: number;
  TotalNetRate: number;
  Currency: string;
  RoomCount: number;
  TotalSupplement?: number;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContactEmail: string;
  CustomerRequest: string;
  ClientReference: string;
  BookingGroupReference: string;
  // ClientReferenceOriginal: string;
  IsGroupedBooking: boolean;
  // SupplierBookingRemarks: string;
  // SupplierBookingReference: string;
  Nationality: string;
  HotelConfirmationCode: string;
  BookingCancelTime?: Date;
}
