import { HttpParams } from '@angular/common/http';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';

export class NgxHttpParamsHelper {

  /**
   * 组装请求数据
   * @param payload 数据
   * @param name 对应名称
   * @param params 已存在的 ${NgCommonHttp.HttpParams}
   */
  static buildHttpParams(payload: any, name?: string, params?: HttpParams): HttpParams {
    params = params == null ? new HttpParams() : params;
    const isArray: boolean = payload instanceof Array;
    if (payload != null) {
      for (const key in payload) {
        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty(key)) {
          const val = payload[key];
          if (val != null) {
            const ak = NgxHttpParamsHelper.buildAKey(key, name, isArray);
            if (val instanceof Date) {
              let valStr = '';
              if (val.getHours() === 0 && val.getMinutes() === 0 && val.getSeconds() === 0 && val.getMilliseconds() === 0) {
                valStr = DateTimeHelper.format(val, DateTimeHelper.DEFAULT_DATE_FORMAT);
              } else {
                valStr = DateTimeHelper.format(val, DateTimeHelper.DEFAULT_DATE_TIME_FORMAT);
              }
              params = params.append(ak, valStr);
            } else if (typeof val === 'object') {
              params = NgxHttpParamsHelper.buildHttpParams(val, ak, params);
            } else {
              params = params.append(ak, val);
            }
          }

        }
      }
    }
    return params;
  }

  private static buildAKey(key: string | number, name: string, isArray: boolean): string {
    const hasName: boolean = name != null;
    let aKey = key as string;
    if (isArray) {
      aKey = '[' + key + ']';
      if (hasName) {
        aKey = name + aKey;
      }
    } else if (hasName) {
      aKey = name + '.' + aKey;
    }
    return aKey;
  }

}

