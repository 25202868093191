import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-weapp-proxy',
  templateUrl: './weapp-proxy.component.html',
  styleUrls: ['./weapp-proxy.component.scss']
})
export class WeappProxyComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.router.navigate(this.route.snapshot.url.map(item => item.path), {queryParamsHandling: 'preserve'});
  }

}
