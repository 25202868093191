import { HttpServiceBase } from '../http';
import { ActivityKeyEnum } from './enums/activity-key.enum';
import { EntityCategoryEnum } from './enums/entity-category.enums';
import { EntityModifyTypeEnum } from './enums/entity-modify-type.enum';
import {
  ActivityConfigModel,
  ActivityRegionModel,
  BannerModel
} from './models/activity-models';
import { IDataEntityModel } from './models/data-entity.model';
import { resourceService } from './resource.service';

class HotelPromotionActivityService extends HttpServiceBase {

  constructor() {
    super();
  }

  getActivity(alias: string) {
    return resourceService.getResourceEntityExtracted<any[]>(EntityCategoryEnum.Activity, alias,
      (entity) => {
        const resp = entity.EntityValue;
        const config: ActivityConfigModel = resp[0];
        if (!config) {
          return [new ActivityConfigModel()];
        }
        if (!config.customInfoDict) {
          const regions: ActivityRegionModel[] = resp.slice(1);
          config.customInfoDict = regions.reduce((result, item) => {
            (item.customInfoList || []).map(info => {
              result[info.HotelID] = info;
            });
            return result;
          }, {});
        }
        return resp;
      });
  }

  createActivity(alias: string, value: string, desc: string) {
    const entity = {
      EntityKey: alias,
      EntityType: EntityCategoryEnum.Activity,
      EntityValue: value || '[]',
      EntityDesc: desc
    } as IDataEntityModel<string>;
    return resourceService.updateResourceEntity(entity, EntityModifyTypeEnum.Create);
  }


  updateActivity(alias: string, config: ActivityConfigModel, sections: ActivityRegionModel[]) {
    const entity = {} as IDataEntityModel<string>;
    entity.EntityKey = alias;
    entity.EntityType = EntityCategoryEnum.Activity;
    entity.EntityValue = JSON.stringify([config, ...sections]);
    return resourceService.updateResourceEntity(entity, EntityModifyTypeEnum.Update);
  }

  /**
   * 获取活动入口数据
   * @param raw 用于配置页
   */
  getActivityEntry(raw = false) {
    return resourceService.getResourceEntityExtracted<BannerModel[]>(
      EntityCategoryEnum.Activity, ActivityKeyEnum.ActivityEntry,
      (entity) => {
        let resp = entity.EntityValue.map(item => {
          if (item.Id) {
            item.DefaultReferenceUrl = ['/promotion', item.BannerType, item.Id].filter(seg => !!seg).join('/');
          }

          const normalized = new BannerModel(item);
          return normalized;
        });
        if (!raw) {
          resp = resp.filter(item => item.IsValid)
            // 没有链接则使用默认的
            .map(item => {
              item.ReferenceUrl = item.ReferenceUrl || item.DefaultReferenceUrl;
              return item;
            });
        }
        return resp;
      }
    );
  }
}

export const hotelPromotionActivityService = new HotelPromotionActivityService();
