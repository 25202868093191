import {
  AfterViewInit,
  Attribute,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewEncapsulation,
  Input,
  Renderer2,
  ViewChild,
  EventEmitter,
  OnDestroy,
  Output,
  HostBinding,
  ContentChildren,
  AfterContentInit,
  QueryList,
  Self,
  Optional
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
  NgControl,
  NgModel
} from '@angular/forms';
import { coerceBooleanProperty } from '../../../core/coercion/boolean-property';
import { DidaFormHintDirective } from '../dida-form-group/dida-form-hint.directive';

export enum DidaCheckboxState {
  Init,
  Checked,
  Unchecked,
  Indeterminate
}

export class DidaCheckboxChangeEvent {
  source: DidaCheckboxComponent;
  checked: boolean;
}

let nextInstanceId = 0;

@Component({
  selector: 'dida-checkbox',
  exportAs: 'didaCheckbox',
  templateUrl: './dida-checkbox.component.html',
  styleUrls: ['./dida-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // providers: [{
  //   provide: NG_VALUE_ACCESSOR,
  //   useExisting: DidaCheckboxComponent,
  //   multi: true,
  // }],
  preserveWhitespaces: true
})
export class DidaCheckboxComponent
  implements ControlValueAccessor, AfterViewInit, AfterContentInit, OnDestroy {
  private _id: string;
  private _instanceId = `didaCheckbox#${nextInstanceId++}`;
  private _required: boolean;
  private _currentCheckState: DidaCheckboxState = DidaCheckboxState.Init;
  private _checked = false;
  private _disabled = false;
  private _indeterminate = false;
  private _onFocusEventListener: (event: FocusEvent) => void;
  private _onBlurEventListener: (event: FocusEvent) => void;

  @HostBinding('class.size_sm')
  get bingFormGroupSizeSm() {
    return this.size === 'sm';
  }

  @HostBinding('class.size_lg')
  get bingFormGroupSizeLg() {
    return this.size === 'lg';
  }

  @Input() size = '';

  @HostBinding('attr.id')
  @Input()
  get id() {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._instanceId;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._updateView();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
    if (this._required) {
      this.ngControl.control.setValidators([Validators.requiredTrue]);
    } else {
      this.ngControl.control.clearValidators();
    }
  }

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(checked: boolean) {
    if (this._checked !== checked) {
      this._checked = checked;
      this._updateView();
      this._changeDetectorRef.detectChanges();
    }
  }

  @Input()
  get indeterminate() {
    return this._indeterminate;
  }
  set indeterminate(indeterminate: boolean) {
    let changed = indeterminate !== this._indeterminate;
    this._indeterminate = indeterminate;

    if (changed) {
      if (this._indeterminate) {
        this._transitionCheckState(DidaCheckboxState.Indeterminate);
      } else {
        this._transitionCheckState(
          this.checked ? DidaCheckboxState.Checked : DidaCheckboxState.Unchecked
        );
      }
      this.indeterminateChange.emit(this._indeterminate);
    }
  }

  @Input() name: string | null = null;

  @Input() value: string;

  @Output() change: EventEmitter<DidaCheckboxChangeEvent> = new EventEmitter<
    DidaCheckboxChangeEvent
  >();

  @Output() indeterminateChange: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * 原生的input元素
   *
   * @type {ElementRef}
   * @memberof DidaCheckboxComponent
   */
  @ViewChild('input', { static: true }) _inputElement: ElementRef;

  /**
   * 原生的input元素
   *
   * @type {ElementRef}
   * @memberof DidaCheckboxComponent
   */
  @ViewChild('checkboxIcon', { static: true }) _iconElement: ElementRef;

  @ContentChildren(DidaFormHintDirective) _hints: QueryList<
    DidaFormHintDirective
  >;

  get inputId(): string {
    return `${this.id}__input`;
  }

  onTouched: () => any = () => {};

  private _controlValueAccessorChangeFn: (value: any) => void = () => {};

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.id = this.id;
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit() {
    let element = this._inputElement.nativeElement as HTMLElement;
    this._onFocusEventListener = (event: FocusEvent) =>
      this._onFocus(event, element);
    this._onBlurEventListener = (event: FocusEvent) =>
      this._onBlur(event, element);
    element.addEventListener('focus', this._onFocusEventListener, true);
    element.addEventListener('blur', this._onBlurEventListener, true);
  }

  ngAfterContentInit() {
    this._hints.forEach(h => (h.nonDotted = true));
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    let element = this._inputElement.nativeElement as HTMLElement;
    element.removeEventListener('focus', this._onFocusEventListener, true);
    element.removeEventListener('blur', this._onBlurEventListener, true);
  }

  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this._checked = !!value;
    this._updateView();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this._changeDetectorRef.detectChanges();
  }

  _onLabelTextChange() {
    this._changeDetectorRef.detectChanges();
  }

  _onInputClick(event: Event) {
    event.stopPropagation();
    if (!this.disabled) {
      if (this._indeterminate) {
        Promise.resolve().then(() => {
          this._indeterminate = false;
          this.indeterminateChange.emit(this._indeterminate);
        });
      }
      this.toggle();
      this._transitionCheckState(
        this._checked ? DidaCheckboxState.Checked : DidaCheckboxState.Unchecked
      );
      this._emitChangeEvent();
    }
  }

  _onInteractionEvent(event: Event) {
    event.stopPropagation();
  }

  private _transitionCheckState(newState: DidaCheckboxState) {
    let oldState = this._currentCheckState;
    if (oldState === newState) {
      return;
    }
    this._currentCheckState = newState;
  }

  private _onFocus(event: FocusEvent, element: HTMLElement) {
    // noop
  }

  private _onBlur(event: FocusEvent, element: HTMLElement) {
    // noop
  }

  private _emitChangeEvent() {
    let event = new DidaCheckboxChangeEvent();
    event.source = this;
    event.checked = this.checked;

    this._controlValueAccessorChangeFn(this.checked);
    this.change.emit(event);
  }

  private _updateView() {
    if (this._checked) {
      this.renderer.addClass(
        this._iconElement.nativeElement,
        'icon-checkbox_selected'
      );
    } else {
      this.renderer.removeClass(
        this._iconElement.nativeElement,
        'icon-checkbox_selected'
      );
    }

    if (this._disabled) {
      this.renderer.addClass(
        this._iconElement.nativeElement,
        'icon-checkbox_disabled'
      );
    } else {
      this.renderer.removeClass(
        this._iconElement.nativeElement,
        'icon-checkbox_disabled'
      );
    }
  }

  toggle(): void {
    this.checked = !this.checked;
  }
}
