import { Directive, Host, Input, HostBinding } from '@angular/core';

let nextInstanceId = 0;

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'dida-form-hint'
})
export class DidaFormHintDirective {

    @Input() id = `didaFormHint#${nextInstanceId++}`;

    @HostBinding('class') class = 'dida-form-hint';

    @HostBinding('class.dida-form-hint_no-dot') 
    @Input() nonDotted = false;

    @HostBinding('attr.id') _id = this.id;    

}
