import { HttpProtocolTypeEnum } from './enums';
import { SERVER_CONFIG } from '../app-server-config';

export const APP_PROTOCOL: HttpProtocolTypeEnum =
  window.location.href.split('/')[0] === 'https:' ? HttpProtocolTypeEnum.HTTPS : HttpProtocolTypeEnum.HTTP;

export class ExternalRoutingHelper {
  /**
   * 强制重载到外部页面
   *
   * @static
   * @param {string} externalUrl
   * @param {boolean} [openNewWindow=false]
   * @param {HttpProtocolTypeEnum} [protocol=null]
   * @memberof ExternalRoutingHelper
   */
  static navigateTo(externalUrl: string, openNewWindow = false, protocol: HttpProtocolTypeEnum = null): void {
    const actualUrl = ExternalRoutingHelper.constructActualExternalUrl(externalUrl, protocol);
    if (openNewWindow) {
      window.open(actualUrl, '_blank');
    } else {
      window.location.href = actualUrl;
    }
  }

  /**
   * 构建外部链接
   *
   * @static
   * @param {string} externalUrl
   * @param {HttpProtocolTypeEnum} [protocol=null]
   * @returns {string}
   * @memberof ExternalRoutingHelper
   */
  static constructActualExternalUrl(externalUrl: string, protocol: HttpProtocolTypeEnum = null): string {
    let actualUrl = externalUrl;
    if (actualUrl.startsWith('/')) {
      // redirect to old site
      if (SERVER_CONFIG.oldSiteUrl) {
        if (protocol == null) {
          protocol = APP_PROTOCOL;
        }
        if (protocol === HttpProtocolTypeEnum.HTTP) {
          actualUrl = `http://${SERVER_CONFIG.oldSiteUrl}${actualUrl}`;
        } else if (protocol === HttpProtocolTypeEnum.HTTPS) {
          actualUrl = `https://${SERVER_CONFIG.oldSiteUrl}${actualUrl}`;
        } else {
          actualUrl = `${SERVER_CONFIG.oldSiteUrl}${actualUrl}`;
        }
      } else {
        actualUrl = '/Api' + actualUrl;
      }
    }
    return actualUrl;
  }
}
