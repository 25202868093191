import {
  Component, Inject,
  Input, LOCALE_ID,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { DidaUITKGeneralEventModel, IDidaUITKEventModel } from '@dida-shopping/ngx-dida-uitk/core';
import {
  ApplicationContextUpdateEvent,
  ApplicationEventHub,
  ApplicationService,
  ComponentBaseWithContext,
  UserCouponService,
  ApplicationNavbarNoticeChangeEvent, QueryParamHelper, DIDA_APP_NAME
} from '@dida-shopping/ngx-dida-site-core';
import { CurrencyModel } from '@dida-shopping/dida-services/currency';
import { CouponHelper } from '@dida-shopping/dida-services/campaign';
import { Router } from '@angular/router';
import { collapseMotion } from 'ng-zorro-antd/core/animation';
import { DateTimeHelper, IDateRangeWindowModel, INavBarSettingModel } from '@dida-shopping/dida-services/common';

const EXTRA_LANGUAGE_APPS = new Set<string>([
  'home-app'
]);

@Component({
  selector: 'nd-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [collapseMotion]
})
export class DidaUITKNavBarComponent extends ComponentBaseWithContext implements OnInit, OnDestroy {

  @Input() ndSetting: INavBarSettingModel = {};

  currentSuffixMessage: string | TemplateRef<any>;
  isShowSuffixMessage = false;
  canSupportExtraLocales = false;

  get isSimplifiedNav(): boolean {
    return this.ndSetting.navSimplified;
  }

  get showHomeNavigations() {
    return !this.appContext || !this.appContext.userIdentityInfo.isAuthenticated;
  }

  get showUserNavigations() {
    return (
      this.appContext &&
      this.appContext.userIdentityInfo.isAuthenticated &&
      this.userProfile.ClientID // &&
      // this.userProfile.EmailConfirmed
    );
  }

  get allowCoupon() {
    return CouponHelper.allowUseCoupon(this.ctxStorage);
  }

  get activeCouponCount(): number {
    return this.userCouponService.activeCouponCount;
  }

  get activeCouponCountDisplay(): string {
    const count = this.activeCouponCount;
    if (count <= 99) {
      return count.toString();
    }
    return '99+';
  }

  get showCurrencySetting() {
    return this.hasUserLogin && this.currencyInfo && this.ndSetting.showCurrencySetting;
  }

  get showNavBarSearchPanel() {
    return this.hasUserLogin && this.ndSetting.showNavSearch;
  }


  constructor(
    @Inject(DIDA_APP_NAME) private appName: string,
    @Inject(LOCALE_ID) private locale: string,
    private appService: ApplicationService,
    private userCouponService: UserCouponService,
    private appEventHub: ApplicationEventHub,
    private router: Router
  ) {
    super();
    if (!appEventHub || !appService) {
      throw new Error(
        `ApplicationService is not defined. Make sure you have import NgxSiteCoreModule in your AppModule.`
      );
    }
  }

  ngOnInit(): void {
    this.initLanguageControl();
    this.initUserCouponService();
    this.subscriptions['appEvent'] = this.appEventHub.subscribe({
      next: event => {
        // this.ctxStorage = Object.assign({}, APP_CONTEXT_STORAGE);
        if (event instanceof ApplicationContextUpdateEvent) {
          this.initLanguageControl();
          this.initUserCouponService();
        }
        if (event instanceof ApplicationNavbarNoticeChangeEvent) {
          this.showSuffixMessage(event.message, event.duration);
        }
      }
    });
  }

  initLanguageControl() {
    this.canSupportExtraLocales = EXTRA_LANGUAGE_APPS.has(this.appName) && !this.hasUserLogin;
  }

  initUserCouponService() {
    if (!this.allowCoupon) {
      this.userCouponService.stop();
      return;
    }
    if (this.hasUserLogin) {
      this.userCouponService.start();
    } else {
      this.userCouponService.stop();
    }
  }

  switchCurrency($event: DidaUITKGeneralEventModel<CurrencyModel>) {
    this.appService.updateCurrencySetting($event.data.code).subscribe();
  }

  switchLanguage($event: IDidaUITKEventModel<LanguageType>) {
    this.appService.switchLanguage($event.data);
  }

  // onBrandLogoClick($event: MouseEvent) {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   if (this.isSimplifiedNav) {
  //     this.router.navigate(['/']);
  //   }
  // }

  showSuffixMessage(template: string | TemplateRef<any>, duration = 5000) {
    this.currentSuffixMessage = template;
    this.isShowSuffixMessage = true;
    if (duration) {
      setTimeout(() => {
        this.isShowSuffixMessage = false;
      }, duration);
    }
  }

  onSearchPanelOptionChange(id: string, type: 'hotel' | 'region', dateRange: IDateRangeWindowModel) {
    let routeCommands: string[] = [];
    const params = {
      hotelID: null,
      regionID: null,
      checkInDate: DateTimeHelper.format(dateRange.From, DateTimeHelper.DEFAULT_DATE_FORMAT),
      checkOutDate: DateTimeHelper.format(dateRange.To, DateTimeHelper.DEFAULT_DATE_FORMAT)
    };
    if (type === 'hotel') {
      routeCommands = ['/hotel', 'detail', id];
      params.hotelID = id;
    } else if (type === 'region') {
      routeCommands = ['/hotel', 'list'];
      params.regionID = id;
    }
    this.router.navigate(routeCommands, { queryParams: QueryParamHelper.serialize(params) });
  }
}
