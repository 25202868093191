import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { LoggerService } from './logger/logger.service';
import { AuthenticationService } from './services/auth/authentication.service';
import { AuthorizationService } from './services/auth/authorization.service';

import { HttpClient } from './http/http-client';
import { HotelStaticService } from './services/hotel/hotel-static.service';
import { RateSearchService } from './services/rates/rate-search.service';
import { PromotionInfoService } from './services/promotion/promotion-info.service';
import { MessageService } from './services/message/message.service';
import { UserService } from './services/user/user.service';
import { GoogleMapService } from './services/maps/google-map.service';
// import { GaodeMapService } from './services/maps/gaode-map.service';
import { OrderService } from './services/order/order.service';
import { BookingService } from './services/booking/booking.service';
import { WorkOrderService } from './services/work-order/work-order.service';
import { ClientService } from './services/client/client.service';
import { BehaviorManagementService } from './logger/behavior-management.service';
import { BillService } from './services/bill/bill.service';
import { ResourceService } from './services/resource/resource.service';
import { InvoiceService } from './services/invoice/invoice.service';
import { RateStateService } from './services/rates/rate-state.service';
import { LocalStorageService } from './services/storage/localstorage.service';
import { RoomOccupancyPickerService } from './services/hotel/room-occupancy-picker.service';
import { UserPageViewHistoryService } from './services/user/user-page-view-history.service';
import { TicketService } from './services/kangaroo/ticket.service';
import { PointsService } from './services/points/points.service';
import { AddressService } from './services/user/address.service';
import { DeactivationGuardService } from './services/guards/deactivation-guard.service';
import { TransfersRateService } from './services/transfers/rates/transfers-rate.service';
import { TransfersOrderService } from './services/transfers/order/transfers-order.service';
import { TransfersBookingService } from './services/transfers/booking/transfers-booking.service';
import { CouponService } from './services/campaigns/coupon/coupon.service';
import { CouponMarketService } from './services/pointsmarket/coupon-market.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  providers: [
    LoggerService,
    AuthenticationService,
    AuthorizationService,

    // AuthenticationGuardService,
    // AuthorizationGuardService,
    DeactivationGuardService,
    HttpClient,
    HotelStaticService,
    RateSearchService,
    PromotionInfoService,
    MessageService,
    UserService,
    UserPageViewHistoryService,
    GoogleMapService,
    // GaodeMapService,
    OrderService,
    BookingService,
    WorkOrderService,
    ClientService,
    BehaviorManagementService,
    BillService,
    ResourceService,
    InvoiceService,
    LocalStorageService,
    RateStateService,
    RoomOccupancyPickerService,
    TicketService,
    PointsService,
    CouponMarketService,
    AddressService,
    TransfersRateService,
    TransfersOrderService,
    TransfersBookingService,
    CouponService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import CoreModule in the AppModule only,`
      );
    }
  }
}
