<app-page-meta [title]="errorCode"></app-page-meta>
<div class="container" [ngSwitch]="errorCode" style="margin-top: 20px;">
    <div *ngSwitchCase="404" class="text-center">
        <img src="https://dsimage.didatravel.com/v2-app/images/404.png" alt="" style="width: 650px">
        <h4 i18n="@@Error_Notice_PageNotFound">页面飞走了，请返回<a class="active" routerLink="/home">首页</a></h4>
    </div>
    <div *ngSwitchCase="403">
        <h4 i18n="@@Error_Notice_PermimissionDenied">您没有权限访问该页面！！</h4>
        <p>
            <a class="active" routerLink="/home" i18n="@@Text_Link_BackToHome">返回首页</a>
        </p>
    </div>
    <div *ngSwitchDefault>
        <h4 i18n="@@Error_Notice_SystemError">系统出错了，请稍后再试或者联系客服！！</h4>
        <p>
            <a class="active" routerLink="/home" i18n="@@Text_Link_BackToHome">返回首页</a>
        </p>
    </div>
</div>
