import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  ApplicationEventHub,
  CampaignEventHub,
  FavoriteHotelEventHub,
  FeedbackEventHub,
  MapEventHub,
  MessageCenterEventHub,
  ServiceEventHub,
  TicketEventHub
} from './dida-event-hubs';

export * from './enums';
export * from './models';
export * from './dida-event-hubs';

@NgModule({
  providers: [
    ApplicationEventHub,
    MapEventHub,
    CampaignEventHub,
    FavoriteHotelEventHub,
    ServiceEventHub,
    FeedbackEventHub,
    MessageCenterEventHub,
    TicketEventHub
  ]
})
export class NgxDidaEventsModule {
  constructor(@Optional() @SkipSelf() parentModule: NgxDidaEventsModule) {
    if (parentModule) {
      throw new Error(
        `NgxDidaEventsModule has already been loaded. Import NgxDidaEventsModule in the AppModule only,`
      );
    }
  }
}
