import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaSelectComponent } from './dida-select.component';
import { DidaFormGroupModule } from '../dida-form-group/dida-form-group.module';
import { DidaInputModule } from '../dida-input/dida-input.module';
import {
  DidaFormErrorStateMatcher,
  DidaSelectFormErrorStateMatcher
} from '../dida-form-error-options';
import { DidaSelectOptionComponent } from './dida-select-option/dida-select-option.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    DidaFormGroupModule,
    DidaInputModule,
    BsDropdownModule.forRoot()
  ],
  exports: [
    DidaSelectComponent,
    DidaSelectOptionComponent,
    DidaFormGroupModule,
    DidaInputModule
  ],
  declarations: [DidaSelectComponent, DidaSelectOptionComponent],
  providers: [DidaFormErrorStateMatcher, DidaSelectFormErrorStateMatcher]
})
export class DidaSelectModule {}
