import { GeoCoordinate } from './geo-coordinate.model';

export const EARTH_RADIUS_KM = 6371;

export class GeographyService {

  /**
   * 终点坐标计算
   *
   * @private
   * @param {GeoCoordinate} startPos 起始坐标
   * @param {number} azimuth 方位角（-180~180）
   * @param {number} distance 距离（单位KM）
   * @returns {GeoCoordinate}
   * @memberof GaodeMapsComponent
   */
  public static calculateDestPosition(startPos: GeoCoordinate, azimuth: number, distance: number): GeoCoordinate {
    // let:
    // φ is latitude
    // λ is longitude
    // θ is the bearing (clockwise from north)
    // δ is the angular distance d/R
    // d being the distance travelled
    // R the earth’s radius
    // 
    // we have:
    // φ2 = asin( sin φ1 ⋅ cos δ + cos φ1 ⋅ sin δ ⋅ cos θ )
    // λ2 = λ1 + atan2( sin θ ⋅ sin δ ⋅ cos φ1, cos δ − sin φ1 ⋅ sin φ2 )

    const bearing = Math.PI * azimuth / 180;
    const delta = distance / EARTH_RADIUS_KM;
    const lng1Radians = startPos.Longitude * Math.PI / 180; // λ1
    const lat1Radians = startPos.Latitude * Math.PI / 180; // φ1

    const lat2Radians = Math.asin(Math.sin(lat1Radians) * Math.cos(delta) +
      Math.cos(lat1Radians) * Math.sin(delta) * Math.cos(bearing));
    const lng2Radians = lng1Radians + Math.atan2(Math.sin(bearing) * Math.sin(delta) * Math.cos(lat1Radians),
      Math.cos(delta) - Math.sin(lat1Radians) * Math.sin(lat2Radians));

    const lng2 = lng2Radians * 180 / Math.PI;
    const lat2 = lat2Radians * 180 / Math.PI;

    const des = new GeoCoordinate(lat2, lng2);
    return des;
  }

  /**
   * 计算方位角
   *
   * @private
   * @param {GeoCoordinate} startPos 起始坐标
   * @param {GeoCoordinate} endPos 终点坐标
   * @returns {number}
   * @memberof GaodeMapsComponent
   */
  public static calculateAzimuth(startPos: GeoCoordinate, endPos: GeoCoordinate): number {
    // let:
    // φ1,λ1 is the start point (lat, lng)
    // φ2,λ2 the end point (lat, lng)
    // Δλ is the difference in longitude
    //
    // we have:
    // θ = atan2( sin Δλ ⋅ cos φ2 , cos φ1 ⋅ sin φ2 − sin φ1 ⋅ cos φ2 ⋅ cos Δλ )

    const lng1Radians = startPos.Longitude * Math.PI / 180; // λ1
    const lat1Radians = startPos.Latitude * Math.PI / 180; // φ1

    const lng2Radians = endPos.Longitude * Math.PI / 180; // λ2
    const lat2Radians = endPos.Latitude * Math.PI / 180; // φ2

    const lngDelta = lng2Radians - lng1Radians; // Δλ

    const y = Math.sin(lngDelta) * Math.cos(lat2Radians);
    const x = Math.cos(lat1Radians) * Math.sin(lat2Radians) - Math.sin(lat1Radians) * Math.cos(lat2Radians) * Math.cos(lngDelta);
    const bearing = Math.atan2(y, x);

    const azimuth = (bearing * 180 / Math.PI) % 360;

    return azimuth;
  }


  /**
   * 计算距离（半正矢量函数算法）
   * 注意：为了降低误差，如果当前地图的函数库有距离计算方法，请使用对应的算法进行计算。
   * 
   * @static
   * @param {GeoCoordinate} startPos 起始坐标
   * @param {GeoCoordinate} endPos 终点坐标
   * @returns {number} 距离，单位KM
   * @memberof GeographyService
   */
  public static calculateDistance(startPos: GeoCoordinate, endPos: GeoCoordinate): number {
    // let:
    // φ1,λ1 is the start point (lat, lng)
    // φ2,λ2 is the end point (lat, lng)
    // R is the earth radius(in KM)
    // d is the distance
    // 
    // according to the Haversine formula:	
    //  a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
    //  c = 2 ⋅ atan2( √a, √(1−a) )
    //  d = R ⋅ c

    const lat1Radians = startPos.Latitude * Math.PI / 180; // φ1
    const lat2Radians = endPos.Latitude * Math.PI / 180; // φ2

    const deltaLat = (endPos.Latitude - startPos.Latitude) * Math.PI / 180; // Δφ
    const deltaLng = (endPos.Longitude - startPos.Longitude) * Math.PI / 180; // Δλ 

    const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1Radians) * Math.cos(lat2Radians) *
      Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = EARTH_RADIUS_KM * c;

    return d;
  }

  /**
   * 计算中间点
   *
   * @static
   * @param {GeoCoordinate} startPos 起始坐标
   * @param {GeoCoordinate} endPos 终点坐标
   * @returns {GeoCoordinate}
   * @memberof GeographyService
   */
  public static calculateMidPos(startPos: GeoCoordinate, endPos: GeoCoordinate): GeoCoordinate {
    // let:
    // φ1,λ1 is the start point (lat, lng)
    // φ2,λ2 is the end point (lat, lng)
    // φm,λm is the mid point (lat, lng)

    // we have:
    // Bx = cos φ2 ⋅ cos Δλ
    // By = cos φ2 ⋅ sin Δλ
    // φm = atan2( sin φ1 + sin φ2, √(cos φ1 + Bx)² + By² )
    // λm = λ1 + atan2(By, cos(φ1)+Bx)

    const lng1Radians = GeographyService.toRadians(startPos.Longitude); // λ1
    const lat1Radians = GeographyService.toRadians(startPos.Latitude); // φ1
    const lat2Radians = GeographyService.toRadians(endPos.Latitude); // φ2

    const deltaLng = GeographyService.toRadians(endPos.Longitude - startPos.Longitude); // Δλ 

    const Bx = Math.cos(lat2Radians) * Math.cos(deltaLng);
    const By = Math.cos(lat2Radians) * Math.sin(deltaLng);

    const latMidRadians = Math.atan2(Math.sin(lat1Radians) + Math.sin(lat2Radians),
      Math.sqrt((Math.cos(lat1Radians) + Bx) * (Math.cos(lat1Radians) + Bx) + By * By));

    const lngMidRadians = lng1Radians + Math.atan2(By, Math.cos(lat1Radians) + Bx);

    const latMid = GeographyService.toAngle(latMidRadians);
    const lngMid = GeographyService.toAngle(lngMidRadians);

    const mid = new GeoCoordinate(latMid, lngMid);
    return mid;
  }

  public static toRadians(angle: number) {
    return angle * Math.PI / 180;
  }

  public static toAngle(radians: number) {
    return radians * 180 / Math.PI;
  }



}