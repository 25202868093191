import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export enum AlertType {
    Default = 'Default',
    Success = 'success',
    Info = 'info',
    Danger = 'danger',
    Warning = 'warning',
    Pending = 'pending'
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {


    @Input()
    type: AlertType;

    @Input()
    inline = false;

    @Input()
    plain = false;

    @Input()
    dismissible = false;

    @Output()
    dismiss = new EventEmitter();

    AlertType = AlertType;

    constructor() { }

    ngOnInit() {
    }

}
