import { HttpServiceBase } from '../http';
import {
  IUserProfileModel,
  IUserCurrencyInfoModel,
  IUserPointAccountModel,
  ISubAccountPermissionViewModel
} from './models';
import { LanguageType } from '../i18n';
import { IPaginationWrapper } from '@dida-shopping/dida-services/common';
import { RedeemRecordStatusEnum, ITransactionRecord, IDailyRedeemInfoModel } from '@dida-shopping/dida-services/point-market';

class UserService extends HttpServiceBase {

  constructor() {
    super();
  }

  getUserInfo(userId: string) {
    return this.httpClientWrapper.post<IUserProfileModel>(
      `/UserApi/GetDetail`, { userId }, {
        resultProcessor: (profile: IUserProfileModel) => {
          if (typeof (profile.UserName) === 'undefined') {
            profile.UserName = null as any;
          }
          if (typeof profile.Email === 'undefined') {
            profile.Email = null as any;
          }
          if (!profile.ExperimentIDs) {
            profile.ExperimentIDs = [];
          }
          if (!profile.NotificationEmails) {
            profile.NotificationEmails = [];
          }
          if (!profile.Roles) {
            profile.Roles = [];
          }
          const raw: any = profile.Language;
          let lang = LanguageType.zh_CN;
          switch (raw) {
            case 1: {
              lang = LanguageType.en_US;
              break;
            }
            case 2: {
              lang = LanguageType.zh_CN;
              break;
            }
          }
          profile.Language = lang;
          if (profile.NotificationSetting == null) {
            // set default notification setting.
            profile.NotificationSetting = {
              ConfirmCodeByEmail: false,
              ConfirmCodeByPhone: false,
              ConfirmCodeByWeChat: false,
              WorkOrderNoticeByEmail: false,
              WorkOrderNoticeByWeChat: false
            };
          }
          return profile;
        }
      }
    );
  }

  deleteAccount(userId: string) {
    return this.httpClientWrapper.post<IUserProfileModel>(
      `/UserApi/CleanupUser`, { userId }
    );
  }

  updateCurrencySetting(currencyCode: string) {
    return this.httpClientWrapper.post<IUserCurrencyInfoModel>(
      `/AccountApi/UpdateCurrencySetting`,
      { currencyCode }
    );
  }

  updateLanguageSetting(lang: string) {
    return this.httpClientWrapper.post<boolean>(
      `/AccountApi/UpdateLanguageSetting`,
      { lang }
    );
  }

  getUserPointsAccount(fromCache = false) {
    return this.httpClientWrapper.get<IUserPointAccountModel>(
      `/UserPointsApi/GetUserPoints`,
      { fromCache }
    );
  }

  updateClientUserInfo(clientUser: IUserProfileModel, password?: string, passwordConfirm?: string) {
    return this.httpClientWrapper.post<IUserProfileModel>(
      '/UserApi/UpdateClientUserInfo',
      {
        clientUser,
        password,
        passwordConfirm,
        clearNotificationEmail: clientUser.NotificationEmails.length === 0
      }, {
        payloadProcessor: (options) => {
          const userProfile: IUserProfileModel = options.clientUser;
          this._preProcessLangType(userProfile);
          return options;
        }
      }
    );
  }


  // validations
  checkUserNameExist(userName: string) {
    return this.httpClientWrapper.get<boolean>('/UserApi/CheckUserNameExist', { userName });
  }
  checkEmailExist(email: string) {
    return this.httpClientWrapper.get<boolean>('/UserApi/CheckEmailExist', { email: encodeURIComponent(email) });
  }
  checkSensitiveKeyword(content: string) {
    return this.httpClientWrapper.get<boolean>('/ContentValidateApi/CheckSensitiveKeyword', { content });
  }

  // others
  resendEmailConfirmation() {
    return this.httpClientWrapper.post<true>('/AccountApi/ResendEmailConfirmation', {});
  }
  changePassword(payload: { OldPassword: string, NewPassword: string, ConfirmPassword: string }) {
    return this.httpClientWrapper.post<true>('/AccountApi/ChangePassword', payload);
  }
  changeEmail(payload: { NewEmail: string }) {
    return this.httpClientWrapper.post<true>('/AccountApi/ChangeEmail', payload);
  }


  // sub-account
  searchSubClientUser(keyword = '', userStatus = 1, pageNum = 1, pageSize = 20) {
    return this.httpClientWrapper.get<IPaginationWrapper<IUserProfileModel>>(
      '/UserApi/SearchSubClientUser',
      {
        keyword, userStatus, pageNum, pageSize
      },
      {
        resultProcessor: (page) => {
          page.Data.map(profile => profile || {} as any);
          return page;
        }
      }
    );
  }
  addSubAccount(user: IUserProfileModel, password: string, passwordConfirm) {
    return this.httpClientWrapper.post<IUserProfileModel>(
      '/RegisterApi/CreateClientSubAccountUser',
      {
        form: user,
        password,
        passwordConfirm
      },
      {
        payloadProcessor: (raw) => {
          this._preProcessLangType(raw.form);
          return raw;
        },
        resultProcessor: (userProfile) => {
          return userProfile || {};
        }
      }
    );
  }
  getSubAccountPermission(userName: string) {
    return this.httpClientWrapper.get<ISubAccountPermissionViewModel>(
      '/UserApi/GetSubAccountPermission',{ userName }
    );
  }
  updateSubAccountPermission(model: ISubAccountPermissionViewModel) {
    return this.httpClientWrapper.post<ISubAccountPermissionViewModel>(
      '/UserApi/UpdateSubAccountPermission',{ model }
    );
  }

  getUserPointsTradeRecords(pageNum = 1, pageSize = 10) {
    return this.httpClientWrapper.post<IPaginationWrapper<ITransactionRecord>>('/UserPointsApi/GetUserPointsTradeRecords', {
      statues: [RedeemRecordStatusEnum.Confirmed, RedeemRecordStatusEnum.Failed],
      pageNum, pageSize
    });
  }

  /**
   * 兑换签到积分
   */
  redeemCheckinPoints() {
    return this.httpClientWrapper.post<IDailyRedeemInfoModel>('/UserPointsApi/RedeemSignInPoints', {});
  }

  getCheckinPointsInfo() {
    return this.httpClientWrapper.get<IDailyRedeemInfoModel>('/UserPointsApi/GetCheckinPointsInfo');
  }

  private _preProcessLangType(userProfile: IUserProfileModel) {
    let lang = 1;
    switch (userProfile.Language) {
      case LanguageType.en_US: {
        lang = 1;
        break;
      }
      case LanguageType.zh_CN: {
        lang = 2;
        break;
      }
    }
    userProfile.Language = lang as any;
  }

}

export const userService = new UserService();
