import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import {
  AvailableNationalities,
  HotNationalityList,
  Nationality,
  NationalityHelper
} from '@dida-shopping/dida-services/nationality';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { BehaviorManagementService } from '@dida-shopping/ngx-dida-site-core';
import {
  ComponentBehaviorCategory,
  BehaviorActionType
} from '@dida-shopping/dida-services/bm';

@Component({
  selector: 'nd-nationality-select',
  templateUrl: './nationality-select.component.html',
  styleUrls: ['./nationality-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DidaUITKNationalitySelectComponent {
  @Input() ndLang: LanguageType = LanguageType.en_US;

  @Input() ndNationality: Nationality;
  @Output() ndNationalityChange = new EventEmitter<Nationality>();

  @Output()
  ndSelect = new EventEmitter<Nationality>();

  @ViewChild('input', { static: true })
  inputElement: ElementRef;
  @ViewChild('list', { static: true })
  listElement: ElementRef;

  searchKeyword: string;
  nationality = {
    hot: HotNationalityList,
    normal: AvailableNationalities
  };

  constructor(
    private bmService: BehaviorManagementService,
    private changeRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  onKeywordChange(searchKeyword: string) {
    searchKeyword = (searchKeyword || '').trim().toLocaleLowerCase();
    let nationalities = AvailableNationalities;
    if (searchKeyword) {
      nationalities = AvailableNationalities.filter(na => {
        const searchText = na.code + na.displayName(this.ndLang);
        const matched =
          searchText.toLocaleLowerCase().indexOf(searchKeyword) > -1;
        return matched;
      });
    }
    this.nationality.normal = nationalities.sort((a1, a2) =>
      a1.code.localeCompare(a2.code)
    );
    if (this.listElement) {
      this.renderer.setProperty(this.listElement.nativeElement, 'scrollTop' , 0);
    }
  }

  convert(items: any): Nationality[] {
    return items as Nationality[];
  }

  onSelect(na: Nationality) {
    if (!this.ndNationality || this.ndNationality.code !== na.code) {
      this.bmService.trackEvent({
        category: ComponentBehaviorCategory.NationalityPicker,
        action: BehaviorActionType.Select,
        label: `${na.code}`,
        value: 1
      });
      this.ndNationality = na;
      this.ndNationalityChange.emit(na);
    }
    this.ndSelect.next(na);
  }

  isSelected(na: Nationality): boolean {
    return this.ndNationality && this.ndNationality.code === na.code;
  }

  getDisplayName(na: Nationality): string {
    return na.displayName(this.ndLang);
  }

  focus() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus()
    }
  }
}
