import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import { DidaTabsCascaderConfig } from './tabs-cascader.model';
import { BehaviorManagementService } from '@dida-shopping/ngx-dida-site-core';
import { ComponentBehaviorCategory, SuggestionTypeaheadActionType } from '@dida-shopping/dida-services/bm';
import { ObjectHelper } from '@dida-shopping/dida-services/common';

@Component({
  selector: 'nd-tabs-cascader',
  templateUrl: './tabs-cascader.component.html',
  styleUrls: ['./tabs-cascader.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DidaUITKTabsCascaderComponent),
    multi: true
  }]
})
export class DidaUITKTabsCascaderComponent implements ControlValueAccessor {

  // input & output

  @Input() ndConfig: DidaTabsCascaderConfig;
  @Output() ndChange = new EventEmitter<any[]>();

  @Input() ndExtra: TemplateRef<any>

  // public properties

  public selectedOptions: any[] = [];

  // private fields

  private _value: any[] = [];
  private _propagateChange: (value: any) => void;

  constructor(
    private bmService: BehaviorManagementService,
  ) {}

  // public methods

  optionClick(type: number, option: any, index: number, childIndex: number, event: Event): void {
    const currentOption = this._getSelectedOption(type);
    if (currentOption !== option) {
      if (type === 0) {
        this.selectedOptions = [ this.ndConfig.options[index] ];
        this._value = [ this.selectedOptions[0][this.ndConfig.valueProperty] ];
        this.bmService.trackEvent({
          category: ComponentBehaviorCategory.SuggestionTypeahead,
          action: SuggestionTypeaheadActionType.SelectRegionTab,
          label: `TabRegionID_${this.selectedOptions[0][this.ndConfig.valueProperty].id}`,
          value: 1,
        });
      } else if (type === 1) {
        this.selectedOptions[1] = this.selectedOptions[0].children[index];
        this._value[1] = this.selectedOptions[1][this.ndConfig.valueProperty];
        this.bmService.trackEvent({
          category: ComponentBehaviorCategory.SuggestionTypeahead,
          action: SuggestionTypeaheadActionType.SelectHotSearchRegion,
          label: `RegionID_${this.selectedOptions[1][this.ndConfig.valueProperty].id}`,
          value: 1,
        });
      } else if (type === 2) {
        this.selectedOptions[1] = this.selectedOptions[0].children[index].children[childIndex];
        this._value[1] = this.selectedOptions[1][this.ndConfig.valueProperty];
        this.bmService.trackEvent({
          category: ComponentBehaviorCategory.SuggestionTypeahead,
          action: SuggestionTypeaheadActionType.SelectHotSearchRegion,
          label: `AirportCode_${this.selectedOptions[1][this.ndConfig.valueProperty].airportCode}`,
          value: 1,
        });
      }
      this._propagateChange(this._value);
      this._valueChange();
    }
  }

  writeValue(value: any): void {
    this._value = [];
    this.selectedOptions = [];
    ObjectHelper.toArray(value).forEach((v: any, index: number) => {
      this._value.push(v);
      this.selectedOptions[index] = this.ndConfig.options.find((element) => {
        return element[this.ndConfig.valueProperty] === v;
      });
    });
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any) {}


  // presentation logic
  getWidth() {
    return this.ndConfig.width ? this.ndConfig.width + 'px' : '100%';
  }

  getHeight() {
    return this.ndConfig.height ? this.ndConfig.height + 'px' : 'auto';
  }

  isActive(type: number, option: any) {
    return this._getSelectedOption(type) === option;
  }

  isShowChildren() {
    return this.selectedOptions.length >= 1;
  }

  get isGroupMode(): boolean {
    return this.ndConfig.groupMode === true;
  }

  // private methods
  private _valueChange(): void {
    this.ndChange.emit(this._value);
  }

  private _getSelectedOption(type: number): any {
    if (this.selectedOptions == null || this.selectedOptions.length <= type) {
      return null;
    } else {
      return this.selectedOptions[type];
    }
  }

}
