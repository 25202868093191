import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { APP_CONTEXT_STORAGE, IApplicationContextStorage } from '@dida-shopping/dida-services/storage';
import { ApplicationEventHub } from '../events/dida-event-hubs';
import { applicationContextService } from '@dida-shopping/dida-services/auth';
import { IDidaApplicationUserContextModel } from '@dida-shopping/dida-services/auth';
import { ngxHttpCall } from '../http';
import { SERVER_CONFIG } from '../app-server-config';
import { APP_VERSION } from '../app-verison';
import {
  ApplicationClientInfoChangeEvent,
  ApplicationContextUpdateEvent,
  ApplicationCurrencyChangeEvent,
  ApplicationExperimentInfoChangeEvent, ApplicationLanguageChangeEvent
} from '../events';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { userService, clientService } from '@dida-shopping/dida-services/membership';
import { map } from 'rxjs/operators';
import { DIDA_APP_NAME } from '../routing';


export const DEFAULT_CONTEXT_VALID_TIME_SEC = 10;

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private _ctxValidTimeForRouting: Date;

  get isContextValid(): boolean {
    return  this._ctxValidTimeForRouting && this._ctxValidTimeForRouting > new Date();
  }

  get context(): IDidaApplicationUserContextModel {
    return APP_CONTEXT_STORAGE.context;
  }

  get storage(): IApplicationContextStorage {
    return APP_CONTEXT_STORAGE;
  }

  constructor(
    @Inject(DIDA_APP_NAME) private appName: string,
    @Inject(LOCALE_ID) private locale: 'en-US' | 'zh-CN',
    private appEventHub: ApplicationEventHub
  ) {

  }


  switchLanguage(lang: LanguageType) {
    const hasUserLogin = this.context && this.context.userIdentityInfo && this.context.userIdentityInfo.isAuthenticated;
    let success = false;
    ngxHttpCall(
      hasUserLogin ?
        userService.updateLanguageSetting(lang) :
        applicationContextService.switchLanguage(lang)
    ).subscribe({
      next: () => {
        success = true;
      },
      error: (err) => {
        console.error(err);
      }
    }).add(() => {
      if (success) {
        if (APP_CONTEXT_STORAGE.context) {
          APP_CONTEXT_STORAGE.context.lang = lang;
        }
        // Roman: There's no need to broadcast an event since the page will reload.
        // this.appEventHub.broadcast(new ApplicationLanguageChangeEvent(
        //   lang, `[ApplicationService::switchLanguage]`, new Date())
        // );
        window.location.reload();
      }
    });
  }

  updateCurrencySetting(currencyCode: string) {
    return ngxHttpCall(
      userService.updateCurrencySetting(currencyCode)
    ).pipe(
      map(info => {
        APP_CONTEXT_STORAGE.context.currencyInfo = info;
        this.appEventHub.broadcast(new ApplicationCurrencyChangeEvent(
          info, `[ApplicationService::updateCurrencySetting]`, new Date()
        ));
        return info;
      })
    );
  }

  reloadContext() {
    return ngxHttpCall(
      applicationContextService.getContext(
        this.appName,
        this.updateApplicationContext.bind(this))
    );
  }

  reloadUserPointsAccount(fromCache = false) {
    return ngxHttpCall(
      userService.getUserPointsAccount(fromCache)
    ).pipe(
      map(pointAccountInfo => {
        APP_CONTEXT_STORAGE.context.userPointAccountInfo = pointAccountInfo;
        return pointAccountInfo;
      })
    )
  }

  reloadClientInfo() {
    return ngxHttpCall(
      clientService.getClientInfo(true)
    ).pipe(
      map((result) => {
        APP_CONTEXT_STORAGE.userIdentityInfo.clientInfo = result;
        this.appEventHub.broadcast(new ApplicationClientInfoChangeEvent(
          result, `[ApplicationService::reloadClientInfo]`, new Date()
        ));
        return result;
          // let msg = new AppMessage(GlobalMessageTypeEnum.ClientInfoUpdate, {
          //   result
          // });
          // ApplicationContextService.Context.userIdentityInfo.clientInfo = result;
          // this.actionService.emit(msg);
          // return result;
      })
    )
  }

  updateApplicationContext(ctx: IDidaApplicationUserContextModel) {
    const result = APP_CONTEXT_STORAGE.set(ctx, this.locale);

    this._ctxValidTimeForRouting = DateTimeHelper.addSeconds(new Date(), DEFAULT_CONTEXT_VALID_TIME_SEC);

    if (result.experimentChanged) {
      this.appEventHub.broadcast(new ApplicationExperimentInfoChangeEvent(
        ctx && ctx.rolledOutExperiments,
        ctx && ctx.userExperiments,
        ctx && ctx.clientExperiments,
        `[ApplicationService::updateApplicationContext]`,
        new Date()
      ));
    }

    // application version validation
    if (!SERVER_CONFIG.ignoreBuildNumber &&
      ctx.fe_version != null &&
      APP_VERSION.buildNumber != null &&
      APP_VERSION.buildNumber !== ctx.fe_version) {
      // version not match
      if (!ctx.fe_deploying && ctx.fe_reload) {
        // reload current page immediately if not from route
        window.top.location.reload();
        return;
      }
    }

    if (result.langChanged) {
      this.appEventHub.broadcast(new ApplicationLanguageChangeEvent(
        ctx && ctx.lang, `[ApplicationService::preProcessApplicationContext]`, new Date()
      ));
    }

    try {
      if (result.currencyChanged) {
        this.appEventHub.broadcast(new ApplicationCurrencyChangeEvent(
          ctx && ctx.currencyInfo, `[ApplicationService::preProcessApplicationContext]`, new Date()
        ));
      }
      if (result.success) {
        this.appEventHub.broadcast(new ApplicationContextUpdateEvent(
          ctx, `[ApplicationService::preProcessApplicationContext]`, new Date()
        ));
      }
    } catch (e) {
      // escape error catcher
    }
  }

  expireApplicationContextValidTimeForRoute() {
    this._ctxValidTimeForRouting = DateTimeHelper.addSeconds(new Date(), -1000);
  }

  // hasAnyExperiment() {
  //
  // }
}
