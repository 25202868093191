export enum LogLevelType {
  trace = 0,
  debug = 1,
  info = 2,
  warn = 3,
  error = 4,
  fatal = 5
}

export interface IClientSideLog {
  message: string;
  level: LogLevelType;
  url: string;
  time: string;
  stack?: string;
  version?: string;
  context?: { [prop: string]: any };
}
