import { HttpServiceBase } from '../http';
import { IPasswordResetModel, IUserLoginCredential } from '../auth/models';

class AuthenticationService extends HttpServiceBase {

  constructor() {
    super('AuthenticationService');
  }

  login(credential: IUserLoginCredential) {
    return this.httpClientWrapper.post<any>('/AccountApi/Login', credential);
  }

  logout() {
    return this.httpClientWrapper.post<any>('/AccountApi/Logoff', null);
  }

  /**
   * 找回密码
   * @param email 已验证的注册邮箱
   */
  findPassword(email: string) {
    return this.httpClientWrapper.post<boolean>('/AccountApi/FindPassword', {
      Email: email
    });
  }

  /**
   * 重置密码
   * @param model
   */
  resetPassword(model: IPasswordResetModel) {
    return this.httpClientWrapper.post<boolean>('/AccountApi/ResetPassword', model);
  }
}

export const authenticationService = new AuthenticationService();
