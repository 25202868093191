<ng-container *ngIf="advertisement">
    <div class="advertise-section animated {{!inited ? '' : (adExpanded? 'slideInLeft':'slideOutLeft')}}">
        <div class="container">
            <div class="advertise-container">
                <a [href]="advertisement.ReferenceUrl">
                    <app-img routerLink="" [pure]="true" class="advertise" [src]="advertisement.ImgUrl"></app-img>
                </a>
                <div class="ad-dismiss" (click)="toggleAd()">&times;</div>
            </div>
        </div>
    </div>
    <div class="ad-bullet animated {{!inited ? '' : (!adExpanded? 'slideInLeft':'slideOutLeft')}}" (click)="toggleAd()"></div>
</ng-container>
<div class="account-notice" *ngIf="appContext?.isTestClientAccount">
    <ng-container i18n="@@Text_Notice_TestClientAccountDesc">您当前的账号是测试账号，所有的入住订单为测试订单，如需真实可入住的订单，请联系相关负责人，获取正式账号。</ng-container>
</div>
