import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HighlightParams } from './highlight.model';
import { StringHelper, KeywordHelper } from '@dida-shopping/dida-services/common';

@Pipe({
  name: 'ndHighlight'
})

@Injectable()
export class DidaUITKHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string, args: HighlightParams): any {
    if (!args.isDisabled && value) {
      const tag = args.tagName ? args.tagName : 'span';
      const className = args.className || 'nd-text-active';
      if (args.keyword) {
        const temp = [];
        KeywordHelper.getMatchs(value, args.keyword, true).forEach(m => {
          if (m.key) {
            const arr = StringHelper.split(m.word, m.key, true);
            const tempValue = arr.map(item => {
              return item.isKey ? `<${tag} class="${className}">${item.str}</${tag}>` : item.str;
            }).join('');
            temp.push(tempValue);
          } else {
            temp.push(m.word);
          }
          value = temp.join(' ');
        });
      } else {
        value = `<${tag} class="${className}">${value}</${tag}>`;
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

