import { HttpServiceBase } from '../http';
import { IRegionHotelRadarSearchOption, IRegionHotelRadarSearchResult, IUserFavoriteHotelDetail } from './models';
import { SuggestionItem } from '@dida-shopping/dida-services/suggestions';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';

class UserHotelService extends HttpServiceBase {
  constructor() {
    super();
  }

  /**
   * 获取用户收藏酒店信息
   */
  getFavoriteHotels() {
    return this.httpClientWrapper.get<IUserFavoriteHotelDetail[]>(
      '/UserFavoriteHotelApi/Get'
    );
  }

  /**
   * 添加收藏酒店
   * @param hotelId
   */
  addFavoriteHotel(hotelId: number) {
    return this.httpClientWrapper.post<IUserFavoriteHotelDetail[]>(
      '/UserFavoriteHotelApi/Add',
      {
        hotelId,
        option: null /*不传option时必须设为null, 否则后台报错*/
      }
    );
  }

  /**
   * 移除收藏酒店
   * @param hotelId
   */
  removeFavoriteHotel(hotelId: number) {
    return this.httpClientWrapper.post<IUserFavoriteHotelDetail[]>(
      '/UserFavoriteHotelApi/Remove',
      {
        hotelId,
        option: null /*不传option时必须设为null, 否则后台报错*/
      }
    );
  }

  /**
   *
   */
  getUserRegionSearchHistory() {
    return this.httpClientWrapper.get<SuggestionItem[]>('/HotelApi/GetUserRegionSearchHistory');
  }

  /**
   *
   * @param {IRegionHotelRadarSearchOption} options
   */
  getRegionHotelRadarData(options: IRegionHotelRadarSearchOption) {
    return this.httpClientWrapper.get<IRegionHotelRadarSearchResult>('/HotelApi/GetRegionHotelRadarData', { options },
      {
        resultProcessor(resp: IRegionHotelRadarSearchResult) {
          resp.CheckInDate = DateTimeHelper.parse(resp.CheckInDate, true);
          resp.CheckOutDate = DateTimeHelper.parse(resp.CheckOutDate, true);
          // [resp.FeaturedRadar, resp.HotSellRadar, ...resp.StarRatingRadarList].map(item => {
          //   if (item) {
          //     item.CheckInDate = options.CheckInDate;
          //     item.CheckOutDate = options.CheckOutDate;
          //   }
          // });
          return resp;
        }
      });
  }
}

export const userHotelService = new UserHotelService();
