<nav class="navbar navbar-default" [class.navbar_with-search-form]="isShowSearchForm">
  <div class="container">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <!-- <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" aria-expanded="false" ng-click="isNavCollapsed = !isNavCollapsed">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
      </button> -->
      <a class="navbar-brand" routerLink="/home">
        <div class="img">LVZAN</div>
      </a>
    </div>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" *ngIf="initialized">
      <!-- Nav Left -->
      <ul class="nav navbar-nav">
        <ng-container *ngIf="!isHideNav">
          <ng-container *ngIf="hasUserLogin && userIdentity.profile.ClientID && userIdentity.profile.EmailConfirmed">
            <!-- 预订酒店 -->
            <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <a routerLink="/hotel/find" i18n="@@Text_Nav_HotelFind">预订酒店</a>
            </li>
            <ng-container *ngIf="isCN">
              <!-- 接送机 -->
              <li
                routerLinkActive="active"
                *didaExperiment="transferOrderControl"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a routerLink="/transfer" i18n="@@Text_Nav_TransferService">接送机</a>
                <span class="navbar__img-label navbar__img-label_new"></span>
                <!-- <span class="navbar__label navbar__label_new">NEW</span> -->
              </li>
            </ng-container>
            <!-- 订单管理 -->
            <li
              dropdown
              #orderDropdown="bs-dropdown"
              triggers="mouseover"
              routerLinkActive="active"
              (mouseenter)="enterNavDropdown('orderDropdown', orderDropdown)"
              (mouseleave)="closeNavDropdown('orderDropdown', orderDropdown)"
            >
              <a href="javascript:;" (click)="(false)" dropdownToggle>
                <span i18n="@@Title_BookingManagement">订单管理</span>
                <!--                <span class="dida-icon dida-icon-dropdown dida-icon-dropdown_sm"-->
                <!--                      [class.dida-icon-dropdown_opened]="orderDropdown.isOpen"></span>-->
                <!-- Hidden anchors - to trigger upper level router link active -->
                <a [hidden]="true" routerLink="/booking"></a>
                <a [hidden]="true" routerLink="/ticket/list"></a>
                <a [hidden]="true" routerLink="/bill"></a>
                <a [hidden]="true" routerLink="/invoice"></a>
                <a [hidden]="true" routerLink="/transfer/booking"></a>
              </a>
              <ul
                *dropdownMenu
                class="dropdown-menu"
                #orderDropdownMenu
                (mouseenter)="enterNavDropdown('orderDropdown', orderDropdown)"
                (mouseleave)="leaveNavDropdown('orderDropdown', orderDropdown)"
              >
                <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <a class="dropdown-item" i18n="@@Title_BookingManagement" routerLink="/booking">订单管理</a>
                </li>
                <ng-container *didaExperiment="workOrderControl">
                  <ng-container *ngIf="isCN">
                    <li class="divider dropdown-divider"></li>
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <a class="dropdown-item" routerLink="/ticket/list" i18n="@@Text_Nav_TicketManagement">工单管理</a>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *didaExperiment="settlementControl">
                  <ng-container *ngIf="isCN">
                    <li class="divider dropdown-divider"></li>
                    <li
                      style="position: relative"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: false }"
                    >
                      <a class="dropdown-item" routerLink="/bill" i18n="@@Text_Nav_BillManagement">账单管理</a>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *didaExperiment="invoiceControl">
                  <ng-container *ngIf="isCN">
                    <li class="divider dropdown-divider"></li>
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
                      <a class="dropdown-item" routerLink="/invoice" i18n="@@Text_Nav_InvoiceManagement">发票管理</a>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *didaExperiment="transferFeatureControl">
                  <ng-container *ngIf="isCN">
                    <li class="divider dropdown-divider"></li>
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <a class="dropdown-item" i18n="@@Title_TransferBookingManagement" routerLink="/transfer/booking"
                        >接送机订单管理</a
                      >
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </li>
            <!-- 积分商城 -->
            <li
              routerLinkActive="active"
              *didaExperiment="userPointsControl"
              [hidden]="!isCN"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a routerLink="/point-market" i18n="@@Label_PointsMarket">积分商城</a>
            </li>
            <!-- 搜索酒店 -->
            <!--            <li>-->
            <!--              <a class="btn btn-primary _dida-submit-button_inverse">-->
            <!--               <span class="glyphicon glyphicon-search"></span> 搜索酒店-->
            <!--              </a>-->
            <!--            </li>-->
          </ng-container>

          <ng-container *ngIf="!hasUserLogin">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <a routerLink="/home" i18n="@@Text_Nav_Home">首页</a>
            </li>
            <li class="visible-md visible-lg">
              <a routerLink="/home/news" i18n="@@Text_Nav_Bulletin">公告栏</a>
            </li>
            <ng-container *ngIf="isHomePage">
              <li class="visible-md visible-lg">
                <a offset="70" routerLink="/home" fragment="partner_section" i18n="@@Text_Nav_Partners">合作伙伴</a>
              </li>
              <li class="visible-md visible-lg">
                <a offset="70" routerLink="/home" fragment="honor_section" i18n="@@Text_Nav_HonorRecords">获奖记录</a>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- 活动入口 -->
      </ul>
      <!-- Nav Right  -->
      <ul class="nav navbar-nav navbar-right">
        <ng-container *ngIf="!hasUserLogin">
          <!-- <li>
            <a href="" (click)="switchLanguage($event)">{{ isCN ? "English" : "中文" }}</a>
          </li> -->
          <li class="user-profile">
            <a
              href="javascript:;"
              #loginoutPopover="bs-popover"
              [popover]="loginoutTemplate"
              triggers="click"
              placement="bottom"
              outsideClick="true"
            >
              <ng-container i18n="@@Text_Nav_SignInOrRegister">登录/注册</ng-container>
              <span
                class="dida-icon dida-icon-dropdown dida-icon-dropdown_sm"
                [class.dida-icon-dropdown_opened]="loginoutPopover.isOpen"
              ></span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="hasUserLogin">
          <!-- 货币设置 -->
          <!-- <li *ngIf="isShowCurrencySetting" class="visible-md visible-lg">
            <a href="javascript:;" #currencyPopover="bs-popover" [popover]="currencyTemplate" triggers="click"
               placement="bottom" outsideClick="true">
              {{ currencyInfo.Code }}
              <span class="dida-icon dida-icon-dropdown dida-icon-dropdown_sm"
                    [class.dida-icon-dropdown_opened]="currencyPopover.isOpen"></span>
            </a>
          </li> -->
          <li class="user-profile">
            <app-badge [value]="unReadMessageCount"></app-badge>
            <a
              href="javascript:;"
              #userProfilePopover="bs-popover"
              [popover]="userIdentityTemplate"
              triggers="click"
              placement="bottom"
              outsideClick="true"
              >{{ userIdentity.profile.UserName }}
              <span
                class="dida-icon dida-icon-dropdown dida-icon-dropdown_sm"
                [class.dida-icon-dropdown_opened]="userProfilePopover.isOpen"
              ></span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- /.container-fluid -->
  <div class="container search-form" *ngIf="initialized && isShowSearchForm">
    <div class="form-inline">
      <div class="form-group">
        <nd-suggestion-typeahead [ndConfig]="suggestionTypeaheadConfig" (ndChange)="destinationChange($event)">
        </nd-suggestion-typeahead>
      </div>
      <div class="form-group">
        <dida-date-range-picker
          (onChange)="dateRangeChange($event)"
          [style]="'mini'"
          [showLabel]="false"
          [fromDate]="searchPayload.checkInDate"
          [toDate]="searchPayload.checkOutDate"
        >
        </dida-date-range-picker>
      </div>
    </div>
    <span>&nbsp;</span>
    <a
      routerLink="/hotel/list"
      [queryParams]="searchParams"
      class="btn btn-sm btn-primary"
      i18n="查询@@Text_Button_HotelSearch_Search"
      >查&nbsp;询</a
    >
    <a
      #hotelDetailLink
      class="hide"
      [routerLink]="['/hotel/detail', this.searchPayload.hotelID || '']"
      [queryParams]="searchParams"
    ></a>
  </div>
</nav>

<nd-navbar-notice-modal-wrapper></nd-navbar-notice-modal-wrapper>

<ng-template #loginoutTemplate>
  <form action="" (submit)="login()" class="login-form">
    <p class="form-control-error" *ngIf="loginError">
      <app-login-error [error]="loginError"></app-login-error>
    </p>
    <div class="form-group form-group-lg">
      <div class="input-group">
        <div class="icon icon-user"></div>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="loginInfo.LoginIdentifier"
          i18n-placeholder="@@Text_Placeholder_UsernameOrEmail"
          placeholder="用户名/邮箱"
        />
        <div
          class="dida-icon input-clean-button dida-icon-turn-off"
          [hidden]="!loginInfo.LoginIdentifier"
          (click)="cleanKeywordInputText(true)"
        ></div>
      </div>
    </div>

    <div class="form-group form-group-lg">
      <div class="input-group">
        <div class="icon icon-lock"></div>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="loginInfo.Password"
          i18n-placeholder="@@Text_Placeholder_Password"
          placeholder="密码"
        />
        <div
          class="dida-icon input-clean-button dida-icon-turn-off"
          [hidden]="!loginInfo.Password"
          (click)="cleanKeywordInputText(false)"
        ></div>
      </div>
    </div>
    <div class="">
      <a routerLink="/account/find-password" class="text-highlight pull-right" i18n="@@Text_ForgetPassword"
        >忘记密码？</a
      >
      <dida-checkbox [(ngModel)]="loginInfo.RememberMe" name="rememberme" class="pull-left" style="height: 20px">
        <ng-container i18n="@@Text_RememberMe">记住我</ng-container>
      </dida-checkbox>
      <div class="clearfix"></div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-block" type="submit" [disabled]="loginStatus !== LoginStatus.INIT">
        <ng-container *ngIf="loginStatus == LoginStatus.INIT" i18n="@@Label_Login">登录</ng-container>
        <ng-container *ngIf="loginStatus == LoginStatus.LOGINING" i18n="@@Text_Logining">正在登录...</ng-container>
        <ng-container *ngIf="loginStatus == LoginStatus.LOGINED" i18n="@@Text_LoginSuccess">登录成功</ng-container>
      </button>
    </div>
    <div class="">
      <span i18n="@@Text_Question_NoAccount">没有账号？</span>
      <a class="active" routerLink="/account/register" i18n="@@Text_Register">注册</a>
    </div>
  </form>
</ng-template>

<ng-template #userIdentityTemplate>
  <ul class="user-profile-menu">
    <li class="item">
      <p class="item-label" i18n="@@Text_Title_AccountInfo">账户信息</p>
      <span class="h5">
        <span>{{ userIdentity.profile.FirstName }} {{ userIdentity.profile.LastName }}</span>
        <span class="pull-right" *didaExperiment="[ExperimentID.AllowViewTestingFeature]">
          <a href="javascript:;" (click)="swithUserLanguage()">{{ isCN ? 'Switch English' : '切换中文' }}</a>
        </span>
      </span>
    </li>
    <li class="item" *ngIf="userIdentity.clientInfo">
      <p class="item-label" i18n="@@Text_Title_AccountBalance">账户余额</p>
      <span class="h4 text-highlight">
        <strong>{{ userIdentity.clientInfo.ClientAccount.Balance | number: '0.0-2' }}</strong>
        <span class="pull-right">
          <a routerLink="/account/manage/client/balance" (click)="userProfilePopover.hide()">
            <small class="text-highlight" i18n="@@Text_ViewDetail">查看明细</small>
          </a>
        </span>
      </span>
    </li>
    <li class="item" *ngIf="userIdentity.clientInfo">
      <p class="item-label">
        <span i18n="@@Text_Title_ClientName">单位名称</span> ({{ userIdentity.clientInfo.ClientID }})
      </p>
      <span class="h5">{{ userIdentity.clientInfo.Name }}</span>
    </li>
    <ng-container *didaExperiment="userPointsControl">
      <li class="item" *ngIf="isCN && appContext.userPointAccountInfo">
        <p class="item-label" i18n="@@Text_Title_DidaPoints">旅赞积分</p>
        <span class="h4 text-highlight">
          {{ appContext.userPointAccountInfo.Balance | number }}
          <span class="pull-right">
            <a routerLink="/account/manage/points" (click)="userProfilePopover.hide()">
              <small class="text-highlight" i18n="@@Title_MyPoints">我的积分</small>
            </a>
          </span>
        </span>
      </li>
    </ng-container>
    <ng-container *ngIf="showCouponTag">
      <li class="item">
        <p class="item-label" i18n="@@Title_Coupon">优惠券</p>
        <span class="h4 text-highlight">
          {{ activeCouponCount | number }}
          <span class="pull-right">
            <a routerLink="/account/manage/coupon" (click)="userProfilePopover.hide()">
              <small class="text-highlight" i18n="@@Title_MyCoupons">我的优惠券</small>
            </a>
          </span>
        </span>
      </li>
    </ng-container>
    <li class="item item-utils">
      <div class="row text-center" (click)="userProfilePopover.hide()">
        <a
          [class.col-md-3]="showDataStatistics"
          [class.col-md-4]="!showDataStatistics"
          class="h5 icon-wrapper"
          routerLink="/account/message-center"
          *ngIf="userIdentity.profile.ClientID"
          i18n-title="@@Text_Title_Message"
          title="消息"
        >
          <span class="icon icon-profile-dropdown icon-profile-dropdown_ring"></span>
          <br />
          <span i18n="@@Text_Title_Message">消息</span>
          <app-badge [value]="unReadMessageCount" style="position: absolute; top: -10px"></app-badge>
        </a>
        <a
          [class.col-md-3]="showDataStatistics"
          [class.col-md-4]="!showDataStatistics"
          class="h5 icon-wrapper"
          routerLink="/account/manage"
          *ngIf="userIdentity.profile"
          i18n-title="@@Title_AccountCenter"
          title="个人中心"
        >
          <span class="icon icon-profile-dropdown icon-profile-dropdown_setting"></span>
          <br />
          <span i18n="@@Title_AccountCenter">个人中心</span>
        </a>
        <ng-container *ngIf="showDataStatistics">
          <a
            class="h5 col-md-3 icon-wrapper"
            routerLink="/account/data-center"
            *ngIf="userIdentity.profile"
            i18n-title="@@Label_DataStatistics"
            title="数据中心"
          >
            <span class="icon icon-profile-dropdown icon-profile-dropdown_statistics"></span>
            <br />
            <span i18n="@@Label_DataStatistics">数据中心</span>
          </a>
        </ng-container>
        <a
          [class.col-md-3]="showDataStatistics"
          [class.col-md-4]="!showDataStatistics"
          class="h5 icon-wrapper"
          href="javascript:;"
          (click)="logout()"
        >
          <span class="icon icon-profile-dropdown icon-profile-dropdown_exit"></span>
          <br />
          <span i18n="@@Text_Title_Exit">退出</span>
        </a>
      </div>
    </li>
  </ul>
</ng-template>
<ng-template #currencyTemplate>
  <div class="currency-container h5">
    <h4 i18n="@@Label_HotCurrency">常用币种</h4>
    <hr />
    <div class="row currency-list">
      <a
        href="javascript:;"
        class="col-sm-4 currency-item"
        *ngFor="let currency of hotCurrencyList"
        [ngClass]="{ active: currencyInfo && currencyInfo.Code == currency }"
        (click)="changeCurrency(currency)"
        >{{ currency }}</a
      >
    </div>
    <h4 i18n="@@Label_AllCurrency">所有币种</h4>
    <hr />
    <div class="row currency-list">
      <a
        href="javascript:;"
        class="col-sm-4 currency-item"
        *ngFor="let currency of appContext.currencyList"
        [ngClass]="{ active: currencyInfo && currencyInfo.Code == currency }"
        (click)="changeCurrency(currency)"
        >{{ currency }}</a
      >
    </div>
  </div>
</ng-template>
