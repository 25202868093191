import { Injectable } from '@angular/core';
import { FavoriteHotelEventHub, FavoriteHotelUpdateEvent } from '../events';
import { AbstractRunnableService } from './runnable-service.base';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IUserFavoriteHotelDetail, userHotelService } from '@dida-shopping/dida-services/hotel';
import { USER_FAVORITE_HOTEL_STORAGE } from '@dida-shopping/dida-services/storage';
import { ngxHttpCall } from '../http';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';

@Injectable({
  providedIn: 'root'
})
export class UserFavoriteHotelService extends AbstractRunnableService {
  static readonly MAX_CACHE_VALID_SEC: number = 60; // 缓存有效一分钟
  static readonly CACHE_RELOAD_INTERVAL_SEC: number = 60; // 每分钟刷新一次

  private _currentSubscription: Subscription

  get favoriteHotelCount(): number {
    return USER_FAVORITE_HOTEL_STORAGE.getFavoriteHotelCount();
  }

  get lastCacheTime(): Date {
    return USER_FAVORITE_HOTEL_STORAGE.getCacheTime();
  }

  constructor(
    private favoriteHotelEventHub: FavoriteHotelEventHub
  ) {
    super(UserFavoriteHotelService.CACHE_RELOAD_INTERVAL_SEC);
  }

  protected execute(): Subscription {
    return this.loadUserFavoriteHotels();
  }

  isCacheValidBy(time: Date): boolean {
    const validCacheTime = DateTimeHelper.addSeconds(this.lastCacheTime, UserFavoriteHotelService.MAX_CACHE_VALID_SEC);
    return time <= validCacheTime;
  }

  getUserFavoriteHotels(): IUserFavoriteHotelDetail[] {
    return USER_FAVORITE_HOTEL_STORAGE.getFavoriteHotels();
  }

  addFavoriteHotel(hotelId: number) {
    return ngxHttpCall(
      userHotelService.addFavoriteHotel(hotelId)
    ).subscribe({
      next: (hotels) => {
        this.updateCache(hotels, `[UserFavoriteHotelService::addFavoriteHotel]`, new Date());
      }
    });
  }

  removeFavoriteHotel(hotelId: number) {
    return ngxHttpCall(
      userHotelService.removeFavoriteHotel(hotelId)
    ).subscribe({
      next: (hotels) => {
        this.updateCache(hotels, `[UserFavoriteHotelService::removeFavoriteHotel]`, new Date());
      }
    });
  }

  isFavoriteHotel(hotelId: number) {
    return USER_FAVORITE_HOTEL_STORAGE.isFavoriteHotel(hotelId);
  }

  loadUserFavoriteHotels(ignoreCache = false): Subscription {
    const time = new Date();
    if (!ignoreCache && this.isCacheValidBy(time)) {
      const subject = new BehaviorSubject<boolean>(true);
      subject.next(true);
      subject.complete();
      return subject.subscribe();
    }

    if(!this._currentSubscription) {
      this._currentSubscription = ngxHttpCall(
        userHotelService.getFavoriteHotels()
      ).subscribe({
        next: (hotels) => {
          this.updateCache(hotels, `[UserFavoriteHotelService::loadUserFavoriteHotels]`, new Date());
          this._currentSubscription = null
        }
      })
    }

    return this._currentSubscription
  }

  private updateCache(favoriteHotels: IUserFavoriteHotelDetail[], source: string, time: Date) {
    const result = USER_FAVORITE_HOTEL_STORAGE.setFavoriteHotels(favoriteHotels, time);
    if (result.hasUpdated) {
      this.favoriteHotelEventHub.broadcast(
        new FavoriteHotelUpdateEvent(
          `[UserFavoriteHotelService::loadUserFavoriteHotels]`,
          new Date()
        )
      );
    }
  }
}
