import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import {
  BillPayModel,
  BillDetailModel
} from '../../../shared/models/bill/client-bill-detail-order.model';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { BookingSettlementRequestOptions } from '../../../shared/models/settlement/settlement-request-options.model';
import { HotelBookingSettleResult } from '../../../shared/models/settlement/hotel-booking-settle-result.model';
import { BillModel } from '../../../shared/models/bill/bill-model';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { BillSearchOption } from '../../../shared/models/bill/bill-search-option';
import { BillResult } from '../../../shared/models/bill/bill-result';
import { ClientBillModel } from '../../../shared/models/bill/client-bill-model';
import { DidaShoppingClientBillDetailModel } from '../../../shared/models/bill/client-bill-detail-model';
import { BillBookingDetailModel } from '../../../shared/models/bill/bill-booking-detail-model';

/**
 * 账单数据服务
 *
 * @export
 * @class BillService
 */
@Injectable()
export class BillService {
  constructor(private logger: LoggerService, private httpClient: HttpClient) {}

  /**
   * 获取账单
   *
   * @param {string} billID
   * @returns {HttpObservable<BillPayModel>}
   * @memberof BillService
   */
  getBillDetailOrderInfo(billID: string) {
    return this.httpClient.get<BillPayModel>(
      '/HotelBookingSettlementApi/GetBookingSettlementList',
      {
        billID
      },
      null,
      mdl => {
        if (mdl.BillDetailModel && mdl.BillDetailModel.length > 0) {
          mdl.BillDetailModel.forEach(b => {
            this.billDetailProcessor(b);
          });
        }
      }
    );
  }

  /**
   * 作废账单
   *
   * @param {string} billID
   * @returns {HttpObservable<string>}
   * @memberof BillService
   */
  AbandonBill(billID: string) {
    return this.httpClient.post<BillResult>('/HotelBookingSettlementApi/AbandonBill', {
      billID: billID
    });
  }

  /**
   * 账单查询接口
   *
   * @param {BillSearchOption} option 搜索参数
   * @returns {HttpObservable<PaginationWrapper<DidaClientBillModel>>}
   * @memberof BookingService
   */
  search(
    option: BillSearchOption
  ) {
    return this.httpClient.get<PaginationWrapper<ClientBillModel>>(
      '/HotelBookingSettlementApi/ClientSearchBill',
      {
        searchOptions: option
      },
      null,
      mdl => {
        if (mdl.Data && mdl.Data.length > 0) {
          mdl.Data.forEach(b => {
            this.billModelProcessor(b);
          });
        }
      },
      payload => {
        let opt: BillSearchOption = payload.searchOptions;
        if (opt.DateRange && opt.DateRange.From && opt.DateRange.To) {
          opt.DateRange.From = DateTimeHelper.format(
            opt.DateRange.From,
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
          opt.DateRange.To = DateTimeHelper.format(
            opt.DateRange.To,
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
        }
      }
    );
  }

  /**
   * 账单详情查询接口
   *
   * @param {BillSearchOption} option 搜索参数
   * @returns {HttpObservable<DidaShoppingClientBillDetailModel>}
   * @memberof BookingService
   */
  searchDetail(
    option: BillSearchOption
  ) {
    return this.httpClient.get<DidaShoppingClientBillDetailModel>(
      '/HotelBookingSettlementApi/ClientSearchBillDetail',
      {
        searchOptions: option
      },
      null,
      mdl => {
        if (mdl.ClientBillModel && mdl.BillBookingDetailList.length > 0) {
          this.billModelProcessor(mdl.ClientBillModel);
          mdl.BillBookingDetailList.forEach(b => {
            this.BillBookingDetailProcessor(b);
          });
        }
      },
      payload => {
        let opt: BillSearchOption = payload.searchOptions;
        if (opt.DateRange && opt.DateRange.From && opt.DateRange.To) {
          opt.DateRange.From = DateTimeHelper.format(
            opt.DateRange.From,
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
          opt.DateRange.To = DateTimeHelper.format(
            opt.DateRange.To,
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
        }
      }
    );
  }

  /**
   * 账单结算
   *
   * @param {BookingSettlementRequestOptions} options
   * @returns {HttpObservable<HotelBookingSettleResult>}
   * @memberof BillService
   */
  BookingSettlement(
    options: BookingSettlementRequestOptions
  ) {
    return this.httpClient.post<HotelBookingSettleResult>(
      '/HotelBookingSettlementApi/BookingSettlement',
      { options: options }
    );
  }

  /**
   * 生成账单
   *
   * @param {string[]} bookingList
   * @returns {HttpObservable<string>}
   * @memberof BillService
   */
  GenerateBill(bookingList: string[]) {
    return this.httpClient.post<BillResult>(
      '/HotelBookingSettlementApi/GenerateBillToSettlement',
      { bookingList: bookingList }
    );
  }

  private billDetailProcessor(detail: BillDetailModel) {
    if (detail.BookingDate) {
      detail.BookingDate = DateTimeHelper.parse(detail.BookingDate, true);
    }
    if (detail.CheckInDate) {
      detail.CheckInDate = DateTimeHelper.parse(detail.CheckInDate, true);
    }

    if (detail.CancellationPolicies && detail.CancellationPolicies.length > 0) {
      detail.CancellationPolicies.forEach(c => {
        c.From = DateTimeHelper.parse(c.From, true);
      });
    }
  }

  private billModelProcessor(detail: ClientBillModel) {
    if (detail.BillEndTime) {
      detail.BillEndTime = DateTimeHelper.parse(detail.BillEndTime, true);
    }
    if (detail.TrackStartTime) {
      detail.TrackStartTime = DateTimeHelper.parse(detail.TrackStartTime, true);
    }
    if (detail.TrackEndTime) {
      detail.TrackEndTime = DateTimeHelper.parse(detail.TrackEndTime, true);
    }
    if (detail.CreateTime) {
      detail.CreateTime = DateTimeHelper.parse(detail.CreateTime, true);
    }
    if (detail.UpdateTime) {
      detail.UpdateTime = DateTimeHelper.parse(detail.UpdateTime, true);
    }
  }

  private BillBookingDetailProcessor(detail: BillBookingDetailModel) {
    if (detail.CheckInDate) {
      detail.CheckInDate = DateTimeHelper.parse(detail.CheckInDate, true);
    }
    if (detail.CheckOutDate) {
      detail.CheckOutDate = DateTimeHelper.parse(detail.CheckOutDate, true);
    }
    if (detail.OrderDate) {
      detail.OrderDate = DateTimeHelper.parse(detail.OrderDate, true);
    }
    if (detail.CancellationFromDate) {
      detail.CancellationFromDate = DateTimeHelper.parse(
        detail.CancellationFromDate,
        true
      );
    }
  }
}
