import { IGeoGoordinate } from '@dida-shopping/dida-services/geography';

export class HotelPriceSearchFilter {
    BedTypeID?: number;
    BreakfastTypeID?: number;
    PersonCount?: number;
    HotelKeyWord?: string;
    StarRating?: number[];
    MaxPrice?: number;
    MinPrice?: number;
    Location?: IGeoGoordinate;
    Radius?: number;
    HotelChainCode?: string[];
    // EanSupplierType: number;
}
