import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';

/**
 * 特殊需求通信服务
 *
 * @export
 * @class SpecialRequestService
 */
@Injectable()
export class SpecialRequestService {

  constructor(
    private logger: LoggerService,
    private httpClient: HttpClient
  ) {
  }


  /**
   * 获取供应商支持的特殊需求ID列表
   *
   * @param {number} supplierID
   * @param {string} responseId
   * @param {string} ratePlanMask
   * @returns {HttpObservable<number[]>}
   * @memberof SpecialRequestService
   */
  getSupplierSpecialRequest(supplierID: number, responseId: string, ratePlanMask: string) {
    return this.httpClient.get<number[]>('/HotelBookingApi/GetSupplierSpecialRequestIDList', {
      supplierID,
      responseId,
      ratePlanMask
    });
  }
}
