export class ArrayHelper {
    static range(start: number, count: number): number[] {
        const result = new Array(count);
        for (let i = 0; i < count; i++) {
            result[i] = start + i;
        }
        return result;
    }

    static isNullOrEmpty<T>(arr: T[]): boolean {
        return !!arr && arr.length > 0;
    }

    /**
     * @description 数组去重
     * @author Adam.Lee
     * @param arr
     * @returns 去重后的新数组
     */
    static unique(arr: any[]): any[] {
        return Array.from(new Set(arr));
    }

    static twoDimensionalize<T = any>(arr: T[], size: number): T[][] {
        const tempArr = arr.map(item => item);
        const result = [];
        do {
            const list = tempArr.splice(0, size);
            result.push(list);
        } while (tempArr.length > 0);

        return result;
    }
}
