import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'nd-alert',
})
export class DidaUITKAlertSizeDirective {

  @Input()
  ndSize: 'default' | 'small' = 'small';

  @HostBinding('class.nd-alert_small') get size() {
    return this.ndSize === 'small';
  }

}
