
export class PaginationBaseModel {
    PageNum?= 1;
    PageSize?= 10;
}

export class PaginationWrapper<T> extends PaginationBaseModel {
    Data: T[] = [];
    TotalAmount: number;
    PageNum: number;
    PageSize: number;
}

