<div class="dida-tabs-cascader" ndBoxShadow [showAlways]="true" [style.width]="getWidth()">
  <div class="dida-tabs-cascader-parent">
    <ul class="dida-tabs-cascader-parent__list">
      <li class="dida-tabs-cascader-parent__item" [class.dida-tabs-cascader-parent__item_active]="isActive(0, option)"
          *ngFor="let option of ndConfig.options; let i=index" (click)="optionClick(0, option, i, null, $event)">
        {{ option[ndConfig.labelProperty] }}</li>
    </ul>
  </div>
  <div class="dida-tabs-cascader-children" nz-row *ngIf="isShowChildren()"
       [class.dida-tabs-cascader-children_group]="isGroupMode">
    <div nz-col nzSpan="24" *ngIf="!isGroupMode">
      <div  class="dida-tabs-cascader-children__list" nz-row>
        <div nz-col nzSpan="4" class="dida-tabs-cascader-children__item"
             [class.dida-tabs-cascader-children__item_active]="isActive(1, option)"
             *ngFor="let option of selectedOptions[0].children; let i=index"
             (click)="optionClick(1, option, i, null, $event)">
          {{ option[ndConfig.labelProperty] }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="24" class="dida-tabs-cascader-children__group-list group-list" *ngIf="isGroupMode"
         [style.height]="getHeight()">
      <div nz-row>
        <div nz-col nzSpan="24" class="group-list__row" *ngFor="let option of selectedOptions[0].children; let i=index">
          <div class="group-list__title" [attr.title]="option[ndConfig.labelProperty]">
            {{ option[ndConfig.labelProperty] }}
          </div>
          <div class="group-list__item-list">
            <ul>
              <li *ngFor="let item of option.children; let j=index" class="group-list__item"
                  [class.group-list__item_active]="isActive(1, item)" (click)="optionClick(2, item, i, j, $event)"
                  [attr.title]="item[ndConfig.labelProperty]">
                {{ item[ndConfig.labelProperty] }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <ng-content selector="[nd-tabs-cascader-extra]"></ng-content>
    </div>
  </div>
</div>
