import { environment } from '../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-external-redirect',
    templateUrl: './external-redirect.component.html',
    styleUrls: ['./external-redirect.component.scss']
})
export class ExternalRedirectComponent implements OnInit {

    targetName: string;
    targetUrl: string;
    redirectType: number;
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        let redirectType = this.redirectType = this.route.snapshot.params['type'];
        let {tn: targetName, tu: targetUrl} = this.route.snapshot.queryParams;
        this.targetName = targetName;
        if (!redirectType) {
            location.href = `${location.protocol}//${environment.oldSite}${location.pathname}${location.search}${location.hash}`;
        } else {
            setTimeout(() => {
                location.replace(targetUrl);
            }, 3000);
        }
    }

}
