import { Directive, Host, Input, HostBinding } from '@angular/core';

let nextInstanceId = 0;

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'dida-form-label'
})
export class DidaFormLabelDirective {

    @Input() id = `didaFormLabel#${nextInstanceId++}`;

    @HostBinding('class') class = 'dida-form-label';

    @HostBinding('attr.id') _id = this.id;    

}
