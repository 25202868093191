import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  @Input() IsCoupon?: boolean;

  @Input() hideImage: boolean;

  constructor() { }

  get showCouponImg() {
    return this.IsCoupon === true;
  }

  ngOnInit() {
  }

}
