<div #ref><ng-content></ng-content></div>
<span class="placeholder text-view-center" *ngIf="ref.children.length == 0">
    <span *ngIf="processStep === ProcessStepType.Processing" i18n="@@Text_File_Uploading">上传中...</span>
    <div *ngIf="processStep !== ProcessStepType.Processing">
        <div class="placeholder-icon">+</div>
        <small class="text-highlight text-sm" *ngIf="uploadError && showStatusMessage">{{uploadError.Message}}</small>
    </div>
</span>
<input type="file" #fileControl name="" id="" class="hidden" [accept]="accept" [multiple]="this.maxCount > 1" (change)="onFileChange()">
<div hidden #errorMessageContainer>
    <span [attr.data-type]="UploadErrorType.MaxSize" i18n="@@Error_ImageUpload_MaxSize">图片最大不能超过 {{maxSize}} M</span>
    <span [attr.data-type]="UploadErrorType.MaxCount" i18n="@@Error_ImageUpload_MaxCount">图片最多不能超过 {{maxCount}} 张</span>
    <span [attr.data-type]="UploadErrorType.ImageRatio" i18n="@@Error_FileUpload_Ratio">请提供长宽比例为 {{ratioDesc}} 的图片</span>
    <span [attr.data-type]="UploadErrorType.Network" i18n="@@Error_FileUpload_Network">网络繁忙，请稍后再试</span>
    <span [attr.data-type]="UploadErrorType.Default" i18n="@@Error_FileUpload_Default">上传失败，请稍后再试</span>
</div>
