import { Breakpoints } from './enums/breakpoints.enum';

export class LayoutHelper {
  static getBreakpoint(width: number): Breakpoints {
    let breakpoint: Breakpoints;
    if (width <= 480) {
      breakpoint = Breakpoints.xs;
    } else if (width <= 768) {
      breakpoint = Breakpoints.sm;
    } else if (width <= 992) {
      breakpoint = Breakpoints.md;
    } else if (width <= 1200) {
      breakpoint = Breakpoints.lg;
    } else if (width <= 1600) {
      breakpoint = Breakpoints.xl;
    } else {
      breakpoint = Breakpoints.xxl;
    }
    return breakpoint;
  }

  static getBreakpointPropertyName(
    breakpoint: Breakpoints,
    prefix = 'na'
  ) {
    return `${prefix}${(Breakpoints as any)[breakpoint]}`;
  }

  static forEach(
    callbackfn: (point: Breakpoints, value: string) => void
  ): void {
    const object = Breakpoints as any;
    for (const key in object) {
      // eslint-disable-next-line no-prototype-builtins
      if (object.hasOwnProperty(key)) {
        callbackfn(key as Breakpoints, object[key]);
      }
    }
  }
}
