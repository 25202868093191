
export class HotelRateStateModel {

  /**
   *Creates an instance of HotelRateStateModel.
   * @param {number} hotelId
   * @param {string} pageKey
   * @param {number} [timeticks=0]
   * @param {{[key: string]: string}} unavailableRatePlanList - key: rate plan id or responseId_mask, value: message
   * @memberof HotelRateStateModel
   */
  constructor(
    public hotelId: number,
    public pageKey: string,
    public timeticks: number = 0,
    public unavailableRatePlanList: { [key: string]: string } = {}) {

  }

}
