import { TicketDetail } from '../models/kangaroo/ticket-detail.model';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { HotelBookingStatusType } from '../enums/hotel-booking-status-type';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

export class TicketHelper {

  static init(ticket: TicketDetail): void {
    if (ticket && ticket.ReplyList) {
      if (ticket.BookingInfo) {
        if (ticket.BookingInfo.CancelTime) {
          ticket.BookingInfo.CancelTime = DateTimeHelper.parse(
            ticket.BookingInfo.CancelTime,
            true
          );
        }
        ticket.BookingInfo.CheckInDate = DateTimeHelper.parse(
          ticket.BookingInfo.CheckInDate,
          true
        );
        ticket.BookingInfo.CheckOutDate = DateTimeHelper.parse(
          ticket.BookingInfo.CheckOutDate,
          true
        );
        ticket.BookingInfo.OrderDate = DateTimeHelper.parse(
          ticket.BookingInfo.OrderDate,
          true
        );
      }
      ticket.ReplyList.forEach(reply => {
        reply.Attachments = reply.AttachmentJson
          ? JSON.parse(reply.AttachmentJson)
          : [];
      });
      const clientReplyList = ticket.ReplyList.filter(
        reply => reply.IsSender
      ).sort((a, b) => {
        return (
          -1 *
          (DateTimeHelper.parse(a.CreateTime).getTime() -
            DateTimeHelper.parse(b.CreateTime).getTime())
        );
      });
      if (clientReplyList.length > 0) {
        ticket.Question = clientReplyList[0];
      }
    }
  }

  static normalize(ticket: TicketDetail) {
    TicketHelper.init(ticket);
    return ticket;
  }

  /**
   * 订单列表页 与 工单列表页稍稍有点不同
   * 只有工单列表页才能允许已取消的订单发起工单（在已有工单的情况下允许追加）
   */
  static canCreate(
    bookingStatus: HotelBookingStatusType,
    checkoutDate: Date,
    forTicketListPage: boolean,
    lang: LanguageType,
  ): boolean {
    if (lang !== LanguageType.zh_CN) {
      return false;
    }

    if (
      bookingStatus !== HotelBookingStatusType.Confirmed &&
      bookingStatus !== HotelBookingStatusType.Canceled
    ) {
      return false;
    }

    let day = 31;
    if (bookingStatus === HotelBookingStatusType.Canceled) {
      if (forTicketListPage) {
        day = 15;
      } else {
        // 只有工单列表才能允许已取消的订单发起工单
        return false;
      }
    }

    let today = DateTimeHelper.today;
    const lastTime = DateTimeHelper.addDays(checkoutDate, day);
    return lastTime.getTime() - today.getTime() >= 0;
  }
}
