import { SERVER_CONFIG } from './app-server-config';


export function resolveServiceHost(url: string): string {
    let actualUrl = url;
    if (url.startsWith('/')) {
        // When a service end point is specified, proxy setting should be ignore.
        // THIS IS AN ENFORCEMENT FOR MAKING SURE THE BACK END SERVICE CORS FEATURE IS WORK AS EXPECTED.
        if (SERVER_CONFIG.serviceEndPoint) {
            actualUrl = `${SERVER_CONFIG.serviceEndPoint}${actualUrl}`;
        } else if (SERVER_CONFIG.proxyEnabled && actualUrl.indexOf('/assets/') < 0) {
            // PROXY MODE SHOULD BE RETIRED IN DAILY DEVELPOMENT.
            // THE ONLY USE CASE OF PROXY SETTING IS DEBUGGING THE PRODUCTION API.
            actualUrl = `/Api${url}`;
        }
    }
    return actualUrl;
}
