import { RateSource, PriceType } from '@dida-shopping/dida-services/rate';
/**
 * 最低价过滤条件
 *
 * @export
 * @class LowestRateFilter
 */
export class LowestRateFilter {
    /**
     * 是否仅返回最低价
     *
     * @type {boolean}
     * @memberof LowestRateFilter
     */
    LowestRateOnly: boolean;

    // /**
    //  * 价格来源
    //  *
    //  * @type {RateSource}
    //  * @memberof LowestRateFilter
    //  */
    // RateSource: RateSource;

    // /**
    //  * 价格类型
    //  *
    //  * @type {PriceType}
    //  * @memberof LowestRateFilter
    //  */
    // PriceType: PriceType;

    // /**
    //  * 是否需要最低价的Rateplan信息
    //  *
    //  * @type {boolean}
    //  * @memberof LowestRateFilter
    //  */
    // NeedDetailLowestRatePlanInfo?: boolean;

    /**
     * 是否需要比价
     *
     * *不知道具体干嘛，需要问James*
     *
     * @type {boolean}
     * @memberof LowestRateFilter
     */
    NeedCompareLowestRate?: boolean;

    /**
     * 是否根据城市排序
     *
     * *不知道具体干嘛，需要问James*
     *
     * @type {boolean}
     * @memberof LowestRateFilter
     */
    NeedOrderByCity?: boolean;
}
