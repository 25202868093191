
<footer [class.extra-padding-bottom]="appContext?.isTestClientAccount">
  <div class="container top-part">
    <div class="row">
      <div class="col-sm-3">
        <h4 i18n="@@Text_Title_Aboutme">关于旅赞</h4>
        <p>
          <a class="footer__link" href="#" i18n="@@Text_CompanyCulture">旅赞文化</a>
        </p>
        <!-- <p>
          <a class="footer__link" routerLink="/home/news" i18n="@@Text_CompanyNews">公告栏</a>
        </p> -->
        <p *ngIf="isCN">
          <a class="footer__link" routerLink="/home/faq" i18n="@@Text_FAQ">常见问题与解答</a>
        </p>
        <!-- <p *ngIf="isUnderTransfer">
                    <a class="footer__link" routerLink="/transfer/service-center">服务中心</a>
                </p> -->
      </div>
      <div class="col-sm-3 contact-info">
        <h4 i18n="@@Text_Title_ContactEmail">联系方式</h4>
        <p>
          <span i18n="@@Text_Label_HotelRelBizCustomerServiceEmail">客服邮箱</span>：
          <a class="footer__link" href="mailto:cs@lvzantravel.com">cs@lvzantravel.com</a>
        </p>
        <p>
          <span i18n="@@Text_Label_HotelRelBizCustomerService">客服电话</span>：
          <a class="footer__link" href="tel:0755-82628520">0755-25679781</a>
        </p>
      </div>

      <div class="col-sm-3 contact-info">
        <h4 i18n="@@Text_Title_ContactEmail">服务条款</h4>
        <p>
          <a class="footer__link" routerLink="/home/doc/PrivacyPolicy" target="_blank" i18n="@@Text_PrivacyPolicy"
            >隐私政策</a
          >
        </p>
        <p>
          <a class="footer__link" routerLink="/home/doc/CompanyPolicies" target="_blank" i18n="@@Text_CompanyPolicies"
            >公司政策</a
          >
        </p>
        <p>
          <a class="footer__link" routerLink="/home/doc/TermsConditions" target="_blank" i18n="@@Text_TermsConditions"
            >条件与条款</a
          >
        </p>
      </div>

      <!-- <div class="col-sm-4 col-md-3 qrcode-list">
                <div class="qrcode-list__item">
                    <h4 i18n="@@Text_Title_OfficialAccount">微信公众号</h4>
                    <app-image src="/common/qrcode-wechat.jpg?x-oss-process=image/resize,w_640"></app-image>
                    <h6 i18n="@@Text_Notice_OfficialAccountBinding">已与小程序关联</h6>
                </div>
                <div class="qrcode-list__item">
                    <h4 i18n="@@Text_Title_Weapp">微信小程序</h4>
                    <app-image src="/common/qrcode-weapp.jpg?x-oss-process=image/resize,w_640"></app-image>
                </div>
            </div> -->
    </div>
  </div>
  <hr />

  <div class="container bottom-part">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 text-center">
        <p class="row cert-section">
          <a
            class="col-md-4"
            href="//ss.knet.cn/verifyseal.dll?sn=e17072644030068410nkux000000"
            target="_blank"
            [ngSwitch]="isCN"
          >
            <img
              *ngSwitchCase="false"
              class="img-rounded"
              src="//dsimage.didatravel.com/v2-app/images/certs/cnnic_en.png"
            />
            <img
              *ngSwitchCase="true"
              class="img-rounded"
              src="//dsimage.didatravel.com/v2-app/images/certs/cnnic.png"
            />
          </a>
          <a class="col-md-4" href="javascript:">
            <img class="img-rounded" src="//dsimage.didatravel.com/v2-app/images/payment/visa.jpg" />
          </a>
          <a class="col-md-4" href="javascript:">
            <img class="img-rounded" src="//dsimage.didatravel.com/v2-app/images/payment/master.jpg" />
          </a>
        </p>
        <p class="copyright">
          ©2012 - {{ currentFullYear }} <span i18n="@@Text_DidaTravel_CompanyName">旅赞科技</span>｜
          <a class="footer__link" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备12073186号</a>｜
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006962"
            >粤公网安备44030502006962号</a
          >
        </p>
        <p class="company-address" i18n="@@Text_CompanyAddress">
          深圳市南山区沙河西路1819号深圳湾科技生态园2区9栋B1座24层
        </p>
      </div>
    </div>
  </div>
</footer>
