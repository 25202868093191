import {
  Directive,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ContentChild,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { Subject, fromEvent, Observable, merge, Subscription } from 'rxjs';
import { startWith, takeUntil, mapTo } from 'rxjs/operators';
import { NzIconDirective, NzIconService } from 'ng-zorro-antd/icon';
import { Icons, iconService } from '@dida-shopping/dida-services/icon';
import { DocumentMouseMoveEvent } from '@dida-shopping/ngx-dida-uitk/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nz-icon]'
})
export class DidaUITKIconStatusDirective implements OnChanges, OnDestroy {
  @Input()
  ndTarget: Element;

  @Input()
  ndType: Icons;

  @Input()
  ndHover = true;

  @Output()
  ndIconHoverChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Roman: Ng9(Ivy) does not support query content child(ren) from the host target, inject it directly
  // @ContentChild(NzIconDirective, { static: true, read: NzIconDirective })
  // private nzIcon: NzIconDirective;

  get hovered(): boolean {
    return this.isHover;
  }

  private _hoverDetectionActive: boolean;
  private hoverStateIcon: Icons;
  private isHover = false;
  private hover$: Observable<boolean>;
  private hoverSubscription: Subscription;
  // 鼠标动得太快就像龙卷风, 以下这三个都是为了解决 mouseleave 不触发而存在的
  // private mousemove$ = fromEvent<MouseEvent>(document, 'mousemove');
  private mouseWithinTarget$ = new Subject<boolean>();
  private mousemoveSubscription: Subscription;
  private destroy$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private nzIconService: NzIconService,
    private nzIcon: NzIconDirective // <= from host element
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(`[${this.ndType}]: ngOnChanges()`);
    if (changes.ndType) {
      if (changes.ndType.currentValue !== changes.ndType.previousValue) {
        this.stopHoverDetection(); // stop and start new detection in init, if necessary.
      }
    }
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stopHoverDetection();
  }

  private init() {
    if (!this.hasHoverState() || !(this.ndType && this.ndHover)) {
      this.stopHoverDetection();
      return;
    }
    this.nzIconService
      .getRenderedContent(this.hoverStateIcon, null)
      .subscribe(val => {
        // console.log(val);
      });
    if (this._hoverDetectionActive) {
      console.log(`[stopping previous detection] icon: ${this.ndType}`, this);
      this.stopHoverDetection();
    }

    const target = (this.ndTarget ||
      this.elementRef.nativeElement) as HTMLElement;
    this.hover$ = merge(
      fromEvent(target, 'mouseenter').pipe(mapTo(true)),
      fromEvent(target, 'mouseleave').pipe(mapTo(false)),
      this.mouseWithinTarget$
    );

    this.hoverSubscription = this.hover$
      .pipe(
        startWith(false),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: hovered => {
          // console.log(`[${this.ndType}] hover set to: ${hovered}.`);
          this.nextHoverState(target, hovered);
        }
      });
    this._hoverDetectionActive = true;
  }

  private hasHoverState(): boolean {
    this.hoverStateIcon = iconService.getIcon(this.ndType);
    return this.ndType !== this.hoverStateIcon;
  }

  private stopHoverDetection() {
    if (this.hoverSubscription) {
      this.hoverSubscription.unsubscribe();
      this.hoverSubscription = null;
    }
    if (this.mousemoveSubscription) {
      this.mousemoveSubscription.unsubscribe();
      this.mousemoveSubscription = null;
    }
    if (this.nzIcon && this.nzIcon.type !== this.ndType) {
      this.nzIcon.nzType = this.ndType;
      this.nzIcon.ngOnChanges({
        nzType: {} as any
      });
    }
    // reset hover state to false
    if (this.isHover) {
      this.isHover = false;
      this.ndIconHoverChange.emit(false);
    }
    this._hoverDetectionActive = false;
  }

  private nextHoverState(target: HTMLElement, hovered: boolean) {
    if (this.hovered !== hovered) {
      if (hovered) {
        this.nzIcon.nzType = this.hoverStateIcon;
        this.guaranteeWithinTarget(target);
      } else {
        this.nzIcon.nzType = this.ndType;
        if (this.mousemoveSubscription) {
          this.mousemoveSubscription.unsubscribe();
          this.mousemoveSubscription = null;
        }
      }
      this.nzIcon.ngOnChanges({
        nzType: {} as any
      });
      this.isHover = hovered;
      this.ndIconHoverChange.emit(hovered);
    }
  }

  private guaranteeWithinTarget(target: HTMLElement) {
    this.mousemoveSubscription = DocumentMouseMoveEvent.pipe(
      takeUntil(this.destroy$)
    ).subscribe(event => {
      if (this.hovered && !target.contains(event.target as HTMLElement)) {
        this.mouseWithinTarget$.next(false);
      }
    });
  }
}
