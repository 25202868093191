<ng-container *ngIf="error">

    <ng-container [ngSwitch]="error.errorCode">
        <ng-container *ngSwitchCase="ErrorCode.AuthenticationError_Failure" i18n="@@Text_Error_SignInFailed_UsernameOrPasswordNotMatch">
          用户名或密码出错，登录失败。
        </ng-container>
        <ng-container *ngSwitchCase="ErrorCode.AuthenticationError_UserLocked" i18n="@@Text_Error_SignInFailed_AccountLocked">
          您的账户已被锁定，详情请与机构管理员联系。
        </ng-container>
        <ng-container *ngSwitchCase="ErrorCode.AuthenticationError_ClientLocked" i18n="@@Text_Error_SignInFailed_ClientLocked">
          您所在机构已被锁定，详情请与机构管理员联系。
        </ng-container>
        <ng-container *ngSwitchDefault>{{error.message}}</ng-container>
    </ng-container>

</ng-container>
