import { ChangeDetectionStrategy, Component, HostBinding, OnChanges, OnDestroy, AfterContentInit, ContentChildren, Input, QueryList, EventEmitter, Output, Self, Optional, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, Validators, NgControl } from '@angular/forms';
import { DidaUITKTagComponent } from './tag.component';
import { Subscription } from 'rxjs';
import { ArrayHelper } from '@dida-shopping/dida-services/common'

@Component({
  selector: 'nd-tag-group',
  template: `<ng-content></ng-content>`,
  styles: [`
    :host {
      display: flex;
      flex-flow: wrap;
      margin: 0 -4px;
    }

    ::ng-deep nd-tag-group > nd-tag {
      margin: 0 4px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DidaUITKTagGroupComponent implements OnChanges, OnDestroy, AfterContentInit, ControlValueAccessor {
    @HostBinding('class.nd-tag-group') classNdTagGroup = true;
    @ContentChildren(DidaUITKTagComponent, {
      descendants: true
    })
    childTags: QueryList<DidaUITKTagComponent>;

    @Input()
    ndCheckable: boolean;

    @Input()
    ndClosable: boolean;

    @HostBinding('class.nd-tag-group_multi')
    @Input()
    ndMulti: boolean;

    @HostBinding('class.nd-tag-group_small')
    @Input()
    ndSize: 'small' | 'large'

    /**
     * 标签背景色
     */
    @Input()
    ndColor: any;

    /**
     * 标签样式
     */
    @Input()
    ndStyle: any;

    /**
     * 标签背景透明度
     */
    @Input()
    ndBgOpacity = 0.12

    @Input()
    ndVerticalGutter = 4;

    @Input()
    ndValue: any;

    @Output()
    ndChange = new EventEmitter<any>();

    private _required: boolean;

    @Input()
    set required(isRequired: boolean) {
      this._required = isRequired;
      if (this.control) {
        if (isRequired) {
          this.control.control.setValidators(Validators.required);
        } else {
          this.control.control.clearAsyncValidators();
        }
      }
    }

    get required() {
      return this._required;
    }

    subscriptionList: Subscription[];

    constructor(@Optional() @Self() private control: NgControl, private changeRef: ChangeDetectorRef) {
      if (this.control) {
        this.control.valueAccessor = this;
      }
    }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
    private propagateChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
    private propagateTouch = (_: any) => {};

    private updateTagsStatus(value: any | any[], isUser?: boolean) {
      const isMultiValue = Array.isArray(value)
      if(isMultiValue) {
        value = ArrayHelper.unique(value)
      }
      this.ndValue = value;
      if (this.childTags && this.ndCheckable) {

        this.childTags.map(tag => {
          if(Array.isArray(value)) {
            tag.ndChecked = value.includes(tag.ndValue);
          } else {
            tag.ndChecked = value === tag.ndValue;
          }
          tag.detectChanges()
        });
      }

      if (isUser) {
        this.propagateChange(this.ndValue);
        this.ndChange.emit(this.ndValue);
        // this.propagateTouch()
      }

    }

    private updateTagProps() {
      if(this.childTags) {
        this.childTags.map(tag => {
          if(this.ndCheckable !== undefined) {
            tag.ndCheckable = this.ndCheckable
          }
          if(this.ndClosable !== undefined) {
            tag.ndClosable = this.ndClosable
          }

          if(this.ndSize !== undefined) {
            tag.ndSize = this.ndSize
          }

          if(this.ndColor !== undefined) {
            tag.ndColor = this.ndColor
          }

          if(this.ndBgOpacity !== undefined) {
            tag.ndBgOpacity = this.ndBgOpacity
          }

          const tagStyle = this.ndStyle || {};

          if(this.ndVerticalGutter !== undefined) {
            tagStyle['margin-bottom'] = this.ndVerticalGutter + 'px'
          }
          tag.ndStyle = tagStyle
          tag.detectChanges()
        })
      }
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes.value) {
        this.updateTagsStatus(changes.value.currentValue);
      }
      setTimeout(() => {
        this.updateTagProps()
      }, 0);
    }

    ngAfterContentInit() {
      this.updateSubscription();
      this.updateTagProps()
      this.childTags.changes.subscribe(childTags => {
        this.updateSubscription();
        this.updateTagProps()
        this.changeRef.detectChanges();
      });
    }

    private updateSubscription() {
      if (this.subscriptionList) {
        this.subscriptionList.map(item => item.unsubscribe());
      }

      this.subscriptionList = this.childTags.map(tag => {
        this.updateTagsStatus(this.ndValue);
        return tag.ndChange.subscribe(checked => {
          const value = tag.ndValue
          if(this.ndMulti) {
            if(checked) {
              this.updateTagsStatus([...(this.ndValue || []), value], true);
            } else {
              this.updateTagsStatus(this.ndValue.filter(item => item !== value), true);
            }
          } else {
            this.updateTagsStatus(value, true);
          }
        });
      });
    }

    writeValue(value) {
      this.updateTagsStatus(value);
    }

    registerOnChange(fn: any) {
      this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
      this.propagateTouch = fn;
    }

    ngOnDestroy() {
      this.subscriptionList.map(item => item.unsubscribe());
      this.subscriptionList = null;
    }

}
