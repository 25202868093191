export enum ClientStatusTypeEnum {
  Active = 1,
  Locked = 0,
  NoSpecified = -10,
}

export enum ClientAccountStatusTypeEnum {
  Active = 1,
  Frozen = 0,
  NoSpecified = -10,
}

export enum ClientCandidateStatusTypeEnum {
  NoSpecified = -10,
  Pending = 0,
  Confirmed = 1,
  Invalid = -1,
}
