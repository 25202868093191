import { Translatable, TranslatableModel } from "@dida-shopping/dida-services/common";
import { LanguageType } from "@dida-shopping/dida-services/i18n";

export class FeatureGuideModel implements Translatable {
  constructor(payload: FeatureGuideModel) {
    Object.assign(this, {
      ...payload,
      ExpiredTime: payload.ExpiredTime && new Date(payload.ExpiredTime),
      Steps: payload.Steps.map(step => new FeatureGuideStepModel(step))
    })
  }

  get expired() {
    return +this.ExpiredTime - Date.now() > 0
  }

  switchLanguage(lang: LanguageType) {
    this.Steps.map(step => step.switchLanguage(lang))
    return this
  }

  ID: string;
  /**
   * @description 指定页面，/Path/.test(location.pathname)
   */
  Path: string;
  Steps: FeatureGuideStepModel[];
  ExpiredTime: Date;
  /**
   * @description 自动检查是否展示引导
   */
  AutoDetect: boolean;
}

export class FeatureGuideStepModel extends TranslatableModel {
  constructor(payload: FeatureGuideStepModel) {
    super()
    Object.assign(this, payload)
  }

  switchLanguage(lang) {
    Object.assign(this, this[lang])
  }

  Target: string;
  Desc: string;
}
