import { Injectable } from '@angular/core';
import { FormGroupDirective, NgForm, FormControl } from '@angular/forms';

/**
 * 定义表单显示错误信息的行为
 * 
 * 根据Angular Material的CDK部件删减后的实现，具体见
 * 
 * @link https://github.com/angular/material2
 * 
 * @export
 * @class DidaFormErrorStateMatcher
 */
@Injectable()
export class DidaFormErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.touched || (form && form.submitted)));
  }
}

/**
 * 定义选项列表显示错误信息的行为
 * 
 * @export
 * @class DidaSelectFormErrorStateMatcher
 */
@Injectable()
export class DidaSelectFormErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null, isOpened: boolean): boolean {
    return !!(!isOpened && control && control.invalid && (control.touched || (form && form.submitted)));
  }
}

