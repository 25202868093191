import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ICouponModel, FieldGroupRestrictionTypeEnum } from '@dida-shopping/dida-services/campaign';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'nd-navbar-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DidaUITKNavBarCampaignModalComponent {
  // @Input() visible = false;

  @Input() list: ICouponModel[];

  @Output() hide = new EventEmitter<void>();

  restrictionType = FieldGroupRestrictionTypeEnum;

  getEffectDay(item: ICouponModel) {
    return DateTimeHelper.getDateOffset(
      item.EffectiveTimeSetting.From,
      item.EffectiveTimeSetting.To
    );
  }

  constructor(
    private modalRef: NzModalRef
  ) {}

  hideModal() {
      this.modalRef.close();
  }
}
