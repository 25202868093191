import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaUITKTabsCascaderComponent } from './tabs-cascader.component';
import { DidaUITKDirectiveModule } from '../directives';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { DidaUITKTabsCascaderExtraDirective } from './tabs-cascader-extra.directive';



@NgModule({
  declarations: [DidaUITKTabsCascaderComponent, DidaUITKTabsCascaderExtraDirective],
  imports: [
    CommonModule,
    NzGridModule,
    DidaUITKDirectiveModule
  ],
  exports: [DidaUITKTabsCascaderComponent]
})
export class DidaUITKTabsCascaderModule { }
