import { mergeMap, delayWhen, map, switchMap, flatMap } from 'rxjs/operators';
import { ResetPasswordViewModel } from '../../../account/password-reset/password-reset.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { Observable, Observer } from 'rxjs';
import { UserLoginCredential } from '../../../shared/models/auth/user-login-credential';
import { UserRegisterViewModel } from '../../../shared/models/auth/user-register-view.model';
import { ApplicationService } from '@dida-shopping/ngx-dida-site-core';

// import { ApplicationContextService } from './application-context.service';


@Injectable()
export class AuthenticationService {

  constructor(private httpClient: HttpClient,
              private appService: ApplicationService) {
  }

  /**
   * 用户登陆
   * @param credential 用户登陆凭证
   */
  login(credential: UserLoginCredential): Observable<{ success: boolean, status: number }> {
    return this.httpClient.post<{ RedirectTo: string, Status: number }>('/AccountApi/Login', credential).pipe(
      switchMap((resp) => {
        return new Observable<{ success: boolean, status: number }>((ob) => {
          this.appService.reloadContext().subscribe((ctx) => {
            if (!resp.Status && ctx.userIdentityInfo && ctx.userIdentityInfo.profile && !ctx.userIdentityInfo.profile.EmailConfirmed) {
              resp.Status = 6; // RequiresEmailConfirm
            }
            ob.next({ success: true, status: resp.Status });
          }, error => {
            ob.error(error);
          }, () => {
            ob.complete();
          });
        });
      })
    );
  }

  /**
   * 登出
   */
  logout(): Observable<boolean> {
    return this.httpClient.post<boolean>('/AccountApi/Logoff', null).pipe(
      delayWhen(resp => {
        return Observable.create((ob: Observer<boolean>) => {
          this.appService.reloadContext().subscribe(() => {
            ob.next(true);
          }, error => {
            ob.error(error);
          }, () => {
            ob.complete();
          });
        });
      })
    );
  }

  /**
   * 注册
   * @param sr  Sales Representitive
   */
  register(model: UserRegisterViewModel, sr = ''): Observable<boolean> {
    let url = '/AccountApi/UserRegister';
    if (sr) {
      url = `${url}?sr=${sr}`;
    }
    return this.httpClient.post<boolean>(url, model).pipe(
      delayWhen(resp => {
        return Observable.create((ob: Observer<boolean>) => {
          this.appService.reloadContext().subscribe(() => {
            ob.next(true);
          }, error => {
            ob.error(error);
          }, () => {
            ob.complete();
          });
        });
      })
    );
  }

  /**
   * 找回密码
   * @param email 已验证的注册邮箱
   */
  findPassword(email: string): Observable<boolean> {
    return this.httpClient.post('/AccountApi/FindPassword', {
      Email: email
    });
  }

  /**
   * 重置密码
   * @param model
   */
  resetPassword(model: ResetPasswordViewModel): Observable<boolean> {
    return this.httpClient.post('/AccountApi/ResetPassword', model);
  }

  changePassword(model: any) {
    return this.httpClient.post<boolean>('/AccountApi/ChangePassword', model);
  }

  changeEmail(model: any) {
    return this.httpClient.post<boolean>('/AccountApi/ChangeEmail', model);
  }
}
