import { Region } from './region.model';
import { SuggestionItemCategory } from '../suggestions';

export class Airport extends Region {
  airportCode: string;
  region: Region;
  constructor(id: string, code: string, region: Region, name: string, nameEn: string, order: number) {
    super(id, SuggestionItemCategory.Airport, name, nameEn, region.continentID, order);
    this.airportCode = code;
    this.region = region;
  }
}

