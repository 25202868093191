// import { timeout, takeUntil } from 'rxjs/operators';
// import { GlobalMessageTypeEnum } from './../../shared/enums/app-message-types';
// import * as NgCommonHttp from '@angular/common/http';
import { Injectable } from '@angular/core';

// import {
//   Observable,
//   Observer,
//   PartialObserver,
//   CompletionObserver,
//   NextObserver,
//   ErrorObserver,
//   Operator,
//   Subscription,
//   Unsubscribable as AnonymousSubscription,
//   Subject,
//   of
// } from 'rxjs';

import {
  // resolveServiceHost,
  // SERVER_CONFIG,
  // APP_VERSION,
  NgxServiceHttpClientWrapper, HttpObservable, ngxHttpCall
} from '@dida-shopping/ngx-dida-site-core';
import { LoggerService } from '../logger/logger.service';
// import { HttpHelper } from './http-helper';
// import { AppMessage } from '../../shared/models/shared-models';
// import { ActionService } from '../services/action/action.service';
// import { HttpResponse } from '@angular/common/http';

// const DEFAULT_HTTP_TIMEOUT = 180000;
//
// export interface StandardResponseModel<T> {
//   Data?: T;
//   Success: boolean;
//   MessageCode: number;
//   Message: string;
//   ServerTime: Date;
// }
//
// export interface StandardErrorModel {
//   errorCode: number;
//   message: string;
//   networkErr?: boolean;
//   validationResult?: any;
// }
//
// export interface HttpObserver<T> extends NextObserver<T>, ErrorObserver<T> {
//   next: (value: T) => void;
//   error: (err: StandardErrorModel) => void;
// }
//
// export class HttpObservable<T> extends Observable<T> {
//   private constructor(
//     private observable: Observable<T>,
//     private pusher: (sub: Subscription) => Subscription
//   ) {
//     super();
//     Object.assign(this, observable);
//   }
//
//   static create<T>(
//     observable: Observable<T>,
//     pusher: (sub: Subscription) => Subscription
//   ): HttpObservable<T> {
//     return new HttpObservable<T>(observable, pusher);
//   }
//
//   subscribe(
//     observerOrNext?:
//       | HttpObserver<T>
//       | PartialObserver<T>
//       | ((value: T) => void),
//     error?: (error: StandardErrorModel) => void,
//     complete?: () => void
//   ): Subscription {
//     let sub: Subscription = null;
//     if (typeof observerOrNext === 'function') {
//       sub = super.subscribe(observerOrNext, error, complete);
//     } else {
//       sub = super.subscribe(observerOrNext);
//     }
//     return this.pusher(sub);
//   }
//   // get internalObservable(): Observable<T> {
//   //     return this.observable;
//   // }
// }
//
// class SubscriptionRecord {
//   private __counter__ = 0;
//   get newCount(): number {
//     return this.__counter__++;
//   }
//   url: string;
//   raw: Subscription;
//   ngUnsubscribe: Subject<void> = new Subject();
//   subscriptions: Map<number, Subscription> = new Map<number, Subscription>();
//   add(sub: Subscription): number {
//     const count = this.newCount;
//     this.subscriptions.set(count, sub);
//     sub.add(() => {
//       this.subscriptions.delete(count);
//     });
//     return count;
//   }
// }

/**
 * HTTP CLIENT实现封装
 */
@Injectable()
export class HttpClient {
  constructor(
    private httpWrapper: NgxServiceHttpClientWrapper,
    // private http: NgCommonHttp.HttpClient,
    private logger: LoggerService
    // private actionService: ActionService
  ) {
    this.logger.debug(`HttpClient ==> new instance!`);
    // window.setInterval(() => {
    //   this.logger.debug(this.__records__);
    // }, 5000);
  }

  // private __counter__ = 0;
  // private __records__: Map<number, SubscriptionRecord> = new Map<
  //   number,
  //   SubscriptionRecord
  // >();
  //
  // private __neteworkErrorSubject__: Subject<AppMessage> = new Subject<
  //   AppMessage
  // >();
  // private __networkErrors__: Map<string, number> = new Map<string, number>();

  /**
   * 发起GET请求
   * @param url 请求URL
   * @param params 请求参数(QueryString)
   */
  get<T>(
    url: string,
    params?: object,
    timeoutMillisec?: number,
    resultPreProcessor: (raw: T) => void = null,
    payloadPreProcessor: (payload: any) => void = null
  ): HttpObservable<T> {


    // if (payloadPreProcessor != null) {
    //   payloadPreProcessor(params);
    // }
    // let httpParams: NgCommonHttp.HttpParams = null;
    // if (params instanceof NgCommonHttp.HttpParams) {
    //   httpParams = params;
    // } else {
    //   httpParams = HttpHelper.buildHttpParams(params);
    // }
    // timeoutMillisec =
    //   timeoutMillisec == null ? DEFAULT_HTTP_TIMEOUT : timeoutMillisec;
    // const rUrl = resolveServiceHost(url);
    // const ob = this.http
    //   .get(rUrl, {
    //     headers: this.buildHeaders(timeoutMillisec),
    //     params: httpParams,
    //     withCredentials: true,
    //     observe: 'response'
    //   })
    //   .pipe(timeout(timeoutMillisec));
    // return this.buildHttpObservable(url, ob, resultPreProcessor);
    return ngxHttpCall(
      this.httpWrapper.get<T>(url, params, {
        timeoutMillisec: timeoutMillisec,
        resultProcessor: resultPreProcessor ? (raw: T) => {
          resultPreProcessor(raw);
          return raw;
        } : null,
        payloadProcessor: payloadPreProcessor ? (payloadInner) => {
          payloadPreProcessor(payloadInner);
          return payloadInner;
        } : null
      })
    );
  }

  /**
   * 发起POST请求
   * @param url 请求URL
   * @param payload 请求表单数据
   */
  post<T>(
    url: string,
    payload: object,
    timeoutMillisec?: number,
    resultPreProcessor: (raw: T) => void = null,
    payloadPreProcessor: (payload: any) => void = null
  ): HttpObservable<T> {
    // const rUrl = resolveServiceHost(url);
    // if (payloadPreProcessor != null) {
    //   payloadPreProcessor(payload);
    // }
    // timeoutMillisec =
    //   timeoutMillisec == null ? DEFAULT_HTTP_TIMEOUT : timeoutMillisec;
    // const ob = this.http
    //   .post<StandardResponseModel<T>>(rUrl, payload, {
    //     headers: this.buildHeaders(timeoutMillisec),
    //     withCredentials: true,
    //     observe: 'response'
    //   })
    //   .pipe(timeout(timeoutMillisec));
    // return this.buildHttpObservable(url, ob, resultPreProcessor);
    return ngxHttpCall(
      this.httpWrapper.post<object, T>(url, payload, {
        timeoutMillisec: timeoutMillisec,
        resultProcessor: resultPreProcessor ? (raw: T) => {
          resultPreProcessor(raw);
          return raw;
        } : null,
        payloadProcessor: payloadPreProcessor ? (payloadInner) => {
          payloadPreProcessor(payloadInner);
          return payloadInner;
        } : null
      })
    );
  }

  // private buildHttpObservable<T>(
  //   url: string,
  //   observable: Observable<Object>,
  //   resultPreProcessor: (raw: T) => void = null
  // ): HttpObservable<T> {
  //   const counter = this.newCounter;
  //   return HttpObservable.create(
  //     Observable.create(httpOb => {
  //       const record = new SubscriptionRecord();
  //       const orisub = observable
  //         .pipe(takeUntil(record.ngUnsubscribe))
  //         .subscribe({
  //           next: (resp: HttpResponse<StandardResponseModel<T>>) => {
  //             if (resp.status === 200) {
  //               const data = resp.body;
  //               if (data.Success) {
  //                 if (resultPreProcessor != null) {
  //                   try {
  //                     resultPreProcessor(data.Data);
  //                   } catch (err) {
  //                     httpOb.error({
  //                       errorCode: -1,
  //                       message: `result processing error: ${err}`,
  //                       validationResult: null,
  //                       networkErr: false
  //                     });
  //                     return;
  //                   }
  //                 }
  //                 httpOb.next(data.Data);
  //                 httpOb.complete();
  //               } else {
  //                 // Stop version check
  //                 // if (data.MessageCode === -2) { // version not correct
  //                 //     window.top.location.reload(); // force reload
  //                 // }
  //                 httpOb.error({
  //                   errorCode: data.MessageCode,
  //                   message: data.Message,
  //                   validationResult: data.Data,
  //                   networkErr: false
  //                 });
  //               }
  //               this.actionService.emit(
  //                 new AppMessage(GlobalMessageTypeEnum.RequestFinish, {
  //                   url
  //                 })
  //               );
  //             } else {
  //               httpOb.error({
  //                 errorCode: resp.status,
  //                 message: resp.statusText,
  //                 networkErr: true,
  //                 validationResult: null
  //               });
  //               let error: any = {
  //                 status: resp.status,
  //                 message: resp.statusText
  //               };
  //               this.actionService.emit(
  //                 new AppMessage(GlobalMessageTypeEnum.RequestFinish, {
  //                   url,
  //                   error
  //                 })
  //               );
  //             }
  //           },
  //           error: err => {
  //             httpOb.error({
  //               errorCode: err.status,
  //               message: err.message,
  //               networkErr: true,
  //               validationResult: null
  //             });
  //             this.actionService.emit(
  //               new AppMessage(GlobalMessageTypeEnum.RequestFinish, {
  //                 url,
  //                 err
  //               })
  //             );
  //           },
  //           complete: () => {
  //             this.logger.debug(`dida=http=>finished!`);
  //           }
  //         });
  //       record.raw = orisub;
  //       record.url = url;
  //       this.__records__.set(counter, record);
  //     }),
  //     (sub: Subscription) => {
  //       if (this.__records__.has(counter)) {
  //         const rec = this.__records__.get(counter);
  //         rec.add(sub);
  //       }
  //       sub.add(() => {
  //         if (this.__records__.has(counter)) {
  //           const record = this.__records__.get(counter);
  //           if (record.subscriptions.size === 0) {
  //             record.ngUnsubscribe.next();
  //             record.ngUnsubscribe.complete();
  //             record.raw.unsubscribe();
  //             this.logger.debug(`unsubscribe => `, record);
  //             this.__records__.delete(counter);
  //           } else {
  //             this.logger.debug(`more than one subscriptions => `, record);
  //           }
  //         }
  //       });
  //       return sub;
  //     }
  //   );
  // }
  //
  // private buildHeaders(timeoutMillisec?: number): NgCommonHttp.HttpHeaders {
  //   // let buildNumber = SERVER_CONFIG.ignoreBuildNumber ? -1 : APP_VERSION.buildNumber;
  //   return new NgCommonHttp.HttpHeaders({
  //     // 'Timeout': `${timeout == null ? DEFAULT_HTTP_TIMEOUT : timeoutMillisec}`,
  //     // 'build-number': `${buildNumber}`,
  //   });
  // }
  //
  // private get newCounter() {
  //   return this.__counter__++;
  // }
}
