import { Directive, Host, Input, HostBinding } from '@angular/core';

let nextInstanceId = 0;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'nd-suggestion-input-placeholder'
})
export class DidaUITKSuggestionInputPlaceholderDirective {

    @Input() id = `ndSuggestionInputPlaceholder#${nextInstanceId++}`;

    @HostBinding('class') class = 'nd-suggestion-input-placeholder';

    @HostBinding('attr.id') _id = this.id;

}
