import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaFormGroupModule } from './dida-form-group/dida-form-group.module';
import { DidaInputModule } from './dida-input/dida-input.module';
import { DidaCheckboxModule } from './dida-checkbox/dida-checkbox.module';
import { DidaSelectModule } from './dida-select/dida-select.module';
import { DidaRadioModule } from './dida-radio/dida-radio.module';
import { DidaFormsetModule } from './dida-formset/dida-formset.module';

@NgModule({
  imports: [
    CommonModule,
    DidaFormGroupModule,
    DidaInputModule,
    DidaCheckboxModule,
    DidaSelectModule,
    DidaRadioModule,
    DidaFormsetModule
  ],
  exports: [
    DidaFormGroupModule,
    DidaInputModule,
    DidaCheckboxModule,
    DidaSelectModule,
    DidaRadioModule,
    DidaFormsetModule
  ],
  declarations: []
})
export class DidaFormModule {}
