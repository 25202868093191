import { Injectable, OnDestroy } from '@angular/core';
import { HotelBookingPreOrderOption } from '../../../shared/models/order/hotel-booking-pre-order-option.model';
import { LocalStorageService } from '../storage/localstorage.service';

export const KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS = 'DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS';

@Injectable()
export class RateSearchContextService implements OnDestroy {
  constructor(private storage: LocalStorageService) {
    this.autoRemove();
  }

  ngOnDestroy(): void {
    this.autoRemove();
  }

  setOptions(key: string, item: HotelBookingPreOrderOption) {
    let valueString = JSON.stringify(item);
    this.storage.save(`${KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS}:${key}`, valueString);
    console.log(item);
    this.autoRemove();
  }

  getOptions(key: string): HotelBookingPreOrderOption {
    let jsonValueString = this.storage.get(`${KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS}:${key}`);
    let result = {};
    if (jsonValueString) {
      result = JSON.parse(jsonValueString);
    }
    return result as HotelBookingPreOrderOption;
  }

  autoRemove() {
    try {
      const timetick = new Date().getTime();
      const expire = 24 * 60 * 60 * 1000;
      this.storage.keys().forEach((key) => {
        if (key && key.startsWith(KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS)) {
          const item = this.storage.get(key);
          if (item) {
            const options = JSON.parse(item) as HotelBookingPreOrderOption;
            if (timetick - options.__timetick__ > expire) {
              this.storage.clear(key);
              console.log(key);
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}
