import { Provider } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DidaUITKDebugLoginComponent } from './debug-login.component';
import { DIDA_AUTH_MODAL_TRIGGER, ConditionCheckError } from '@dida-shopping/ngx-dida-site-core';


export const DIDA_UITK_AUTH_MODAL_TRIGGER_PROVIDER: Provider = {
  provide: DIDA_AUTH_MODAL_TRIGGER,
  deps: [NzModalService],
  useFactory: (nzModalService: NzModalService) => {
    return (url: string, reason: ConditionCheckError) => {
      nzModalService.create({
        nzTitle: 'Login',
        nzFooter: null,
        nzClosable: false,
        nzKeyboard: false,
        nzMaskClosable: false,
        nzContent: DidaUITKDebugLoginComponent,
        nzComponentParams: {
          returnUrl: url,
          reason: reason,
        }
      });
    };
  }
};
