<div class="nationality-select">
  <div class="nationality-select__search">
    <nz-input-group [nzPrefix]="prefix" [nzSize]="'small'">
      <input type="text" #input nz-input [(ngModel)]="searchKeyword" i18n-placeholder="@@Text_Placeholder_NationalitySearch"
        placeholder="请输入国籍/国家名" (input)="onKeywordChange($event.target['value'])" />
    </nz-input-group>
    <ng-template #prefix><i class="nationality-select__search-icon" nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
  </div>
  <div class="nationality-select__list" #list>
    <ul>
      <ng-container *ngIf="!searchKeyword">
        <ng-container *ngTemplateOutlet="sublist; context: { group: 'hot', items: nationality.hot }"></ng-container>
      </ng-container>
      <ng-container *ngIf="nationality.normal?.length;else empty">
        <ng-container *ngTemplateOutlet="sublist; context: { group: 'normal', items: nationality.normal }"></ng-container>
      </ng-container>
    </ul>
  </div>
</div>
<ng-template #sublist let-group="group" let-items="items">
  <li *ngIf="!searchKeyword" class="nationality-select__item-group nd-text-ellipsis">
    <ng-container *ngIf="group === 'hot'" i18n="常用国籍@@Title_Nationality_FrequentlyUse">常用国籍</ng-container>
    <ng-container *ngIf="group === 'normal'" i18n="所有国籍@@Title_Nationality_All">所有国籍</ng-container>
  </li>
  <li *ngFor="let na of convert(items)" class="nationality-select__item nd-text-ellipsis"
    [class.nationality-select__item_active]="isSelected(na)" (click)="onSelect(na)">
    <span class="nationality-select__item-code">{{ na.code }}</span>{{ getDisplayName(na) }}
  </li>
</ng-template>
<ng-template #empty>
  <div class="nationality-select__list-empty">
    <small class="nd-text-active" i18n="@@Text_NationalityNotFound">抱歉，找不到相关国籍，请确认后再搜索！</small>
  </div>
</ng-template>
