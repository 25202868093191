import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { DidaUITKIconModule } from '@dida-shopping/ngx-dida-uitk/icons';

import { DidaUITKNationalityPickerComponent } from './nationality-picker/nationality-picker.component';
import { DidaUITKNationalitySelectComponent } from './nationality-select/nationality-select.component';

export * from './nationality-picker/nationality-picker.component';
export * from './nationality-select/nationality-select.component';

@NgModule({
  imports: [CommonModule, FormsModule, NzInputModule, DidaUITKIconModule],
  declarations: [
    DidaUITKNationalityPickerComponent,
    DidaUITKNationalitySelectComponent
  ],
  exports: [
    DidaUITKNationalityPickerComponent,
    DidaUITKNationalitySelectComponent
  ]
})
export class DidaUITKNationalityPickerModule {}
