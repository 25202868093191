import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { ResourceService } from '../../../../core/services/resource/resource.service';
import { ImageLinkModel } from '../../../models/shared-models';
import { ComponentBaseWithContext } from '@dida-shopping/ngx-dida-site-core';

@Component({
  selector: 'app-suspension',
  templateUrl: './suspension.component.html',
  styleUrls: ['./suspension.component.scss']
})
export class SuspensionComponent extends ComponentBaseWithContext implements OnInit {

  adExpanded = false;
  inited: boolean;

  adList: ImageLinkModel[];
  advertisement: ImageLinkModel;

  @HostBinding('class')
  position: 'top' | 'bottom' = 'bottom';

  constructor(
    private resourceService: ResourceService,
  ) {
    super();
  }

  toggleAd() {
    this.adExpanded = !this.adExpanded;
    this.inited = true;
    localStorage.setItem(`hide_ad_${this.advertisement.Id}`, this.adExpanded ? '' : '1');
  }

  ngOnInit() {

    // 获取页面广告
    this.resourceService.getAdList(this.lang).subscribe(resp => {
      this.adList = resp;
      let advertisement = resp[0];
      if (!advertisement) {
        return;
      }
      if (advertisement.IsCNOnly && !this.isCN) {
        this.advertisement = null;
        return;
      }
      if (advertisement.Controls && !this.hasAnyExperiments(advertisement.Controls)) {
        this.advertisement = null;
        return;
      }

      this.advertisement = advertisement;
      let hidden = !!localStorage.getItem(`hide_ad_${this.advertisement.Id}`);
      if (!hidden) {
        this.toggleAd();
      }

    });
  }

}
