import {
  IDidaHttpRequestOptions,
  IServiceHttpClientWrapper,
  IAsyncOperation,
  StandardResponseModel,
} from '@dida-shopping/dida-services/http'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { NgxHttpParamsHelper } from './ngx-http-params-helper';
import { timeout, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpObservable } from './http-observable.model';
import { resolveServiceHost } from '../app-service-call-resolver';
import { ServiceEventHub, ServiceRequestFinishedEvent } from '../events';
import { ObjectHelper } from '@dida-shopping/dida-services/common';

const DEFAULT_HTTP_TIMEOUT = 180000;

class SubscriptionRecord {
  private __counter__ = 0;
  get newCount(): number {
    return this.__counter__++;
  }
  url: string;
  raw: Subscription;
  ngUnsubscribe: Subject<void> = new Subject();
  subscriptions: Map<number, Subscription> = new Map<number, Subscription>();
  add(sub: Subscription): number {
    const count = this.newCount;
    this.subscriptions.set(count, sub);
    sub.add(() => {
      this.subscriptions.delete(count);
    });
    return count;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NgxServiceHttpClientWrapper implements IServiceHttpClientWrapper {
  private readonly apiEndpointUrlResolver: (apiEndpoint: string) => string
  private __counter__ = 0;
  private __records__: Map<number, SubscriptionRecord> = new Map<
    number,
    SubscriptionRecord
  >();

  constructor(
    private http: HttpClient,
    private serviceEventHub: ServiceEventHub,
  ) {
    this.apiEndpointUrlResolver = resolveServiceHost;
  }

  get<Result>(apiEndpoint: string, params?: any, options?: IDidaHttpRequestOptions<any, Result>): IAsyncOperation<Result> {
    let paramCloned: any = ObjectHelper.clone(params);
    if (options && options.payloadProcessor) {
      paramCloned = options.payloadProcessor(paramCloned);
    }
    let httpParams: HttpParams = null;
    if (paramCloned instanceof HttpParams) {
      httpParams = paramCloned;
    } else {
      httpParams = NgxHttpParamsHelper.buildHttpParams(paramCloned);
    }
    const timeoutMillisec = (options && options.timeoutMillisec) || DEFAULT_HTTP_TIMEOUT;
    const url = this.apiEndpointUrlResolver(apiEndpoint);
    const ob = this.http.get<StandardResponseModel<Result>>(url, {
      params: httpParams,
      withCredentials: true,
      observe: 'response'
    }).pipe(timeout(timeoutMillisec));
    return this.buildHttpObservable(url, ob, options && options.resultProcessor);
  }

  post<Payload, Result>(apiEndpoint: string, data?: Payload, options?: IDidaHttpRequestOptions<Payload, Result>): IAsyncOperation<Result> {
    let payloadCloned: any = data instanceof FormData ? data : ObjectHelper.clone(data);
    if (options && options.payloadProcessor) {
      payloadCloned = options.payloadProcessor(payloadCloned) as any;
    }
    const timeoutMillisec = (options && options.timeoutMillisec) || DEFAULT_HTTP_TIMEOUT;
    const url = this.apiEndpointUrlResolver(apiEndpoint);
    const ob = this.http
      .post<StandardResponseModel<Result>>(url, payloadCloned, {
        withCredentials: true,
        observe: 'response'
      })
      .pipe(timeout(timeoutMillisec));
    return this.buildHttpObservable(url, ob, options && options.resultProcessor);
  }

  private buildHttpObservable<T>(
    url: string,
    observable: Observable<any>,
    resultProcessor: (raw: any) => T = null
  ) {
    const counter = this.newCounter;
    return HttpObservable.create(
      Observable.create(httpOb => {
        const record = new SubscriptionRecord();
        const orisub = observable
          .pipe(takeUntil(record.ngUnsubscribe))
          .subscribe({
            next: (resp: HttpResponse<StandardResponseModel<T>>) => {
              if (resp.status === 200) {
                const data = resp.body;
                if (data.Success) {
                  if (resultProcessor != null) {
                    try {
                      data.Data = resultProcessor(data.Data);
                    } catch (err) {
                      httpOb.error({
                        errorCode: -1,
                        message: `result processing error: ${err}`,
                        validationResult: null,
                        networkErr: false
                      });
                      return;
                    }
                  }
                  httpOb.next(data.Data);
                  httpOb.complete();
                } else {
                  // Stop version check
                  // if (data.MessageCode === -2) { // version not correct
                  //     window.top.location.reload(); // force reload
                  // }
                  httpOb.error({
                    errorCode: data.MessageCode,
                    message: data.Message,
                    validationResult: data.Data,
                    networkErr: false
                  });
                }
                this.serviceEventHub.broadcast(new ServiceRequestFinishedEvent(
                  url, null,`[NgxServiceHttpClientWrapper::buildHttpObservable::next]`, new Date()
                ));
              } else {
                httpOb.error({
                  errorCode: resp.status,
                  message: resp.statusText,
                  networkErr: true,
                  validationResult: null
                });
                const error: any = {
                  status: resp.status,
                  message: resp.statusText
                };
                this.serviceEventHub.broadcast(new ServiceRequestFinishedEvent(
                  url, error,`[NgxServiceHttpClientWrapper::buildHttpObservable::next]`, new Date()
                ));
              }
            },
            error: err => {
              httpOb.error({
                errorCode: err.status,
                message: err.message,
                networkErr: true,
                validationResult: null
              });

              this.serviceEventHub.broadcast(new ServiceRequestFinishedEvent(
                url, err,`[NgxServiceHttpClientWrapper::buildHttpObservable::error]`, new Date()
              ));
            },
            complete: () => {
              // tslint:disable-next-line:no-console
              console.debug(`dida=http=>finished!`);
            }
          });
        record.raw = orisub;
        record.url = url;
        this.__records__.set(counter, record);
      }),
      (sub: Subscription) => {
        if (this.__records__.has(counter)) {
          const rec = this.__records__.get(counter);
          rec.add(sub);
        }
        sub.add(() => {
          if (this.__records__.has(counter)) {
            const record = this.__records__.get(counter);
            if (record.subscriptions.size === 0) {
              record.ngUnsubscribe.next();
              record.ngUnsubscribe.complete();
              record.raw.unsubscribe();
              // tslint:disable-next-line:no-console
              console.debug(`unsubscribe => `, record);
              this.__records__.delete(counter);
            } else {
              // tslint:disable-next-line:no-console
              console.debug(`more than one subscriptions => `, record);
            }
          }
        });
        return sub;
      }
    );
  }


  private get newCounter() {
    return this.__counter__++;
  }

}
