import { IApplicationRouteRule } from './routing';

export const APP_SITE_ROUTE_CONFIG: {
  rules: IApplicationRouteRule[];
  initialized: boolean;
  noticeIfCrossSite: boolean;
} = {
  rules: [],
  noticeIfCrossSite: false,
  initialized: false,
};
