import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'

export class TimeSpan {
  private left: Date;
  private right: Date;
  private cache: { [key: string]: number } = {};

  constructor(left: Date, right: Date) {
    this.left = new Date(left);
    this.right = new Date(right);
  }

  get totalMilliseconds(): number {
    if (this.cache.totalMilliseconds == null) {
      this.cache.totalMilliseconds = differenceInMilliseconds(
        this.left,
        this.right
      );
    }
    return this.cache.totalMilliseconds;
  }

  get totalSeconds(): number {
    if (this.cache.totalSeconds == null) {
      this.cache.totalSeconds = differenceInSeconds(this.left, this.right);
    }
    return this.cache.totalSeconds;
  }

  get totalMinutes(): number {
    if (this.cache.totalMinutes == null) {
      this.cache.totalMinutes = differenceInMinutes(this.left, this.right);
    }
    return this.cache.totalMinutes;
  }

  get totalHours(): number {
    if (this.cache.totalHours == null) {
      this.cache.totalHours = differenceInHours(this.left, this.right);
    }
    return this.cache.totalHours;
  }

  get totalDays(): number {
    if (this.cache.totalDays == null) {
      this.cache.totalDays = differenceInDays(this.left, this.right);
    }
    return this.cache.totalDays;
  }

  get totalMonths(): number {
    if (this.cache.totalMonths == null) {
      this.cache.totalMonths = differenceInMonths(this.left, this.right);
    }
    return this.cache.totalMonths;
  }

  get totalYears(): number {
    if (this.cache.totalYears == null) {
      this.cache.totalYears = differenceInYears(this.left, this.right);
    }
    return this.cache.totalYears;
  }
}
