import { ChangeDetectionStrategy, Component, Input, HostBinding, Output, HostListener, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'nd-tag',
  template: `
    <div class="nd-tag"
      [ngStyle]="tagStyle"
      [class.nd-tag_checked]="ndChecked"
      [class.nd-tag_checkable]="ndCheckable"
      [class.nd-tag_closable]="ndClosable"
      [class.nd-tag_disabled]="ndDisabled"
      [class.nd-tag_block]="ndBlock"
      [class.nd-tag_small]="ndSize === 'small'"
      [class.nd-tag_large]="ndSize === 'large'"
      [class.nd-tag_with-color]="ndColor"

    >
      <ng-content></ng-content>
      <span (click)="onClose($event)"*ngIf="ndClosable">
        <i class="nd-tag__close-icon" nz-icon nzType="close" nzTheme="outline"></i>
      </span>
      <span class="nd-tag__indicator" *ngIf="ndCheckable">
        <i class="nd-tag__indicator-icon" nz-icon nzType="check" nzTheme="outline"></i>
      </span>
    </div>
  `,
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DidaUITKTagComponent {
  constructor(
    private changeDetector: ChangeDetectorRef) {
  }

  @Input()
  ndChecked = false;

  @Input()
  ndCheckable = false;

  @Output()
  ndChange = new EventEmitter<boolean>();


  @Input()
  ndClosable = false;

  @Output()
  ndClose = new EventEmitter<void>();

  @Input()
  ndDisabled = false;

  @Input()
  ndReverse = false;

  @Input()
  ndValue: any;

  @Input()
  ndSize: 'small' | 'large';

  @Input()
  ndBlock: boolean;

  /**
   * 标签颜色
   */
  @Input()
  ndStyle: any;

  /**
   * 标签颜色
   */
  @Input()
  ndColor: string;

  /**
   * 标签背景透明度
   */
  @Input()
  ndBgOpacity = 0.12

  get tagStyle() {

    if(!this.ndColor && !this.ndStyle) {
      return
    }

    if(!this.ndColor) {
      return this.ndStyle
    }

    if(this.ndReverse) {
      return {
        color: 'white',
        'background-color': this.ndColor,
        ...(this.ndStyle || {}),
      }
    }

    let colorSegs = []
    if(this.ndColor.startsWith('#')) {
      colorSegs = [
        this.ndColor.substr(1, 2),
        this.ndColor.substr(3, 2),
        this.ndColor.substr(5, 2)
      ].map(item => parseInt(item, 16))
    } else if(this.ndColor.startsWith('rgb')) {
      colorSegs = this.ndColor.substring(
        this.ndColor.indexOf('(') + 1,
        this.ndColor.indexOf(')')
      ).split(',')
    }
    let bgColor = ''
    colorSegs[3] = this.ndBgOpacity
    bgColor = `rgba(${colorSegs.join(', ')})`
    return {
      color: this.ndColor,
      'background-color': bgColor,
      ...(this.ndStyle || {}),
    }
  }

  @HostListener('click')
  onHostClick() {
    if (this.ndDisabled || !this.ndCheckable) {
      return;
    }
    this.ndChecked = !this.ndChecked;
    this.ndChange.emit(this.ndChecked);
  }

  onClose($event) {
    // $event.stopPropagation()
    this.ndClose.emit()
  }

  detectChanges() {
    this.changeDetector.detectChanges();
  }
}
