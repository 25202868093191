import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2DidaProviderBase } from './angulartics2-dida.models';
import {
    DidaBehaviorEventTrackingOption,
    ComponentBehaviorCategory,
    BehaviorActionType,
    DidaBehaviorEventUserProperties
} from '../behavior-management.models';
import { LoggerService } from '../logger.service';

@Injectable()
export class Angulartics2DidaGoogleAnalyticsProvider extends Angulartics2GoogleAnalytics implements Angulartics2DidaProviderBase {

    constructor(
        private angulartics2_: Angulartics2,
        private logger: LoggerService,
    ) {
        super(angulartics2_);
    }

    eventTrack(action: string, properties: any): void {
        this.logger.error(`The event is not being tracked. Do not use event track, use 'track' instead.`);
    }

    track(option: DidaBehaviorEventTrackingOption): void {
        super.eventTrack(option.action.toString(), {
            category: option.category,
            label: option.label,
            value: option.value,
            noninteraction: option.noninteraction,
            hitCallback: option.callback,
            page: option.page,
        });
    }

    setUserInfo(username: string, clientId?: string): void {
        super.setUsername(username);
        let userProperty = {
            'dimension1': username,
        };
        if (clientId != null) {
            userProperty['dimension2'] = clientId;
        }
        this.setUserProperties(userProperty);
    }

    setUsername(username: string): void {
        this.logger.error(`Should not call 'setUsername', use 'setUserInfo' instead.`);
    }
}
