import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaodeMapsComponent, GaodeMapConfigModel } from './gaode-maps.component';
import { GaodeMapsRadarToolkitComponent } from './radar-toolkit/gaode-maps-radar-toolkit.component';
import { GaodeMapMarkerDirective, GaodeMapMarkerEvent } from './gaode-map-marker.directive';


@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    GaodeMapsComponent,
    GaodeMapsRadarToolkitComponent,
    GaodeMapMarkerDirective,
  ],
  declarations: [
    GaodeMapsComponent,
    GaodeMapsRadarToolkitComponent,
    GaodeMapMarkerDirective,
  ],  
  providers: []
})
export class GaodeMapsModule { }
