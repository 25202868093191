import {
  GaodeMapLanguageType,
  GaodeMapVectorForeignMapping,
  GaodeMapVectorMapForeignType,
  IGaodeMapOptionModel
} from './models';
import { GeoBoundary2D, GeoCoordinate } from '@dida-shopping/dida-services/geography';

const GaodeMapVersion = [1, 4, 15];

declare let AMap: any;

export interface IGaodeMapGeoCoder {
  getAddress(coordinate: [number, number], cb: (status: string, result: any) => void);
}

const DIDA_GAODE_MAP_ID_KEY = '__DIDA_GAODE_MAP_ID_KEY__';

class GaodeMapService {
  private static _gaode_map_inst_counter_ = 0;
  private _gaode_LatKey: string;
  private _gaode_LngKey: string;

  private readonly _map_version: string;
  // private _map: any;
  // private _mapPromise: Promise<any>;
  // private _mapResolver: (map?: any) => void;
  private _mapJSLoaded: boolean;
  private _mapJsLoadPromise: Promise<void>;
  private readonly _map_inst_: { [key: string]: any };

  constructor() {
    this._map_version = GaodeMapVersion.join('.');
    this._map_inst_ = [];
    this._mapJSLoaded = false;
  }

  get AMap() {
    return AMap;
  }

  public async createMap(el: any, options: IGaodeMapOptionModel): Promise<any> {
    // this._map = null;
    let key: string = null;
    let map: any = null;
    await this.ensureMapJsLoaded().then(() => {
      const lang: GaodeMapLanguageType = options.lang || 'zh_en';
      let vectorMapForeign: GaodeMapVectorMapForeignType = 'cn';
      if (lang === 'en') {
        vectorMapForeign = 'en';
      } else if (lang === 'zh_en' || lang === 'zh_cn') {
        vectorMapForeign = 'cn';
      }
      const mapOptions = {
        ...options,
        vectorMapForeign: GaodeMapVectorForeignMapping[this._map_version][vectorMapForeign],
        lang: lang
      };
      console.log(`create gaode map with: `, mapOptions);
      key = `amap_${this._map_version}_${GaodeMapService._gaode_map_inst_counter_++}`;
      map = new AMap.Map(el, mapOptions);
      map[DIDA_GAODE_MAP_ID_KEY] = key;
      this._map_inst_[key] = map;
      this.detectLngLat();
    });
    return map;
  }

  public disposeMap(inst: any) {
    const key = typeof inst === 'string' ? inst : (inst && inst[DIDA_GAODE_MAP_ID_KEY]);
    if (this._map_inst_[key]) {
      inst = inst || this._map_inst_[key];
      delete this._map_inst_[key];
    } else {
      inst = null;
    }
    if (inst) {
      inst.clearMap();
      inst.destroy();
    }
  }

  public async convertGPSGeoCoordinate(lng: number, lat: number, countryCode: string): Promise<GeoCoordinate> {
    return new Promise<GeoCoordinate>(resolve => {
      let geoPos = new GeoCoordinate(lat, lng);
      // const lngLen = lng.toString().split('.')[1].length;
      // const latLen = lng.toString().split('.')[1].length;
      if (
        countryCode && countryCode.toLowerCase() === 'cn'
        // || (lngLen <= 6 && latLen <= 6)
      ) {
        resolve(geoPos);
      } else {
        const mapType = 'gps';
        this.ensureMapJsLoaded().then(() => {
          AMap.convertFrom(geoPos.gaodeCoordinate, mapType, (status, result) => {
            if (result.info === 'ok') {
              const gaodeLngLat = result.locations[0];
              geoPos = this.convertLngLatToGeoCoordinate(gaodeLngLat);
            }
            resolve(geoPos);
          });
        }).catch(() => {
          resolve(geoPos);
        });
      }
    });
  }

  public convertLngLatToGeoCoordinate(lnglat: any) {
    if (lnglat) {
      if (this._gaode_LatKey && this._gaode_LngKey) {
        return new GeoCoordinate(lnglat[this._gaode_LatKey], lnglat[this._gaode_LngKey]);
      }
      return new GeoCoordinate(lnglat.getLat(), lnglat.getLng());
    }
    return null;
  }

  public getMapBoundary(map: any): GeoBoundary2D {
    let boundary: GeoBoundary2D = null;
    const mapInst = typeof map === 'string' ? this._map_inst_[map] : map;
    if (mapInst) {
      const bounds = mapInst.getBounds();
      const center = mapInst.getCenter();
      const ne = this.convertLngLatToGeoCoordinate(bounds.northeast); // new GeoCoordinate(bounds.northeast.P, bounds.northeast.Q);
      const sw = this.convertLngLatToGeoCoordinate(bounds.southwest); // new GeoCoordinate(bounds.southwest.P, bounds.southwest.Q);
      const ct = this.convertLngLatToGeoCoordinate(center); // new GeoCoordinate(center.P, center.Q);
      boundary = new GeoBoundary2D(ne, sw, ct);
    }
    return boundary;
  }

  public buildGeoCoder(city: string, lang: GaodeMapLanguageType, radius: number): Promise<Partial<IGaodeMapGeoCoder>> {
     return new Promise<Partial<IGaodeMapGeoCoder>>(resolve => {
       this.ensureMapJsLoaded().then(() => {
         resolve(new AMap.Geocoder({ city, lang, radius }));
       }).catch(() => {
         resolve(null);
       });
     });
  }

  private detectLngLat() {
    if (!this._gaode_LatKey || !this._gaode_LngKey) {
      const lat = 13.678441029687267;
      const lng = 100.578857421875;
      const inst = new AMap.LngLat(lng, lat);
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZA';
      for (let i = 0; i < alphabet.length - 1; i++) {
        const latKey = alphabet[i];
        const lngKey = alphabet[i + 1];
        if (inst[latKey] === lat && inst[lngKey] === lng) {
          console.log(`current keys: `, latKey, lngKey, inst);
          this._gaode_LatKey = latKey;
          this._gaode_LngKey = lngKey;
        }
      }
    }
  }

  private ensureMapJsLoaded(): Promise<void> {
    if (!this._mapJsLoadPromise) {
      this._mapJsLoadPromise = new Promise((resolve => {
        if (document) {
          const scriptEl = document.createElement('script');
          scriptEl.id = `gaodeMapApiScript`;
          scriptEl.async = true;
          scriptEl.src = `https://webapi.amap.com/maps?v=${this._map_version}&key=649aecc7415e9ef603eec3fb6f9d5f5e&plugin=AMap.Geocoder`;
          scriptEl.onload = () => {
            this._mapJSLoaded = true;
            resolve();
          };
          document.body.appendChild(scriptEl);
        }
      }));
    }
    return this._mapJsLoadPromise;
  }

}


export const gaodeMapService = new GaodeMapService();
