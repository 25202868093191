export enum SuggestionItemCategory {
    Hotel = 0,
    POI = 1,
    POIS = 2,
    Neighborhood = 3,
    City = 4,
    MultiCity = 5,
    MultiRegion = 6,
    Province = 7,
    Country = 8,
    Continent= 9,
    TrainStation = 10,
    MetroStation = 11,
    HighLevelRegion = 12,
    Airport = 13,
    Unknown = 100,
}



// public enum RegionTypeEnumType
// {
//     [Description("Point of Interest")]
//     PointOfInterest = 1,

//     [Description("Point of Interest Shadow")]
//     PointOfInterestShadow = 2,

//     [Description("Neighborhood")]
//     Neighborhood = 3,

//     [Description("City")]
//     City = 4,

//     [Description("Multi-City (Vicinity)")]
//     MultiCity = 5,

//     [Description("Multi-Region (within a country)")]
//     MultiRegion = 6,

//     [Description("Province (State)")]
//     Privince = 7,

//     [Description("Country")]
//     Country = 8,

//     [Description("Continent")]
//     Continent = 9,

//     [Description("Train Station")]
//     TrainStation = 10,

//     [Description("Metro Station")]
//     MetroStation = 11,

//     [Description("High Level Region")]
//     HighLevelRegion = 12,
// }
