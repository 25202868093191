import { MessageSketchModel } from './message-sketch.model';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { MessageStatusTypeEnum } from '../../enums/message-status-type';

// from UserMessageModel
export class MessageModel extends MessageSketchModel {
    Language: LanguageType;
    ClientID: string;
    Priority: number;
    Title: string;
    Author: string;
    Summary: string;
    Content: string;
    Source: string;
    UserMessageStatus: MessageStatusTypeEnum;
    UpdateTime: string;
    ReadTime: string;

    IsSelected: boolean; // 是否选择状态，用于界面上操作
}
