import { LanguageType } from '../../i18n';
import { ResourceBase } from '../../common/models';

/**
 * 国籍目录是一个从A-Z排序的索引目录
 * + 其中`index`可以直接通过CountryCode首字母在字母表位置（比如，A为0，Z为25）直接进行提取
 * + 在每一个目录下的值`items`，则是通过CountryCode从A-Z排序
 */
export type NationalityDirectoryStruct = INationalityDirectoryItem[];

export interface INationalityDirectoryItem {
  /**
   * 索引（CountryCode首字母）
   */
  indexChar: string;
  /**
   * 索引（CountryCode首字母在字母表的位置，比如A对应0）
   */
  index: number;
  items: Nationality[];
}

export const DEFAULT_NATIONALITY_CODE = 'CN';

export interface INationality {
  code: string
  name: string
  name_cn: string
  name_en: string
  highPriority: boolean
  order: number
}

export class Nationality extends ResourceBase<string> {
  highPriority: boolean;

  // ROMAN: 由于小程序的View里似乎用getter无效，所以用固定属性代替
  // get name_cn() {
  //   console.log(`hey!`, this.i18n, this.displayName(LanguageType.zh_CN));
  //   return this.displayName(LanguageType.zh_CN);
  // }
  //
  // get name_en() {
  //   return this.displayName(LanguageType.en_US);
  // }

  constructor(
    code: string,
    name: string,
    highPriority = false,
    order = -1,
    lang: LanguageType = LanguageType.en_US
  ) {
    super(code, name, order, lang);
    this.highPriority = highPriority;
  }

  addLangSetting(lang: LanguageType, name: string): Nationality {
    super.addLangSetting(lang, name);
    return this;
  }

  clone(): Nationality {
    return super.clone() as Nationality;
  }

  /**
   * 转成普通对象
   * redux传入 non-serializable对象有坑
   */
  plain(): INationality {
    return {
      code: this.code,
      name: this.name,
      name_cn: this.name_cn,
      name_en: this.name_en,
      highPriority: this.highPriority,
      order: this.order
    }
  }

  protected constructSelf(): Nationality {
    return new Nationality(this.code, this.name, this.highPriority, this.order);
  }
}
