import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2DidaGoogleAnalyticsProvider, Angulartics2DidaBaiduAnalyticsProvider } from './providers';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { DidaBehaviorEventTrackingOption } from '@dida-shopping/dida-services/bm';

/**
 * 网站用户行为管理服务,不影响业务本身
 */
@Injectable({
  providedIn: 'root'
})
export class BehaviorManagementService {
  private currentPage = '';

  constructor(
    private angulartics2: Angulartics2,
    private googleAnalytics: Angulartics2DidaGoogleAnalyticsProvider,
    private baiduAnalyticsProvider: Angulartics2DidaBaiduAnalyticsProvider,
    private router: Router,
    private location: Location,
  ) {
    this.googleAnalytics.startTracking();
    this.baiduAnalyticsProvider.startTracking();

    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => !this.angulartics2.settings.developerMode),
    ).subscribe((event: NavigationEnd) => {
      const path = this.getPagePath(event.urlAfterRedirects);
      if (this.angulartics2.settings.pageTracking.basePath.length) {
        this.currentPage = this.angulartics2.settings.pageTracking.basePath + path;
      } else {
        this.currentPage = this.location.prepareExternalUrl(path);
      }
    });
  }

  initUserSession(username: string, clientId: string) {
    try {
      this.googleAnalytics.setUserInfo(username, clientId);
      this.baiduAnalyticsProvider.setUserInfo(username, clientId);
    } catch (e) {
      console.error(`init user session failed.`, e);
    }
  }

  trackEvent(option: DidaBehaviorEventTrackingOption) {
    try {
      if (option.page == null) {
        option.page = this.currentPage;
      }
      this.googleAnalytics.track(option);
      this.baiduAnalyticsProvider.track(option);
    } catch (e) {
      console.error(`track event failed.`, e);
    }
  }


  protected getPagePath(path: string): string {
    if (this.angulartics2.settings.pageTracking.clearIds || this.angulartics2.settings.pageTracking.clearQueryParams) {
      return path
        .split('/')
        .map(part => this.angulartics2.settings.pageTracking.clearQueryParams ? part.split('?')[0] : part)
        .filter(part => !this.angulartics2.settings.pageTracking.clearIds ||
          !part.match(this.angulartics2.settings.pageTracking.idsRegExp))
        .join('/');
    }
    return path;
  }

}
