import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';
import { EnumDateSelectType } from '../../models/EnumDefine.Model';
import { ApplicationService, BehaviorManagementService } from '@dida-shopping/ngx-dida-site-core';
import {
  ComponentBehaviorCategory,
  BehaviorActionType
} from '../../../../../core/logger/behavior-management.models';
import { CalendarComponent } from '../calendar/calendar.component';
import { CDateHelper } from '../../common/CDateHelper';
import { CalendarStyleModel } from '../../models/CalendarStyle.Model';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

@Component({
  selector: 'dida-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class DateRangePickerComponent implements AfterViewInit {
  active = false; // 如果active是true，则显示红色下划线
  today: Date = new Date();

  @Input()
  showLabel = true;
  @Input()
  showSummary = false;

  @Input()
  minDate = new Date();
  @Input()
  maxDate = new Date(
    this.minDate.getFullYear() + 1,
    this.minDate.getMonth(),
    this.minDate.getDate()
  );

  @Input()
  fromDate: Date = new Date();
  @Output()
  fromDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input()
  toDate: Date = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate() + 1
  );
  @Output()
  toDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output()
  onChange: EventEmitter<{ fromDate: Date; toDate: Date }> = new EventEmitter<{
    fromDate: Date;
    toDate: Date;
  }>();
  @Output()
  onCalendarClose: EventEmitter<{
    fromDate: Date;
    toDate: Date;
  }> = new EventEmitter<{
    fromDate: Date;
    toDate: Date;
  }>();

  @Input()
  minDays = 2; // 最少选择日期，默认2天

  @Input()
  maxDays = 3650;

  @Input()
  readonly = false;

  @Input()
  style: null | 'normal' | 'normal-sm' | 'mini' | 'simple' = 'normal';

  get styleClass(): string {
    if (this.style === 'normal' || this.style === 'normal-sm') {
      return 'date-range-picker';
    }
    return `date-range-picker-${this.style}`;
  }

  dateType: EnumDateSelectType = EnumDateSelectType.None;

  get fromDateStr(): string {
    return CDateHelper.getDateString(this.fromDate, 'yyyy-MM-dd');
  }

  get toDateStr(): string {
    if (!this.toDate) {
      return this.appService.context.lang === LanguageType.zh_CN
        ? '结束日期'
        : 'End Date';
    }
    return CDateHelper.getDateString(this.toDate, 'yyyy-MM-dd');
  }

  constructor(
    private element: ElementRef,
    private bmService: BehaviorManagementService,
    private appService: ApplicationService
  ) {
  }

  @ViewChildren('dateCalendarZone')
  dateCalendarItemList: QueryList<ElementRef>;

  @ViewChild('dateCalendar', { static: true })
  dateCalendar: CalendarComponent;

  get calendarStyle(): CalendarStyleModel {
    return this.getCalendarStyle(this.style);
  }

  ngAfterViewInit() {
  }

  getCalendarStyle(style: string): CalendarStyleModel {
    switch (style) {
      case 'normal-sm':
        return new CalendarStyleModel(-20, 25, true, 30, 125);
      case 'simple':
        return new CalendarStyleModel(-290, 25, true, 310, 410);
      case 'normal':
      case 'mini':
      default:
        return new CalendarStyleModel(-20, 25, true, 50, 165);
    }
  }

  /**
   * 点击日历以外的区域时关闭日历
   *
   * @param {*} event
   * @returns {void}
   * @memberof DateRangePickerComponent
   */
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    /**
     * 部分元素className不是字符串，不能使用className.indexOf判断样式类是否存在
     */
    if (event.target.classList.contains('ignore')) {
      return; // 这里做个特殊处理，因为点了上一个月和下一个月按钮的时候，整个组件会刷新，刷新后，item变成新的html，但event.target还是旧的，contains会不生效
    }
    let needClose = true;
    if (this.dateCalendarItemList) {
      this.dateCalendarItemList.forEach(it => {
        let item;
        if (it.nativeElement) {
          item = it.nativeElement;
        } else if ((it as any).element) {
          item = (it as any).element.nativeElement;
        }
        if (item.contains(event.target)) {
          // 发现html button按钮不能contains
          needClose = false;
        }
      });
    }
    if (this.dateCalendar && needClose) {
      this.dateCalendar.closeCalendar();
      // this.onCalendarClose.emit({ fromDate: this.fromDate, toDate: this.toDate });
    }
  }

  setDateType(dateType: EnumDateSelectType) {
    if (this.readonly) {
      return;
    }
    this.dateCalendar.showCalendar(dateType);
    this.dateType = dateType;
  }

  fromDateChanged($event: Date) {
    this.bmService.trackEvent({
      category: ComponentBehaviorCategory.DateRangePicker,
      action: BehaviorActionType.Select,
      label: `FromDate`,
      value: 1
    });
    this.fromDateChange.emit(this.fromDate);
    this.onChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }

  toDateChanged($event: Date) {
    if ($event) {
      // 有设置toDate的时候再emit出去，否则页面可能会报错
      this.bmService.trackEvent({
        category: ComponentBehaviorCategory.DateRangePicker,
        action: BehaviorActionType.Select,
        label: `ToDate`,
        value: 1
      });
      this.fromDateChange.emit(this.fromDate);
      this.toDateChange.emit(this.toDate);
      this.onChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
    }
  }

  selectTypeChanged($event: EnumDateSelectType) {
    this.dateType = $event;
  }

  // 这里算的是'晚'数量，下面的'staySimpleDays'是天数
  get stayNights(): number {
    if (this.fromDate && this.toDate) {
      return CDateHelper.getDays(this.toDate, this.fromDate);
    }
    return -1;
  }

  get stayDays(): number {
    return this.stayNights + 1;
  }
}
