import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { authenticationService } from '@dida-shopping/dida-services/auth';
import { ConditionCheckError, ngxHttpCall } from '@dida-shopping/ngx-dida-site-core';
import { StandardErrorModel } from '@dida-shopping/dida-services/http';
import { Router } from '@angular/router';
import { DEFAULT_USER } from './debug-login-data';

@Component({
  selector: 'nd-debug-login',
  templateUrl: './debug-login.component.html',
  styleUrls: ['./debug-login.component.scss']
})
export class DidaUITKDebugLoginComponent implements OnInit, AfterViewInit {

  ConditionCheckError = ConditionCheckError;

  @Input() returnUrl: string;
  @Input() reason: ConditionCheckError;

  loading: boolean;
  error: StandardErrorModel;
  loginForm: FormGroup;

  constructor(
    private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // 在 debug-login 目录下运行
    // git update-index --assume-unchanged debug-login-data.ts
    // 就可以放心改改改了
    this.loginForm = this.formBuilder.group({
      'userName': [DEFAULT_USER.userName, [Validators.required]],
      'password': [DEFAULT_USER.password, [Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    if (DEFAULT_USER.userName) {
      this.login();
    }
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      ngxHttpCall(
        authenticationService.login({
          LoginIdentifier: this.loginForm.get('userName').value,
          Password: this.loginForm.get('password').value,
          RememberMe: true,
        })
      ).subscribe({
        next: () => {
          this.error = null;
        },
        error: (err: StandardErrorModel) => {
          this.error = err;
          this.loginForm.get('password').setErrors({
            'login': err.message
          });
        }
      }).add(() => {
        this.loading = false;
        if (!this.error) {
          this.router.navigate([this.returnUrl || window.location.href], {
            queryParams: {
              'from-login': true
            }
          }).then();
          this.modal.close(true);
        }
      });
    } else {
      for (const i in this.loginForm.controls) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.loginForm.controls.hasOwnProperty(i)) {
          this.loginForm.controls[i].markAsDirty();
          this.loginForm.controls[i].updateValueAndValidity();
        }
      }
    }
  }

}
