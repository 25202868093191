import { Occupancy } from './occupancy.model';

export class RealTimeFilter {
    IsRealTime = false;
    RoomCount = 1;
    OccupancySetting: Occupancy = new Occupancy();
    // TimeOut?: number;
    // ReplaceByCacheWhenTimeout?: boolean;
    // TimeOutNeedThrowException?: boolean;
}
