
export class ImageLinkI18nModel {
  constructor(payload?: ImageLinkI18nModel) {
    if(payload) {
      Object.assign(this, payload)
    }
  }
  Title: string;
  SubTitle: string;
  Desc: string;
  Reference: string;
  ImgUrl: string;
  ImgUrl2: string;
  // ImgPreview: string;
}
