import { Component, OnInit, Input } from '@angular/core';
import { MessageCategoryTypeEnum } from '../../../enums/message-category-type';
import { TicketOperations } from '../../../enums/kangaroo/ticket-operations.enum';

@Component({
  selector: 'app-message-category-type',
  templateUrl: './message-category-type.component.html',
  styleUrls: ['./message-category-type.component.scss']
})
export class MessageCategoryTypeComponent implements OnInit {
  @Input()
  type: MessageCategoryTypeEnum;

  @Input()
  ticketOperation: TicketOperations = null;

  CategoryType = MessageCategoryTypeEnum;
  TicketOperations = TicketOperations;

  constructor() {}

  ngOnInit() {}
}
