import { Directive, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { SERVER_CONFIG } from '../app-server-config';
import { IDidaApplicationUserContextModel, IDidaUserIdentityInfoModel } from '@dida-shopping/dida-services/auth';
import {
  IClientModel,
  IUserCurrencyInfoModel,
  IUserPointAccountModel,
  IUserProfileModel
} from '@dida-shopping/dida-services/membership';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import {
  ExperimentControls,
  ExperimentHelper,
  ExperimentID,
  IExperimentControls
} from '@dida-shopping/dida-services/experiment';
import { ErrorCode } from '@dida-shopping/dida-services/common';
import { ExternalRoutingHelper } from '../routing';
import { APP_CONTEXT_STORAGE, IApplicationContextStorage } from '@dida-shopping/dida-services/storage';
import { APP_VERSION } from '../app-verison';
import { I18nHelper } from '../i18n';
import { PRESERVED_DEV_CLIENTS } from '../app-routing-data.model';

/**
 * 抽象的虚拟的组件类
 */
@Directive()
abstract class AbstractComponentBase implements OnInit, OnDestroy {

  private static _instanceCount = 0
  private readonly _instanceId: number;
  get instanceId() {
    return this._instanceId
  }

  protected subscriptions: { [key: string]: Subscription } = {};
  protected destroySignal$: Subject<boolean> = new Subject<boolean>();

  protected constructor() {
    this._instanceId = AbstractComponentBase._instanceCount++
    console.debug(`[AbstractComponentBase] Component Created: ${this.constructor.name}`, this)
  }

  ngOnInit() {
    console.debug(`[ngOnInit]:${this.constructor.name}`);
  }

  ngOnDestroy() {
    console.debug(`[ngOnDestroy]:${this.constructor.name}`);
    this.destroySignal$.next(true);
    if (this.subscriptions) {
      for (const key in this.subscriptions) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.subscriptions.hasOwnProperty(key)) {
          const subscription = this.subscriptions[key];
          if (subscription) {
            subscription.unsubscribe();
          }
        }
      }
    }
  }

}

/**
 * 组件基础类（一般不需要ApplicationContext信息的组件引用）
 */
@Directive()
export abstract class ComponentBase extends AbstractComponentBase  {
  // private static readonly locale: string = I18nHelper.getApplicationLocale();

  public readonly ServiceConfig = SERVER_CONFIG; //Object.assign({}, );
  public readonly AppVersion = APP_VERSION;  // Object.assign({}, );
  public readonly ExperimentID = ExperimentID;
  public readonly ExperimentControls = ExperimentControls;
  public readonly ErrorCode = ErrorCode;
  public readonly isDev: boolean = isDevMode();

  public get lang(): LanguageType {
    return <LanguageType>I18nHelper.getApplicationLocale();
  };

  public readonly isCN: boolean = this.lang === LanguageType.zh_CN;

  isLoading = true;
  isUploading = true;

  protected constructor() {
    super();
    // console.log(this.lang);
  }

  getExternalUrl(location: string): string {
    return ExternalRoutingHelper.constructActualExternalUrl(location);
  }

  goToAnchor(anchor: string, offset = 0) {
    let target = document.querySelector(`#${anchor}`) as HTMLElement;
    if (target) {
      let offsetTop = -offset;
      do {
        offsetTop += target.offsetTop;
        target = target.offsetParent as HTMLElement;
      } while (target.offsetParent === document.body);
      document.documentElement.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: offsetTop
      });
    }
  }
}

/**
 * 带ApplicationContext的组件基础类（需要ApplicationContext信息的组件引用）
 */
@Directive()
export abstract class ComponentBaseWithContext extends ComponentBase  {

  get showEanInfo() {
    return true
  }

  get appContext(): IDidaApplicationUserContextModel {
    return this.ctxStorage.context;
  }

  get userIdentity(): IDidaUserIdentityInfoModel {
    return this.ctxStorage.userIdentityInfo;
  }

  get userProfile(): IUserProfileModel {
    return this.ctxStorage.userProfile;
  }

  get clientInfo(): IClientModel {
    return this.ctxStorage.clientInfo;
  }

  get currencyInfo(): IUserCurrencyInfoModel {
    return this.ctxStorage.currencyInfo;
  }

  get hasUserLogin(): boolean {
    return this.userIdentity && this.userIdentity.isAuthenticated;
  }

  get userPointInfo(): IUserPointAccountModel {
    return this.appContext && this.appContext.userPointAccountInfo;
  }

  get isDevClient(): boolean {
    return this.clientInfo && PRESERVED_DEV_CLIENTS.indexOf(this.clientInfo.ClientID) >= 0;
  }

  public isAllowControl(control: IExperimentControls): boolean {
    return ExperimentHelper.isAllowControl(control, this.ctxStorage);
  }

  public hasAnyExperiments(exps: ExperimentID[]): boolean {
    return ExperimentHelper.hasAnyExperiments(exps, this.ctxStorage);
  }

  protected constructor(
    protected ctxStorage: IApplicationContextStorage = APP_CONTEXT_STORAGE
  ) {
    super();
    if (!ctxStorage) {
      throw new Error(`ctxStorage is required!`);
    }
  }

}
