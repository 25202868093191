// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { IApplicationEnvironment } from '@dida-shopping/dida-services/common';

export const environment: IApplicationEnvironment = {
  production: true,
  localDebug: false,
  proxyEnabled: false,
  ignoreBuildNumber: true,
  localeCookieName: 'PP_dida-locale',
  //hmr: false,
  oldSite: '', // 'v1-test.didatravel.com',
  service: {
    endpoint: 'https://shoppingwebapi-test.didatravel.com', // 'http://webapi-test.shopping.didatravel.com',
  },
  googleMap: {
    apiHost: '//ditu.google.cn',
    apiKey: 'AIzaSyDK9ZspgnjH6AQi2iRrZNtJX4Xl-MPu6-g', // ProdKey-Referer
  },
};
