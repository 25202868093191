import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  EmbeddedViewRef,
  SimpleChanges,
  OnChanges,
  SimpleChange
} from '@angular/core';

@Directive({
  selector: '[didaStringTemplateOutlet]'
})
export class StringTemplateOutletDirective implements OnChanges {
  private isTemplate: boolean;
  private inputTemplate: TemplateRef<any> | null = null;
  private inputString: string | null = null;
  private inputViewRef: EmbeddedViewRef<void> | null = null;
  private defaultViewRef: EmbeddedViewRef<void> | null = null;
  constructor(
    private viewContainer: ViewContainerRef,
    private defaultTemplate: TemplateRef<void>
  ) {}

  @Input()
  set didaStringTemplateOutlet(value: string | TemplateRef<any>) {
    if (value instanceof TemplateRef) {
      this.isTemplate = true;
      this.inputTemplate = value;
    } else {
      this.isTemplate = false;
      this.inputString = value;
    }
  }

  get didaStringTemplateOutlet(): string | TemplateRef<any> {
    if (this.isTemplate) {
      return this.inputTemplate;
    } else {
      return this.inputString;
    }
  }

  @Input() public didaStringTemplateOutletContext: Object;

  ngOnChanges(changes: SimpleChanges) {
    const recreateView = this.shouldRecreateView(changes);
    if (recreateView) {
      this.updateView();
    } else {
      if (this.inputViewRef && this.didaStringTemplateOutletContext) {
        this.updateExistingContext(this.didaStringTemplateOutletContext);
      }
    }
  }

  private updateView() {
    if (!this.isTemplate) {
      /** use default template when input is string **/
      if (!this.defaultViewRef) {
        this.viewContainer.clear();
        this.inputViewRef = null;
        if (this.defaultTemplate) {
          this.defaultViewRef = this.viewContainer.createEmbeddedView(
            this.defaultTemplate
          );
        }
      }
    } else {
      /** use input template when input is templateRef **/
      if (!this.inputViewRef) {
        this.viewContainer.clear();
        this.defaultViewRef = null;
        if (this.inputTemplate) {
          this.inputViewRef = this.viewContainer.createEmbeddedView(
            this.inputTemplate,
            this.didaStringTemplateOutletContext
          );
        }
      }
    }
  }

  private shouldRecreateView(changes: SimpleChanges): boolean {
    const ctxChange = changes['didaStringTemplateOutletContext'];
    return (
      !!changes['didaStringTemplateOutlet'] ||
      (ctxChange && this.hasContextShapeChanged(ctxChange))
    );
  }

  private hasContextShapeChanged(ctxChange: SimpleChange): boolean {
    const prevCtxKeys = Object.keys(ctxChange.previousValue || {});
    const currCtxKeys = Object.keys(ctxChange.currentValue || {});

    if (prevCtxKeys.length === currCtxKeys.length) {
      for (const propName of currCtxKeys) {
        if (prevCtxKeys.indexOf(propName) === -1) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  private updateExistingContext(ctx: Object): void {
    for (const propName of Object.keys(ctx)) {
      (<any>this.inputViewRef.context)[propName] = (<any>(
        this.didaStringTemplateOutletContext
      ))[propName];
    }
  }
}
