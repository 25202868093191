
// import { LoggerService } from '../../logger/logger.service';
import { Subject } from 'rxjs';
import { share, filter } from 'rxjs/operators';
import { RateStateHelper } from './rate-state.helper';
import { HotelRateStateModel } from './models/hotel-rate-state.model';
import { localStorageService } from '../storage/localstorage.service';

export const KEY_HotelRateState = 'DIDA_SHOPPING_RATE_STATE';

class RateStateService {
  private rateStateSubject = new Subject<{
    data: { [key: string]: HotelRateStateModel };
    external: boolean;
  }>();

  public changes = this.rateStateSubject.asObservable().pipe(share());

  constructor(
  ) {
    localStorageService.changes.pipe(
      filter(e => e.key === KEY_HotelRateState))
      .subscribe(event => {
        let data = {};
        if (event.value != null) {
          data = JSON.parse(event.value);
        }
        this.rateStateSubject.next({
          data: data,
          external: event.external
        });
      });
  }

  public update(item: HotelRateStateModel) {
    const items = this.getItems();
    const key = RateStateHelper.getItemKey(item);
    items[key] = item;
    this.saveItems(items);
  }

  public remove(item: HotelRateStateModel) {
    const items = this.getItems();
    const key = RateStateHelper.getItemKey(item);
    if (items[key]) {
      delete items[key];
      this.saveItems(items);
    }
  }

  public get(key: string): HotelRateStateModel {
    const items = this.getItems();
    return items[key];
  }

  private saveItems(items: { [key: string]: HotelRateStateModel }) {
    const valueString = JSON.stringify(items);
    localStorageService.save(KEY_HotelRateState, valueString);
  }

  private getItems(): { [key: string]: HotelRateStateModel } {
    const jsonValueString = localStorageService.get(KEY_HotelRateState);
    let result = {};
    if (jsonValueString) {
      result = JSON.parse(jsonValueString);
    }
    return result;
  }
}

export const rateStateService = new RateStateService()
