
export enum ApplicationMessageTypeEnum {
  ContextUpdate = 'CONTEXT_UPDATE',
  CurrencyChange = 'CURRENCY_CHANGE',
  LanguageChange = 'LANGUAGE_CHANGE',
  ClientInfoUpdate = 'CLIENT_INFO_UPDATE',
  ExperimentInfoChange = 'EXPERIMENT_INFO_CHANGE',
  RouteDataChange = 'RouteDataChange',
  NavbarNoticeChange = 'NAVBAR_NOTICE_CHANGE'
}

