export enum TicketOperations {
  None = 0,
  Add = 1,
  Reply = 2,
  Follow = 3,
  Assign = 4,
  Handover = 5,
  Summary = 6,
  Complete = 7
}
