
import { Md5 } from 'ts-md5/dist/md5';
import { enc, AES, mode, pad} from 'crypto-js';

export class SigningHelper {
  static verifySignature(sign: string, userName: string, outsign: string): boolean {
    const value = Md5.hashStr(`${sign}${userName}`).toString();
    return value === outsign;
  }

  static generateSignature(requestParams: { [name: string]: any }): string {
    let raw = `Did@`;
    Object.keys(requestParams).sort().forEach(key => {
      if (Object.prototype.hasOwnProperty.call(requestParams, key)) {
        if (requestParams[key] != null) {
          raw += `${key}:${requestParams[key]};`;
        }
      }
    });
    raw += `Tr@v3l`;
    const sign = Md5.hashStr(raw).toString();
    return sign;
  }

  static decode(encrypted: string, sign: string, stamp: string): string {
    const deKey = enc.Utf8.parse(sign);
    const iv = enc.Utf8.parse(stamp.substr(0, 16));
    const encryptedHexStr = enc.Hex.parse(encrypted);
    const source = enc.Base64.stringify(encryptedHexStr);
    const decrypted = AES.decrypt(source, deKey, {
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });
    const realValue = decrypted.toString(enc.Utf8);
    return realValue;
  }

}
