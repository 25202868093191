import { HotelChainModel, DidaHotelSearchPayload } from '@dida-shopping/dida-services/hotel';
import { HotelPriceSearchOptions } from '@dida-shopping/dida-services/rate';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
import { HttpServiceBase } from '@dida-shopping/dida-services/http';


class HotelSearchContextService extends HttpServiceBase {
  public payload: DidaHotelSearchPayload;

  constructor() {
    super()
  }

  /**
   * 获取酒店品牌
   * @param {HotelPriceSearchOptions} options
   * @returns {HttpObservable<HotelChainModel>}
   * @memberof HotelSearchContextService
   */
  getHotelChainList(
    options: HotelPriceSearchOptions
  ) {
    return this.httpClientWrapper.post<HotelChainModel[]>(
      '/HotelApi/GetHotelChainList',
      {
        options: options
      },
      {
        payloadProcessor: payload => {
          const option: HotelPriceSearchOptions = payload.options;
          if (option.PriceSearchFilter && option.PriceSearchFilter.Location) {
            if (option.PriceSearchFilter.Location instanceof GeoCoordinate) {
              option.PriceSearchFilter.Location =
                option.PriceSearchFilter.Location.serviceCoordinate;
            }
          }
          return payload
        }
      }

    );
  }
}

export const hotelSearchContextService = new HotelSearchContextService()
