import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RoomOccupancyPickerService {
  init: Subject<void> = new Subject<void>();
  tipsChange: Subject<string[]> = new Subject<string[]>();
  constructor() { }

}
