// import { EanRequest } from '../common/ean/ean-request.model';

import { HotelPriceSearchFilter } from './hotel-price-search-filter.model';
import { LowestRateFilter } from './lowest-rate-filter.model';
import { RealTimeFilter } from './realtime-filter.model';
import { SortFilter } from './sort-filter.model';

/**
 * 酒店价格查询选项
 *
 * @export
 * @class HotelPriceSearchOptions
 */
export class HotelPriceSearchOptions {

  constructor() {
    this.RealTimeFilter = new RealTimeFilter();
    this.LowestRateFilter = new LowestRateFilter();
    this.PriceSearchFilter = new HotelPriceSearchFilter();
    this.SortFilter = new SortFilter();
  }

  // ClientID: string;
  // UserID: string;

  // /**
  //  *
  //  *
  //  * @type {string[]}
  //  * @memberof HotelPriceSearchOptions
  //  */
  // SourceMaskList: string[];

  // /**
  //  * SupplierIDMaskHourKey. See: DidaShopping.Models.Auth.DidaApplicationUserContextModel --> SupplierIDMaskHourKey
  //  *
  //  * @type {string}
  //  * @memberof HotelPriceSearchOptions
  //  */
  // MHK: string;

  /**
   * 供应商ID列表
   *
   * @type {string[]}
   * @memberof HotelPriceSearchOptions
   */
  SupplierIDList: string[];

  /**
   * 入住日期
   *
   * @type {Date}
   * @memberof HotelPriceSearchOptions
   */
  CheckInDate: Date;

  /**
   * 离店日期
   *
   * @type {Date}
   * @memberof HotelPriceSearchOptions
   */
  CheckOutDate: Date;

  /**
   * 目的地ID
   *
   * @type {string}
   * @memberof HotelPriceSearchOptions
   */
  DestinationID?: string;


  /**
   * List<RegionID>
   *
   * @type {string}
   * @memberof HotelPriceSearchOptions
   */
  Regions?: string[];

  /**
   * 区域ID
   *
   * @type {string}
   * @memberof HotelPriceSearchOptions
   */
  RegionID?: string;

  /**
   * 酒店ID列表
   *
   * **用于精确酒店搜索，此条件可能会覆盖某些筛选条件**
   *
   * @type {number[]}
   * @memberof HotelPriceSearchOptions
   */
  HotelIDList?: number[];


  /**
   *  置顶的酒店ID们
   * <br/>(2020.11.05) Roman: 目前主要是Shopping在用
   * <br/>https://tower.im/teams/191049/todos/100021/
   * <br/>https://tower.im/teams/191049/todos/100020/
   * @type {number[]}
   * @memberof HotelPriceSearchOptions
   */
  HoistingHotelIDList?: number[];

  /**
   * 页码
   *
   * @type {number}
   * @memberof HotelPriceSearchOptions
   */
  PageNum?: number;

  /**
   * 每页显示条目
   *
   * @type {number}
   * @memberof HotelPriceSearchOptions
   */
  CountPerPage?: number;

  /**
   * 低价过滤条件
   *
   * @type {LowestRateFilter}
   * @memberof HotelPriceSearchOptions
   */
  LowestRateFilter?: LowestRateFilter;

  /**
   * 国籍设定
   *
   * @type {string}
   * @memberof HotelPriceSearchOptions
   */
  Nationality: string;

  /**
   * 价格筛选条件
   *
   * @type {HotelPriceSearchFilter}
   * @memberof HotelPriceSearchOptions
   */
  PriceSearchFilter?: HotelPriceSearchFilter;

  /**
   * 实时价格搜索条件
   *
   * @type {RealTimeFilter}
   * @memberof HotelPriceSearchOptions
   */
  RealTimeFilter?: RealTimeFilter;

  /**
   * 是否需要待确认RatePlan
   *
   * @type {boolean}
   * @memberof HotelPriceSearchOptions
   */
  IsNeedOnRequest?: boolean;

  /**
   * 排序条件
   *
   * @type {SortFilter}
   * @memberof HotelPriceSearchOptions
   */
  SortFilter?: SortFilter;

  // /**
  //  *
  //  *
  //  * @type {EanRequest}
  //  * @memberof HotelPriceSearchOptions
  //  */
  // EanRequest: EanRequest;

  /**
   * 币种
   *
   * @type {string}
   * @memberof HotelPriceSearchOptions
   */
  Currency?: string;

  // /**
  //  * 语言
  //  * **此处不可用**
  //  *
  //  * @type {number}
  //  * @memberof HotelPriceSearchOptions
  //  */
  // LanguageType: number;

  /**
   * @description 不需要HotelStatic
   */
  IsPriceOnly?: boolean;

  toMapParams() {
    const option = {
      RegionID: this.DestinationID,
      CheckOut: this.CheckOutDate.toLocaleDateString(),
      CheckIn: this.CheckInDate.toLocaleDateString(),
      SortingType: this.SortFilter.SortBy,
      StarRating: this.PriceSearchFilter.StarRating
    };
    return Object.keys(option).reduce((result, key, index) => {
      return `${result}&${key}=${option[key]}`;
    }, '');
  }
}
