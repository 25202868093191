<div class="dida-form-group"
[class.dida-form-group_invalid]="_formGroupControl.errorState"
[class.dida-form-group_invalid_warning]="_onWarningState"
[class.dida-form-group_invalid_danger]="_onDangerState">
  <div class="dida-form-group_wrapper" 
    (click)="_formGroupControl.onContainerClick($event)">
    <div class="dida-form-group__label-wrapper" *ngIf="_hasLabel || showEmptyLabel">
      <label class='dida-form-group__label' 
        [attr.for]="_formGroupControl.id"    
        [class.dida-form-group__label_info]="colorOnLabel && _colorMatch('info')"
        [class.dida-form-group__label_success]="colorOnLabel && _colorMatch('success')"
        [class.dida-form-group__label_warning]="colorOnLabel && (_colorMatch('warning') || _onWarningState)"
        [class.dida-form-group__label_danger]="colorOnLabel && (_colorMatch('danger') || _onDangerState)"
        [class.dida-form-group__label_bold]="boldLabel"
        #formLabel>
        <ng-content select="dida-form-label"></ng-content>
        {{ label }}
        <span *ngIf="showEmptyLabel">&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </label>
    </div>

    <div class="dida-form-group__input-infix dida-input">
      <div class="dida-input__wrapper">
        <!-- 表单控制组件插入位置 -->
        <ng-content></ng-content>

        <span class="dida-input__placeholder" *ngIf="_hasPlaceholder && _formGroupControl.shouldShowPlaceholder">
          <span #placeholder>
            <ng-content select="dida-input-placeholder"></ng-content>
            {{ _formGroupControl.placeholder }}
          </span>
        </span>
      </div>
      <div class="dida-input__underline" #underline>
        <span class="dida-input__ripple"
          [class.dida-input__ripple_info]="_colorMatch('info')" 
          [class.dida-input__ripple_success]="_colorMatch('success')" 
          [class.dida-input__ripple_warning]="_colorMatch('warning') || _onWarningState" 
          [class.dida-input__ripple_danger]="_colorMatch('danger') || _onDangerState"></span>
      </div>
    </div>
  </div>
  <div class="dida-form-group__subscript-wrapper">
    <div *ngIf="_canShowError" class="dida-form-group__error-wrapper">
      <!-- 表单验证错误消息区 -->
      <ng-content select="dida-form-error"></ng-content>
    </div>
    <div *ngIf="_canShowHint" class="dida-form-group__hint-wrapper">
      <!-- 表单组件提示区域 -->
      <ng-content select="dida-form-hint"></ng-content>
    </div>
  </div>
</div>