import { Directive, Optional, Self, Input } from '@angular/core';
import {
  NgControl,
  Validator,
  NG_VALIDATORS,
  ValidationErrors,
  FormGroup,
  NgModel,
  FormControl,
  Validators
} from '@angular/forms';
import {
  ASCIIValidator,
  spaceInnerValidator,
  nameValidator,
  spaceValidator,
  nameWithChineseValidator
} from '@dida-shopping/dida-services/common';

const TextValidatorType = {
  name: nameWithChineseValidator,
  ascii: ASCIIValidator,
  nonSpace: spaceValidator,
  required: Validators.required,
  requiredTrue: Validators.requiredTrue,
  minlength: Validators.minLength,
  maxlength: Validators.maxLength
};

export type DidaUITKValidateOptions = Partial<{
  name: boolean;
  ascii: boolean;
  nonSpace: boolean;
  required: boolean;
  maxlength: number;
  minlength: number;
  requiredTrue: boolean;
}>;

@Directive({
  selector: 'input[ndValidate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DidaUITKValidateDirective, multi: true }]
})
export class DidaUITKValidateDirective implements Validator {
  /**
   * 根据声名顺序只展示第一个碰到的错误
   */
  @Input() ndValidate: DidaUITKValidateOptions;

  validate(control: FormControl): ValidationErrors {
    let error = null;
    if (!this.ndValidate) {
      return error;
    }

    Object.keys(this.ndValidate).find(item => {
      if (!TextValidatorType[item]) {
        return false;
      }
      const configValue = this.ndValidate[item];
      if (typeof configValue === 'boolean') {
        if (configValue) {
          error = TextValidatorType[item](control);
        }
      } else {
        error = TextValidatorType[item](configValue)(control);
      }
      if (error) {
        return true;
      }
      return false;
    });

    return error;
  }
}
