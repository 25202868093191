<div class="container">
    <div class="redirect">
        <img class="redirect__img" src="https://dsimage.didatravel.com/v2-app/images/common/sprite_loading.gif"/>
        <ng-container [ngSwitch]="redirectType">
            <ng-container *ngSwitchCase="1">
                <h4 class="redirect__title" i18n="@@Text_Redirect_HotelPrice">即将带您前往{{targetName}}，因其网站约束入住条件可能会被重置，敬请留意。</h4>
                <p class="redirect__content" i18n="@@Text_Redirect_Desc">如跳转后的酒店不一致，欢迎通过“建议反馈”联系我们修正。</p>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <h4 class="redirect__title" i18n="@@Text_Redirect_HotelWebsite">即将带您前往酒店官网，正在响应中...</h4>
                <p class="redirect__content" i18n="@@Text_Redirect_Desc">如跳转后的酒店不一致，欢迎通过“建议反馈”联系我们修正。</p>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <h4 class="redirect_title" i18n="@@Text_redirecting">正在跳转中。。。</h4>
            </ng-container>
        </ng-container>
    </div>
</div>

