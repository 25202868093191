import { NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DidaUITKIconStatusDirective } from './icon-status.directive';

export const ICON_ASSETS_CDN = 'https://cdn.didatravel.com/icons-svg/';

@NgModule({
  declarations: [
    DidaUITKIconStatusDirective
  ],
  imports: [
    NzIconModule
  ],
  exports: [
    NzIconModule,
    DidaUITKIconStatusDirective
  ]
})
export class DidaUITKIconModule {
  constructor(
    @Optional() @SkipSelf() parentModule: DidaUITKIconModule,
    private nzIconService: NzIconService
  ) {
    if (!parentModule) {
      // use dida cdn as default
      console.log(`[DidaUITKIconModule] setting icon assets source to: ${ICON_ASSETS_CDN}`);
      this.nzIconService.changeAssetsSource(ICON_ASSETS_CDN);
    }
  }
}
