import {
  Component,
  Input,
  forwardRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Nationality,
  NationalityHelper
} from '@dida-shopping/dida-services/nationality';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { collapseMotion } from 'ng-zorro-antd/core/animation';
import { DidaUITKNationalitySelectComponent } from '../nationality-select/nationality-select.component';

@Component({
  selector: 'nd-nationality-picker',
  templateUrl: './nationality-picker.component.html',
  styleUrls: ['./nationality-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DidaUITKNationalityPickerComponent),
      multi: true
    }
  ],
  animations: [collapseMotion],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DidaUITKNationalityPickerComponent
  implements ControlValueAccessor {
  @Input()
  ndLang: LanguageType = LanguageType.zh_CN;

  @Input()
  ndShowPlaceHolder: boolean;

  @Input()
  ndShowLabel = true;

  @Input()
  ndMode: 'default' | 'select' = 'default';

  @Input()
  ndVisible: boolean;
  @Output()
  ndVisibleChange = new EventEmitter<boolean>();

  @Input()
  ndDisabled: boolean;

  @ViewChild('host', { static: true })
  hostElement: ElementRef;

  @ViewChild(DidaUITKNationalitySelectComponent, { static: true })
  selectComponent: DidaUITKNationalitySelectComponent;

  set value(value: Nationality) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(this.innerValue);
    }
  }

  get value(): Nationality {
    return this.innerValue;
  }

  isDisabled = false;

  private innerValue: Nationality = NationalityHelper.getNationality('CN');
  constructor(private changeRef: ChangeDetectorRef) {}

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (!this.hostElement.nativeElement.contains(event.target)) {
      this.trigger(false);
    }
  }

  trigger(visible: boolean) {
    if (visible && this.ndDisabled) {
      return;
    }

    const isChange = visible !== this.ndVisible;
    this.ndVisible = visible;
    if (this.ndVisible && this.selectComponent) {
      this.selectComponent.focus();
    }
    if (isChange) {
      this.ndVisibleChange.next(this.ndVisible);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: Nationality) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  onBlur() {
    this.onTouched();
  }

  writeValue(obj: Nationality): void {
    this.innerValue = obj;
    this.changeRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
