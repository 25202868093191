import { CurrencyModel } from './models';
import { LanguageType } from '../i18n';
/**
 * 币种目录是一个从A-Z排序的索引目录
 * + 其中`index`可以直接通过CurrencyCode首字母在字母表位置（比如，A为0，Z为25）直接进行提取
 * + 在每一个目录下的值`items`，则是通过CurrencyCode从A-Z排序
 */
export type CurrencyDirectoryStruct = ICurrencyDirectoryItem[];

export interface ICurrencyDirectoryItem {
  /**
   * 索引（CurrencyCode首字母）
   */
  indexChar: string;
  /**
   * 索引（CurrencyCode首字母在字母表的位置，比如A对应0）
   */
  index: number;
  items: CurrencyModel[];
}


export const CURRENCY_LIST: Array<CurrencyModel> = [
  new CurrencyModel('AED','U.A.E Dirham').addLangSetting(LanguageType.zh_CN, '阿联酋迪拉姆'),
  new CurrencyModel('AUD','Australian Dollar').addLangSetting(LanguageType.zh_CN, '澳元'),
  new CurrencyModel('BHD','Bahrain Dinar').addLangSetting(LanguageType.zh_CN, '巴林第纳尔'),
  new CurrencyModel('BND','Brunei Ringgit').addLangSetting(LanguageType.zh_CN, '文莱林吉特'),
  new CurrencyModel('BRL','Brazil Real').addLangSetting(LanguageType.zh_CN, '巴西雷亚尔'),
  new CurrencyModel('CAD','Canadian Dollar').addLangSetting(LanguageType.zh_CN, '加拿大元'),
  new CurrencyModel('CHF','Swiss Franc').addLangSetting(LanguageType.zh_CN, '瑞士法郎'),
  new CurrencyModel('CNY','Chinese Yuan').addLangSetting(LanguageType.zh_CN, '人民币'),
  new CurrencyModel('DKK','Danish Krone').addLangSetting(LanguageType.zh_CN, '丹麦克朗'),
  new CurrencyModel('EUR','Euro').addLangSetting(LanguageType.zh_CN, '欧元'),
  new CurrencyModel('FJD','Fijian Dollar').addLangSetting(LanguageType.zh_CN, '斐济元'),
  new CurrencyModel('GBP','British Pound').addLangSetting(LanguageType.zh_CN, '英镑'),
  new CurrencyModel('HKD','Hong Kong Dollar').addLangSetting(LanguageType.zh_CN, '港币'),
  new CurrencyModel('IDR','Indonesian Rupiah').addLangSetting(LanguageType.zh_CN, '印尼盾'),
  new CurrencyModel('INR','Indian Rupee').addLangSetting(LanguageType.zh_CN, '印度卢比'),
  new CurrencyModel('JPY','Japanese Yen').addLangSetting(LanguageType.zh_CN, '日元'),
  new CurrencyModel('KRW','Korean Won').addLangSetting(LanguageType.zh_CN, '韩元'),
  new CurrencyModel('MAD','Moroccan Dirham').addLangSetting(LanguageType.zh_CN, '摩洛哥迪拉姆'),
  new CurrencyModel('MOP','Macau Pataca').addLangSetting(LanguageType.zh_CN, '澳门元'),
  new CurrencyModel('MYR','Malaysian Ringgit').addLangSetting(LanguageType.zh_CN, '林吉特'),
  new CurrencyModel('NOK','Norwegian Krone').addLangSetting(LanguageType.zh_CN, '挪威克朗'),
  new CurrencyModel('NZD','New Zealand Dollar').addLangSetting(LanguageType.zh_CN, '新西兰元'),
  new CurrencyModel('PHP','Philippine Peso').addLangSetting(LanguageType.zh_CN, '菲律宾比索'),
  new CurrencyModel('QAR','Qatar Riyal').addLangSetting(LanguageType.zh_CN, '卡塔尔里亚尔'),
  new CurrencyModel('RUB','Russian Ruble').addLangSetting(LanguageType.zh_CN, '俄罗斯卢布'),
  new CurrencyModel('SCR','Seychelles Rupee').addLangSetting(LanguageType.zh_CN, '塞舌尔卢比'),
  new CurrencyModel('SEK','Swedish Krona').addLangSetting(LanguageType.zh_CN, '瑞典克朗'),
  new CurrencyModel('SGD','Singapore Dollar').addLangSetting(LanguageType.zh_CN, '新币'),
  new CurrencyModel('THB','Thai Baht').addLangSetting(LanguageType.zh_CN, '泰铢'),
  new CurrencyModel('TWD','New Taiwan Dollar').addLangSetting(LanguageType.zh_CN, '台币'),
  new CurrencyModel('USD','U.S. Dollar').addLangSetting(LanguageType.zh_CN, '美元'),
  new CurrencyModel('VND','Vietnamese Dong').addLangSetting(LanguageType.zh_CN, '越南盾'),
  new CurrencyModel('ZAR','South African Rand').addLangSetting(LanguageType.zh_CN, '南非兰特'),
].sort((a, b) => a.code.localeCompare(b.code));

export const CURRENCY_MAP: Map<string, CurrencyModel> = CURRENCY_LIST.reduce((map, item) => {
  map.set(item.code, item);
  return map;
}, new Map<string, CurrencyModel>());

export const CURRENCY_DIRECTORY: CurrencyDirectoryStruct = (() => {
  const CHAR_CODE_A = 'A'.charCodeAt(0);
  const CHAR_CODE_Z = 'Z'.charCodeAt(0);

  // 初始化CurrencyDirectoryStruct，使得每一个字母都有独立的位置
  const directory: CurrencyDirectoryStruct = [];
  for (let i = CHAR_CODE_A; i <= CHAR_CODE_Z; i++) {
    directory.push({
      index: i - CHAR_CODE_A,
      indexChar: String.fromCharCode(i),
      items: []
    });
  }

  // 往目录添加数据
  CURRENCY_LIST.forEach(item => {
    const index = item.code.charCodeAt(0) - CHAR_CODE_A;
    directory[index].items.push(item); // push to array
  });

  // 移除不存在的项目（但是保留其的索引占位）
  directory.forEach((item, idx) => {
    if (item.items.length === 0) {
      delete directory[idx];
    }
  });

  return directory;
})();
