
<ng-container [ngSwitch]="pure" *ngIf="!editable || src">
    <div *ngSwitchCase="false" class="img-container" [title]="title" [style.backgroundImage]="'url('+imgSrc+')'" [style.backgroundSize]="bgSize">
        <ng-content></ng-content>
    </div>
    <img *ngSwitchCase="true" class="img-pure" [src]="imgSrc" [alt]="alt || 'Image Not founed'" [title]="title">
</ng-container>

<ng-container *ngIf="editable">
    <a *ngIf="src" href="javascript:;" data-dismiss  (click)="clearImg()"
        i18n-title="@@Text_Button_Remove" title="移除">&times;</a>

    <app-uploader class="uploader" accept="image/*" [path]="uploadPath"
        *ngIf="!src" [maxSize]="2" (statusChange)="onUploaderChange($event)"></app-uploader>
</ng-container>
