/**
 * shopping页面类型
 *
 * @export
 * @enum {number}
 */
export enum ShoppingSitePageTypeEnum {
  /**
   *  酒店入口 /hotel/find
   */
  HotelFindPage = 1,
  /**
   *  酒店列表 /hotel/list
   */
  HotelListPage = 2,
  /**
   *  酒店详情 /hotel/detail
   */
  HotelDetailPage = 3,
  /**
   *  预订页面 /hotel/order
   */
  OrderPage = 4,
  /**
   *  订单列表 /booking
   */
  BookingListPage = 5,
  /**
   *  订单详情 /booking/detail
   */
  BookingDetailPage = 6,
}
