import { ParamMap } from '@angular/router';

import { Occupancy } from '@dida-shopping/dida-services/rate';
import { DidaHotelSearchPayload } from '../../hotels/shared/hotel-search/hotel-search.model';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { HotelCouponModel } from '../models/campaigns/coupons/coupon.model';
import { CouponConsumeRecordInfoModel } from '../models/campaigns/coupons/coupon-consume-record-info.model';
import { CouponTypeEnum } from '../enums/campaigns/coupons/coupon-type.enum';
import { QueryParamHelper } from '@dida-shopping/ngx-dida-site-core';
import { DEFAULT_NATIONALITY_CODE, NationalityHelper } from '@dida-shopping/dida-services/nationality';

export class Converter {
  /**
   * @description To array
   * @deprecated use ObjectHelper.toArray instead
   *
   */
  static toArray(value: any): any[] {
    let ret = value;
    if (value === undefined || value === null) {
      ret = [];
    } else if (!Array.isArray(value)) {
      ret = [value];
    }
    return ret;
  }

  // route
  static toQueryParam(payload: DidaHotelSearchPayload) {
    return QueryParamHelper.serialize(payload, param => {
      param.checkInDate = DateTimeHelper.format(
        param.checkInDate,
        'yyyy-MM-dd'
      );
      param.checkOutDate = DateTimeHelper.format(
        param.checkOutDate,
        'yyyy-MM-dd'
      );
      delete param.hotelID;
      // delete param.regions;
    });
  }

  static toDidaHotelSearchPayload(paramMap: ParamMap): DidaHotelSearchPayload {
    const deserializeOption = {
      autoParseDate: true,
      autoParseNumber: true,
      autoParseBoolean: true
    };
    const result = QueryParamHelper.deserialize<DidaHotelSearchPayload>(
      paramMap,
      DidaHotelSearchPayload,
      deserializeOption
    );

    // 检查国籍代码
    let nationalityCode = result['nationality'];
    if (nationalityCode) {
      nationalityCode = nationalityCode.slice(0, 2);
      let na = NationalityHelper.getNationality(nationalityCode);
      if (!na) {
        nationalityCode = DEFAULT_NATIONALITY_CODE;
      }
      result['nationality'] = nationalityCode;
    }

    const today = DateTimeHelper.today;
    if (!result.roomCount || result.roomCount < 1) {
      result.roomCount = 1;
    }

    if (!result.checkInDate || result.checkInDate < today) {
      result.checkInDate = DateTimeHelper.addDays(today, 1);
    }

    if (!result.checkOutDate || result.checkInDate >= result.checkOutDate) {
      result.checkOutDate = DateTimeHelper.addDays(result.checkInDate, 1);
    }

    if (!result.occupancy) {
      result.occupancy = new Occupancy();
    }

    if (!result.occupancy.AdultCount || result.occupancy.AdultCount < 1) {
      result.occupancy.AdultCount = 2;
    }

    if (!result.occupancy.ChildAgeList) {
      result.occupancy.ChildAgeList = [];
    }

    result.occupancy.ChildCount = result.occupancy.ChildAgeList.length;

    if (!result.starRatings) {
      result.starRatings = [];
    }

    if (result.priceRange) {
      if (
        result.priceRange.minimum &&
        result.priceRange.maximum &&
        result.priceRange.minimum > result.priceRange.maximum
      ) {
        const temps = [result.priceRange.minimum, result.priceRange.maximum];
        result.priceRange.minimum = temps[1];
        result.priceRange.maximum = temps[0];
      }
    }

    return result;
  }

  // static getComputedRouteData(snapshot: ActivatedRouteSnapshot, result = []) {
  //   if (snapshot.data) {
  //     result.push(snapshot.data);
  //   }
  //   if (snapshot.parent) {
  //     Converter.getComputedRouteData(snapshot.parent, result);
  //   }
  //   return Object.assign({}, ...result.reverse());
  // }

  static getCouponConsumeRecordInfo(
    coupon: HotelCouponModel,
    totalPriceCNY: number
  ): CouponConsumeRecordInfoModel {
    switch (coupon.CouponType) {
      case CouponTypeEnum.DiscountOnCertainPurchaseValue: {
        return {
          BookingID: null,
          CouponID: coupon.CouponID,
          CouponConsumeRecordID: null,
          CouponTypeID: coupon.CouponType,
          CampaignID: coupon.CampaignID,
          CampaignDisplayName_EN: coupon.DisplayName_EN,
          CampaignDisplayName_CN: coupon.DisplayName_CN,
          MinimumPurchaseAmount: coupon.RewardSetting.MinimumPurchaseAmount,
          RewardTypeID: coupon.RewardSetting.CampaignRewardTypeID,
          RewardAdjustmentTypeID:
            coupon.RewardSetting.CampaignRewardAdjustmentTypeID,
          RewardAmount: coupon.RewardSetting.CampaignRewardAmount,
          ActualRewardAmount:
            totalPriceCNY > coupon.RewardSetting.CampaignRewardAmount
              ? coupon.RewardSetting.CampaignRewardAmount
              : totalPriceCNY
        };
      }
      default:
        return null;
    }
  }
}
