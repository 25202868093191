import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { TicketIssueView } from '../../../shared/models/kangaroo/ticket-issue-view.model';
import { TicketSources } from '../../../shared/enums/kangaroo/ticket-sources.enum';
import { TicketSearchOptions } from '../../../shared/models/kangaroo/ticket-search-options.model';
import { TicketDetail } from '../../../shared/models/kangaroo/ticket-detail.model';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { TicketHelper } from '../../../shared/utils/ticket-helper';
import { TicketFeedbackModel } from '../../../shared/models/kangaroo/ticket-feedback.model';
import { map } from 'rxjs/operators';
import { TicketAddModel } from '../../../shared/models/kangaroo/ticket-add.model';

@Injectable()
export class TicketService {
  constructor(private httpClient: HttpClient) {}

  getTickets(options: TicketSearchOptions) {
    return this.httpClient.post<PaginationWrapper<TicketDetail>>(
      `/Ticket/Get`,
      options,
      null,
      result => {
        if (result && result.Data && result.Data.length > 0) {
          result.Data.forEach(item => {
            TicketHelper.init(item);
          });
        }
      }
    );
  }

  getTicketsByToken(token: string) {
    return this.httpClient.post<PaginationWrapper<TicketDetail>>(
      `/open/GetTickets`,
      { token },
      null,
      result => {
        if (result && result.Data && result.Data.length > 0) {
          result.Data.forEach(item => {
            TicketHelper.init(item);
          });
        }
      }
    );
  }

  getTicketIssues(source: TicketSources) {
    return this.httpClient.get<TicketIssueView[]>(
      `/Ticket/GetTicketIssues?source=${source}`,
      null,
      null,
      this.ticketIssuePreProcessor
    );
  }

  getTicketIssuesV2(source: TicketSources) {
    return this.httpClient.get<TicketIssueView[]>(
      `/Ticket/GetTicketIssuesV2?source=${source}`,
      null,
      null,
      this.ticketIssuePreProcessor
    );
  }

  getConfirmCodesFakeCuttofDays() {
    return this.httpClient.get<number>(
      `/Ticket/GetConfirmCodesFakeCuttofDays`,
      null,
      null,
      result => {
        return result;
      }
    );
  }

  addTicket(ticket: TicketAddModel) {
    return this.httpClient.post<TicketDetail>(`/Ticket/Create?`, ticket);
  }

  urgeTicket(ticketId: string, bookingId: string) {
    return this.httpClient
      .post<TicketDetail>('/Ticket/HurryTicket', {
        options: {
          TicketID: ticketId,
          DidaBookingID: bookingId
        }
      })
      .pipe(map(item => TicketHelper.normalize(item)));
  }

  addFeedback(data: TicketFeedbackModel) {
    return this.httpClient
      .post<TicketDetail>('/Ticket/AddFeedback', {
        options: {
          ...data,
          AttachmentJson: JSON.stringify(data.AttachmentJson)
        }
      })
      .pipe(map(item => TicketHelper.normalize(item)));
  }

  private ticketIssuePreProcessor(views: TicketIssueView[]) {
    const action = (items: TicketIssueView[]) => {
      items.forEach(item => {
        item.Settings = item.JsonProperty ? JSON.parse(item.JsonProperty) : {};
        // 之前组件少，在DB里面配JSON很恶心，所以逻辑是 Controls 里面禁止的才不展示
        // 现在工单标准化增加了组件，判断逻辑改为了 Controls 有的组件才展示
        // 为了减少小程序端的影响，先在这里写死
        // 在 DidaTravel\SourceCode\CustomServiceWorkspace\Test\TestConsole\Program 里面写了个 ShoppingTicketIssueSetting 方法方便在代码里面配置 JSON (SVN版本： 41072)
        if (item.Settings.Controls) {
          ['content', 'attachments'].forEach(name => {
            const control = item.Settings.Controls.find(x => x.Name === name);
            if (!control) {
              item.Settings.Controls.push({
                Name: name,
                IsRequired: false,
                IsVisible: true
              });
            }
          });
        }
        if (!item.Children) {
          item.Children = [];
        }
        action(item.Children);
      });
    };
    action(views);
  }
}
