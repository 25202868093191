import { IApplicationContextStorage } from "@dida-shopping/dida-services/storage";
import { LanguageType } from "@dida-shopping/dida-services/i18n";
import { CouponErrorTypes } from "./enums/coupon-error-types.enum";
import { DateTimeHelper } from "@dida-shopping/dida-services/common";
import {
  IFieldRestrictionModel,
  IDateTimeRangeRestrictionModel
} from "./models/restriction.models";
import { ICouponModel } from "./models/coupon.model";
import { CouponTypeEnum } from "./enums/coupon-type.enum";
import { FieldAvailabilityTypeEnum } from "./enums/field-availability-type";
import { FieldGroupRestrictionTypeEnum } from "./enums/field-group-restriction-type";
import { HotelCouponFilter } from "./models/hotel-coupon-filter.model";
import { ExperimentControls, ExperimentHelper } from "../experiment";
import { ICouponConsumeRecordInfoModel } from "@dida-shopping/dida-services/campaign";

export class CouponHelper {
  /**
   * 检查是否可以使用优惠券功能
   * 由于这个是业务上的写死逻辑，所以这里面的校验不包括AB逻辑的判断
   * 每次判断都要调用这里和AB,容易漏，所以还是加上吧
   * @param context
   */
  static allowUseCoupon(ctxStorage: IApplicationContextStorage) {
    if (
      !ExperimentHelper.isAllowControl(
        ExperimentControls.CouponControl,
        ctxStorage
      )
    ) {
      return false;
    }
    if (ctxStorage.context.lang !== LanguageType.zh_CN) {
      return false;
    }
    if (!ctxStorage.clientInfo) {
      return false;
    }
    return ctxStorage.currencyInfo.SettlementCode === "CNY";
  }

  static checkDateTimeRangeRestriction(
    restriction: IDateTimeRangeRestrictionModel,
    value: Date
  ): boolean {
    if (restriction == null) {
      return true;
    }

    if (
      restriction.DayOfWeekList &&
      !restriction.DayOfWeekList.includes(value.getDay())
    ) {
      return false;
    }

    switch (restriction.RestrictionType) {
      case FieldGroupRestrictionTypeEnum.Disabled:
        return true;
      case FieldGroupRestrictionTypeEnum.ApplyFromField:
        return new Date(restriction.From) <= value;
      case FieldGroupRestrictionTypeEnum.ApplyToField:
        return new Date(restriction.To) >= value;
      case FieldGroupRestrictionTypeEnum.ApplyBoth:
        return (
          new Date(restriction.From) <= value &&
          value <= new Date(restriction.To)
        );
      default:
        return false;
    }
  }

  static checkFieldRestriction(
    restriction: IFieldRestrictionModel,
    value: number
  ): boolean {
    if (restriction == null) {
      return true;
    }

    switch (restriction.RestrictionType) {
      case FieldAvailabilityTypeEnum.Disabled:
        return true;
      case FieldAvailabilityTypeEnum.Inclusive:
        return restriction.Collection.includes(value);
      case FieldAvailabilityTypeEnum.Exclusive:
        return !restriction.Collection.includes(value);
      default:
        return false;
    }
  }

  /**
   * 检查优惠券是否满足特定使用条件
   * orderTime：下单日期
   * stayDate：入住日期
   * totalPriceCNY：订单总价（目前的设计是查价选CNY才能出来优惠券）
   * payChannelType：支付方式（目前未限制支付方式，但是设计图有，先定义着吧）
   */
  static check(
    coupon: ICouponModel,
    filter: HotelCouponFilter
  ): CouponErrorTypes[] {
    const result: CouponErrorTypes[] = [];
    const serverNow = DateTimeHelper.serverNow;
    // 首先检查生效日期
    if (
      !CouponHelper.checkDateTimeRangeRestriction(
        coupon.EffectiveTimeSetting,
        serverNow
      )
    ) {
      result.push(CouponErrorTypes.effectiveTime);
    }

    // 检查规则
    if (coupon.RestrictionRuleModel) {
      const rule = coupon.RestrictionRuleModel;

      // 检查下单时间
      if (
        filter.orderTime &&
        !CouponHelper.checkDateTimeRangeRestriction(
          rule.OrderTimeRestriction,
          filter.orderTime
        )
      ) {
        result.push(CouponErrorTypes.orderTime);
      }

      // 检查入住时间
      if (filter.stayDate) {
        if (
          !CouponHelper.checkDateTimeRangeRestriction(
            rule.CheckInDateRestriction,
            filter.stayDate.checkInDate
          )
        ) {
          result.push(CouponErrorTypes.checkInDate);
        }

        if (
          !CouponHelper.checkDateTimeRangeRestriction(
            rule.CheckOutDateRestriction,
            filter.stayDate.checkOutDate
          )
        ) {
          result.push(CouponErrorTypes.checkOutDate);
        }
      }

      // 检查支付渠道
      if (
        filter.payChannel &&
        !CouponHelper.checkFieldRestriction(
          rule.PayChannelRestriction,
          filter.payChannel
        )
      ) {
        result.push(CouponErrorTypes.payChannel);
      }
    }

    // 根据优惠券类型进行相关检查
    switch (coupon.CouponType) {
      case CouponTypeEnum.DiscountOnCertainPurchaseValue:
        {
          if (
            filter.totalPriceCNY >= 0 &&
            filter.totalPriceCNY < coupon.RewardSetting.MinimumPurchaseAmount
          ) {
            result.push(CouponErrorTypes.minimumPurchaseAmount);
          }
        }
        break;
    }

    return result.length === 0 ? null : result;
  }

  static getCouponConsumeRecordInfo(coupon: ICouponModel, totalPriceCNY: number): ICouponConsumeRecordInfoModel {
    switch (coupon.CouponType) {
      case CouponTypeEnum.DiscountOnCertainPurchaseValue: {
        return {
          BookingID: null,
          CouponID: coupon.CouponID,
          CouponConsumeRecordID: null,
          CouponTypeID: coupon.CouponType,
          CampaignID: coupon.CampaignID,
          CampaignDisplayName_EN: coupon.DisplayName_EN,
          CampaignDisplayName_CN: coupon.DisplayName_CN,
          MinimumPurchaseAmount: coupon.RewardSetting.MinimumPurchaseAmount,
          RewardTypeID: coupon.RewardSetting.CampaignRewardTypeID,
          RewardAdjustmentTypeID: coupon.RewardSetting.CampaignRewardAdjustmentTypeID,
          RewardAmount: coupon.RewardSetting.CampaignRewardAmount,
          ActualRewardAmount:
            totalPriceCNY > coupon.RewardSetting.CampaignRewardAmount
              ? coupon.RewardSetting.CampaignRewardAmount
              : totalPriceCNY
        }
      }
      default:
        return null
    }
  }
}
