import { HttpServiceBase } from '../http';
import {
  IClientAccountBalanceLogModel,
  IClientCustomVoucherInfo,
  IClientModel,
  IClientVerificationResultModel,
  IUserProfileModel
} from './models';
import { PayChannelTypeEnum } from '../pay-channel';
import { IPaginationWrapper } from '../common';

class ClientService extends HttpServiceBase {

  constructor() {
    super('ClientService');
  }


  getClientInfo(isReload: boolean) {
    return this.httpClientWrapper.post<IClientModel>(
      `/ClientApi/GetUserClientInfo`,
      {
        fromCache: !isReload
      }
    );
  }

  getClientVerificationResult() {
    return this.httpClientWrapper.get<IClientVerificationResultModel>('/ClientApi/GetClientVerificationResult');
  }

  getCustomVoucherInfo() {
    return this.httpClientWrapper.get<IClientCustomVoucherInfo>('/ClientApi/GetCustomVoucherInfo');
  }

  updateCustomVoucherInfo(info: IClientCustomVoucherInfo) {
    return this.httpClientWrapper.post<boolean>('/ClientApi/UpdateCustomVoucherInfo', { info });
  }

  getSalesRepresentativeInfo() {
    return this.httpClientWrapper.get<IUserProfileModel>('/ClientApi/GetSaleRepresentiveInfo');
  }


  getBalanceLogList(payChannel: PayChannelTypeEnum, pageNum = 1, pageSize = 10) {
    return this.httpClientWrapper.post<IPaginationWrapper<IClientAccountBalanceLogModel>>(
      '/HotelBookingApi/GetBalanceLogList', // should move api endpoint to client domain
      {
        payChannelType: payChannel,
        pageNum,
        pageSize
      }
    );
  }

  getExportBalanceLogListUrl(payChannel: PayChannelTypeEnum) {
    const url = `/HotelBookingApi/ExportBalanceLogList?payChannelType=${payChannel}`;
    return url;
  };

}

export const clientService = new ClientService();
