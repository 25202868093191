import { HttpServiceBase } from '../http';
import { ColorTypeEnum, IPaginationWrapper } from '../common';
import { MessageCategoryTypeEnum } from './enums';
import { IMessageModel, IMessageSketchModel, IMessageSearchOptionModel } from './models';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

class UserMessageService extends HttpServiceBase {
  constructor() {
    super();
  }

  /**
   * 获取未读消息列表
   */
  getUnreadMessages() {
    return this.httpClientWrapper.get<IPaginationWrapper<IMessageSketchModel>>(
      '/UserMessageApi/GetUnreadMessage',
      null,
      {
        resultProcessor: (page: IPaginationWrapper<IMessageSketchModel>) => {
          return this.processMessagePageData(page as any);
        }
      }
    );
  }

  /**
   * 获取消息列表
   * @param options
   */
  get(options: IMessageSearchOptionModel) {
    return this.httpClientWrapper.post<IPaginationWrapper<IMessageModel>>(
      '/UserMessageApi/SearchMessage',
      options,
      {
        resultProcessor: (page: IPaginationWrapper<IMessageModel>) => {
          return this.processMessagePageData(page);
        }
      }
    );
  }

  /**
   * 根据ID获取消息
   * @param messageId
   * @param markAsRead
   */
  getDetailById(messageId: number, markAsRead = true) {
    return this.httpClientWrapper.post<IMessageModel>(
      '/UserMessageApi/GetMessageDetail',
      { messageId, markAsRead },
      {
        resultProcessor: (mdl) => {
          if (mdl) {
            this.preProcessMessage(mdl);
          }
          return mdl;
        }
      }
    );
  }

  /**
   * 把指定消息设为己读
   * @param {number | Array<number>>} messageID
   */
  readById(messageID: number | number[]) {
    let standaloneId = null;
    if (Array.isArray(messageID)) {
      if (messageID.length === 1) {
        standaloneId = messageID[0];
      } else {
        return this.httpClientWrapper.post<boolean>(
          '/UserMessageApi/BatchReadMessage',
          { listMessageID: messageID }
        );
      }
    } else {
      standaloneId = messageID;
    }
    if (standaloneId !== null) {
      return this.httpClientWrapper.post<boolean>(
        '/UserMessageApi/ReadMessage',
        { messageID: standaloneId }
      );
    }
  }

  /**
   * 把指定分类把消息全部设为己读
   * @param {MessageCategoryTypeEnum} category
   */
  readAllByCategory(category?: MessageCategoryTypeEnum) {
    return this.httpClientWrapper.post<boolean>(
      '/UserMessageApi/ReadAllMessage',
      {
        categoryTypeID: category
      }
    );
  }

  /**
   * 批量删除指定消息
   * @param {Array<number>} idList
   */
  deleteListById(idList: number[]) {
    return this.httpClientWrapper.post<boolean>(
      '/UserMessageApi/BatchDeleteMessage',
      {
        listMessageID: idList
      }
    );
  }


  private processMessagePageData(page: IPaginationWrapper<IMessageModel>) {
    page.Data.forEach(mdl => this.preProcessMessage(mdl));
    return page;
  }

  private preProcessMessage(mdl: IMessageModel) {
    mdl._calculatedConfig = {
      useTemplate: false,
      colorType: ''
    };
    if (mdl.TitleDict) {
      Object.keys(mdl.TitleDict).forEach(langKey => {
        if (langKey === LanguageType._zh_CN) {
          mdl.TitleDict[LanguageType.zh_CN] = mdl.TitleDict[langKey];
        } else if (langKey === LanguageType._en_US) {
          mdl.TitleDict[LanguageType.en_US] = mdl.TitleDict[langKey];
        }
      });
    }
    let setting
    switch (mdl.Category) {
      case MessageCategoryTypeEnum.WorkOrderStatusChange:
      case MessageCategoryTypeEnum.System:
        setting = mdl.ExtraSetting || '{}';
        mdl.ExtraSetting = JSON.parse(setting as string);
        if (mdl.Category === MessageCategoryTypeEnum.WorkOrderStatusChange) {
          mdl._calculatedConfig.useTemplate = mdl.Params && !!mdl.Params['LogType'];
        }
        if(mdl.Content) {
          mdl.Content = mdl.Content.replace(/<a\s/g, '<a target="_blank" ');
        }
        break;
      case MessageCategoryTypeEnum.BillStatusChange:
        switch (mdl.MessageTemplateID) {
          case 40011:
            mdl._calculatedConfig.colorType = ColorTypeEnum.Success;
            break;
          case 40008:
            mdl._calculatedConfig.colorType = ColorTypeEnum.Primary;
            break;
          case 40010:
            mdl._calculatedConfig.colorType = ColorTypeEnum.Error;
            break;
          case 40009:
            mdl._calculatedConfig.colorType = ColorTypeEnum.Warning;
            break;
        }
        break;
    }
  }
}

export const userMessageService = new UserMessageService();
