
import { WorkOrderDeadlineTypeEnum } from '../../enums/work-order-deadline-type';
export { WorkOrderDeadlineTypeEnum } from '../../enums/work-order-deadline-type';
import { WorkOrderStatusTypeEnum } from '../../enums/work-order-status-type';
import { FeedbackSubTypeEnum, FeedbackTypeEnum } from '@dida-shopping/dida-services/cs-ticket';
export { WorkOrderStatusTypeEnum } from '../../enums/work-order-status-type';

export enum WorkOrderTypeEnum {
    Booking = 1,
    Feedback = 2
}

export enum WorkOrderLogTypeEnum {
    // 用户创建
    UserCreate = 1,
    // 客服创建
    CustomerCreate = 2,
    // 加急
    Urge = 3,
    // 客服锁定
    Lock = 4,
    // 转交给其他人
    Reassign = 5,
    // 更新
    Update = 6,
    // 处理完
    Finish = 7
}

export class WorkOrderLog {

    WorkOrderID: number;
    Operator: string;
    Remark: string;
    IsUrgent: number;
    CreateTime: string;
    Status: WorkOrderStatusTypeEnum;
    LogType: WorkOrderLogTypeEnum;
}

export class WorkOrderModel {
    ReferenceID: string;
    ReferenceData: any;
    WorkOrderID: number;
    WorkOrderData: any;
    WorkOrderLogList: WorkOrderLog[];
    UserID: string;
    ClientID: string;
    Operator: string;
    Remark: string;
    DeadlineType: WorkOrderDeadlineTypeEnum;
    IsUrgent: number;
    UpdateTime: string;
    CreateTime: string;
    ActualFinishTime: string;
    WorkOrderType: WorkOrderTypeEnum;
    WorkOrderSubType: number;
    ExpectedFinishTime: string;
    Status: WorkOrderStatusTypeEnum;
    Addable: boolean;
}

export class WorkOrderSeachOptionsModel {
    WorkOrderID?: number;
    IsUrgent?: number;
    ClientID: string;
    Operator: string;
    Remark: string;
    ReferenceID: string;
    WorkOrderType: WorkOrderTypeEnum;
    WorkOrderSubType: Number;
    RequireReferenceData = true;
    Status: WorkOrderStatusTypeEnum;
    DeadlineType: WorkOrderDeadlineTypeEnum;
}

export enum BookingWorkOrderTypeEnum {
    GuestInfo = 1,
    CheckInOut = 2,
    RoomType = 3,
    Breakfast = 4,
    RoomCount = 5,
    Remark = 6,
    Others = 7,
}

// export enum FeedbackTypeEnum {
//     Encourage = 1,
//     Complain = 2,
//     Resource = 3,
//     NewFearture = 4,
//     Others = 5,
// }
//
// export enum FeedbackSubTypeEnum {
//     WebsiteSpeed = 1,
//     ServiceRelative,
//     Functionality,
//     MapRelative,
//     BizResource,
//     Others,
//     HotelNameError,
//     RateStarError,
//     AddressError,
//     TelephoneError,
//     WebsiteError,
// }

export class WorkOrderDataModel {
    Content: string;
}

export class BookingWorkOrderDataModel extends WorkOrderDataModel {
    Type: BookingWorkOrderTypeEnum;

}

export class FeedbackWorkOrderDataModel extends WorkOrderDataModel {
    Type: FeedbackTypeEnum;
    SubType: FeedbackSubTypeEnum = null;
    UserEmail: string;
    UserID: string;
    Referer: string;
}

