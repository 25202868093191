import { IWeChatConfigModel } from './models';

class WeChatService {
  config(config: IWeChatConfigModel) {

    wx.config({
      debug: !!config.Debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.AppId, // 必填，公众号的唯一标识
      timestamp: config.Timestamp, // 必填，生成签名的时间戳
      nonceStr: config.NonceStr, // 必填，生成签名的随机串
      signature: config.Signature, // 必填，签名
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'previewImage',
        'openLocation'
      ] // 必填，需要使用的JS接口列表
    });
  }
}

export const weChatService = new WeChatService();
