import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterViewInit,
  AfterContentInit,
  AfterContentChecked,
  ViewChild,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Renderer2,
  ChangeDetectorRef,
  QueryList,
  Input,
} from '@angular/core';
import { DidaFormGroupControl } from './dida-form-group-control';
import { startWith } from 'rxjs/operators';
import { DidaFormLabelDirective } from './dida-form-label.directive';
import { DidaInputPlaceholderDirective } from './dida-input-placeholder.directive';
import { DidaFormErrorDirective } from './dida-form-error.directive';
import { DidaFormHintDirective } from './dida-form-hint.directive';

/**
 * 表单组件
 * 
 * 根据Angular Material的Input部件删减后的实现，具体见
 * 
 * @link https://github.com/angular/material2
 * 
 * @export
 * @class DidaFormGroupComponent
 * @implements {AfterViewInit}
 * @implements {AfterContentInit}
 * @implements {AfterContentChecked}
 */
@Component({
  selector: 'dida-form-group',
  templateUrl: './dida-form-group.component.html',
  styleUrls: [
    './dida-form-group.component.scss',
    '../dida-input/dida-input.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DidaFormGroupComponent implements AfterViewInit, AfterContentInit, AfterContentChecked {

  // property and state bindings.
  @HostBinding('class.dida-form-group_invalid')
  get invalid() { return this._formGroupControl.errorState; }
  @HostBinding('class.dida-form-group_diabled')
  get disabled() { return this._formGroupControl.disabled; }
  @HostBinding('class.dida-form-group_focused')
  get isFocused() { return this._formGroupControl.focused; }
  @HostBinding('class.dida-form-group_info')
  get isStyleInfo() { return this.color === 'info'; }
  @HostBinding('class.dida-form-group_success')
  get isStyleSuccess() { return this.color === 'success'; }
  @HostBinding('class.dida-form-group_warning')
  get isStyleWarning() { return this.color === 'warning'; }
  @HostBinding('class.dida-form-group_danger')
  get isStyleDanger() { return this.color === 'danger'; }

  @HostBinding('class.size_sm')
  get bindFormGroupSizeSm() { return this.size === 'sm'; }

  @HostBinding('class.size_lg')
  get bindFormGroupSizeLg() { return this.size === 'lg'; }

  @HostBinding('class.ng-untouched')
  get bindNgUntouched() { return this._shouldForward('untouched'); }

  @HostBinding('class.ng-touched')
  get bindNgTouched() { return this._shouldForward('touched'); }

  @HostBinding('class.ng-pristine')
  get bindNgPristine() { return this._shouldForward('pristine'); }

  @HostBinding('class.ng-dirty')
  get bindNgDirty() { return this._shouldForward('dirty'); }

  @HostBinding('class.ng-valid')
  get bindNgValid() { return this._shouldForward('valid'); }

  @HostBinding('class.ng-invalid')
  get bindNgInvalid() { return this._shouldForward('invalid'); }

  @HostBinding('class.ng-pending')
  get bindNgPending() { return this._shouldForward('pending'); }

  @Input() size = '';

  @Input() showHintOnError = false;

  @Input() showEmptyLabel = true;

  @Input() colorOnLabel = false;

  @Input() label: string;

  @Input() color: 'primary' | 'success' | 'info' | 'warning' | 'danger' = 'primary';

  @Input() boldLabel = false;

  // @Input()
  // get dividerColor(): 'primary' | 'success' | 'info' | 'warning' | 'danger' { return this.color; }
  // set dividerColor(value) { this.color = value; }

  @ViewChild('underline', { static: true }) underlineRef: ElementRef;
  @ViewChild('placeholder', { static: false }) private _placeholder: ElementRef;
  @ViewChild('formLabel', { static: true }) private _formLabel: ElementRef;

  @ContentChild(DidaFormGroupControl, { static: false }) _formGroupControl: DidaFormGroupControl<any>;
  @ContentChild(DidaFormLabelDirective, { static: false }) _labelChild: DidaFormLabelDirective;
  @ContentChild(DidaInputPlaceholderDirective, { static: false }) _placeholderChild: DidaInputPlaceholderDirective;
  @ContentChildren(DidaFormErrorDirective) _errors: QueryList<DidaFormErrorDirective>;
  @ContentChildren(DidaFormHintDirective) _hints: QueryList<DidaFormHintDirective>;

  constructor(
    public _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngAfterContentInit() {
    this.validateControlChild();
    if (this._formGroupControl.controlType) {
      this._renderer.addClass(this._elementRef.nativeElement,
        `dida-form-group__${this._formGroupControl.controlType}`);
    }

    this._formGroupControl.stateChanges.pipe(startWith(null!)).subscribe(() => {
      this.validateLabels();
      this.validatePlaceholders();
      this._changeDetectorRef.markForCheck();
    });

    let ngControl = this._formGroupControl.ngControl;
    if (ngControl && ngControl.valueChanges) {
      ngControl.valueChanges.subscribe(() => {
        this._changeDetectorRef.markForCheck();
      });
    }

    this._hints.changes.pipe(startWith(null)).subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });

    this._errors.changes.pipe(startWith(null)).subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });
  }

  ngAfterContentChecked() {
    this.validateControlChild();
  }

  ngAfterViewInit() {
    this._changeDetectorRef.detectChanges();
  }

  _shouldForward(prop: string): boolean {
    let ngControl = this._formGroupControl ? this._formGroupControl.ngControl : null;
    return ngControl && (ngControl as any)[prop];
  }

  _colorMatch(color: string) {
    return this.color === color;
  }

  get _onWarningState(): boolean {
    return this._formGroupControl.errorState && this._formGroupControl.errorLevel === 'warning';
  }

  get _onDangerState(): boolean {
    return this._formGroupControl.errorState && this._formGroupControl.errorLevel === 'danger';
  }

  get _canShowHint(): boolean {
    return this.showHintOnError || this._displayedMessageType === 'hint';
  }

  get _canShowError(): boolean {
    return this._displayedMessageType === 'error';
  }

  get _displayedMessageType(): 'error' | 'hint' {
    return (this._errors && this._errors.length > 0 &&
      this._formGroupControl.errorState) ? 'error' : 'hint';
  }

  get _hasPlaceholder(): boolean {
    return !!(this._formGroupControl.placeholder || this._placeholderChild);
  }

  get _hasLabel(): boolean {
    return !!(this.label || this._labelChild);
  }

  private validatePlaceholders() {
    if (this._formGroupControl.placeholder && this._placeholderChild) {
      throw new Error(`more than one placeholder was defined within this form group`);
    }
  }

  private validateLabels() {
    if (this.label && this._labelChild) {
      throw new Error(`more than one label was defined within this form group`);
    }
  }

  private validateControlChild() {
    if (!this._formGroupControl) {
      throw new Error(`control is needed!`);
    }
  }

}
