import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import {
  ProductSearchOptions,
  ProductInfoModel,
  ProductInfoBaseModel
} from '../../../shared/models/points/product.models';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { RedeemRecordInfoModel, ProductRedeemOptions } from '../../../shared/models/points/redeem.models';
import {
  LotteryInfoModel,
  LotteryDrawResultModel,
  LotteryWinnerModel,
  PrizeRedeemOptions,
  LotteryDrawLogSearchModel,
  LotteryDrawLogModel,
  LotteryPrizeModel
} from '../../../shared/models/points/lottery.models';
import { Observable, interval, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class PointsService {

  constructor(private httpClient: HttpClient) { }
  /**
   *
   * @param alias 活动别名
   */
  getLotteryInfo(alias: string) {
    return this.httpClient.post<LotteryInfoModel>('/PointsLottery/GetLotteryInfo', { alias });
  }

  /**
   *
   * @param alias 活动别名
   * @param level 抽奖转盘：0 - 小转盘，1 - 大转盘
   */
  drawLottery(alias: string, level: number) {
    return this.httpClient.post<LotteryDrawResultModel>('/PointsLottery/DrawLottery', { alias, level });
  }
  drawLotteryTest(alias: string, level: number) {
    return new Observable<LotteryDrawResultModel>(ob => {
      ob.next({
        PrizeInfo: {
          'ID': 3,
          'LotteryID': 2,
          'ProductID': 86,
          'ProductInfo': {
            'ProductID': 86,
            'Value': 55000,
            'Status': 0,
            'Name': '得力削笔刀',
            'Description': '得力(deli)简约学生削笔机/铅笔削笔器/卷笔刀/转笔刀 蓝色0641',
            'RedeemCount': null,
            'RedeemRecords': null,
            'Images': null,
            'ImageSrc': 'https://dsimage.didatravel.com/Upload/47CFD217E1CE06FFE1AB227E6E60AFA3.png',
            'ImageThumbSrc': 'https://dsimage.didatravel.com/Upload/47CFD217E1CE06FFE1AB227E6E60AFA3.png'
          } as ProductInfoModel,
          'Level': 0,
          'Title': '',
          'Desc': '',
          'CreateTime': '2019-02-21T16:20:27',
          'UpdateTime': '2019-02-21T16:20:27'
        } as LotteryPrizeModel,
        PrizeLogID: 0,
      });
    });
    // return this.httpClient.post<LotteryDrawResultModel>('/PointsLottery/DrawLottery', { alias, level });
  }

  /**
   *
   * @param lotteryID 活动id
   * @param orderByValue 是否根据奖品价值排序
   */
  getWinnerList(lotteryID: number, orderByValue: boolean = false) {
    return this.httpClient.post<PaginationWrapper<LotteryWinnerModel>>('/PointsLottery/GetWinnerList', { lotteryID, orderByValue });
  }

  watchWinnerList(lotteryID: number, time = 5000) {
    return timer(0, time).pipe(
      flatMap(() => this.getWinnerList(lotteryID, false))
    );
  }


  /**
   *
   * @param options
   * @param pageNum
   * @param pageSize
   */
  getProducts(options: ProductSearchOptions, pageNum = 1, pageSize = 10) {
    options.PageNum = pageNum;
    options.PageSize = pageSize;
    options.WithImages = true;
    return this.httpClient.post<PaginationWrapper<ProductInfoModel>>('/PointsMarketApi/GetProducts', { options }, null, result => {
      result.Data.map(item => this.normalizeProduct(item));
    });
  }

  updateProduct(product: ProductInfoBaseModel) {
    return this.httpClient.post<ProductInfoModel>(
      '/PointsMarketApi/UpdateProduct',
      { product }, null,
      result => this.normalizeProduct(result)
    );
  }

  redeemProduct(options: ProductRedeemOptions) {
    return this.httpClient.post<number>('/PointsMarketApi/RedeemProduct', { options });
  }

  redeemPrize(options: PrizeRedeemOptions) {
    return this.httpClient.post<number>('/PointsLottery/RedeemPrize', { options });
  }

  GetDrawLog(options: LotteryDrawLogSearchModel) {
    let payload = Object.assign(new LotteryDrawLogSearchModel(), options);
    return this.httpClient.post<PaginationWrapper<LotteryDrawLogModel>>('/PointsLottery/GetDrawLog', { options: payload });
  }

  getProductRedeemRecords(productId: string, pageNum: number = 1, pageSize: number = 10) {
    let model = { productId, pageNum, pageSize };
    return this.httpClient.post<PaginationWrapper<RedeemRecordInfoModel>>('/PointsMarketApi/GetRedeemRecordPage', model);
  }

  getTotalPointsRedeemed() {
    return this.httpClient.get<number>('/UserPointsApi/GetRedeemedPointCount');
  }

  private normalizeProduct(item: ProductInfoModel) {
    item.ImageSrc = item.ImageSrc || item.Images && item.Images[0].ImageSrc;
    item.ImageThumbSrc = item.ImageSrc || item.Images && item.Images[0].ImageThumbSrc;
    return item;
  }
}

