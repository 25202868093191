<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="CategoryType.System" i18n="@@Text_Menu_SiteNotification">系统通知</ng-container>
    <ng-container *ngSwitchCase="CategoryType.BookingFreeCancellationNotice" i18n="@@Text_Menu_NextDayCancel">次日取消收费</ng-container>
    <ng-container *ngSwitchCase="CategoryType.BookingLowerPriceNotice" i18n="@@Text_Menu_BookingPriceDrop">订单降价</ng-container>
    <ng-container *ngSwitchCase="CategoryType.BookingHotelConfirmationNotice" i18n="@@Text_Menu_HotelConfirmation">酒店确认号更新</ng-container>
    <ng-container *ngSwitchCase="CategoryType.WorkOrderStatusChange">
        <ng-container *ngIf="ticketOperation != null">
            <ng-container [ngSwitch]="ticketOperation">
                <ng-container *ngSwitchCase="TicketOperations.Add" i18n="@@Ticket_Menu_Operation_Add">工单创建</ng-container>
                <ng-container *ngSwitchCase="TicketOperations.Complete" i18n="@@Ticket_Operation_TicketComplete">工单已完成</ng-container>
                <ng-container i18n="@@Text_Menu_WorkOrderStatus" *ngSwitchDefault>工单状态更新</ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="ticketOperation === null" i18n="@@Text_Menu_WorkOrderStatus">工单状态更新</ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="CategoryType.InvoiceStatusChange" i18n="@@Text_Menu_Invoicing">发票申请</ng-container>
    <ng-container *ngSwitchCase="CategoryType.BillStatusChange" i18n="@@Text_Menu_BillStatus">账单状态更新</ng-container>
    <ng-container *ngSwitchCase="CategoryType.PointsExchange" i18n="@@Text_Menu_PointsRedeem">积分兑换</ng-container>
    <ng-container *ngSwitchCase="CategoryType.CouponNearExpiration" i18n="@@Text_Menu_CouponReminder">优惠券提醒</ng-container>
</ng-container>
