import { Injectable } from '@angular/core';
import {
  ngxHttpCall
} from '../http';
import {
  MessageCenterEventHub,
  MessageCenterUnreadMessageUpdateEvent
} from '../events';
import { IMessageSketchModel, MessageCategoryTypeEnum, userMessageService } from '@dida-shopping/dida-services/message';
import { USER_MESSAGE_STORAGE } from '@dida-shopping/dida-services/storage';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { AbstractRunnableService } from './runnable-service.base';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService extends AbstractRunnableService {

  static readonly MAX_CACHE_VALID_SEC: number = 15; //
  static readonly CACHE_RELOAD_INTERVAL_SEC: number = 60; // 每分钟刷新一次

  get unreadMessageCount(): number {
    return USER_MESSAGE_STORAGE.getUnreadMessageCount();
  }

  get lastCacheTime(): Date {
    return USER_MESSAGE_STORAGE.getCacheTime();
  }

  constructor(
    private messageCenterEventHub: MessageCenterEventHub
  ) {
    super(MessageCenterService.CACHE_RELOAD_INTERVAL_SEC);
  }

  protected execute(): Subscription {
    return this.loadUnreadMessages();
  }

  isCacheValidBy(time: Date): boolean {
    const validCacheTime = DateTimeHelper.addSeconds(this.lastCacheTime, MessageCenterService.MAX_CACHE_VALID_SEC);
    return time <= validCacheTime;
  }

  readMessagesById(messageIdList: number[]) {
    return (
      ngxHttpCall(
        userMessageService.readById(messageIdList)
      )
    ).subscribe({
      next: (success) => {
        if (success) {
          const result = USER_MESSAGE_STORAGE.removeUnreadMessages(messageIdList);
          if (result.hasUpdated) {
            this.messageCenterEventHub.broadcast(
              new MessageCenterUnreadMessageUpdateEvent(null, '[MessageCenterService::readMessagesById]', new Date())
            );
          }
        }
      }
    });
  }

  readAllMessages(category?: MessageCategoryTypeEnum) {
    return ngxHttpCall(
      userMessageService.readAllByCategory(category)
    ).subscribe({
      next: (success) => {
        let unreadMessages = USER_MESSAGE_STORAGE.getUnreadMessages();
        if (category) {
          unreadMessages = unreadMessages.filter(msg => msg.Category === category);
        }
        if (unreadMessages.length > 0) {
          const targetIdList = unreadMessages.map(x => x.MessageID);
          const result = USER_MESSAGE_STORAGE.removeUnreadMessages(targetIdList);
          if (result.hasUpdated) {
            this.messageCenterEventHub.broadcast(
              new MessageCenterUnreadMessageUpdateEvent(null, '[MessageCenterService::readAllMessages]', new Date())
            );
          }
        }
      }
    });
  }

  deleteMessagesById(messageIdList: number[]) {
    return ngxHttpCall(
      userMessageService.deleteListById(messageIdList)
    ).subscribe({
      next: (success) => {
        if (success) {
          const result = USER_MESSAGE_STORAGE.removeUnreadMessages(messageIdList);
          if (result.hasUpdated) {
            this.messageCenterEventHub.broadcast(
              new MessageCenterUnreadMessageUpdateEvent(null, '[MessageCenterService::deleteMessagesById]', new Date())
            );
          }
        }
      }
    });
  }

  loadUnreadMessages(ignoreCache = false): Subscription {
    const time = new Date();
    if (!ignoreCache && this.isCacheValidBy(time)) {
      const subject = new BehaviorSubject<boolean>(true);
      subject.next(true);
      subject.complete();
      return subject.subscribe();
    }
    return ngxHttpCall(
      userMessageService.getUnreadMessages()
    ).subscribe({
      next: (page) => {
        const result = USER_MESSAGE_STORAGE.setUnreadMessages(page, time);
        if (result.hasUpdated) {
          this.messageCenterEventHub.broadcast(
            new MessageCenterUnreadMessageUpdateEvent(null, '[MessageCenterService::loadUnreadMessages]', new Date())
          );
        }
      }
    });
  }

  getUnreadMessages(): IMessageSketchModel[] {
    return USER_MESSAGE_STORAGE.getUnreadMessages();
  }
}
