import { IEventModelBase } from './event-base.model';
import { ApplicationMessageTypeEnum, FavoriteHotelMessageTypeEnum } from '../enums';
import { IUserCurrencyInfoModel } from '@dida-shopping/dida-services/membership';

export abstract class FavoriteHotelEventBase implements IEventModelBase<FavoriteHotelMessageTypeEnum> {


  protected constructor(
    public readonly type: FavoriteHotelMessageTypeEnum,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }
}

export class FavoriteHotelGetFavorIconPosEvent extends FavoriteHotelEventBase {
  constructor(
    public readonly rect: ClientRect,
    source: string,
    time: Date
  ) {
    super(FavoriteHotelMessageTypeEnum.GetFavorIconPos, source, time);
  }

}

export class FavoriteHotelFavorIconPosEvent extends FavoriteHotelEventBase {
  constructor(
    public readonly iconRect: ClientRect,
    source: string,
    time: Date
  ) {
    super(FavoriteHotelMessageTypeEnum.FavorIconPos, source, time);
  }
}

export class FavoriteHotelUpdateEvent extends FavoriteHotelEventBase {
  constructor(
    source: string,
    time: Date
  ) {
    super(FavoriteHotelMessageTypeEnum.FavoriteHotelUpdate, source, time);
  }
}

