import { Injectable } from '@angular/core';

import { Angulartics2 } from 'angulartics2';
import {Angulartics2BaiduAnalytics} from 'angulartics2/baidu';
import { Angulartics2DidaProviderBase } from './angulartics2-dida.models';
import { DidaBehaviorEventTrackingOption } from '../behavior-management.models';
import { LoggerService } from '../logger.service';


declare var _hmt: any;

@Injectable()
export class Angulartics2DidaBaiduAnalyticsProvider extends Angulartics2BaiduAnalytics implements Angulartics2DidaProviderBase {

    constructor(
        private angulartics2_: Angulartics2,
        private logger: LoggerService,
    ) {
        super(angulartics2_);
    }

    eventTrack(action: string, properties: any): void {
        this.logger.error(`The event is not being tracked. Do not use event track, use 'track' instead.`);
    }

    track(option: DidaBehaviorEventTrackingOption): void {
        super.eventTrack(option.action.toString(), {
            category: option.category,
            label: option.label,
            value: option.value,
            noninteraction: option.noninteraction,
            hitCallback: option.callback,
            page: option.page,
        });
        if (typeof (option.callback) === 'function') {
            window.setTimeout(() => {
                option.callback();
            }, 50); // trigger 50ms later
        }
    }

    pageTrack(path) {
        if (typeof _hmt !== 'undefined' && _hmt) {
          _hmt.push(['_trackPageview', path]);
          _hmt.push(['_setReferrerOverride', path]);
        }
    }


    setUsername(userId: string) {
        // set default custom variables name to 'identity' and 'value'
        this.logger.error(`Should not call 'setUsername', use 'setUserInfo' instead.`);
    }

    setUserProperties(properties: any) {
        _hmt.push(['_setCustomVar', 3, 'userprop', JSON.stringify(properties)]);
    }

    setUserInfo(username: string, clientId?: string): void {
        _hmt.push(['_setCustomVar', 1, 'identity', username]);
        if (clientId != null) {
            _hmt.push(['_setCustomVar', 2, 'client', clientId]);
        }
    }
}
