import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MessageCenterService } from './message-center.service';
import { UserFavoriteHotelService } from './user-favorite-hotel.service';
import { UserCouponService } from './user-coupon.service';
import { SidebarSettingService } from './sidebar-settting.service';
import { NgxDidaEventsModule } from '../events';

export enum DidaStorageServicesType {
  MessageCenterService,
  UserFavoriteHotelService,
  UserCouponService,
}

export {
  MessageCenterService,
  UserFavoriteHotelService,
  UserCouponService
};

@NgModule({
  imports: [
    NgxDidaEventsModule
  ]
})
export class NgxDidaStorageServiceModule {

  constructor(@Optional() @SkipSelf() parentModule: NgxDidaStorageServiceModule) {
    if (parentModule) {
      throw new Error(
        `NgxDidaMessagesModule has already been loaded. Import NgxDidaMessagesModule in the AppModule only,`
      );
    }
  }

  static forRoot(...services: DidaStorageServicesType[]): ModuleWithProviders<NgxDidaStorageServiceModule> {
    const serviceTypes = services.map(x => {
      switch (x) {
        case DidaStorageServicesType.MessageCenterService:
          return MessageCenterService;
        case DidaStorageServicesType.UserFavoriteHotelService:
          return UserFavoriteHotelService;
        case DidaStorageServicesType.UserCouponService:
          return UserCouponService;
      }
      return null;
    });
    return {
      ngModule: NgxDidaStorageServiceModule,
      providers: serviceTypes.filter(x => x === null)
    };
  }
}
