export class CalendarStyleModel {
    constructor(MarginLeft: number,
        MarginTop: number,
        ShowSriangle: boolean,
        SriangleFromMarginLeft: number,
        SriangleToMarginLeft: number) {
        this.MarginLeft = MarginLeft;
        this.MarginTop = MarginTop;
        this.ShowSriangle = ShowSriangle;
        this.SriangleFromMarginLeft = SriangleFromMarginLeft;
        this.SriangleToMarginLeft = SriangleToMarginLeft;
    }
    MarginLeft: number;
    MarginTop: number;
    ShowSriangle: boolean;
    SriangleFromMarginLeft: number;
    SriangleToMarginLeft: number;
}
