import { LanguageType } from '../../i18n';
import { ResourceBase } from '../../common/models';

export class CurrencyModel extends ResourceBase<string> {
  constructor(
    code: string,
    name: string,
    order = -1,
    lang: LanguageType = LanguageType.en_US
  ) {
    super(code, name, order, lang);
  }

  addLangSetting(lang: LanguageType, name: string): CurrencyModel {
    super.addLangSetting(lang, name);
    return this;
  }

  clone(): CurrencyModel {
    return super.clone() as CurrencyModel;
  }

  protected constructSelf(): CurrencyModel {
    return new CurrencyModel(this.code, this.name, this.order);
  }
}
