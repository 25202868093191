
import { SuggestionItemCategory } from '../suggestions';
import { SuggestionQueryPaginationPayload } from './suggestion-query-pagination-payload.model';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

export class SuggestionQueryPayload {
    DocID?: string = null;
    IDs: string[] = [];
    Keyword: string;
    ParentID: string;
    Categories: SuggestionItemCategory[] = [];
    TransferLocations: string[] = [];
    DestinationOnly: boolean;
    WithMetadata: boolean;
    WithHierarchy: boolean;
    WithItemObject: boolean;
    Language: LanguageType;
    Size: number;
    PaginationOption: SuggestionQueryPaginationPayload;
}

