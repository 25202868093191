import { DidaUITKEventBase } from '@dida-shopping/ngx-dida-uitk/core/uitk-event.base';

class DidaUITKWindowEvent<Event extends UIEvent> extends DidaUITKEventBase<Event> {
  constructor(eventName: string
  ) {
    super('window', eventName, window);
  }
}


export const WindowResizeEvent = new DidaUITKWindowEvent<UIEvent>('resize');

