import { WorkOrderTypeEnum } from './enums';
import { IWorkOrderModel } from './models';

export class WorkOrderHelper {
  static normalizeTicket(ticket: IWorkOrderModel): IWorkOrderModel {
    switch (ticket.WorkOrderType) {
      case WorkOrderTypeEnum.Booking:
        ticket.WorkOrderData = Object.assign({}, JSON.parse(ticket.WorkOrderData));
        ticket.ReferenceData = Object.assign({}, JSON.parse(ticket.ReferenceData));
    }
    return ticket;
  }
}
