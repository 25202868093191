import { LanguageType } from "../../i18n";

export interface Translatable {
  switchLanguage(lang: LanguageType)
}

export abstract class TranslatableModel implements Translatable {
  constructor() {

  }

  switchLanguage(lang: LanguageType) {
    switch (lang) {
      case LanguageType.zh_CN:
        Object.assign(this, this[LanguageType.zh_CN], this[LanguageType._zh_CN])
        break;

      default:
        break;
    }
  }
}
