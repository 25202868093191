import { IEventModelBase } from './event-base.model';
import { CampaignMessageTypeEnum } from '../enums';

export abstract class CampaignEventBase implements IEventModelBase<CampaignMessageTypeEnum> {

  protected constructor(
    public readonly type: CampaignMessageTypeEnum,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }
}

export class CampaignLoginShowCouponModalEvent extends CampaignEventBase {
  constructor(
    source: string,
    time: Date = null
  ) {
    super(CampaignMessageTypeEnum.LoginShowCouponModal, source, time);
  }
}

export class UserActiveCouponUpdateEvent extends CampaignEventBase {
  constructor(
    source: string,
    time: Date = null
  ) {
    super(CampaignMessageTypeEnum.UserActiveCouponUpdated, source, time);
  }
}
