import { Injectable, isDevMode } from '@angular/core';

@Injectable()
export class LoggerService {
  private _prodMode: boolean;

  constructor() {
    this._prodMode = !isDevMode();
  }

  debug(...items: any[]): void {
    if (this._prodMode) {
      return;
    }
    // tslint:disable-next-line:no-console
    console.debug(items);
  }

  info(...items: any[]): void {
    // tslint:disable-next-line:no-console
    console.info(items);
  }

  warn(...items: any[]): void {
    console.warn(items);
  }

  error(msg: string, err: Error = null, ...optionalParams: any[]): void {
    console.error(msg, err, optionalParams);
  }
}
