import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { InvoiceBookingSearchOption } from '../../../shared/models/invoice/invoice-booking-search-option';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { InvoiceBooking } from '../../../shared/models/invoice/invoice-booking';
import { InvoiceSearchOption } from '../../../shared/models/invoice/invoice-search-option';
import { DidaInvoice } from '../../../shared/models/invoice/dida-invoice';
import { InvoiceDetailResult } from '../../../shared/models/invoice/invoice-detail-result';
import { InvoiceClientInfoResult, ClientInvoiceInfoDetail } from '../../../shared/models/invoice/invoice-client-info-result';
import { ClientInvoiceInfo } from '../../../shared/models/invoice/client-invoice-info';
import { InvoiceContextModel } from '../../../shared/models/invoice/invoice-Img-result';
import { ClientInvoiceInfoOption, IvoiceInfoOption } from '../../../shared/models/invoice/client-invoice-info-option';
import { ApplyInvoiceResult } from '../../../shared/models/invoice/apply-invoice-result';
import { AddOrUpdateInvoiceResult } from '../../../shared/models/invoice/invoice-detail-update-result';
import { InvoiceSearchResult } from '../../../shared/models/invoice/invoice-search-result';
import { HttpObservable } from '@dida-shopping/ngx-dida-site-core';

/**
 * 发票数据服务
 *
 * @export
 * @class InvoiceService
 */
@Injectable()
export class InvoiceService {
    constructor(
        private logger: LoggerService,
        private httpClient: HttpClient
    ) { }

    search(option: InvoiceBookingSearchOption): HttpObservable<PaginationWrapper<InvoiceBooking>> {
        return this.httpClient.get('/HotelBookingInvoiceApi/GetApplyInvoiceBooking', {
            searchOptions: option,
        }, null, (mdl) => {
            if (mdl.Data && mdl.Data.length > 0) {
                mdl.Data.forEach(b => {
                    this.InvoiceBookingProcessor(b);
                });
            }
        }, (payload) => {
            let opt: InvoiceBookingSearchOption = payload.searchOptions;
            if (opt.BeginDate && opt.EndDate) {
                opt.BeginDate = DateTimeHelper.format(opt.BeginDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
                opt.EndDate = DateTimeHelper.format(opt.EndDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
            }
        });
    }

    getInvoiceDetail(invoiceID: string): HttpObservable<InvoiceDetailResult> {
        return this.httpClient.get('/HotelBookingInvoiceApi/GetInvoiceDetail', {
            invoiceID: invoiceID,
        }, null, (mdl) => {
            if (mdl.Bookings && mdl.Bookings.length > 0) {
                mdl.Bookings.forEach(b => {
                    this.InvoiceBookingProcessor(b);
                });
            }
            if (mdl.Invoice) {
                this.LoadDidaInvoiceProcessor(mdl.Invoice);
            }
        });
    }
    getInvoice(option: InvoiceSearchOption): HttpObservable<InvoiceSearchResult> {
        return this.httpClient.get('/HotelBookingInvoiceApi/GetClientInvoice', {
            option: option,
        }, null, (mdl) => {
            if (mdl.DidaInvoiceList && mdl.DidaInvoiceList.length > 0) {
                mdl.DidaInvoiceList.forEach(b => {
                    this.LoadDidaInvoiceProcessor(b);
                });
            }
        }, (payload) => {
            let opt: InvoiceBookingSearchOption = payload.option;
            if (opt.BeginDate && opt.EndDate) {
                opt.BeginDate = DateTimeHelper.format(opt.BeginDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
                opt.EndDate = DateTimeHelper.format(opt.EndDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
            }
        });
    }

    getClientInvoiceInfo(invoiceID: string): HttpObservable<InvoiceClientInfoResult> {
        return this.httpClient.get('/HotelBookingInvoiceApi/GetClientInvoiceInfo', {
            invoiceID: invoiceID,
        }, null, (mdl) => {
            if (mdl.ClientInvoiceInfos && mdl.ClientInvoiceInfos.length > 0) {
                mdl.ClientInvoiceInfos.forEach(b => {
                    this.LoadClientInvoiceInfoProcessor(b);
                });
            }
        });
    }


    getClientInvoiceInfoDetail(infoID: number): HttpObservable<ClientInvoiceInfoDetail> {
        return this.httpClient.get('/HotelBookingInvoiceApi/GetClientInvoiceInfoDetail', {
            infoID: infoID,
        }, null, (mdl) => {
            if (mdl.Detail) {
                this.LoadClientInvoiceInfoProcessor(mdl.Detail);
            }
        });
    }

    searchIvoiceInfoList(option: IvoiceInfoOption): HttpObservable<PaginationWrapper<ClientInvoiceInfo>> {
        return this.httpClient.get('/HotelBookingInvoiceApi/GetInvoiceInfoList', {
            option: option,
        }, null, (mdl) => {
            if (mdl.Data && mdl.Data.length > 0) {
                mdl.Data.forEach(b => {
                    this.LoadClientInvoiceInfoProcessor(b);
                });
            }
        }, (payload) => {
            const opt: IvoiceInfoOption = payload.searchOptions;
        });
    }

    upload(files: FormData, parameters): HttpObservable<InvoiceContextModel> {
        return this.httpClient.post('/HotelBookingInvoiceApi/SaveInvioceSynopsisImg', files);
    }

    clientApplyInvoice(options: ClientInvoiceInfoOption): HttpObservable<ApplyInvoiceResult> {
        return this.httpClient.post('/HotelBookingInvoiceApi/ClientApplyInvoice', options);
    }

    genInvoiceID(bookingIDs: string[]): HttpObservable<string> {
        return this.httpClient.post('/HotelBookingInvoiceApi/GenInvoice', bookingIDs);
    }

    updateInvoiceInfo(data: ClientInvoiceInfo) {
        return this.httpClient.post<AddOrUpdateInvoiceResult>('/HotelBookingInvoiceApi/AddOrUpdateInvoice', data);
    }

    private InvoiceBookingProcessor(detail: InvoiceBooking) {
        if (detail.CheckIn) {
            detail.CheckIn = DateTimeHelper.parse(detail.CheckIn, true);
        }
        if (detail.CheckOut) {
            detail.CheckOut = DateTimeHelper.parse(detail.CheckOut, true);
        }
        if (detail.CancellationDate) {
            detail.CancellationDate = DateTimeHelper.parse(detail.CancellationDate, true);
        }
        if (detail.OrderDate) {
            detail.OrderDate = DateTimeHelper.parse(detail.OrderDate, true);
        }
    }

    private LoadDidaInvoiceProcessor(invoice: DidaInvoice) {
        if (invoice.CreateTime) {
            invoice.CreateTime = DateTimeHelper.parse(invoice.CreateTime, true);
        }
        if (invoice.LastUpdateTime) {
            invoice.LastUpdateTime = DateTimeHelper.parse(invoice.LastUpdateTime, true);
        }
        if (invoice.SendTime) {
            invoice.SendTime = DateTimeHelper.parse(invoice.SendTime, true);
        }
    }

    private LoadClientInvoiceInfoProcessor(info: ClientInvoiceInfo) {
        if (info.CreateDate) {
            info.CreateDate = DateTimeHelper.parse(info.CreateDate, true);
        }
    }
}
