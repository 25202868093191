import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaFormGroupComponent } from './dida-form-group.component';
import { DidaFormLabelDirective } from './dida-form-label.directive';
import { DidaFormHintDirective } from './dida-form-hint.directive';
import { DidaFormErrorDirective } from './dida-form-error.directive';
import { DidaInputPlaceholderDirective } from './dida-input-placeholder.directive';
import { DidaUsernameComponent } from './dida-username/dida-username.component';
import { DidaEmailComponent } from './dida-email/dida-email.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    DidaFormGroupComponent,
    DidaFormHintDirective,
    DidaFormErrorDirective,
    DidaFormLabelDirective,
    DidaInputPlaceholderDirective,
    DidaUsernameComponent,
    DidaEmailComponent,
  ],
  declarations: [
    DidaFormGroupComponent,
    DidaFormHintDirective,
    DidaFormErrorDirective,
    DidaFormLabelDirective,
    DidaInputPlaceholderDirective,
    DidaUsernameComponent,
    DidaEmailComponent,
  ]
})
export class DidaFormGroupModule { }
