import { HttpServiceBase } from '../http';
import { IDataEntityModel, IFileUploadOptions, ImageLinkModel } from './models';
import { IPaginationWrapper, ObjectHelper } from '@dida-shopping/dida-services/common';
import { BannerCategoryEnum } from './enums/banner-category.enum';
import { ActivityBannerModel } from './models/activity-banner.model';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { EntityCategoryEnum } from './enums/entity-category.enums';
import { EntityModifyTypeEnum } from './enums/entity-modify-type.enum';
import { PromotionHotelModel } from './models/promotion-hotel-info.model';

class ResourceService extends HttpServiceBase {
  private _updateTimeCache = new Map<string, string>();

  constructor() {
    super();
  }

  /**
   * 获取资源数据
   * @param type
   */
  getResourceEntities<T>(type: string) {
    return this.httpClientWrapper.get<IDataEntityModel<T>[]>(
      '/ResourceApi/GetDataEntity',
      { entityType: type, PageSize: 100 },
      {
        resultProcessor: (raw: IPaginationWrapper<IDataEntityModel<T>>) => {
          return this.flattenDataEntityPage(raw);
        },
      }
    );
  }

  /**
   * 获取资源数据项
   * @param type
   * @param key
   */
  getResourceEntity<T>(type: string, key: string) {
    return this.httpClientWrapper.get<IDataEntityModel<T>>(
      '/ResourceApi/GetDataEntity',
      { entityType: type, entityKey: key },
      {
        resultProcessor: (raw: IPaginationWrapper<IDataEntityModel<T>>) => {
          const arr = this.flattenDataEntityPage(raw);
          if (!arr[0]) {
            return null as any;
          }
          return arr[0];
        },
      }
    );
  }

  /**
   * 获取资源数据项(附带模型提取器（extractor）)
   * @param type
   * @param key
   */
  getResourceEntityExtracted<T>(type: string, key: string, extractor: (entity: IDataEntityModel<T>) => T) {
    return this.httpClientWrapper.get<T>(
      '/ResourceApi/GetDataEntity',
      { entityType: type, entityKey: key },
      {
        resultProcessor: (raw: IPaginationWrapper<IDataEntityModel<T>>) => {
          const arr = this.flattenDataEntityPage(raw);
          if (!arr[0]) {
            return null;
          }
          return extractor(arr[0]);
        },
      }
    );
  }

  /**
   * 更新数据
   */
  updateResourceEntity(entity: IDataEntityModel<string>, modifyType: EntityModifyTypeEnum) {
    entity.UpdateTime = this._updateTimeCache.get([entity.EntityType, entity.EntityKey].join('.'));
    return this.httpClientWrapper.post<boolean>('/ResourceApi/UpdateDataEntity', {
      entity: entity,
      modifyType,
    });
  }

  /**
   * 获取推广（广告）酒店
   * @param hotelId
   */
  getPromotionData(hotelId) {
    return resourceService.getResourceEntityExtracted<PromotionHotelModel>(
      EntityCategoryEnum.HotelPromotion,
      hotelId,
      (entity) => {
        return entity.EntityValue[0];
      }
    );
    //.pipe(map(item => item.EntityValue[0]));
  }

  /**
   * 获取推广（广告）酒店列表
   */
  getPromotionList() {
    return resourceService.getResourceEntityExtracted<ImageLinkModel[]>(
      EntityCategoryEnum.HotelPromotion,
      'HotelList',
      (entity) => {
        return entity.EntityValue.map((item) => new ImageLinkModel(item)).filter((item) => item.IsValid);
      }
    );
  }

  /**
   * 获取轮播图数据
   * @param bannerCategory 轮播图类型
   * @param raw 用于配置页
   */
  getBannerList(
    bannerCategory: BannerCategoryEnum | string,
    options?: Partial<{
      lang: LanguageType;
    }> | boolean
  ) {
    return this.getResourceEntityExtracted<ActivityBannerModel[]>(
      EntityCategoryEnum.BannerSetting,
      bannerCategory,
      (resp) => {
        let values = resp.EntityValue.map((item) => {
          item.Inactive = item.Inactive || false;
          // if (item.BannerType === undefined) {
          //   item.BannerType = 'activity';
          // }
          if (item.Id && item.BannerType) {
            item.DefaultReferenceUrl = ['/promotion', item.BannerType, item.Id].filter((seg) => !!seg).join('/');
          }

          return new ActivityBannerModel(item);
        });

        const getAll = options === true
        if (getAll) {
          return values.sort((a, b) => {
            if (a.IsValid && !b.IsValid) {
              return -1;
            } else {
              return 1;
            }
          });
        }

        values = values.filter((item) => item.IsValid);

        if(typeof options === 'object') {
          if (options.lang) {
            if (options.lang !== LanguageType.zh_CN) {
              values = values.filter((item) => !item.IsCNOnly);
            }
            values.map((item) => item.switchLanguage(options.lang));
          }
        }

        // 没有链接则使用默认的
        values.map((item) => {
          item.ReferenceUrl = item.ReferenceUrl || item.DefaultReferenceUrl;
        });

        return values;
      }
    );
  }

  /**
   * 保存banner设置
   * @param catetoryType banner位置
   * @param bannerList banner数据
   */
  updateBannerSetting(catetoryType: string, bannerList: ActivityBannerModel[]) {
    const model: IDataEntityModel<string> = {} as any;
    model.EntityValue = JSON.stringify(bannerList);
    model.EntityType = EntityCategoryEnum.BannerSetting;
    model.EntityKey = catetoryType;
    return this.updateResourceEntity(model, EntityModifyTypeEnum.Update);
  }

  uploadFile(formData: FormData, options: IFileUploadOptions) {
    for (const key of Object.keys(options)) {
      formData.append(key, options[key]);
    }
    return this.httpClientWrapper.post<string>('/FileApi/Upload', formData);
  }

  getHotelPoster(option, userId: string) {
    return this.httpClientWrapper.post<string>('/Poster/GetHotelPoster', { option, userId, v: 2 });
  }

  getBulletin<T = any>(id: string) {
    return this.httpClientWrapper.post<T>(
      '/ResourceApi/SearchBulletin',
      {
        options: {
          ID: id,
          withContent: true,
          PageNum: 1,
          PageSize: 10,
        },
      },
      {
        resultProcessor: (resp) => resp.Data[0],
      }
    );
  }

  searchBulletin<T = any>(lang: string, type: number = 2, pageNum: number = 1, pageSize: number = 10) {
    return this.httpClientWrapper.post<IPaginationWrapper<T>>('/ResourceApi/SearchBulletin', {
      options: {
        Type: type,
        Lang: lang,
        PageSize: pageSize,
        pageNum: pageNum,
      },
    });
  }

  getHomePageConfig() {
    return this.httpClientWrapper.get('/ResourceApi/GetHomePageConfig');
  }

  updateRewardRecord(records: any[]) {
    const model: IDataEntityModel<string> = {} as any;
    model.EntityValue = JSON.stringify(records);
    model.EntityType = EntityCategoryEnum.HomePageData;
    model.EntityKey = 'HonorRecordList';
    return this.updateResourceEntity(model, EntityModifyTypeEnum.Update);
  }

  private flattenDataEntityPage<T>(page: IPaginationWrapper<IDataEntityModel<T>>) {
    return page.Data.map((item) => {
      this._updateTimeCache.set([item.EntityType, item.EntityKey].join('.'), item.UpdateTime);
      item.EntityValue = JSON.parse(item.EntityValue.toString());
      return item;
    });
  }
}

export const resourceService = new ResourceService();
