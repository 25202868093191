import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ServerTimeInterceptor implements HttpInterceptor {

  constructor() {
    console.log(`[ServerTimeInterceptor] created.`);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        try {
          if (event instanceof HttpResponse) {
            if (event.body.ServerTime) {
              const serverTime = DateTimeHelper.parse(
                event.body.ServerTime,
                true
              );
              DateTimeHelper.syncServerTime(serverTime);
            }
          }
        } catch {
          // TODO
        }
      })
    );
  }
}
