export enum CalendarModeEnum {
    SingleDate = 1,
    DateRange = 2,
    Month = 3,
    // MultiDates = 3, // not supported
}

export enum EnumDateSelectType {
    None = 0,
    FromDate = 1,
    EndDate = 2,
    Pendding = 3, // 选择FromDate后准备选择EndDate
    ResetFromDate = 4, // 重选FromDate，发生在选完EndDate后，重新打开日历选择了一个比FromDate更前的日期
    // EndDateToFromDate = 5, // 选了EndDate,但是选了一个比EndDate更前的日期
}

export enum EnumSelectStatus {
    None = 1,
    Start = 2,
    End = 3,
    Selected = 4,
    StartEnd = 5, // Start和End是同一天
    Reselected = 6, // 重选
}

export enum EnumTrianglePos {
    FromDate = 1,
    ToDate = 2
}

export enum EnumMonthNameCN {
    '一月' = 1,
    '二月' = 2,
    '三月' = 3,
    '四月' = 4,
    '五月' = 5,
    '六月' = 6,
    '七月' = 7,
    '八月' = 8,
    '九月' = 9,
    '十月' = 10,
    '十一月' = 11,
    '十二月' = 12
}

export enum EnumMonthNameEN {
    'January' = 1,
    'February' = 2,
    'March' = 3,
    'April' = 4,
    'May' = 5,
    'June' = 6,
    'July' = 7,
    'August' = 8,
    'September' = 9,
    'October' = 10,
    'November' = 11,
    'December' = 12
}

export enum EnumDayOfWeekNameCN {
    '日' = 0,
    '一' = 1,
    '二' = 2,
    '三' = 3,
    '四' = 4,
    '五' = 5,
    '六' = 6
}

export enum EnumDayOfWeekNameEN {
    'Su' = 0,
    'Mo' = 1,
    'Tu' = 2,
    'We' = 3,
    'Th' = 4,
    'Fr' = 5,
    'Sa' = 6
}
