import {
  SupplierHotelDestination,
  ShoppingHotelStaticDetail
} from '../../../shared/models/hotel/hotel-static-detail.model';
import { Suppliers } from '../../../shared/enums/supplier-type';
import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';

import { HotelStaticDetail } from '../../../shared/models/hotel/hotel.models';
import { RoomTypeSearchResult, RoomTypeContent } from '../../../shared/models/room/room.models';
import { HotelStaticDataOptions } from '../../../shared/models/hotel/hotel-static-data-options.model';
import { IDidaApplicationUserContextModel, SigningHelper } from '@dida-shopping/dida-services/auth';
import { map } from 'rxjs/operators';
import { RecommendContent } from '../../../shared/models/hotel/hotel-recommend.models';
import { HotelSearchSubregionModel } from '../../../hotels/shared/hotel-search-filter/hotel-search-filter.model';
import { HotelVideoModel } from '@dida-shopping/dida-services/hotel'

/**
 * 酒店静态信息数据服务
 *
 * @export
 * @class HotelStaticService
 */
@Injectable()
export class HotelStaticService {

  constructor(
    private logger: LoggerService,
    private httpClient: HttpClient
  ) {
  }

  /**
   * 获取比价接口
   *
   * @param {number} hotelId
   * @returns {HttpObservable<boolean>}
   * @memberof HotelStaticService
   */
  getCrawlerControl(hotelId: number) {
    return this.httpClient.get<boolean>('/HotelApi/GetCrawlerControl', { id: hotelId });
  }


  /**
   * 获取指定区域下的子区域
   * @param regionId
   * @returns
   */
  getHotelSearchSubRegion(regionId: string) {
    return this.httpClient.get<{ [key: string]: HotelSearchSubregionModel[] }>('/HotelApi/GetHotelSearchSubRegion', { regionId });
  }


  /**
   * 获取酒店全量静态信息
   *
   * **注意：该接口会返回全量信息，数据量会很大**
   *
   * @param {number} hotelId
   * @param {boolean} [withMeta=true]
   * @param {boolean} [withStaticMap=true]
   * @param {boolean} [withTransferLocations=false]
   * @returns {HttpObservable<ShoppingHotelStaticDetail>}
   * @memberof HotelStaticService
   */
  getHotelDetail(hotelId: number, withMeta = true, withTransferLocations = false) {
    return this.httpClient
      .get<ShoppingHotelStaticDetail>('/HotelApi/GetHotelStaticById', { id: hotelId, withMeta, withTransferLocations })
      .pipe(map(item => {
        if (item.HotelMeta && item.HotelMeta.WebsiteUrl && !item.HotelMeta.WebsiteUrl.startsWith('http')) {
          item.HotelMeta.WebsiteUrl = 'http://' + item.HotelMeta.WebsiteUrl;
        }
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        return item;
      }));
  }


  getHotelDetailOpen(hotelId: number) {
    return this.httpClient.get<HotelStaticDetail>('/HotelApi/GetHotelStaticOpen', { id: hotelId })
      .pipe(map(item => {
        if (item.HotelMeta && item.HotelMeta.WebsiteUrl && !item.HotelMeta.WebsiteUrl.startsWith('http')) {
          item.HotelMeta.WebsiteUrl = 'http://' + item.HotelMeta.WebsiteUrl;
        }
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        return item;
      }));
  }

  getHotelStaticData(hotelId: number, options?: HotelStaticDataOptions) {
    return this.httpClient.get<HotelStaticDetail>('/HotelApi/GetHotelStaticData', { id: hotelId, options }).pipe(
      map(item => {
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        return item;
      })
    );
  }


  /**
   * 获取猜你喜欢的酒店
   */
  getRecommendContent() {
    return this.httpClient.get<RecommendContent[]>('/HotelApi/GetRecommendContent').pipe(
      map(resp => {
        return (resp || []).filter(region => {
          region.Hotels = region.Hotels || [];
          return region.Hotels.length > 0;
        });
      })
    );
  }

  /**
   * 获取酒店静态地图
   *
   * @param {number} hotelId
   * @param {number} height
   * @param {number} width
   * @param {string} [iconUrl=null]
   * @param {boolean} [useHDImage=null]
   * @param {number} [zoomLevel=null]
   * @returns
   * @memberof HotelStaticService
   */
  getHotelGeoStaticMapData(
    hotelId: number, height: number, width: number,
    context: IDidaApplicationUserContextModel,
    iconUrl: string = null,
    useHDImage: boolean = null,
    zoomLevel: number = null) {

    let sign: string = null;
    let userName = context.userIdentityInfo.profile.UserName;

    return this.httpClient.get<{
      DidaHotelID: number,
      StaticMapUrl: string,
      StaticMapContent: string,
      StaticMapContentType: string,
    }>('/HotelApi/GetHotelGeoStaticMapData', {
      hotelId,
      height,
      width,
      iconUrl,
      useHDImage,
      zoomLevel
    }, null, null, (payload) => {
      let queryParam = {
        hotelId: hotelId,
        height: height,
        width: width,
        iconUrl: iconUrl,
        useHDImage: useHDImage,
        zoomLevel: zoomLevel,
        username: userName,
        pin: new Date().getTime()
      };
      payload.sign = sign = SigningHelper.generateSignature(queryParam);
      payload.pin = queryParam.pin;
    });
  }

  getHotelVideo(hotelId: number) {
    return this.httpClient.get<HotelVideoModel>('/HotelApi/GetHotelVideo', {
      hotelId
    })
  }

  /**
   * 获取酒店房型信息
   *
   * @param {number} hotelId 酒店ID
   * @returns {HttpObservable<RoomTypeSearchResult>}
   * @memberof HotelStaticService
   */
  getHotelRoomInfo(hotelId: number) {
    return this.httpClient.get<RoomTypeSearchResult>('/HotelApi/GetHotelRoomContent', { hotelId }, null, (result) => {
      if (result.Rooms) {
        result.Rooms.forEach(r => {
          if (r.Images) {
            // r.Images.forEach(m => {
            //   if (!m.Url.startsWith('http')) {
            //     m.Url = UrlHelper.toAvailableBackgroundUrl(`https://image-cdn.didatravel.com/${m.Url}`);
            //   }
            // });
            r.Images = r.Images.sort((a, b) => a.IsDefault ? 1 : 0);
          }
        });
      }
    });
  }

  /**
   * 获取房型信息
   * * Opt1: supplierId + rateplanId
   * * Opt2: responseId + ratePlanMask
   * @param {number} hotelId 酒店ID
   * @param {number} supplierId 供应商ID
   * @param {string} ratePlanId 供应商RatePlanID
   * @param {string} responseId
   * @param {string} ratePlanMask
   * @returns {HttpObservable<RoomTypeContent>}
   * @memberof HotelStaticService
   */
  getRoomInfo(hotelId: number, supplierId: number, ratePlanId: string,
              responseId: string, ratePlanMask: string) {
    return this.httpClient.get<RoomTypeContent>('/HotelApi/GetRoomContent', {
      hotelId, supplierId, ratePlanId, responseId, ratePlanMask
    }).pipe(
      map(result => {
        if (result) {
          if (result.Images) {
            result.Images = result.Images.sort((a, b) => a.IsDefault ? 1 : 0);
          }
        } else {
          // 这时候result是一个空字符串你敢信？
          result = null;
        }
        return result;
      })
    );
  }
  // BEFORE MERGE:
  // getRoomInfo(hotelId: number, supplierId: number, ratePlanId: string,
  //     responseId: string, ratePlanMask: string): HttpObservable<RoomTypeContent> {
  //     return this.httpClient.get('/HotelApi/GetRoomContent', {
  //         hotelId, supplierId, ratePlanId, responseId, ratePlanMask
  //     }, null, (result) => {
  //         if (result) {
  //             if (result.Images) {
  //                 result.Images = result.Images.sort((a, b) => a.IsDefault ? 1 : 0);
  //             } else {
  //               result.Images = [];
  //             }
  //         }
  //     });
  // }

  /**
   * 获取供应商酒店信息
   * @param hotelId
   * @param suppliers
   */
  getHotelSuppliersList(hotelId: number, suppliers?: Suppliers[]) {
    return this.httpClient.get<SupplierHotelDestination[]>('/HotelApi/GetHotelSupplierList', { hotelId, sids: suppliers && suppliers.join(',') });
  }
}
