import { CMonth } from './CMonth.Model';

export class CYear {
    Year: number;
    MonthList: CMonth[] = []; // { [key: string]: CDate[] } = {};

    static createYear(year: number): CYear {
        const cYear = new CYear();
        cYear.Year = year;
        for (let i = 0; i < 12; i++) {
            let m = CMonth.createMonth(year, i);
            m.Enable = true;
            cYear.MonthList.push(m);
        }
        return cYear;
    }

    get FirstDate(): Date {
        return new Date(this.Year, 1, 1);
    }

    get LastDate(): Date {
        return new Date(this.Year + 1, 1, 0); 
    }
}

