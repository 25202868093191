import { NgModule } from '@angular/core';
import { DidaUITKMonthPickerComponent } from './components/month-picker/month-picker.component';
import { DidaUITKDateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DidaUITKCalendarComponent } from './components/calendar/calendar.component';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { DidaUITKIconModule } from '@dida-shopping/ngx-dida-uitk/icons';

@NgModule({
  declarations: [
    DidaUITKCalendarComponent,
    DidaUITKMonthPickerComponent,
    DidaUITKDateRangePickerComponent
  ],
  imports: [
    CommonModule,
    NzIconModule,
    DidaUITKIconModule
  ],
  exports: [
    DidaUITKCalendarComponent,
    DidaUITKMonthPickerComponent,
    DidaUITKDateRangePickerComponent
  ]
})
export class DidaUITKCalendarModule { }
