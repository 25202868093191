import { LanguageType } from '../../i18n';

export abstract class ResourceBase<T> {
  protected i18n: Map<LanguageType, string> = new Map<LanguageType, string>();

  name_cn: string;
  name_en: string;

  protected constructor(
    public readonly code: T,
    public readonly name: string,
    public order = -1,
    lang: LanguageType = LanguageType.en_US
  ) {
    this.addLangSetting(lang, name);
  }

  addLangSetting(lang: LanguageType, name: string): ResourceBase<T> {
    const langType = this.toStandardLanguageType(lang);
    this.i18n.set(langType, name);
    switch (langType) {
      case LanguageType.en_US:
        this.name_en = name;
        break;
      case LanguageType.zh_CN:
        this.name_cn = name;
        break;
      default:
        break;
    }
    return this;
  }

  displayName(lang: LanguageType): string {
    const langType = this.toStandardLanguageType(lang);
    if (this.i18n.has(langType)) {
      return this.i18n.get(langType);
    }
    return this.i18n[LanguageType.en_US];
  }

  clone(): ResourceBase<T> {
    const cloned = this.constructSelf();
    this.i18n.forEach((value, lang, map) => {
      cloned.addLangSetting(lang, value);
    });
    return cloned;
  }

  protected toStandardLanguageType(lang: LanguageType) {
    switch (lang) {
      case LanguageType.en_US:
      case LanguageType._en_US:
        return LanguageType.en_US;
      case LanguageType.zh_CN:
      case LanguageType._zh_CN:
        return LanguageType.zh_CN;
      default:
        break;
    }
  }

  protected abstract constructSelf(): ResourceBase<T>;
}
