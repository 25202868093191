import {
  Directive,
  ViewContainerRef,
  TemplateRef,
  EventEmitter,
  Host,
  HostBinding,
  Input,
  Output,
  ElementRef,
  Renderer2,
  HostListener,
  OnInit,
  AfterContentInit
} from '@angular/core';
import { DidaUITKSortingFieldDirective } from './sorting-field.directive';

export class DidaSortingField {

  name: string;

  sortAsc = true;

  constructor(
    private _viewContainerRef: ViewContainerRef
  ) { }


}

export interface DidaSortingConfig {
  name: string;
  orderBy: 'asc' | 'desc';
}


let uniqueInstanceId = 0;

@Directive({
  selector: '[ndSortingGroup]'
})
export class DidaUITKSortingGroupDirective implements OnInit, AfterContentInit {

  private _id = `ndSortingGroup${uniqueInstanceId++}`;
  private _groupName: string;
  private _defaultFieldName: string;
  private _fields: DidaUITKSortingFieldDirective[];

  @HostBinding('class.nd-sorting-group') class = true;

  @HostBinding('id')
  get id() {
    return `ndSortingGroup#${this._id}`;
  }

  @Output() ndChange = new EventEmitter<DidaSortingConfig>();

  @Input() ndSortingGroup(name: string) {
    this._groupName = name;
  }

  _addField(field: DidaUITKSortingFieldDirective) {
    if (!this._fields) {
      this._fields = [];
    }
    this._fields.push(field);
    if (this._defaultFieldName === field.name) {
      field.active = true;
      this.ndChange.emit({
        name: field.name,
        orderBy: field.isSortAsc ? 'asc' : 'desc'
      });
    }
  }

  _changeField(name: string, asc: boolean) {
    let target: DidaUITKSortingFieldDirective = null;
    this._fields.forEach(x => {
      if (x.name === name) {
        target = x;
        target.active = true;
      } else {
        x.active = false;
      }
    });
    if (!target) {
      throw new Error(`target field not found: ${name}`);
    }
    target.field.sortAsc = asc;
    this.ndChange.emit({
      name: name,
      orderBy: asc ? 'asc' : 'desc'
    });
  }

  constructor(
    private elRef: ElementRef,
  ) {

  }

  ngOnInit() {
    const element: HTMLElement = this.elRef.nativeElement;
    const field = element.getAttribute('sorting-default-field');
    if (field) {
      this._defaultFieldName = field;
    }
  }

  ngAfterContentInit() {
    this.reset();
  }

  reset() {
    const defaultField = this._fields.find(x => x.name === this._defaultFieldName);
    if (!defaultField) {
      this._changeField(defaultField.name, defaultField.defaultOrder === 'asc');
    }
  }

}


