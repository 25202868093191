<div class="alert-section" *ngIf="hasUserLogin && showOTAForbiddenNotice">
    <ng-container *didaExperiment="otaForbidControl">
        <a href="javascript:;" (click)="closeOTAForbiddenNotice()" class="pull-right">&times;</a>
        <div i18n="@@Text_Notice_OTAForbidden">本账号所采购的酒店禁止售卖至携程、去哪儿、飞猪、艺龙、美团等OTA平台，如有发现，我司将会扣取整单房费作为罚金。</div>
    </ng-container>
</div>

<app-page-meta i18n-keywords="@@Text_SEO_Keywords" keywords="深圳市旅赞旅游科技股份有限公司,深圳旅赞,LvzanTravel,国际酒店"></app-page-meta>
<app-page-meta i18n-description="@@Text_SEO_Desc"
    description="深圳市旅赞旅游科技股份有限公司于2012年成立，专注于中国出境旅游市场，是一家基于I.T技术优势和运营优势的互联网旅游公司。主营业务：国际酒店的线上（系统API对接大型OTA）及线下（针对线下旅行社）分销。">
</app-page-meta>

<!--
    BUG: https://tower.im/teams/191049/todos/80519/
    Fix: use [hidden] tag to make sure the `appContext` subscription work correctly inside the `ngOnInit` method,
         while using `*ngIf`(previous) will miss some event timing, here, the `GlobalMessageTypeEnum.RouteDataChange` event.
-->
<app-navbar [hidden]="routeData.hideLayout"></app-navbar>

<div [class]="routeData.themeClass" [ngClass]="{'main-container': !this.routeData.hideLayout}">
    <router-outlet></router-outlet>
</div>

<ng-container *ngIf="!routeData.hideLayout">
  <app-sidebar *ngIf="!routeData.hideNav && !routeData.hideSideNav"></app-sidebar>

  <app-footer></app-footer>

  <app-suspension *ngIf="appContext"></app-suspension>
</ng-container>



<!-- 网络错误提示弹窗 -->
<div bsModal #netErrorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    (onHidden)="onNetworkErrModalClosed($event)">
    <div class="modal-dialog modal-sm network-modal-dialog" role="document">
        <div class="modal-content network-modal-content">
            <div class="modal-header network-modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="netErrorModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center" style="padding-top: 10px;">
                <img style="width: 160px;"
                    src="https://dsimage.didatravel.com/v2-app/images/network_error.png?x-oss-process=image/resize,w_480"
                    alt="" />
                <p class="network-error-tips" i18n="@@Label_NetworkError">哎呀服务器好像罢工了，请稍后再试吧~</p>
            </div>
        </div>
    </div>
</div>
<!-- 网络错误提示弹窗 -->

<!-- 消息弹窗 -->
<!-- 优惠券弹窗 -->
<!-- 移到ngx-dida-uitk/navbar/notice-modal-wrapper -->
