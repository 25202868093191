import { IEventModelBase } from './event-base.model';
import { MessageCenterEventTypeEnum } from '../enums';

export abstract class MessageCenterEventBase implements IEventModelBase<MessageCenterEventTypeEnum> {

  protected constructor(
    public readonly type: MessageCenterEventTypeEnum,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }

}

export class MessageCenterUnreadMessageUpdateEvent extends MessageCenterEventBase {

  constructor(
    public readonly userId: string,
    source: string,
    time: Date = null
  ) {
    super(
      MessageCenterEventTypeEnum.UnreadMessageUpdate,
      source,
      time
    );
  }
}
