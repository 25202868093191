import { CMonth } from '../../models/CMonth.Model';
import { CDateHelper } from '../../common/CDateHelper';
import { CalendarOptions } from './CalendarOptions.Model';
import { EnumSelectStatus, CalendarModeEnum } from '../../models/EnumDefine.Model';
import { CYear } from '../../models/CYear.Model';
import { CalendarHelper } from './CalendarHelper';
import { ICalendarCell } from '../../models/ICalendarCell';

export enum EnumMouseStatus {
    None = 0,
    FromDateSelected = 1,
    EndDateSelected = 2
}

export class Calendar {
    DisplayYearList: CYear[];
    DisplayMonthList: CMonth[];
    currentMode: CalendarModeEnum;

    constructor(public options: CalendarOptions) {
        this.options = options;
        this.init(options.FromDate);
    }

    static createCalendar(options: CalendarOptions) {
        const cal = new Calendar(options);
        return cal;
    }

    clearSelect() {
        this.DisplayMonthList.forEach(m => {
            m.DateList.forEach(d => {
                d.SelectStatus = EnumSelectStatus.None; // enable的状态不用改变
            });
        });
        this.DisplayYearList.forEach(y => {
            y.MonthList.forEach(m => {
                m.SelectStatus = EnumSelectStatus.None; // enable的状态不用改变
            });
        });
    }

    private processFromDate (date: Date, d: ICalendarCell) {
        if (CDateHelper.compareDate(d.Date, date) === 0) {
            // 开始时间
            if (CDateHelper.compareDate(d.Date, this.options.EndDate) === 0) {
                d.SelectStatus = EnumSelectStatus.StartEnd;
            } else {
                d.SelectStatus = EnumSelectStatus.Start;
            }
        } else if (CDateHelper.compareDate(d.Date, date) > 0
            && CDateHelper.compareDate(d.Date, this.options.EndDate) < 0) {
            // 选中
            d.SelectStatus = EnumSelectStatus.Selected;
        } else if (CDateHelper.compareDate(d.Date, this.options.EndDate) === 0) {
            // 结束时间
            d.SelectStatus = EnumSelectStatus.End;
        } else {
            // 其他
            d.SelectStatus = EnumSelectStatus.None;
        }
        d.IsMouseOver = false;
    }
    resetFromDate(date: Date) {
        // 这里逻辑是如果已经设置了StartDate和EndDate，只要当前StartDate < EndDate，则直接把当前date当成StartDate，并且刷新日历，不清空EndDate
        if (CalendarHelper.isDatePicker(this.options.Mode)) {
            this.DisplayMonthList.forEach(m => {
                m.DateList.forEach(d => {
                    this.processFromDate(date, d);
                });
            });
        }
        if (CalendarHelper.isMonthPicker(this.options.Mode)) {
            this.DisplayYearList.forEach(m => {
                m.MonthList.forEach(d => {
                    this.processFromDate(date, d);
                });
            });
        }
        return true;
    }

    /**
     * 设置开始时间
     * 1. 把之前选择的全部置成非选中状态
     * 2. 把当前date设置为当前显示时间
     *
     * @param {Date} date
     * @returns {boolean}
     * @memberof Calendar
     */
    setFromDate(date: Date): boolean {
        // if (!this.valid(date)) {
        //     return;
        // }
        this.clearSelect(); // 清除选择

        // let isFound = false;
        this.DisplayMonthList.forEach(m => {
            if (CDateHelper.isInMonth(date, m)) {
                m.DateList.forEach(d => {
                    if (CDateHelper.dateEquals(date, d.Date)) {
                        // d.IsFromDate = true;
                        d.SelectStatus = EnumSelectStatus.Start;
                        // isFound = true;
                    }
                });
            }
        });
        this.DisplayYearList.forEach(m => {
            if (CDateHelper.isInYear(date, m)) {
                m.MonthList.forEach(d => {
                    if (CDateHelper.dateEquals(date, d.Date)) {
                        // d.IsFromDate = true;
                        d.SelectStatus = EnumSelectStatus.Start;
                        // isFound = true;
                    }
                });
            }
        });
        return true;
    }

    /*
    选择结束日期时的mouseover
    遍历当前显示的月份，如果日期 是选择开始日期/在选择期间内/是选择结束日期，分别设置不同的样式。
    */
    private processMouseOver (date: Date, maxDays: number, d: ICalendarCell) {
        // 判断当前日期是否已经超过maxDays,ps:maxDays要减1
        if (CDateHelper.getDays(d.Date, this.options.FromDate) > maxDays - 1) {
            d.SelectStatus = EnumSelectStatus.None;
            d.IsMouseOver = false;
            return;
        }
        const cFromDate = CDateHelper.compareDate(d.Date, this.options.FromDate);
        const cToDate = CDateHelper.compareDate(d.Date, this.options.EndDate);
        if (cFromDate < 0) {
            // 清除样式
            d.SelectStatus = EnumSelectStatus.None;
        } else if (cFromDate === 0) {
            // 开始时间
            if (cToDate === 0) {
                // 开始结束同一天
                d.SelectStatus = EnumSelectStatus.StartEnd;
            } else {
                d.SelectStatus = EnumSelectStatus.Start;
            }
        } else if (CDateHelper.compareDate(d.Date, date) <= 0) {
            d.SelectStatus = EnumSelectStatus.Reselected;
        } else if (cToDate < 0) {
            // 枚举的日期小于选择结束日期
            d.SelectStatus = EnumSelectStatus.Selected;
        } else if (cToDate === 0) {
            // 枚举的日期为选择结束日期
            d.SelectStatus = EnumSelectStatus.End;
        } else {
            d.SelectStatus = EnumSelectStatus.None;
        }
        d.IsMouseOver = false;
    }
    mouseOver(date: Date, maxDays: number) {
        this.DisplayMonthList.forEach(m => {
            m.DateList.forEach(d => {
                this.processMouseOver(date, maxDays, d);
            });
        });
        this.DisplayYearList.forEach(m => {
            m.MonthList.forEach(d => {
                this.processMouseOver(date, maxDays, d);
            });
        });
    }

    private processEndDate (date: Date, d: ICalendarCell) {
        if (CDateHelper.compareDate(d.Date, this.options.FromDate) === 0) {
            // 开始时间
            if (CDateHelper.compareDate(d.Date, date) === 0) {
                d.SelectStatus = EnumSelectStatus.StartEnd;
            } else {
                d.SelectStatus = EnumSelectStatus.Start;
            }
        } else if (CDateHelper.compareDate(d.Date, this.options.FromDate) > 0
            && CDateHelper.compareDate(d.Date, date) < 0) {
            // 选中
            d.SelectStatus = EnumSelectStatus.Selected;
        } else if (CDateHelper.compareDate(d.Date, date) === 0) {
            // 结束时间
            d.SelectStatus = EnumSelectStatus.End;
        } else {
            // 其他
            d.SelectStatus = EnumSelectStatus.None;
        }
        d.IsMouseOver = false;
    }
    setEndDate(date: Date) {
        // if (!this.valid(date)) {
        //     return;
        // }
        this.DisplayMonthList.forEach(m => {
            m.DateList.forEach(d => {
                this.processEndDate(date, d);
            });
        });
        this.DisplayYearList.forEach(m => {
            m.MonthList.forEach(d => {
                this.processEndDate(date, d);
            });
        });
        return true;
    }

    nextMonth() {
        const firstDate = this.DisplayMonthList[0].DateList[0].Date;
        const nextMonthFirstDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 1);
        this.init(nextMonthFirstDate);
    }

    prevMonth() {
        const firstDate = this.DisplayMonthList[0].DateList[0].Date;
        const prevMonthFirstDate = new Date(firstDate.getFullYear(), firstDate.getMonth() - 1, 1);
        this.init(prevMonthFirstDate);
    }

    prevYear() {
        const firstDate = this.DisplayYearList[0].FirstDate;
        const prevYearFirstDate = new Date(firstDate.getFullYear() - 1, 0, 1);
        this.init(prevYearFirstDate);
    }

    nextYear() {
        const firstDate = this.DisplayYearList[0].FirstDate;
        const nextYearFirstDate = new Date(firstDate.getFullYear() + 1, 0, 1);
        this.init(nextYearFirstDate);
    }

    // private isDatePicker() {
    //     if (this.options.Mode === CalendarModeEnum.DateRange
    //         || this.options.Mode === CalendarModeEnum.SingleDate) {
    //         return true;
    //     }
    //     return false;
    // }

    // private isMonthPicker() {
    //     if (this.options.Mode === CalendarModeEnum.Month) {
    //         return true;
    //     }
    //     return false;
    // }

    /**
     * 初始化
     *
     * @private
     * @param {Date} dateInFirstMonth 第一个月(年)的日期
     * @memberof Calendar
     */
    private init(initDate: Date) {
        // 创建月份
        this.DisplayMonthList = [];
        this.DisplayYearList = []; // 清空原来的日历
        if (CalendarHelper.isDatePicker(this.options.Mode)) {
            for (let i = 0; i < this.options.DisplayCalendarCount; i++) {
                const m = CMonth.createMonth(initDate.getFullYear(), initDate.getMonth() + i);
                this.DisplayMonthList.push(m);
            }
            // 选择
            this.DisplayMonthList.forEach(m => {
                m.DateList.forEach(d => {
                    if (this.options.MinDate && CDateHelper.compareDate(d.Date, this.options.MinDate) < 0) {
                        d.Enable = false;
                    }
                    if (this.options.MaxDate && CDateHelper.compareDate(d.Date, this.options.MaxDate) > 0) {
                        d.Enable = false;
                    }
                    if (this.options.FromDate && CDateHelper.compareDate(d.Date, this.options.FromDate) === 0) {
                        // 开始时间
                        if (CDateHelper.compareDate(d.Date, this.options.EndDate) === 0) { // 如果同时等于结束时间
                            d.SelectStatus = EnumSelectStatus.StartEnd;
                        } else {
                            d.SelectStatus = EnumSelectStatus.Start;
                        }
                    } else if (this.options.EndDate
                        && CDateHelper.compareDate(d.Date, this.options.FromDate) > 0
                        && CDateHelper.compareDate(d.Date, this.options.EndDate) < 0) {
                        // 选中
                        d.SelectStatus = EnumSelectStatus.Selected;
                    } else if (this.options.EndDate && CDateHelper.compareDate(d.Date, this.options.EndDate) === 0) {
                        // 结束时间
                        d.SelectStatus = EnumSelectStatus.End;
                    } else {
                        // 其他
                        d.SelectStatus = EnumSelectStatus.None;
                    }
                    d.IsMouseOver = false;
                });
            });
        } else if (CalendarHelper.isMonthPicker(this.options.Mode)) {
            for (let i = 0; i < this.options.DisplayCalendarCount; i++) {
                const y = CYear.createYear(initDate.getFullYear());
                this.DisplayYearList.push(y);
            }
            //
            this.DisplayYearList.forEach(y => {
                y.MonthList.forEach(m => {
                    if (this.options.MinDate && CDateHelper.compareDate(m.FirstDate, this.options.MinDate) < 0) {
                        m.Enable = false;
                    }
                    if (this.options.MaxDate && CDateHelper.compareDate(m.FirstDate, this.options.MaxDate) > 0) {
                        m.Enable = false;
                    }
                    if (this.options.FromDate && CDateHelper.compareDate(m.FirstDate, this.options.FromDate) === 0) {
                        // 开始时间
                        if (CDateHelper.compareDate(m.FirstDate, this.options.EndDate) === 0) { // 如果同时等于结束时间
                            m.SelectStatus = EnumSelectStatus.StartEnd;
                        } else {
                            m.SelectStatus = EnumSelectStatus.Start;
                        }
                    } else if (this.options.EndDate
                        && CDateHelper.compareDate(m.FirstDate, this.options.FromDate) > 0
                        && CDateHelper.compareDate(m.FirstDate, this.options.EndDate) < 0) {
                        // 选中
                        m.SelectStatus = EnumSelectStatus.Selected;
                    } else if (this.options.EndDate && CDateHelper.compareDate(m.FirstDate, this.options.EndDate) === 0) {
                        // 结束时间
                        m.SelectStatus = EnumSelectStatus.End;
                    } else {
                        // 其他
                        m.SelectStatus = EnumSelectStatus.None;
                    }
                    m.IsMouseOver = false;
                });
            });
        }
    }

    onCalendarClose() {
        // 日历关闭时
    }

    getValue() {
        return [this.options.FromDate, this.options.EndDate];
    }
}
