import { HttpServiceBase } from "../http";
import {
  IUserCouponCacheModel,
  IUserCouponCountModel,
  CouponSearchOptions,
  ICouponModel
} from "./models";
import {
  JsonHelper,
  IPaginationWrapper
} from "@dida-shopping/dida-services/common";
import { CouponTypeEnum } from "./enums/coupon-type.enum";
import { FieldGroupRestrictionTypeEnum } from "./enums/field-group-restriction-type";

class CouponService extends HttpServiceBase {
  constructor() {
    super();
  }

  searchCouponByCache(isModal: boolean) {
    return this.httpClientWrapper.get<IUserCouponCacheModel>(
      "/CouponApi/SearchCacheCoupons",
      {
        isModal: isModal
      },
      {
        resultProcessor: (result: IUserCouponCacheModel) => {
          if (result.CouponModels && result.CouponModels.length > 0) {
            result.CouponModels = JsonHelper.parseJsonWithDate(
              JSON.stringify(result.CouponModels)
            );
          }
          return result;
        }
      }
    );
  }

  searchCouponCacheWithoutProcess(isModal: boolean) {
    return this.httpClientWrapper.get<IUserCouponCacheModel>(
      "/CouponApi/SearchCacheCoupons",
      {
        isModal
      }
    );
  }

  searchCouponCount() {
    return this.httpClientWrapper.get<IUserCouponCountModel[]>(
      "/CouponApi/SearchCouponCount"
    );
  }

  searchCoupon(payload: Partial<CouponSearchOptions>) {
    return this.httpClientWrapper.post<IPaginationWrapper<ICouponModel>>(
      "/CouponApi/Search",
      {
        payload: payload
      },
      {
        resultProcessor: result => {
          if (result.Data && result.Data.length > 0) {
            result.Data = JsonHelper.parseJsonWithDate(
              JSON.stringify(result.Data)
            );
          }
          (result.Data || []).map((item: ICouponModel) => {
            switch (item.CouponType) {
              case CouponTypeEnum.DiscountOnCertainPurchaseValue:
                if (
                  item.RestrictionRuleModel.OrderTimeRestriction
                    .RestrictionType !== FieldGroupRestrictionTypeEnum.Disabled
                ) {
                  item.EffectiveTimeSetting =
                    item.RestrictionRuleModel.OrderTimeRestriction;
                }
                break;
            }
          });
          return result;
        }
      }
    );
  }
}

export const couponService = new CouponService();
