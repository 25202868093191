<div class="nationality-picker" [class.nationality-picker_select]="ndMode === 'select'" [class.nationality-picker_open]="ndVisible" [class.nationality-picker_disabled]="ndDisabled" #host>
  <div class="nationality-picker__label" *ngIf="ndMode === 'default' && ndShowLabel" i18n="国籍@@Label_Nationality">国籍</div>
  <div class="nationality-picker__trigger" (click)="trigger(!ndVisible)">
    <span *ngIf="ndShowPlaceHolder && !value;else text" class="nationality-picker__placeholder" i18n="@@Text_Placeholder_NationalityPlaceholder">请选择国籍</span>
    <ng-template #text>
      <span class="nationality-picker__text nd-text-ellipsis">
        <ng-container >{{ value?.displayName(ndLang) }}</ng-container>
      </span>
    </ng-template>
    <span class="nationality-picker__icon">
      <i [class.nationality-picker__icon_open]="ndVisible" nz-icon nzType="down" nzTheme="outline"></i>
    </span>
  </div>
  <nd-nationality-select class="nationality-picker__list" [@collapseMotion]="ndVisible ? 'expanded' : 'collapsed'" [ndLang]="ndLang" [(ndNationality)]="value" (ndSelect)="trigger(false)"></nd-nationality-select>
</div>
