import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CalendarComponent,
    DateRangePickerComponent,
    MonthPickerComponent,
  ],
  exports: [
    CalendarComponent,
    DateRangePickerComponent,
    MonthPickerComponent,
  ],
  providers: []
})
export class DateTimePickerModule { }
