import { DidaSuggestionTypeaheadConfig } from '@dida-shopping/dida-services/suggestions';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { HotelPriceSearchOptions } from '../../../shared/models/rates/hotel-price-search-option.model';
import { RealTimeFilter } from '../../../shared/models/rates/realtime-filter.model';
import { HotelPriceSearchFilter } from '../../../shared/models/rates/hotel-price-search-filter.model';
import { Occupancy } from '@dida-shopping/dida-services/rate';
import { QueryParamHelper } from '@dida-shopping/ngx-dida-site-core';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';

export class DidaHotelSearchConfig {
  /**
   * 默认的 check-in date
   */
  checkInDate? = DateTimeHelper.addDays(DateTimeHelper.today, 1);

  /**
   * 默认的 check-out date
   */
  checkOutDate? = DateTimeHelper.addDays(this.checkInDate, 1);

  /**
   * 默认的 房间数量
   */
  roomCount? = 1;

  /**
   * 默认的 入住信息
   */
  occupancy?: Occupancy = {
    RoomNum: 1,
    AdultCount: 2,
    ChildCount: 0,
    ChildAgeList: []
  };

  /**
   * 建议搜索配置
   */
  suggestionTypeaheadConfig = new DidaSuggestionTypeaheadConfig();

  /**
   * 开启搜索条件配置
   */
  enableSearchFilter = false;

  /**
   * Room occupancy config of dida hotel search config
   */
  // roomOccupancyConfig = new RoomOccupancyConfig();

  /**
   * 是否在加载时尝试解析路由参数
   */
  enableRouteParamsHandle = false;

  nationality: string;

  /**
   * 自定义标签
   *
   * @type {boolean}
   * @memberof DidaHotelSearchConfig
   */
  customizeLabel?: boolean;
}

export interface DidaHotelSearchPriceRange {
  minimum: number;
  maximum: number;
}

export class DidaHotelSearchPayload {
  keyword?: string;
  hotelID?: number;
  regionID?: string;
  regions?: string[];
  checkInDate: Date;
  checkOutDate: Date;
  roomCount: number;
  occupancy: Occupancy;
  priceRange: DidaHotelSearchPriceRange;
  starRatings: number[];
  nationality: string;
  promotionId: string;
  area?: {
    lng: number;
    lat: number;
    radius: number;
  };

  constructor() {
    this.checkInDate = new Date();
    let checkoutDate = new Date();
    checkoutDate.setDate(checkoutDate.getDate() + 1);

    // nationality: Nationality = getNationality('CN');
    this.checkOutDate = checkoutDate;
  }

  toSearchOption(): HotelPriceSearchOptions {
    const obj = this;
    const options = new HotelPriceSearchOptions();
    options.PageNum = 1;
    options.CountPerPage = 10;
    const realTimeFilter = new RealTimeFilter();
    realTimeFilter.IsRealTime = false;
    const hotelPriceSearchFilter = new HotelPriceSearchFilter();

    if (obj.hotelID) {
      options.HotelIDList = [obj.hotelID];
    }

    if (obj.checkInDate) {
      options.CheckInDate = obj.checkInDate;
    }

    if (obj.checkOutDate) {
      options.CheckOutDate = obj.checkOutDate;
    }

    if (obj.regions && obj.regions.length) {
      options.Regions = obj.regions;
    }

    if (obj.regionID) {
      options.DestinationID = obj.regionID;
    }

    if (obj.roomCount) {
      realTimeFilter.RoomCount = obj.roomCount;
    }

    if (obj.occupancy) {
      realTimeFilter.OccupancySetting = obj.occupancy;
    }

    if (obj.keyword) {
      hotelPriceSearchFilter.HotelKeyWord = obj.keyword;
    }

    if (obj.priceRange) {
      hotelPriceSearchFilter.MaxPrice = obj.priceRange.maximum;
      hotelPriceSearchFilter.MinPrice = obj.priceRange.minimum;
    }

    if (obj.starRatings) {
      hotelPriceSearchFilter.StarRating = obj.starRatings;
    }

    if (obj.nationality) {
      options.Nationality = obj.nationality;
    }

    if (obj.area) {
      hotelPriceSearchFilter.Radius = obj.area.radius;
      hotelPriceSearchFilter.Location = new GeoCoordinate(
        obj.area.lat,
        obj.area.lng
      );
    }

    options.PageNum = 1;
    options.CountPerPage = 10;

    options.RealTimeFilter = realTimeFilter;
    options.PriceSearchFilter = hotelPriceSearchFilter;
    return options;
  }
  toRatePlanSearchOption(): HotelPriceSearchOptions {
    const options = new HotelPriceSearchOptions();
    options.CheckInDate = this.checkInDate.toLocaleDateString() as any;
    options.CheckOutDate = this.checkOutDate.toLocaleDateString() as any;
    options.PriceSearchFilter = null;
    options.SortFilter = null;
    options.RealTimeFilter.RoomCount = this.roomCount;
    options.RealTimeFilter.OccupancySetting = this.occupancy;
    return options;
  }
  toQueryParam() {
    let result = QueryParamHelper.serialize(this, param => {
      param.checkInDate = DateTimeHelper.format(
        param.checkInDate,
        'yyyy-MM-dd'
      );
      param.checkOutDate = DateTimeHelper.format(
        param.checkOutDate,
        'yyyy-MM-dd'
      );
      delete param.hotelID;
    });
    result.regionID = null;
    return result;
  }
}
