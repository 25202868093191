<div class="dida-select" [class.dida-select__active]="active" selector="selector">
    <div dropdown [autoClose]="!multiple" (isOpenChange)="dropdownVisibilityChange($event)" [container]="container">
        <span dropdownToggle class="dida-select__toggle" [class.dida-select__toggle_border]="border">
            <span class="dida-select__toggle-content" [class.dida-select__toggle-content_no-select]="shouldShowPlaceholder">
                <span *ngIf="showFallback">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span class="dida-select__trigger-value">
                    <ng-container *ngIf="customTemplate;else default">
                        <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: selected }"></ng-container>
                    </ng-container>
                    <ng-template #default>
                        <ng-container *didaStringTemplateOutlet="triggerValue">{{ triggerValue }}</ng-container>
                    </ng-template>
                </span>
                <span class="dida-select__indicator dida-icon dida-icon-dropdown dida-icon-dropdown_sm"
                    [class.dida-icon-dropdown_opened]="opened"></span>
            </span>
        </span>
        <ul *dropdownMenu class="dropdown-menu dida-select__option-list  dropdown-menu-right"
            [class.dropdown-menu-right]="align === 'right'" [attr.id]="dropdownMenuId" role="menu">
            <ng-content select="dida-select-option"></ng-content>
        </ul>
    </div>
</div>
