import { Nationality, DEFAULT_NATIONALITY_CODE } from './models';
import { NationalityDirectory } from './nationality-collection.res';
import { LanguageType } from '../i18n';

const CHAR_CODE_A = 'A'.charCodeAt(0);

export class NationalityHelper {

  /**
   * 根据CountryCode获取Nationality
   * @param code
   */
  static getNationality(code?: string): Nationality {
    if (!code) {
      code = DEFAULT_NATIONALITY_CODE;
    } else {
      code = code.toUpperCase().trim(); // 预处理
    }
    const index = code.charCodeAt(0) - CHAR_CODE_A;
    const dirItem = NationalityDirectory[index];
    if (dirItem) {
      const tar = dirItem.items.find(x => x.code === code);
      if (tar) {
        return tar.clone(); // 返回一个新对象，防止污染
      }
    }
    return null;
  }

  /**
   * 根据CountryCode获取国籍展示名
   * @param code
   * @param lang
   */
  static getNationalityName(code: string, lang: LanguageType): string {
    const nationality = NationalityHelper.getNationality(code);
    if (nationality) {
      return nationality.displayName(lang);
    }
    return null;
  }
}
