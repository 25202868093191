/**
 * boolean值解析
 * 
 * @export
 * @param {*} value 
 * @returns {boolean} 
 */
export function coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false'; 
} 
