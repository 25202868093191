
import { ObjectHelper } from '@dida-shopping/dida-services/common';
import { ExperimentID, IExperimentControls } from '@dida-shopping/dida-services/experiment';
import { LanguageType } from '@dida-shopping/dida-services/i18n';


export class ImageLinkI18nModel {
    Title: string;
    SubTitle: string;
    Desc: string;
    Reference: string;
    ImgUrl: string;
    ImgUrl2: string;
    ImgPreview: string;
}

export class ImageLinkModel extends ImageLinkI18nModel {
    Id: string;
    StartDate: string;
    Expired: string;
    ReferenceUrl: string;
    Controls: ExperimentID[];
    ClientIds: string[];
    IsCNOnly: boolean;
    /**
     * @deprecated 请使用Inactive代替
     */
    InActive: boolean;
    Inactive: boolean;
    en_US: ImageLinkI18nModel;
    zh_CN: ImageLinkI18nModel;
    get IsValid() {
        if (this.InActive) {
            return false;
        }
        return !this.IsExpired && !this.IsIneffective;
    }

    get IsExpired() {
        if (!this.Expired) {
            return false;
        }
        // 兼容safari
        // Expired格式固定yyyy-MM-dd HH:mm:ss
        let expiredTime = +Date.parse(this.Expired.replace(/-/g, '/'));
        if (expiredTime < Date.now()) {
            return true;
        }
        return false;
    }

    get IsIneffective() {
        if (!this.StartDate) {
            return false;
        }

        let startDate = +Date.parse(this.StartDate.replace(/-/g, '/'));
        if (startDate > Date.now()) {
            return true;
        }
        return false;
    }


    static normalize(item, lang = LanguageType.zh_CN): ImageLinkModel {

        item = ObjectHelper.assign(new ImageLinkModel(), item);

        item.zh_CN = ObjectHelper.assign(item.toI18nModel(), item[LanguageType.zh_CN], item.zh_CN);

        let enModel = new ImageLinkI18nModel();

        item.en_US = ObjectHelper.assign(item.toI18nModel(), item[LanguageType.en_US], item.en_US, enModel);

        ObjectHelper.assign(item, lang === LanguageType.en_US ? item.en_US : item.zh_CN);
        return item;
    }

    constructor() {
        super();
        this.en_US = new ImageLinkI18nModel();
        this.zh_CN = new ImageLinkI18nModel();
        this.IsCNOnly = true;
    }

    toI18nModel() {
        let translation = new ImageLinkI18nModel();
        translation.Desc = this.Desc;
        translation.ImgUrl = this.ImgUrl;
        translation.ImgPreview = this.ImgPreview;
        translation.ImgUrl2 = this.ImgUrl2;
        translation.Reference = this.Reference;
        translation.Title = this.Title;
        translation.SubTitle = this.SubTitle;
        return translation;
    }
}

export class SiteNotification {
    Id: string;
    StartDate: string;
    EndDate: string;
    Include: string[];
    Exclude: string[];
    Title: string;
    Appellation: string;
    Inscribe: string;
    Subject: string;
    Controls: ExperimentID[];
}

export enum ProcessTypeEnum {
    Add, Edit, Delete
}

export enum ProcessStepTypeEnum {
    Initial, Processing, Success, Failed
}

export enum ColorThemeEnum {
    Primary = 'primary',
    Info = 'info',
    Waring = 'warning',
    Success = 'success',
    Danger = 'danger',
}

export class HotelListPageBannerSettings {
    language: string;
    title: string;
    imgUrl: string;
    linkUrl: string;
    isEnable: boolean;
    experiment: IExperimentControls;
}
