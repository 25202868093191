import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface GlobalSelectionEvent {
  id: string;
  visible: boolean;
}

export class GlobalSelectionEventControl {
    static selectionEventSubject = new Subject<GlobalSelectionEvent>();
}
