<div class="dida-select-option"
  [class.dida-select-option_active]="active"
  [class.dida-select-option_disabled]="disabled"
  [class.dida-select-option_selected]="selected"
  [class.dida-select-option_multiple]="multiple">
  <span class="dida-select-option__content">
    <i *ngIf="multiple" class="icon icon-checkbox checkbox__icon" [class.icon-checkbox_selected]="selected"></i>
    <ng-content></ng-content>
  </span>
</div>
