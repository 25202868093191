import { mergeMap, filter, flatMap } from 'rxjs/operators';
// import { BaseComponent } from './base.component';
// import { WechatService } from './core/services/auth/wechat.service';
import { MessageModel } from './shared/models/message/message.model';
import { combineLatest, Observable, of } from 'rxjs';
import { HttpClient } from './core/http/http-client';
import {
  Component,
  ViewChild,
  OnInit,
  TemplateRef,
  AfterViewInit,
  isDevMode, OnDestroy
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
// import { LoggerService } from './core/logger/logger.service';
// import { ApplicationContextService } from './core/services/auth/application-context.service';
import { MessageService } from './core/services/message/message.service';
import {
  Router,
  ResolveEnd,
  ActivationStart,
  ActivationEnd,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
  Scroll
} from '@angular/router';
import {
  ApplicationContextUpdateEvent,
  ApplicationEventHub, ApplicationRouteDataChangeEvent,
  ApplicationService, CampaignEventHub, CampaignLoginShowCouponModalEvent,
  ComponentBaseWithContext, IRouteDataModel, ServiceEventHub, ServiceRequestFinishedEvent
} from '@dida-shopping/ngx-dida-site-core';
// import { AppMessage, SiteNotification } from './shared/models/shared-models';
// import { GlobalMessageTypeEnum } from './shared/enums/app-message-types';
import { ExperimentID, ExperimentControls } from '@dida-shopping/dida-services/experiment';

import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ExternalRoutingHelper } from './core/router/external-routing-helper';
// import { Converter } from './shared/utils/converter';
// import { MessageSearchOptionModel } from './shared/models/message/message.models';
// import { CarouselComponent } from 'ngx-bootstrap/modal';

// import { DidaApplicationUserContextModel } from './shared/models/auth/application-context.model';
// import { ActionService } from './core/services/action/action.service';
// import { CouponService } from './core/services/campaigns/coupon/coupon.service';
// import { CouponModel } from './shared/models/campaigns/coupons/coupon.model';
// import { ExperimentService } from './core/services/experiment/experiment.service';
import { ViewportScroller } from '@angular/common';

import { weChatService } from '@dida-shopping/dida-services/wechat';
import { RouteDataHelper } from '@dida-shopping/ngx-dida-site-core';
// import { DateTimeHelper } from '@dida-shopping/dida-services/common';

const VIEWPORT_STATIC = 'width=1200,initial-scale=1';
const VIEWPORT_RESPONSIVE =
  'width=device-width,user-scalable=no, initial-scale=1';

@Component({
  selector: 'dida-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ComponentBaseWithContext implements OnInit, OnDestroy, AfterViewInit {
  routeData: {
    themeClass: string;
    hideNav: boolean;
    hideLayout: boolean;
    hideSideNav: boolean;
  } = {
    themeClass: 'theme-default',
    hideNav: true,
    hideLayout: true,
    hideSideNav: true
  };
  showOTAForbiddenNotice = false;
  otaForbidControl = [];

  networkErrorDict: {
    [key: string]: object
  } = {};

  // 弹窗消息
  // @ViewChild('popupMessageModal', { static: false })
  // popupMessageModal: ModalDirective;
  // popupMessageList: MessageModel[];
  // popupMsgIndex = -1;
  // lockUrl: string;

  // 网络错误弹窗消息
  @ViewChild('netErrorModal', { static: true })
  netErrorModal: ModalDirective;

  // couponModel: CouponModel[] = [];
  // couponModalVisible = false;
  // couponControl = ExperimentControls.CouponControl;

  loadingInfo: {
    loading: boolean;
    title: string;
    desc?: string;
  } = {
    loading: false,
    title: ''
  };

  activityControl = ExperimentID.DidaActivity_NewBeginning2018;

  modalRef: BsModalRef;

  // showIENotice = false;

  _currentPath = '';

  constructor(
    // private appService: ApplicationService,
    private appEventHub: ApplicationEventHub,
    private serviceEventHub: ServiceEventHub,
    // private campaignEventHub: CampaignEventHub,
    private router: Router,
    // private route: ActivatedRoute,
    // private messageService: MessageService,
    private metaService: Meta,
    // private wechatService: WechatService,
    private modalService: BsModalService,
    // private couponService: CouponService,
    private viewportScroller: ViewportScroller
    // private experimentService: ExperimentService,
    // private actionService: ActionService,
    // private httpClient: HttpClient,
    // private logger: LoggerService,
    // private appContext: ApplicationContextService,
  ) {
    super();
    this.viewportScroller.setHistoryScrollRestoration('manual');
  }

  closeOTAForbiddenNotice() {
    if (localStorage && localStorage.setItem) {
      localStorage.setItem('hideOTAForbiddenNotice', '1');
    }
    this.showOTAForbiddenNotice = false;
  }

  getExternalUrl(location: string): string {
    return ExternalRoutingHelper.constructActualExternalUrl(location);
  }

  // readNextMsg(carousel: CarouselComponent) {
  //   if (carousel.isLast(this.popupMsgIndex)) {
  //     this.markCurMsgAsRead();
  //     this.popupMessageModal.hide();
  //     // unlock
  //     this.popupMessageList = null;
  //     this.popupMsgIndex = -1;
  //     return;
  //   }
  //   carousel.nextSlide();
  // }

  // msgSlideChange(index) {
  //   if (this.popupMsgIndex !== -1) {
  //     this.markCurMsgAsRead();
  //   }
  //   // tslint:disable-next-line:no-console
  //   console.debug('message slide index: ', this.popupMsgIndex, index);
  //   this.popupMsgIndex = index;
  // }

  // private markCurMsgAsRead() {
  //   let msgIndex = this.popupMsgIndex;
  //   let msg = this.popupMessageList[msgIndex];
  //   // 缓存与数据库可能不一致，这里强制已读
  //   if (!msg.IsRead) {
  //     // console.log('markAsRead', msgIndex, msg.MessageID);
  //   }
  //   this.messageService.read(msg.MessageID).subscribe(resp => {
  //     msg.IsRead = true;
  //   });
  // }

  checkPopupMessage() {
  }

  openModal(template: TemplateRef<any>, config?) {
    return (this.modalRef = this.modalService.show(template, config));
  }

  closeModal(storageKey?) {
    if (storageKey && localStorage && localStorage.setItem) {
      localStorage.setItem(storageKey, '1');
    }

    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  // closeCouponModal() {
  //   this.couponModalVisible = false;
  // }


  onNetworkErrModalClosed($event: ModalDirective) {
    this.networkErrorDict = {}; // clear all the errors.
  }

    scrollTo(id) {
        if (!id) {
            window.scrollTo(0, 0);
            return;
        }
        let target = null
        try {
          // 忽略异常hash
          // ps:百度统计页面点击图会添加一串hash导致querySelector报错
          // DOMException: Failed to execute 'querySelector' on 'Document': '#domain=www.didatravel.com&cla...qzggL&protocal=https:' is not a valid selector.
          target = document.querySelector('#' + id);
        } catch(e) {

        }
        if (!target) {
            window.scrollTo(0, 0);
            return;
        }
        try {
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        } catch (e) { }
    }

  ngOnInit() {
    super.ngOnInit();
    // OTA禁售提示
    this.showOTAForbiddenNotice =
      localStorage && !localStorage.getItem('hideOTAForbiddenNotice');


      // this.showIENotice = navigator.userAgent.indexOf('Trident') > -1;

    // 监听应用消息
    this.subscriptions['appEventHub:ApplicationContextUpdateEvent'] = (this.appEventHub.events.pipe(
      filter(event => event instanceof ApplicationContextUpdateEvent)
    ) as Observable<ApplicationContextUpdateEvent>).subscribe({
      next: (event) => {
        if (!this.otaForbidControl.length) {
          this.otaForbidControl.push(-809);
        }
        if (event.ctx.wechatConfig) {
          weChatService.config(event.ctx.wechatConfig);
        }
      }
    });

    // 监听服务请求结束消息
    this.subscriptions['serviceEventHub:ServiceRequestFinishedEvent'] = (this.serviceEventHub.events.pipe(
      filter(event => event instanceof ServiceRequestFinishedEvent)
    ) as Observable<ServiceRequestFinishedEvent>).subscribe({
      next: (event) => {
        if (event.error) {
          // 5** error detection
          if (Math.floor((event.error.status || 0) / 100) === 5) {
            this.networkErrorDict[event.url] = event.error;
          } else if (this.networkErrorDict[event.url]) {
            delete this.networkErrorDict[event.url];
          }

          if (event.error.status === 401) {
            // authentication required
            location.replace(`/account/login?returnUrl=${location.href}`);
          }
        }
        if (Object.keys(this.networkErrorDict).length > 0) {
          if (!this.netErrorModal.isShown) {
            this.netErrorModal.show();
          }
        } else {
          if (this.netErrorModal.isShown) {
            this.netErrorModal.hide();
          }
        }
      }
    });

    // // 监听活动信息
    // this.subscriptions['campaignEventHub'] = this.campaignEventHub.subscribe({
    //   next: (event) => {
    //     if (event instanceof CampaignLoginShowCouponModalEvent) {
    //       if (this.hasAnyExperiments([ExperimentID.CouponFeature]) && this.isCN) {
    //         this.couponService.searchCouponByCache(true).subscribe(resp => {
    //           if (resp.TotalAmount > 0) {
    //             this.couponModel = resp.CouponModels.sort((a, b) => {
    //               let value = -1 * DateTimeHelper.getDateOffset(a.EffectiveTimeSetting.To, b.EffectiveTimeSetting.To);
    //               if (value !== 0) {
    //                 return value;
    //               }
    //               value = (a.RewardSetting.CampaignRewardAmount - b.RewardSetting.CampaignRewardAmount) * -1;
    //               if (value !== 0) {
    //                 return value;
    //               }
    //               return value;
    //             });
    //             this.couponModalVisible = true;
    //           }
    //         });
    //       }
    //     }
    //   }
    // });

    // 监听路由变化
    this.subscriptions['router'] = this.router.events.subscribe(event => this.onRouteChange(event));

    // // 监听系统消息
    // this.subscriptions['router+messageService'] = combineLatest(
    //   this.router.events.pipe(filter(item => item instanceof ResolveEnd)),
    //   this.messageService.subject
    // ).pipe(
    //   mergeMap(([event, resp]) => {
    //     let url = (event as ResolveEnd).url;
    //     // lock
    //     if (this.popupMessageList || this.lockUrl === url) {
    //       return [];
    //     }
    //     if (resp && resp.Data.length > 0) {
    //       this.lockUrl = url;
    //     }

    //     let popupList =
    //       resp &&
    //       resp.Data.filter(item => {
    //         if (item.IsRead) {
    //           return false;
    //         }
    //         if (!item.ExtraSetting || !item.ExtraSetting.ShouldPopup) {
    //           return false;
    //         }
    //         if (
    //           item.ExtraSetting.StartTime &&
    //           new Date() < new Date(item.ExtraSetting.StartTime)
    //         ) {
    //           return false;
    //         }
    //         if (
    //           item.ExtraSetting.ExpireTime &&
    //           new Date() > new Date(item.ExtraSetting.ExpireTime)
    //         ) {
    //           return false;
    //         }
    //         if (item.ExtraSetting.SpecifyPage) {
    //           if (!new RegExp(item.ExtraSetting.SpecifyPage, 'i').test(url)) {
    //             return false;
    //           }

    //           item.ExtraSetting.Order = 0;
    //         }
    //         return true;
    //       });

    //     popupList = popupList.sort((a, b) => {
    //       return a.ExtraSetting.Order > b.ExtraSetting.Order ? 1 : -1;
    //     });

    //     let messageIdList = popupList.map(item => item.MessageID);
    //     if (messageIdList.length === 0) {
    //       return [];
    //     }
    //     let option = new MessageSearchOptionModel();
    //     option.Category = null;
    //     option.WithMessageContent = true;
    //     option.MessageIDList = messageIdList;
    //     return this.messageService.get(option);
    //   }))
    //   .subscribe(resp => {
    //     this.popupMessageList = resp.Data.map(item => {
    //       item.Content = (item.Content as string).replace(/\<a\s/g, '<a target="_blank" ')
    //       return item
    //     });
    //     if (resp.Data && resp.Data.length) {
    //       this.popupMessageModal.show();
    //     }
    //   });
  }

  onRouteChange(event) {
    // console.log(event.id || 0, event.toString());
    // if (event instanceof NavigationStart) {
    //     this.loadingInfo.loading = true;
    // }
    if (event instanceof ActivationStart) {
      let data = RouteDataHelper.getComputedRouteData(event.snapshot);
      this.appEventHub.broadcast(new ApplicationRouteDataChangeEvent(
        data, `[AppComponent::onRouteChange]`, new Date()
      ));
      //
      // this.appContext.emit(
      //   new AppMessage(GlobalMessageTypeEnum.RouteDataChange, data)
      // );
      this.routeData.themeClass = `theme-${data.theme || 'default'}`;
      // document.documentElement.classList.add(this.routeData.themeClass);
      this.routeData.hideNav = data.hideNav || false;
      // console.trace('hey')
      this.routeData.hideLayout = data.hideLayout || false;
      this.routeData.hideSideNav = data.hideSideNav || false;

      let viewPortConfig = data.responsive
        ? VIEWPORT_RESPONSIVE
        : VIEWPORT_STATIC;
      this.metaService.updateTag({ name: 'viewport', content: viewPortConfig });
    }

    // if (event instanceof ActivationEnd) {
    // }

    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.loadingInfo.loading = false;
    }

    if (event instanceof Scroll) {
      let e = event;
      let { urlAfterRedirects } = e.routerEvent;
      let urlPathAfterRedirects = (urlAfterRedirects || '').split('?')[0];
      if (e.position) {
        // backward navigation
        this.viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        // this.viewportScroller.scrollToAnchor(e.anchor);
        this.scrollTo(e.anchor);
      } else {
        // forward navigation
        // 如果只是参数变动则不滚动
        if (this._currentPath !== urlPathAfterRedirects) {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }
      this._currentPath = urlPathAfterRedirects;
    }

    // if (event instanceof ResolveEnd) {
    // }

    // if (event instanceof RouteConfigLoadStart) {

    // }
    // if (event instanceof RouteConfigLoadEnd) {

    // }
    // if (event instanceof RoutesRecognized) {

    // }
    // if (event instanceof GuardsCheckStart) {

    // }
    // if (event instanceof GuardsCheckEnd) {

    // }
    // if (event instanceof ResolveStart) {

    // }
  }

  ngAfterViewInit() {
    console.log('app view Init');
  }
}
