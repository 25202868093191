import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgxServiceHttpClientWrapper } from './ngx-service-http-client-wrapper';
import { NgxDidaEventsModule } from '../events';

export * from './http-observable.model';
export * from './ngx-http-params-helper';
export * from './ngx-service-http-client-wrapper';
export * from './ngx-http-call';
export * from './server-time-interceptor';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxDidaEventsModule,
  ],
  providers: [
    NgxServiceHttpClientWrapper
  ]
})
export class NgxDidaHttpModule {

}
