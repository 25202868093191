import { HttpServiceBase } from '../http';
import { FeedbackWorkOrderDataModel, IWorkOrderModel } from './models';


class WorkOrderSevice extends HttpServiceBase {

  constructor() {
    super()
  }

  createFeedbackWorkOrder(workOrderData: FeedbackWorkOrderDataModel) {
    return this.httpClientWrapper.post<IWorkOrderModel>('/WorkOrder/CreateFeedbackWorkOrder', { workOrderData });
}
}
export const workOrderService = new WorkOrderSevice()
