import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CalendarOptions } from './CalendarOptions.Model';
import {
  CalendarModeEnum,
  EnumDateSelectType,
  EnumDayOfWeekNameCN,
  EnumDayOfWeekNameEN,
  EnumMonthNameCN,
  EnumMonthNameEN
} from '../../models/EnumDefine.Model';
import {
  CalendarStatusFactory,
  DisplayOptions,
  EnumAnimationPos,
  EnumCalendarStatus,
  EnumTrianglePos,
  ICalendarStatus,
  ICalendarStatusComponent
} from './CalendarStatus.Model';
// import { ApplicationContextService } from '../../../../../core/services/auth/application-context.service';
import { Calendar } from './Calendar.Model';
import { CDate } from '../../models/CDate.Model';
import { CDateHelper } from '../../common/CDateHelper';
import { ICalendarCell } from '../../models/ICalendarCell';
import { CalendarHelper } from './CalendarHelper';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApplicationService } from '@dida-shopping/ngx-dida-site-core';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

@Component({
  selector: 'dida-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements ICalendarStatusComponent, AfterViewInit {
  language: LanguageType = this.appService.context ? this.appService.context.lang
    : LanguageType.zh_CN; // zh-CN or en-US

  @Input()
  mode: CalendarModeEnum;

  @Input()
  fromDate: Date;

  @Output()
  fromDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input()
  toDate: Date;

  @Input()
  displayCalendarCount = 2;

  @Output()
  toDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  selectTypeChange: EventEmitter<EnumDateSelectType> = new EventEmitter<
    EnumDateSelectType
  >();

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  @Input()
  minDays = 2; // 最少选择日期，默认2天

  @Input()
  maxDays = 3650; // 最大选择日期，默认28天

  // 样式相关的
  @Input()
  marginLeft = -20; // 整个弹出框左偏移
  @Input()
  marginTop = 25; // 整个弹出框上偏移
  @Input()
  showSriangle = true; // 显示小三角形
  @Input()
  sriangleFromMarginLeft = 50; // 小三角FromDate的左偏移
  @Input()
  sriangleToMarginLeft = 165; // 小三角ToDate的左偏移
  styles: SafeHtml;
  animationFromDateName = '';
  animationToDateName = '';

  calendarStatus: ICalendarStatus; // 日历状态
  options: CalendarOptions;
  display: DisplayOptions = new DisplayOptions();
  calendar: Calendar;

  constructor(
    private element: ElementRef,
    private sanitizer: DomSanitizer,
    private appService: ApplicationService,
  ) {}

  // isShowChanged = false; // 打开状态是否改变
  flashFlag = false;

  // @Input()
  showCalendar(selectType: EnumDateSelectType) {
    if (selectType === EnumDateSelectType.FromDate) {
      this.display.trianglePos = EnumTrianglePos.FromDate;
      this.display.animationPos = this.display.showCalendar
        ? EnumAnimationPos.FromDate
        : EnumAnimationPos.None; // 从关闭打开不放动画
      this.calendarStatus = CalendarStatusFactory.createExpectFromDateStatus(
        this
      );
    } else {
      this.display.trianglePos = EnumTrianglePos.ToDate;
      this.display.animationPos = this.display.showCalendar
        ? EnumAnimationPos.ToDate
        : EnumAnimationPos.None;
      this.calendarStatus = CalendarStatusFactory.createExpectToDateStatus(
        this
      );
    }
    this.display.showCalendar = true;

    this.animationFromDateName = `animation-from-date-${this.sriangleToMarginLeft}-${this.sriangleFromMarginLeft}`;
    this.animationToDateName = `animation-to-date-${this.sriangleFromMarginLeft}-${this.sriangleToMarginLeft}`;
    this.styles = this.sanitizer.bypassSecurityTrustHtml(`
    <style>
    .animation-from-date-${this.sriangleToMarginLeft}-${this.sriangleFromMarginLeft} {
        animation-name: keyframe_from-date-${this.sriangleToMarginLeft}-${this.sriangleFromMarginLeft};
        animation-duration: 0.3s;
        left: ${this.sriangleFromMarginLeft}px;
    }
    .animation-to-date-${this.sriangleFromMarginLeft}-${this.sriangleToMarginLeft} {
        animation-name: keyframe_to-date-${this.sriangleFromMarginLeft}-${this.sriangleToMarginLeft};
        animation-duration: 0.3s;
        left: ${this.sriangleToMarginLeft}px;
    }
    @keyframes keyframe_from-date-${this.sriangleToMarginLeft}-${this.sriangleFromMarginLeft} {
        from {
            left: ${this.sriangleToMarginLeft}px;
        }
        to {
            left: ${this.sriangleFromMarginLeft}px;
        }
    }
    @keyframes keyframe_to-date-${this.sriangleFromMarginLeft}-${this.sriangleToMarginLeft} {
        from {
            left: ${this.sriangleFromMarginLeft}px;
        }
        to {
            left: ${this.sriangleToMarginLeft}px;
        }
    }
    </style>`);
  }

  closeCalendar() {
    this.display.showCalendar = false;
    // 检查是否正常关闭
    if (CalendarHelper.isDateRangPicker(this.options.Mode)) {
      if (this.fromDate && !this.toDate) {
        // 如果toDate没有设，说明没有正常选择结束时间就关闭了
        const toDate = new CDate();
        const minDaySpan = this.minDays - 1;
        toDate.Date = new Date(
          this.fromDate.getFullYear(),
          this.fromDate.getMonth(),
          this.fromDate.getDate() + minDaySpan
        );
        if (
          this.options.MaxDate &&
          CDateHelper.compareDate(toDate.Date, this.options.MaxDate) > 0
        ) {
          toDate.Enable = false;
        } else {
          toDate.Enable = true;
        }
        this.calendarStatus.click(toDate.Date);
        // this.onCellClick(toDate);
      } else {
        // 如果大于maxDays,则模拟选择过程
        const days = CDateHelper.getDays(this.toDate, this.fromDate);
        if (days > this.maxDays - 1) {
          this.calendarStatus = CalendarStatusFactory.createExpectFromDateStatus(
            this
          );
          this.calendarStatus.click(this.fromDate);
          this.calendarStatus.click(
            new Date(
              this.fromDate.getFullYear(),
              this.fromDate.getMonth(),
              this.fromDate.getDate() + this.maxDays - 1
            )
          );
        }
      }
    } else if (this.options.Mode === CalendarModeEnum.SingleDate) {
      // do-nothing
    }
    this.selectTypeChange.emit(EnumDateSelectType.None);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes) {
    if (!this.options) {
      return;
    }
    let isChange = false;
    if (changes['fromDate']) {
      this.options.FromDate = changes['fromDate'].currentValue; // .previousValue
      isChange = true;
    }
    if (changes['toDate']) {
      this.options.EndDate = changes['toDate'].currentValue; // .previousValue
      isChange = true;
    }
    if (isChange) {
      this.calendar.mouseOver(this.options.FromDate, this.maxDays);
    }
  }

  // @ViewChild('calendarPopUp')
  // calendarPopUp: ElementRef;
  // get popUpHeight() {
  //   return this.calendarPopUp.nativeElement.offsetHeight() + 'px';
  // }

  // @ViewChild('calendarPopUp')
  // calendarPopUp: ElementRef;
  get maxWeekCount() {
    // return this.calendarPopUp.nativeElement.offsetHeight();
    // if (!this.calendar) {
    //   this.ngAfterViewInit();
    // }
    let max = 0;
    this.calendar.DisplayMonthList.forEach(m => {
      const tmp = Math.ceil((m.FrontDateList.length + m.DateList.length) / 7.0);
      max = tmp > max ? tmp : max;
    });
    return max;
  }

  get isDatePicker(): boolean {
    return CalendarHelper.isDatePicker(this.mode);
  }

  get isMonthPicker(): boolean {
    return CalendarHelper.isMonthPicker(this.mode);
  }

  ngAfterViewInit() {
    if (!this.options) {
      this.options = new CalendarOptions();
      this.options.Mode = CalendarModeEnum.DateRange;
      this.options.DisplayCalendarCount = 2;
      this.options.MinDate = CDateHelper.getToday();
      this.options.MaxDate = CDateHelper.addDays(this.options.MinDate, 365);
      this.options.FromDate = CDateHelper.getToday();
      this.options.EndDate = CDateHelper.addDays(this.options.FromDate, 1);
      this.options.MinDays = this.minDays;
      this.options.MaxDays = this.maxDays;
    } else {
      if (!this.options.Mode) {
        this.options.Mode = CalendarModeEnum.DateRange;
      }
      if (!this.options.DisplayCalendarCount) {
        this.options.DisplayCalendarCount = 2;
      }
      if (!this.options.MinDate) {
        this.options.MinDate = CDateHelper.getToday();
      }
      if (!this.options.MinDate) {
        this.options.MaxDate = CDateHelper.addDays(this.options.MinDate, 365);
      }
      if (!this.options.FromDate) {
        this.options.FromDate = CDateHelper.getToday();
      }
      if (!this.options.EndDate) {
        this.options.EndDate = CDateHelper.addDays(this.options.FromDate, 1);
      }
      if (!this.options.MinDays) {
        this.options.MinDays = this.minDays;
      }
      if (!this.options.MaxDays) {
        this.options.MaxDays = this.maxDays;
      }
    } // 设置默认值
    this._updateView();
  }

  _updateView() {
    const minDaySpan = this.minDays - 1; // 如果minDays=2，表示最少选2天，间隔是1天
    if (
      this.fromDate &&
      this.toDate &&
      CDateHelper.getDays(this.toDate, this.fromDate) < minDaySpan
    ) {
      this.toDate = CDateHelper.addDays(this.fromDate, minDaySpan);
      this.toDateChange.emit(this.toDate);
    }
    if (this.fromDate) {
      this.options.FromDate = this.fromDate;
    }
    if (this.toDate) {
      this.options.EndDate = this.toDate;
    }
    if (this.minDate) {
      this.options.MinDate = this.minDate;
    }
    if (this.maxDate) {
      this.options.MaxDate = this.maxDate;
    }
    if (this.displayCalendarCount) {
      this.options.DisplayCalendarCount = this.displayCalendarCount;
    }
    if (this.mode) {
      this.options.Mode = this.mode;
    } else {
      this.mode = CalendarModeEnum.DateRange;
      this.options.Mode = this.mode;
    }

    if (this.options.Mode === CalendarModeEnum.SingleDate) {
      this.options.EndDate = null;
    }
    this.calendar = Calendar.createCalendar(this.options); // 这个两次赋值会引发错误
  }

  onCellClick(date: ICalendarCell) {
    if (!date.Enable) {
      return;
    }
    this.calendarStatus.click(date.Date);
    this.flashFlag = !this.flashFlag;
  }

  validStartDate(fromDate: Date, toDate: Date = null): boolean {
    if (
      this.options.MinDate &&
      CDateHelper.compareDate(fromDate, this.options.MinDate) < 0
    ) {
      return false;
    }
    const minDaySpan = this.minDays - 1; // 如果minDays=2，表示最少选2天，间隔是1天
    const maxDaySpan = this.maxDays - 1;
    if (
      this.options.MaxDate &&
      CDateHelper.getDays(this.options.MaxDate, fromDate) < minDaySpan
    ) {
      return false;
    }
    if (toDate) {
      const daySpan = CDateHelper.getDays(toDate, fromDate);
      if (daySpan < minDaySpan || daySpan > maxDaySpan) {
        return false;
      }
    }
    return true;
  }

  validEndDate(fromDate: Date, toDate: Date): boolean {
    if (
      this.options.MinDate &&
      CDateHelper.compareDate(fromDate, this.options.MinDate) < 0
    ) {
      return false;
    }
    if (
      this.options.MaxDate &&
      CDateHelper.compareDate(toDate, this.options.MaxDate) > 0
    ) {
      return false;
    }
    const minDaySpan = this.minDays - 1; // 如果minDays=2，表示最少选2天，间隔是1天
    const maxDaySpan = this.maxDays - 1;
    const daySpan = CDateHelper.getDays(toDate, fromDate);
    if (daySpan < minDaySpan || daySpan > maxDaySpan) {
      return false;
    }
    return true;
  }

  onCellMouseOver(date: CDate) {
    // if ((!this.toDate) && this.calendarStatus.calendarStatusType === EnumCalendarStatus.FromDate2ToDateStatus) {
    //  this.calendar.mouseOver(date.Date);
    // }
    if (
      this.calendarStatus.calendarStatusType ===
        EnumCalendarStatus.FromDate2ToDateStatus ||
      this.calendarStatus.calendarStatusType ===
        EnumCalendarStatus.ExpectToDateStatus
    ) {
      this.calendar.mouseOver(date.Date, this.maxDays);
    }
    date.IsMouseOver = true;
  }

  popupMouseOut() {
    const d = CDateHelper.addDays(this.options.FromDate, -1);
    this.calendar.mouseOver(d, this.maxDays);
  }

  onCellMouseOut(date: CDate) {
    date.IsMouseOver = false;
  }

  previous() {
    if (this.previousDisable()) {
      return;
    }
    if (CalendarHelper.isMonthPicker(this.mode)) {
      this.calendar.prevYear();
    } else if (CalendarHelper.isDatePicker(this.mode)) {
      this.calendar.prevMonth();
    }
  }

  previousDisable() {
    if (this.options.MinDate) {
      const firstDate =
        this.mode === CalendarModeEnum.Month
          ? this.calendar.DisplayYearList[0].MonthList[0].FirstDate
          : this.calendar.DisplayMonthList[0].DateList[0].Date;
      if (CDateHelper.compareDate(firstDate, this.options.MinDate) <= 0) {
        return true; // disable
      }
    }
    return false;
  }

  next() {
    if (this.nextDisable()) {
      return;
    }
    if (CalendarHelper.isMonthPicker(this.mode)) {
      this.calendar.nextYear();
    } else if (CalendarHelper.isDatePicker(this.mode)) {
      this.calendar.nextMonth();
    }
  }

  nextDisable() {
    if (this.options.MaxDate) {
      let lastDate: Date = null;
      if (this.mode === CalendarModeEnum.Month) {
        const lastYear = this.calendar.DisplayYearList[
          this.calendar.DisplayYearList.length - 1
        ];
        lastDate = lastYear.LastDate;
      } else {
        const lastMonth = this.calendar.DisplayMonthList[
          this.calendar.DisplayMonthList.length - 1
        ];
        lastDate = lastMonth.DateList[lastMonth.DateList.length - 1].Date;
      }
      if (CDateHelper.compareDate(lastDate, this.options.MaxDate) >= 0) {
        return true; // disable
      }
    }
    return false;
  }

  getMonthName(index: number) {
    if (this.language === 'zh-CN') {
      return EnumMonthNameCN[index + 1];
    }
    return EnumMonthNameEN[index + 1];
  }

  getDayOfWeekName(index: number) {
    if (this.language === 'zh-CN') {
      return EnumDayOfWeekNameCN[index];
    }
    return EnumDayOfWeekNameEN[index];
  }
}
