import { fromEvent, Observable, OperatorFunction, PartialObserver, Subscription, UnaryFunction } from 'rxjs';

interface IDidaUITKEventCounter {
  subscriptions: Subscription[];
}

const EVENT_SUBSCRIPTIONS: {
  [eventKey: string]: IDidaUITKEventCounter
} = {};

export abstract class DidaUITKEventBase<Event extends UIEvent> {

  protected _event: Observable<Event>;
  protected _intervalPin: number;

  readonly eventKey: string;

  constructor(
    public readonly eventPrefix: string,
    public readonly eventName: string,
    protected readonly eventSource: Document | Window
  ) {
    this.eventKey = `${eventPrefix}:${eventName}`;
  }

  pipe(): Observable<Event>;
  pipe<A>(op1: OperatorFunction<Event, A>): Observable<A>;
  pipe<A, B>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>): Observable<B>;
  pipe<A, B, C>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>): Observable<C>;
  pipe<A, B, C, D>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>): Observable<D>;
  pipe<A, B, C, D, E>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>): Observable<E>;
  pipe<A, B, C, D, E, F>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>): Observable<F>;
  pipe<A, B, C, D, E, F, G>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>): Observable<G>;
  pipe<A, B, C, D, E, F, G, H>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>, op8: OperatorFunction<G, H>): Observable<H>;
  pipe<A, B, C, D, E, F, G, H, I>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>, op8: OperatorFunction<G, H>, op9: OperatorFunction<H, I>): Observable<I>;
  pipe<A, B, C, D, E, F, G, H, I>(op1: OperatorFunction<Event, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>, op8: OperatorFunction<G, H>, op9: OperatorFunction<H, I>, ...operations: OperatorFunction<any, any>[]): Observable<any>;
  pipe(...operations: OperatorFunction<any, any>[]): Observable<any> {
    if (operations.length === 0) {
      return this as any;
    }
    const ob = this.pipeFromArray_Internal(operations)(this.ensureEventOb() && this._event);
    const subscribe = ob.subscribe.bind(ob);
    ob.subscribe = (
      observerOrNext?: PartialObserver<any> | ((value: any) => void),
      error?: (error: any) => void,
      complete?: () => void
    ): Subscription => {
      return _eventObSubscribe(
        this,
        subscribe,
        observerOrNext,
        error,
        complete
      );
    };
    return ob;
  }

  subscribe(observerOrNext?: PartialObserver<Event> | ((value: Event) => void),
            error?: (error: any) => void,
            complete?: () => void): Subscription {
    return _eventObSubscribe(
      this,
      (this.ensureEventOb() && this._event).subscribe.bind(this._event),
      observerOrNext,
      error,
      complete
    );
  }

  private ensureEventOb(): boolean {
    if (!this._event) {
      this._event = fromEvent<Event>(this.eventSource, this.eventName);
    }
    return true;
  }

  private pipeFromArray_Internal<T, R>(fns: Array<UnaryFunction<T, R>>): UnaryFunction<T, R> {
    if (fns.length === 1) {
      return fns[0];
    }
    return function piped(input: T): R {
      return fns.reduce((prev: any, fn: UnaryFunction<T, R>) => fn(prev), input as any);
    };
  }
}


function _eventObSubscribe<E extends UIEvent>(
  src: DidaUITKEventBase<E>,
  op: (observerOrNext?: PartialObserver<Event> | ((value: Event) => void),
       error?: (error: any) => void,
       complete?: () => void) => Subscription,
  observerOrNext?: PartialObserver<Event> | ((value: Event) => void),
  error?: (error: any) => void,
  complete?: () => void
): Subscription {
  const subscription = op(observerOrNext, error, complete);
  const unsubscribe = subscription.unsubscribe.bind(subscription);
  subscription.unsubscribe = () => {
    _eventUnsubscribe(src, subscription, unsubscribe);
  };
  if (!EVENT_SUBSCRIPTIONS[src.eventKey]) {
    EVENT_SUBSCRIPTIONS[src.eventKey] = { subscriptions: [] };
  }
  const counter = EVENT_SUBSCRIPTIONS[src.eventKey];
  counter.subscriptions.push(subscription);
  return subscription;
}

function _eventUnsubscribe<E extends UIEvent>(
  src: DidaUITKEventBase<E>,
  subscription: Subscription,
  op: () => void
): void {
  op();
  const counter = EVENT_SUBSCRIPTIONS[src.eventKey];
  if (counter) {
    const idx = counter.subscriptions.findIndex(x => x === subscription);
    counter.subscriptions.splice(idx, 1);
  }
}
