import { NgModule } from '@angular/core';
import { DidaUITKExperimentControlDirective } from './experiment/experiment.directive';
import { CommonModule } from '@angular/common';
import { DidaUITKValidateDirective } from './validate/validate.directive';
import { DidaUITKBoxShadowDirective } from './box-shadow/box-shadow.directive';
import { DidaUITKAlertSizeDirective } from './alert-size/alert-size.directive';
import { DidaUITKGridLayoutDirective } from './grid-layout/grid-layout.directive';
import { DidaUITKPageMetaDirective } from './page-meta/page-meta.directive';
import { DidaUITKSortingGroupDirective } from './sorting-group/sorting-group.directive';
import { DidaUITKSortingFieldDirective } from './sorting-group/sorting-field.directive';
import { DidaUITKOssSrcDirective } from './oss-src/oss-src.directive';
import { DidaUITKV1HrefDirective } from './v1-href/v1-href.directive';

export { DidaUITKExperimentControlDirective } from "./experiment/experiment.directive";
export { DidaUITKBoxShadowDirective } from './box-shadow/box-shadow.directive';
export { DidaUITKValidateDirective, DidaUITKValidateOptions } from './validate/validate.directive';
export { DidaUITKAlertSizeDirective } from './alert-size/alert-size.directive';

@NgModule({
  declarations: [
    DidaUITKExperimentControlDirective,
    DidaUITKValidateDirective,
    DidaUITKBoxShadowDirective,
    DidaUITKAlertSizeDirective,
    DidaUITKGridLayoutDirective,
    DidaUITKPageMetaDirective,
    DidaUITKSortingGroupDirective,
    DidaUITKSortingFieldDirective,
    DidaUITKOssSrcDirective,
    DidaUITKV1HrefDirective,
  ],
  imports: [CommonModule],
  exports: [
    DidaUITKExperimentControlDirective,
    DidaUITKValidateDirective,
    DidaUITKBoxShadowDirective,
    DidaUITKAlertSizeDirective,
    DidaUITKGridLayoutDirective,
    DidaUITKPageMetaDirective,
    DidaUITKSortingGroupDirective,
    DidaUITKSortingFieldDirective,
    DidaUITKOssSrcDirective,
    DidaUITKV1HrefDirective
  ]
})
export class DidaUITKDirectiveModule {

}
