import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaInputDirective } from './dida-input.directive';
import { DidaFormGroupModule } from '../dida-form-group';
import { DidaFormErrorStateMatcher } from '../dida-form-error-options';

@NgModule({
  imports: [
    CommonModule,
    DidaFormGroupModule,
  ],
  declarations: [
    DidaInputDirective,
  ],
  exports: [
    DidaFormGroupModule,
    DidaInputDirective
  ],
  providers: [DidaFormErrorStateMatcher]
})
export class DidaInputModule { }
