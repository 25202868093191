import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { IDidaUserIdentityInfoModel } from '@dida-shopping/dida-services/auth';

@Injectable()
export class AuthorizationService {

  static AuthInfo: IDidaUserIdentityInfoModel;

  constructor(private httpClient: HttpClient) { }

  // /**
  //  * 获取当前用户的鉴权信息
  //  */
  // getAuthorizeInfo(): HttpObservable<UserIdentity> {
  //   return this.httpClient.get('/AuthorizationApi/GetAuthInfo');
  // }

  /**
   * NOT IMPLEMENTED
   */
  // hasPermission(): HttpObservable<UserAuthorizeInfo> {
  //   return this.httpClient.get('');
  // }

}
