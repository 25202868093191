import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../core/services/message/message.service';
import { UserService } from '../../../../core/services/user/user.service';
import { MessageSketchModel } from '../../../models/message/message-sketch.model';
import { FavoriteHotelDetail } from '../../../models/user/favorite-hotel.models';
import { PaginationWrapper } from '../../../models/common/pagination-wrapper.model';
import {
  FeedbackWorkOrderDataModel
} from '../../../models/work-order/work-order.models';
import {
  ProcessStepTypeEnum
} from '../../../models/shared-models';
import { WorkOrderService } from '../../../../core/services/work-order/work-order.service';
import { MessageModel } from '../../../models/message/message.model';
import { AnimationBuilder, animate, style } from '@angular/animations';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  ApplicationContextUpdateEvent,
  ApplicationEventHub,
  ComponentBaseWithContext, FavoriteHotelEventHub, FavoriteHotelFavorIconPosEvent,
  FeedbackEventHub, FeedbackOpenDialogEvent, ngxHttpCall, MessageCenterService, MessageCenterEventHub, MessageCenterUnreadMessageUpdateEvent
} from '@dida-shopping/ngx-dida-site-core';
import { filter } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { ResourceService, EntityCategoryEnum } from '../../../../core/services/resource/resource.service';
import { SidebarSettingModel, CustomServiceItemModel } from './sidebar.models';
import { Observable, Subscription } from 'rxjs';
import { BrowserHelper } from '@dida-shopping/dida-services/common';
import { ExperimentID } from '@dida-shopping/dida-services/experiment';
import { FeedbackSubTypeEnum, FeedbackTypeEnum } from '@dida-shopping/dida-services/cs-ticket';
import { clientService, IUserProfileModel } from '@dida-shopping/dida-services/membership';
import { IDidaApplicationUserContextModel } from '@dida-shopping/dida-services/auth';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import {IMessageSketchModel} from '@dida-shopping/dida-services/message'

type FavoriteHotelViewDetail = FavoriteHotelDetail & { processing?: boolean };

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends ComponentBaseWithContext implements OnInit {
  isLogin = false;
  unreadMessageList: IMessageSketchModel[] = [];
  favoriteHotelList: FavoriteHotelViewDetail[] = [];
  srInfo: IUserProfileModel;

  customServiceSetting: CustomServiceItemModel;

  transferFeatureControl = this.ExperimentControls.DidaTransferFeatureControl;

  isInWechatWebview = BrowserHelper.isInWeChatWebView;

  messageSubscription: Subscription;
  favoriteSubscription: Subscription;
  sidebarSettingSubscription: Subscription;

  @ViewChild('favorIcon', { static: false })
  favorIcon: ElementRef;
  @ViewChild('shadowFavorIcon', { static: false })
  shadowFavorIcon: ElementRef;

  @ViewChild('feedbackModal', { static: true })
  feedbackModal: ModalDirective;


  get IsHotelDetail() {
    return location.pathname.startsWith('/hotel/detail');
  }

  get showSurveyEntrance() {
    return (
      this.hasAnyExperiments([
        ExperimentID.ExposeSurveyEntrance
      ]) && this.isCN
    );
  }

  get isFeedbackContentRequired() {
    return [
      FeedbackTypeEnum.Complain,
      FeedbackTypeEnum.NewFearture,
      FeedbackTypeEnum.Others
    ].includes(this.feedbackTicket.Type);
  }

  FeedbackType = FeedbackTypeEnum;
  FeedbackSubType = FeedbackSubTypeEnum;
  feedbackTicket = new FeedbackWorkOrderDataModel();
  feedbackTicketError = null;
  ticketProcessStep = ProcessStepTypeEnum.Initial;
  ProcessStepType = ProcessStepTypeEnum;


  /**
   * 监听click事件
   * @param event
   */
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (event.target.classList.contains('open-slide-feedback')) {
      // 页面上任何的一个带有open-slide-feedback的class元素被点击后弹出feedbackModal
      this.feedbackModal.show();
      return;
    }
  }

  constructor(
    private messageService: MessageCenterService,
    private messageCenterEventHub: MessageCenterEventHub,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private route: ActivatedRoute,
    private animationBuilder: AnimationBuilder,
    // private actionService: ActionService,
    private resourceService: ResourceService,
    private feedbackEventHub: FeedbackEventHub,
    private appEventHub: ApplicationEventHub,
    private favoriteHotelEventHub: FavoriteHotelEventHub,
  ) {
    super();
  }

  submitFeedbackTicket(feedbackForm: NgForm) {
    if (feedbackForm.invalid) {
      return;
    }
    // console.log(this.feedbackTicket);
    this.ticketProcessStep = ProcessStepTypeEnum.Processing;
    this.feedbackTicket.Referer = location.href;
    this.workOrderService
      .createFeedbackWorkOrder(this.feedbackTicket)
      .subscribe(
        item => {
          this.ticketProcessStep = ProcessStepTypeEnum.Success;
          this.feedbackTicketError = null;
        },
        error => {
          this.ticketProcessStep = ProcessStepTypeEnum.Failed;
          this.feedbackTicketError = error.message;
        }
      );
  }

  // ROMAN: MERGE-NOTES: REMOVED IN MASTER
  // callCustomerService($event) {
  //   $event.preventDefault();
  //   if (!this.customServiceSetting) {
  //     return;
  //   }
  //   switch (this.customServiceSetting.Type) {
  //     case 'link':
  //       window.open(this.customServiceSetting.Value);
  //       break;
  //     case 'dialog':
  //       break;
  //   }
  // }

  resetFeedbackTicket() {
    setTimeout(() => {
      this.ticketProcessStep = ProcessStepTypeEnum.Initial;
      this.feedbackTicket = new FeedbackWorkOrderDataModel();
    }, 0);
  }

  removeFavoriteHotel(hotel: FavoriteHotelViewDetail) {
    if (hotel.processing) {
      return;
    }
    hotel.processing = true;
    this.userService.removeFavoriteHotel(hotel.HotelID).subscribe(
      () => {
      },
      err => {
        console.log(err);
      }
    ).add(() => {
      hotel.processing = false;
    });

  }

  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  getFavorIconPos() {
    return (this.favorIcon
      .nativeElement as HTMLElement).getBoundingClientRect();
  }

  markAllMessageAsReaded() {
    this.messageService.readAllMessages();
  }

  getTicketOperation(message: MessageModel) {
    if (message.ExtraSetting && message.ExtraSetting.TicketSettings) {
      return message.ExtraSetting.TicketSettings.OperationID;
    } else {
      return null;
    }
  }

  private onContextUpdate(userContext: IDidaApplicationUserContextModel) {

    let { isAuthenticated, profile } = userContext.userIdentityInfo;
    this.isLogin = isAuthenticated;
    this.feedbackTicket.UserEmail = profile ? profile.Email : '';

    if (!this.sidebarSettingSubscription) {
      this.sidebarSettingSubscription = this.loadSidebarSetting(userContext.lang);
    }

    if (!isAuthenticated) {
      // 退出后取消用户数据的监听
      this.messageService.stop()

      if (this.favoriteSubscription) {
        this.favoriteSubscription.unsubscribe();
        this.favoriteSubscription = null;
      }
      this.srInfo = null;
      return;
    }


    // 用户已登录
    // 1.监听收藏酒店
    // 2.监听未读消息
    // 3.加载销售代表信息

    if (!this.favoriteSubscription) {
      this.favoriteSubscription = this.userService.observeFavoriteHotelInfo().subscribe(
        (hotels: Array<FavoriteHotelDetail>) => {
          this.favoriteHotelList = hotels;
        },
        err => console.log(err)
      );
    }


    this.messageService.start()

    let clientInfo = this.clientInfo;
    let curSrName = this.srInfo && this.srInfo.UserName;
    let srName = clientInfo && clientInfo.SalesRepresentative;
    if (!srName) {
      this.srInfo = null;
    } else if (srName !== curSrName) {
      ngxHttpCall(
        clientService.getSalesRepresentativeInfo()
      ).subscribe((sr) => {
        this.srInfo = sr;
      });
    }
  }

  // private subscribeMessage() {
  //   return this.messageService.subscribe(
  //     (resp: PaginationWrapper<MessageSketchModel>) => {
  //       this.unreadMessageList = resp;
  //     }
  //   );
  // }

  ngOnInit() {

    // this.feedbackTicket.Type = FeedbackTypeEnum.NewFearture;

    // todo: remove debug logic
    // this.feedbackTicket.Type = 1;
    // this.ticketProcessStep = ProcessStepTypeEnum.Failed;
    // this.feedbackTicketError = 'Network ERROR';
    // setInterval(() => {
    //     this.ticketProcessStep = (this.ticketProcessStep + 1) % 3;
    // }, 2000);

    // bugfix: 重新登录后部分组件不展示
    if(this.appContext) {
      this.onContextUpdate(this.appContext)
    }

    this.subscriptions['appEventHub'] = (this.appEventHub.events.pipe(
      filter(event => event instanceof ApplicationContextUpdateEvent)
    ) as Observable<ApplicationContextUpdateEvent>).subscribe((event) => {
      this.onContextUpdate(event.ctx);
    });

    this.subscriptions['feedbackEventHub'] = (this.feedbackEventHub.subscribe({
      next: (event) => {
        if (event instanceof FeedbackOpenDialogEvent) {
          this.feedbackTicket.Type = event.feedbackType;
          this.feedbackTicket.SubType = event.feedbackSubType;
          this.feedbackModal.show();
        }
      }
    }));

    this.subscriptions['favoriteHotelEventHub'] = (this.favoriteHotelEventHub.events.pipe(
      filter(event => event instanceof FavoriteHotelFavorIconPosEvent)
    ) as Observable<FavoriteHotelFavorIconPosEvent>).subscribe((event) => {
      let sourceRect = event.iconRect;
      let targetRect = (this.favorIcon
        .nativeElement as HTMLElement).getBoundingClientRect();

      this.animationBuilder.build([
        style({
          transform: `translate3d(${sourceRect.left - targetRect.left}px, ${sourceRect.top - targetRect.top}px, 0)`,
          display: 'inline-block',
          opacity: 1
        }),
        animate('1s ease-in', style({
          transform: 'translate3d(0, 0, 0)',
          display: 'inline-block',
          opacity: 1
        })),
        animate(500, style({
          opacity: 0,
          display: 'none'
        }))
      ])
        .create(this.shadowFavorIcon.nativeElement)
        .play();
    });

    this.subscriptions['messageEventHub'] = this.messageCenterEventHub.events.pipe(
      filter(event => event instanceof MessageCenterUnreadMessageUpdateEvent)
    ).subscribe(() => {

      this.unreadMessageList = this.messageService.getUnreadMessages();
    })

  }

  loadSidebarSetting(lang: LanguageType) {

    // 获取侧边栏设置
    let isProduction = location.hostname === 'www.didatravel.com';
    let entityKey = isProduction ? 'SidebarSetting' : 'SidebarSettingTest';

    return this.resourceService.getResourceEntity<SidebarSettingModel>(
      EntityCategoryEnum.CommonData, entityKey)
      .subscribe(({ EntityValue: setting }) => {
        if (lang !== LanguageType.zh_CN) {
          setting.CustomService.Before.concat(setting.CustomService.After).filter(Boolean).map(item => {
            Object.assign(item, item.en_US);
          });
        }
        this.customServiceSetting = setting.CustomService.Before[0];
        if (this.customServiceSetting && this.customServiceSetting.Type === 'dialog') {
          /**小程序webview不允许外源脚本 */
          if (this.isInWechatWebview) {
            return;
          }
          this.initCustomerService();
        }
      });
  }

  initCustomerService() {
    let script = document.getElementById(this.customServiceSetting.Id) as HTMLScriptElement
    if(!script) {
      script = document.createElement('script');
      script.id = this.customServiceSetting.Id;
      script.setAttribute('charset', 'utf-8');
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
    script.src = this.customServiceSetting.Value;
  }


  callCustomerService($event) {
    $event.preventDefault();
    if (!this.customServiceSetting) {
      return;
    }
    switch (this.customServiceSetting.Type) {
      case 'link':
        window.open(this.customServiceSetting.Value);
        break;
      case 'dialog':
        break;
    }
  }
}
