import { IClientSideLog, LogLevelType } from './log.models';
import { DateTimeHelper } from '../common';
import { IDidaApplicationUserContextModel } from '../auth/models';

export class ClientSideLogHelper {
  static buildClientSiteLog(message: string, level: LogLevelType, version: string, appCtx: IDidaApplicationUserContextModel = null): IClientSideLog {
    let context = {};
    if (appCtx && appCtx.userIdentityInfo && appCtx.userIdentityInfo.isAuthenticated) {
      const identity = appCtx.userIdentityInfo;
      context = {
        userInfo: {
          clientId: identity.clientInfo.ClientID,
          userId: identity.profile.UserID,
          userName: identity.profile.UserName
        },
        userAgent: navigator.userAgent
      };
    }
    return {
      message,
      level: level,
      url: location.href,
      time: DateTimeHelper.format(new Date(), DateTimeHelper.DEFAULT_DATE_TIME_FORMAT),
      stack: '',
      version: version,
      context: context,
    };
  }
}
