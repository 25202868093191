import { HotelRateStateModel } from './models/hotel-rate-state.model';


export class RateStateHelper {

  static generateStateModel(hotelId: number): HotelRateStateModel {
    const model = new HotelRateStateModel(
      hotelId,
      new Date().getTime().toString(),
    );
    return model;
  }

  static getItemKey(item: HotelRateStateModel) {
    return `${item.hotelId}_${item.pageKey}`;
  }

  static getRatePlanKey(supplierId: number, ratePlanId: string, responseId: string, ratePlanMask: string) {
    if (supplierId === 0) {
      return `${responseId}_${ratePlanMask}`;
    } else {
      return `${supplierId}:${ratePlanId}`;
    }
  }


}
