import { Injectable } from '@angular/core';
import { HttpClient } from '../../../http/http-client';
import { TransferPriceSearchOption } from '../../../../shared/models/transfer/transfer-price-search-option';
import { TransferPriceSearchResultModel } from '../../../../shared/models/transfer/transfer-price-search-result.model';
import { TransferRatePlanPriceModel } from '../../../../shared/models/transfer/transfer-rate.models';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';

@Injectable()
export class TransfersRateService {
  constructor(private httpClient: HttpClient) {}

  searchAvailability(
    options: TransferPriceSearchOption
  ) {
    return this.httpClient.post<TransferPriceSearchResultModel>(
      '/TransferPriceApi/Search',
      {
        options: options
      },
      null,
      resp => {
        if (resp.RatePlanPriceList) {
          resp.RatePlanPriceList.forEach(item =>
            this.ratePlanPriceProcessor(item)
          );
        }
      },
      (payload: { options: TransferPriceSearchOption }) => {
        if (payload && payload.options) {
          let opts = payload.options;
          if (opts.TransportArrivalTime instanceof Date) {
            opts.TransportArrivalTime = DateTimeHelper.format(
              opts.TransportArrivalTime,
              DateTimeHelper.DEFAULT_DATE_TIME_FORMAT
            ) as any;
          }
          if (opts.TransportDepartureTime instanceof Date) {
            opts.TransportDepartureTime = DateTimeHelper.format(
              opts.TransportDepartureTime,
              DateTimeHelper.DEFAULT_DATE_TIME_FORMAT
            ) as any;
          }
          if (opts.ExpectedUseTime instanceof Date) {
            opts.ExpectedUseTime = DateTimeHelper.format(
              opts.ExpectedUseTime,
              DateTimeHelper.DEFAULT_DATE_TIME_FORMAT
            ) as any;
          }
        }
      }
    );
  }

  getAvailability(
    transferId: string,
    currency: string,
    responseToken: string
  ) {
    return this.httpClient.get<TransferRatePlanPriceModel>(
      '/TransferPriceApi/GetAvailability',
      {
        transferId,
        currency,
        responseToken
      },
      null,
      result => {
        this.ratePlanPriceProcessor(result);
      }
    );
  }

  ratePlanPriceProcessor(ratePlanPrice: TransferRatePlanPriceModel) {
    if (ratePlanPrice) {
      ratePlanPrice.PriceExpiryTime = DateTimeHelper.parse(
        ratePlanPrice.PriceExpiryTime,
        true
      );
      if (ratePlanPrice.CancellationPolicyList) {
        ratePlanPrice.CancellationPolicyList.forEach(cxl => {
          cxl.From = DateTimeHelper.parse(cxl.From, true);
        });

        if (ratePlanPrice.CancellationPolicyList.length > 0) {
          let firstCancelDate = ratePlanPrice.CancellationPolicyList[0].From;
          if (firstCancelDate > new Date()) {
            ratePlanPrice.__refundable__ = true;
          }
        }
        ratePlanPrice.__refundable__ = !!ratePlanPrice.__refundable__;
      }
    }
  }
}
