// DO NOT EXPORT TO MODULE
import { Subscription } from 'rxjs';

export abstract class AbstractRunnableService {
  private _running: boolean;
  private _started: boolean;
  private _timerHandle: number;

  protected constructor(
    public readonly RELOAD_INTERVAL_SEC: number
  ) {
    const className = this.constructor.name;
    console.log(`[RunnableService:${className}] created, with interval: ${RELOAD_INTERVAL_SEC} (secs)`);
  }

  get started(): boolean {
    return this._started;
  }

  get running(): boolean {
    return this._running;
  }

  start() {
    if (this._started) {
      return;
    }
    this._started = true;
    this._running = true;
    this.execute().add(() => {
      this._running = false;
      if (this._started) {
        this._timerHandle = window.setInterval(() => {
          if (!this._running) {
            this._running = true;
            this.execute().add(() => {
              this._running = false;
            });
          }
        }, this.RELOAD_INTERVAL_SEC * 1000);
      }
    });
  }

  stop() {
    if (!this._started) {
      return;
    }
    if (this._timerHandle) {
      window.clearInterval(this._timerHandle);
      this._timerHandle = null;
      this._started = false;
    }
  }

  protected abstract execute(): Subscription;
}
