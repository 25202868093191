import { SERVER_CONFIG } from '../app-server-config';

export class I18nHelper {
  // private static C_locale: 'en-US' | 'zh-CN' | 'ja-JP';

  static getApplicationLocale() {
    return {en: 'en-US',zh:'zh-CN'}[document.documentElement.lang] || document.documentElement.lang
    // if (!I18nHelper.C_locale) {
    //   let cookieLocale: 'en-US' | 'zh-CN'| 'ja-JP' = null;
    //   if (document.cookie) {
    //     const langCookieNeedle = `${SERVER_CONFIG.localeCookieName || 'dida-locale'}=`;
    //     const langCookieItems = document.cookie.split('; ').filter(x => x.startsWith(langCookieNeedle));
    //     if (langCookieItems.length > 0) {
    //       const parts = langCookieItems[0].split('=');
    //       cookieLocale = <'en-US' | 'zh-CN'| 'ja-JP'>parts[parts.length - 1];
    //     }
    //   }
    //   // const cookieLang = (
    //   //   document.cookie &&
    //   //   document.cookie.split('; ').includes(`${SERVER_CONFIG.localeCookieName || 'dida-locale'}=en-US`)
    //   // ) ? 'en-US' : 'zh-CN';
    //   // const defaultFallback = cookieLocale || 'zh-CN';
    //   const locale = document['locale'] || cookieLocale || 'zh-CN';
    //   I18nHelper.C_locale = document['locale'] = locale;
    // }
    // return I18nHelper.C_locale;
  }
}
