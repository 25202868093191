import { Injectable } from '@angular/core';
import { HttpClient } from '../../../http/http-client';
import { TransferBookingDetailModel } from '../../../../shared/models/transfer/order/transfer-booking-detail.models';
import { TransferBookingSearchOption } from '../../../../shared/models/transfer/order/transfer-booking-search-option.model';
import { PaginationWrapper } from '../../../../shared/models/common/pagination-wrapper.model';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import {
  TransferBookingCancelChargeInfoModel,
  TransferBookingCancelResultModel
} from '../../../../shared/models/transfer/order/transfer-booking-cancel.models';

@Injectable()
export class TransfersBookingService {
  constructor(private httpClient: HttpClient) {
  }

  static bookingResultProcessor(detail: TransferBookingDetailModel) {
    if (detail.CancellationPolicyList) {
      detail.CancellationPolicyList.forEach(item => {
        item.From = DateTimeHelper.parse(item.From);
      });
    } else {
      detail.CancellationPolicyList = [];
    }

    if (detail.OrderTime) {
      detail.OrderTime = DateTimeHelper.parse(detail.OrderTime, true);
    }

    if (detail.PaymentRemainingSeconds) {
      detail._paymentEndTime = DateTimeHelper.addSeconds(
        new Date(),
        detail.PaymentRemainingSeconds
      );
    }

    if (detail.TransferInfo) {
      detail.TransferInfo.TransportArrivalTime = DateTimeHelper.parse(detail.TransferInfo.TransportArrivalTime, true);
      detail.TransferInfo.TransportDepartureTime = DateTimeHelper.parse(detail.TransferInfo.TransportDepartureTime, true);
      detail.TransferInfo.ExpectedUseTime = DateTimeHelper.parse(detail.TransferInfo.ExpectedUseTime, true);
      if (detail.TransferInfo.CancellationPolicyList) {
        detail.TransferInfo.CancellationPolicyList.forEach(item => {
          item.From = DateTimeHelper.parse(item.From);
        });
      }
      if (detail.TransferInfo.CalculatedPickupDateTime) {
        detail.TransferInfo.CalculatedPickupDateTime = DateTimeHelper.parse(detail.TransferInfo.CalculatedPickupDateTime, true);
      }
      if (detail.TransferInfo.PickupInfo) {
        detail.TransferInfo.PickupInfo.PickupDate = DateTimeHelper.parse(detail.TransferInfo.PickupInfo.PickupDate, true);
      }
    }

    console.log(detail);
  }

  getBookingList(option: TransferBookingSearchOption) {
    return this.httpClient.get<PaginationWrapper<TransferBookingDetailModel>>(
      '/TransferBookingApi/GetTransferBookingList',
      { searchOption: option },
      null,
      (result) => {
        // result processor
        if (result.Data && result.Data.length > 0) {
          result.Data.forEach(i => TransfersBookingService.bookingResultProcessor(i));
        }
      }
    );
  }

  getBookingDetail(transferBookingId: string) {
    return this.httpClient.get<TransferBookingDetailModel>(
      '/TransferBookingApi/GetTransferBookingDetail', { transferBookingId }, null,
      (result) => {// result processor
        TransfersBookingService.bookingResultProcessor(result);
      });
  }

  getBookingDetailByPreOrderId(preOrderId: string) {
    return this.httpClient.get<TransferBookingDetailModel>(
      '/TransferBookingApi/GetTransferBookingDetailByPreOrderID', { preOrderId }, null,
      (result) => {// result processor
        TransfersBookingService.bookingResultProcessor(result);
      });
  }


  cancelBooking(transferBookingId: string) {
    return this.httpClient.post<TransferBookingCancelChargeInfoModel>(
      '/TransferBookingProcessApi/Cancel', { transferBookingId }, null,
      (chargeInfo) => {
        if (chargeInfo.ExpireTime) {
          chargeInfo.ExpireTime = DateTimeHelper.parse(chargeInfo.ExpireTime, true);
        }
      }
    );
  }

  confirmCancelBooking(transferBookingId: string, cancelConfirmId: string, description: string)  {
    return this.httpClient.post<TransferBookingCancelResultModel>(
      '/TransferBookingProcessApi/CancelConfirm', { transferBookingId, cancelConfirmId, description });
  }

  updateBookingClientReference(transferBookingId: string, clientReference: string) {
    return this.httpClient.post<boolean>(
      `/TransferBookingProcessApi/UpdateTransferBookingClientReference`, { transferBookingId, clientReference }, null
    );
  }

  checkClientReference(clientReference: string) {
    return this.httpClient.get<{
      IsValid: boolean,
      ClientReference: string,
      BookingID?: string,
    }>(
      `/TransferBookingApi/CheckClientReference`, { clientReference }, null
    );
  }
}
