<div class="dida-suggestion-typeahead">
  <div class="search">
    <ng-container *ngIf="!ndConfig.customizeLabel">
        <span class="search__header">
          <ng-container *ngIf="searchMode === 'all'">
              <label [for]="searchKeywordId" class="search__label" i18n="@@Text_Label_Destination">目的地</label>
          </ng-container>
          <ng-container *ngIf="ndConfig.enableRegionSearch" [ngSwitch]="searchMode">
            <ng-container *ngSwitchCase="'all'">
                <label class="search__checkbox" *ngIf="regionOptions.length > 0"
                       nz-checkbox [(ngModel)]="switchCheckboxSelected"
                       (ngModelChange)="regionOptionSelect(regionOptions[0], 200)">
                  <span class="search__checkbox-text" i18n="@@Text_Label_SearchByKeyword">搜关键字</span>
                </label>
            </ng-container>
            <ng-container *ngSwitchCase="'region'">
              <span class="search__sub-region">
                <ng-container i18n="@@Text_SuggestionTypeahead_SearchUnderRegion">
                  只搜 <span class="search__em" [title]="currentSelectedRegion.name">{{ currentSelectedRegion.name }}</span> 内
                </ng-container>
                <span class="search__close-button" (click)="regionOptionSelect(null)">
                  <i nz-icon nzType="close" nzTheme="outline"></i>
                </span>
              </span>
            </ng-container>
          </ng-container>
        </span>
    </ng-container>
    <div class="search-input" [class.search-input_focus]="isKeywordInputOnFcous" [class.search-input_with-tag]="showSearchKeywordTag">
      <input class="search-input__keyword" #keywordControl="ngModel" autocomplete="off" type="text" name="keyword" [id]="searchKeywordId"
        [(ngModel)]="keyword"
        [ndValidate]="{minlength: currentSuggestionTypeaheadOptions.minKeywordLength, maxlength: 200}"
        (focus)="setKeywordInputStatus('focus')" (blur)="setKeywordInputStatus('blur')"
        (keyup)="searchInputKeyUp($event)"
        (paste)="searchInputKeyPaste($event)"
        (change)="keywordChange()">
      <div class="search-input__keyword-tag" [ngStyle]="{ left: keyword_tag_position_left + 'px' }" *ngIf="showSearchKeywordTag" (click)="setKeywordInputStatus('focus')">周边地区</div>
      <div class="search-input__placeholder" [hidden]="keyword">
        <ng-container *ngIf="ndConfig.customizePlaceholder">
          <ng-content select="nd-suggestion-input-placeholder"></ng-content>
        </ng-container>
        <ng-container *ngIf="!ndConfig.customizePlaceholder"
                      i18n="@@Text_Placeholder_SuggestionTypeahead_CitySiteHotel">城市／地标／商圈／酒店
        </ng-container>
      </div>
      <ng-container [ngSwitch]="searchingSuggestion">

        <div class="search-input__clean-button" *ngSwitchCase="false" [hidden]="!keyword" (click)="cleanKeywordInputText()">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </div>
        <div class="search-input__clean-button" *ngSwitchCase="true">
          <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="suggestion-dropdown">
    <div class="suggestion-dropdown__main" [@collapseMotion]="isShowSuggestionDropdown ? 'expanded' : 'collapsed'">
      <div class="suggestion-box" [hidden]="!isShowSuggestionOptions">
        <div class="suggestion-group" *ngFor="let item of suggestionOptions">
          <div class="suggestion-category" *ngIf="currentSuggestionTypeaheadOptions.showCategory">
            <ng-container [ngSwitch]="item.type">
              <ng-container *ngSwitchCase="'destination'">
                <i nz-icon nzType="nd:business-district" class="suggestion-category__icon"></i>
                <span i18n="@@Text_SuggestionCategory_Region">区域/商圈</span>
              </ng-container>
              <ng-container *ngSwitchCase="'viewpoint'">
                <i nz-icon nzType="nd:sights" class="suggestion-category__icon"></i>
                <span i18n="@@Text_SuggestionCategory_ViewPoint">热门景点</span>
              </ng-container>
              <ng-container *ngSwitchCase="'hotel'">
                <i nz-icon nzType="nd:hotel" class="suggestion-category__icon"></i>
                <span i18n="@@Text_SuggestionCategory_Hotel">酒店</span>
              </ng-container>
            </ng-container>
          </div>
          <div class="suggestion-list" [class.suggestion-list_full]="!currentSuggestionTypeaheadOptions.showCategory">
            <div class="suggestion-item" [class.suggestion-item__active]="isActiveSuggestionOption(option)"
                 *ngFor="let option of item.options"
                 (mouseenter)="suggestionOptionHover(option, 'mouseEnter')"
                 (mouseleave)="suggestionOptionHover(option, 'mouseLeave')"
                 (click)="suggestionOptionSelect(option)">
              <div class="suggestion-item__main">
                <div class="suggestion-item__title">
                  <span [lang]="ndConfig.languageType" [title]="option.name"
                        [innerHTML]="option.name | ndHighlight: { keyword: keyword, isDisabled: isActiveSuggestionOption(option) }"></span>
                  <span [lang]="ndConfig.languageType" *ngIf="option.name !== option.name_en" [title]="option.name_en"
                        [innerHTML]="option.name_en | ndHighlight: { keyword: keyword, isDisabled: isActiveSuggestionOption(option) }"></span>
                </div>
                <div class="suggestion-item__subtitle" *ngIf="option.nameOld" >
                  <nd-tag ndSize="small" ndColor="#FF5654" i18n="@@Label_Once_SigleWord">曾</nd-tag>
                  <span
                    [innerHTML]="option.nameOld | ndHighlight: { keyword: keyword, isDisabled: isActiveSuggestionOption(option) }"></span>
                </div>
              </div>
              <div class="suggestion-item__extra" *ngIf="option.category === 0" [title]="getExtraTitle(option)">
                <ng-container
                  *ngIf="currentSuggestionTypeaheadOptions.extranInfoMode === 'default'">{{ option.address.destinationName }}
                  , {{ option.address.countryName }}</ng-container>
                <ng-container *ngIf="currentSuggestionTypeaheadOptions.extranInfoMode === 'category'"
                              i18n="@@Text_Info_Hotel">酒店
                </ng-container>
              </div>
              <div class="suggestion-item__extra" *ngIf="option.category === 13" [title]="getExtraTitle(option)">
                <ng-container i18n="@@Text_Info_Airport">机场</ng-container>
              </div>
              <div class="suggestion-item__extra" *ngIf="option.category !== 0 && option.category !== 13">
                <ng-container *ngIf="currentSuggestionTypeaheadOptions.extranInfoMode === 'default'">
                  <ng-container i18n="@@Text_Info_SuggestionTypeahead_Nearby">附近约
                    <span
                      [class.dida-text-highlight]="!isActiveSuggestionOption(option)">{{ option.source.Metadata.HotelCount }}</span>
                    间酒店
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="currentSuggestionTypeaheadOptions.extranInfoMode === 'category'"
                              i18n="@@Text_Info_Destination">目的地
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="suggestion-group" *ngIf="isShowNoResult">
          <div class="suggestion-list suggestion-list_full">
            <div class="suggestion-item" i18n="@@Text_Info_Suggestion_No_Result">没有找到任何数据，请输入其他的关键字</div>
          </div>
        </div>
      </div>
      <div class="suggestion-box" *ngIf="keywordControl.errors">
        <div class="suggestion-group">
          <div class="suggestion-list suggestion-list_full">
              <div class="suggestion-item" i18n="@@Text_Info_Validate_String_Min_Length">请输入至少 {{ currentSuggestionTypeaheadOptions.minKeywordLength }} 个字符</div>
          </div>

        </div>
      </div>
      <ng-container *ngIf="isShowDefaultSuggestion" [ngSwitch]="ndConfig.airportMode">
        <!-- 热门城市 -->
        <nd-tabs-cascader *ngSwitchDefault [ndConfig]="tabsCascaderConfig" (ndChange)="cityChange($event)" [(ngModel)]='tabsCascaderSelectedOptions'>
          <ng-container *ngIf="isCN">
            <div class="suggestion-outreach" nd-tabs-cascader-extra *ndExperiment="ExperimentControls.HotelPackageControl">
              <i class="suggestion-outreach__icon" nz-icon nzType="nd:loudspeaker"></i>
              <div class="suggestion-outreach__content">
                <a routerLink="/package" target="_blank">酒店套餐上线啦！ 度假套餐权益升级无限，点击查看</a>
              </div>
            </div>
          </ng-container>
        </nd-tabs-cascader>
        <!-- 热门机场 -->
        <nd-tabs-cascader *ngSwitchCase="true" [ndConfig]="tabsCascaderConfig" (ndChange)="airportChange($event)" [(ngModel)]='tabsCascaderSelectedOptions'></nd-tabs-cascader>

      </ng-container>
    </div>
  </div>
</div>
