
// from DidaShoppingMessageCategoryTypeEnum
export enum MessageCategoryTypeEnum {
    System = 10000,
    Client = 20000,
    User = 30000,
    Booking = 40000,
    BookingFreeCancellationNotice = 40001,
    BookingHotelConfirmationNotice = 40002,
    BookingLowerPriceNotice = 40003,
    PostPayBookingAutoCancelNotice = 40004,
    PointsExchange = 40005,
    InvoiceStatusChange = 40006,
    WorkOrderStatusChange = 40007,
    BillStatusChange = 40008,
    CouponNearExpiration = 40009,
    Promotions = 50000,
    Others = 99999,
}
