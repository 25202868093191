export enum FeedbackTypeEnum {
  Encourage = 1,
  Complain = 2,
  Resource = 3,
  NewFearture = 4,
  Others = 5,
}

export enum FeedbackSubTypeEnum {
  WebsiteSpeed = 1,
  ServiceRelative,
  Functionality,
  MapRelative,
  BizResource,
  Others,
  HotelNameError,
  RateStarError,
  AddressError,
  TelephoneError,
  WebsiteError,
}
