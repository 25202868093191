<ng-container [ngSwitch]="message.Category" *ngIf="message.UseTemplate">
    <ng-container *ngSwitchCase="MessageCateType.WorkOrderStatusChange" [ngSwitch]="params['LogType']">
        <span *ngSwitchCase="WorkOrderLogTypeEnum.CustomerCreate" i18n="@@Text_Title_TicketMsg_CustomerCreate">工单[BookingID：{{params['ReferenceID']}}，修改类型：<app-booking-ticket-type [type]="params['ModifyType']"></app-booking-ticket-type>]创建成功</span>
        <span *ngSwitchCase="WorkOrderLogTypeEnum.Finish" i18n="@@Text_Title_TicketMsg_Finish">工单[BookingID：{{params['ReferenceID']}}，修改类型：<app-booking-ticket-type [type]="params['ModifyType']"></app-booking-ticket-type>]已处理完成</span>
        <span *ngSwitchCase="WorkOrderLogTypeEnum.Lock" i18n="@@Text_Title_TicketMsg_Lock">工单[BookingID：{{params['ReferenceID']}}，修改类型：<app-booking-ticket-type [type]="params['ModifyType']"></app-booking-ticket-type>]开始跟进处理</span>
        <span *ngSwitchCase="WorkOrderLogTypeEnum.Urge" i18n="@@Text_Title_TicketMsg_Urge">工单[BookingID：{{params['ReferenceID']}}，修改类型：<app-booking-ticket-type [type]="params['ModifyType']"></app-booking-ticket-type>]进入加急状态</span>
        <span *ngSwitchCase="WorkOrderLogTypeEnum.Update" i18n="@@Text_Title_TicketMsg_Update">工单[BookingID：{{params['ReferenceID']}}，修改类型：<app-booking-ticket-type [type]="params['ModifyType']"></app-booking-ticket-type>]内容更新</span>
    </ng-container>
    <ng-container *ngSwitchCase="MessageCateType.BookingFreeCancellationNotice">

    </ng-container>
    <ng-container *ngSwitchCase="MessageCateType.BookingLowerPriceNotice">

    </ng-container>
    <ng-container *ngSwitchCase="MessageCateType.BookingHotelConfirmationNotice">

    </ng-container>
    <ng-container *ngSwitchCase="MessageCateType.InvoiceStatusChange">

    </ng-container>
    <ng-container *ngSwitchCase="MessageCateType.BillStatusChange">

    </ng-container>
    <ng-container *ngSwitchCase="MessageCateType.PointsExchange">

    </ng-container>
</ng-container>
<span *ngIf="!message.UseTemplate">
    <ng-container [ngSwitch]="message.Category">
        <ng-container *ngSwitchCase="MessageCateType.WorkOrderStatusChange">
            <ng-container *ngIf="message.ExtraSetting && message.ExtraSetting.TicketSettings;else defaultTitle">
                订单号：{{ message.ExtraSetting.TicketSettings.DidaBookingID }}, 工单({{ message.ExtraSetting.TicketSettings.Issue.Name }})<ng-container [ngSwitch]="message.ExtraSetting.TicketSettings.OperationID">
                    <ng-container *ngSwitchCase="TicketOperations.Add" i18n="@@Ticket_Operation_Add">创建</ng-container>
                    <ng-container *ngSwitchCase="TicketOperations.Complete" i18n="@@Ticket_Operation_Complete">已完成</ng-container>
                    <ng-container i18n="@@Ticket_Operation_Update" *ngSwitchDefault >有更新</ng-container>
                </ng-container>
            </ng-container>
            <ng-template #defaultTitle>
                <ng-container *ngIf="message['Title'] || message.TitleDict[lang]">
                    {{ message['Title'] || message.TitleDict[lang]}}
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ng-container *ngIf="message['Title'] || message.TitleDict[lang]">
                {{ message['Title'] || message.TitleDict[lang] }}
            </ng-container>
        </ng-container>
    </ng-container>
</span>
