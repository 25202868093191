
const _PUBLIC_HOST = 'dsimage.didatravel.com';
const _FALLBACK_IMG = '/v2-app/images/image-fallback.svg';


export class UrlHelper {
    static toAvailableBackgroundUrl(url: string) {
        return encodeURI(url);
    }

    static addParams(url: string, params: {[key: string]:  any}) {
      const keys = Object.keys(params);
      if (keys.length < 1) {
        return url;
      }

      const queryStr = keys.map(key => `${key}=${params[key]}`).join('&');
      const seperater = url.indexOf('?') > 0 ? '&' : '?';

      return url + seperater + queryStr;
    }


  /**
     * Normalizes static url
     * @param url
     * @param [config]
     * @returns  stirng
     */
  static normalizeStaticUrl(url: string, config: boolean | {
    /**
     * 是否返回默认图片
     */
    orDefault?: boolean,
    /**
     * 指定图片宽度
     */
    width?: number,
    /**
     * 给文件名添加后缀
     */
    postfix?: string
  } = false): string {
      if (typeof config === 'boolean') {
          config = {
              orDefault: config
          };
      }

      if (!url) {
          if (config.orDefault) {
              url = _FALLBACK_IMG;
          } else {
              return url;
          }
      }

      url = encodeURI(url);
      let  [fileUrl = '', queryStr = ''] = url.split('?');


      if (config.postfix) {
          fileUrl = fileUrl.replace(/(\.[^.]+$)/, `_${config.postfix}$1`);
      }

      if (!fileUrl.endsWith('.svg')) {

          if (config.width && !queryStr.includes('x-oss-process')) {
              if (queryStr) {
                  queryStr += '&';
              }
              let computedWidth = Math.ceil(config.width * devicePixelRatio);
              computedWidth = Math.min(computedWidth, 3840/*1920*2*/);
              queryStr += 'x-oss-process=image/resize,w_' + computedWidth;
          }
      }

      url = fileUrl;
      if (queryStr) {
          url += '?' + queryStr;
      }

      if (url.includes('//')) {
          return url;
      }

      return `https://${_PUBLIC_HOST}${url}`;
  }
}
