import { Injectable, ErrorHandler } from '@angular/core';
import { aliyunLoggerService, LogLevelType, ClientSideLogHelper } from '@dida-shopping/dida-services/logging';
import { APP_VERSION } from '../app-verison';
import { ApplicationService } from '../application';


@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private logs: Map<string, any> = new Map<string, any>();

  constructor(
    private appService: ApplicationService
  ) { }

  handleError(error: any): void {
    try {
      const keys: string[] = [location.href, error.message];
      const log = ClientSideLogHelper.buildClientSiteLog(error.message, LogLevelType.error, APP_VERSION.buildNumber, this.appService.context);
      log.stack = error.stack;
      // eslint-disable-next-line no-prototype-builtins
      if (error.hasOwnProperty('ngDebugContext')) {
        const debugContext = error.ngDebugContext;
        const component = {
          source: debugContext.component.constructor.name,
          view: debugContext.view.component.constructor.name
        };
        keys.push(component.source);
        log.context.component = component;
      }
      const key = keys.join(':');
      if (!this.logs.has(key)) {
        this.logs.set(key, log);
        aliyunLoggerService.sendLog(log);
      }
      console.error(error);
    } catch (err) {
      // TODO
    }
  }
}
