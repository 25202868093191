
import { IEventModelBase } from './event-base.model';
import { MapMessageTypeEnum, ServiceMessageTypeEnum } from '../enums';

export abstract class ServiceEventBase implements IEventModelBase<ServiceMessageTypeEnum> {

  protected constructor(
    public readonly type: ServiceMessageTypeEnum,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }
}

export class ServiceRequestFinishedEvent extends ServiceEventBase {
  constructor(
    public readonly url: string,
    public readonly error: {
      status: number,
      message: string,
    } | null,
    source: string,
    time: Date
  ) {
    super(ServiceMessageTypeEnum.RequestFinish, source, time);
  }
}
