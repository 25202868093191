import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import { HotelBookingPreOrderOption } from '../../../shared/models/order/hotel-booking-pre-order-option.model';
import { HotelBookingPreOrderDetail } from '../../../shared/models/order/hotel-booking-pre-order-detail.model';
import { BookingConfirmationContext } from '../../../shared/models/order/booking-confirmation-context.model';
import { DateTimeHelper, ObjectHelper } from '@dida-shopping/dida-services/common';
import { RatePlanViewModel } from '../../../shared/models/rateplan/rateplan.models';
import { PayChannelTypeEnum } from '../../../shared/enums/pay-channel-type';
import { BookingDetailModel } from '../../../shared/models/booking/hotel-booking-detail.model';
import { DidaValueAddInfoModel } from '../../../shared/models/value-add/dida-value-add.models';
import { QRCodeContentResult } from '../../../shared/models/order/qr-code-content-result.model';
import { CouponInfoForBookingConfirmModel } from '../../../shared/models/campaigns/coupons/coupon-info-for-booking-confirm.model';

/**
 * 下单数据服务
 *
 * @export
 * @class OrderService
 */
@Injectable()
export class OrderService {
  constructor(private logger: LoggerService, private httpClient: HttpClient) {}

  /**
   * 预下单接口
   *
   * ## 提示
   * 1. 该接口不会生成BookingID
   * 2. 每调用一次RatePlan询价接口
   * 3. 带有PreOrderID的请求，会更新PreOrderID
   * 4. PreOrderID有效期为30分钟（订单信息确认页面有影响）
   *
   * ## 使用场景
   * 1. 在酒店详情页点击“预订”按钮时
   * 2. 在更换入住条件时
   * 3. 在前往支付页面前
   *
   * @param {HotelBookingPreOrderOption} option 预下单参数
   * @returns {HttpObservable<HotelBookingPreOrderDetail>}
   * @memberof OrderService
   */
  preOrder(option: HotelBookingPreOrderOption) {
    return this.httpClient.post<HotelBookingPreOrderDetail>(
      '/HotelBookingPreOrderApi/PreOrder',
      {
        payload: option,
      },
      null,
      this.preOrderDetailProcessor,
      (payload) => {
        payload.payload = ObjectHelper.deepCopy(payload.payload);
        let mdl: HotelBookingPreOrderOption = payload.payload;
        mdl.BookingOption.CheckIn = DateTimeHelper.format(
          mdl.BookingOption.CheckIn,
          DateTimeHelper.DEFAULT_DATE_FORMAT
        );
        mdl.BookingOption.CheckOut = DateTimeHelper.format(
          mdl.BookingOption.CheckOut,
          DateTimeHelper.DEFAULT_DATE_FORMAT
        );
      }
    );
  }

  checkHistoryRoomGuestDuplicate(option: HotelBookingPreOrderDetail) {
    return this.httpClient.post<boolean>('/HotelBookingPreOrderApi/CheckHistoryRoomGuestDuplicate', {
      bookingOptions: option,
    });
  }

  /**
   * 取RatePlanView信息
   *
   *
   */
  getRatePlanView(didaHotelID: number, supplierID: number, supplierRatePlanID: string) {
    return this.httpClient.get<RatePlanViewModel>('/HotelBookingApi/GetSupplierRatePlanView', {
      didaHotelID,
      supplierID,
      supplierRatePlanID,
    });
  }
  /**
   * 获取预下订单信息
   *
   * @param {string} preOrderId 预下单ID
   * @returns {HttpObservable<HotelBookingPreOrderDetail>}
   * @memberof OrderService
   */
  getPreOrderBooking(preOrderId: string) {
    return this.httpClient.get<HotelBookingPreOrderDetail>(
      '/HotelBookingPreOrderApi/GetPreOrderInfo',
      {
        preOrderID: preOrderId,
      },
      null,
      this.preOrderDetailProcessor
    );
  }

  /**
   * 通过订单ID获取对应的PreOrderID
   *
   * @param {string} bookingId
   * @returns {HttpObservable<string>}
   * @memberof OrderService
   */
  getPreOrderIdByBookingId(bookingId: string) {
    return this.httpClient.get<string>('/HotelBookingPreOrderApi/GetPreOrderIDByBookingID', {
      bookingID: bookingId,
    });
  }

  getHotelBookingDetailByPreOrderId(preOrderId: string, confirmResult: string, confirmMessageCode: string) {
    return this.httpClient.get<BookingDetailModel>('/HotelBookingApi/GetHotelBookingDetailByPreOrderId', {
      preOrderID: preOrderId,
      confirmResult: confirmResult,
      confirmMessageCode: confirmMessageCode,
    });
  }

  /**
   * 锁定
   *
   * * 当用户前往下单确认页时，调用次接口来设定pre-order的最终有效期
   *
   * @param {string} preOrderId
   * @returns {HttpObservable<boolean>}
   * @memberof OrderService
   */
  lockPreOrder(preOrderId: string) {
    return this.httpClient.post<boolean>('/HotelBookingPreOrderApi/LockPreOrder', {
      preOrderID: preOrderId,
    });
  }

  /**
   * 解锁
   *
   * * 当用户返回订单信息填写页时，调用次接口来解锁pre-order的最终有效期
   *
   * @param {string} preOrderId
   * @returns {HttpObservable<boolean>}
   * @memberof OrderService
   */
  unLockPreOrder(preOrderId: string) {
    return this.httpClient.post<boolean>('/HotelBookingPreOrderApi/UnLockPreOrder', {
      preOrderID: preOrderId,
    });
  }

  /**
   * 确认下单
   *
   * *类似以前的ConfirmV2*
   *
   * @param {string} preOrderId 预下单ID
   * @param {PayChannelTypeEnum} payChannel 支付通道
   * @param {number} payChannelFeeRate 支付通道手续费
   * @returns {HttpObservable<BookingConfirmationContext>}
   * @memberof OrderService
   */
  confirm(
    preOrderId: string,
    totalPrice: number,
    currency: string,
    payChannel: PayChannelTypeEnum,
    doublePoints = false,
    isNeedOnRequest = false,
    valueAddList: DidaValueAddInfoModel[] = null,
    payChannelFeeRate = 0,
    timeout = 280000,
    previouPreOrderID: string,
    couponInfo: CouponInfoForBookingConfirmModel
  ) {
    return this.httpClient.post<BookingConfirmationContext>(
      '/HotelBookingProcessApi/ConfirmV3',
      {
        preOrderID: preOrderId,
        totalPrice: totalPrice,
        currency: currency,
        payChannel: payChannel,
        payChannelFeeRate: payChannelFeeRate,
        doublePoints: doublePoints,
        isNeedOnRequest: isNeedOnRequest,
        c: valueAddList,
        previouPreOrderID: previouPreOrderID,
        couponInfo: couponInfo,
      },
      timeout,
      (raw: BookingConfirmationContext) => {
        this.bookingConfirmationCtxProcessor(raw);
      },
      (payload) => {
        if (payload.valueAddList && payload.valueAddList instanceof Array) {
          payload.valueAddList = (payload.valueAddList as DidaValueAddInfoModel[]).map((va) => {
            let arr: { Key: number; Value: number }[] = [];
            Object.keys(va.Configuration.OptionSelection).forEach((key: string) => {
              let paramIdx = parseInt(key, 10);
              let optIdx = va.Configuration.OptionSelection[paramIdx];
              arr.push({
                Key: paramIdx,
                Value: optIdx as number,
              });
            });
            va.Configuration.OptionSelection = arr;
            return va;
          });
        }
      }
    );
  }

  /**
   * 检查是否强制收取晚宴费
   * @param {string} hotelID 酒店ID
   * @param {string} fromDate 入住日期
   * @param {string} toDate 离店日期
   */
  checkHasBanquet(hotelID: number, fromDate: Date, toDate: Date) {
    return this.httpClient.post('/HotelBookingApi/IsHasBanquet', {
      hotelID,
      fromDate: DateTimeHelper.parse(fromDate, true),
      toDate: DateTimeHelper.parse(toDate, true),
    });
  }

  getQRCodeContent(transactionID: string, realTimeQuery: boolean) {
    return this.httpClient.get<QRCodeContentResult>('/HotelBookingQRCodeApi/GetQRCodeContent', {
      transactionID: transactionID,
      realTimeQuery: realTimeQuery,
    });
  }

  private bookingConfirmationCtxProcessor(raw: BookingConfirmationContext) {
    if (raw.BookingDetail) {
      raw.BookingDetail.TravelDates.From = DateTimeHelper.parse(raw.BookingDetail.TravelDates.From, true);
      raw.BookingDetail.TravelDates.To = DateTimeHelper.parse(raw.BookingDetail.TravelDates.To, true);
      if (raw.BookingDetail.OrderDate) {
        raw.BookingDetail.OrderDate = new Date(raw.BookingDetail.OrderDate);
      }
      if (raw.BookingDetail.BookingCancelTime) {
        raw.BookingDetail.BookingCancelTime = DateTimeHelper.parse(raw.BookingDetail.BookingCancelTime, true);
      }
      if (raw.BookingDetail.CancellationPolicies && raw.BookingDetail.CancellationPolicies.length > 0) {
        raw.BookingDetail.CancellationPolicies.forEach((c) => {
          c.From = DateTimeHelper.parse(c.From, true);
        });
      }
    }
    if (raw.PreOrderDetail) {
      this.preOrderDetailProcessor(raw.PreOrderDetail);
    }
  }

  public preOrderDetailProcessor(detail: HotelBookingPreOrderDetail) {
    detail.RequestPayload.CheckIn = DateTimeHelper.parse(detail.RequestPayload.CheckIn, true);
    detail.RequestPayload.CheckOut = DateTimeHelper.parse(detail.RequestPayload.CheckOut, true);
    if (detail.Response) {
      if (!detail.Response.RatePlanPriceList) {
        detail.Response.RatePlanPriceList = [];
        return;
      }
    }
    detail.Response.RatePlanPriceList.forEach((p) => {
      p.RatePlan.IsPackage = ['405', '407'].includes(p.RatePlan.RatePlanID.split('$')[0]);
      p.RatePlan.ExtraInfoIDList = ((p.RatePlan.ExtraInfoIDList as any) || '')
        .split('|')
        .map((item) => parseInt(item, 10));
      p.RateList.forEach((r) => {
        r.StayDate = DateTimeHelper.parse(r.StayDate, true);
      });
      p.CancellationList.forEach((c) => {
        c.FromDate = DateTimeHelper.parse(c.FromDate, true);
      });
    });
  }
}
