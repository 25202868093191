import {
  CompilerOptions,
  enableProdMode,
  LOCALE_ID,
  MissingTranslationStrategy,
  NgModuleRef,
  Provider,
  TRANSLATIONS,
  TRANSLATIONS_FORMAT,
  ViewEncapsulation,
  ApplicationRef
} from '@angular/core';
import { IApplicationEnvironment } from '@dida-shopping/dida-services/common';
import { ISiteRouteRuleModel } from '@dida-shopping/dida-services/config';
import { APP_VERSION } from './app-verison';
import { SERVER_CONFIG } from './app-server-config';
import { APP_SITE_ROUTE_CONFIG } from './app-site-route-config';
// import { hmrBootstrap } from './hmr';
import { RouteRuleHelper } from './routing/route-rule.helper';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import zh from '@angular/common/locales/zh';
import ja from '@angular/common/locales/ja';
import { I18nHelper } from './i18n';
// import { createNewHosts } from '@angularclass/hmr'

const LOCALE_MAP = {'en-US': en, 'zh-CN': zh, 'ja-JP': ja};

export interface IDidaNgxApplicationBootstrapOption<M> {
  appEnv: IApplicationEnvironment;
  boosterBuilder: (options: CompilerOptions) => Promise<NgModuleRef<M>>;
  siteRouteJsonResolver: () => ISiteRouteRuleModel[];
  // translationResolver?: (locale: string) => string;
}

declare let __webpack_public_path__: string;
const razorViewBagPrefix = '@ViewBag.';

export function didaNgxApplicationBootstrap<M>(
  option: IDidaNgxApplicationBootstrapOption<M>
) {
  const environment = option.appEnv;
  const oldSiteUrl: string = document['OldSiteHost'];
  const webStaticContentHost: string = document['WebStaticContentHost'];
  const apiServiceHost: string = document['ApiServiceHost'];
  const cookieNameLocale: string = document['CookieName_Locale'];
  const versionInDoc: string = document['__VERSION__'];
  let hostProtocal: string = document['Host_Protocal'];

  const siteRouteJson: string = document['SITE_ROUTES'];

  // Google Map Config
  const gmApiHost: string = document['GoogleMapAPIHost'];
  const gmApiKey: string = document['GoogleMapAPIKey'];


  APP_VERSION.buildNumber = versionInDoc;


  if (environment.proxyEnabled) {
    SERVER_CONFIG.proxyEnabled = true;
  }
  if (environment.ignoreBuildNumber) {
    SERVER_CONFIG.ignoreBuildNumber = true;
  }
  if (environment.localDebug) {
    SERVER_CONFIG.localDebug = true;
  }


  if (hostProtocal == null || hostProtocal.indexOf(razorViewBagPrefix) >= 0) {
    hostProtocal = 'http://';
  }

  if (webStaticContentHost != null && webStaticContentHost.indexOf(razorViewBagPrefix) === -1 && __webpack_public_path__) {
    // replace the razor syntax to the real web static content location.
    __webpack_public_path__ = __webpack_public_path__
      .replace('@ViewBag.WebStaticContentHost', webStaticContentHost);
  }


  SERVER_CONFIG.webPublicPath = __webpack_public_path__;


  if (apiServiceHost != null && apiServiceHost.indexOf(razorViewBagPrefix) === -1) {
    SERVER_CONFIG.serviceEndPoint = `${hostProtocal}${apiServiceHost}`;
  } else if (environment.service && environment.service.endpoint) {
    SERVER_CONFIG.serviceEndPoint = environment.service.endpoint;
  }


  if (oldSiteUrl != null && oldSiteUrl.indexOf(razorViewBagPrefix) === -1) {
    SERVER_CONFIG.oldSiteUrl = `${oldSiteUrl}`;
  } else if (environment.oldSite) {
    SERVER_CONFIG.oldSiteUrl = environment.oldSite;
  }

  if (cookieNameLocale != null && cookieNameLocale.indexOf(razorViewBagPrefix) === -1) {
    SERVER_CONFIG.localeCookieName = cookieNameLocale;
  } else {
    SERVER_CONFIG.localeCookieName = environment.localeCookieName || 'dida-locale';
  }


  // initialize google map settings
  if (gmApiHost != null && gmApiHost.indexOf(razorViewBagPrefix) === -1) {
    SERVER_CONFIG.googleMapSetting.ApiHost = gmApiHost;
  } else {
    SERVER_CONFIG.googleMapSetting.ApiHost = environment.googleMap.apiHost;
  }
  if (gmApiKey != null && gmApiHost.indexOf(razorViewBagPrefix) === -1) {
    SERVER_CONFIG.googleMapSetting.JavaScriptMap.ApiKey = gmApiKey;
  } else {
    SERVER_CONFIG.googleMapSetting.JavaScriptMap.ApiKey = environment.googleMap.apiKey;
  }

  // initialize local debug behavior
  if (environment.localDebug) {
    SERVER_CONFIG.enableLoginModal = true;
  } else {
    SERVER_CONFIG.enableLoginModal = false;
  }

  const locale = I18nHelper.getApplicationLocale();
  const providers: Provider = [{ provide: LOCALE_ID, useValue: locale }];
  const localeData = LOCALE_MAP[locale];
  if (environment.production) {
    enableProdMode();
  }
  // Roman: ivy need aot turn on so that not support i18n in JIT mode any more
  // If you want to change the locale, please update the project serve node in `angular.json `
  // E.g.:
  // "devvm": {
  //    "browserTarget": "account-app:build:devvm,en"
  // },
  // else if (option.translationResolver) {
  //   const translations = option.translationResolver(locale);
  //   providers.push(...[
  //     { provide: TRANSLATIONS, useValue: translations },
  //     { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
  //   ]);
  // }
  registerLocaleData(localeData, locale);

  // initialize site routes
  let siteRouteRules: ISiteRouteRuleModel[] = null;
  if (siteRouteJson !== '#SiteRouteJson#') {
    try {
      siteRouteRules = JSON.parse(siteRouteJson);
    } catch (e) {
      console.error(`invalid site route json: '${siteRouteJson}'`);
    }
  } else {
    try {
      siteRouteRules = option.siteRouteJsonResolver();
    } catch (e) {
      console.error(`invalid site route json: '${siteRouteJson}'`);
    }
  }
  if (siteRouteRules != null) {
    const rules = RouteRuleHelper.extractApplicationRouteRules(siteRouteRules);
    APP_SITE_ROUTE_CONFIG.rules.unshift(...rules);
    APP_SITE_ROUTE_CONFIG.initialized = true;
  }
  APP_SITE_ROUTE_CONFIG.noticeIfCrossSite = environment.localDebug;

  const compilerOpts: CompilerOptions = {
    providers,
    defaultEncapsulation: ViewEncapsulation.Emulated,
    preserveWhitespaces: true,
    missingTranslation: MissingTranslationStrategy.Ignore
  };

  console.log(SERVER_CONFIG);

  const bootstrap = () => {
    return booster;
  };

  const booster = option.boosterBuilder(compilerOpts);

  booster.then(() => {
    console.log(`application started!`);
  }, err => {
    console.error(err);
  });

  if(environment.localDebug) {
    // if (environment.hmr) {
    //   /**
    //    * @warning 各个app里的main.ts里面也要调用一下module['hot'].accept()才会生效
    //    * @todo 开启了不保存状态也没啥卵用....
    //    */
    //   booster.then((ngModule: NgModuleRef<any>) => {
    //     module['hot'].accept()
    //     module['hot'].dispose(() => {
    //       const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef)
    //       const elements = appRef.components.map(c => c.location.nativeElement)
    //       const makeVisible = createNewHosts(elements)
    //       ngModule.destroy()
    //       makeVisible()
    //     })
    //   })
    // } else {
    //   console.error('HMR is not enabled for webpack-dev-server!');
    //   console.log('Are you using the --hmr flag for ng serve?');
    // }
  }

  return booster
}
