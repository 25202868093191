import { PartialObserver, Observable, Subscription } from 'rxjs';
import { StandardErrorModel, IAsyncOperation } from '@dida-shopping/dida-services/http';

// export interface HttpObserver<T> extends NextObserver<T>, ErrorObserver<T> {
//   next: (value: T) => void;
//   error: (err: StandardErrorModel) => void;
// }

export interface HttpObserver<T> {
  next: (value: T) => void;
  error?: (err: StandardErrorModel) => void;
  closed?: boolean;
  complete?: () => void;
}

export class HttpObservable<T> extends Observable<T> implements IAsyncOperation<T> {
  type: 'promise' | 'observable' = 'observable';

  private constructor(
    private observable: Observable<T>,
    private pusher: (sub: Subscription) => Subscription
  ) {
    super();
    Object.assign(this, observable);
  }

  static create<T>(
    observable: Observable<T>,
    pusher: (sub: Subscription) => Subscription
  ): HttpObservable<T> {
    return new HttpObservable<T>(observable, pusher);
  }

  touch() {
    this.subscribe({
      next: () => {
        console.log(`[TOUCHED]`);
      },
      error: (err) => {
        console.warn(`[TOUCHED][ERROR]`, err);
      }
    });
  }

  subscribe(
    observerOrNext?:
      Partial<HttpObserver<T>>
      | ((value: T) => void),
    error?: (error: StandardErrorModel) => void,
    complete?: () => void
  ): Subscription {
    let sub: Subscription = null;
    if (typeof observerOrNext === 'function') {
      sub = super.subscribe(observerOrNext, error, complete);
    } else {
      sub = super.subscribe(observerOrNext as PartialObserver<T>);
    }
    return this.pusher(sub);
  }

  // get internalObservable(): Observable<T> {
  //     return this.observable;
  // }
}
