import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '../../../http/http-client';
import { LoggerService } from '../../../logger/logger.service';
import { CouponSearchOptionPayload } from '../../../../shared/models/campaigns/coupons/coupon-search-option-payload.model';
import { PaginationWrapper } from '../../../../shared/models/common/pagination-wrapper.model';
import {
  CouponModel,
  UserCouponCacheModel,
  UserCouponCountModel
} from '../../../../shared/models/campaigns/coupons/coupon.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ParseHelper } from '../../../../shared/utils/parse-helper';

/**
 * 优惠券数据服务
 *
 * @export
 * @class CouponService
 */
@Injectable()
export class CouponService {
  private _timeout = 1 * 60 * 1000;
  private _timer = null;
  private couponEmitter = new EventEmitter<void>();
  private _activeUserCoupon: UserCouponCacheModel[];
  private couponSubject: BehaviorSubject<UserCouponCacheModel>;

  constructor(private logger: LoggerService, private httpClient: HttpClient) {
    let defaultValue = new UserCouponCacheModel();
    this.couponSubject = new BehaviorSubject<UserCouponCacheModel>(
      defaultValue
    );
    this.couponEmitter.pipe(debounceTime(1000)).subscribe(() => {
      this.getActiveCoupon();
    });
  }

  searchCoupon(
    payload: CouponSearchOptionPayload
  ) {
    return this.httpClient.get<PaginationWrapper<CouponModel>>(
      '/CouponApi/Search',
      {
        payload: payload
      },
      null,
      result => {
        if (result.Data && result.Data.length > 0) {
          result.Data = ParseHelper.parseJsonWithDate(
            JSON.stringify(result.Data)
          );
          this.logger.debug(result.Data);
        }
      }
    );
  }

  private getActiveCoupon(): void {
    this.searchCouponByCache(false).subscribe((resp: UserCouponCacheModel) => {
      this.couponSubject.next(resp);
    });
  }

  searchCouponByCache(isModal: boolean) {
    return this.httpClient.get<UserCouponCacheModel>(
      '/CouponApi/SearchCacheCoupons',
      {
        isModal: isModal
      },
      null,
      result => {
        if (result.CouponModels && result.CouponModels.length > 0) {
          result.CouponModels = ParseHelper.parseJsonWithDate(
            JSON.stringify(result.CouponModels)
          );
          this.logger.debug(result.CouponModels);
        }
      }
    );
  }

  searchCouponCount() {
    return this.httpClient.get<UserCouponCountModel[]>('/CouponApi/SearchCouponCount');
  }

  subscribe(...args): Subscription {
    if (!this._timer) {
      this.couponEmitter.emit();
      this._timer = setInterval(() => {
        this.couponEmitter.emit();
      }, this._timeout);
    }
    return this.couponSubject.subscribe(...args);
  }
}
