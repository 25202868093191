
<div class="campaign-header"></div>
<div class="campaign-body">
  <div class="campaign-body__flex">
    <div class="campaign-item-body__list clearfix nd-flex" *ngFor="let item of list">
      <div class="campaign-item-body__item nd-flex nd-flex__auto">
        <div class="campaign-item-body__amount">
          <span class="campaign-item-body__amount_currency">¥</span>
          <span class="campaign-item-body__amount_number">{{ item.RewardSetting.CampaignRewardAmount }}</span>
        </div>
        <div class="campaign-item-body__text">
          <div class="campaign-item-body__text_type">
            酒店满减券
          </div>
          <div class="campaign-item-body__text_rule">满{{ item.RewardSetting.MinimumPurchaseAmount }}可用</div>
        </div>
      </div>
      <div class="campaign-item-body__split"></div>
      <div class="campaign-item-body__item  nd-flex__auto">
        <div class="campaign-item-body__meta">
          <div>
            <span>有效日期：</span>
            <span class="campaign-item-body__date"
              >{{ item.EffectiveTimeSetting.From | date: 'yyyy-MM-dd' }}至{{
                item.EffectiveTimeSetting.To | date: 'yyyy-MM-dd'
              }}</span
            >
          </div>
          <div>
            <span>入离日期：</span>
            <span
              *ngIf="item.RestrictionRuleModel.CheckInDateRestriction as restriction"
              class="campaign-item-body__date"
              [ngSwitch]="restriction.RestrictionType"
            >
              <ng-container *ngSwitchCase="restrictionType.Disabled">不限</ng-container>
              <ng-container *ngSwitchCase="restrictionType.ApplyBoth">
                {{ restriction.From | date: 'yyyy-MM-dd' }}至{{ restriction.To | date: 'yyyy-MM-dd' }}
              </ng-container>
            </span>
            <br />
            <!-- <span class="active">{{effectDay}}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="campaign-body__mask">
    <button (click)="hideModal()" class="campaign-body__use-button">我知道了</button>
  </div>
</div>
3
