
import { debounceTime, map } from 'rxjs/operators';
import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { MessageCategoryTypeEnum } from '../../../shared/enums/message-category-type';
import { Injectable, OnDestroy, EventEmitter } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';

import { MessageSketchModel } from '../../../shared/models/message/message-sketch.model';
import { MessageCategoryUnReadModel } from '../../../shared/models/message/message-category-unread.model';
import { MessageListModel } from '../../../shared/models/message/message-list.model';
import { MessageSearchOptionModel } from '../../../shared/models/message/message-search-option.models';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { MessageModel } from '../../../shared/models/message/message.model';

/**
 * 用户信息数据服务
 *
 * @export
 * @class MessageService
 */
@Injectable()
export class MessageService implements OnDestroy {
  private _unreadMessageList: MessageSketchModel[];
  private messageSubject: BehaviorSubject<
    PaginationWrapper<MessageSketchModel>
  >;
  private messageEmitter = new EventEmitter<void>();
  private messageIdentifier: string;
  private _timeout = 1 * 60 * 1000;
  private _timer = null;

  get subject() {
    return this.messageSubject;
  }

  get unreadMessageList() {
    return this._unreadMessageList;
  }

  constructor(private logger: LoggerService, private httpClient: HttpClient) {
    let defaultValue = new PaginationWrapper<MessageSketchModel>();
    this.messageSubject = new BehaviorSubject<
      PaginationWrapper<MessageSketchModel>
    >(defaultValue);
    this.messageEmitter.pipe(debounceTime(1000)).subscribe(() => {
      this.getUnreadMessage();
    });
  }

  /**
   * 获取未读消息列表
   * @memberof MessageService
   */
  private getUnreadMessage(): void {
    this.httpClient.get('/UserMessageApi/GetUnreadMessage')
      .pipe(
        map(this.populateMessageParams)
      )
      .subscribe((resp: PaginationWrapper<MessageSketchModel>) => {
        // let msgIdentifier = resp.Data && resp.Data.map(item => item.MessageID).join('.');
        // if (msgIdentifier !== this.messageIdentifier) {
        this._unreadMessageList = resp.Data;
        // console.log(resp.Data);
        this.messageSubject.next(resp);
        // this.messageIdentifier = msgIdentifier;
        // }
      });
  }
  /**
   * 获取未读消息个数和当然的category title
   * @memberof MessageService
   * @param {string?} category
   * @returns {HttpObservable<boolean>}
   */
  getCategoryUnReadCounts(
    category?: string
  ) {
    return this.httpClient.get<MessageCategoryUnReadModel>('/UserMessageApi/GetCategoryUnReadCounts', {
      category
    });
  }

  /**
   * 获取消息列表
   * @memberof MessageService
   * @param {MessageSearchOptionModel} options
   * @returns {HttpObservable<UserMessageModel>}
   */
  getList(
    options: MessageSearchOptionModel
  ) {
    return this.httpClient.post<PaginationWrapper<MessageListModel>>('/UserMessageApi/SearchMessage', options);
  }

  /**
   * 获取消息列表
   * @memberof MessageService
   * @param {MessageSearchOptionModel} options
   * @returns {Observable<UserMessageModel>}
   */
  get(options: MessageSearchOptionModel) {
    return this.httpClient
      .post<PaginationWrapper<MessageModel>>(
        '/UserMessageApi/SearchMessage',
        options
      )
      .pipe(map(this.populateMessageParams));
  }
  /**
   * 把消息设为己读
   * @memberof MessageService
   * @param {number} id
   * @returns {Observable<boolean>}
   */
  read(id: number) {
    return this.httpClient
      .post<boolean>('/UserMessageApi/ReadMessage', { messageID: id })
      .pipe(map(resp => {
        this.messageEmitter.emit();
        return resp;
      }));
  }
  /**
   * 根据分类把消息全部设为己读
   * @memberof MessageService
   * @param {number} categoryId
   * @returns {Observable<boolean>}
   */
  readAll(categoryId?: number) {
    return this.httpClient
      .post<boolean>('/UserMessageApi/ReadAllMessage', {
        categoryTypeID: categoryId
      })
      .pipe(
        map(resp => {
          this.messageEmitter.emit();
          return resp;
        })
      );
  }
  /**
   * 根据消息Id列表把消息全部设为己读
   * @memberof MessageService
   * @param {Array<number>} idList
   * @returns {Observable<boolean>}
   */
  readBatch(idList: Array<number>) {
    return this.httpClient
      .post<boolean>('/UserMessageApi/BatchReadMessage', {
        listMessageID: idList
      })
      .pipe(
        map(resp => {
          this.messageEmitter.emit();
          return resp;
        })
      );
  }
  /**
   * 批量删除消息
   * @memberof MessageService
   * @param {Array<number>} idList
   * @returns {Observable<boolean>}
   */
  deleteBatch(idList: Array<number>): Observable<boolean> {
    return this.httpClient
      .post<boolean>('/UserMessageApi/BatchDeleteMessage', { listMessageID: idList }).pipe(
        map(resp => {
          this.messageEmitter.emit();
          return resp;
        }));
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  clearTimer() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }

  subscribe(...args): Subscription {
    if (!this._timer) {
      this.messageEmitter.emit();
      this._timer = setInterval(() => {
        // console.log('get unread message!', new Date().toISOString());
        this.messageEmitter.emit();
      }, this._timeout);
    }
    return this.messageSubject.subscribe(...args);
  }

  private populateMessageParams(
    resp: PaginationWrapper<MessageModel>
  ): PaginationWrapper<MessageModel> {
    resp.Data = resp.Data.map(model => {
      switch (model.Category) {
        case MessageCategoryTypeEnum.WorkOrderStatusChange:
        case MessageCategoryTypeEnum.System:
          let setting = model.ExtraSetting || '{}';
          model.ExtraSetting = JSON.parse(setting as string);
          if(model.Content) {
            model.Content = model.Content.replace(/\<a\s/g, '<a target="_blank" ');
          }
      }
      let message = Object.assign(new MessageModel(), model);
      return message;
    });
    return resp;
  }
}
