import { AbstractRunnableService } from './runnable-service.base';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { USER_COUPON_STORAGE } from '@dida-shopping/dida-services/storage';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { couponService, ICouponModel } from '@dida-shopping/dida-services/campaign';
import { CampaignEventHub, UserActiveCouponUpdateEvent } from '../events';
import { ngxHttpCall } from '../http';


@Injectable({
  providedIn: 'root'
})
export class UserCouponService extends AbstractRunnableService {
  static readonly MAX_CACHE_VALID_SEC: number = 15; //
  static readonly CACHE_RELOAD_INTERVAL_SEC: number = 60; // 每分钟刷新一次

  get activeCouponCount(): number {
    return USER_COUPON_STORAGE.activeCount;
  }

  get lastCacheTime(): Date {
    return USER_COUPON_STORAGE.cacheTime;
  }

  constructor(
    private campaignEventHub: CampaignEventHub
  ) {
    super(UserCouponService.CACHE_RELOAD_INTERVAL_SEC);
  }

  protected execute(): Subscription {
    return this.loadUserCoupons();
  }

  isCacheValidBy(time: Date): boolean {
    const validCacheTime = DateTimeHelper.addSeconds(this.lastCacheTime, UserCouponService.MAX_CACHE_VALID_SEC);
    return time <= validCacheTime;
  }

  loadUserCoupons(ignoreCache = false): Subscription {
    const time = new Date();
    if (!ignoreCache && this.isCacheValidBy(time)) {
      const subject = new BehaviorSubject<boolean>(true);
      subject.next(true);
      subject.complete();
      return subject.subscribe();
    }
    return ngxHttpCall(
      couponService.searchCouponByCache(false)
    ).subscribe({
      next: (cache) => {
        const result = USER_COUPON_STORAGE.setActiveCoupons(cache.CouponModels, time);
        if (result.hasUpdated) {
          this.campaignEventHub.broadcast(
            new UserActiveCouponUpdateEvent(
              `[UserCouponService::loadUserCoupons]`, new Date()
            )
          )
        }
      }
    });
  }

  getActiveCoupons(): ICouponModel[] {
    return USER_COUPON_STORAGE.getActiveCoupons();
  }
}
