import { Inject, Injectable, InjectionToken, isDevMode, LOCALE_ID, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { SERVER_CONFIG } from '../app-server-config';
import { APP_VERSION } from '../app-verison';
import { Observable, Observer } from 'rxjs';
import { ApplicationService, BehaviorManagementService } from '../application';
import { APP_CONTEXT_STORAGE } from '@dida-shopping/dida-services/storage';
import { IDidaApplicationUserContextModel } from '@dida-shopping/dida-services/auth';
import { ConditionCheckError } from './enums/condition-check-error.enum';
import { StandardErrorModel } from '@dida-shopping/dida-services/http';
import { ExperimentHelper } from '@dida-shopping/dida-services/experiment';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { ClientStatusTypeEnum, UserStatusTypeEnum } from '@dida-shopping/dida-services/membership';
import { IRouteDataModel, UserContextConditions } from '../app-routing-data.model';

export const MAX_FAILED_COUNT = 3;

let FAILED_COUNT = 0;

export const DIDA_AUTH_MODAL_TRIGGER = new InjectionToken<{
  value: (url: string, message: string) => void
}>('DIDA_AUTH_MODAL_TRIGGER');

@Injectable()
export class AuthorizationGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private router: Router,
    private bmService: BehaviorManagementService,
    private appService: ApplicationService,
    @Inject(LOCALE_ID) private locale: 'en-US' | 'zh-CN',
    @Optional() @Inject(DIDA_AUTH_MODAL_TRIGGER) private authModalTrigger: (url: string, message: ConditionCheckError) => void
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const path = state.url;
    const { redirectInWechat = null, redirectInMobile = null, condition: cond = null } = route.data || {};
    return Observable.create((ob: Observer<boolean>) => {

      // TODO:: Double Confirm This Logic
      // if (redirectInWechat && BrowserHelper.isInWebView) {
      //   // tslint:disable-next-line:no-console
      //   console.debug(`--------------- redirectInWechat ${redirectInWechat}`);
      //   this.redirect(ob, redirectInMobile, state.root.queryParams);
      //   return;
      // }
      //
      // if (redirectInMobile && BrowserHelper.isInWebView) {
      //   // tslint:disable-next-line:no-console
      //   console.debug(`--------------- redirectInMobile ${redirectInMobile}`);
      //   this.redirect(ob, redirectInMobile, state.root.queryParams);
      //   return;
      // }
      if (this.appService.isContextValid) {
        this.handle(this.appService.context, route.data, path, ob);
      } else {
        this.handleAuthorizeInfo(ob, route, state);
      }
    });
  }

  canLoad(route: Route): Observable<boolean> {
    const path = route.path;
    return Observable.create((ob: Observer<boolean>) => {
      if (this.appService.isContextValid) {
        this.handle(this.appService.context, route.data, path, ob);
      } else {
        this.handleAuthorizeInfo(ob, null, null, route);
      }
    });
  }

  private handleAuthorizeInfo(
    ob: Observer<boolean>,
    routeSnapshot?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot,
    route?: Route
  ): void {
    const path = route != null ? route.path : state.url;
    const data = route != null ? route.data : routeSnapshot.data;

    // let cond: UserContextConditions = data != null ? data.condition : null;

    this.appService.reloadContext().subscribe({
      next: (ctx) => {
        if (APP_CONTEXT_STORAGE.userIdentityInfo.isAuthenticated) {
          const { UserName, ClientID } = APP_CONTEXT_STORAGE.userProfile;
          this.bmService.initUserSession(UserName, ClientID);
        }
        this.handle(ctx, data, path, ob);
      },
      error: (err: StandardErrorModel) => {
        APP_CONTEXT_STORAGE.set(null); // failed
        if (err.networkErr) {
          FAILED_COUNT++;
        } else {
          FAILED_COUNT = 0;
        }
        if (FAILED_COUNT > MAX_FAILED_COUNT) {
          this.router.navigateByUrl('/error');
        } else {
          this.postProcess(ConditionCheckError.NotLogin, ob, path);
        }
      }
    });
  }

  private handle(usrAppCtx: IDidaApplicationUserContextModel, routeData: IRouteDataModel, path: string, ob: Observer<boolean>) {
    // let cond: UserContextConditions = routeData != null ? routeData.condition : null;
    const {
      condition: cond = null,
      ignoreAccountLocked = false,
      experimentCtrl: expCtrl = null,
      langRestriction: langCtrl = null
    } = routeData || {};
    let error = this.checkCondition(cond, usrAppCtx, ignoreAccountLocked);
    if (error === ConditionCheckError.None) {
      // if (this.appService.context) {
      //   const curLang = usrAppCtx.lang;
      //   if (this.locale !== curLang) {
      //     window.top.location.href = path;
      //     return; // skip route resolve
      //   }

      //   // detect version
      //   if (!SERVER_CONFIG.ignoreBuildNumber &&
      //     usrAppCtx.fe_version != null &&
      //     APP_VERSION.buildNumber != null &&
      //     APP_VERSION.buildNumber !== usrAppCtx.fe_version) {

      //     // version not match
      //     console.warn(`[version] version is not match. cur: ${APP_VERSION.buildNumber}, live: ${usrAppCtx.fe_version}.`);

      //     if (!usrAppCtx.fe_deploying && usrAppCtx.fe_reload) {
      //       console.warn(`[version] reloading for live version.`);
      //       // reload current page immediately
      //       window.top.location.href = path;
      //       return;
      //     }
      //   }
      // }
    }
    if (error === ConditionCheckError.None) {
      if (expCtrl && !ExperimentHelper.isAllowControl(expCtrl, APP_CONTEXT_STORAGE)) {
        error = ConditionCheckError.PermissionDenied;
      }
    }
    if (error === ConditionCheckError.None) {
      if (langCtrl && !this.checkLanguages(langCtrl, usrAppCtx)) {
        error = ConditionCheckError.LanguageNotSupported;
      }
    }

    this.postProcess(error, ob, path);
  }

  private checkCondition(cond: UserContextConditions,
                         ctx: IDidaApplicationUserContextModel, ignoreAccountLocked: boolean): ConditionCheckError {
    if (cond == null) {
      return ConditionCheckError.None;
    }

    const { profile, clientInfo, isAuthenticated } = ctx.userIdentityInfo;

    if (cond.needAuth) {

      // 用户未登录直接退出
      if (!isAuthenticated) {
        return ConditionCheckError.NotLogin;
      }

      if (!ignoreAccountLocked) {

        // // 用户所在机构被锁定
        if (clientInfo && clientInfo.Status === ClientStatusTypeEnum.Locked) {
          return ConditionCheckError.AccountLocked;
        }

        // 用户锁定
        if (profile.Status === UserStatusTypeEnum.Locked) {
          return ConditionCheckError.AccountLocked;
        }
      }
    }

    if (cond.devOnly) {
      if (!isDevMode()) {
        return ConditionCheckError.RestrictedDevModeOnly;
      }
      if (cond.devClients) {
        if (cond.devClients.indexOf(clientInfo.ClientID) === -1) {
          return ConditionCheckError.RestrictedDevModeOnly;
        }
      }
    }

    if (cond.needClientInfo) {
      const isClientActive = clientInfo && clientInfo.Status === ClientStatusTypeEnum.Active;
      if (!isClientActive) {
        return ConditionCheckError.ClientRegistering;
      }
    }

    if (cond.needConfirmedEmail) {
      const isEmailConfirmed = profile && profile.EmailConfirmed;
      if (!isEmailConfirmed) {
        if(profile.Status === UserStatusTypeEnum.ClientInfoProcessing) {
          return ConditionCheckError.ClientRegistering;
        } else {
          return ConditionCheckError.EmailUnconfirmed
        }
      }
    }

    if (cond.mainAccountOnly) {
      const isMainAccount = profile && typeof (profile.ParentUserID) !== 'string';
      if (!isMainAccount) {
        return ConditionCheckError.PermissionDenied;
      }
    }
    return ConditionCheckError.None;
  }

  private checkLanguages(langCtrl: LanguageType[], ctx: IDidaApplicationUserContextModel): boolean {
    const targetLang = langCtrl.indexOf(ctx.lang);
    return targetLang > -1;
  }

  private postProcess(canAccessError: ConditionCheckError, ob: Observer<boolean>, path: string) {
    const returnUrl = this.getReturnUrl();
    ob.next(canAccessError === ConditionCheckError.None);
    ob.complete();
    switch (canAccessError) {
      case ConditionCheckError.NotLogin:
        if (this.detectShowLoginModal(path, ConditionCheckError.NotLogin)) {
          return;
        } else {
          // return this.router.navigateByUrl(returnUrl);
          window.location.href = returnUrl;
          return;
        }
      case ConditionCheckError.ClientRegistering:
        if (this.detectShowLoginModal(path, ConditionCheckError.ClientRegistering)) {
          return;
        } else {
          return this.router.navigateByUrl('/account/register');
        }
      case ConditionCheckError.AccountLocked:
        if (this.detectShowLoginModal(path, ConditionCheckError.AccountLocked)) {
          return;
        } else {
          return this.router.navigateByUrl('/account/manage');
        }
      case ConditionCheckError.EmailUnconfirmed:
        return this.router.navigateByUrl('/account/email-confirm');
      case ConditionCheckError.PermissionDenied:
        return this.router.navigateByUrl('/error/404', { replaceUrl: true });
      case ConditionCheckError.LanguageNotSupported:
        return this.router.navigateByUrl('/error/404', { replaceUrl: true });
      case ConditionCheckError.RestrictedDevModeOnly:
        return this.router.navigateByUrl('/home', { replaceUrl: true });
    }
  }

  private detectShowLoginModal(path: string, reason: ConditionCheckError): boolean {
    if (SERVER_CONFIG.enableLoginModal && this.authModalTrigger) {
      this.appService.expireApplicationContextValidTimeForRoute();
      this.authModalTrigger(path, reason);
      return true;
    }
    return false;
  }

  private getReturnUrl() {
    const searchStr = location.search;
    const nestedReturnAt = searchStr.indexOf('returnUrl=');
    let nestedReturnUrl = null;
    if (nestedReturnAt >= 0) {
      nestedReturnUrl = decodeURIComponent(searchStr.substring(nestedReturnAt).split('&')[0].replace('returnUrl=', ''));
    }
    return `/account/login?returnUrl=${encodeURIComponent(nestedReturnUrl || location.href)}`;
  }

  private redirect(ob: Observer<boolean>, path: string, params: any) {
    ob.next(false);
    ob.complete();
    return this.router.navigate([path], {
      queryParams: params
    });
  }

}
