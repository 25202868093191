import { Region } from '@dida-shopping/dida-services/destinations';

export class DidaTabsCascaderConfig {

    /**
     * 默认宽度*(为空则占满)*
     */
    width?: number;

    /**
     * 控件高度*(为空则自动)*
     *
     * @type {number}
     * @memberof DidaTabsCascaderConfig
     */
    height?: number;

    /**
     * 选项
     */
    options: IDidaTabsCascaderOption[];

    /**
     * 分组模式
     *
     * @type {boolean}
     * @memberof DidaTabsCascaderConfig
     */
    groupMode = false;

    /**
     * 用作显示标签的属性名称*(默认为label)*
     */
    labelProperty = 'label';

    /**
     * 用作选定值的属性名称*(默认为value)*
     */
    valueProperty = 'value';
}

export interface IDidaTabsCascaderOption {
    label: string;
    value: Region;
    children?: IDidaTabsCascaderOption[];
    order: number;
}
