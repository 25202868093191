export enum WorkOrderTypeEnum {
  Booking = 1,
  Feedback = 2
}


export enum BookingWorkOrderTypeEnum {
  GuestInfo = 1,
  CheckInOut = 2,
  RoomType = 3,
  Breakfast = 4,
  RoomCount = 5,
  Remark = 6,
  Others = 7,
}
