import {
  CouponModel
} from '../models/campaigns/coupons/coupon.model';
import { FieldGroupRestrictionTypeEnum } from '../enums/campaigns/coupons/field-group-restriction-type';
import { DateTimeRangeRestrictionModel } from '../models/campaigns/coupons/date-time-range-restriction.model';
import { CouponErrorTypes } from '../enums/campaigns/coupons/coupon-error-types.enum';
import { CouponTypeEnum } from '../enums/campaigns/coupons/coupon-type.enum';
import { FieldRestrictionModel } from '../models/campaigns/coupons/field-restriction.model';
import { FieldAvailabilityTypeEnum } from '../enums/campaigns/coupons/field-availability-type';
import { HotelCouponFilter } from '../../campaigns/shared/hotel-coupon-selector/hotel-coupon-selector.model';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { IApplicationContextStorage } from '@dida-shopping/dida-services/storage';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

export class HotelCouponHelper {
  /**
   * 检查是否可以使用优惠券功能
   * 由于这个是业务上的写死逻辑，所以这里面的校验不包括AB逻辑的判断
   * @param context
   */
  static allowUseCoupon(ctxStorage: IApplicationContextStorage, lang: LanguageType) {
    if (!ctxStorage.clientInfo) {
      return false;
    }

    if (lang !== LanguageType.zh_CN) {
      return false;
    }

    const clientInfo = ctxStorage.clientInfo;
    if (
      clientInfo.ClientAccount.Currency !== 'CNY' ||
      (clientInfo.ClientCreditAccount &&
        clientInfo.ClientCreditAccount.Currency !== 'CNY')
    ) {
      return false;
    }

    return true;
  }

  static checkDateTimeRangeRestriction(
    restriction: DateTimeRangeRestrictionModel,
    value: Date
  ): boolean {
    if (restriction == null) {
      return true;
    }

    if (
      restriction.DayOfWeekList &&
      !restriction.DayOfWeekList.includes(value.getDay())
    ) {
      return false;
    }

    switch (restriction.RestrictionType) {
      case FieldGroupRestrictionTypeEnum.Disabled:
        return true;
      case FieldGroupRestrictionTypeEnum.ApplyFromField:
        return restriction.From <= value;
      case FieldGroupRestrictionTypeEnum.ApplyToField:
        return restriction.To >= value;
      case FieldGroupRestrictionTypeEnum.ApplyBoth:
        return restriction.From <= value && value <= restriction.To;
      default:
        return false;
    }
  }

  static checkFieldRestriction(
    restriction: FieldRestrictionModel,
    value: number
  ): boolean {
    if (restriction == null) {
      return true;
    }

    switch (restriction.RestrictionType) {
      case FieldAvailabilityTypeEnum.Disabled:
        return true;
      case FieldAvailabilityTypeEnum.Inclusive:
        return restriction.Collection.includes(value);
      case FieldAvailabilityTypeEnum.Exclusive:
        return !restriction.Collection.includes(value);
      default:
        return false;
    }
  }

  /**
   * 检查优惠券是否满足特定使用条件
   * orderTime：下单日期
   * stayDate：入住日期
   * totalPriceCNY：订单总价（目前的设计是查价选CNY才能出来优惠券）
   * payChannelType：支付方式（目前未限制支付方式，但是设计图有，先定义着吧）
   */
  static check(
    coupon: CouponModel,
    filter: HotelCouponFilter
  ): CouponErrorTypes[] {
    const result: CouponErrorTypes[] = [];
    const serverNow = DateTimeHelper.serverNow;
    // 首先检查生效日期
    if (
      !HotelCouponHelper.checkDateTimeRangeRestriction(
        coupon.EffectiveTimeSetting,
        serverNow
      )
    ) {
      result.push(CouponErrorTypes.effectiveTime);
    }

    // 检查规则
    if (coupon.RestrictionRuleModel) {
      const rule = coupon.RestrictionRuleModel;

      // 检查下单时间
      if (
        filter.orderTime &&
        !HotelCouponHelper.checkDateTimeRangeRestriction(
          rule.OrderTimeRestriction,
          filter.orderTime
        )
      ) {
        result.push(CouponErrorTypes.orderTime);
      }

      // 检查入住时间
      if (filter.stayDate) {
        if (
          !HotelCouponHelper.checkDateTimeRangeRestriction(
            rule.CheckInDateRestriction,
            filter.stayDate.checkInDate
          )
        ) {
          result.push(CouponErrorTypes.checkInDate);
        }

        if (
          !HotelCouponHelper.checkDateTimeRangeRestriction(
            rule.CheckOutDateRestriction,
            filter.stayDate.checkOutDate
          )
        ) {
          result.push(CouponErrorTypes.checkOutDate);
        }
      }

      // 检查支付渠道
      if (
        filter.payChannel &&
        !HotelCouponHelper.checkFieldRestriction(
          rule.PayChannelRestriction,
          filter.payChannel
        )
      ) {
        result.push(CouponErrorTypes.payChannel);
      }
    }

    // 根据优惠券类型进行相关检查
    switch (coupon.CouponType) {
      case CouponTypeEnum.DiscountOnCertainPurchaseValue:
        {
          if (
            filter.totalPriceCNY >= 0 &&
            filter.totalPriceCNY < coupon.RewardSetting.MinimumPurchaseAmount
          ) {
            result.push(CouponErrorTypes.minimumPurchaseAmount);
          }
        }
        break;
    }

    return result.length === 0 ? null : result;
  }
}
