<div *ngIf="!inline && !plain">
    <div [ngSwitch]="type" class="alert-head">
      <span class="icon icon-status icon-status_fail" *ngSwitchCase="AlertType.Danger" ></span>
      <span class="icon icon-status icon-status_pending" *ngSwitchCase="AlertType.Pending"></span>
      <span class="icon icon-status icon-status_success" *ngSwitchCase="AlertType.Success" ></span>
    </div>
    <div class="alert-body">
        <div class="alert-content">
            <ng-container [ngTemplateOutlet]="alertContentTemplate"></ng-container>
        </div>
    </div>
</div>

<div class="alert-inline" *ngIf="inline">
    <ng-container [ngSwitch]="type">
        <span class="alert-inline__icon icon icon-status icon-status_fail" *ngSwitchCase="AlertType.Danger" ></span>
        <span class="alert-inline__icon icon icon-status icon-status_pending" *ngSwitchCase="AlertType.Pending"></span>
        <span class="alert-inline__icon icon icon-status icon-status_success" *ngSwitchCase="AlertType.Success" ></span>
    </ng-container>
    <span class="alert-inline__content">
        <ng-container [ngTemplateOutlet]="alertContentTemplate"></ng-container>
    </span>
</div>

<div class="alert-plain alert-plain_{{type}}"  *ngIf="plain">
    <ng-container [ngSwitch]="type">
        <span class="alert-plain__icon icon icon-alert icon-alert_danger" *ngSwitchCase="AlertType.Danger"></span>
        <span class="alert-plain__icon icon icon-alert icon-alert_info" *ngSwitchCase="AlertType.Info"></span>
        <span class="alert-plain__icon icon icon-alert icon-alert_warning" *ngSwitchCase="AlertType.Warning"></span>
        <span class="alert-plain__icon icon icon-alert icon-alert_success" *ngSwitchCase="AlertType.Success"></span>
        <span class="alert-plain__icon icon icon-alert icon-alert_default" *ngSwitchDefault></span>
    </ng-container>
    <span class="alert-plain__content">
        <ng-container [ngTemplateOutlet]="alertContentTemplate"></ng-container>
    </span>
    <a href="javascript:;" class="alert-plain__dismiss" (click)="dismiss.emit()" *ngIf="dismissible"
      i18n-title="@@Text_Button_Close" title="关闭">&times;</a>
</div>

<ng-template #alertContentTemplate>
    <ng-content></ng-content>
</ng-template>
