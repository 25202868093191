import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { DidaUITKGeneralEventModel } from '@dida-shopping/ngx-dida-uitk/core/event.models';
import { Icons } from '@dida-shopping/dida-services/icon';

const ICON_BY_LANG = new Map<string, Icons>([
  ['en-US', Icons.english],
  ['zh-CN', Icons.chinese],
  ['ja-JP', Icons.japanese]
]);

@Component({
  selector: 'nd-navbar-lang-switch',
  templateUrl: './lang-switch.component.html',
  styleUrls: ['./lang-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DidaUITKNavBarLanguageSwitchComponent implements OnInit, OnChanges {

  @Input() lang: LanguageType;

  @Input() canShowExtraLang: boolean;

  @Output() ndLanguageChange: EventEmitter<DidaUITKGeneralEventModel<LanguageType>>
    = new EventEmitter<DidaUITKGeneralEventModel<LanguageType>>();

  Icons = Icons;
  LanguageType = LanguageType;

  isOpen: boolean;
  iconType: Icons;

  get isCN() {
    return this.lang && (this.lang === LanguageType.zh_CN || this.lang === LanguageType._zh_CN);
  }

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (!this.lang) {
      this.lang = LanguageType.zh_CN;
    }
    this.iconType = ICON_BY_LANG.get(this.lang);
  }

  langSelected($event: LanguageType) {
    this.lang = $event;
    this.iconType = ICON_BY_LANG.get(this.lang);
    this.ndLanguageChange.emit(
      new DidaUITKGeneralEventModel<LanguageType>(this, $event)
    );
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lang) {
      this.iconType = ICON_BY_LANG.get(this.lang);
    }
  }

}
