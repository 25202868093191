import { IEventModelBase } from './event-base.model';
import { MapMessageTypeEnum } from '../enums';
import { IHotelCoordinations } from '@dida-shopping/dida-services/maps';

type MapProviders = 'google' | 'gaode';

export abstract class MapEventBase implements IEventModelBase<MapMessageTypeEnum> {

  protected constructor(
    public readonly type: MapMessageTypeEnum,
    public readonly mapProvider: MapProviders,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }
}

export class MapItemSelectEvent extends MapEventBase {
  constructor(
    public readonly coordination: IHotelCoordinations,
    mapProvider: MapProviders,
    source: string,
    time: Date
  ) {
    super(MapMessageTypeEnum.MapItemSelect, mapProvider, source, time);
  }
}

export class HotelItemSelectEvent extends MapEventBase {
  constructor(
    public readonly coordination: IHotelCoordinations,
    mapProvider: MapProviders,
    source: string,
    time: Date
  ) {
    super(MapMessageTypeEnum.HotelItemSelect, mapProvider, source, time);
  }
}

export class HotelItemHoverEvent extends MapEventBase {

  constructor(
    public readonly coordination: IHotelCoordinations,
    public readonly enter: boolean,
    mapProvider: MapProviders,
    source: string,
    time: Date
  ) {
    super(MapMessageTypeEnum.HotelItemHover, mapProvider, source, time);
  }
}
