import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, PartialObserver } from 'rxjs';
import { EventBaseEnumType } from './enums';
import {
  IEventModelBase,
  ApplicationEventBase,
  MapEventBase,
  CampaignEventBase,
  FavoriteHotelEventBase,
  ServiceEventBase,
  MessageCenterEventBase
} from './models';
import { FeedbackEventBase } from './models/feedback-event.models';
import { TicketEventBase } from './models/ticket-event.models';

export interface IDidaEventHub {
  broadcast(payload: any);

  subscribe(): Subscription;
}

const EVENT_HUB_COUNTER: {
  [eventHubName: string]: number;
} = {};

abstract class DidaEventHubBase<T extends IEventModelBase<EventBaseEnumType>>
  implements IDidaEventHub {
  public readonly events: Observable<T>;
  public readonly name: string;
  public readonly instance_id: number;
  protected constructor() {
    this.events = new Subject<T>();
    this.name = this.constructor.name;
    if (!EVENT_HUB_COUNTER[this.name]) {
      EVENT_HUB_COUNTER[this.name] = 0;
    }
    this.instance_id = EVENT_HUB_COUNTER[this.name]++;
    console.log(
      `[DidaEventHub] Created: ${this.name}, instance_id: ${this.instance_id}`
    );
  }

  broadcast(payload: T) {
    const eventsSubject = this.events as Subject<T>;
    eventsSubject.next(payload);
  }

  subscribe(observer?: PartialObserver<T>): Subscription {
    return this.events.subscribe(observer);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationEventHub extends DidaEventHubBase<
  ApplicationEventBase
> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ServiceEventHub extends DidaEventHubBase<ServiceEventBase> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class MapEventHub extends DidaEventHubBase<MapEventBase> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CampaignEventHub extends DidaEventHubBase<CampaignEventBase> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteHotelEventHub extends DidaEventHubBase<
  FavoriteHotelEventBase
> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class FeedbackEventHub extends DidaEventHubBase<FeedbackEventBase> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class MessageCenterEventHub extends DidaEventHubBase<
  MessageCenterEventBase
> {
  constructor() {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class TicketEventHub extends DidaEventHubBase<TicketEventBase> {
  constructor() {
    super();
  }
}
