import { MessageCategoryTypeEnum } from '../../../enums/message-category-type';
import { WorkOrderLogTypeEnum } from '../../../models/work-order/work-order.models';
import { Component, OnInit, Input } from '@angular/core';
import { MessageSketchModel } from '../../../models/message/message-sketch.model';
import { TicketOperations } from '../../../enums/kangaroo/ticket-operations.enum';
import { ApplicationService } from '@dida-shopping/ngx-dida-site-core';

@Component({
  selector: 'app-message-title',
  templateUrl: './message-title.component.html',
  styleUrls: ['./message-title.component.scss']
})
export class MessageTitleComponent implements OnInit {
  TicketOperations = TicketOperations;

  @Input()
  message: MessageSketchModel;
  WorkOrderLogTypeEnum = WorkOrderLogTypeEnum;
  MessageCateType = MessageCategoryTypeEnum;

  get params() {
    return this.message.Params;
  }

  get lang() {
    return  this.appService.context.lang.replace('-', '_');
  }

  constructor(
    private appService: ApplicationService
  ) {

  }

  ngOnInit() {}
}
