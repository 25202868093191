import { Injectable, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../storage/localstorage.service';
import {
  TransferPreOrderResultModel,
  TransferPreOrderRequestOption
} from '../../../../shared/models/transfer/order/transfer-pre-order.models';
import { HttpClient } from '../../../http/http-client';
import { TransferBookingConfirmOption } from '../../../../shared/models/transfer/order/transfer-booking-confirm-option.model';
import { PayChannelTypeEnum } from '../../../../shared/enums/pay-channel-type';
import { TransferBookingConfirmationContext } from '../../../../shared/models/transfer/order/transfer-booking-confirmation-context.model';
import { TransfersBookingService } from '../booking/transfers-booking.service';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';

@Injectable()
export class TransfersOrderService {
  private static orderInfo: TransferPreOrderResultModel;

  constructor(
    private localStorage: LocalStorageService,
    private httpClient: HttpClient
  ) {}

  static preOrderResultProcessor(preOrderInfo: TransferPreOrderResultModel) {
    if (preOrderInfo) {
      preOrderInfo.CreateTime = DateTimeHelper.parse(
        preOrderInfo.CreateTime,
        true
      );
      preOrderInfo.UpdateTime = DateTimeHelper.parse(
        preOrderInfo.UpdateTime,
        true
      );

      if (preOrderInfo.RequestPayload) {
        if (preOrderInfo.RequestPayload.ServiceOverview) {
          preOrderInfo.RequestPayload.ServiceOverview.TransportArrivalTime = DateTimeHelper.parse(
            preOrderInfo.RequestPayload.ServiceOverview.TransportArrivalTime,
            true
          );

          preOrderInfo.RequestPayload.ServiceOverview.TransportDepartureTime = DateTimeHelper.parse(
            preOrderInfo.RequestPayload.ServiceOverview.TransportDepartureTime,
            true
          );

          preOrderInfo.RequestPayload.ServiceOverview.ExpectedUseTime = DateTimeHelper.parse(
            preOrderInfo.RequestPayload.ServiceOverview.ExpectedUseTime,
            true
          );
        }
      }

      if (preOrderInfo.Response) {
        if (preOrderInfo.Response.CancellationPolicyList) {
          preOrderInfo.Response.CancellationPolicyList.forEach(item => {
            item.From = DateTimeHelper.parse(item.From);
          });
          if (preOrderInfo.Response.CancellationPolicyList.length > 0) {
            let firstCancelDate =
              preOrderInfo.Response.CancellationPolicyList[0].From;
            if (firstCancelDate > new Date()) {
              preOrderInfo.Response.__refundable__ = true;
            }
          }
        } else {
          preOrderInfo.Response.CancellationPolicyList = [];
        }
        preOrderInfo.Response.__refundable__ = !!preOrderInfo.Response
          .__refundable__;
        preOrderInfo.Response.PriceExpiryTime = DateTimeHelper.parse(
          preOrderInfo.Response.PriceExpiryTime,
          true
        );

        if (preOrderInfo.Response.PickUpInfo) {
          const pickUpInfo = preOrderInfo.Response.PickUpInfo;
          pickUpInfo.PickupDate = DateTimeHelper.parse(
            pickUpInfo.PickupDate,
            true
          );
          if (pickUpInfo.PickupDate && pickUpInfo.PickupTime) {
            preOrderInfo.Response.__calculated_pickup_time__ = DateTimeHelper.parse(
              `${DateTimeHelper.format(
                pickUpInfo.PickupDate,
                DateTimeHelper.DEFAULT_DATE_FORMAT
              )}T${pickUpInfo.PickupTime}`,
              true
            );
          }
        }
      }
    }
  }

  static confirmationCtxProcessor(ctx: TransferBookingConfirmationContext) {
    if (ctx) {
      if (ctx.TransferBookingDetail) {
        TransfersBookingService.bookingResultProcessor(
          ctx.TransferBookingDetail
        );
      }
    }
  }

  storePreOrderOption(option: TransferPreOrderRequestOption): string {
    let _t = new Date().getTime();
    let key = `transfer_order_${_t}`;
    let json = JSON.stringify(option);
    this.localStorage.save(key, json);
    return key;
  }

  getPreOrderOption(key: string): TransferPreOrderRequestOption {
    let option: TransferPreOrderRequestOption = null;
    let json = this.localStorage.get(key);
    if (json) {
      option = JSON.parse(json);
    }
    return option;
  }

  deactivePreOrderOption(key: string) {
    this.localStorage.clear(key);
  }

  preOrder(
    option: TransferPreOrderRequestOption,
    needStore: boolean
  ) {
    return this.httpClient.post<TransferPreOrderResultModel>(
      '/TransferBookingPreOrderApi/PreOrder',
      option,
      null,
      result => {
        // result processor
        TransfersOrderService.preOrderResultProcessor(result);
        if (needStore) {
          TransfersOrderService.orderInfo = result;
        }
      }
    );
  }

  updatePreOrderFormData(
    preOrderId: string,
    modificationToken: string,
    confirmOption: TransferBookingConfirmOption,
    needStore: boolean
  ) {
    return this.httpClient.post<TransferPreOrderResultModel>(
      '/TransferBookingPreOrderApi/UpdatePreOrderFormData',
      {
        preOrderId: preOrderId,
        modificationToken: modificationToken,
        confirmOption: confirmOption
      },
      null,
      result => {
        TransfersOrderService.preOrderResultProcessor(result);
        if (needStore) {
          TransfersOrderService.orderInfo = result;
        }
      }
    );
  }

  getPreOrderInfo(
    preOrderId: string,
    needStore: boolean
  ) {
    return this.httpClient.get<TransferPreOrderResultModel>(
      '/TransferBookingPreOrderApi/GetPreOrderInfo',
      { preOrderId },
      null,
      preorderDetail => {
        // result processor
        TransfersOrderService.preOrderResultProcessor(preorderDetail);
        if (needStore) {
          TransfersOrderService.orderInfo = preorderDetail;
        }
      }
    );
  }

  getPreOrderInfoByBookingID(
    transferBookingId: string,
    needStore: boolean
  ) {
    return this.httpClient.get<TransferPreOrderResultModel>(
      '/TransferBookingPreOrderApi/GetPreOrderInfoByBookingID',
      { transferBookingId },
      null, // timeout
      preOrderDetail => {
        // result processor
        TransfersOrderService.preOrderResultProcessor(preOrderDetail);
        if (needStore) {
          TransfersOrderService.orderInfo = preOrderDetail;
        }
      }
    );
  }

  confirmTransferBooking(
    preOrderId: string,
    modificationToken: string,
    payChannel: PayChannelTypeEnum,
    currency: string,
    totalPrice: number,
    isAcceptTerms: boolean,
    payChannelFeeRate: number = null
  ) {
    return this.httpClient.post<TransferBookingConfirmationContext>(
      '/TransferBookingProcessApi/Confirm',
      {
        preOrderId: preOrderId,
        modificationToken: modificationToken,
        payChannel: payChannel,
        payChannelFeeRate: payChannelFeeRate,
        currency: currency,
        totalPrice: totalPrice,
        isAcceptTerms: isAcceptTerms
      },
      null,
      TransfersOrderService.confirmationCtxProcessor
    );
  }
}
