import { ProductInfoModel } from './product.models';
import { RedeemContactInfoModel } from './contactInfo.models';
import { PaginationWrapper, PaginationBaseModel } from '../common/pagination-wrapper.model';


export class LotteryPrizeModel {
    ID: number;

    LotteryID: number;

    Probability: number;

    MaxCount: number;

    ProductID: number;

    ProductPrice: number;

    ProductInfo: ProductInfoModel;

    Level: number;

    Order: number;

    IsVirtual: boolean;

    Title: string;

    Desc: string;

    CreateTime: Date | string;

    UpdateTime: Date | string;

    offsetX: number;
    offsetY: number;
    angle: number;
    imageThumb: HTMLImageElement;
}

export class LotteryInfoModel {
    ID: number;

    Alias: string;

    Title: string;

    Day: number;

    StartTime: string;

    EndTime: string;

    CreateTime: Date | string;

    UpdateTime: Date | string;

    HighValue: number;

    LowValue: number;

    PrizeList: LotteryPrizeModel[];

    Lang: string;

    Timestamp: number;
}


export class LotteryDrawResultModel {
    PrizeInfo: LotteryPrizeModel;
    PrizeLogID: number;
    AvailableBalance?: number;
}

export class LotteryWinnerModel {
    ProductName: string;
    ProductValue: number;
    UserName: string;
    Cellphone: string;
    CreateTime: Date | string;
    LotteryID: number;
    Level: number;
    PrizeLogID: number;
    hidden = false;
}

export class PrizeRedeemOptions {
    AcceptAgreement: boolean;

    UserComments: string;

    Quantity: number;

    Contact: RedeemContactInfoModel;

    LotteryID: number;

    PrizeLogID: number;

    ProductID: number;
}

export class LotteryDrawLogSearchModel extends PaginationBaseModel {
    LotteryID: number;
    HasPrize?= true;
    Redeemed?= false;
    NotRedeemed?= true;
}

export class LotteryDrawLogModel {

    UpdateTime: string;
    CreateTime: string;
    RedeemRecordID: number | null;
    ProductID: number | null;
    PrizeID: number | null;
    Level: number;
    PointsValue: number;
    UserID: string;
    TransactionID: string;
    LotteryID: number;
    ID: number;
    ClientID: string;
}

