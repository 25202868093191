import { IExperimentControls } from './models';
import { ExperimentID } from './enums';
import { IApplicationExperimentInfo } from '../storage/application-context.storage';

export class ExperimentHelper {

  public static isAllowControl(control: IExperimentControls, experimentInfo: IApplicationExperimentInfo): boolean {
    let fitIncludes = true;
    let fitExcludes = true;
    if (control.includes != null) {
      fitIncludes = ExperimentHelper.hasAnyExperiments(control.includes, experimentInfo);
    }
    if (control.excludes != null) {
      fitExcludes = !ExperimentHelper.hasAnyExperiments(control.excludes, experimentInfo);
    }
    return fitIncludes && fitExcludes;
  }

  public static hasAnyExperiments(exps: ExperimentID[], experimentInfo: IApplicationExperimentInfo): boolean {
    let flag = false;
    if (experimentInfo.rolledOutExperiments != null) {
      flag = ExperimentHelper.checkExperiments(exps, experimentInfo.rolledOutExperiments);
      if (flag) {
        return true;
      }
    }
    if (experimentInfo.userExperiments != null) {
      flag = ExperimentHelper.checkExperiments(exps, experimentInfo.userExperiments);
      if (flag) {
        return true;
      }
    }
    if (experimentInfo.clientExperiments != null) {
      flag = ExperimentHelper.checkExperiments(exps, experimentInfo.clientExperiments);
      if (flag) {
        return true;
      }
    }
    return flag;
  }

  private static checkExperiments(exps: ExperimentID[], group: ExperimentID[]): boolean {
    const remaining = group.filter(x => exps.indexOf(x) >= 0);
    if (remaining.length > 0) {
      return true;
    }
    return false;
  }

}
