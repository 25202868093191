import {
  IProductSearchOptions,
  IProductInfoModel,
  IProductInfoBaseModel,
  IRedeemRecordInfoModel,
  IProductRedeemOptions,
  ICouponProductSearchOptions,
  ICouponProductModel,
  IDailyRedeemInfoModel,
  IUserRedeemOrderModel
} from '@dida-shopping/dida-services/point-market'
import { HttpServiceBase } from '@dida-shopping/dida-services/http'
import { IPaginationWrapper, DateTimeHelper } from '@dida-shopping/dida-services/common'
import { ProductCategoryEnum } from './enums'
import { UserRedeemOrderSearchModel } from './models'
import { IDateTimeRangeRestrictionModel } from '@dida-shopping/dida-services/campaign'

class PointsMarketService extends HttpServiceBase {
  constructor() {
    super()
  }

  /**
   *
   * @param options
   * @param pageNum
   * @param pageSize
   */
  getProducts(options: IProductSearchOptions, pageNum = 1, pageSize = 10) {
    options.PageNum = pageNum
    options.PageSize = pageSize
    options.WithImages = true
    return this.httpClientWrapper.post<IPaginationWrapper<IProductInfoModel>>(
      '/PointsMarketApi/GetProducts',
      { options },
      {
        resultProcessor: result => {
          result.Data.map(item => this.normalizeProduct(item))
          return result
        }
      }
    )
  }

  updateProduct(product: IProductInfoBaseModel) {
    return this.httpClientWrapper.post<IProductInfoModel>(
      '/PointsMarketApi/UpdateProduct',
      { product },
      {
        resultProcessor: this.normalizeProduct
      }
    )
  }

  redeemProduct(options: IProductRedeemOptions) {
    return this.httpClientWrapper.post<number>('/PointsMarketApi/RedeemProduct', { options })
  }

  getProductRedeemRecords(productId: string, pageNum = 1, pageSize = 10) {
    const model = { productId, pageNum, pageSize }
    return this.httpClientWrapper.post<IPaginationWrapper<IRedeemRecordInfoModel>>(
      '/PointsMarketApi/GetRedeemRecordPage',
      model
    )
  }

  getUserRedeemRecords(options: UserRedeemOrderSearchModel, pageNum: number) {
    return this.httpClientWrapper.post<IPaginationWrapper<IUserRedeemOrderModel>>('/PointsMarketApi/GetUserRedeemRecords', {
      pageNum,
      pageSize: 10,
      options
    }, {
      resultProcessor(resp) {
        resp.Data.map(item => item.SubOrderList.map(order => {
          if(order.ProductCategoryType === ProductCategoryEnum.Coupon) {
            order.ProductImageSrc = order.ProductImageSrc || 'https://dsimage.didatravel.com/points-market/coupon/coupon-general.png'
          }
        }))
        return resp
      }
    });
  }

  getTotalPointsRedeemed() {
    return this.httpClientWrapper.get<number>('/UserPointsApi/GetRedeemedPointCount')
  }

  private normalizeProduct(item: IProductInfoModel) {
    item.ImageSrc = item.ImageSrc || (item.Images && item.Images[0].ImageSrc)
    item.ImageThumbSrc = item.ImageSrc || (item.Images && item.Images[0].ImageThumbSrc)
    return item
  }


  getCouponProducts(
    options: ICouponProductSearchOptions
  ) {
    return this.httpClientWrapper.post<IPaginationWrapper<ICouponProductModel>>(
      '/CouponMarketApi/GetProducts',
      { options },
      {
        resultProcessor:  result => {
          if (result && result.Data) {
            result.Data.forEach(item => {
              this.processDateTimeRangeRestrictionField(
                item.EffectiveTimeSetting
              );
              if (item.RestrictionRuleModel) {
                this.processDateTimeRangeRestrictionField(
                  item.RestrictionRuleModel.CheckInDateRestriction
                );
                this.processDateTimeRangeRestrictionField(
                  item.RestrictionRuleModel.CheckOutDateRestriction
                );
                this.processDateTimeRangeRestrictionField(
                  item.RestrictionRuleModel.OrderTimeRestriction
                );
              }
            });
          }
          return result
        }
      },

    );
  }

  purchaseCoupon(options: IProductRedeemOptions) {
    return this.httpClientWrapper.post<number>('/CouponMarketApi/PurchaseCoupon', { options });
  }

  /**
   * 兑换签到积分
   */
  redeemCheckinPoints() {
    return this.httpClientWrapper.post<IDailyRedeemInfoModel>('/UserPointsApi/RedeemSignInPoints', {});
  }

  getCheckinPointsInfo() {
    return this.httpClientWrapper.get<IDailyRedeemInfoModel>('/UserPointsApi/GetCheckinPointsInfo');
  }

  private processDateTimeRangeRestrictionField(
    restriction: IDateTimeRangeRestrictionModel
  ) {
    if (restriction) {
      restriction.From = DateTimeHelper.parse(restriction.From, true);
      restriction.To = DateTimeHelper.parse(restriction.To, true);
    }
  }

}

export const pointsMarketService = new PointsMarketService()
