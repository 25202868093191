import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaUITKHighlightPipe } from './highlight/highlight.pipe';



@NgModule({
  declarations: [DidaUITKHighlightPipe],
  imports: [
    CommonModule
  ],
  exports: [DidaUITKHighlightPipe]
})
export class DidaUITKPipesModule { }
