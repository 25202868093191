import { ActivatedRouteSnapshot } from '@angular/router';
import { IRouteDataModel } from '../app-routing-data.model';

export class RouteDataHelper {

  static getComputedRouteData(snapshot: ActivatedRouteSnapshot, result = []): IRouteDataModel {
    if (snapshot.data) {
      result.push(snapshot.data);
    }
    if (snapshot.parent) {
      RouteDataHelper.getComputedRouteData(snapshot.parent, result);
    }
    return Object.assign({}, ...result.reverse());
  }
}
