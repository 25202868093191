import { ModuleWithProviders, NgModule, SecurityContext } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DidaUITKMarkdownDocumentComponent } from './md-doc.component';
import { CommonModule } from '@angular/common';
import { DidaUITKLoadingModule } from '@dida-shopping/ngx-dida-uitk/loading';

export * from './md-doc.component';

@NgModule({
  declarations: [
    DidaUITKMarkdownDocumentComponent
  ],
  imports: [
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      sanitize: SecurityContext.NONE,
    }),
    CommonModule,
    DidaUITKLoadingModule
  ],
  exports: [
    DidaUITKMarkdownDocumentComponent,
    MarkdownModule,
  ]
})
export class DidaUITKMarkdownDocumentModule {

}
