
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
import { IPaginationWrapper } from '../common';
import { HttpServiceBase } from '../http';
import { SuggestionQueryPayload } from './suggestion-query-payload.model';
import { SuggestionItem } from './suggestion-item.model';
import { SuggestionSearchHelper } from './suggestion-search-helper';

class SuggestionService extends HttpServiceBase {
  constructor() {
    super('SuggestionService');
   }

  /**
   * 建议搜索
   * @param query 搜索条件
   */
  search(
    payload: SuggestionQueryPayload
  ) {
    return this.httpClientWrapper.post<IPaginationWrapper<SuggestionItem>>(
      '/SuggestionApi/SearchV2',
      payload,
      {
        resultProcessor: result => {
          if (result.Data) {
            result.Data.forEach(x => this.processSuggestionItem(x));
          }
          return result
        }
      }
    );
  }

  /**
   * 建议搜索
   * @param payloads 搜索条件
   */
  multiplSearch(
    payloads: SuggestionQueryPayload[]
  ) {
    return this.httpClientWrapper.post<IPaginationWrapper<SuggestionItem>[]>(
      '/SuggestionApi/MultiplSearch',
      payloads,
      {
        resultProcessor:  result => {
          result.forEach(r => {
            if (r.Data) {
              r.Data.forEach(x => {
                this.processSuggestionItem(x);
              });
            }
          });
          return result
        }
      },

    );
  }

  /**
   * 搜索指定区域
   * @param regionID 区域ID
   */
  searchRegion(regionID: string) {
    const regionQuery = SuggestionSearchHelper.createSuggestionQueryPayload(
      null,
      SuggestionSearchHelper.REGION_SEARCH_BY_ID_CATEGORIES,
      1,
      [regionID]
    );
    return this.httpClientWrapper
      .post<SuggestionItem>(
        '/SuggestionApi/SearchV2',
        regionQuery,
        {
          resultProcessor: result => {
            if (result.Data) {
              result.Data.forEach(x => this.processSuggestionItem(x));
            }
            return result.Data.length === 1 ? result.Data[0] : null;
          }
        }
      );
  }


  private processSuggestionItem(item: SuggestionItem) {
    if (item) {
      item.ID = item.IDString;
      if (item.Metadata) {
        if (item.Metadata.Location) {
          item.Metadata.Location = new GeoCoordinate(item.Metadata.Location.Latitude, item.Metadata.Location.Longitude);
        }
      }
    }
  }
}

export const suggestionService = new SuggestionService()
