import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaFormsetComponent } from './dida-formset.component';
@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DidaFormsetComponent
  ], exports: [
    DidaFormsetComponent
  ]
})
export class DidaFormsetModule { }
