import {
    JdProductPoolModel,
    ProductVerifyModel,
    JdMarketUserWishesModel,
    ChangeWishesOption } from '../../../shared/models/points/jdmarket/JdMarket.models';
import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { LoggerService } from '../../logger/logger.service';
import { RedeemRecordStatusTypeEnum, UserRedeemRecordModel } from '../../../shared/models/points/redeem.models';
import { RedeemContactInfoModel } from '../../../shared/models/points/contactInfo.models';
import { RedeemRecordInfoModel, ProductRedeemOptions } from '../../../shared/models/points/redeem.models';
import { ProductInfoModel, ProductSearchOptions} from '../../../shared/models/points/product.models';
/**
 * 积分商城京东商品兑换相关
 *
 * @export
 * @class JdmarketService
 */
@Injectable()
export class JdmarketService {
    constructor(private logger: LoggerService, private httpClient: HttpClient) {

    }

    getProductRedeemRecords(productId: string, pageNum: number = 1, pageSize: number = 10) {
        let model = { productId, pageNum, pageSize };
        return this.httpClient.post<PaginationWrapper<RedeemRecordInfoModel>>('/JdMarketApi/GetRedeemRecordPage', model);
    }

    getUserRedeemRecords(pageNum = 1, pageSize = 10) {
        let model = {
            Statuses: [RedeemRecordStatusTypeEnum.PendingForConfirm,
            RedeemRecordStatusTypeEnum.Confirmed, RedeemRecordStatusTypeEnum.Failed],
            PageSize: pageSize,
            PageNum: pageNum
        };
        return this.httpClient.post<PaginationWrapper<UserRedeemRecordModel>>('/JdMarketApi/GetUserRedeemRecords', model);
    }

    getProductPool() {
        return this.httpClient.get<JdProductPoolModel[][]>('/JdMarketApi/GetProductPool');
    }

    /**
   *
   * @param model
   * @param pageNum
   * @param pageSize
   */
    getProducts(model: ProductSearchOptions, pageNum = 1, pageSize = 10) {
        model.PageNum = pageNum;
        model.PageSize = pageSize;
        return this.httpClient.post<PaginationWrapper<ProductInfoModel>>('/JdMarketApi/GetProducts', model);
    }

    redeemProduct(model: ProductRedeemOptions) {
        return this.httpClient.post<number>('/JdMarketApi/RedeemProduct', model);
    }

    getExpressPoints(options: ProductRedeemOptions) {
        return this.httpClient.post<number>('/JdMarketApi/GetExpressPoints', options);
    }

    checkProductState(options: ProductRedeemOptions) {
        return this.httpClient.post<ProductVerifyModel[]>('/JdMarketApi/GetProductState', options);
    }
    addWish(model: ProductVerifyModel) {
        return this.httpClient.post('/JdMarketApi/AddWish', model);
    }

    delWishes(ids: number[]) {
        return this.httpClient.post('/JdMarketApi/DeleteUserWishes', ids);
    }


    changeWishProductQuatity(options: ChangeWishesOption) {
        return this.httpClient.post<ProductVerifyModel>('/JdMarketApi/ChangeWishQuantity', options);
    }

    getWishes(options: RedeemContactInfoModel) {
        return this.httpClient.get<JdMarketUserWishesModel[]>('/JdMarketApi/GetUserWishes', options);
    }
    getWishesCount() {
        return this.httpClient.get<number>('/JdMarketApi/GetWishesCount');
    }

}
