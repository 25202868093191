<div class="debug-login-form">
  <ng-container [ngSwitch]="reason">
    <p *ngSwitchCase="ConditionCheckError.ClientRegistering">This account is not certified so that you cannot visit the current page, please login with another account.</p>
    <p *ngSwitchCase="ConditionCheckError.AccountLocked">This account has been lock so that you cannot visit the current page, please login with another account.</p>
    <p *ngSwitchDefault>The application need you to login before routing into sub-modules.</p>
  </ng-container>

  <form nz-form [formGroup]="loginForm" (ngSubmit)="login()">
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input your username!">
        <nz-input-group>
          <input type="text" nz-input formControlName="userName" placeholder="Username" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="pwdErrorTips">
        <nz-input-group>
          <input type="password" nz-input formControlName="password" placeholder="Password" />
          <ng-template #pwdErrorTips let-control>
            <ng-container *ngIf="control.errors.required">Please input your Password!</ng-container>
            <ng-container *ngIf="control.errors.login">{{ error.message }}</ng-container>
          </ng-template>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button class="login-form-button" [nzType]="'primary'" [nzLoading]="loading">Log in</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
