export enum HotelBookingStatusType {
    PreBook = 0,
    Booked = 1,
    Confirmed = 2,
    Canceled = 3,
    Failed = 4,
    Pending = 5,
    OnRequest = 6,
    Unsettled = 99,
    NoSpecified = -1,
}
