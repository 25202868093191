
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoggerService } from '../../logger/logger.service';
import { HttpClient } from '../../http/http-client';
import { UserAddressModel } from '../../../shared/models/user/user-address.models';
import { JdAddress } from '../../../shared/models/points/jdmarket/JdMarket.models';
import { map } from 'rxjs/operators';

@Injectable()
export class AddressService {

  private _address_list: UserAddressModel[];
  constructor(private logger: LoggerService, private httpClient: HttpClient) { }


  getAllAddress() {
    return this.httpClient.get<JdAddress[]>('/JdMarketApi/GetAllAddress');
}

  // User Addresses
  getAddresses(force = false, pageNum = 1, pageSize = 10) {
    if (!force && this._address_list && this._address_list.length) {
      return observableOf(this._address_list);
    }

    return this.httpClient.post<UserAddressModel[]>('/UserAddressApi/Get', { pageNum, pageSize }).pipe(
      map(resp => {
        this._address_list = resp;
        return resp;
      })
    );
  }

  deleteAddress(addr: UserAddressModel) {
    return this.httpClient.post<boolean>('/UserAddressApi/Delete', { addr });
  }

  createAddress(addr: UserAddressModel) {
    return this.httpClient.post<UserAddressModel>('/UserAddressApi/Add', { addr });
  }

  updateAddress(addr: UserAddressModel) {
    return this.httpClient.post<UserAddressModel>('/UserAddressApi/Update', { addr });
  }
}
