import { CalendarModeEnum } from '../../models/EnumDefine.Model';

export class CalendarOptions {
    FromDate: Date;
    EndDate: Date;
    MinDate: Date; // 可选的最小值
    MaxDate: Date; // 可选的最大值
    MinDays: number;
    MaxDays: number;
    DisplayCalendarCount = 2; // 显示多个日历
    Mode: CalendarModeEnum = CalendarModeEnum.DateRange;
}
