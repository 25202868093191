import { NgModule } from '@angular/core';
import { DidaUITKLoadingComponent } from './loading.component';
import { DidaUITKLoadingSubscriptDirective } from './loading-subscript.directive';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    DidaUITKLoadingSubscriptDirective,
    DidaUITKLoadingComponent
  ],
  imports:[
    CommonModule,
    NzModalModule,
  ],
  exports: [
    DidaUITKLoadingComponent,
    DidaUITKLoadingSubscriptDirective
  ],
  providers:[],
})
export class DidaUITKLoadingModule {

}
