import { DateTimeHelper } from './date-time.helper';

export class JsonHelper {
  static parseJsonWithDate(json: any): any {
    const result = JSON.parse(json, (key: any, value: any) => {
      if (typeof value === 'string') {
        const date = DateTimeHelper.parse(value, true);
        if (date !== null) {
          return date;
        }
      }
      return value;
    });
    return result;
  }
}
