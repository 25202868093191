import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Directives
import { DIDA_SHARED_DIRECTIVES, ScrollFixedTopDirective } from './directives';

@NgModule({
  declarations: [
    ScrollFixedTopDirective,
    DIDA_SHARED_DIRECTIVES
  ],
  imports: [CommonModule],
  exports: [
    ScrollFixedTopDirective,
    DIDA_SHARED_DIRECTIVES,
  ]
})
export class DirectivesModule {}
