export class Occupancy {
    RoomNum = 1;
    AdultCount = 2;
    ChildCount = 0;
    ChildAgeList: number[] = [];
}


/**
 * DidaOccupancy配置项目
 */
export enum GuestType {
  /**
   * 房间
   */
  Room,
  /**
   *成人
   */
  Adult,
  /**
   * 儿童
   */
  Chlid
}

export class RoomOccupancyConfig {
  /**
   * 最大房间数*(默认为`5`)*
   */
  maxRoomCount?= 5;

  /**
   * 最大成人数*(默认为`8`)*
   */
  maxAdultCount?= 10;
  /**
   * 最大儿童数*(默认为`3`)*
   */
  maxChlidCount = 3;

  /**
   * 最小房间数*(默认为`1`)*
   */
  minRoomCount = 1;

  /**
   * 最小成人数*(默认为`1`)*
   */
  minAdultCount = 1;
  /**
   * 最小儿童数*(默认为`0`)*
   */
  minChlidCount = 0;

  /**
   * 默认展示RoomCount
   */
  showRoomCount?= true;

  showRoomGuest?= true;

  showChildList?= true;

  customizeLabel?= false;

  style?: 'bordered';

  /**
   * Ignore multiple person notice of room occupancy config
   */
  ignoreMultiplePersonNotice = false;

  popoverOffsetX = 0;
  popoverOffsetY = 4
}


export class OccupancyPickerDataModel {
  roomCount: number
  occupancy: Occupancy
}
