export enum UploadErrorTypeEnum {
  Success = 0,
  MaxSize,
  MaxCount,
  ImageRatio,
  Network,
  Default
}

export interface UploadConfigModel {
  maxCount: number;
  maxSize: number;
  ratio: number;
  path: string;
  disableClass: string;
  accept: string;
  showStatusMessage: boolean;
}

export class UploadError {
  Code: UploadErrorTypeEnum;
  Message: any;
  Data: any;
  FileName: string;
  constructor(code: UploadErrorTypeEnum, msg?: any, data?: any) {
    this.Code = code;
    this.Message = msg;
    this.Data = data;
  }
}
