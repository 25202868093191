import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

type FeatureGuideEventType = 'detect' | 'default'

@Injectable({
  providedIn: 'root'
})
export class FeatureGuideService {

  onDetect = new ReplaySubject(1)

  constructor() { }

  trigger(type: FeatureGuideEventType) {
    console.log('FeatureGuideService', type)
    switch(type) {
      default:
        this.onDetect.next()
    }
  }
}
