import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaUITKRoomOccupancyPickerComponent } from './room-occupancy-picker.component';
import { DidaUITKDirectiveModule } from '../directives';
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { DidaUITKIconModule } from '../icons';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormsModule } from '@angular/forms';
import { DidaUITKRoomOccupancyExtraInfoDirective } from './room-occupancy-extra-info.directive';

@NgModule({
  declarations: [DidaUITKRoomOccupancyPickerComponent, DidaUITKRoomOccupancyExtraInfoDirective],
  imports: [
    CommonModule,
    FormsModule,
    DidaUITKDirectiveModule,
    NzPopoverModule,
    NzSelectModule,
    NzGridModule,
    DidaUITKIconModule
  ],
  exports: [
    DidaUITKRoomOccupancyPickerComponent,
    DidaUITKRoomOccupancyExtraInfoDirective
  ]
})
export class DidaUITKRoomOccupancyPickerModule { }
