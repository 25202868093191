import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';


import { DidaUITKDirectiveModule } from '../directives/directive.module';
import { DidaUITKIconModule } from '../icons';
import { DidaUITKSuggestionTypeaheadModule } from '../suggestion-typeahead';
import { DidaUITKNavBarComponent } from './navbar.component';
import { DidaUITKCalendarModule } from '@dida-shopping/ngx-dida-uitk/calendar';
import { DidaUITKNavBarCurrencySelectComponent } from './currency-select/currency-select.component';
import { DidaUITKNavBarLanguageSwitchComponent } from './lang-switch/lang-switch.component';
import { DidaUITKNavBarShortcutsComponent } from './shortcuts/shortcuts.component';
import { DidaUITKNavBarUserProfileComponent } from './user-profile/user-profile.component';
import { DidaUITKNavBarSearchPanelComponent } from './search-panel/search-panel.component';
import { DidaUITKNavBarNoticeModalWrapperComponent } from './notice-modal-wrapper/notice-modal-wrapper.component';
import { DidaUITKNavBarCampaignModalComponent } from './notice-modal-wrapper/campaign-modal/campaign-modal.component';
import { DidaUITKMarkdownDocumentModule } from '@dida-shopping/ngx-dida-uitk/md-doc';
import { DidaUITKNavFeatureGuideComponent } from './notice-modal-wrapper/feature-guide/feature-guide.component';

export * from './navbar.models';
export * from './navbar.component';
export * from './currency-select/currency-select.component';
export * from './lang-switch/lang-switch.component';


const ngZorroConfig: NzConfig = {
  message: { nzTop: 74 },
  notification: { nzTop: 74 }
};

@NgModule({
  declarations: [
    DidaUITKNavBarCurrencySelectComponent,
    DidaUITKNavBarLanguageSwitchComponent,
    DidaUITKNavBarShortcutsComponent,
    DidaUITKNavBarUserProfileComponent,
    DidaUITKNavBarSearchPanelComponent,
    DidaUITKNavBarComponent,
    DidaUITKNavBarCampaignModalComponent,
    DidaUITKNavBarNoticeModalWrapperComponent,
    DidaUITKNavFeatureGuideComponent
  ],
  exports: [
    DidaUITKNavBarComponent,
    DidaUITKNavBarCurrencySelectComponent,
    DidaUITKNavBarLanguageSwitchComponent,
    DidaUITKNavBarNoticeModalWrapperComponent,
    DidaUITKNavBarSearchPanelComponent
  ],
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    NzIconModule,
    NzMenuModule,
    NzPopoverModule,
    NzFormModule,
    NzInputModule,
    NzGridModule,
    NzButtonModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSelectModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzNoAnimationModule,
    NzModalModule,
    NzCarouselModule,
    NzMessageModule,
    NzPopoverModule,
    DidaUITKDirectiveModule,
    DidaUITKIconModule,
    DidaUITKSuggestionTypeaheadModule,
    DidaUITKCalendarModule,
    NzOutletModule,
    DidaUITKMarkdownDocumentModule,
  ],
  entryComponents: [
    DidaUITKNavBarCampaignModalComponent,
    DidaUITKNavBarSearchPanelComponent,
  ],
  providers: [
    { // The FactoryProvider
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    }
  ]
})

export class DidaUITKNavBarModule {

}
