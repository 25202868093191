import { Nationality, NationalityDirectoryStruct } from './models';
import { LanguageType } from '../i18n';

export const AvailableNationalities: Array<Nationality> = [

  new Nationality('CN', 'Chinese (Mainland)', true).addLangSetting(LanguageType.zh_CN, '中国大陆'),
  new Nationality('HK', 'Chinese (Hong Kong)', true).addLangSetting(LanguageType.zh_CN, '中国香港'),
  new Nationality('TW', 'Chinese (Taiwan)', true).addLangSetting(LanguageType.zh_CN, '中国台湾'),
  new Nationality('MO', 'Chinese (Macao)', true).addLangSetting(LanguageType.zh_CN, '中国澳门'),
  new Nationality('US', 'American', true).addLangSetting(LanguageType.zh_CN, '美国'),
  new Nationality('ES', 'Spanish', true).addLangSetting(LanguageType.zh_CN, '西班牙'),
  new Nationality('GB', 'British', true).addLangSetting(LanguageType.zh_CN, '英国'),
  new Nationality('AU', 'Australian', true).addLangSetting(LanguageType.zh_CN, '澳大利亚'),
  new Nationality('JP', 'Japanese', true).addLangSetting(LanguageType.zh_CN, '日本'),
  new Nationality('KR', 'Korean', true).addLangSetting(LanguageType.zh_CN, '韩国'),

  new Nationality('AE', 'Emirati', false).addLangSetting(LanguageType.zh_CN, '阿联酋'),
  new Nationality('AO', 'Angolan', false).addLangSetting(LanguageType.zh_CN, '安哥拉'),
  new Nationality('AR', 'Argentine', false).addLangSetting(LanguageType.zh_CN, '阿根廷'),
  new Nationality('AT', 'Austrian', false).addLangSetting(LanguageType.zh_CN, '奥地利'),
  new Nationality('BB', 'Barbadosian', false).addLangSetting(LanguageType.zh_CN, '巴巴多斯'),
  new Nationality('BE', 'Belgian', false).addLangSetting(LanguageType.zh_CN, '比利时'),
  new Nationality('BR', 'Brazilian', false).addLangSetting(LanguageType.zh_CN, '巴西'),
  new Nationality('CA', 'Canadian', false).addLangSetting(LanguageType.zh_CN, '加拿大'),
  new Nationality('CH', 'Swiss', false).addLangSetting(LanguageType.zh_CN, '瑞士'),
  new Nationality('CL', 'Chilean', false).addLangSetting(LanguageType.zh_CN, '智利'),
  new Nationality('CU', 'Cuban', false).addLangSetting(LanguageType.zh_CN, '古巴'),
  new Nationality('CZ', 'Czech', false).addLangSetting(LanguageType.zh_CN, '捷克'),
  new Nationality('DE', 'German', false).addLangSetting(LanguageType.zh_CN, '德国'),
  new Nationality('DK', 'Danish', false).addLangSetting(LanguageType.zh_CN, '丹麦'),
  new Nationality('EG', 'Egyptian', false).addLangSetting(LanguageType.zh_CN, '埃及'),
  new Nationality('FI', 'Finnish', false).addLangSetting(LanguageType.zh_CN, '芬兰'),
  new Nationality('FR', 'French', false).addLangSetting(LanguageType.zh_CN, '法国'),
  new Nationality('GR', 'Greek', false).addLangSetting(LanguageType.zh_CN, '希腊'),
  new Nationality('HU', 'Hungarian', false).addLangSetting(LanguageType.zh_CN, '匈牙利'),
  new Nationality('ID', 'Indonesian', false).addLangSetting(LanguageType.zh_CN, '印度尼西亚'),
  new Nationality('IE', 'Irish', false).addLangSetting(LanguageType.zh_CN, '爱尔兰'),
  new Nationality('IL', 'Israeli', false).addLangSetting(LanguageType.zh_CN, '以色列'),
  new Nationality('IN', 'Indian', false).addLangSetting(LanguageType.zh_CN, '印度'),
  new Nationality('IQ', 'Iraqi', false).addLangSetting(LanguageType.zh_CN, '伊拉克'),
  new Nationality('IR', 'Iranian', false).addLangSetting(LanguageType.zh_CN, '伊朗'),
  new Nationality('IT', 'Italian', false).addLangSetting(LanguageType.zh_CN, '意大利'),
  new Nationality('KH', 'Cambodian', false).addLangSetting(LanguageType.zh_CN, '柬埔寨'),
  new Nationality('LK', 'Sri Lankan', false).addLangSetting(LanguageType.zh_CN, '斯里兰卡'),
  new Nationality('MM', 'Myanmar', false).addLangSetting(LanguageType.zh_CN, '缅甸'),
  new Nationality('MU', 'Mauritius', false).addLangSetting(LanguageType.zh_CN, '毛里求斯'),
  new Nationality('MX', 'Mexican', false).addLangSetting(LanguageType.zh_CN, '墨西哥'),
  new Nationality('MY', 'Malaysian', false).addLangSetting(LanguageType.zh_CN, '马来西亚'),
  new Nationality('NL', 'Dutch', false).addLangSetting(LanguageType.zh_CN, '荷兰'),
  new Nationality('NZ', 'New Zealand', false).addLangSetting(LanguageType.zh_CN, '新西兰'),
  new Nationality('PH', 'Philippine', false).addLangSetting(LanguageType.zh_CN, '菲律宾'),
  new Nationality('PK', 'Pakistani', false).addLangSetting(LanguageType.zh_CN, '巴基斯坦'),
  new Nationality('PL', 'Polish', false).addLangSetting(LanguageType.zh_CN, '波兰'),
  new Nationality('PT', 'Portuguese', false).addLangSetting(LanguageType.zh_CN, '葡萄牙'),
  new Nationality('RO', 'Romanian', false).addLangSetting(LanguageType.zh_CN, '罗马尼亚'),
  new Nationality('RU', 'Russian', false).addLangSetting(LanguageType.zh_CN, '俄罗斯'),
  new Nationality('SA', 'Saudi Arabian', false).addLangSetting(LanguageType.zh_CN, '沙特阿拉伯'),
  new Nationality('SE', 'Swedish', false).addLangSetting(LanguageType.zh_CN, '瑞典'),
  new Nationality('SG', 'Singaporean', false).addLangSetting(LanguageType.zh_CN, '新加坡'),
  new Nationality('SI', 'Slovenia', false).addLangSetting(LanguageType.zh_CN, '斯洛文尼亚'),
  new Nationality('TH', 'Thai', false).addLangSetting(LanguageType.zh_CN, '泰国'),
  new Nationality('TR', 'Turkish', false).addLangSetting(LanguageType.zh_CN, '土耳其'),
  new Nationality('UA', 'Ukrainian', false).addLangSetting(LanguageType.zh_CN, '乌克兰'),
  new Nationality('VN', 'Vietnamese', false).addLangSetting(LanguageType.zh_CN, '越南'),
  new Nationality('ZA', 'South African', false).addLangSetting(LanguageType.zh_CN, '南非'),
  new Nationality('NO', 'Norse', false).addLangSetting(LanguageType.zh_CN, '挪威'),
  new Nationality('AD', 'Andorra', false).addLangSetting(LanguageType.zh_CN, '安道尔'),
  new Nationality('AF', 'Afghan', false).addLangSetting(LanguageType.zh_CN, '阿富汗'),
  new Nationality('AG', 'Antigua and barbuda', false).addLangSetting(LanguageType.zh_CN, '安提瓜和巴布达'),
  new Nationality('AI', 'Anguilla', false).addLangSetting(LanguageType.zh_CN, '安圭拉'),
  new Nationality('AL', 'Albanian', false).addLangSetting(LanguageType.zh_CN, '阿尔巴尼亚'),
  new Nationality('AM', 'Armenian', false).addLangSetting(LanguageType.zh_CN, '亚美尼亚'),
  new Nationality('AN', 'Of the Netherlands Antilles', false).addLangSetting(LanguageType.zh_CN, '荷属安的列斯群岛'),
  new Nationality('AS', 'American Samoan', false).addLangSetting(LanguageType.zh_CN, '美属萨摩亚'),
  new Nationality('AW', 'Aruba', false).addLangSetting(LanguageType.zh_CN, '阿鲁巴'),
  new Nationality('AZ', 'azerbaijani', false).addLangSetting(LanguageType.zh_CN, '阿塞拜疆'),
  new Nationality('BA', 'Bosnia and herzegovina', false).addLangSetting(LanguageType.zh_CN, '波斯尼亚和黑塞哥维那'),
  new Nationality('BD', 'bangladeshi', false).addLangSetting(LanguageType.zh_CN, '孟加拉国'),
  new Nationality('BF', 'Burkina faso', false).addLangSetting(LanguageType.zh_CN, '布基纳法索'),
  new Nationality('BG', 'Bulgarian', false).addLangSetting(LanguageType.zh_CN, '保加利亚'),
  new Nationality('BH', 'The people of bahrain', false).addLangSetting(LanguageType.zh_CN, '巴林'),
  new Nationality('BI', 'burundian', false).addLangSetting(LanguageType.zh_CN, '布隆迪'),
  new Nationality('BJ', 'Benin people', false).addLangSetting(LanguageType.zh_CN, '贝宁'),
  new Nationality('BN', 'The brunei people', false).addLangSetting(LanguageType.zh_CN, '文莱'),
  new Nationality('BO', 'Bolivian', false).addLangSetting(LanguageType.zh_CN, '玻利维亚'),
  new Nationality('BQ', 'Dutch Caribbean', false).addLangSetting(LanguageType.zh_CN, '荷兰加勒比区'),
  new Nationality('BS', 'bahamian', false).addLangSetting(LanguageType.zh_CN, '巴哈马'),
  new Nationality('BT', 'bhutanese', false).addLangSetting(LanguageType.zh_CN, '不丹'),
  new Nationality('BW', 'Botswana', false).addLangSetting(LanguageType.zh_CN, '博茨瓦纳'),
  new Nationality('BY', 'byelorussian', false).addLangSetting(LanguageType.zh_CN, '白俄罗斯'),
  new Nationality('BZ', 'belizean', false).addLangSetting(LanguageType.zh_CN, '伯利兹'),
  new Nationality('CC', 'The cocos islands', false).addLangSetting(LanguageType.zh_CN, '科科斯群岛'),
  new Nationality('CD', 'The democratic republic of Congo', false).addLangSetting(LanguageType.zh_CN, '刚果（金）'),
  new Nationality('CF', 'Of the central African republic', false).addLangSetting(LanguageType.zh_CN, '中非共和国'),
  new Nationality('CG', 'The people of the republic of Congo', false).addLangSetting(LanguageType.zh_CN, '刚果（布）'),
  new Nationality('CI', 'ivorian', false).addLangSetting(LanguageType.zh_CN, '科特迪瓦'),
  new Nationality('CK', 'Cook islands', false).addLangSetting(LanguageType.zh_CN, '库克群岛'),
  new Nationality('CM', 'cameroonian', false).addLangSetting(LanguageType.zh_CN, '喀麦隆'),
  new Nationality('CO', 'Colombian', false).addLangSetting(LanguageType.zh_CN, '哥伦比亚'),
  new Nationality('CR', 'Costa ricans', false).addLangSetting(LanguageType.zh_CN, '哥斯达黎加'),
  new Nationality('CV', 'The cape verdeans', false).addLangSetting(LanguageType.zh_CN, '佛得角'),
  new Nationality('CW', 'Curacao', false).addLangSetting(LanguageType.zh_CN, '库拉索'),
  new Nationality('CY', 'Cypriot', false).addLangSetting(LanguageType.zh_CN, '塞浦路斯'),
  new Nationality('DJ', 'Djibouti', false).addLangSetting(LanguageType.zh_CN, '吉布提'),
  new Nationality('DO', 'dominicans', false).addLangSetting(LanguageType.zh_CN, '多米尼加'),
  new Nationality('DZ', 'Algerian', false).addLangSetting(LanguageType.zh_CN, '阿尔及利亚'),
  new Nationality('EC', 'Ecuadorian', false).addLangSetting(LanguageType.zh_CN, '厄瓜多尔'),
  new Nationality('EE', 'esthonian', false).addLangSetting(LanguageType.zh_CN, '爱沙尼亚'),
  new Nationality('EH', 'Western Sahara', false).addLangSetting(LanguageType.zh_CN, '西撒哈拉'),
  new Nationality('ER', 'Eritrean', false).addLangSetting(LanguageType.zh_CN, '厄立特里亚'),
  new Nationality('ET', 'Ethiopian', false).addLangSetting(LanguageType.zh_CN, '埃塞俄比亚'),
  new Nationality('FJ', 'Fijian', false).addLangSetting(LanguageType.zh_CN, '斐济'),
  new Nationality('FK', 'Falklands', false).addLangSetting(LanguageType.zh_CN, '福克兰群岛'),
  new Nationality('FM', 'Micronesian', false).addLangSetting(LanguageType.zh_CN, '密克罗尼西亚'),
  new Nationality('FO', 'Faroese', false).addLangSetting(LanguageType.zh_CN, '法罗群岛'),
  new Nationality('FW', 'French west Indies', false).addLangSetting(LanguageType.zh_CN, '法属西印度群岛'),
  new Nationality('GA', 'The gabonese', false).addLangSetting(LanguageType.zh_CN, '加蓬'),
  new Nationality('GD', 'Grenada', false).addLangSetting(LanguageType.zh_CN, '格林纳达'),
  new Nationality('GE', 'Georgian', false).addLangSetting(LanguageType.zh_CN, '格鲁吉亚'),
  new Nationality('GF', 'French Guiana', false).addLangSetting(LanguageType.zh_CN, '法属圭亚那'),
  new Nationality('GG', 'Guernsey', false).addLangSetting(LanguageType.zh_CN, '格恩西岛'),
  new Nationality('GH', 'The ghanaian', false).addLangSetting(LanguageType.zh_CN, '加纳'),
  new Nationality('GI', 'Gibraltarian', false).addLangSetting(LanguageType.zh_CN, '直布罗陀'),
  new Nationality('GL', 'greenlandic', false).addLangSetting(LanguageType.zh_CN, '格陵兰'),
  new Nationality('GM', 'gambian', false).addLangSetting(LanguageType.zh_CN, '冈比亚'),
  new Nationality('GN', 'guinean', false).addLangSetting(LanguageType.zh_CN, '几内亚'),
  new Nationality('GP', 'Guadeloupe', false).addLangSetting(LanguageType.zh_CN, '瓜德罗普岛'),
  new Nationality('GQ', 'Equatorial guinean', false).addLangSetting(LanguageType.zh_CN, '赤道几内亚'),
  new Nationality('GS', 'South Georgia', false).addLangSetting(LanguageType.zh_CN, '南乔治亚岛'),
  new Nationality('GT', 'Guatemalan', false).addLangSetting(LanguageType.zh_CN, '危地马拉'),
  new Nationality('GU', 'People of Guam', false).addLangSetting(LanguageType.zh_CN, '关岛'),
  new Nationality('GW', 'Guinea-bissau', false).addLangSetting(LanguageType.zh_CN, '几内亚比绍'),
  new Nationality('GY', 'Guyanese', false).addLangSetting(LanguageType.zh_CN, '圭亚那'),
  new Nationality('HM', 'The people of hurd and MacDonald', false).addLangSetting(LanguageType.zh_CN, '赫德岛和麦克唐纳群岛'),
  new Nationality('HN', 'honduran', false).addLangSetting(LanguageType.zh_CN, '洪都拉斯'),
  new Nationality('HR', 'Croatian', false).addLangSetting(LanguageType.zh_CN, '克罗地亚'),
  new Nationality('HT', 'Haitian', false).addLangSetting(LanguageType.zh_CN, '海地'),
  new Nationality('IO', 'British Indian Ocean territory', false).addLangSetting(LanguageType.zh_CN, '英属印度洋领地'),
  new Nationality('IS', 'Icelanders', false).addLangSetting(LanguageType.zh_CN, '冰岛'),
  new Nationality('JE', 'jersey', false).addLangSetting(LanguageType.zh_CN, '泽西岛'),
  new Nationality('JM', 'Jamaican', false).addLangSetting(LanguageType.zh_CN, '牙买加'),
  new Nationality('JO', 'The Jordanian', false).addLangSetting(LanguageType.zh_CN, '约旦'),
  new Nationality('KE', 'Kenyan', false).addLangSetting(LanguageType.zh_CN, '肯尼亚'),
  new Nationality('KG', 'Kyrgyz', false).addLangSetting(LanguageType.zh_CN, '吉尔吉斯斯坦'),
  new Nationality('KI', 'Kiribati', false).addLangSetting(LanguageType.zh_CN, '基里巴斯'),
  new Nationality('KM', 'The comoros', false).addLangSetting(LanguageType.zh_CN, '科摩罗'),
  new Nationality('KN', 'Saint kitts and nevis', false).addLangSetting(LanguageType.zh_CN, '圣基茨和尼维斯'),
  new Nationality('KP', 'The north koreans', false).addLangSetting(LanguageType.zh_CN, '朝鲜'),
  new Nationality('KV', 'kosovar', false).addLangSetting(LanguageType.zh_CN, '科索沃'),
  new Nationality('KW', 'Kuwaiti', false).addLangSetting(LanguageType.zh_CN, '科威特'),
  new Nationality('KY', 'The cayman islands', false).addLangSetting(LanguageType.zh_CN, '开曼群岛'),
  new Nationality('KZ', 'Kazakhs', false).addLangSetting(LanguageType.zh_CN, '哈萨克斯坦'),
  new Nationality('LA', 'Lao', false).addLangSetting(LanguageType.zh_CN, '老挝'),
  new Nationality('LB', 'Lebanese', false).addLangSetting(LanguageType.zh_CN, '黎巴嫩'),
  new Nationality('LC', 'Saint Lucia', false).addLangSetting(LanguageType.zh_CN, '圣卢西亚岛'),
  new Nationality('LI', 'Liechtenstein', false).addLangSetting(LanguageType.zh_CN, '列支敦士登'),
  new Nationality('LR', 'liberian', false).addLangSetting(LanguageType.zh_CN, '利比里亚'),
  new Nationality('LS', 'Lesotho', false).addLangSetting(LanguageType.zh_CN, '莱索托'),
  new Nationality('LT', 'Lithuanian', false).addLangSetting(LanguageType.zh_CN, '立陶宛'),
  new Nationality('LU', 'luxemburger', false).addLangSetting(LanguageType.zh_CN, '卢森堡'),
  new Nationality('LV', 'latvian', false).addLangSetting(LanguageType.zh_CN, '拉脱维亚'),
  new Nationality('LY', 'Libyan', false).addLangSetting(LanguageType.zh_CN, '利比亚'),
  new Nationality('MA', 'Moroccan', false).addLangSetting(LanguageType.zh_CN, '摩洛哥'),
  new Nationality('MC', 'monacan', false).addLangSetting(LanguageType.zh_CN, '摩纳哥'),
  new Nationality('MD', 'moldovan', false).addLangSetting(LanguageType.zh_CN, '摩尔多瓦'),
  new Nationality('ME', 'The negroes of the gate', false).addLangSetting(LanguageType.zh_CN, '门的内哥罗'),
  new Nationality('MF', 'Of the French saint Martin', false).addLangSetting(LanguageType.zh_CN, '法属圣马丁'),
  new Nationality('MG', 'Malagasy', false).addLangSetting(LanguageType.zh_CN, '马达加斯加岛'),
  new Nationality('MH', 'Of the Marshall islands', false).addLangSetting(LanguageType.zh_CN, '马绍尔群岛'),
  new Nationality('MK', 'macedonic', false).addLangSetting(LanguageType.zh_CN, '马其顿'),
  new Nationality('ML', 'malian', false).addLangSetting(LanguageType.zh_CN, '马里'),
  new Nationality('MN', 'The mongols', false).addLangSetting(LanguageType.zh_CN, '蒙古'),
  new Nationality('MP', 'Northern mariana islands', false).addLangSetting(LanguageType.zh_CN, '北马里亚纳群岛'),
  new Nationality('MQ', 'Martinique', false).addLangSetting(LanguageType.zh_CN, '马提尼克岛'),
  new Nationality('MR', 'Mauritanian', false).addLangSetting(LanguageType.zh_CN, '毛利塔尼亚'),
  new Nationality('MS', 'Montserrat', false).addLangSetting(LanguageType.zh_CN, '蒙特塞拉特'),
  new Nationality('MT', 'Maltese', false).addLangSetting(LanguageType.zh_CN, '马耳他'),
  new Nationality('MV', 'maldivian', false).addLangSetting(LanguageType.zh_CN, '马尔代夫'),
  new Nationality('MW', 'malawian', false).addLangSetting(LanguageType.zh_CN, '马拉维'),
  new Nationality('MZ', 'mozambican', false).addLangSetting(LanguageType.zh_CN, '莫桑比克'),
  new Nationality('NA', 'namibian', false).addLangSetting(LanguageType.zh_CN, '纳米比亚'),
  new Nationality('NC', 'New caledonian', false).addLangSetting(LanguageType.zh_CN, '新喀里多尼亚'),
  new Nationality('NE', 'nigerien', false).addLangSetting(LanguageType.zh_CN, '尼日尔'),
  new Nationality('NF', 'Norfolk', false).addLangSetting(LanguageType.zh_CN, '诺福克岛'),
  new Nationality('NG', 'Nigerian', false).addLangSetting(LanguageType.zh_CN, '尼日利亚'),
  new Nationality('NI', 'nicaraguan', false).addLangSetting(LanguageType.zh_CN, '尼加拉瓜'),
  new Nationality('NP', 'nepalese', false).addLangSetting(LanguageType.zh_CN, '尼泊尔'),
  new Nationality('NR', 'Nauru people', false).addLangSetting(LanguageType.zh_CN, '瑙鲁'),
  new Nationality('NU', 'niue', false).addLangSetting(LanguageType.zh_CN, '纽埃岛'),
  new Nationality('OM', 'The people of Oman', false).addLangSetting(LanguageType.zh_CN, '阿曼'),
  new Nationality('PA', 'Panamanian', false).addLangSetting(LanguageType.zh_CN, '巴拿马'),
  new Nationality('PE', 'Peruvian', false).addLangSetting(LanguageType.zh_CN, '秘鲁'),
  new Nationality('PF', 'French polynesians', false).addLangSetting(LanguageType.zh_CN, '法属波利尼西亚'),
  new Nationality('PG', 'Papua New Guinea', false).addLangSetting(LanguageType.zh_CN, '巴布亚新几内亚'),
  new Nationality('PM', 'Saint Pierre and the secret clone', false).addLangSetting(LanguageType.zh_CN, '圣皮埃尔岛和密克隆'),
  new Nationality('PN', 'Pitcairn', false).addLangSetting(LanguageType.zh_CN, '皮特凯恩'),
  new Nationality('PR', 'Puerto Rican', false).addLangSetting(LanguageType.zh_CN, '波多黎各'),
  new Nationality('PS', 'The State of Palestine', false).addLangSetting(LanguageType.zh_CN, '巴勒斯坦'),
  new Nationality('PW', 'Palau people', false).addLangSetting(LanguageType.zh_CN, '帕劳'),
  new Nationality('PY', 'Paraguayan', false).addLangSetting(LanguageType.zh_CN, '巴拉圭'),
  new Nationality('QA', 'qatari', false).addLangSetting(LanguageType.zh_CN, '卡塔尔'),
  new Nationality('RE', 'reunion', false).addLangSetting(LanguageType.zh_CN, '留尼汪'),
  new Nationality('RS', 'serb', false).addLangSetting(LanguageType.zh_CN, '塞尔维亚'),
  new Nationality('RW', 'rwandese', false).addLangSetting(LanguageType.zh_CN, '卢旺达'),
  new Nationality('SB', 'The Solomon islands', false).addLangSetting(LanguageType.zh_CN, '所罗门群岛'),
  new Nationality('SC', 'The seychelles', false).addLangSetting(LanguageType.zh_CN, '塞舌尔'),
  new Nationality('SD', 'The Sudanese', false).addLangSetting(LanguageType.zh_CN, '苏丹'),
  new Nationality('SJ', 'Svalbard', false).addLangSetting(LanguageType.zh_CN, '斯瓦尔巴特群岛'),
  new Nationality('SK', 'slovakian', false).addLangSetting(LanguageType.zh_CN, '斯洛伐克'),
  new Nationality('SL', 'Sierra leonean', false).addLangSetting(LanguageType.zh_CN, '塞拉利昂'),
  new Nationality('SM', 'SAN marino', false).addLangSetting(LanguageType.zh_CN, '圣马力诺'),
  new Nationality('SN', 'senegalese', false).addLangSetting(LanguageType.zh_CN, '塞内加尔'),
  new Nationality('SO', 'Somali', false).addLangSetting(LanguageType.zh_CN, '索马里'),
  new Nationality('SS', 'South Sudanese', false).addLangSetting(LanguageType.zh_CN, '南苏丹'),
  new Nationality('ST', 'SAO tome and principe', false).addLangSetting(LanguageType.zh_CN, '圣多美和普林西比'),
  new Nationality('SV', 'Salvadoran', false).addLangSetting(LanguageType.zh_CN, '萨尔瓦多'),
  new Nationality('SX', 'The Dutch saint Martin', false).addLangSetting(LanguageType.zh_CN, '荷属圣马丁'),
  new Nationality('SY', 'Syrian', false).addLangSetting(LanguageType.zh_CN, '叙利亚'),
  new Nationality('SZ', 'Swaziland', false).addLangSetting(LanguageType.zh_CN, '斯威士兰'),
  new Nationality('TC', 'Turks and caicos', false).addLangSetting(LanguageType.zh_CN, '特克斯和凯科斯群岛'),
  new Nationality('TD', 'chadian', false).addLangSetting(LanguageType.zh_CN, '乍得'),
  new Nationality('TG', 'togolese', false).addLangSetting(LanguageType.zh_CN, '多哥'),
  new Nationality('TJ', 'Tajik', false).addLangSetting(LanguageType.zh_CN, '塔吉克斯坦'),
  new Nationality('TK', 'Tokelau', false).addLangSetting(LanguageType.zh_CN, '托克劳群岛'),
  new Nationality('TL', 'timorese', false).addLangSetting(LanguageType.zh_CN, '东帝汶'),
  new Nationality('TM', 'Turkmen', false).addLangSetting(LanguageType.zh_CN, '土库曼斯坦'),
  new Nationality('TN', 'Tunisian', false).addLangSetting(LanguageType.zh_CN, '突尼斯'),
  new Nationality('TO', 'tongan', false).addLangSetting(LanguageType.zh_CN, '汤加'),
  new Nationality('TT', 'The people of Trinidad and Tobago', false).addLangSetting(LanguageType.zh_CN, '特立尼达和多巴哥'),
  new Nationality('TV', 'Tuvalu', false).addLangSetting(LanguageType.zh_CN, '图瓦卢'),
  new Nationality('TZ', 'tanzanian', false).addLangSetting(LanguageType.zh_CN, '坦桑尼亚'),
  new Nationality('UG', 'ugandan', false).addLangSetting(LanguageType.zh_CN, '乌干达'),
  new Nationality('UM', 'Small island native American', false).addLangSetting(LanguageType.zh_CN, '美国本土外小岛屿'),
  new Nationality('UY', 'Uruguayan', false).addLangSetting(LanguageType.zh_CN, '乌拉圭'),
  new Nationality('UZ', 'Uzbek', false).addLangSetting(LanguageType.zh_CN, '乌兹别克斯坦'),
  new Nationality('VA', 'Vatican', false).addLangSetting(LanguageType.zh_CN, '梵蒂冈'),
  new Nationality('VC', 'Saint Vincent and the grenadines', false).addLangSetting(LanguageType.zh_CN, '圣文森特和格林纳丁斯'),
  new Nationality('VE', 'Venezuelan', false).addLangSetting(LanguageType.zh_CN, '委内瑞拉'),
  new Nationality('VG', 'British virgin islands', false).addLangSetting(LanguageType.zh_CN, '英属维尔京群岛'),
  new Nationality('VI', 'The virgin islands', false).addLangSetting(LanguageType.zh_CN, '维尔京群岛'),
  new Nationality('VU', 'Vanuatu', false).addLangSetting(LanguageType.zh_CN, '瓦努阿图'),
  new Nationality('WF', 'Wallis and futuna', false).addLangSetting(LanguageType.zh_CN, '瓦利斯群岛和富图纳群岛'),
  new Nationality('WS', 'Samoan', false).addLangSetting(LanguageType.zh_CN, '萨摩亚'),
  new Nationality('YE', 'yemeni', false).addLangSetting(LanguageType.zh_CN, '也门'),
  new Nationality('YT', 'Mayotte', false).addLangSetting(LanguageType.zh_CN, '马约特'),
  new Nationality('ZM', 'zambian', false).addLangSetting(LanguageType.zh_CN, '赞比亚'),
  new Nationality('ZW', 'zimbabwean', false).addLangSetting(LanguageType.zh_CN, '津巴布韦')

].sort((a, b) => a.code.localeCompare(b.code));

export const HotNationalityList = AvailableNationalities.filter(item => item.highPriority);

export const NationalityDirectory: NationalityDirectoryStruct = (() => {
  const CHAR_CODE_A = 'A'.charCodeAt(0);
  const CHAR_CODE_Z = 'Z'.charCodeAt(0);

  // 初始化NationalityDirectoryStruct，使得每一个字母都有独立的位置
  const directory: NationalityDirectoryStruct = [];
  for (let i = CHAR_CODE_A; i <= CHAR_CODE_Z; i++) {
    directory.push({
      index: i - CHAR_CODE_A,
      indexChar: String.fromCharCode(i),
      items: []
    });
  }

  // 往目录添加数据
  AvailableNationalities.forEach(item => {
    const index = item.code.charCodeAt(0) - CHAR_CODE_A;
    directory[index].items.push(item); // push to array
  });

  // 移除不存在的项目（但是保留其的索引占位）
  directory.forEach((item, idx) => {
    if (item.items.length === 0) {
      delete directory[idx];
    }
  });

  return directory;
})();
