import { Destination } from './destination.model';
import { SuggestionItemCategory } from '../suggestions';

export class Region extends Destination {
    continentID: string;
    constructor(id: string, type: SuggestionItemCategory, name: string, nameEn: string, continentID: string, order: number) {
        super();
        this.id = id;
        this.name = {
            'zh-CN': name,
            'en-US': nameEn
        };
        this.type = type;
        this.continentID = continentID;
        this.order = typeof order === 'undefined' ? 0 : order;
        this.displayOptions = {
            'zh-CN': {
                fullName: name,
                shortName: name,
                displayName: name
            },
            'en-US': {
                fullName: nameEn,
                shortName: nameEn,
                displayName: nameEn
            }
        };
    }
}
