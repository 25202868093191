import { HotelBooking } from '../../../shared/models/booking/hotel-booking.model';
import { WorkOrderTypeEnum } from '../../../shared/enums/work-order-type';
import {
    WorkOrderSeachOptionsModel,
    WorkOrderModel,
    BookingWorkOrderDataModel,
    FeedbackWorkOrderDataModel
} from '../../../shared/models/work-order/work-order.models';
import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { map } from 'rxjs/operators';
/**
 * 工单相关接口
 */
@Injectable()
export class WorkOrderService {

    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService,
    ) { }


    static normalizeTicket(ticket): WorkOrderModel {

        switch (ticket.WorkOrderType) {
            case WorkOrderTypeEnum.Booking:
                ticket.WorkOrderData = Object.assign(new BookingWorkOrderDataModel(), JSON.parse(ticket.WorkOrderData));
                ticket.ReferenceData = Object.assign(new HotelBooking(), JSON.parse(ticket.ReferenceData));
        }
        return ticket;
    }
    getWorkOrderList(option: WorkOrderSeachOptionsModel, pageNum = 1, pageSize = 10) {
        let model = { option, pageSize, pageNum };
        return this.httpClient.post<PaginationWrapper<WorkOrderModel>>('/WorkOrder/GetWorkOrderList', model).pipe(
            map(resp => {
                resp.Data.map(ticket => WorkOrderService.normalizeTicket(ticket));
                return resp;
            })
        );
    }

    createBookingWorkOrder(bookingId: string, workOrderData: BookingWorkOrderDataModel) {
        return this.httpClient.post<WorkOrderModel>('/WorkOrder/CreateBookingWorkOrder', { bookingId, workOrderData });
    }

    createFeedbackWorkOrder(workOrderData: FeedbackWorkOrderDataModel) {
        return this.httpClient.post<WorkOrderModel>('/WorkOrder/CreateFeedbackWorkOrder', { workOrderData });
    }
}
