import { Component, OnInit, Input } from '@angular/core';

import { WorkOrderTypeEnum,
  WorkOrderSeachOptionsModel,
  WorkOrderModel,
  BookingWorkOrderDataModel,
  BookingWorkOrderTypeEnum } from '../../models/work-order/work-order.models';

@Component({
  selector: 'app-booking-ticket-type',
  templateUrl: './booking-ticket-type.component.html',
  styleUrls: ['./booking-ticket-type.component.scss']
})
export class BookingTicketTypeComponent implements OnInit {

  @Input()
  type: BookingWorkOrderTypeEnum;

  BookingModifyTypeEnum = BookingWorkOrderTypeEnum;
  constructor() { }

  ngOnInit() {
  }

}
