import { UploadError, UploadErrorTypeEnum, UploadConfigModel } from './uploader.models';
import {
  Component,
  OnInit,
  HostBinding,
  HostListener,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { ProcessStepTypeEnum } from '../../models/shared-models';
import { ResourceService } from '../../../core/services/resource/resource.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  constructor(private resourceService: ResourceService) { }

  formData: FormData;
  processStep = ProcessStepTypeEnum.Initial;
  ProcessStepType = ProcessStepTypeEnum;
  uploadError: UploadError;
  UploadErrorType = UploadErrorTypeEnum;

  get ratioDesc() {
    if (Array.isArray(this.ratio)) {
      return this.ratio.join(':');
    }
    return this.ratio;
  }

  @Input() maxSize = 2; // 2MB
  @Input() maxCount = 1;
  @Input() ratio: number | [number, number] = 0;
  @Input() path = '/Upload';
  @Input() disabledClass = 'disabled';
  @Input() disabled: boolean;
  @Input() accept = 'image/*';
  @Input() showStatusMessage = true;


  @Output() statusChange = new EventEmitter<
    UploadError
  >();

  @Output() error = new EventEmitter<UploadError>();
  @Output() success = new EventEmitter<{
    Name: string,
    Url: string;
    Type: string;
  }>();

  @HostBinding('class.disabled')
  get _disabled() {
    return this.disabled || this.processStep === ProcessStepTypeEnum.Processing;
  }

  @ViewChild('fileControl', { static: true })
  fileControl: ElementRef<HTMLInputElement>;

  @ViewChild('errorMessageContainer', { static: true })
  errorMessageContainer: ElementRef<HTMLElement>;

  @HostListener('click') onclick($event) {
    this.fileControl.nativeElement.click();
  }

  onFileChange = async () => {
    const files = this.fileControl.nativeElement.files;
    if (files.length < 1) {
      return;
    }
    try {
      if (files.length > this.maxCount) {
        throw new UploadError(UploadErrorTypeEnum.MaxCount);
      }
      for (let index = 0; index < files.length; index++) {
        const element = files.item(index) as File;
        if (element.size > this.maxSize * 1024 * 1024) {
          throw new UploadError(UploadErrorTypeEnum.MaxSize, element.name);
        }
        let ratio = this.ratio;
        if (Array.isArray(ratio)) {
          ratio = ratio[0] / ratio[1];
        }
        if (ratio > 0) {

          let ImgRatio = await this.getImageRatio(element);
          if (ImgRatio !== ratio) {
            throw new UploadError(UploadErrorTypeEnum.ImageRatio);
          }
        }

      }
    } catch (error) {
      let err: UploadError = error;
      err.Message = this.getErrorMessage(err.Code);
      this.uploadError = err;
      this.statusChange.emit(err);
      this.error.emit(err);
      return;
    }

    this.upload();
  }

  upload() {
    this.processStep = ProcessStepTypeEnum.Processing;
    this.formData = new FormData();

    const file = this.fileControl.nativeElement.files.item(0) as File;
    this.fileControl.nativeElement.files = null;
    this.formData.append('files', file, file.name);

    this.resourceService.uploadFile(this.formData, { Path: this.path }).subscribe(
      url => {
        this.processStep = ProcessStepTypeEnum.Initial;
        let result = new UploadError(UploadErrorTypeEnum.Success, url);
        result.FileName = file.name;
        this.statusChange.emit(result);
        this.success.emit({
          Name: file.name,
          Url: ResourceService.normalizeStaticUrl(url),
          Type: file.type
        });
      },
      err => {
        this.processStep = ProcessStepTypeEnum.Initial;
        this.uploadError = new UploadError(UploadErrorTypeEnum.Network, this.getErrorMessage(UploadErrorTypeEnum.Default));
        this.statusChange.emit(this.uploadError);
        this.error.emit(this.uploadError);
      }
    );
  }

  async getImageRatio(file: File) {
    return await new Promise((resolve) => {
      let pReader = new FileReader();

      pReader.addEventListener('load', function (e) {
        let src = e.target['result'];
        let image = new Image();
        image.addEventListener('load', () => {
          resolve(image.naturalWidth / image.naturalHeight);
        });
        image.src = src as string;

      });
      pReader.readAsDataURL(file);
    });
  }

  private getErrorMessage(type: UploadErrorTypeEnum) {
    let messageElement = this.errorMessageContainer.nativeElement.querySelector(
      `[data-type="${type}"], [data-type="${UploadErrorTypeEnum.Default}"]`);
    if (messageElement) {
      return messageElement.textContent.trim();
    }
    return null;
  }

  ngOnInit() { }
}
