import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { map } from 'rxjs/operators';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { Observable } from 'rxjs';
import { ImageLinkModel, SiteNotification } from '../../../shared/models/shared-models';
// import { PromotionPageModel } from '../../../promotion/hotel/promotion.models';
// import { HotlineModel } from '../../../promotion/hotline/hotline.models';

import {
  ActivityConfigModel,
  ActivityRegionModel,
  BannerModel,
  DEFAULT_ACTIVITY_CONFIG,
} from '../../../promotion/shared/models/activity-models';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { ObjectHelper } from '@dida-shopping/dida-services/common';
import { Nationality } from '@dida-shopping/dida-services/nationality';
const _LOCAL_PATH = '/assets/';
const _PUBLIC_HOST = 'dsimage.didatravel.com';
const _LOCAL_HOST = 'localhost:4200';

const _FALLBACK_IMG = '/v2-app/images/image-fallback.svg';

export const FALLBACK_IMG = _FALLBACK_IMG;

export class DataEntityModel<T> {
  EntityValue: T;
  EntityKey: string;
  EntityDesc: string;
  EntityType: string;
  UpdateTime: string;
  CreateTime: string;
}

export enum EntityModifyTypeEnum {
  Create = 1,
  Update = 2,
  Delete = 3,
}

export enum EntityCategoryEnum {
  Activity = 'Activity',
  CommonData = 'CommonData',
  HomePageData = 'HomePageData',
  HotelPromotion = 'HotelPromotion',
  Hotline = 'Hotline',
  BannerSetting = 'BannerSetting',
}

export enum ActivityKeyEnum {
  ActivityEntry = 'ActivityEntry',
}

export enum BannerCategoryTypeEnum {
  HomeIndex = 'HomeIndex',
  HotelIndex = 'HotelIndex',
  HotelIndex2 = 'HotelIndex2',
}

export class FileUploadOptions {
  Path: string;
  Reference?: string;
  Desc?: string;
  Title?: string;
}

@Injectable()
export class ResourceService {
  private static _nationalityList: Array<Nationality> = [];
  private static _updateTimeCache = new Map<string, string>();
  static get NationalityList() {
    return ResourceService._nationalityList;
  }

  constructor(private httpClient: HttpClient) {}

  /**
   * Normalizes static url
   * @param url
   * @param [config]
   * @returns  stirng
   */
  static normalizeStaticUrl(
    url: string,
    config:
      | boolean
      | {
          /**
           * 是否返回默认图片
           */
          orDefault?: boolean;
          /**
           * 指定图片宽度
           */
          width?: number;
          /**
           * 给文件名添加后缀
           */
          postfix?: string;
        } = false
  ): string {
    if (typeof config === 'boolean') {
      config = {
        orDefault: config,
      };
    }

    if (!url) {
      if (config.orDefault) {
        url = _FALLBACK_IMG;
      } else {
        return url;
      }
    }

    url = encodeURI(url);
    let [fileUrl = '', queryStr = ''] = url.split('?');

    if (config.postfix) {
      fileUrl = fileUrl.replace(/(\.[^.]+$)/, `_${config.postfix}$1`);
    }

    if (!fileUrl.endsWith('.svg')) {
      if (config.width && !queryStr.includes('x-oss-process')) {
        if (queryStr) {
          queryStr += '&';
        }
        let computedWidth = Math.ceil(config.width * devicePixelRatio);
        computedWidth = Math.min(computedWidth, 3840 /*1920*2*/);
        queryStr += 'x-oss-process=image/resize,w_' + computedWidth;
      }
    }

    url = fileUrl;
    if (queryStr) {
      url += '?' + queryStr;
    }

    if (url.includes('//')) {
      return url;
    }

    if (url.indexOf(_LOCAL_PATH) !== 0) {
      url = `https://${_PUBLIC_HOST}${url}`;
    } else if (isDevMode()) {
      url = `//${_LOCAL_HOST}${url}`;
    } else {
      url = `https://${_PUBLIC_HOST}${url.slice(_LOCAL_PATH.length - 1)}`;
    }

    return url;
  }

  private static normalizeAndCheck(item: ImageLinkModel) {
    if (item.InActive) {
      return false;
    }

    if (item.Expired && new Date(item.Expired) < new Date()) {
      return false;
    }

    if (item.StartDate && new Date(item.StartDate) > new Date()) {
      return false;
    }

    if (!(item instanceof ImageLinkModel)) {
      item = ObjectHelper.assign(new ImageLinkModel(), item);
    }

    item.en_US = ObjectHelper.assign(item.toI18nModel(), item[LanguageType.en_US], item.en_US);
    item.zh_CN = ObjectHelper.assign(item.toI18nModel(), item[LanguageType.zh_CN], item.zh_CN);

    ObjectHelper.assign(item, item.zh_CN);
    ObjectHelper.assign(item, item.en_US);

    return true;
  }

  /**
   * 更新数据
   */
  updateResourceEntity(entity: DataEntityModel<string>, modifyType: EntityModifyTypeEnum) {
    entity.UpdateTime = ResourceService._updateTimeCache.get([entity.EntityType, entity.EntityKey].join('.'));
    return this.httpClient.post<boolean>('/ResourceApi/UpdateDataEntity', {
      entity: entity,
      modifyType,
    });
  }

  updateRewardRecord(records: any[]) {
    let model = new DataEntityModel<string>();
    model.EntityValue = JSON.stringify(records);
    model.EntityType = EntityCategoryEnum.HomePageData;
    model.EntityKey = 'HonorRecordList';
    return this.updateResourceEntity(model, EntityModifyTypeEnum.Update);
  }

  /**
   * 获取资源数据
   * @param entityType
   */
  getResourceEntitys<T>(entityType: string) {
    return this.httpClient
      .get<PaginationWrapper<DataEntityModel<T>>>('/ResourceApi/GetDataEntity', { entityType, PageSize: 100 })
      .pipe(
        map((resp) =>
          resp.Data.map((item) => {
            item.EntityValue = JSON.parse(item.EntityValue.toString());
            return item;
          })
        )
      );
  }

  /**
   * 获取资源数据项
   * @param entityType h
   * @param entityKey
   */
  getResourceEntity<T>(entityType: string, entityKey: string): Observable<DataEntityModel<T>> {
    return this.httpClient
      .get<PaginationWrapper<DataEntityModel<T>>>('/ResourceApi/GetDataEntity', { entityType, entityKey })
      .pipe(
        map((resp) => {
          let result = resp.Data[0];
          if (!result) {
            return;
          }
          ResourceService._updateTimeCache.set([result.EntityType, result.EntityKey].join('.'), result.UpdateTime);
          if (result.EntityValue) {
            result.EntityValue = JSON.parse(result.EntityValue.toString()) as T;
            return result;
          }
          return;
        })
      );
  }

  getTranslatableEntity(
    entityType: string,
    entityKey: string,
    lang = LanguageType.zh_CN
  ): Observable<ImageLinkModel[]> {
    return this.getResourceEntity<ImageLinkModel[]>(entityType, entityKey).pipe(
      map((resp) => resp?.EntityValue.map((item) => ImageLinkModel.normalize(item, lang)))
    );
  }
  //// 首页内容
  // 业务优势
  getCompanyAdvantage(lang: LanguageType) {
    return this.getTranslatableEntity(EntityCategoryEnum.HomePageData, 'Advantages', lang);
  }
  // 发展历程
  getCompanyMilestones(lang: LanguageType) {
    return this.getTranslatableEntity(EntityCategoryEnum.HomePageData, 'Milestones', lang);
  }
  // 关于旅赞
  getHomePageCommon(lang: LanguageType) {
    return this.getTranslatableEntity(EntityCategoryEnum.HomePageData, 'Common', lang);
  }

  getAdList(lang: LanguageType) {
    return this.getTranslatableEntity(EntityCategoryEnum.CommonData, 'Advertisement', lang).pipe(
      map((list) => list.filter((item) => item.IsValid))
    );
  }

  /**
   * 获取活动入口数据
   * @param raw 用于配置页
   */
  getActivityEntry(raw = false) {
    let result = this.getResourceEntity<BannerModel[]>(EntityCategoryEnum.Activity, ActivityKeyEnum.ActivityEntry).pipe(
      map((resp) =>
        resp.EntityValue.map((item) => {
          if (item.Id) {
            item.DefaultReferenceUrl = ['/promotion', item.BannerType, item.Id].filter((seg) => !!seg).join('/');
          }

          return ImageLinkModel.normalize(item) as BannerModel;
        })
      ),
      map((resp) =>
        resp.map((item) => {
          // 去除旧配置中的时间部分，只保留日期
          let [expiredDate] = (item.Expired || ' ').split(' ');
          item.Expired = expiredDate;
          return item;
        })
      )
    );
    if (!raw) {
      result = result.pipe(
        map((resp) =>
          resp
            .filter((item) => item.IsValid)
            // 没有链接则使用默认的
            .map((item) => {
              item.ReferenceUrl = item.ReferenceUrl || item.DefaultReferenceUrl;
              return item;
            })
        )
      );
    }
    return result;
  }

  updateActivityEntry(entrys: BannerModel[]) {
    let model = new DataEntityModel<string>();
    model.EntityValue = JSON.stringify(entrys);
    model.EntityType = EntityCategoryEnum.Activity;
    model.EntityKey = ActivityKeyEnum.ActivityEntry;
    return this.updateResourceEntity(model, EntityModifyTypeEnum.Update);
  }

  /**
   * 获取轮播图数据
   * @param bannerCategory 轮播图类型
   * @param raw 用于配置页
   */
  getBannerList(bannerCategory: BannerCategoryTypeEnum | string, raw = false) {
    let result = this.getResourceEntity<BannerModel[]>(EntityCategoryEnum.BannerSetting, bannerCategory).pipe(
      map((resp) =>
        resp.EntityValue.map((item) => {
          if (item.BannerType === undefined) {
            item.BannerType = 'activity';
          }
          if (item.Id) {
            item.DefaultReferenceUrl = ['/promotion', item.BannerType, item.Id].filter((seg) => !!seg).join('/');
          }

          return ImageLinkModel.normalize(item) as BannerModel;
        })
      )
    );
    if (!raw) {
      result = result.pipe(
        map((resp) =>
          resp
            .filter((item) => item.IsValid)
            // 没有链接则使用默认的
            .map((item) => {
              item.ReferenceUrl = item.ReferenceUrl || item.DefaultReferenceUrl;
              return item;
            })
        )
      );
    }
    return result;
  }

  /**
   * 保存banner设置
   * @param catetoryType banner位置
   * @param bannerList banner数据
   */
  updateBannerSetting(catetoryType: string, bannerList: BannerModel[]) {
    let model = new DataEntityModel<string>();
    model.EntityValue = JSON.stringify(bannerList);
    model.EntityType = EntityCategoryEnum.BannerSetting;
    model.EntityKey = catetoryType;
    return this.updateResourceEntity(model, EntityModifyTypeEnum.Update);
  }

  getActivity(alias: string) {
    return this.getResourceEntity<any[]>(EntityCategoryEnum.Activity, alias).pipe(
      map((resp) => resp && resp.EntityValue),
      map((resp) => {
        let config: ActivityConfigModel = resp[0];
        if (!config) {
          return [Object.assign(new ActivityConfigModel(), DEFAULT_ACTIVITY_CONFIG)];
        }
        if (!config.customInfoDict) {
          let regions: ActivityRegionModel[] = resp.slice(1);
          config.customInfoDict = regions.reduce((result, item) => {
            (item.customInfoList || []).map((info) => {
              result[info.HotelID] = info;
            });
            return result;
          }, {});
        }
        return resp;
      })
    );
  }

  createActivity(alias: string, value: string, desc: string) {
    let entity = new DataEntityModel<string>();
    entity.EntityKey = alias;
    entity.EntityType = EntityCategoryEnum.Activity;
    entity.EntityValue = value || '[]';
    entity.EntityDesc = desc;
    return this.updateResourceEntity(entity, EntityModifyTypeEnum.Create);
  }

  updateActivity(alias: string, config: ActivityConfigModel, sections: ActivityRegionModel[]) {
    let entity = new DataEntityModel<string>();
    entity.EntityKey = alias;
    entity.EntityType = EntityCategoryEnum.Activity;
    entity.EntityValue = JSON.stringify([config, ...sections]);
    return this.updateResourceEntity(entity, EntityModifyTypeEnum.Update);
  }

  /**
   * 获取弹窗通知
   */
  getSiteNotification() {
    return this.getResourceEntity<SiteNotification[]>(EntityCategoryEnum.CommonData, 'SiteNotification').pipe(
      map((item) => item.EntityValue)
    );
  }

  /**
   * 获取推广（广告）酒店
   * @param hotelId
   */
  // getPromotionData(hotelId) {
  //     return this.getResourceEntity<PromotionPageModel>(EntityCategoryEnum.HotelPromotion, hotelId)
  //         .pipe(
  //             map(item => item.EntityValue[0])
  //         );
  // }

  /**
   * 获取推广（广告）酒店列表
   */
  getPromotionList() {
    return this.getResourceEntity<ImageLinkModel[]>(EntityCategoryEnum.HotelPromotion, 'HotelList').pipe(
      map((resp) => resp?.EntityValue.filter((item) => ResourceService.normalizeAndCheck(item)) || [])
    );
  }

  uploadFile(formData: FormData, options: FileUploadOptions) {
    for (let key of Object.keys(options)) {
      formData.append(key, options[key]);
    }

    return this.httpClient.post<string>('/FileApi/Upload', formData);
  }

  // getHotlineList() {
  //     return this.getResourceEntitys<HotlineModel>(EntityCategoryEnum.Hotline).pipe(
  //         map(list => {

  //             let result = list.map(item => {
  //                 item.EntityValue.Alias = item.EntityKey;
  //                 return item.EntityValue;
  //             });
  //             return result;
  //         })
  //     );
  // }

  // getHotlineDetail(alias) {
  //     return this.getResourceEntity<HotlineModel>(EntityCategoryEnum.Hotline, alias)
  //         .pipe(
  //             map(item => {
  //                 item.EntityValue.Alias = item.EntityKey;
  //                 return item.EntityValue;
  //             })
  //         );
  // }

  // updateHotline(hotline: HotlineModel, modifyType = EntityModifyTypeEnum.Create) {
  //     let payload = new DataEntityModel<string>();

  //     payload.EntityType = EntityCategoryEnum.Hotline;
  //     payload.EntityKey = hotline.Alias;
  //     payload.EntityDesc = hotline.Header.Title;
  //     if (modifyType !== EntityModifyTypeEnum.Delete) {
  //         payload.EntityValue = JSON.stringify(hotline);
  //     }
  //     return this.updateResourceEntity(payload, modifyType);
  // }

  searchBulletin<T = any>(type: number = 2, pageNum: number = 1, pageSize: number = 10) {
    return this.httpClient.post<PaginationWrapper<T>>('/ResourceApi/SearchBulletin', {
      options: {
        BulletinType: type,
      },
      pageNum,
      pageSize,
    });
  }

  getBulletin<T = any>(id: string) {
    return this.httpClient
      .post<PaginationWrapper<T>>('/ResourceApi/SearchBulletin', {
        options: {
          ID: id,
        },
        pageNum: 1,
        pageSize: 10,
      })
      .pipe(map((resp) => resp.Data[0]));
  }
}
