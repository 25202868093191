
import addMilliseconds from 'date-fns/addMilliseconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { DayOfWeek } from './enums/day-of-week.enum';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';

export class DateTimeHelper {
  private static serverTime: {
    baseTime: Date;
    syncTime: Date;
  } | null = null;
  static readonly DEFAULT_DATE_FORMAT = `yyyy-MM-dd`;
  static readonly DEFAULT_DATE_TIME_FORMAT = `yyyy-MM-dd HH:mm:ss`;

  static get today(): Date {
    return this.getToday();
  }

  static getDate(date?: any) {
    return new Date(date || new Date());
  }

  /**
   *
   * @param date
   * @param formatStr 日期格式，默认yyyy-MM-dd HH:mm:ss
   */
  static getString(date: any, formatStr = DateTimeHelper.DEFAULT_DATE_TIME_FORMAT) {
    return format(new Date(date || new Date()), formatStr);
  }

  /**
   *
   * @param date 日期
   * @returns 日期字符串
   */
  static getDateString(date?: any) {
    return DateTimeHelper.getString(date, DateTimeHelper.DEFAULT_DATE_FORMAT);
  }

  static getDateTimeString(date?: any) {
    return DateTimeHelper.getString(date);
  }

  static getRelateDate(offset = 0, date?: any) {
    return addDays(new Date(date || new Date()), offset);
  }

  static getRelateDateString(offset = 0, date?: any) {
    const targetDate = this.getRelateDate(offset, date);
    return DateTimeHelper.getDateString(targetDate);
  }

  static getYesterDayString(date?: any) {
    const yesterday = DateTimeHelper.getRelateDate(-1, date);
    return DateTimeHelper.getDateString(yesterday);
  }

  static getTomorrowString(date?: any) {
    const tomorrow = DateTimeHelper.getRelateDate(1, date);
    return DateTimeHelper.getDateString(tomorrow);
  }

  static getDateDiff(date1: any, date2: any) {
    return differenceInDays(
      DateTimeHelper.getDate(date2),
      DateTimeHelper.getDate(date1)
    );
  }

  static getHourDiff(date1: any, date2: any) {
    return differenceInHours(
      DateTimeHelper.getDate(date2),
      DateTimeHelper.getDate(date1)
    );
  }

  static getDays(toDate: Date, fromDate: Date): number {
    const intervalMsec = toDate.getTime() - fromDate.getTime();
    const intervalSec = intervalMsec / 1000;
    const days = parseInt((intervalSec / 3600 / 24).toString(), 10);
    return days;
  }

  static addMonths(date: Date, value: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + value);
    return newDate;
  }

  static addDays(date: Date | string | number, value: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + value);
    return newDate;
  }

  static addMinutes(date: Date, value: number): Date {
    const newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + value);
    return newDate;
  }

  static addSeconds(date: Date, value: number): Date {
    const newDate = new Date(date);
    newDate.setSeconds(newDate.getSeconds() + value);
    return newDate;
  }

  static format(date: Date, formatStr: string): any {
    return DateTimeHelper.getString(date, formatStr)
  }

  static parse(dateStr: any, dateWithTChar?: boolean): Date | null {
    if (dateStr instanceof Date) {
      return dateStr;
    }
    if (typeof dateStr === 'string') {
      if (dateWithTChar) {
        const match = dateStr.match(
          /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})T(\d{1,2}):(\d{1,2}):(\d{1,2})/
        );
        if (match === null) {
          return null;
        }
        const date = new Date(
          parseInt(match[1], 10), // year
          parseInt(match[3], 10) - 1, // month
          parseInt(match[4], 10), // date
          parseInt(match[5], 10), // hour
          parseInt(match[6], 10), // minute
          parseInt(match[7], 10) // second
        );
        return date;
      } else {
        const match = dateStr.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})T?/);
        if (match === null) {
          return null;
        }
        const date = new Date(
          parseInt(match[1], 10),
          parseInt(match[3], 10) - 1,
          parseInt(match[4], 10)
        );
        return date;
      }
      // let date: Date = null;
      // if (dateWithTChar) {
      //     dateStr = dateStr.replace('T', ' ');
      // }
      // date = new Date(dateStr);
    }
    return null;
  }

  static getDayOfWeek(date: Date): DayOfWeek | null {
    if (date) {
      return date.getDay();
    }
    return null;
  }

  static getDateOffset(startDate, endDate = new Date()) {
    const msOfDate = 24 * 60 * 60 * 1000;
    const _s = +new Date(startDate) / msOfDate;
    const _e = +new Date(endDate) / msOfDate;
    const result = Math.floor(_e) - Math.floor(_s);
    return result;
  }

  private static getToday(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static syncServerTime(serverTime: Date) {
    DateTimeHelper.serverTime = {
      baseTime: serverTime,
      syncTime: new Date()
    };
  }

  /**
   * 使用该方法时请确保至少已经通过API访问过服务器一次
   */
  static get serverNow(): Date {
    if (DateTimeHelper.serverTime) {
      const { baseTime, syncTime } = DateTimeHelper.serverTime;
      return addMilliseconds(
        new Date(),
        differenceInMilliseconds(baseTime, syncTime)
      );
    }
    return new Date();
  }

  /**
   * 使用该方法时请确保至少已经通过API访问过服务器一次
   */
  static get serverToday() {
    const date = DateTimeHelper.serverNow;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  /**
   * 使用该方法时请确保至少已经通过API访问过服务器一次
   * 将服务器时间转换为本地时间
   */
  static toLocalTime(serverTime: Date): Date {
    if (DateTimeHelper.serverTime) {
      const { baseTime, syncTime } = DateTimeHelper.serverTime;
      return addMilliseconds(
        serverTime,
        differenceInMilliseconds(syncTime, baseTime)
      );
    }
    return serverTime;
  }

  static getDateRangeList(from: Date, to: Date, offset = 7, maxLen=21) {
    const nowDate = new Date();
    if (!from || nowDate > from) {
      from = nowDate;
    }
    const defaultEndDate = addDays(from, offset);
    if (!to || to < defaultEndDate) {
      to = defaultEndDate;
    }

    let nextDate = addDays(from, 1);

    const result = [];
    do {
      result.push({
        From: DateTimeHelper.format(
          from,
          DateTimeHelper.DEFAULT_DATE_FORMAT
        ),
        To: DateTimeHelper.format(nextDate, DateTimeHelper.DEFAULT_DATE_FORMAT)
      });
      from = addDays(from, 1)
      nextDate = addDays(nextDate, 1)
    } while (from <= to);

    // if (isDevMode()) {
    //     return result.slice(0, 1);
    // }
    return result.slice(0, maxLen);
  }
}
