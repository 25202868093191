import { BalanceLogModel } from '../../../shared/models/user/balance-log.model';
import { UserRedeemRecordModel } from '../../../shared/models/points/redeem.models';
import { InvitationViewModel } from '../../../shared/models/user/user-invitation.models';
import { Injectable } from '@angular/core';
import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import { FavoriteHotelDetail } from '../../../shared/models/user/favorite-hotel.models';
import { SubAccountPermissionViewModel } from '../../../shared/models/client/subaccount-permission-view.model';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { RedeemRecordStatusTypeEnum } from '../../../shared/models/points/redeem.models';
import { DailyRedeemInfoModel } from '../../../shared/models/points/daily-redeem-info.model';
import { InvitationModel } from '../../../shared/models/user/user-invitation.models';
import { TransactionRecord } from '../../../shared/models/points/transaction.models';
import { PayChannelTypeEnum } from '../../../shared/enums/pay-channel-type';
import { ExternalRoutingHelper } from '../../router/external-routing-helper';
import { UserPointDetailViewModel } from '../../../shared/models/user/user-point-detail-view.model';
import { UserPointsSearchOptions } from '../../../shared/models/user/userPoints-search-options';
import { map } from 'rxjs/operators';
import { UserCurrencyInfoModel } from '../../../shared/models/user/currency-info.model';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { IUserProfileModel } from '@dida-shopping/dida-services/membership';


/**
 * 用户相关信息数据服务
 *
 * @export
 * @class UserService
 */
@Injectable()
export class UserService {
  private favoriteHotelSubject = new BehaviorSubject<Array<FavoriteHotelDetail>>([]);
  private _loadingHotel = false;

  constructor(private logger: LoggerService, private httpClient: HttpClient) {

  }

  /**
   * 获取用户数据
   * @param userId 用户Id
   */
  getUserInfo(userId: string) {
    return this.httpClient.post<IUserProfileModel>('/UserApi/GetDetail', { userId }).pipe(
      map(resp => resp || {} as any) // new UserProfileModel(resp))
    );
  }

  /**
   * 删除用户
   * @param userId 用户ID
   */
  deleteAccount(userId: string) {
    return this.httpClient.post<IUserProfileModel>('/UserApi/CleanupUser', { userId });
  }

  /**
   * 获取收藏酒店信息
   *
   * @returns {Observable<Array<FavoriteHotelDetail>>}
   * @memberof UserService
   */
  observeFavoriteHotelInfo(): Observable<Array<FavoriteHotelDetail>> {
    if (!this._loadingHotel) {
      this._loadingHotel = true;
      this.httpClient.get('/UserFavoriteHotelApi/Get').subscribe(
        (hotels: Array<FavoriteHotelDetail>) => {
          this.favoriteHotelSubject.next(hotels);
        },
        err => this.favoriteHotelSubject.error(err)
      ).add(() => {
        this._loadingHotel = false;
      });

    }
    return this.favoriteHotelSubject.asObservable();
  }

  /**
   * 添加收藏酒店
   *
   * @param {number} hotelId 酒店ID
   * @returns {void}
   * @memberof UserService
   */
  addFavoriteHotel(hotelId: number): Observable<boolean> {
    let that = this;
    return Observable.create((observer: Observer<boolean>) => {
      let payload = {
        hotelId,
        option: null /*不传option时必须设为null, 否则后台报错*/
      };
      this.httpClient.post('/UserFavoriteHotelApi/Add', payload).subscribe(
        (hotels: Array<FavoriteHotelDetail>) => {
          that.favoriteHotelSubject.next(hotels);
          observer.next(true);
        },
        err => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  /**
   * 移除收藏酒店
   *
   * @param {number} hotelId 酒店ID
   * @returns {void}
   * @memberof UserService
   */
  removeFavoriteHotel(hotelId: number): Observable<boolean> {
    let that = this;
    return Observable.create((observer: Observer<boolean>) => {
      let payload = {
        hotelId,
        option: null /*不传option时必须设为null, 否则后台报错*/
      };
      this.httpClient.post('/UserFavoriteHotelApi/Remove', payload).subscribe(
        (hotels: Array<FavoriteHotelDetail>) => {
          that.favoriteHotelSubject.next(hotels);
          observer.next(false);
        },
        err => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  changeEmail(payload: any) {

    return this.httpClient.post('/AccountApi/ChangeEmail', payload);
  }

  changePassword(payload: any) {
    return this.httpClient.post('/AccountApi/ChangePassword', payload);
  }

  resendEmailConfirmation() {
    return this.httpClient.post('/AccountApi/ResendEmailConfirmation', {});
  }

  checkUserNameExist(userName: string) {
    return this.httpClient.get<boolean>('/UserApi/CheckUserNameExist', { userName });
  }

  checkEmailExist(email: string) {
    return this.httpClient.get<boolean>('/UserApi/CheckEmailExist', { email: encodeURIComponent(email) });
  }

  checkSensitiveKeyword(content: string) {
    return this.httpClient.get<boolean>('/ContentValidateApi/CheckSensitiveKeyword', { content });
  }

  updateClientUserInfo(clientUser: IUserProfileModel, password?: string, passwordConfirm?: string) {
    return this.httpClient.post<IUserProfileModel>('/UserApi/UpdateClientUserInfo', {
      clientUser, password, passwordConfirm, clearNotificationEmail: clientUser.NotificationEmails.length === 0
    }, null, null, (payload: any) => {
      this._preProcessLangType(payload.clientUser);
    });
  }

  searchSubClientUser(keyword = '', userStatus = 1, pageNum = 1, pageSize = 20) {
    return this.httpClient.get<PaginationWrapper<IUserProfileModel>>('/UserApi/SearchSubClientUser', {
      keyword, pageNum, pageSize, userStatus
    }).pipe(
      map(page => {
        page.Data.map(profile => profile || {} as any); //  new UserProfileModel(profile)
        return page;
      })
    );
  }

  addSubAccount(form: IUserProfileModel, password: string, passwordConfirm: string) {
    this._preProcessLangType(form);
    return this.httpClient.post<IUserProfileModel>('/RegisterApi/CreateClientSubAccountUser', {
      form, password, passwordConfirm
    }).pipe(
      map(userProfile => userProfile || {} as any) //  new UserProfileModel(userProfile)
    );
  }

  getSubAccountPermission(userName: string) {
    return this.httpClient.get<SubAccountPermissionViewModel>('/UserApi/GetSubAccountPermission', { userName });
  }

  updateSubAccountPermission(model: SubAccountPermissionViewModel) {
    return this.httpClient.post<SubAccountPermissionViewModel>('/UserApi/UpdateSubAccountPermission', { model });
  }

  getUserPointsTradeRecords(pageNum = 1, pageSize = 10) {
    return this.httpClient.post<PaginationWrapper<TransactionRecord>>('/UserPointsApi/GetUserPointsTradeRecords', {
      statues: [RedeemRecordStatusTypeEnum.Confirmed, RedeemRecordStatusTypeEnum.Failed],
      pageNum, pageSize
    });
  }

  /**
   * 获取邀请记录
   */
  getInivationRecords(pageNum = 1, pageSize = 10) {
    return this.httpClient.post<PaginationWrapper<InvitationModel>>('/UserInvitationApi/GetInivationRecords', {
      pageNum,
      pageSize
    });
  }

  getUserRedeemRecords(pageNum = 1, pageSize = 10) {
    let options = {
      statues: [RedeemRecordStatusTypeEnum.Confirmed, RedeemRecordStatusTypeEnum.Failed],
      pageNum,
      pageSize
    };
    return this.httpClient.post<PaginationWrapper<UserRedeemRecordModel>>('/UserPointsApi/GetUserRedeemRecords', { options });
  }

  getUserPointsViewList(options: UserPointsSearchOptions, pageNum = 1, pageSize = 10) {
    return this.httpClient.post<PaginationWrapper<UserPointDetailViewModel>>('/UserPointsApi/GetUserPointsViewList',
      { options, pageNum, pageSize });
  }

  /**
   * 兑换签到积分
   */
  redeemCheckinPoints() {
    return this.httpClient.post<DailyRedeemInfoModel>('/UserPointsApi/RedeemSignInPoints', {});
  }

  getCheckinPointsInfo() {
    return this.httpClient.get<DailyRedeemInfoModel>('/UserPointsApi/GetCheckinPointsInfo');
  }

  inviteNewUser(model: InvitationViewModel) {
    return this.httpClient.post<boolean>('/UserInvitationApi/Invitation', { model });
  }

  updateCurrencySetting(currency: string) {
    return this.httpClient.post<UserCurrencyInfoModel>('/AccountApi/UpdateCurrencySetting', { currencyCode: currency });
  }

  updateLanguageSetting(lang: string) {
    this.httpClient.post<boolean>('/AccountApi/UpdateLanguageSetting', { lang }).subscribe({
      next: (success) => {
        // do nothing
      }
    }).add(() => {
      window.location.reload(); // reload the page
    });
  }

  getBalanceLogList(payChannelType: PayChannelTypeEnum = null, pageNum = 1, pageSize = 10) {
    return this.httpClient.post<PaginationWrapper<BalanceLogModel>>('/HotelBookingApi/GetBalanceLogList',
      { payChannelType, pageNum, pageSize });
  }

  exportBalanceLogList(payChannelType: PayChannelTypeEnum = null) {
    let url = `/HotelBookingApi/ExportBalanceLogList?payChannelType=${payChannelType}`;
    ExternalRoutingHelper.navigateTo(url, true);
  }

  private _preProcessLangType(userProfile: any) {
    let lang = null;
    switch (userProfile.Language) {
      case LanguageType.en_US: {
        lang = 1;
        break;
      }
      case LanguageType.zh_CN: {
        lang = 2;
        break;
      }
    }
    userProfile.Language = lang;

  }
}
