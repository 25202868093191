
import { KeywordHelper } from '@dida-shopping/dida-services/common';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { SuggestionItemCategory } from './suggestion-item-category';
import { SuggestionQueryPayload } from './suggestion-query-payload.model';
import { SuggestionItem } from './suggestion-item.model';
import { SuggestionOption } from './suggestion-typeahead.model';

export class SuggestionSearchHelper {
  static readonly REGION_SEARCH_CATEGORIES = [
    SuggestionItemCategory.City,
    SuggestionItemCategory.Neighborhood,
    SuggestionItemCategory.MultiCity,
    SuggestionItemCategory.MultiRegion,
    SuggestionItemCategory.HighLevelRegion,
    SuggestionItemCategory.Province
  ];

  static readonly SUB_REGION_SEARCH_CATEGORIES = [
    SuggestionItemCategory.City,
    SuggestionItemCategory.Neighborhood
  ];

  static readonly HOTEL_SEARCH_CATEGORIES = [SuggestionItemCategory.Hotel];

  static readonly REGION_SEARCH_BY_ID_CATEGORIES = [
    SuggestionItemCategory.POI,
    SuggestionItemCategory.POIS,
    SuggestionItemCategory.City,
    SuggestionItemCategory.Neighborhood,
    SuggestionItemCategory.MultiCity,
    SuggestionItemCategory.MultiRegion,
    SuggestionItemCategory.HighLevelRegion,
    SuggestionItemCategory.Province
  ];

  static readonly AIRPORT_SEARCH_CATEGORIES = [SuggestionItemCategory.Airport];

  static readonly POI_SEARCH_CATEGORIES = [
    SuggestionItemCategory.POIS,
    SuggestionItemCategory.POI
  ];

  static readonly NEED_SEARCH_IN_PARENT_CATEGORIES = [
    SuggestionItemCategory.City,
    SuggestionItemCategory.Neighborhood,
    SuggestionItemCategory.POIS,
    SuggestionItemCategory.POI
  ];

  static readonly SPECIAL_PARENT_ID_FOR_SEARCH = new Set([
    '77', // Hong Kong SAR
    '104', // Macau SAR
  ]);

  // TODO: 后台没有返回SuggestionItemCategory，这里直接判断字符串
  static readonly IGNORE_DRILL_UP_CATEGORIES = [
    // SuggestionItemCategory.Country,
    'Country',
    // SuggestionItemCategory.Continent
    'Continent',
    // SuggestionItemCategory.Province,
    'Province (State)'
  ];

  static shouldSearchInParentRegion(option: SuggestionOption) {
    // 部分City没有所属的MultiCity
    return (
      SuggestionSearchHelper.NEED_SEARCH_IN_PARENT_CATEGORIES.includes(option.category) ||
      SuggestionSearchHelper.SPECIAL_PARENT_ID_FOR_SEARCH.has(option.parentId)
    ) && option.parentId
  }

  static createSuggestionQueryPayload(
    keyword: string,
    categories: SuggestionItemCategory[],
    size: number,
    ids?: string[],
    parentID?: string,
    transferLocations?: string[]
  ): SuggestionQueryPayload {
    const result: SuggestionQueryPayload = new SuggestionQueryPayload();
    result.IDs = ids;
    result.Keyword = keyword;
    result.Size = size;
    result.Categories = categories;
    result.ParentID = parentID;
    result.Language = LanguageType.en_US;
    result.PaginationOption = { PageNum: -1 };
    result.WithMetadata = true;
    result.WithHierarchy = true;
    result.WithItemObject = true;
    result.TransferLocations = transferLocations;
    return result;
  }

  static getName(item: SuggestionItem, lang: LanguageType): string {
    let name = null;
    if (lang === LanguageType.zh_CN) {
      if (item.Category === SuggestionItemCategory.Hotel) {
        name = item.ItemObject.ChineseName;
      } else if (item.Category === SuggestionItemCategory.Airport) {
        name = item.ItemObject.Name_FullCN;
      } else {
        name = item.ItemObject.RegionNameLong_CN;
      }
    }
    return name ? name : item.DisplayOption.FullName;
  }

  static getOldName(suggestion: SuggestionItem, keyword: string, lang: LanguageType) {
    let name = null;
    if (
      suggestion.Category === SuggestionItemCategory.Hotel &&
      suggestion.ItemObject.AliasList
    ) {
      if (KeywordHelper.hasMatch(this.getName(suggestion, lang), keyword)) {
        return name;
      }
      const aliasArr = suggestion.ItemObject.AliasList as {
        Alias: string;
        Language: number;
        IsChecked: boolean;
      }[];

      const alias = aliasArr.find(item =>
        KeywordHelper.hasMatch(item.Alias, keyword)
      );
      name = alias && alias.IsChecked && alias.Alias;
    }
    return name;
  }

  static getAddress(
    item: SuggestionItem,
    lang: LanguageType = LanguageType.zh_CN,
  ): { destinationName: string; countryName: string } {
    const address = { destinationName: '', countryName: '' };

    if (item.Category === SuggestionItemCategory.Airport) {
      // TODO
    } else if (item.Category === SuggestionItemCategory.Hotel) {
      if (lang === LanguageType.zh_CN) {
        address.destinationName = item.ItemObject.DestinationName_CN;
        address.countryName = item.ItemObject.CountryName_CN;
      }
      if (!address.destinationName || !address.countryName) {
        address.destinationName = item.ItemObject.DestinationName;
        address.countryName = item.ItemObject.CountryName;
      }
    }
    return address;
  }
}
