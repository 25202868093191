import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ExperimentHelper, ExperimentID } from '@dida-shopping/dida-services/experiment';
import { Observable, Subscription } from 'rxjs';
import {
  ApplicationEventHub,
  ApplicationExperimentInfoChangeEvent,
  ApplicationService
} from '@dida-shopping/ngx-dida-site-core';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[ndExperiment]'
})
export class DidaUITKExperimentControlDirective implements OnInit, OnDestroy {

  @Input() set ndExperiment(setting: ExperimentID | ExperimentID[] | { includes?: ExperimentID[], excludes?: ExperimentID[], ignoreEmpty?: boolean }) {
    if (setting) {
      if (setting instanceof Array) {
        this.includes = setting;
        this.excludes = null;
      } else if (typeof setting === 'object') {
        this.includes = setting.includes;
        this.excludes = setting.excludes;
        this.ignoreEmpty = !!setting.ignoreEmpty;
      } else if (!isNaN(setting)) {
        this.includes = [setting];
        this.excludes = null;
      } else {
        this.includes = null;
        this.excludes = null;
      }
    }
    this._updateView();
  }

  private subscription: Subscription;

  visible = false;

  private includes?: ExperimentID[];
  private excludes?: ExperimentID[];
  private ignoreEmpty = false;


  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private appService: ApplicationService,
    private appEventHub: ApplicationEventHub) {


  }

  ngOnInit(): void {
    this.subscription = (this.appEventHub.events.pipe(
      filter(event => event instanceof ApplicationExperimentInfoChangeEvent)
    ) as Observable<ApplicationExperimentInfoChangeEvent>).subscribe(
      (event) => {
        this._updateView();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private _updateView() {
    const prevState = this.visible;
    if (this.ignoreEmpty) {
      if ((this.includes == null || this.includes.length === 0) && (this.excludes == null || this.excludes.length === 0)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }
    }
    const curState = ExperimentHelper.isAllowControl({
      includes: this.includes,
      excludes: this.excludes
    }, this.appService.storage);
    if (curState !== prevState) {
      this.visible = curState;
      if (!this.visible) {
        this.viewContainer.clear();
      } else if (this.visible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }
}
