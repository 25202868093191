import { CompilerOptions } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  didaNgxApplicationBootstrap, IDidaNgxApplicationBootstrapOption
} from '@dida-shopping/ngx-dida-site-core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const bootstrapOption: IDidaNgxApplicationBootstrapOption<AppModule> = {
  appEnv: environment,
  boosterBuilder: (options: CompilerOptions) => {
    return platformBrowserDynamic(options.providers).bootstrapModule(AppModule, options);
  },
  // translationResolver: environment.translationResolver, // REFACTORING REQUIRED! COMMONJS MODULE COULD NO BE TREE-SHAKE.
  siteRouteJsonResolver: () => require(`./site-route.config.json`)
};

didaNgxApplicationBootstrap(bootstrapOption);
