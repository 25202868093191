import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, inject, isDevMode, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureGuideService, ngxHttpCall } from '@dida-shopping/ngx-dida-site-core';
import { filter, flatMap, map, mergeAll, skipWhile, withLatestFrom } from 'rxjs/operators';
import { EntityCategoryEnum, resourceService } from '@dida-shopping/dida-services/resource';
import { FeatureGuideModel, FeatureGuideStepModel } from './feature-guide.model';
import { concat, merge, of } from 'rxjs';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { NzPopoverComponent, NzPopoverDirective } from 'ng-zorro-antd/popover';

@Component({
  selector: 'nd-feature-guide',
  template: `
    <div class="feature-guide" *ngIf="currentStep">
      <div class="feature-guide__mask">
        <div
          class="feature-guide__window"
          #stepPopover="nzPopover"
          nz-popover
          [nzPopoverVisible]="true"
          [nzPopoverContent]="popoverContent"
          [nzPopoverBackdrop]="true"
          [nzPopoverPlacement]="'bottom'"
          [nzPopoverTrigger]="null"
        >
        <ng-template #popoverContent>
          <div class="feature-guide__body">
            {{currentStep.Desc}}
          </div>
          <div class="feature-guide__footer">
            <button nz-button nzType="default" nzSize="small" (click)="onCancel()" *ngIf="!isLastStep">
              <ng-container i18n="@@Button_FeatureGuide_Skip">跳过</ng-container>
            </button>
            <button nz-button nzType="primary" nzSize="small" (click)="onConfirm()">
              <ng-container *ngIf="isLastStep" i18n="@@Button_FeatureGuide_StepFinish">完成</ng-container>
              <ng-container *ngIf="!isLastStep" i18n="@@Button_FeatureGuide_StepNext">下一步</ng-container>
            </button>
          </div>
        </ng-template>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./feature-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DidaUITKNavFeatureGuideComponent implements OnInit, OnDestroy, AfterViewChecked {
  readonly FeatureGuideStorageKey = 'f_e_flags';
  readonly FeatureGuideStorageDivider = '|';
  featureGuideList: FeatureGuideModel[];
  targetPostion: Partial<DOMRect>;
  currentGuide: FeatureGuideModel;
  currentStep: FeatureGuideStepModel;
  currentStepList: FeatureGuideStepModel[];
  currentTarget: HTMLElement
  isLastStep = false;

  okText: string;
  cancelText: string;

  get featureGuideFlags(): string[] {
    return (localStorage.getItem(this.FeatureGuideStorageKey) || '').split(this.FeatureGuideStorageDivider);
  }

  @ViewChild('stepPopover')
  popover: NzPopoverDirective;

  constructor(
    private router: Router,
    private featureGuideService: FeatureGuideService,
    @Inject(LOCALE_ID) private lang: LanguageType,
    private cdr: ChangeDetectorRef
  ) {}

  markCurrentGuideAsRead() {
    let keys = this.featureGuideFlags;
    keys.push(this.currentGuide.ID);
    localStorage.setItem(this.FeatureGuideStorageKey, keys.join(this.FeatureGuideStorageDivider));
    this.currentStep = null;
    this.currentGuide = null;
    this.cdr.markForCheck()
  }

  ngOnInit(): void {
    ngxHttpCall(resourceService.getResourceEntity<FeatureGuideModel[]>(EntityCategoryEnum.CommonData, 'FeatureGuide'))
      .pipe(
        map(({ EntityValue: featureGuideList }) => {
          return featureGuideList
            .map((guide) => new FeatureGuideModel(guide).switchLanguage(this.lang))
            .filter((guide) => !guide.expired && !this.featureGuideFlags.includes(guide.ID));
        }),
        skipWhile((featureGuideList) => featureGuideList.length === 0)
      )
      .subscribe({
        next: (featureGuideList) => {
          let autoDetectList = featureGuideList.filter((guide) => guide.AutoDetect);
          if (autoDetectList.length) {
            // console.log('autoDetectList');
            merge([of(1), this.router.events.pipe(filter((item) => item instanceof NavigationEnd))]).subscribe(() => {
              this.checkAvailableGuide(autoDetectList);
            });
          }
          let manualGuideList = featureGuideList.filter((guide) => !guide.AutoDetect);
          if (manualGuideList.length) {
            // console.log('manualGuideList');
            this.featureGuideService.onDetect.subscribe(() => {
              this.checkAvailableGuide(manualGuideList);
            });
          }
        },
      });

    this.featureGuideService.onDetect.subscribe((event) => {});
  }

  ngOnDestroy(): void {
    // if (!isDevMode()) {
    //   localStorage.setItem(this.FeatureGuideStorageKey, this.featureGuideFlags.join(this.FeatureGuideStorageDivider));
    // }
  }

  checkAvailableGuide(guideList: FeatureGuideModel[]) {
    let guideIndex = guideList.findIndex((guide) => new RegExp(guide.Path).test(location.pathname));
    if (guideIndex < 0) {
      return;
    }
    let guide = guideList.splice(guideIndex, 1)[0];
    if (guide) {
      this.currentGuide = guide;
      this.currentStepList = guide.Steps;
      setTimeout(() => {
        this.handleFeatureGuideStep();
      }, 1000);
    }
  }

  handleFeatureGuideStep() {
    const step = (this.currentStep = this.currentStepList.shift());

    if (!step) {
      this.markCurrentGuideAsRead()
      return;
    }

    this.cdr.markForCheck();

    const target = this.currentTarget = document.querySelector(step.Target);
    if (!target) {
      this.handleFeatureGuideStep();
      return;
    }
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    this.isLastStep = this.currentStepList.length === 0;
    let targetPostion = this.currentTarget.getBoundingClientRect();
    setTimeout(() => {
      this.popover.elementRef.nativeElement.style.left = targetPostion.left + 'px'
      this.popover.elementRef.nativeElement.style.height = targetPostion.height + 'px'
      this.popover.elementRef.nativeElement.style.width = targetPostion.width + 'px'
      this.popover.elementRef.nativeElement.style.top = targetPostion.top + document.documentElement.scrollTop + 'px'
      setTimeout(() => {
        this.popover && this.popover.updatePosition()
      })
    }, 0);
  }

  ngAfterViewChecked() {
  }

  onCancel() {
    this.markCurrentGuideAsRead();
  }

  onConfirm() {
    this.handleFeatureGuideStep();
  }
}
