import { Injectable } from '@angular/core';
import { DidaHotelSearchPayload } from '../../../hotels/shared/hotel-search/hotel-search.model';
import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import { HotelChainModel } from '../../../shared/models/hotel/hotel-chain';
import { HotelPriceSearchOptions } from '../../../shared/models/rates/rates.models';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
@Injectable()
export class HotelSearchContextService {
  public payload: DidaHotelSearchPayload;

  constructor(private logger: LoggerService, private httpClient: HttpClient) {}

  /**
   * 获取酒店品牌
   * @param {HotelPriceSearchOptions} options
   * @returns {HttpObservable<HotelChainModel>}
   * @memberof HotelSearchContextService
   */
  getHotelChainList(
    options: HotelPriceSearchOptions
  ) {
    return this.httpClient.post<HotelChainModel[]>(
      '/HotelApi/GetHotelChainList',
      {
        options: options
      },
      null,
      mdl => {},
      payload => {
        const option: HotelPriceSearchOptions = payload.options;
        if (option.PriceSearchFilter && option.PriceSearchFilter.Location) {
          if (option.PriceSearchFilter.Location instanceof GeoCoordinate) {
            option.PriceSearchFilter.Location =
              option.PriceSearchFilter.Location.serviceCoordinate;
          }
        }
      }
    );
  }
}
