import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../../core/logger/logger.service';
import { ExperimentHelper, ExperimentID } from '@dida-shopping/dida-services/experiment';
import {
  ApplicationEventHub,
  ApplicationExperimentInfoChangeEvent,
  ApplicationService
} from '@dida-shopping/ngx-dida-site-core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * A|B测试控制指令
 *
 *
 *
 * @author Roman Lo
 *
 * @example
 * 1. 当用户存在Experiment 1,2,3其一时，显示该`<p>`元素:
 *    <p *didaExperiment="[1,2,3]"></p>
 *
 * 2. 当用户存在Experiment 1,2,3其一，且不在当用户存在Experiment 22,23里时，显示该`<p>`元素:
 *    <p *didaExperiment="{ includes: [1,2,3], excludes: [22,23] }"></p>
 *
 * 3. 当用户不存在Experiment 22,23里时，显示该`<p>`元素:
 *    <p *didaExperiment="{ excludes: [22,23] }"></p>
 */
@Directive({
  selector: '[didaExperiment]'
})
export class ExperimentControlDirective implements OnInit, OnDestroy {

  private subscription: Subscription;

  visible = false;

  private includes?: ExperimentID[];
  private excludes?: ExperimentID[];
  private ignoreEmpty = false;

  @Input() set didaExperiment(setting: ExperimentID[] | { includes?: ExperimentID[], excludes?: ExperimentID[], ignoreEmpty?: boolean }) {
    if (setting instanceof Array) {
      this.includes = setting;
      this.excludes = null;
    } else {
      this.includes = setting.includes;
      this.excludes = setting.excludes;
      this.ignoreEmpty = !!setting.ignoreEmpty;
    }
    this.logger.debug(`includes: ${this.includes} - excludes: ${this.excludes}`);
    this._updateView();
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private appService: ApplicationService,
    private appEventHub: ApplicationEventHub,
    private logger: LoggerService) {


  }

  ngOnInit(): void {
    this.subscription = (this.appEventHub.events.pipe(
      filter(event => event instanceof ApplicationExperimentInfoChangeEvent)
    ) as Observable<ApplicationExperimentInfoChangeEvent>).subscribe(
      (event) => {
        this._updateView();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private _updateView() {
    let prevState = this.visible;
    // let fitIncludes = true;
    // let fitExcludes = true;
    if (this.ignoreEmpty) {
      if ((this.includes == null || this.includes.length === 0) && (this.excludes == null || this.excludes.length === 0)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }
    }
    // if (this.includes != null) {
    //   fitIncludes = this.expService.hasAnyExperiments(this.includes);
    // }
    // if (this.excludes != null) {
    //   fitExcludes = !this.expService.hasAnyExperiments(this.excludes);
    // }
    const curState = ExperimentHelper.isAllowControl({
      includes: this.includes,
      excludes: this.excludes
    }, this.appService.storage);
    if (curState !== prevState) {
      this.visible = curState;
      if (!this.visible) {
        this.viewContainer.clear();
      } else if (this.visible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      this.logger.debug('view updated', this.visible);
    } else {
      this.logger.debug('view update ignored', this.visible);
    }
  }

}

