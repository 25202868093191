import { SERVER_CONFIG } from '@dida-shopping/ngx-dida-site-core';

export enum HttpProtocolTypeEnum {
    HTTP,
    HTTPS,
}

export const APP_PROTOCOL: HttpProtocolTypeEnum =
    window.location.href.split('/')[0] === 'https:' ? HttpProtocolTypeEnum.HTTPS : HttpProtocolTypeEnum.HTTP;

export class ExternalRoutingHelper {
    /**
     * 强制重载到外部页面
     *
     * @static
     * @param {string} externalUrl
     * @param {boolean} [openNewWindow=false]
     * @param {HttpProtocolTypeEnum} [protocal=null]
     * @memberof ExternalRoutingHelper
     */
    static navigateTo(externalUrl: string, openNewWindow: boolean = false, protocal: HttpProtocolTypeEnum = null): void {
        let actualUrl = ExternalRoutingHelper.constructActualExternalUrl(externalUrl, protocal);
        if (openNewWindow) {
            window.open(actualUrl, '_blank');
        } else {
            window.location.href = actualUrl;
        }
    }

    /**
     * 构建外部链接
     *
     * @static
     * @param {string} externalUrl
     * @param {HttpProtocolTypeEnum} [protocal=null]
     * @returns {string}
     * @memberof ExternalRoutingHelper
     */
    static constructActualExternalUrl(externalUrl: string, protocal: HttpProtocolTypeEnum = null): string {
        let actualUrl = externalUrl;
        if (actualUrl.startsWith('/')) {
            // redirect to old site
            if (SERVER_CONFIG.oldSiteUrl) {
                if (protocal == null) {
                    protocal = APP_PROTOCOL;
                }
                if (protocal === HttpProtocolTypeEnum.HTTP) {
                    actualUrl = `http://${SERVER_CONFIG.oldSiteUrl}${actualUrl}`;
                } else if (protocal === HttpProtocolTypeEnum.HTTPS) {
                    actualUrl = `https://${SERVER_CONFIG.oldSiteUrl}${actualUrl}`;
                } else {
                    actualUrl = `${SERVER_CONFIG.oldSiteUrl}${actualUrl}`;
                }
            } else {
                actualUrl = '/Api' + actualUrl;
            }
        }
        return actualUrl;
    }
}
