import { IMembershipRegisterFormModel } from './models';
import { HttpServiceBase } from '../http';

class MembershipRegisterService extends HttpServiceBase {

  constructor() {
    super();
  }

  register(form: IMembershipRegisterFormModel, sr = '', onSuccess: () => void = null) {
    let url = `/AccountApi/UserRegister`;
    if (sr) {
      url = `${url}?sr=${sr}`;
    }
    return this.httpClientWrapper
      .post<boolean>(url, form, {
        resultProcessor: (result: boolean) => {
          if (result && onSuccess) {
            onSuccess();
          }
          return result;
        }
      });
  }

}


export const membershipRegisterService = new MembershipRegisterService();
