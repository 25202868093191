import { ImageLinkModel } from './image-link.model';

export class ActivityBannerModel extends ImageLinkModel {
  constructor(payload?: ActivityBannerModel) {
    super(payload)
  }
  BannerType: string;
  DefaultReferenceUrl: string;

  WithActivity: boolean;
}
