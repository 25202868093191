import { IPaginationWrapper } from '@dida-shopping/dida-services/common';
import { IMessageSketchModel } from '@dida-shopping/dida-services/message';

export interface IUnreadMessageUpdateResult {
  hasUpdated: boolean;
}

interface IMessageRefType {
  idx: number;
}

class UserMessageStorage {
  private _unreadMessages: IPaginationWrapper<IMessageSketchModel>;
  private _msgId2MsgRef: { [msgId: number]: IMessageRefType };
  private _cacheTime: Date;
  private _isInited: boolean;

  constructor() {
    this._unreadMessages = {
      Data: [],
      TotalAmount: 0,
      PageNum: 0,
      PageSize: 0
    };
    this._msgId2MsgRef = {};
    this._cacheTime = new Date(1970, 1, 1);
  }

  public setUnreadMessages(
    page: IPaginationWrapper<IMessageSketchModel>,
    time: Date = new Date()
  ): IUnreadMessageUpdateResult {
    const result: IUnreadMessageUpdateResult = {
      hasUpdated: false
    };

    let needUpdateCache = false
    if(this._isInited) {
      needUpdateCache = this._unreadMessages.TotalAmount !== page.TotalAmount;
      if (!needUpdateCache) {
        for (let i = 0; i < page.Data.length; i++) {
          const item = page.Data[i];
          const tarMsgRef = this._msgId2MsgRef[item.MessageID];
          if (!tarMsgRef) {
            needUpdateCache = true;
            break;
          }
        }
      }
    } else {
      this._isInited = true;
      needUpdateCache = true;
    }

    if (needUpdateCache) {
      this.syncCache(page, time);
      result.hasUpdated = true;
    }

    return result;
  }

  public removeUnreadMessages(messageIdList: number[]): IUnreadMessageUpdateResult {
    const result: IUnreadMessageUpdateResult = {
      hasUpdated: false
    };
    const time = new Date();
    const targets = messageIdList.filter(x => !!this._msgId2MsgRef[x]);
    if (targets.length > 0) {
      const targetIdSet = new Set<number>(targets);
      const page: IPaginationWrapper<IMessageSketchModel> = {
        Data: this._unreadMessages.Data.filter(x => !targetIdSet.has(x.MessageID)),
        TotalAmount: this._unreadMessages.TotalAmount - targets.length,
        PageSize: this._unreadMessages.PageSize,
        PageNum: this._unreadMessages.PageNum,
      }
      this.syncCache(page, time);
      result.hasUpdated = true;
    }
    return result;
  }

  public getUnreadMessages(): IMessageSketchModel[] {
    return this._unreadMessages.Data.map(item => {
      return JSON.parse(JSON.stringify(item));
    });
  }

  public getUnreadMessageCount(): number {
    return this._unreadMessages && this._unreadMessages.TotalAmount || 0;
  }

  public getCacheTime(): Date {
    return new Date(this._cacheTime.getTime());
  }

  private syncCache(page: IPaginationWrapper<IMessageSketchModel>, time: Date) {
    this._unreadMessages.TotalAmount = page.TotalAmount;
    this._unreadMessages.PageNum = page.PageNum;
    this._unreadMessages.PageSize = page.PageSize;
    const msgId2MsgRef: { [msgId: number]: IMessageRefType } = {};
    this._unreadMessages.Data = page.Data.map((item, idx) => {
      msgId2MsgRef[item.MessageID] = { idx };
      return JSON.parse(JSON.stringify(item));
    });
    this._msgId2MsgRef = msgId2MsgRef;
    this._cacheTime = time;
  }

}

export const USER_MESSAGE_STORAGE = new UserMessageStorage();
