import { Observable, Subject } from 'rxjs'
import { Injectable } from '@angular/core'

export type PageThemeType = 'island' | 'default'

class DidaUITKLayoutService {
  private _pageTheme: PageThemeType = 'default'
  private _subject = new Subject<PageThemeType>()

  get observer() {
    return this._subject.asObservable()
  }

  setTheme(theme: PageThemeType) {
    this._pageTheme = theme
    this._subject.next(theme)
  }

  getTheme() {
    return this._pageTheme
  }
}

export const pageLayoutService = new DidaUITKLayoutService()
