import { ErrorCode } from '../../enums/error-code';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-login-error',
    templateUrl: './login-error.component.html',
    styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {

    @Input()
    error: any;

    ErrorCode = ErrorCode;

    constructor() { }
    ngOnInit() {
    }

}
