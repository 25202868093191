import { MessageExtraSetting } from './message.models';
import { ColorThemeEnum } from '../shared-models';

import { MessageCategoryTypeEnum } from '../../enums/message-category-type';


export class MessageSketchModel {
    MessageID: number;
    MessageTemplateID: number; // 20171013增加第二个维度

    UserID: string;

    CreateTime: Date | string;

    ExpireTime: Date | string | null;

    TitleDict: { [key: string]: string };

    IsRead: boolean;

    Category: MessageCategoryTypeEnum;

    /**跟BookingId相关的UserId，用于权限过滤，发送消息时请填写 */
    ExactAudienceReference: string;

    /**这个字段暂时存储为BookingId */
    EntityReference: string;

    /**额外的消息参数 */
    Params: Object | string;

    ExtraSetting: MessageExtraSetting;

    get UseTemplate() {
        if (this.Params === null) {
            return false;
        }
        if (this.Category === MessageCategoryTypeEnum.WorkOrderStatusChange) {
            return !!this.Params['LogType'];
        }
        return false;
    }

    get ColorTheme() {
        if (this.Category === MessageCategoryTypeEnum.BillStatusChange) {
            switch (this.MessageTemplateID) {
                case 40011: return ColorThemeEnum.Success;
                case 40008: return ColorThemeEnum.Primary;
                case 40010: return ColorThemeEnum.Danger;
                case 40009: return ColorThemeEnum.Waring;
            }
        }
        return '';
    }
}



export class BookingTicketMessageParamModel {
    WorkOrderID: number;
    ModifyType: number;
    LogType: number;
    Remark: string;
    ReferenceID: string;
}

