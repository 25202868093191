import { Injectable } from '@angular/core';
import { LoggerService } from '../../logger/logger.service';
import { HttpClient } from '../../http/http-client';
import {
  CouponProductSearchOptions,
  CouponProductModel
} from '../../../shared/models/points/coupon-market/coupon-market.models';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { ProductRedeemOptions } from '../../../shared/models/points/redeem.models';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { DateTimeRangeRestrictionModel } from '../../../shared/models/campaigns/coupons/date-time-range-restriction.model';

/**
 * 积分商城优惠券接口
 *
 * @export
 * @class CouponMarketService
 */
@Injectable()
export class CouponMarketService {
  constructor(private logger: LoggerService, private httpClient: HttpClient) {}

  getCouponProducts(
    options: CouponProductSearchOptions
  ) {
    return this.httpClient.get<PaginationWrapper<CouponProductModel>>(
      '/CouponMarketApi/GetProducts',
      { options },
      null,
      result => {
        if (result && result.Data) {
          result.Data.forEach(item => {
            this.processDateTimeRangeRestrictionField(
              item.EffectiveTimeSetting
            );
            if (item.RestrictionRuleModel) {
              this.processDateTimeRangeRestrictionField(
                item.RestrictionRuleModel.CheckInDateRestriction
              );
              this.processDateTimeRangeRestrictionField(
                item.RestrictionRuleModel.CheckOutDateRestriction
              );
              this.processDateTimeRangeRestrictionField(
                item.RestrictionRuleModel.OrderTimeRestriction
              );
            }
          });
        }
      }
    );
  }

  purchaseCoupon(options: ProductRedeemOptions) {
    return this.httpClient.post<number>('/CouponMarketApi/PurchaseCoupon', { options });
  }

  private processDateTimeRangeRestrictionField(
    restriction: DateTimeRangeRestrictionModel
  ) {
    if (restriction) {
      restriction.From = DateTimeHelper.parse(restriction.From, true);
      restriction.To = DateTimeHelper.parse(restriction.To, true);
    }
  }
}
