import { IUserFavoriteHotelDetail } from '../hotel';

interface IHotelRefType {
  idx: number;
}

export interface IFavoriteHotelUpdateResult {
  hasUpdated: boolean;
}

class UserFavoriteHotelStorage {

  private _data: IUserFavoriteHotelDetail[];
  private _hotelId2Ref: { [hotelId: number]: IHotelRefType };
  private _cacheTime: Date;

  constructor() {
    this._data = [];
    this._hotelId2Ref = {};
    this._cacheTime = new Date(1970, 1, 1);
  }

  setFavoriteHotels(
    hotels: IUserFavoriteHotelDetail[],
    time: Date = new Date()
  ): IFavoriteHotelUpdateResult {
    const result: IFavoriteHotelUpdateResult = {
      hasUpdated: false
    };
    let needUpdateCache = this._data.length !== hotels.length;
    if (!needUpdateCache) {
      for (let i = 0; i < hotels.length; i++) {
        const item = hotels[i];
        const tarRef = this._hotelId2Ref[item.HotelID];
        if (!tarRef) {
          needUpdateCache = true;
          break;
        }
      }
    }
    if (needUpdateCache) {
      this.syncCache(hotels, time);
      result.hasUpdated = true;
    }
    return result;
  }

  removeFavoriteHotels(hotelIdList: number[]): IFavoriteHotelUpdateResult {
    const result: IFavoriteHotelUpdateResult = {
      hasUpdated: false
    };
    const time = new Date();
    const targets = hotelIdList.filter(x => !!this._hotelId2Ref[x]);
    if (targets.length > 0) {
      const targetIdSet = new Set<number>(targets);
      const data = this._data.filter(x => !targetIdSet.has(x.HotelID));
      this.syncCache(data, time);
      result.hasUpdated = true;
    }
    return result;
  }

  getCacheTime(): Date {
    return new Date(this._cacheTime.getTime());
  }

  getFavoriteHotels(): IUserFavoriteHotelDetail[] {
    return this._data.map(item => {
      return JSON.parse(JSON.stringify(item));
    });
  }

  getFavoriteHotelCount(): number {
    return this._data.length;
  }

  isFavoriteHotel(hotelId: number) {
    // eslint-disable-next-line no-prototype-builtins
    return this._hotelId2Ref.hasOwnProperty(hotelId.toString());
  }

  private syncCache(hotels: IUserFavoriteHotelDetail[], time: Date) {
    const hotelId2Ref: { [hotelId: number]: IHotelRefType } = {};
    this._data = hotels.map((item, idx) => {
      hotelId2Ref[item.HotelID] = { idx };
      return JSON.parse(JSON.stringify(item));
    });
    this._hotelId2Ref = hotelId2Ref;
    this._cacheTime = time;
  }
}

export const USER_FAVORITE_HOTEL_STORAGE = new UserFavoriteHotelStorage();
