import { ExperimentID } from './enums';
import { IExperimentControls } from './models';


export const CommonDisableControl: IExperimentControls = {
  includes: [99999]
};

export const BookingSettlementControl: IExperimentControls = {
  includes: [ExperimentID.BookingSettlement],
  excludes: [ExperimentID.ForbidBookingSettlement]
};

export const InvoiceControl: IExperimentControls = {
  includes: [ExperimentID.InvoiceApply],
  excludes: [ExperimentID.HideInvoiceApply]
};

export const InsuranceControl: IExperimentControls = {
  includes: [ExperimentID.AllowInsurance],
  excludes: [ExperimentID.ForbidInsurance]
};

export const PaymentChannel_WeChatControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_WeChat],
  excludes: [ExperimentID.PaymentChannel_WeChat_Forbidden]
};

export const PaymentChannel_DeferredPaymentControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_DeferredPayment],
  excludes: [ExperimentID.PaymentChannel_DeferredPayment_Forbidden]
};

export const PaymentChannel_AlipayControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_Alipay],
  excludes: [ExperimentID.PaymentChannel_Alipay_Forbidden]
};

export const PaymentChannel_OceanpaymentFooControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_Oceanpayment],
  excludes: [ExperimentID.PaymentChannel_Oceanpayment_Forbidden]
};

export const PaymentChannel_BalanceControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_Balance],
  excludes: [ExperimentID.PaymentChannel_Balance_Forbidden]
};

export const PaymentChannel_BaoFooControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_BaoFoo],
  excludes: [ExperimentID.PaymentChannel_BaoFoo_Forbidden]
};

export const PaymentChannel_WeChatMinAppPayControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_WeChatMinAppPay],
  excludes: [ExperimentID.PaymentChannel_WeChatMinAppPay_Forbidden]
};

export const PaymentChannel_NuveiFooControl: IExperimentControls = {
  includes: [ExperimentID.PaymentChannel_Nuvei],
  excludes: [ExperimentID.PaymentChannel_Nuvei_Forbidden]
};

export const SettlementPayChannel_AlipayControl: IExperimentControls = {
  includes: [ExperimentID.AllowSettlementPayChannel_Alipay],
  excludes: [ExperimentID.ForbidSettlementPayChannel_Alipay]
}

export const SettlementPayChannel_BaoFooControl: IExperimentControls = {
  includes: [ExperimentID.AllowSettlementPayChannel_BaoFoo],
  excludes: [ExperimentID.ForbidSettlementPayChannel_BaoFoo]
}

export const WorkOrderControl: IExperimentControls = {
  includes: [ExperimentID.WorkOrder],
  excludes: [ExperimentID.ForbidWorkOrder]
};

export const UserPointsControl: IExperimentControls = {
  includes: [ExperimentID.AllowUserPoints],
  excludes: [ExperimentID.ForbidUserPoints]
};

export const BalanceDetailControl: IExperimentControls = {
  includes: [ExperimentID.AllowBalanceDetail]
  // excludes: [ ExperimentID.bal ]
};

export const DidaActivityOkinawaSakuraControl: IExperimentControls = {
  includes: [ExperimentID.DidaActivity_OkinawaSakura]
  // excludes: [ ExperimentID.bal ]
};

export const DidaTransferFeatureControl: IExperimentControls = {
  includes: [ExperimentID.TransfersFeature],
  excludes: [ExperimentID.TransfersFeature_Forbidden]
};

// 暂时停用所有接送机入口
export const DidaTransferEntranceFeatureControl_Temp: IExperimentControls = CommonDisableControl || {
  includes: [ExperimentID.TransfersFeature],
  excludes: [ExperimentID.TransfersFeature_Forbidden]
};

/**
 * @description 优惠券的展示还涉及前端的一些hardcode，请使用CouponHelper.isAllowCoupon
 */
export const CouponControl: IExperimentControls = {
  includes: [ExperimentID.CouponFeature],
  excludes: [ExperimentID.CouponFeature_Forbidden]
};

export const DataStatisticsControl: IExperimentControls = {
  includes: [ExperimentID.DataStatisticsFeature],
  excludes: [ExperimentID.DataStatisticsFeature_Forbidden]
};

export const SpecialRatePlanControl: IExperimentControls = {
  includes: [ExperimentID.SpecialRatePlanFeature],
  excludes: [ExperimentID.SpecialRatePlanFeature_Forbidden]
};

export const DoubleUserPointsControl: IExperimentControls = {
  includes: [ExperimentID.DoubleUserPointsIncentive],
  excludes: [ExperimentID.DoubleUserPointsIncentive_Forbidden]
};

export const V2MapControl: IExperimentControls = {
  includes: [ExperimentID.DidaShoppingMapV2]
};

export const TicketStandardizationControl: IExperimentControls = {
  includes: [ExperimentID.TicketStandardizationFeature],
  excludes: [ExperimentID.TicketStandardizationFeature_Forbidden]
};

export const BookingExportControl: IExperimentControls = {
  excludes: [ExperimentID.ForbidBookingExport],
}

export const HotelPackageControl: IExperimentControls = {
  includes: [ExperimentID.HotelPackageFeature],
}
