import {
  APP_INITIALIZER,
  InjectionToken,
  Injector,
  Provider
} from '@angular/core';
import { NavigationStart, Router, RoutesRecognized, NavigationError } from '@angular/router';
import { IApplicationRouteRule } from './models';
import { APP_SITE_ROUTE_CONFIG } from '../app-site-route-config';
import { ngxHttpCall } from '../http';
import { siteRouteService } from '@dida-shopping/dida-services/config';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RouteRuleHelper } from './route-rule.helper';

export const DIDA_APP_NAME = new InjectionToken<{ value: string }>('DIDA_APP_NAME');
export const DIDA_APP_DEFAULT_ROUTE_PATH = new InjectionToken<{ value: string }>('DIDA_APP_DEFAULT_ROUTE_PATH');

function checkAndHandle(url: string, rules: IApplicationRouteRule[], router: Router, appDefaultPath, fromInit = false): boolean {
  const routeConfigCheckResult = checkRouteConfigs(url, rules, fromInit);
  if (!routeConfigCheckResult.isMatch) {
    // route check failed
    if (fromInit) {
      if (!routeConfigCheckResult.reloadingTriggered) {
        // route to '/' when application init
        router.navigateByUrl('/');
      }
    } else {
      // remain current page
      // const currentPath = window.location.pathname;
      // router.navigateByUrl(currentPath, {
      //   queryParamsHandling: "preserve",
      //   skipLocationChange: true,
      // });
      const origin = window.top.location.origin;
      let targetPath = appDefaultPath;
      let skipLocationChange = false;
      if (!routeConfigCheckResult.routeToDefaultPath) {
        // stay on the current path
        // BUGFIX: https://tower.im/teams/191049/todos/109962/
        // targetPath = window.top.location.href.replace(origin, '');
        skipLocationChange = true;
      }
      router.navigateByUrl(targetPath, {
        skipLocationChange: skipLocationChange,
      });
    }
    return false;
  }
  return true;
}

function didaCrossApplicationRoutingControl(router: Router, rules: IApplicationRouteRule[], defaultPath: string) {
  let navStartUrl: string = null;

  // Roman: Comment out after Upgrade to V11
  // /**
  //  * Bugfix: 路由配置里redirectTo会跳过路由检查导致无法跳转到其他app https://github.com/angular/angular/pull/40067
  //  * Todo: 升级angular, 并移除此段hardcode
  //  */
  // router.errorHandler = (error) => {
  //   let matchs = error.message.match(/^Cannot match any routes\. URL Segment: '(.+)'$/)
  //   if(matchs && matchs[1]) {
  //     let targetUrl = '/' + matchs[1]
  //     if(navStartUrl !== targetUrl) {
  //       setTimeout(() => {
  //         router.navigateByUrl(targetUrl)
  //       })
  //     }
  //     return
  //   }
  //
  //   throw error
  // }

  router.events.pipe(
    filter(event => event instanceof NavigationStart || event instanceof RoutesRecognized)
  ).subscribe((event: NavigationStart | RoutesRecognized) => {
    const currentPath = window.location.pathname;
    let url = event.url;
    let needCheckAndHandle = true;
    if (event instanceof NavigationStart) {
      console.log(`[didaCrossApplicationRoutingControl]: navigation: ${event.url}, current: ${currentPath}`);
      navStartUrl = event.url;
    } else if (event instanceof RoutesRecognized) {
      url = event.urlAfterRedirects || event.url;
      if (navStartUrl === url) {
        needCheckAndHandle = false;
      }
    }
    if (needCheckAndHandle) {
      checkAndHandle(url, rules, router, defaultPath);
    }
  });
}

function checkRouteRule(needle: string, rule: IApplicationRouteRule): boolean {
  let isMatch = false;
  if (typeof (rule.pattern) === 'string') {
    if (rule.pattern === '**') {
      isMatch = true;
    } else {
      if (rule.mode === 'partial-match') {
        if (needle.indexOf(rule.pattern.toUpperCase()) >= 0) {
          isMatch = true;
        }
      } else {
        // strict match
        if (needle === rule.pattern.toUpperCase()) {
          isMatch = true;
        }
      }
    }
  } else if (rule.pattern instanceof RegExp) {
    if (rule.pattern.test(needle)) {
      isMatch = true;
    }
  } else {
    throw new Error(`unknown rule pattern`);
  }
  return rule.matchNegative ? !isMatch : isMatch;
}

function checkRouteConfigs(url: string, rules: IApplicationRouteRule[], ignoreNotice = false): { isMatch: boolean, reloadingTriggered: boolean, routeToDefaultPath: boolean } {
  const needle = url.toUpperCase().split('?')[0];
  const unknownRules: IApplicationRouteRule[] = [];
  const result = { isMatch: true, reloadingTriggered: false, routeToDefaultPath: false };
  let isMatch = false;
  for (const rule of rules) {
    try {
      isMatch = checkRouteRule(needle, rule);
      if (isMatch) {
        if (rule.isExternal) {
          isMatch = false; // set isMatch to false to reload the page
        }
        break;
      }
    } catch (e) {
      // unknown rule pattern
      unknownRules.push(rule);
    }
  }
  if (unknownRules.length > 0) {
    console.error(`unknown rules detected. stop processing.`, unknownRules);
    throw Error(`unknown rules detected.`);
  }
  if (!isMatch) {
    result.isMatch = false;
    if (APP_SITE_ROUTE_CONFIG.noticeIfCrossSite) {
      if (!ignoreNotice) {
        // local detect application
        const subApp = url.split('/')[1];
        const subAppPort = {
          'main': 4200,
          'booking': 4201,
          'account': 4202,
          'home': 4203,
          'hotel': 4205,
          'marketing': 4204,
          'package': 4206
        }[subApp];

        const subAppDetected = !!subAppPort;
        let tarUrl: string = url;
        let routingMsg = ` Please copy the url to another application: ${tarUrl}.`;
        let confirmMsg = ` Press ‘OK’ to stay on current route.`;
        let cancelMsg = ` Press ‘Cancel’ to navigate to the application default path.`;
        if (subAppDetected) {
          tarUrl = `${window.location.protocol}//${window.location.hostname}:${subAppPort}${url}`;
          routingMsg = ` You will be navigated to another application: ${tarUrl} [NEW TAB]. Make sure the application already started. `;
          confirmMsg = ` Press ‘OK’ to continue navigation;`;
          cancelMsg = ` Press ‘Cancel’ to stay on the current route;`;
        }
        const crossRouteMsg = `Cross site routing detected!` +
          routingMsg +
          confirmMsg +
          cancelMsg;
        if (confirm(crossRouteMsg)) {
          if (subAppDetected) {
            window.open(tarUrl);
          }
        } else {
          if (!subAppDetected) {
            result.routeToDefaultPath = true;
          }
        }
      }
    } else {
      // reload to get the correct application: WebStatic routing handler in backend.
      window.top.location.href = `${window.location.origin}${url}`;
      result.reloadingTriggered = true;
    }
  }
  return result;
}

const DidaAppCrossSiteRoutingControlProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (appName: string, defaultPath: string, injector: Injector) => {
    return () => {
      const router = injector.get(Router);
      return new Observable<boolean>((ob) => {
        if (!APP_SITE_ROUTE_CONFIG.initialized) {
          ngxHttpCall(
            siteRouteService.getApplicationRouteConfig(appName)
          ).subscribe({
            next: (result) => {
              const rules = RouteRuleHelper.extractApplicationRouteRules(result && result.Rules);
              APP_SITE_ROUTE_CONFIG.initialized = true;
              APP_SITE_ROUTE_CONFIG.rules.unshift(...rules);
              ob.next(true);
            },
            error: (err) => {
              const errorCtx = {
                message: `failed to get app site route config for application: ${appName}`,
                error: err
              };
              ob.error(errorCtx);
              console.error(errorCtx.message, err);
            }
          }).add(() => {
            console.log(`[Dida_App_Routing_Provider]: finished setting routes`, APP_SITE_ROUTE_CONFIG);
            ob.complete();
          });
        } else {
          ob.next(true);
          ob.complete();
        }
      })
        .toPromise()
        .then(() => {
          const curNavigation = router.getCurrentNavigation();
          const { rules } = APP_SITE_ROUTE_CONFIG;
          if (curNavigation) {
            checkAndHandle(curNavigation.extractedUrl.toString(), rules, router, defaultPath, true);
          }
          didaCrossApplicationRoutingControl(router, rules, defaultPath);
        }, (reason: any) => {
          // TODO: handle rejection
        });
    };
  },
  deps: [DIDA_APP_NAME, DIDA_APP_DEFAULT_ROUTE_PATH, Injector],
  multi: true
};

export function didaAppCrossSiteRoutingControlProviders(appName: string, defaultPath: string): Provider[] {
  return [
    {
      provide: DIDA_APP_NAME,
      useValue: appName
    },
    {
      provide: DIDA_APP_DEFAULT_ROUTE_PATH,
      useValue: defaultPath
    },
    // DidaAppCrossSiteRoutingControlProvider
  ];
}

