import { EnumSelectStatus } from './EnumDefine.Model';
import { ICalendarCell } from './ICalendarCell';

/**
 * C:calendar
 * @export
 * @class CDate
 */
export class CDate implements ICalendarCell {
    Enable: boolean;
    Hidden: boolean;
    Date: Date;
    SelectStatus: EnumSelectStatus;
    IsMouseOver: boolean;
}
