import { WorkOrderService } from '../work-order/work-order.service';
import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';
import { BookingSearchOptions } from '../../../shared/models/booking/booking-search-option.model';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { BookingDetailModel } from '../../../shared/models/booking/hotel-booking-detail.model';
import { DateRangeType } from '../../../shared/enums/date-range-type';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { HotelBookingCancelResult } from '../../../shared/models/booking/booking-cancel-result.model';
import { HotelBookingInvoiceData } from '../../../shared/models/booking/booking-invoice-data.model';
import { ExternalRoutingHelper } from '../../router/external-routing-helper';
import { DidaSpecialRequest } from '../../../shared/models/order/dida-special-request.model';
import { BookingPaginationWrapper } from '../../../shared/models/booking/booking-pagination-wrapper.model';
import { ExportBookingListResult } from '../../../shared/models/booking/export-booking-list.model';
import { TicketHelper } from '../../../shared/utils/ticket-helper';
import { IBookingStatisticsOptions } from '@dida-shopping/dida-services/data-center';

/**
 * 订单数据服务
 *
 * @export
 * @class BookingService
 */
@Injectable()
export class BookingService {
  constructor(private logger: LoggerService, private httpClient: HttpClient) {}

  getEPSBookingAdditionalInfo(bookingId: string) {
    return this.httpClient.get('/HotelBookingApi/EPS_Booking_AdditionalInfo', { bookingId }, null, (resp: any) => {
      if (typeof resp.surchargePerRoom === 'string') {
        resp.surchargePerRoom = JSON.parse(resp.surchargePerRoom);
      }
      return resp;
    });
  }
  /**
   * 获取订单分组信息
   *
   * @param {string} clientReference 客户订单号
   * @returns {HttpObservable<string[]>}
   * @memberof BookingService
   */
  getBookingGroupInfo(clientReference: string, isGroupBooking: boolean) {
    return this.httpClient.get<string[]>('/HotelBookingApi/GetHotelBookingGroupInfo', {
      clientReference,
      isGroupBooking,
    });
  }

  /**
   * 检查关联单订单号
   *
   * @param {string} clientReference 客户订单号
   * @returns {HttpObservable<boolean>}
   * @memberof BookingService
   */
  checkRelatedClientReference(clientReference: string) {
    return this.httpClient.get<boolean>('/HotelBookingApi/CheckRelatedClientReference', {
      clientReference,
    });
  }

  /**
   * 订单查询接口
   *
   * @param {BookingSearchOptions} option 搜索参数
   * @returns {HttpObservable<BookingPaginationWrapper<BookingDetailModel>>}
   * @memberof BookingService
   */
  search(option: BookingSearchOptions) {
    return this.httpClient.get<BookingPaginationWrapper<BookingDetailModel>>(
      '/HotelBookingApi/GetHotelBookingList',
      {
        searchOptions: option,
      },
      null,
      (mdl) => {
        if (mdl.Data && mdl.Data.length > 0) {
          mdl.Data.forEach((b) => {
            this.bookingDetailProcessor(b);
          });
        }
      },
      (payload) => {
        let opt: BookingSearchOptions = payload.searchOptions;
        if (opt.DateRangeType !== DateRangeType.All && opt.DateRangeType !== DateRangeType.FirstCancellationDate) {
          opt.DateRange.From = DateTimeHelper.format(opt.DateRange.From, DateTimeHelper.DEFAULT_DATE_FORMAT);
          opt.DateRange.To = DateTimeHelper.format(opt.DateRange.To, DateTimeHelper.DEFAULT_DATE_FORMAT);
        }
        if (opt.DateRangeType === DateRangeType.FirstCancellationDate) {
          // tslint:disable-next-line:max-line-length
          opt.DateRange.From = DateTimeHelper.format(
            DateTimeHelper.addDays(opt.DateRange.From, 1),
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
          opt.DateRange.To = DateTimeHelper.format(
            DateTimeHelper.addDays(opt.DateRange.To, 1),
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
        }
      }
    );
  }

  /**
   * 获取订单详情
   *
   * @param {string} bookingId 订单ID
   * @returns {HttpObservable<BookingDetailModel>}
   * @memberof BookingService
   */
  getBookingDetail(bookingId: string) {
    return this.httpClient.get<BookingDetailModel>(
      '/HotelBookingApi/GetHotelBookingDetail',
      {
        bookingId,
      },
      null,
      this.bookingDetailProcessor
    );
  }

  /**
   * 取消订单（信息获取）
   *
   * @param {string} bookingId
   * @returns {HttpObservable<HotelBookingCancelResult>}
   * @memberof BookingService
   */
  cancelBooking(bookingId: string) {
    return this.httpClient.post<HotelBookingCancelResult>(
      '/HotelBookingProcessApi/Cancel',
      {
        bookingId,
      },
      null
    );
  }

  /**
   * 确认取消订单（会触发取消逻辑）
   *
   * @param {string} bookingId 订单ID
   * @param {string} cancelConfirmId 取消确认号
   * @param {string} description 取消原因
   * @returns {HttpObservable<HotelBookingCancelResult>}
   * @memberof BookingService
   */
  cancelConfirm(bookingId: string, cancelConfirmId: string, description: string) {
    return this.httpClient.post<HotelBookingCancelResult>(
      '/HotelBookingProcessApi/CancelConfirm',
      {
        bookingId,
        cancelConfirmId,
        description,
      },
      null
    );
  }

  /**
   * 更新客户订单号
   *
   * @param {string} bookingId 订单ID
   * @param {string} clientReference 客户订单号
   * @returns {any}
   * @memberof BookingService
   */
  updateClientReference(bookingId: string, clientReference: string) {
    return this.httpClient.post<any>(
      '/HotelBookingProcessApi/UpdateClientReference',
      {
        bookingId,
        clientReference,
      },
      null
    );
  }

  /**
   * 申请发票
   *
   * @param {string} bookingId 订单号
   * @param {HotelBookingInvoiceData} invoiceData 发票内容
   * @returns {HttpObservable<boolean>}
   * @memberof BookingService
   */
  applyInvoice(bookingId: string, invoiceData: HotelBookingInvoiceData) {
    return this.httpClient.post<boolean>(
      '/HotelBookingProcessApi/ApplyBookingInvoice',
      {
        bookingId,
        invoiceData,
      },
      null
    );
  }

  /**
   * 订单自动取消提醒
   *
   * @param {number} margin
   * @returns {HttpObservable<number>}
   * @memberof BookingService
   */
  expirePostpayBooking(margin: number) {
    return this.httpClient.get<number>('/HotelBookingApi/GetNearExpirePostpayBoolingCount', { margin }, null);
  }

  /**
   *订单接近免费可取消日提醒
   *
   * @param {number} margin
   * @returns {HttpObservable<number>}
   * @memberof BookingService
   */
  FreeCancellableBooking(margin: number) {
    return this.httpClient.get<number>('/HotelBookingApi/GetFreeCancellableBookingCount', { margin }, null);
  }

  /**
   * 导出订单
   *
   * @param {BookingSearchOptions} option
   * @memberof BookingService
   */
  exportBooking(option: BookingSearchOptions) {
    let url = `/HotelBooking/ExportBooking?dateRangeType=${option.DateRangeType}`;
    if (option.DateRangeType !== DateRangeType.All) {
      url += `&rangeFrom=${DateTimeHelper.format(option.DateRange.From, 'yyyy-MM-dd')}`;
      url += `&rangeTo=${DateTimeHelper.format(option.DateRange.To, 'yyyy-MM-dd')}`;
    }
    url += `&bookingStatus=${option.BookingStatus}`;
    ExternalRoutingHelper.navigateTo(url, true);
  }

  /**
   * 导出订单（判断是否要队列处理）
   *
   * @param {BookingSearchOptions} option
   * @memberof BookingService
   */
  exportBookingQueue(option: BookingSearchOptions) {
    return this.httpClient.post<boolean>(
      '/HotelBooking/AddExportBookingQueue',
      {
        searchOptions: option,
      },
      null
    );
  }

  getExportBookingList(pageNum: number = 1) {
    return this.httpClient.post<PaginationWrapper<ExportBookingListResult>>('/HotelBooking/GetExportQueues', {
      pageNum,
    });
  }

  statistics(option: IBookingStatisticsOptions): any {
    return this.httpClient.post(
      '/HotelBookingApi/GetStatistics',
      {
        options: option,
      },
      null,
      null,
      null
    );
  }

  private bookingDetailProcessor(detail: BookingDetailModel) {
    detail.RatePlanID = detail.RatePlanID || detail.ChannelRatePlanID;
    detail.AllowBookingSettlement = detail.AllowBookingSettlement || detail.AllowBookingSettlement_V2;
    detail.TravelDates.From = DateTimeHelper.parse(detail.TravelDates.From, true);
    detail.TravelDates.To = DateTimeHelper.parse(detail.TravelDates.To, true);
    if (detail.TicketDetailList) {
      detail.TicketDetailList.forEach((item) => {
        TicketHelper.init(item);
      });
    }
    if (detail.OrderDate) {
      detail.OrderDate = DateTimeHelper.parse(detail.OrderDate, true);
    }
    if (detail.BookingCancelTime) {
      detail.BookingCancelTime = DateTimeHelper.parse(detail.BookingCancelTime, true);
    }
    if (detail.CancellationPolicies && detail.CancellationPolicies.length > 0) {
      detail.CancellationPolicies.forEach((c) => {
        c.From = DateTimeHelper.parse(c.From, true);
      });
    }
    if (
      detail.CustomerRequest != null &&
      detail.CustomerRequest.startsWith('FMT_SPEC:[') &&
      detail.CustomerRequest.endsWith(']')
    ) {
      // process special requests
      let json = detail.CustomerRequest.substring(9);
      let specialRequests = JSON.parse(json) as DidaSpecialRequest[];
      detail.SpecialRequestItems = specialRequests;
    }

    if (detail.WorkOrderList) {
      detail.WorkOrderList.map((ticket) => WorkOrderService.normalizeTicket(ticket));
    }
  }
}
