
import {share} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class LocalStorageService implements OnDestroy {
  private storageSubject = new Subject<{
    key: string;
    value: string;
    external: boolean;
  }>();

  public changes = this.storageSubject.asObservable().pipe(share());

  constructor() {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.storageSubject.complete();
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      this.storageSubject.next({
        key: event.key,
        value: event.newValue,
        external: true
      });
    }
  }

  public get(key: string): string {
    return localStorage.getItem(key);
  }

  public save(key: string, data: string): void {
    localStorage.setItem(key, data);
    this.storageSubject.next({ key: key, value: data, external: false });
  }

  public clear(key: string) {
    localStorage.removeItem(key);
    this.storageSubject.next({ key: key, value: null, external: false });
  }

  public keys(): string[] {
    if (localStorage.length > 0) {
      const result: string[] = [];
      for (let i = 0; i < localStorage.length; i++) {
        result.push(localStorage.key(i));
      }
      return result;
    } else {
      return [];
    }
  }
}
