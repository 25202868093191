import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaUITKSuggestionTypeaheadComponent } from './suggestion-typeahead.component';
import { FormsModule } from '@angular/forms';
import { DidaUITKTabsCascaderModule } from '../tabs-cascader';
import { DidaUITKPipesModule } from '../pipes';
import { DidaUITKDirectiveModule } from '../directives/directive.module';
import { DidaUITKSuggestionInputLabelDirective } from './suggestion-input-label.directive';
import { DidaUITKSuggestionInputPlaceholderDirective } from './suggestion-input-placeholder.directive';
import { DidaUITKIconModule } from '../icons';

export { DidaUITKSuggestionTypeaheadComponent } from './suggestion-typeahead.component'
export { DidaUITKSuggestionInputPlaceholderDirective } from './suggestion-input-placeholder.directive'
export { DidaUITKSuggestionInputLabelDirective } from './suggestion-input-label.directive'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { DidaUITKTagModule } from '@dida-shopping/ngx-dida-uitk/tag';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [
    DidaUITKSuggestionTypeaheadComponent,
    DidaUITKSuggestionInputLabelDirective,
    DidaUITKSuggestionInputPlaceholderDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DidaUITKTabsCascaderModule,
    DidaUITKPipesModule,
    DidaUITKDirectiveModule,
    DidaUITKIconModule,
    DidaUITKTagModule,
    NzCheckboxModule
  ],
  exports: [
    DidaUITKSuggestionTypeaheadComponent,
    DidaUITKSuggestionInputLabelDirective,
    DidaUITKSuggestionInputPlaceholderDirective
  ]
})
export class DidaUITKSuggestionTypeaheadModule { }
