export interface IDidaUITKEventModel<T> {
  sender: any;
  data: T
}

export class DidaUITKGeneralEventModel<T> implements IDidaUITKEventModel<T> {
  constructor(
    public readonly sender: any,
    public readonly data: T
  ) {
  }
}
