import { HttpServiceBase } from '../http'
import { HotelVideoModel, IRecommendHotelContent, IRoomTypeContent, RoomTypeSearchResult } from './models'
import { SigningHelper, IDidaApplicationUserContextModel } from '../auth'
import { IHotelStaticDetail, SupplierHotelDestination } from './models/hotel-static-detail.model'
import { Suppliers } from '@dida-shopping/dida-services/rateplan'
import { HotelSearchSubregionModel } from './models/hotel-search-subregion.model'
import { IHotelStaticDataOptions } from './models/hotel-static-data-options.model'

class HotelStaticService extends HttpServiceBase {
  constructor() {
    super()
  }

  /**
   * 获取酒店静态地图
   *
   * @param {number} hotelId
   * @param {number} height
   * @param {number} width
   * @param {string} [iconUrl=null]
   * @param {boolean} [useHDImage=null]
   * @param {number} [zoomLevel=null]
   * @returns
   * @memberof HotelStaticService
   */
  getHotelGeoStaticMapData(
    hotelId: number,
    height: number,
    width: number,
    context: IDidaApplicationUserContextModel,
    iconUrl: string = null,
    useHDImage: boolean = null,
    zoomLevel: number = null
  ) {
    let sign: string = null
    const userName = context.userIdentityInfo.profile.UserName

    return this.httpClientWrapper.get<{
      DidaHotelID: number
      StaticMapUrl: string
      StaticMapContent: string
      StaticMapContentType: string
    }>(
      '/HotelApi/GetHotelGeoStaticMapData',
      {
        hotelId,
        height,
        width,
        iconUrl,
        useHDImage,
        zoomLevel
      },
      {
        payloadProcessor: payload => {
          const queryParam = {
            hotelId: hotelId,
            height: height,
            width: width,
            iconUrl: iconUrl,
            useHDImage: useHDImage,
            zoomLevel: zoomLevel,
            username: userName,
            pin: new Date().getTime()
          }
          payload.sign = sign = SigningHelper.generateSignature(queryParam)
          payload.pin = queryParam.pin
          return payload
        }
      }
    )
  }

  getHotelVideo(hotelId: number) {
    return this.httpClientWrapper.get<HotelVideoModel>('/HotelApi/GetHotelVideo', {
      hotelId
    })
  }

  /**
   * 获取房型信息
   * * Opt1: supplierId + rateplanId
   * * Opt2: responseId + ratePlanMask
   * @param {number} hotelId 酒店ID
   * @param {number} supplierId 供应商ID
   * @param {string} ratePlanId 供应商RatePlanID
   * @param {string} responseId
   * @param {string} ratePlanMask
   * @returns {HttpObservable<IRoomTypeContent>}
   * @memberof HotelStaticService
   */
  getRoomInfo(hotelId: number, supplierId: number, ratePlanId: string, responseId: string, ratePlanMask: string) {
    return this.httpClientWrapper
      .get<IRoomTypeContent>('/HotelApi/GetRoomContent', {
        hotelId,
        supplierId,
        ratePlanId,
        responseId,
        ratePlanMask
      }, {
        resultProcessor: result => {
          if (result) {
            if (result.Images) {
              result.Images = result.Images.sort((a, b) => (a.IsDefault ? 1 : 0))
            }
          } else {
            // 这时候result是一个空字符串你敢信？
            result = null
          }
          return result
        }
      })
  }

  /**
   * 获取酒店全量静态信息
   *
   * **注意：该接口会返回全量信息，数据量会很大**
   *
   * @param {number} hotelId
   * @param {boolean} [withMeta=true]
   * @param {boolean} [withStaticMap=true]
   * @param {boolean} [withTransferLocations=false]
   * @returns {HttpObservable<IHotelStaticDetail>}
   * @memberof HotelStaticService
   */
  getHotelDetail(hotelId: number, withMeta = true, withTransferLocations = false) {
    return this.httpClientWrapper.get<IHotelStaticDetail>( '/HotelApi/GetHotelStaticById', {
      id: hotelId,
      withMeta,
      withTransferLocations
    },
    {
      resultProcessor: (item: IHotelStaticDetail) => {
        if (item.HotelMeta && item.HotelMeta.WebsiteUrl && !item.HotelMeta.WebsiteUrl.startsWith('http')) {
          item.HotelMeta.WebsiteUrl = 'http://' + item.HotelMeta.WebsiteUrl;
        }
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        const highlightText = '儿童如果与父母或监护人同住一房并使用现有床铺，可免费入住'
        const spliter = '</li>'
        if(item.PolicyDescription && item.PolicyDescription.includes(highlightText)) {
          item.PolicyDescription = item.PolicyDescription.split('</li>').map(li => {
            if(li.includes(highlightText)) {
              return li.replace('<li>', '<li class="nd-text-active">')
            }
            return li
          }).join(spliter)
        }

        if(item.AliasList) {
          const hotelNameAvailable = [item.Name, item.Name_CN, item.Name_EN]
          item.AliasList = item.AliasList.filter(({Alias, IsChecked}) => !hotelNameAvailable.includes(Alias) && IsChecked)
        }
        return item;
      }
    });
  }

  /**
   * 获取酒店房型信息
   *
   * @param {number} hotelId 酒店ID
   * @returns {HttpObservable<RoomTypeSearchResult>}
   * @memberof HotelStaticService
   */
  getHotelRoomInfo(hotelId: number) {
    return this.httpClientWrapper.get<RoomTypeSearchResult>('/HotelApi/GetHotelRoomContent', { hotelId }, {
      resultProcessor: (result) => {
        if (result.Rooms) {
          result.Rooms.forEach(r => {
            if (r.Images) {
              r.Images = r.Images.sort((a, b) => a.IsDefault ? 1 : 0);
            }
          });
        }
        return result
      }
    }, );
  }

  /**
   * 获取供应商酒店信息
   * @param hotelId
   * @param suppliers
   */
  getHotelSuppliersList(hotelId: number, suppliers?: Suppliers[]) {
    return this.httpClientWrapper.get<SupplierHotelDestination[]>('/HotelApi/GetHotelSupplierList', { hotelId, sids: suppliers && suppliers.join(',') });
  }

  /**
   * 获取指定区域下的子区域
   * @param regionId
   * @returns
   */
  getHotelSearchSubRegion(regionId: string) {
    return this.httpClientWrapper.get<{ [key: string]: HotelSearchSubregionModel[] }>('/HotelApi/GetHotelSearchSubRegion', { regionId });
  }


  /**
   * 获取比价接口
   *
   * @param {number} hotelId
   * @returns {HttpObservable<boolean>}
   * @memberof HotelStaticService
   */
  getCrawlerControl(hotelId: number) {
    return this.httpClientWrapper.get<boolean>('/HotelApi/GetCrawlerControl', { id: hotelId });
  }


  /**
   * 获取猜你喜欢的酒店
   */
  getRecommendContent() {
    return this.httpClientWrapper.get<IRecommendHotelContent[]>('/HotelApi/GetRecommendContent', {}, {
      resultProcessor: resp => {
        return (resp || []).filter(region => {
          region.Hotels = region.Hotels || [];
          return region.Hotels.length > 0;
        });
      }
    })
  }

  /**
   * 获取猜你喜欢的酒店
   * @param regionIdList
   */
  getRegionalPromotedHotels(regionIdList: string[]) {
    return this.httpClientWrapper.get<number[]>('/HotelApi/GetRegionalPromotedHotels', { regionIdList }, {});
  }




  getHotelDetailOpen(hotelId: number) {
    return this.httpClientWrapper.get<IHotelStaticDetail>('/HotelApi/GetHotelStaticOpen', { id: hotelId }, {
      resultProcessor: item => {
        if (item.HotelMeta && item.HotelMeta.WebsiteUrl && !item.HotelMeta.WebsiteUrl.startsWith('http')) {
          item.HotelMeta.WebsiteUrl = 'http://' + item.HotelMeta.WebsiteUrl;
        }
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        return item;
      }
    })
  }



  getHotelStaticData(hotelId: number, options?: IHotelStaticDataOptions) {
    return this.httpClientWrapper.get<IHotelStaticDetail>('/HotelApi/GetHotelStaticData', { id: hotelId, options }, {
      resultProcessor: item => {
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        return item;
      }
    });
  }

  getHotelStaticList(idList: number[]) {
    return this.httpClientWrapper.post<IHotelStaticDetail[]>('/HotelApi/GetHotelStaticList', { idList }, {
      resultProcessor: item => {
        if (item.DestinationIDStr) {
          item.DestinationID = item.DestinationIDStr;
        }
        return item;
      }
    });

  }
}


export const hotelStaticService = new HotelStaticService()
