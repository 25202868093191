import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { NgxDidaEventsModule } from './events'
import { NgxDidaHttpModule, NgxServiceHttpClientWrapper } from './http'
import { NgxDidaGuardsModule } from './guards'
import { GlobalErrorHandler } from './handlers'
import { ServerTimeInterceptor } from './http'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { didaAppCrossSiteRoutingControlProviders } from './routing'
import { HttpServiceBase } from '@dida-shopping/dida-services/http'
import { NgxDidaStorageServiceModule } from './services'
// import { HttpMockApiInterceptor } from './mock/mock.interceptor'

export * from './application'
export * from './http'
export * from './events'
export * from './form'
export * from './guards'

export * from './components/component.bases'
export * from './components/app-component.base'

export * from './routing'
export * from './handlers'
export * from './services'
export * from './i18n'

export * from './app-module-preloader'
export * from './app-service-call-resolver'
export * from './app-verison'
export * from './app-routing-data.model'
export * from './app-server-config'
export * from './app-bootstrap'
export * from './app-site-route-config'
// export * from './hmr'

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    NgxDidaHttpModule,
    NgxDidaEventsModule,
    NgxDidaGuardsModule,
    NgxDidaStorageServiceModule
  ],
  providers: []
})
export class NgxDidaSiteCoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: NgxDidaSiteCoreModule,
    private wrapper: NgxServiceHttpClientWrapper
  ) {
    if (parentModule) {
      throw new Error(
        `NgxDidaSiteCoreModule has already been loaded. Import NgxDidaSiteCoreModule in the AppModule only.`
      )
    }
    console.log(`[NgxDidaSiteCoreModule] Set the default service http client wrapper to:`, wrapper)
    HttpServiceBase.DEFAULT_SERVICE_HTTP_CLIENT_WRAPPER = wrapper
  }

  /**
   * warning:: 此函数调用与装饰器内，只能直接return, 否则编译不通过
   */
  static forRoot(options: { appName: string, defaultPath: string }): ModuleWithProviders<NgxDidaSiteCoreModule> {


    return {
      ngModule: NgxDidaSiteCoreModule,
      providers: [
        ...didaAppCrossSiteRoutingControlProviders(options.appName, options.defaultPath),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerTimeInterceptor,
          multi: true
        },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: HttpMockApiInterceptor,
        //   multi: true
        // }
      ]
    }
  }
}
