import { CMonth } from '../models/CMonth.Model';
import { CYear } from '../models/CYear.Model';

export class CDateHelper {

    static isInMonth(date: Date, month: CMonth) {
        return (date.getFullYear() === month.Year && date.getMonth() === month.Month);
    }

    static isInYear(date: Date, year: CYear) {
        return (date.getFullYear() === year.Year);
    }

    /**
     * 只比较日期部分
     *
     * @static
     * @param {Date} date1
     * @param {Date} date2
     * @memberof CDateHelper
     */
    static dateEquals(date1: Date, date2: Date): boolean {
        if (date1.getTime() - date2.getTime() === 0) {
            return true;
        } else {
            if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth()
                && date1.getDate() === date2.getDate()) {
                return true;
            }
        }
        return false;
    }

    /**
     * 只返回日期部分
     *
     * @static
     * @param {Date} date
     * @returns
     * @memberof CDateHelper
     */
    static getDate(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    /**
     * 比较两个日期
     *
     * @static
     * @param {Date} date1
     * @param {Date} date2
     * @returns {number} >0: date1>date2; <0: date1<date2; =0: date1=date2
     * @memberof CDateHelper
     */
    static compareDate(date1: Date, date2: Date): number {
        if (date1 && !date2) {
            return 1;
        } else if (!date1 && date2) {
            return -1;
        } else if (!date1 && !date2) {
            return 0;
        }

        const d1 = CDateHelper.getDate(date1).getTime();
        const d2 = CDateHelper.getDate(date2).getTime();
        if (d1 > d2) {
            return 1;
        } else if (d1 === d2) {
            return 0;
        } else {
            return -1;
        }
    }

    static getDays(toDate: Date, fromDate: Date): number {
        // Roman: fix daylight saving issue.
        // let:
        // d1: from date,
        // d2: to date
        // d1_tm: the total miniutes of d1
        // d2_tm: the total miniutes of d2        
        // dayDiff: the days offset between d1, d2.
        // 
        // we have:
        // d1_tm = d1.getTime() / (1000 * 60)
        // d2_tm = d2.getTime() / (1000 * 60)  
        // (where `(1000 * 60)` means 1000ms/second * 60seconds/minute)
        //
        // [E1] minuteDiff = (d2_tm - d2.getTimezoneOffset()) -  (d1_tm - d1.getTimezoneOffset()) 
        // 
        // We can also write [E1] in another way by extracting d2_tm, d1_tm out of the bracket,
        // thus we have [E1']:
        // [E1'] minuteDiff = (d2_tm - d1_tm) + (-d2.getTimezoneOffset() + d1.getTimezoneOffset()) 
        // 
        // In most of the cases, d1 and d2 is in the same timezone, which make:
        // (-d2.getTimezoneOffset() + d1.getTimezoneOffset()) = 0
        // 
        // Thus we calculate the difference of the date total minutes first:
        // actualMiniutesDiff = minuteDiff = d2_tm - d1_tm
        // 
        // Then, if we detect the timezone of d1 and d2 is different (may due to daylight saving policy), 
        // we adding back the timezone offset. According to [E1']:
        // actualMiniutesDiff = minuteDiff + (-d2.getTimezoneOffset() + d1.getTimezoneOffset())
        // 
        // finally, we calculate the day differences:
        // dayDiff = actualMiniutesDiff / (60 * 24) 
        // (where `(60 * 24)` means 60minutes/hour * 24hours/day)

        const intervalMsec = toDate.getTime() - fromDate.getTime();
        let intervalMinutes = intervalMsec / 60000; // 60000 = (1000 * 60)
        const toDateMinutesOffset = toDate.getTimezoneOffset();
        const fromDateMinutesOffset = fromDate.getTimezoneOffset();
        if (toDateMinutesOffset !== fromDateMinutesOffset) {
            intervalMinutes = intervalMinutes - toDateMinutesOffset + fromDateMinutesOffset;
        }
        const dayDiff = intervalMinutes / 1440; // 1440 = (60 * 24)
        const days = parseInt(dayDiff.toString(), 10);
        return days;
    }

    static getToday() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }

    static addDays(date: Date, days: number): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
    }

    static getDateString(date: Date, format: string = 'yyyy-MM-dd'): any {
        if (!date) {
            return '';
        }
        const o = {
            'M+': date.getMonth() + 1, // 月份
            'd+': date.getDate(), // 日
            'h+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
            'S': date.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }

        for (const k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
            }
        }

        return format;
    }
}
