import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DidaCheckboxComponent } from './dida-checkbox.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    DidaCheckboxComponent
  ],
  declarations: [DidaCheckboxComponent]
})
export class DidaCheckboxModule { }
