import { IHotelBookingPreOrderOption } from '@dida-shopping/dida-services/order';
import { localStorageService } from '../storage/localstorage.service';

export const KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS =
  'DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS';

class RateSearchContextService {
  private _autoRemoved = false

  setOptions(key: string, item: IHotelBookingPreOrderOption) {
    const valueString = JSON.stringify(item);
    localStorageService.save(
      `${KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS}:${key}`,
      valueString
    );
    console.log(item);
    this.autoRemove();
  }

  getOptions(key: string): IHotelBookingPreOrderOption {
    if(!this._autoRemoved) {
      this.autoRemove();
      this._autoRemoved = true
    }
    const jsonValueString =localStorageService.get(
      `${KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS}:${key}`
    );
    let result = {};
    if (jsonValueString) {
      result = JSON.parse(jsonValueString);
    }
    return result as IHotelBookingPreOrderOption;
  }

  autoRemove() {
    try {
      const timetick = new Date().getTime();
      const expire = 24 * 60 * 60 * 1000;
     localStorageService.keys().forEach(key => {
        if (
          key &&
          key.startsWith(KEY_DIDA_SHOPPING_HOTEL_BOOKING_PRE_ORDER_OPTIONS)
        ) {
          const item =localStorageService.get(key);
          if (item) {
            const options = JSON.parse(item) as IHotelBookingPreOrderOption;
            if (timetick - options.__timetick__ > expire) {
             localStorageService.clear(key);
              console.log(key);
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export const rateSearchContextService = new RateSearchContextService()
