import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  InjectionToken,
  ElementRef,
  ChangeDetectorRef,
  Inject,
  Optional,
  HostBinding,
  HostListener
} from '@angular/core';
import { coerceBooleanProperty } from '../../../../core/coercion/boolean-property';
import { ENTER, SPACE } from '../../../../core/accessories/key-codes';
import { DidaSelectComponent } from '../dida-select.component';
import { SelectMultipleControlValueAccessor } from '@angular/forms';

export class DidaSelectOptionSelectionChangeEvent {
  constructor(
    public source: DidaSelectOptionComponent,
    public isUserInput: boolean,
    public isViaInteraction: boolean
  ) {}
}

export interface DidaSelectOptionParentComponent {
  id: string;
  opened: boolean;
}

export const DIDA_SELECT_OPTION_PARENT_COMPONENT = new InjectionToken<
  DidaSelectOptionParentComponent
>('DIDA_SELECT_OPTION_PARENT_COMPONENT');

let nextInstanceId = 0;

/**
 *
 *
 * @export
 * @class DidaSelectOptionComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dida-select-option',
  exportAs: 'didaSelectOption',
  templateUrl: './dida-select-option.component.html',
  styleUrls: ['./dida-select-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DidaSelectOptionComponent implements OnInit {
  private _multiple: boolean;
  private _selected: boolean;
  private _active = false;
  private _disabled = false;
  private _id = `didaSelectOption#${nextInstanceId++}`;

  @HostBinding('attr.id')
  get id(): string {
    return this._id;
  }

  get selected(): boolean {
    return this._selected;
  }

  get active(): boolean {
    return this._active;
  }

  get multiple(): boolean {
    return this._multiple;
  }

  get viewValue(): string {
    return (
      this.selectionValue ||
      this._getHostElement().textContent ||
      ''
    ).trim();
  }

  @Input() value: any;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input() selectionValue: any;

  @Output() onSelectionChange = new EventEmitter<
    DidaSelectOptionSelectionChangeEvent
  >();

  constructor(
    private _element: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(DIDA_SELECT_OPTION_PARENT_COMPONENT)
    private _parent: DidaSelectOptionParentComponent
  ) {
    if (this._parent) {
      this._id = `${this._parent.id}#${this._id}`;
    }
  }

  ngOnInit() {}

  select(): void {
    this._selected = true;
    this._changeDetectorRef.detectChanges();
    this._emitSelectionChangeEvent();
  }

  deselect(): void {
    this._selected = false;
    this._changeDetectorRef.markForCheck();
    this._emitSelectionChangeEvent();
  }

  focus(): void {
    const element = this._getHostElement();
    if (typeof element.focus === 'function') {
      element.focus();
    }
  }

  setMultiple(isMultiple: boolean) {
    this._multiple = isMultiple;
    this._changeDetectorRef.detectChanges();
  }

  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
      this._changeDetectorRef.markForCheck();
    }
  }

  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  getLabel(): string {
    return this.viewValue;
  }

  @HostListener('keydown', ['$event']) handleKeydown(
    event: KeyboardEvent
  ): void {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      this.selectViaInteraction(event, null);

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();
    }
  }

  @HostListener('click', ['$event']) selectViaInteraction(
    event: MouseEvent | any,
    isSelected: boolean = null
  ): void {
    if (!this.disabled) {
      if (event) {
        // event.stopPropagation();
      }

      if (isSelected !== null) {
        this._selected = isSelected;
      } else {
        if (this.multiple) {
          this._selected = !this._selected;
        } else {
          this._selected = true;
        }
      }

      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent(true, event !== null);
    }
  }

  _getHostElement(): HTMLElement {
    return this._element.nativeElement;
  }

  private _emitSelectionChangeEvent(
    isUserInput = false,
    isViaInteraction = false
  ): void {
    this.onSelectionChange.emit(
      new DidaSelectOptionSelectionChangeEvent(
        this,
        isUserInput,
        isViaInteraction
      )
    );
  }
}
