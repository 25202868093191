import { HttpServiceBase } from '@dida-shopping/dida-services/http';
import {
  ITicketSearchOptions,
  ITicketDetail,
  ITicketIssueView,
  ITicketAddModel,
  ITicketFeedbackModel
} from './models';
import { IPaginationWrapper } from '@dida-shopping/dida-services/common';
import { TicketHelper } from './ticket.helper';
import { TicketSources } from './enums';

class TicketService extends HttpServiceBase {
  constructor() {
    super('TicketService');
  }

  getTickets(options: ITicketSearchOptions) {
    return this.httpClientWrapper.post<IPaginationWrapper<ITicketDetail>>(
      `/Ticket/Get`,
      options,
      {
        resultProcessor: this.ticketDetailListPreProcessor
      }
    );
  }

  getTicketsByBookingID(bookingID: string) {
    return this.httpClientWrapper.post<IPaginationWrapper<ITicketDetail>>(
      `/Ticket/Get`,
      {
        DidaBookingIDList: [bookingID]
      },
      {
        resultProcessor: this.ticketDetailListPreProcessor
      }
    );
  }

  getTicketsByID(ticketID: string) {
    return this.httpClientWrapper.post<IPaginationWrapper<ITicketDetail>>(
      `/Ticket/Get`,
      {
        TicketIDList: [ticketID]
      },
      {
        resultProcessor: this.ticketDetailListPreProcessor
      }
    );
  }

  getTicketsByToken(token: string) {
    return this.httpClientWrapper.post<IPaginationWrapper<ITicketDetail>>(
      `/open/GetTickets`,
      { token },
      { resultProcessor: this.ticketDetailListPreProcessor }
    );
  }

  getTicketIssues(source: TicketSources) {
    return this.httpClientWrapper.get<ITicketIssueView[]>(
      `/Ticket/GetTicketIssues?source=${source}`,
      null,
      { resultProcessor: this.ticketIssuePreProcessor }
    );
  }

  // getTicketIssuesV2(source: TicketSources) {
  //   return this.httpClientWrapper.get<ITicketIssueView[]>(
  //     `/Ticket/GetTicketIssuesV2?source=${source}`,
  //     null,
  //     { resultProcessor: this.ticketIssuePreProcessor }
  //   );
  // }

  getConfirmCodesFakeCuttOfDays() {
    return this.httpClientWrapper.get<number>(
      `/Ticket/GetConfirmCodesFakeCuttOfDays`
    );
  }

  addTicket(ticket: ITicketAddModel) {
    return this.httpClientWrapper.post<ITicketDetail>(
      `/Ticket/Create?`,
      ticket,
      {
        resultProcessor: TicketHelper.normalize
      }
    );
  }

  urgeTicket(ticketId: string, bookingId: string) {
    return this.httpClientWrapper.post<ITicketDetail>(
      '/Ticket/HurryTicket',
      {
        options: {
          TicketID: ticketId,
          DidaBookingID: bookingId
        }
      },
      {
        resultProcessor: TicketHelper.normalize
      }
    );
  }

  addFeedback(data: ITicketFeedbackModel) {
    return this.httpClientWrapper.post<ITicketDetail>(
      '/Ticket/AddFeedback',
      {
        options: {
          ...data,
          AttachmentJson: JSON.stringify(data.AttachmentJson)
        }
      },
      {
        resultProcessor: TicketHelper.normalize
      }
    );
  }

  private ticketIssuePreProcessor(views: ITicketIssueView[]) {
    const action = (items: ITicketIssueView[]) => {
      items.forEach(item => {
        item.Settings = item.JsonProperty ? JSON.parse(item.JsonProperty) : {};
        // 之前组件少，在DB里面配JSON很恶心，所以逻辑是 Controls 里面禁止的才不展示
        // 现在工单标准化增加了组件，判断逻辑改为了 Controls 有的组件才展示
        // 为了减少小程序端的影响，先在这里写死
        // 在 DidaTravel\SourceCode\CustomServiceWorkspace\Test\TestConsole\Program 里面写了个 ShoppingTicketIssueSetting 方法方便在代码里面配置 JSON (SVN版本： 41072)
        if (item.Settings.Controls) {
          ['content', 'attachments'].forEach(name => {
            const control = item.Settings.Controls.find(x => x.Name === name);
            if (!control) {
              item.Settings.Controls.push({
                Name: name,
                IsRequired: false,
                IsVisible: true
              });
            }
          });
        }
        if (!item.Children) {
          item.Children = [];
        }
        action(item.Children);
      });
    };
    action(views);
    return views;
  }

  private ticketDetailListPreProcessor(
    result: IPaginationWrapper<ITicketDetail>
  ) {
    if (result && result.Data && result.Data.length > 0) {
      result.Data.forEach(item => {
        TicketHelper.init(item);
      });
    }
    return result;
  }
}

export const ticketService = new TicketService();
