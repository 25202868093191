import { ICouponModel, IUserCouponCacheModel } from '@dida-shopping/dida-services/campaign';
import { JsonHelper } from '@dida-shopping/dida-services/common';

export interface IUserCouponUpdateResult {
  hasUpdated: boolean;
}

interface IUserCouponRefType {
  idx: number;
}

export interface IUserCouponStorage {
  activeCount: number;
  cacheTime: Date;

  getActiveCoupons(): ICouponModel[];

  setActiveCoupons(coupons: ICouponModel[], time?: Date): IUserCouponUpdateResult;

  removeActiveCoupons(couponIdList: string[]): IUserCouponUpdateResult;
}

class UserCouponStorage implements IUserCouponStorage {
  private _activeCoupons: ICouponModel[];
  private _couponId2Ref: { [couponId: string]: IUserCouponRefType };
  private _cacheTime: Date;
  private _isInited: boolean;

  constructor() {
    this._activeCoupons = [];
    this._couponId2Ref = {};
    this._cacheTime = new Date(1970, 1, 1);
  }

  get activeCount(): number {
    return this._activeCoupons.length;
  }

  get cacheTime(): Date {
    return new Date(this._cacheTime.getTime());
  }

  getActiveCoupons(): ICouponModel[] {
    return this._activeCoupons.map(item => {
      return JsonHelper.parseJsonWithDate(JSON.stringify(item));
    });
  }

  setActiveCoupons(coupons: ICouponModel[], time: Date = new Date()): IUserCouponUpdateResult {
    const result: IUserCouponUpdateResult = { hasUpdated: false };
    if (!coupons) {
      coupons = [];
    }
    let needUpdateCache = false;
    if(this._isInited) {
      needUpdateCache = this._activeCoupons.length !== coupons.length;
      if (!needUpdateCache) {
        for (let i = 0; i < coupons.length; i++) {
          const item = coupons[i];
          const tarRef = this._couponId2Ref[item.CouponID];
          if (!tarRef) {
            needUpdateCache = true;
            break;
          }
        }
      }
    } else {
      this._isInited = true
      needUpdateCache = true;
    }

    if (needUpdateCache) {
      this.syncCache(coupons, time);
      result.hasUpdated = true;
    }

    return result;
  }

  removeActiveCoupons(couponIdList: string[]): IUserCouponUpdateResult {
    const result: IUserCouponUpdateResult = { hasUpdated: false };
    const time = new Date();
    const targets = couponIdList.filter(x => !!this._couponId2Ref[x]);
    if (targets.length > 0) {
      const targetIdSet = new Set<string>(targets);
      const data = this._activeCoupons.filter(x => !targetIdSet.has(x.CouponID));
      this.syncCache(data, time);
      result.hasUpdated = true;
    }
    return result;
  }

  private syncCache(coupons: ICouponModel[], time: Date) {
    const couponId2Ref: { [couponId: string]: IUserCouponRefType } = {};
    this._activeCoupons = coupons.map((item, idx) => {
      couponId2Ref[item.CouponID] = { idx };
      return JsonHelper.parseJsonWithDate(JSON.stringify(item));
    });
    this._couponId2Ref = couponId2Ref;
    this._cacheTime = time;
  }

}


export const USER_COUPON_STORAGE = new UserCouponStorage();
