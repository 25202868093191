import { CalendarModeEnum } from '../../models/EnumDefine.Model';

export class CalendarHelper {
    static isDatePicker(mode: CalendarModeEnum): boolean {
        if (mode === CalendarModeEnum.DateRange || mode === CalendarModeEnum.SingleDate) {
            return true;
        }
        return false;
    }
  
    static isMonthPicker(mode: CalendarModeEnum): boolean {
      if (mode === CalendarModeEnum.Month) {
          return true;
      }
      return false;
    }

    static isSingleDatePicker(mode: CalendarModeEnum): boolean {
        if (mode === CalendarModeEnum.SingleDate || mode === CalendarModeEnum.Month) {
            return true;
        }
        return false;
    }

    static isDateRangPicker(mode: CalendarModeEnum): boolean {
        if (mode === CalendarModeEnum.DateRange) {
            return true;
        }
        return false;
    }
}
