import { IAsyncOperation } from '@dida-shopping/dida-services/http';
import { HttpObservable } from './http-observable.model';



export function ngxHttpCall<Result>(
  pipe: IAsyncOperation<Result>
) {
  if (!pipe) {
    return null;
  }
  if (pipe.type === 'observable') {
    return pipe as HttpObservable<Result>;
  }
  throw new Error(`invalid IAsyncOperation, type: ${pipe.type}. Please make sure you assigning the correct Rxjs based http wrapper.`);
}
