<div class="dida-date-picker__wrapper">
    <div [innerHTML]="styles"></div>
    <div *ngIf="display.showCalendar" class="dida-date-picker__popup"
        (mouseout)="popupMouseOut()"
        [ngStyle]="{
            'width': (displayCalendarCount * 264 + 2) + 'px',
            'left': marginLeft + 'px',
            'top': marginTop + 'px'
        }">
        <div class="{{'dida-icon-triangle-up date-triangle-up ' + (display.animationPos==1 ? animationFromDateName : '') + ' ' + (display.animationPos==2 ? animationToDateName : '')}}"
            #calendarPopUp
            *ngIf="showSriangle"
            [ngClass]="{
            'sm':size=='sm'
            }"
            [ngStyle]="{
                'left': (display.trianglePos==1?sriangleFromMarginLeft:sriangleToMarginLeft) + 'px'
            }">
        </div>
        <div class="mask"
            [ngStyle]="{'height': isMonthPicker ? '150px' : (maxWeekCount==5?'265px':'297px')}"
            [ngClass]="{
            'animation-flash_A':flashFlag,
            'animation-flash_B':!flashFlag
            }">
        </div>
        <ng-container *ngIf="isDatePicker">
            <div class="calendar" *ngFor="let m of calendar.DisplayMonthList; let im = index;"
            [ngClass]="{
            'left':(im==0),
            'right':(im==1)
            }">
                <div class="header">
                    <button class="previous ignore dida-icon-nav-left-active"
                        [ngClass]="{'hidden':(im!==0),'dida-icon-nav-left-active_disabled':previousDisable()}"
                        (click)="previous()">
                        &nbsp;
                    </button>
                    <span class="year ignore">{{m.Year}}</span>
                    <span class="month ignore">{{getMonthName(m.Month)}}</span>
                    <button class="next ignore dida-icon-nav-right-active"
                        [ngClass]="{'hidden':((im+1)!==calendar.DisplayMonthList.length),'dida-icon-nav-right-active_disabled':nextDisable()}"
                        (click)="next()">
                        &nbsp;
                    </button>
                    <div class="clearAll"></div>
                    </div>
                    <div class="content">
                    <div>
                        <div class="cell week">{{getDayOfWeekName(0)}}</div>
                        <div class="cell week">{{getDayOfWeekName(1)}}</div>
                        <div class="cell week">{{getDayOfWeekName(2)}}</div>
                        <div class="cell week">{{getDayOfWeekName(3)}}</div>
                        <div class="cell week">{{getDayOfWeekName(4)}}</div>
                        <div class="cell week">{{getDayOfWeekName(5)}}</div>
                        <div class="cell week">{{getDayOfWeekName(6)}}</div>
                    </div>
                    <div class="cell" *ngFor="let f of m.FrontDateList"></div>
                    <div class="cell" *ngFor="let d of m.DateList; let i = index;" 
                        [ngClass]="{'clearRight': ((m.FrontDateList.length + i)%7==6),
                        'last':((i+1)==m.DateList.length),
                        'enable':d.Enable, 
                        'over':d.IsMouseOver,
                        'selected':d.SelectStatus == 4,
                        'reselected':d.SelectStatus == 6,
                        'start':d.SelectStatus == 2,
                        'end':d.SelectStatus == 3,
                        'startend':d.SelectStatus == 5 }"
                        (click)="onCellClick(d)"
                        (mouseover)="onCellMouseOver(d)"
                        (mouseout)="onCellMouseOut(d)">{{d.Date.getDate()}}</div>
                    <div class="clearAll"></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isMonthPicker">
            <div class="calendar" *ngFor="let y of calendar.DisplayYearList; let iy = index;"
            [ngClass]="{
                'left':(iy==0),
                'right':(iy==1)
            }">
                <div class="header">
                    <button class="previous ignore dida-icon-nav-left-active"
                        [ngClass]="{'hidden':(iy!==0),'dida-icon-nav-left-active_disabled':previousDisable()}"
                        (click)="previous()">
                        &nbsp;
                    </button>
                    <span class="year yaercenter ignore">{{y.Year}}</span>
                    <button class="next ignore dida-icon-nav-right-active"
                        [ngClass]="{'hidden':((iy+1)!==calendar.DisplayYearList.length),'dida-icon-nav-right-active_disabled':nextDisable()}"
                        (click)="next()">
                        &nbsp;
                    </button>
                    <div class="clearAll"></div>
                    </div>
                    <div class="content">
                    <div class="monthcell" *ngFor="let m of y.MonthList; let i = index;" 
                        [ngClass]="{'clearRight': (m.Month%4==3),
                        'last':((i+1)==y.MonthList.length),
                        'enable':m.Enable, 
                        'over':m.IsMouseOver,
                        'selected':m.SelectStatus == 4,
                        'reselected':m.SelectStatus == 6,
                        'start':m.SelectStatus == 2,
                        'end':m.SelectStatus == 3,
                        'startend':m.SelectStatus == 5 }"
                        (click)="onCellClick(m)"
                        (mouseover)="onCellMouseOver(m)"
                        (mouseout)="onCellMouseOut(m)">{{getMonthName(m.Month)}}</div>
                    <div class="clearAll"></div>
                </div>
            </div>
        </ng-container>
    </div>
</div>