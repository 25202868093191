import { SuggestionItem, SuggestionItemCategory } from '@dida-shopping/dida-services/suggestions';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';

export enum SuggestionTypeaheadExtranInfoMode {
  default = 'default',
  category = 'category'
}

export enum SuggestionTypeaheadDropdownTarget {
  none = 0,
  cities = 1,
  suggestions = 2,
  invalid = 3,
}

export enum RegionSearchSwithStyle {
  default = 0,
  checkbox = 1
}

export class SuggestionTypeaheadOptions {

  /**
   * 选项列表中最多展示多少条酒店信息
   * *(可选)*
   */
  maxHotelCount? = 6;

  /**
   * 选项列表中最多展示多少条机场信息
   * *(可选)*
   * @memberof SuggestionTypeaheadOptions
   */
  maxAirportCount? = 0;

  /**
   * 选项列表中最多展示多少条区域信息
   * *(可选)*
   */
  maxRegionCount? = 4;

  /**
   * 选项列表中最多展示多少条热门景点
   * *(可选)*
   */
  maxPOICount? = 4;

  /**
   * 搜索关键字最短长度
   * *(可选)*
   */
  minKeywordLength? = 2;

  /**
   * 选项列表中是否显示左边的分类信息
   * *(可选)*
   */
  showCategory? = true;

  /**
   * 选项列表右侧展示内容
   * *(可选)*
   */
  extranInfoMode? = SuggestionTypeaheadExtranInfoMode.default;

  /**
   * 在鼠标进入选项时自动将该选项设为选中
   * *(可选)*
   */
  autoSelectHover? = false;

  /**
   * 自动选中第一个选项
   * *(可选)*
   */
  autoSelectFirst? = false;

  /**
   * 父区域
   *
   * @type {string}
   * @memberof SuggestionTypeaheadOptions
   */
  parentRegionId?: string;

  /**
   * 是否回填上一次选择的项目（当blur且未选择的时候）
   *
   * @memberof SuggestionTypeaheadOptions
   */
  resetPreviousSelected? = false;
}

export class DidaSuggestionTypeaheadConfig {

  /**
   * 语言
   * *(可选)*
   */
  languageType?: LanguageType;


  /**
   * 是否展示默认选项
   * *(可选)*
   * airpordMode ？ 默认城市列表 ： 默认机场列表
   */
  showDefaultOptions? = true

  /**
   * 用户选择城市时，是否以城市名进行一次搜索
   * *(可选)*
   */
  autoSearchCitySuggestion? = false;

  /**
   * 用户选择机场时，是否以机场名进行一次搜索
   * *(可选)*
   * @memberof DidaSuggestionTypeaheadConfig
   */
  autoSearchAirportSuggetion? = false;

  /**
   * 搜索延迟时间(即用户停止输入多久后才进行搜索)
   * 单位：毫秒
   * *(可选)*
   */
  searchDelay? = 500;

  /**
   * 区域ID，如不为空则仅在指定区域内搜索
   * *(可选)*
   */
  regionID? = null;

  /**
   * 酒店ID，如不为空则默认展示在搜索栏
   * *(可选)*
   */
  hotelID? = null;

  /**
   * 机场IATA代号，如不为空则默认展示在搜索栏
   * *(可选)*
   * @memberof DidaSuggestionTypeaheadConfig
   */
  airportCode? = null;

  /**
   * 默认搜索关键字
   * *(可选)*
   */
  keyword?: string = null;

  /**
   * 配置选项
   * *(可选)*
   */
  options? = new SuggestionTypeaheadOptions();

  /**
   * 启用指定区域内搜索
   * *(可选)*
   */
  enableRegionSearch = false;

  /**
   * 区域内搜索配置选项
   * *(可选)*
   */
  regionSearchOptions? = new SuggestionTypeaheadOptions();

  /**
   * 区域搜索切换风格
   * *(可选)*
   */
  regionSearchSwithStyle? = RegionSearchSwithStyle.checkbox;

  miniMode? = false;

  /**
   * 机场模式
   *
   * @type {boolean}
   * @memberof DidaSuggestionTypeaheadConfig
   */
  airportMode?: boolean;

  /**
   * 自定义标签
   *
   * @type {boolean}
   * @memberof DidaSuggestionTypeaheadConfig
   */
  customizeLabel?: boolean;


  /**
   * 自定义输入提示
   *
   * @type {boolean}
   * @memberof DidaSuggestionTypeaheadConfig
   */
  customizePlaceholder?: boolean;


  /**
   * 能抵达的交通枢纽
   *
   * @type {string[]}
   * @memberof DidaSuggestionTypeaheadConfig
   */
  transferLocations?: string[];

  /**
   * 是否显示区域类型标签
   * Tower: https://tower.im/teams/191049/todos/82117/
   */
  showInputRegionTag?: boolean;
}

export class SuggestionOption {
  id: string;
  parentId: string;
  destinationID: string;
  name: string;
  name_en: string;
  nameOld: string;
  address: { destinationName: string, countryName: string };
  location: GeoCoordinate;
  category: SuggestionItemCategory;
  airportCode?: string;
  source: SuggestionItem;
}

export enum SuggestionValueType {
  typeahead = 0,
  keyword = 1,
}

export class SuggestionValue {
  id?: string;
  parentId?: string;
  name?: string;
  name_en?: string;
  destinationID?: string;
  category?: SuggestionItemCategory;
  keyword?: string;
  airportCode?: string;
  location: GeoCoordinate;

  isSelected: boolean;
  isDeSelected: boolean;
}

export class RegionOption {
  id: string;
  name: string;
  name_en: string;
  category: SuggestionItemCategory;
}
