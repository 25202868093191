import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// NGX
import {TypeaheadModule} from 'ngx-bootstrap/typeahead'
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker'
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'
import {PaginationModule} from 'ngx-bootstrap/pagination'
import {PopoverModule} from 'ngx-bootstrap/popover'
import {ModalModule} from 'ngx-bootstrap/modal'
import {CollapseModule} from 'ngx-bootstrap/collapse'
import {TooltipModule} from 'ngx-bootstrap/tooltip'
import {CarouselModule} from 'ngx-bootstrap/carousel'
import {TabsModule, TabsetConfig} from 'ngx-bootstrap/tabs'
// Pages
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

// Widgets
import { CarouselComponent } from './components/carousel/carousel.component';
import { SliderComponent } from './components/slider/slider.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
// import { StayDatePickerComponent } from './components/stay-date-picker/stay-date-picker.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SuspensionComponent } from './components/layout/suspension/suspension.component';

import { GaodeMapsModule } from './components/gaode-maps/gaode-maps.module';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';

import { LabelComponent } from './components/label/label.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NationalityPickerComponent } from './components/nationality-picker/nationality-picker.component';
import { DailyPriceListComponent } from './components/daily-price-list/daily-price-list.component';
import { StepComponent } from './components/step/step.component';
import { StepsComponent } from './components/steps/steps.component';
import { SpecialRequestComponent } from './components/special-request/special-request.component';
import { SpecialRequestSelectComponent } from './components/special-request/special-request-select/special-request-select.component';
import { SpecialRequestTextComponent } from './components/special-request/special-request-text/special-request-text.component';
import { SpecialRequestOtherComponent } from './components/special-request/special-request-other/special-request-other.component';

// Pipe
import { DayOfWeekPipe } from './pipes/day-of-week/day-of-week.pipe';

import { LoadingSubscriptComponent } from './components/loading/loading-subscript.component';
import { CountDownComponent } from './components/count-down/count-down.component';
// tslint:disable-next-line:max-line-length
import { CancellationPolicyListComponent } from './components/cancellation-policy/cancellation-policy-list/cancellation-policy-list.component';
import { CancellationPolicyStepsComponent } from './components/cancellation-policy/cancellation-policy-steps/cancellation-policy-steps.component';
import { SpecialRequestCheckedPipe } from './components/special-request/special-request.pipe';
import { SpecialRequestService } from '../core/services/order/special-request.service';
import { DidaFormModule } from './form/dida-form.module';
// tslint:disable-next-line:max-line-length
import { CancellationPolicyNonRefundableComponent } from './components/cancellation-policy/cancellation-policy-non-refundable/cancellation-policy-non-refundable.component';
import { RateAvailabilityTimerComponent } from './components/rate-availability-timer/rate-availability-timer.component';
import { ModalComponent } from './components/modal/modal.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { AlertComponent } from './components/alert/alert.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MealInfoComponent } from './components/meal-info/meal-info.component';
import { IsOnRequestComponent } from './components/isonrequest/isonrequest.component';
import { BookingStatusComponent } from './components/booking-status/booking-status.component';
import { BillStatusComponent } from './components/bill-status/bill-status.component';
import { ErrorComponent } from './pages/error/error.component';
import { ExternalRedirectComponent } from './pages/external-redirect/external-redirect.component';
import { InvoiceStatusComponent } from './components/invoice-status/invoice-status.component';
import { ImgComponent } from './components/img/img.component';
import { DialogButtonComponent } from './components/dialog-button/dialog-button.component';
import { ButtonDialogComponent } from './components/dialog-button/button-dialog.component';
import { ImgWrapperComponent } from './components/img-wrapper/img-wrapper.component';
import { SimpleMealInfoComponent } from './components/simple-meal-info/simple-meal-info.component';
import { FeaturePopoverComponent } from './components/feature-popover/feature-popover.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { BookingTicketTypeComponent } from './components/booking-ticket-type/booking-ticket-type.component';
import { ValueAddContentComponent } from './components/value-add-content/value-add-content.component';
import { PageMetaComponent } from './components/page-meta/page-meta.component';
import { BadgeComponent } from './components/badge/badge.component';
import { MessageCategoryTypeComponent } from './components/messages/message-category-type/message-category-type.component';
import { MessageTitleComponent } from './components/messages/message-title/message-title.component';
import { MessageContentComponent } from './components/messages/message-content/message-content.component';
import { TextEllipsisComponent } from './components/text-ellipsis/text-ellipsis.component';
import { CancellationPenaltyTabComponent } from './components/cancellation-penalty-tab/cancellation-penalty-tab.component';
import { MarkdownModule, MarkdownComponent } from 'ngx-markdown';
import { LoginErrorComponent } from './components/login-error/login-error.component';
import { BedTypeComponent } from './components/bed-type/bed-type.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';

import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { ImageComponent } from './components/image/image.component';
import { BookingSelectorComponent } from './components/booking-selector/booking-selector.component';
import { UrlLimitToPipe } from './pipes/url-limit-to.pipe';
import { PostPayBookingComponent } from './components/post-pay-booking/post-pay-booking.component';
import { OfficialAccountOutreachComponent } from './components/official-account-outreach/official-account-outreach.component';
import { CancellationStageComponent } from './components/cancellation-stage/cancellation-stage.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DidaTimePickerComponent } from './components/date-time-picker/time-picker/time-picker.component';
import { PayChannelSelectComponent } from './components/pay-channel-select/pay-channel-select.component';
import { SettlementStatusComponent } from './components/settlement-status/settlement-status.component';
import { DayRoomComponent } from './components/day-room/day-room.component';
import { DocComponent } from './components/doc/doc.component';
import { ButtonComponent } from './components/button/button.component';
import { CampaignModalComponent } from './components/campaign-modal/campaign-modal.component';
import { CampaignModalItemComponent } from './components/campaign-modal/campaign-modal-item/campaign-modal-item.component';
import { DateTimePickerModule } from './components/datetime-picker/datetime-picker.moudle';
import { BillSettlementCycleTypeComponent } from './components/bill-settlement-cycle-type/bill-settlement-cycle-type.component';
import { SwiperComponent } from './components/swiper/swiper/swiper.component';
import { SwiperItemComponent } from './components/swiper/swiper-item/swiper-item.component';
import { DirectivesModule } from './directives/directives.module';
import { FavoriteButtonComponent } from './components/favorite-button/favorite-button.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { WeappProxyComponent } from './pages/weapp-proxy/weapp-proxy.component';
import { DidaUITKDirectiveModule } from '@dida-shopping/ngx-dida-uitk/directives';
import { DidaUITKSuggestionTypeaheadModule } from '@dida-shopping/ngx-dida-uitk/suggestion-typeahead';
import { DidaUITKPipesModule } from '@dida-shopping/ngx-dida-uitk/pipes';
import { DidaUITKTagModule } from '@dida-shopping/ngx-dida-uitk/tag';
import { DidaUITKPaginationModule } from '@dida-shopping/ngx-dida-uitk/pagination';
import { DidaUITKRoomOccupancyPickerModule } from '@dida-shopping/ngx-dida-uitk/room-occupancy-picker';
import { DidaUITKNationalityPickerModule } from '@dida-shopping/ngx-dida-uitk/nationality-picker';
import { DidaUITKNavBarModule } from '@dida-shopping/ngx-dida-uitk/navbar';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DirectivesModule,
    DidaFormModule,
    GaodeMapsModule,
    DateTimePickerModule,
    DidaUITKDirectiveModule,
    DidaUITKSuggestionTypeaheadModule,
    DidaUITKPipesModule,
    DidaUITKTagModule,
    DidaUITKPaginationModule,
    DidaUITKRoomOccupancyPickerModule,
    DidaUITKNationalityPickerModule,
    DidaUITKNavBarModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    // PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    MarkdownModule.forRoot()
  ],
  declarations: [
    // Pages
    PageNotFoundComponent,

    // Widgets
    CarouselComponent,
    SliderComponent,
    BreadcrumbComponent,
    DidaTimePickerComponent,
    DateTimePickerComponent,
    // StayDatePickerComponent,
    StarRatingComponent,
    GoogleMapsComponent,
    LabelComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    LoadingComponent,
    LoadingSubscriptComponent,
    NationalityPickerComponent,
    DailyPriceListComponent,
    StepComponent,
    StepsComponent,
    CancellationPolicyListComponent,
    CancellationPolicyStepsComponent,
    CancellationPolicyNonRefundableComponent,
    CountDownComponent,
    SpecialRequestComponent,
    SpecialRequestSelectComponent,
    SpecialRequestTextComponent,
    SpecialRequestOtherComponent,
    RateAvailabilityTimerComponent,
    ModalComponent,
    MessageModalComponent,
    FileViewerComponent,
    BookingSelectorComponent,
    PayChannelSelectComponent,

    // Pipes
    DayOfWeekPipe,

    SpecialRequestCheckedPipe,

    FileUploadComponent,
    AlertComponent,
    NotFoundComponent,
    MealInfoComponent,
    IsOnRequestComponent,
    BookingStatusComponent,
    BillStatusComponent,
    InvoiceStatusComponent,
    ErrorComponent,
    ExternalRedirectComponent,
    ImgComponent,
    ImgWrapperComponent,
    ButtonDialogComponent,
    DialogButtonComponent,
    SimpleMealInfoComponent,
    FeaturePopoverComponent,
    UploaderComponent,
    BookingTicketTypeComponent,
    ValueAddContentComponent,
    PageMetaComponent,
    BadgeComponent,
    MessageCategoryTypeComponent,
    MessageTitleComponent,
    MessageContentComponent,
    TextEllipsisComponent,
    CancellationPenaltyTabComponent,
    LoginErrorComponent,
    BedTypeComponent,
    ImageComponent,
    SuspensionComponent,
    UrlLimitToPipe,
    PostPayBookingComponent,
    OfficialAccountOutreachComponent,
    CancellationStageComponent,
    SettlementStatusComponent,
    DayRoomComponent,
    DocComponent,
    ButtonComponent,
    CampaignModalComponent,
    CampaignModalItemComponent,
    BillSettlementCycleTypeComponent,
    // SwiperComponent,
    // SwiperItemComponent
    SwiperComponent,
    SwiperItemComponent,
    FavoriteButtonComponent,
    CopyToClipboardComponent,
    WeappProxyComponent
  ],
  exports: [
    // Pages
    PageNotFoundComponent,

    // Widgets

    CarouselComponent,
    SliderComponent,
    BreadcrumbComponent,
    // StayDatePickerComponent,
    DidaTimePickerComponent,
    DateTimePickerComponent,
    StarRatingComponent,
    GoogleMapsComponent,
    LabelComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    SuspensionComponent,
    LoadingComponent,
    LoadingSubscriptComponent,
    NationalityPickerComponent,
    DailyPriceListComponent,
    StepComponent,
    StepsComponent,
    CancellationPolicyListComponent,
    CancellationPolicyStepsComponent,
    CancellationPolicyNonRefundableComponent,
    CountDownComponent,
    SpecialRequestComponent,
    SpecialRequestSelectComponent,
    SpecialRequestTextComponent,
    RateAvailabilityTimerComponent,
    SpecialRequestOtherComponent,
    ModalComponent,
    FileUploadComponent,
    AlertComponent,
    NotFoundComponent,
    MealInfoComponent,
    IsOnRequestComponent,
    BookingStatusComponent,
    BillStatusComponent,
    InvoiceStatusComponent,
    ImgComponent,
    ImgWrapperComponent,
    ImageComponent,
    ButtonDialogComponent,
    DialogButtonComponent,
    SimpleMealInfoComponent,
    FeaturePopoverComponent,
    UploaderComponent,
    BookingTicketTypeComponent,
    ValueAddContentComponent,
    PageMetaComponent,
    BadgeComponent,
    MessageCategoryTypeComponent,
    MessageTitleComponent,
    MessageContentComponent,
    TextEllipsisComponent,
    CancellationPenaltyTabComponent,
    LoginErrorComponent,
    BedTypeComponent,
    MessageModalComponent,
    FileViewerComponent,
    BookingSelectorComponent,
    PayChannelSelectComponent,
    CancellationStageComponent,
    SwiperComponent,
    SwiperItemComponent,
    // Directives

    // Pipes
    DayOfWeekPipe,
    SpecialRequestCheckedPipe,
    UrlLimitToPipe,
    // reexport ngx-bootstrap
    CarouselModule,
    PopoverModule,
    TabsModule,
    BsDropdownModule,
    ModalModule,
    TypeaheadModule,
    CollapseModule,
    DidaUITKDirectiveModule,
    DidaUITKSuggestionTypeaheadModule,
    DidaUITKPipesModule,
    DidaUITKTagModule,
    DidaUITKPaginationModule,
    DidaUITKRoomOccupancyPickerModule,
    DidaUITKNationalityPickerModule,
    MarkdownComponent,
    PostPayBookingComponent,
    OfficialAccountOutreachComponent,
    SettlementStatusComponent,
    DayRoomComponent,
    ButtonComponent,
    CampaignModalComponent,
    DateTimePickerModule,
    GaodeMapsModule,
    DirectivesModule,
    BillSettlementCycleTypeComponent,
    FavoriteButtonComponent,
    CopyToClipboardComponent
  ],
  providers: [SpecialRequestService, TabsetConfig]
})
export class SharedModule {}
