import { NgModule } from '@angular/core';
import { DidaUITKTagComponent } from './tag.component';
import { DidaUITKTagGroupComponent } from './tag-group.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    DidaUITKTagComponent,
    DidaUITKTagGroupComponent
  ],
  imports: [
    CommonModule,
    NzIconModule
  ],
  exports: [
    DidaUITKTagComponent,
    DidaUITKTagGroupComponent
  ],
  providers: [],
})
export class DidaUITKTagModule {

}
