import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2DidaBaiduAnalyticsProvider } from './providers/angulartics2-dida-baidu';
import { Angulartics2DidaGoogleAnalyticsProvider } from './providers/angulartics2-dida-ga';
import { DidaBehaviorEventTrackingOption } from './behavior-management.models';
import { LoggerService } from './logger.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


/**
 * 网站用户行为管理服务,不影响业务本身
 */
@Injectable()
export class BehaviorManagementService {

    private currentPage = '';

    constructor(
        private angulartics2: Angulartics2,
        private googleAnalytics: Angulartics2DidaGoogleAnalyticsProvider,
        private baiduAnalyticsProvider: Angulartics2DidaBaiduAnalyticsProvider,
        private logger: LoggerService,
        private router: Router,
        private location: Location,
    ) {
        this.googleAnalytics.startTracking();
        this.baiduAnalyticsProvider.startTracking();
        
        router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            filter(() => !this.angulartics2.settings.developerMode),
        ).subscribe((event: NavigationEnd) => {
            let path = this.getPagePath(event.urlAfterRedirects);
            if (this.angulartics2.settings.pageTracking.basePath.length) {
                this.currentPage = this.angulartics2.settings.pageTracking.basePath + path;
            } else {
                this.currentPage = this.location.prepareExternalUrl(path);
            }
        });
    }

    initUserSession(username: string, clientId: string) {
        try {
            this.googleAnalytics.setUserInfo(username, clientId);
            this.baiduAnalyticsProvider.setUserInfo(username, clientId);
        } catch (e) {
            this.logger.error(`init user session failed.`, e);
        }
    }

    // removeUserSession(username: string) {
    //   // seems not available right now
    // }

    trackEvent(option: DidaBehaviorEventTrackingOption) {
        try {
            if (option.page == null) {
                option.page = this.currentPage;
            }
            this.googleAnalytics.track(option);
            this.baiduAnalyticsProvider.track(option);
        } catch (e) {
            this.logger.error(`track event failed.`, e);
        }
    }

    trackException() {
        // not supported yet!
    }
    // private get hmt(): any {
    //   return window['_hmt'] || null;
    // }

    // private get ga(): UniversalAnalytics.ga {
    //   return ga || null;
    // }

    protected getPagePath(path: string): string {
        if (this.angulartics2.settings.pageTracking.clearIds || this.angulartics2.settings.pageTracking.clearQueryParams) {
            return path
                .split('/')
                .map(part => this.angulartics2.settings.pageTracking.clearQueryParams ? part.split('?')[0] : part)
                .filter(part => !this.angulartics2.settings.pageTracking.clearIds ||
                    !part.match(this.angulartics2.settings.pageTracking.idsRegExp))
                .join('/');
        }
        return path;
    }


}
