import { Icons, IconStatuses } from './icons.enum';

class IconService {
  private enumMap: { [key: string]: string } = {};
  private statusMap: { [key: string]: { [key: string]: string } } = {};
  constructor() {
    for (const key in Icons) {
      // eslint-disable-next-line no-prototype-builtins
      if (Icons.hasOwnProperty(key)) {
        const element = Icons[key];
        this.enumMap[element] = key;
      }
    }
    // 不能通过命名约定的，直接在这里进行映射
    // key 和 value 均为 icon 的文件名，请参考 oss/dida-cdn/icons-svg/assets/nd
    this.statusMap = {
      active: {
        'example-1': 'example_active'
      },
      disabled: {
        'example-1': 'example-1_disabled'
      }
    };
  }

  getIcon(icon: Icons, status: IconStatuses = IconStatuses.active) {
    if (this.statusMap[status] && this.statusMap[status][icon]) {
      return this.statusMap[status][icon];
    } else {
      const icons = Icons as any;
      const name = `${icon}_${status}`;
      return icons[this.enumMap[name]] || icon;
    }
  }
}
export const iconService = new IconService();
