import { ApplicationMessageTypeEnum } from '../enums';
import { IDidaApplicationUserContextModel } from '@dida-shopping/dida-services/auth';
import { IClientModel, IUserCurrencyInfoModel } from '@dida-shopping/dida-services/membership';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { IEventModelBase } from './event-base.model';
import { IRouteDataModel } from '../../app-routing-data.model';
import { TemplateRef } from '@angular/core';

export abstract class ApplicationEventBase implements IEventModelBase<ApplicationMessageTypeEnum> {


  protected constructor(
    public readonly type: ApplicationMessageTypeEnum,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }
}

export class ApplicationCurrencyChangeEvent extends ApplicationEventBase {

  public readonly currencyCode: string;
  public readonly currencyRatio: number;
  public readonly currencyPlace: number;

  constructor(
    public readonly info: IUserCurrencyInfoModel,
    source: string,
    time: Date
  ) {
    super(ApplicationMessageTypeEnum.CurrencyChange, source, time);
    this.currencyCode = info.Code;
    this.currencyRatio = info.Current2CNYRatio;
    this.currencyPlace = info.CurrentDecimalPlaces;
  }

}

export class ApplicationContextUpdateEvent extends ApplicationEventBase {
  constructor(
    public readonly ctx: IDidaApplicationUserContextModel,
    source: string,
    time: Date) {
    super(ApplicationMessageTypeEnum.ContextUpdate, source, time);

  }

}


export class ApplicationLanguageChangeEvent extends ApplicationEventBase {
  constructor(
    public readonly lang: LanguageType,
    source: string,
    time: Date
  ) {
    super(ApplicationMessageTypeEnum.LanguageChange, source, time);

  }

}

export class ApplicationClientInfoChangeEvent extends ApplicationEventBase {
  constructor(
    public readonly clientInfo: IClientModel,
    source: string,
    time: Date
  ) {
    super(ApplicationMessageTypeEnum.ClientInfoUpdate, source, time);

  }

}

export class ApplicationExperimentInfoChangeEvent extends ApplicationEventBase {
  constructor(
    public readonly rolledOutExperiments: number[],
    public readonly userExperiments: number[],
    public readonly clientExperiments: number[],
    source: string,
    time: Date
  ) {
    super(ApplicationMessageTypeEnum.ExperimentInfoChange, source, time);

  }

}

export class ApplicationRouteDataChangeEvent extends ApplicationEventBase {
  constructor(
    public readonly routeData: IRouteDataModel,
    source: string,
    time: Date
  ) {
    super(ApplicationMessageTypeEnum.RouteDataChange, source, time);
  }

}



export class ApplicationNavbarNoticeChangeEvent extends ApplicationEventBase {
  constructor(
    public readonly message: string | TemplateRef<any>,
    public readonly duration = 5000,
    source = '',
    time = new Date()
  ) {
    super(ApplicationMessageTypeEnum.NavbarNoticeChange, source, time);
  }

}

export class ApplicationNavbarSubffixChangeEvent extends ApplicationEventBase {
  constructor(
    public readonly message: TemplateRef<any> = null,
    source = '',
    time = new Date()
  ) {
    super(ApplicationMessageTypeEnum.NavbarNoticeChange, source, time);
  }

}
