import { IHotelFacilitySetting } from './hotel-facility-setting.model';
import { Icons } from '@dida-shopping/dida-services/icon';

export interface IHotelCustomizedSetting {
  TripAdvisorRating: number;
  TripAdvisorRatingUrl: string;
  TripAdvisorReviewCount: number;
  InternetTypeList: number[];
  IsOnPromotion: boolean;
}

export interface IHotelCustomizeDescription {
  Proposal: string;
  Overview: string;
  Information: string;
  NearbyInterests: string;
  Transportations: string;
  HasWifi: boolean;
}

export interface IHotelImage {
  ImageCaption: string;
  ImageUrl: string;
  ImageUrl_HD: string;
  ImageOrder: number;
}

export interface IHotelRoomImage {
  HotelID: number;
  RoomTypeID: number;
  RoomTypeImage: string;
}

export interface IHotelPostProcessMetadata {
  imageUrl: string;
  hasWifi: boolean;
  isOnPromotion: boolean;
  normalizedPhone: string;
  normalizedZipCode: string;
}

export interface IHotelStaticModel {
  HotelID: number;
  Name: string;
  Name_EN: string;
  Name_CN: string;
  ChainName: string;
  ChainCodeID: string;
  // BrandName: string;
  Address: string;
  Address_CN: string;
  AddressFull_CN: string;
  AddressFull_EN: string;
  CityCode: number;
  CityName: string;
  CityName_CN: string;
  /**
   * 酒店标签
   */
  Tags: string[];
  Tags_CN: string[];
  /**
   * 酒店卖点
   */
  DidaHotelSellingPoint: string;
  DidaHotelSellingPoint_CN: string;
  Description: string;
  Description_CN: string;
  DestinationID: string;
  /**
   * warning: 这是一个临时字段
   * 后端返回的DestinationID是一个bigint，json解析时会被截断,
   * 在不改变后端原有字段的情况下，返回一个对应字符串
   */
  DestinationIDStr: string;
  DestinationName: string;
  DestinationName_CN: string;
  StateCode: string;
  CountryCode: string;
  ZipCode: string;
  StarRating: number;
  Phone: string;
  Longitude: number;
  Latitude: number;
  AirportCode: string;
  PropertyCategory: string;
  Distance: number;
  CustomizeDescription: IHotelCustomizeDescription;
  HotelImageList: IHotelImage[];
  CustomizedSetting: IHotelCustomizedSetting;
  RoomImages: IHotelRoomImage[];
  IsFavorited: boolean;
  FacilitySetting: IHotelFacilitySetting;

  AliasList: {
    Alias: string
    Language: number
    IsChecked: boolean
  }[]

  /**
   * 前端运算结果
   */
  _metadata_: IHotelPostProcessMetadata;
}

export interface IHotelStaticInfoSnapshotModel {
  HotelID: string;
  Name: string;
  Name_EN: string;
  Name_CN: string;
  StarRating?: number;
  ImgUrl: string;
  Address: string;
  Phone: string;
  // Distance?: number;
  FacilitySetting: IHotelFacilitySetting;
  // Favorite: boolean;
}

export interface IHotelFacilityDisplayOption {
  icon: Icons,
  order: number,
  name: string;
  name_en: string;
}

let __facilityOrder__ = 0;

export const HotelFacilityDisplayInfoMap: Map<keyof IHotelFacilitySetting, IHotelFacilityDisplayOption> = new Map<keyof IHotelFacilitySetting, IHotelFacilityDisplayOption>([
  ['Wifi', { icon: Icons.wifi, name: 'Wi-Fi', name_en: 'Wi-Fi', order: __facilityOrder__++ }],
  ['Parking', { icon: Icons.parking, name: '停车场', name_en: 'Parking', order: __facilityOrder__++ }],
  ['Pool', { icon: Icons.swimmingPool, name: '游泳池', name_en: 'Swimming Pool', order: __facilityOrder__++ }],
  ['Gym', { icon: Icons.fitness, name: '健身房', name_en: 'Fitness Center', order: __facilityOrder__++ }],
  ['MeetingRoom', { icon: Icons.meetingRoom, name: '会议室', name_en: 'Meeting Room', order: __facilityOrder__++ }],
  ['AirportTransfer', { icon: Icons.shuttle, name: '机场接送', name_en: 'Airport Transfer', order: __facilityOrder__++ }],
  ['Restaurant', { icon: Icons.restaurant, name: '餐厅', name_en: 'Restaurant', order: __facilityOrder__++ }]
]);
