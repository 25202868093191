import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';

import { RegionBannerSettingView } from '../../../shared/models/regions/region-banner-setting.model';

/**
 * 促销信息数据服务
 *
 * @export
 * @class PromotionInfoService
 */
@Injectable()
export class PromotionInfoService {

  constructor(
    private logger: LoggerService,
    private httpClient: HttpClient
  ) { }

  /**
   * 获取当前进行促销的区域信息
   *
   * @returns {HttpObservable<RegionBannerSettingView[]>}
   * @memberof PromotionInfoService
   */
  getPromotionRegions() {
    return this.httpClient.get<RegionBannerSettingView[]>('/PromotionInfoApi/GetPromotionRegions');
  }

  /**
   * 获取区域下促销的酒店ID列表
   *
   * @param {number} regionId 区域ID
   * @returns {HttpObservable<number[]>}
   * @memberof PromotionInfoService
   */
  getPromotionHotelIDList(regionId: string) {
    return this.httpClient.get<number[]>('/PromotionInfoApi/GetPromotionHotelIDList', {
      regionId
    });
  }

}
