export enum ConditionCheckError {
  None,
  NotLogin,
  AccountLocked,
  ClientRegistering,
  RestrictedDevModeOnly,
  PermissionDenied,
  LanguageNotSupported,
  EmailUnconfirmed
}
