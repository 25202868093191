import { IDidaApplicationUserContextModel, IDidaUserIdentityInfoModel } from '../auth';
import { ExperimentID } from '../experiment';
import {
  IClientModel,
  IUserCurrencyInfoModel,
  IUserProfileModel
} from '../membership/models';
import { DateTimeHelper } from '../common';

export interface IApplicationSetResult {
  success: boolean;
  experimentChanged: boolean;
  currencyChanged: boolean;
  langChanged: boolean;
  isSetEmpty: boolean;
}

export interface IApplicationExperimentInfo {

  rolledOutExperiments: ExperimentID[];
  userExperiments: ExperimentID[];
  clientExperiments: ExperimentID[];

}

export interface IApplicationContextStorage extends IApplicationExperimentInfo {
  context: IDidaApplicationUserContextModel;
  userIdentityInfo: IDidaUserIdentityInfoModel;
  userProfile: IUserProfileModel;
  clientInfo: IClientModel;
  currencyInfo: IUserCurrencyInfoModel;


  responseTime: Date;
  imValidTime: Date;

  set(ctx: IDidaApplicationUserContextModel, locale?: 'en-US' | 'zh-CN' | null): IApplicationSetResult;
}

class ApplicationContextStorage implements IApplicationContextStorage {

  private _context: IDidaApplicationUserContextModel;


  /**
   * 掩码有效期
   *
   * @private
   * @type {Date}
   * @memberof ApplicationContextStorage
   */
  private _imValidTime: Date;

  public get context(): IDidaApplicationUserContextModel {
    return this._context;
  }

  public get userIdentityInfo() {
    return this.context && this.context.userIdentityInfo;
  }

  public get userProfile() {
    return this.userIdentityInfo && this.userIdentityInfo.profile;
  }

  public get clientInfo() {
    return this.userIdentityInfo && this.userIdentityInfo.clientInfo;
  }

  public get currencyInfo() {
    return this.context && this.context.currencyInfo;
  }

  public get responseTime(): Date {
    return this.context && this.context.__responseTime__;
  }

  public get imValidTime(): Date {
    return this._imValidTime;
  }

  public get rolledOutExperiments() {
    return this._context && this._context.rolledOutExperiments || [];
  }

  public get userExperiments() {
    return this._context && this._context.userExperiments || [];
  }

  public get clientExperiments() {
    return this._context && this._context.clientExperiments || [];
  }


  public set(ctx: IDidaApplicationUserContextModel, locale?: 'en-US' | 'zh-CN' | null): IApplicationSetResult {
    const result: IApplicationSetResult = {
      success: true,
      experimentChanged: false,
      currencyChanged: false,
      langChanged: false,
      isSetEmpty: !ctx,
    };

    result.experimentChanged = this.detectExperimentSettingsChanges(ctx);

    const currencyCode = ctx && ctx.currencyInfo && ctx.currencyInfo.Code;
    result.currencyChanged = this.currencyInfo && this.currencyInfo.Code !== currencyCode;

    const lang = ctx && ctx.lang;
    result.langChanged = (locale || this.context && this.context.lang) !== lang;

    this._context = ctx;

    if (ctx) {
      this._imValidTime = DateTimeHelper.addMinutes(ctx.__responseTime__, ctx.imValidRemainings);
    } else {
      this._imValidTime = new Date();
    }

    return result;
  }

  private detectExperimentSettingsChanges(ctx: IDidaApplicationUserContextModel): boolean {
    let hasExperimentChanges = false;
    if (!hasExperimentChanges) {
      const oldRolledOutExperiments = this.rolledOutExperiments;
      const curRolledOutExperiments = ctx && ctx.rolledOutExperiments;
      hasExperimentChanges = !this.checkIsSameExperimentSetting(oldRolledOutExperiments, curRolledOutExperiments);
    }
    if (!hasExperimentChanges) {
      const oldUsrExperiments = this.userExperiments;
      const curUsrExperiments = ctx && ctx.userExperiments;
      hasExperimentChanges = !this.checkIsSameExperimentSetting(oldUsrExperiments, curUsrExperiments);
    }
    if (!hasExperimentChanges) {
      const oldClientExperiments = this.clientExperiments;
      const curClientExperiments = ctx && ctx.clientExperiments;
      hasExperimentChanges = !this.checkIsSameExperimentSetting(oldClientExperiments, curClientExperiments);
    }
    return hasExperimentChanges;
  }

  private checkIsSameExperimentSetting(a: number[], b: number[]): boolean {
    let aStr: string = null;
    let bStr: string = null;
    if (a instanceof Array) {
      aStr = a.sort().join('|');
    }
    if (b instanceof Array) {
      bStr = b.sort().join('|');
    }
    return aStr === bStr;
  }

}

export const APP_CONTEXT_STORAGE = new ApplicationContextStorage();
