/// <reference types="@types/googlemaps" />
import { IHotelCoordinations } from './hotel-coordination.model';

export enum GoogleStaticMapType {
  RoadMap = 'roadmap',
  Satellite = 'satellite',
  Terrain = 'terrain',
  Hybrid = 'hybrid',
}

export enum RequestPageType {
  HotelList,
  HotelDetail,
  BigGoogleMap,
  HotelMap
}

export type GoogleMapJavaScriptLibraryType = 'drawing' | 'geometry' | 'places' | 'visualization';

//
// Summary:
//     Status Code that returned by the search response
export enum StatusCodeTypeEnum {
    //
    // Summary:
    //     Indicates that nooccurred: errors; the place was successfully detected and at
    //     least one result was returned.
    OK = 'OK',
    //
    // Summary:
    //     Indicates that the search was successful but returned no results. This may occur
    //     if the search was passed a latlng in a remote location.
    ZERO_RESULTS = 'ZERO_RESULTS',
    //
    // Summary:
    //     Indicates that you are over your quota.
    OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
    //
    // Summary:
    //     Indicates that your request was denied, generally because of lack of an invalid
    //     key parameter.
    REQUEST_DENIED = 'REQUEST_DENIED',
    //
    // Summary:
    //     Generally indicates that a required query parameter (location or radius) is missing.
    INVALID_REQUEST = 'INVALID_REQUEST',
    //
    // Summary:
    //     UNKNOWN_ERROR
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

/**
 * Google Map Setting
 * @export
 * @interface IGoogleMapSetting
 */
export interface IGoogleMapSetting {
    /**
     * Google map api host domain(with protocal)
     * @type {string}
     * @memberof IGoogleMapSetting
     */
    ApiHost: string;
    /**
     * Maps JavaScript API Setting
     * @memberof IGoogleMapSetting
     */
    JavaScriptMap: {
        ApiKey: string;
        Libraries: GoogleMapJavaScriptLibraryType[];
    };
}

export interface IGoogleMapWebModel {
    HotelCoordinations: Array<IHotelCoordinations>;
    PageType: RequestPageType;
}

export interface IGeoPoint {
    Latitude: number;
    Longitude: number;
}

export interface GoogleStaticMapMarker {
    Location: IGeoPoint;
    Color: string;
}

export interface IDidaGoogleMapMarkerOpt extends google.maps.MarkerOptions {
    _info: any;
    // custom property: hotelId.
    _hotelId: number;
    _icon: string;
    _iconSelected: string;
    _infoWindow: any;
}

export interface IHotelDeatilMapInfo {
    name: string;
    hotelId: number;
    RegionId: string;
    CheckIn: Date;
    CheckOut: Date;
    hotelCoordinate: IHotelCoordinations;
}


export interface IPlaceSearchResponse {

    //
    // Summary:
    //     Attribution information which MUST BE displayed to the user
    html_attributions: string[];
    //
    // Summary:
    //     Search result items
    results: IPlaceSearchResultItem[];
    //
    // Summary:
    //     Response status
    status: StatusCodeTypeEnum | null;
}

//
export interface IPlaceSearchResultItem {

    //
    // Summary:
    //     时区的 offset
    utc_offset: number | null;
    //
    // Summary:
    //     Indicating whether the place has permanently shut down (value true). If the place
    //     is not permanently closed, the flag is absent from the response.
    permanently_closed: boolean | null;
    //
    // Summary:
    //     The price level of the place
    price_level: number | null;
    //
    // Summary:
    //     A feature name of a nearby location. Often this feature refers to a street or
    //     neighborhood within the given results. The vicinity property is only returned
    //     for a Nearby Search.
    vicinity: string;
    //
    // Summary:
    //     Feature types describing the given result.
    types: string[];
    //
    // Summary:
    //     The human-readable name for the returned result. For establishment results, this
    //     is usually the business name.
    name: string;
    //
    // Summary:
    //     The URL of a suggested icon which may be displayed to the user when indicating
    //     this result on a map.
    icon: string;
    //
    // Summary:
    //     Place Geometry
    geometry: IPlaceGeometry;
    //
    // Summary:
    //     The place's rating, from 1.0 to 5.0, based on aggregated user reviews.
    rating: number | null;
    //
    // Summary:
    //     A textual identifier that uniquely identifies a place.
    place_id: string;
    //
    id: string;
    //
    // Summary:
    //     官网
    website: string;


    /**
     * （前端计算）坐标距离，单位：KM
     *
     * @type {number}
     * @memberof PlaceSearchResultItem
     */
    _distance_?: number;
}

//
// Summary:
//     The Geometry information about the result, generally including the location (geocode)
//     of the place and (optionally) the viewport identifying its general area of coverage.
export interface IPlaceGeometry {
    //
    // Summary:
    //     GeoCoordinate
    location: IGoogleLatLng;
}


//
// Summary:
//     Google LatLng Model
export interface IGoogleLatLng {
    //
    // Summary:
    //     Latitude
    lat: number;
    //
    // Summary:
    //     Longitude
    lng: number;
}
