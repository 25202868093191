import { SuggestionItemCategory } from '../suggestions/suggestion-item-category';
import { Region } from './region.model';
import { Airport } from './airport.model';


export const CONTINENTS: Region[] = [
  new Region('6022967', SuggestionItemCategory.Continent, '欧洲', 'Europe', null, 3),
  new Region('6023099', SuggestionItemCategory.Continent, '亚洲', 'Asia', null, 1),
  // 大洋洲, // 澳洲 - 纽西兰与南太平洋
  new Region('6023180', SuggestionItemCategory.Continent, '大洋洲', 'Oceania', null, 4),
  // 美洲,  // 北美
  new Region('500001', SuggestionItemCategory.Continent, '美洲', 'America', null, 2),
  // 美洲,  // 加勒比地区
  // new Region('6022969', SuggestionItemCategory.Continent, '美洲', 'America', null, 0),
  // 美洲,  // 南美洲
  // new Region('6023117', SuggestionItemCategory.Continent, '美洲', 'America', null, 0),
  // 美洲,  // 墨西哥和中美洲
  // new Region('6023181', SuggestionItemCategory.Continent, '美洲', 'America', null, 0),
  // 中东/非洲, // 中东
  new Region('6023182', SuggestionItemCategory.Continent, '中东/非洲', 'Mid East/Africa', null, 5),
  // 中东/非洲, // 非洲与印度洋
  // new Region('6023185', SuggestionItemCategory.Continent, '中东/非洲', 'Mid East/Africa', null, 0),
  new Region('ESAsia', SuggestionItemCategory.Continent, '东南亚', 'Southeast Asia', null, 0)
];

export const HOT_SEARCH_CITY_ID_LIST: string[] = [
  // 上海
  '180026',
  // 北京
  '178237',
  // 深圳
  '6046792',
  // 广州
  '771',
  // 厦门
  '3863',
  // 南京
  '6084436',
  // 成都
  '930',
  // 杭州
  '6084457',
  // 澳门
  '104',
  // 曼谷
  '178236',
  // 普吉
  '6046393',
  // 苏梅岛
  '6035013',
  // 新加坡
  '3168',
  // 巴厘岛
  '602651',
  // 香港岛
  '184245',
  // 九龙
  '6059',
  // 铜锣湾
  '12124',
  // 台北
  '180030',
  // 东京
  '179900',
  // 大阪
  '179897',
  // 首尔
  '178308',
  //#region [移除 by https://tower.im/teams/191049/todos/94939/]
  // 洛杉矶
  // '178280',
  // 拉斯维加斯
  // '178276',
  // 纽约
  // '178293',
  // 伦敦
  // '178279',
  //#endregion
  // 巴黎
  '179898',
  //#region [移除 by https://tower.im/teams/191049/todos/94939/]
  // 罗马
  // '179899',
  //#endregion
  // 悉尼
  '178312',
  // 墨尔本
  '178283',
  // 奥克兰
  '178233',
  // 迪拜
  '1079'
];

let order = 0;
export const CITIES: Region[] = [
  // 东南亚 - 'ESAsia'
  new Region('178236', SuggestionItemCategory.MultiCity, '曼谷', 'Bangkok', 'ESAsia', order++),
  new Region('6046393', SuggestionItemCategory.MultiCity, '普吉', 'Phuket', 'ESAsia', order++),
  new Region('6056197', SuggestionItemCategory.City, '清迈', 'Chiang Mai', 'ESAsia', order++),
  new Region('602231', SuggestionItemCategory.City, '甲米', 'Krabi', 'ESAsia', order++),
  new Region('6035013', SuggestionItemCategory.City, '苏梅岛', 'Koh Samui', 'ESAsia', order++),
  new Region('9514', SuggestionItemCategory.MultiCity, '芭堤雅', 'Pattaya', 'ESAsia', order++),
  new Region('6049558', SuggestionItemCategory.MultiCity, '华欣', 'Hua Hin', 'ESAsia', order++),
  new Region('869', SuggestionItemCategory.City, '科伦坡', 'Colombo', 'ESAsia', order++),
  new Region('180008', SuggestionItemCategory.MultiCity, '吉隆坡', 'Kuala Lumpur', 'ESAsia', order++),
  new Region('11188', SuggestionItemCategory.Province, '沙巴', 'Sabah', 'ESAsia', order++),
  new Region('2766', SuggestionItemCategory.Province, '槟城', 'Penang', 'ESAsia', order++),
  new Region('2062', SuggestionItemCategory.HighLevelRegion, '兰卡威', 'Langkawi', 'ESAsia', order++),
  new Region('3168', SuggestionItemCategory.City, '新加坡', 'Singapore', 'ESAsia', order++),
  new Region('178282', SuggestionItemCategory.MultiCity, '马尼拉', 'Manila', 'ESAsia', order++),
  new Region('800', SuggestionItemCategory.City, '宿务', 'Cebu', 'ESAsia', order++),
  new Region('601371', SuggestionItemCategory.City, '长滩岛', 'Boracay Island', 'ESAsia', order++),
  new Region('178269', SuggestionItemCategory.MultiCity, '雅加达', 'Jakarta', 'ESAsia', order++),
  new Region('602651', SuggestionItemCategory.Province, '巴厘岛', 'Bali', 'ESAsia', order++),
  new Region('178262', SuggestionItemCategory.MultiCity, '胡志明', 'Ho Chi Minh City', 'ESAsia', order++),
  new Region('974', SuggestionItemCategory.MultiCity, '岘港', 'Da Nang', 'ESAsia', order++),
  new Region('1428', SuggestionItemCategory.MultiCity, '河内', 'Hanoi', 'ESAsia', order++),
  new Region('6054439', SuggestionItemCategory.MultiCity, '芽庄', 'Nha Trang', 'ESAsia', order++),
  new Region('2975', SuggestionItemCategory.City, '暹粒', 'Siem Reap', 'ESAsia', order++),
  new Region('6191801', SuggestionItemCategory.MultiCity, '金边', 'Phnom Penh', 'ESAsia', order++),

  // 亚洲 - 6023099
  new Region('77', SuggestionItemCategory.City, '香港', 'Hong Kong', '6023099', order++),
  new Region('6059', SuggestionItemCategory.City, '九龙', 'Kowloon', '6023099', order++),
  new Region('184245', SuggestionItemCategory.City, '香港岛', 'Hong Kong Island', '6023099', order++),
  new Region('12124', SuggestionItemCategory.Neighborhood, '铜锣湾', 'Causeway Bay', '6023099', order++),
  new Region('104', SuggestionItemCategory.City, '澳门', 'Macau', '6023099', order++),
  new Region('6139889', SuggestionItemCategory.City, '氹仔', 'Taipa', '6023099', order++),
  new Region('180030', SuggestionItemCategory.MultiCity, '台北', 'Taipei', '6023099', order++),
  new Region('177485', SuggestionItemCategory.City, '桃园', 'Taoyuan', '6023099', order++),
  new Region('6177563', SuggestionItemCategory.MultiCity, '台中', 'Taichung', '6023099', order++),
  new Region('6177060', SuggestionItemCategory.MultiCity, '台南', 'Tainan', '6023099', order++),
  new Region('6177725', SuggestionItemCategory.MultiCity, '高雄', 'Kaohsiung', '6023099', order++),
  new Region('179900', SuggestionItemCategory.MultiCity, '东京', 'Tokyo', '6023099', order++),
  new Region('179897', SuggestionItemCategory.MultiCity, '大阪', 'Osaka', '6023099', order++),
  new Region('6131486', SuggestionItemCategory.MultiCity, '京都', 'Kyoto', '6023099', order++),
  new Region('6053824', SuggestionItemCategory.City, '冲绳', 'Okinawa City', '6023099', order++),
  new Region('6054145', SuggestionItemCategory.MultiCity, '札幌', 'Sapporo', '6023099', order++),
  new Region('1265', SuggestionItemCategory.MultiCity, '福冈', 'Fukuoka', '6023099', order++),
  new Region('6127908', SuggestionItemCategory.MultiCity, '名古屋', 'Nagoya', '6023099', order++),
  new Region('178308', SuggestionItemCategory.MultiCity, '首尔', 'Seoul', '6023099', order++),
  new Region('6049718', SuggestionItemCategory.HighLevelRegion, '济州岛', 'Jeju Island', '6023099', order++),
  new Region('602043', SuggestionItemCategory.City, '釜山', 'Busan', '6023099', order++),
  new Region('178237', SuggestionItemCategory.MultiCity, '北京', 'Beijing', '6023099', order++),
  new Region('180026', SuggestionItemCategory.MultiCity, '上海', 'Shanghai', '6023099', order++),
  new Region('771', SuggestionItemCategory.MultiCity, '广州', 'Guangzhou', '6023099', order++),
  new Region('6046792', SuggestionItemCategory.MultiCity, '深圳', 'Shenzhen', '6023099', order++),
  new Region('3863', SuggestionItemCategory.MultiCity, '厦门', 'Xiamen', '6023099', order++),
  new Region('6084436', SuggestionItemCategory.MultiCity, '南京', 'Nanjing', '6023099', order++),
  new Region('930', SuggestionItemCategory.MultiCity, '成都', 'Chengdu', '6023099', order++),
  new Region('6084457', SuggestionItemCategory.MultiCity, '杭州', 'Shenzhen', '6023099', order++),

// 美洲 - 500001(NA), 6022969(C), 6023117(SA), 6023181(MA)
  new Region('178280', SuggestionItemCategory.MultiCity, '洛杉矶', 'Los Angeles', '500001', order++),
  new Region('178305', SuggestionItemCategory.MultiCity, '旧金山', 'San Francisco', '500001', order++),
  new Region('178307', SuggestionItemCategory.MultiCity, '西雅图', 'Seattle', '500001', order++),
  new Region('178304', SuggestionItemCategory.Province, '圣迭戈', 'San Diego', '500001', order++),
  new Region('178276', SuggestionItemCategory.MultiCity, '拉斯维加斯', 'Las Vegas', '500001', order++),
  new Region('178265', SuggestionItemCategory.MultiCity, '休斯敦', 'Houston', '500001', order++),
  new Region('178239', SuggestionItemCategory.MultiCity, '波士顿', 'Boston', '500001', order++),
  new Region('178293', SuggestionItemCategory.MultiCity, '纽约', 'New York', '500001', order++),
  new Region('178248', SuggestionItemCategory.MultiCity, '芝加哥', 'Chicago', '500001', order++),
  new Region('178318', SuggestionItemCategory.MultiCity, '华盛顿', 'Washington', '500001', order++),
  new Region('178294', SuggestionItemCategory.MultiCity, '奥兰多', 'Orlando', '500001', order++),
  new Region('178286', SuggestionItemCategory.MultiCity, '迈阿密', 'Miami', '500001', order++),
  new Region('213', SuggestionItemCategory.Province, '夏威夷', 'Hawaii', '500001', order++),
  new Region('178315', SuggestionItemCategory.MultiCity, '温哥华', 'Vancouver', '500001', order++),
  new Region('178314', SuggestionItemCategory.MultiCity, '多伦多', 'Toronto', '500001', order++),
  new Region('178288', SuggestionItemCategory.MultiCity, '蒙特利尔', 'Montreal', '500001', order++),
  new Region('178285', SuggestionItemCategory.MultiCity, '墨西哥城', 'Mexico City', '500001', order++),
  new Region('179995', SuggestionItemCategory.MultiCity, '坎昆', 'Cancun', '500001', order++),
  new Region('6023330', SuggestionItemCategory.MultiCity, '巴拿马', 'Panama City', '500001', order++),
  new Region('178306', SuggestionItemCategory.MultiCity, '圣地亚哥', 'Santiago', '500001', order++),
  new Region('180023', SuggestionItemCategory.MultiCity, '圣保罗', 'Sao Paulo', '500001', order++),
  new Region('178301', SuggestionItemCategory.MultiCity, '里约热内卢', 'Rio de Janeiro', '500001', order++),
  new Region('2077', SuggestionItemCategory.City, '利马', 'Lima', '500001', order++),
  new Region('178242', SuggestionItemCategory.MultiCity, '布宜诺斯艾利斯', 'Buenos Aires', '500001', order++),

// 欧洲 - 6022967
  new Region('178279', SuggestionItemCategory.MultiCity, '伦敦', 'London', '6022967', order++),
  new Region('180010', SuggestionItemCategory.Province, '曼彻斯特', 'Manchester', '6022967', order++),
  new Region('179894', SuggestionItemCategory.MultiCity, '法兰克福', 'Frankfurt', '6022967', order++),
  new Region('179896', SuggestionItemCategory.MultiCity, '慕尼黑', 'Munich', '6022967', order++),
  new Region('179892', SuggestionItemCategory.MultiCity, '柏林', 'Berlin', '6022967', order++),
  new Region('178229', SuggestionItemCategory.MultiCity, '阿姆斯特丹', 'Amsterdam', '6022967', order++),
  new Region('179898', SuggestionItemCategory.MultiCity, '巴黎', 'Paris', '6022967', order++),
  new Region('180102', SuggestionItemCategory.MultiCity, '尼斯', 'Nice', '6022967', order++),
  new Region('179899', SuggestionItemCategory.MultiCity, '罗马', 'Rome', '6022967', order++),
  new Region('180012', SuggestionItemCategory.MultiCity, '米兰', 'Milan', '6022967', order++),
  new Region('179981', SuggestionItemCategory.MultiCity, '威尼斯', 'Venice', '6022967', order++),
  new Region('4191', SuggestionItemCategory.City, '苏黎世', 'Zürich', '6022967', order++),
  new Region('178231', SuggestionItemCategory.MultiCity, '雅典', 'Athens', '6022967', order++),
  new Region('177970', SuggestionItemCategory.City, '圣托里尼岛', 'Santorini', '6022967', order++),
  new Region('178281', SuggestionItemCategory.MultiCity, '马德里', 'Madrid', '6022967', order++),
  new Region('179992', SuggestionItemCategory.MultiCity, '巴塞罗那', 'Barcelona', '6022967', order++),
  new Region('178289', SuggestionItemCategory.MultiCity, '莫斯科', 'Moscow', '6022967', order++),
  new Region('180029', SuggestionItemCategory.MultiCity, '圣彼得堡', 'St. Petersburg', '6022967', order++),
  new Region('178316', SuggestionItemCategory.MultiCity, '维也纳', 'Vienna', '6022967', order++),
  new Region('178267', SuggestionItemCategory.MultiCity, '伊斯坦布尔', 'Istanbul', '6022967', order++),
  new Region('180014', SuggestionItemCategory.MultiCity, '布拉格', 'Prague', '6022967', order++),
  new Region('178252', SuggestionItemCategory.MultiCity, '哥本哈根', 'Copenhagen', '6022967', order++),

// 大洋洲 - 6023180
  new Region('178312', SuggestionItemCategory.MultiCity, '悉尼', 'Sydney', '6023180', order++),
  new Region('178283', SuggestionItemCategory.MultiCity, '墨尔本', 'Melbourne', '6023180', order++),
  new Region('179993', SuggestionItemCategory.MultiCity, '布里斯班', 'Brisbane', '6023180', order++),
  new Region('6051330', SuggestionItemCategory.MultiCity, '阿德莱德', 'Adelaide', '6023180', order++),
  new Region('553248635404168639', SuggestionItemCategory.MultiCity, '凯恩斯', 'Cairns', '6023180', order++),
  new Region('180064', SuggestionItemCategory.MultiCity, '黄金海岸', 'Gold Coast', '6023180', order++),
  new Region('6049663', SuggestionItemCategory.MultiCity, '堪培拉', 'Canberra', '6023180', order++),
  new Region('180013', SuggestionItemCategory.MultiCity, '珀斯', 'Perth', '6023180', order++),
  new Region('6052485', SuggestionItemCategory.MultiCity, '霍巴特', 'Hobart', '6023180', order++),
  new Region('178233', SuggestionItemCategory.MultiCity, '奥克兰', 'Auckland', '6023180', order++),
  new Region('179996', SuggestionItemCategory.MultiCity, '基督城', 'Christchurch', '6023180', order++),
  new Region('6127327', SuggestionItemCategory.MultiCity, '惠灵顿', 'Wellington', '6023180', order++),
  new Region('180018', SuggestionItemCategory.MultiCity, '皇后镇', 'Queenstown', '6023180', order++),
  new Region('6048125', SuggestionItemCategory.MultiCity, '罗托鲁瓦', 'Rotorua', '6023180', order++),

// 中东/非洲 - 6023182(ME), 6023185(AF)
  new Region('1079', SuggestionItemCategory.Province, '迪拜', 'Dubai', '6023182', order++),
  new Region('6053838', SuggestionItemCategory.Province, '阿布扎比', 'Abu Dhabi', '6023182', order++),
  new Region('6160459', SuggestionItemCategory.Province, '沙迦', 'Sharjah', '6023182', order++),
  new Region('1048', SuggestionItemCategory.MultiCity, '多哈', 'Doha', '6023182', order++),
  new Region('180031', SuggestionItemCategory.MultiCity, '特拉维夫', 'Tel Aviv', '6023182', order++),
  new Region('375', SuggestionItemCategory.MultiCity, '安曼', 'Amman', '6023182', order++),
  new Region('1809', SuggestionItemCategory.City, '卡拉奇', 'Karachi', '6023182', order++),
  new Region('178243', SuggestionItemCategory.MultiCity, '开罗', 'Cairo', '6023182', order++),
  new Region('359', SuggestionItemCategory.City, '阿尔及尔', 'Algiers', '6023182', order++),
  new Region('178290', SuggestionItemCategory.MultiCity, '内罗毕', 'Nairobi', '6023182', order++),
  new Region('6143873', SuggestionItemCategory.MultiCity, '蒙巴萨', 'Mombasa', '6023182', order++),
  new Region('3699', SuggestionItemCategory.City, '维多利亚瀑布', 'Victoria Falls', '6023182', order++),
  new Region('178272', SuggestionItemCategory.City, '约翰内斯堡', 'Johannesburg', '6023182', order++),
  new Region('6046820', SuggestionItemCategory.MultiCity, '开普敦', 'Cape Town', '6023182', order++),
  new Region('500119', SuggestionItemCategory.City, '毛里求斯', 'Mauritius', '6023182', order++),
  new Region('3127', SuggestionItemCategory.City, '塞舌尔', 'Seychelles', '6023182', order++)
];

const cityDict: { [key: string]: Region } = CITIES.reduce((accu, region) => {
  accu[region.id] = region;
  return accu;
}, {});


export const AIRPORT_CITIES: Region[] = [
  // 亚洲 - 6023099
  new Region('10805', SuggestionItemCategory.City, '冲绳岛', 'Okinawa', '6023099', order++),
  new Region('178236', SuggestionItemCategory.City, '曼谷', 'Bangkok', '6023099', order++),
  new Region('178237', SuggestionItemCategory.City, '北京', 'Beijing', '6023099', order++),
  new Region('178308', SuggestionItemCategory.City, '首尔', 'Seoul', '6023099', order++),
  new Region('179897', SuggestionItemCategory.City, '大阪', 'Osaka', '6023099', order++),
  new Region('179900', SuggestionItemCategory.City, '东京', 'Tokyo', '6023099', order++),
  new Region('180008', SuggestionItemCategory.City, '吉隆坡', 'Kuala Lumpur', '6023099', order++),
  new Region('180026', SuggestionItemCategory.City, '上海', 'Shanghai', '6023099', order++),
  new Region('180030', SuggestionItemCategory.City, '台北', 'Taipei', '6023099', order++),
  new Region('184245', SuggestionItemCategory.City, '香港', 'Hong Kong', '6023099', order++),
  new Region('3168', SuggestionItemCategory.City, '新加坡', 'Singapore', '6023099', order++),
  new Region('6035013', SuggestionItemCategory.City, '苏梅岛', 'Koh Samui', '6023099', order++),
  new Region('6046393', SuggestionItemCategory.City, '普吉', 'Phuket', '6023099', order++),
  new Region('602651', SuggestionItemCategory.City, '巴厘岛', 'Bali', '6023099', order++),
  new Region('6048315', SuggestionItemCategory.City, '西归浦', 'Seogwipo', '6023099', order++),
  new Region('6177725', SuggestionItemCategory.City, '高雄', 'Kaohsiung', '6023099', order++),
  new Region('8724', SuggestionItemCategory.City, '澳门', 'Macau', '6023099', order++),

  // 欧洲 - 6022967
  new Region('178252', SuggestionItemCategory.City, '哥本哈根', 'Copenhagen', '6022967', order++),
  new Region('178279', SuggestionItemCategory.City, '伦敦', 'London', '6022967', order++),
  new Region('178281', SuggestionItemCategory.City, '马德里', 'Madrid', '6022967', order++),
  new Region('178289', SuggestionItemCategory.City, '莫斯科', 'Moscow', '6022967', order++),
  new Region('179894', SuggestionItemCategory.City, '法兰克福', 'Frankfurt', '6022967', order++),
  new Region('179898', SuggestionItemCategory.City, '巴黎', 'Paris', '6022967', order++),
  new Region('179899', SuggestionItemCategory.City, '罗马', 'Rome', '6022967', order++),
  new Region('179992', SuggestionItemCategory.City, '巴塞罗那', 'Barcelona', '6022967', order++),
  new Region('180012', SuggestionItemCategory.City, '米兰', 'Milan', '6022967', order++),
  new Region('4726', SuggestionItemCategory.City, '亚眠', 'Amiens', '6022967', order++),
  new Region('5203', SuggestionItemCategory.City, '特里尔', 'Trier', '6022967', order++),
  new Region('6046241', SuggestionItemCategory.City, '利物浦', 'Liverpool', '6022967', order++),
  new Region('6069845', SuggestionItemCategory.City, '爱丁堡', 'Edinburgh', '6022967', order++),

  // 中东或非州 - 6023182
  new Region('178243', SuggestionItemCategory.City, '开罗', 'Cairo', '6023182', order++),
  new Region('180031', SuggestionItemCategory.City, '特拉维夫', 'Tel Aviv', '6023182', order++),
  new Region('3127', SuggestionItemCategory.City, '马希岛', 'Mahe Island', '6023182', order++),
  new Region('553248635974547388', SuggestionItemCategory.City, '迪拜', 'Dubai', '6023182', order++),
  new Region('602195', SuggestionItemCategory.City, '贝尔马尔', 'Belle Mare', '6023182', order++),
  new Region('6046820', SuggestionItemCategory.City, '开普敦', 'Cape Town', '6023182', order++),
  new Region('6051471', SuggestionItemCategory.City, '约翰尼斯堡', 'Johannesburg', '6023182', order++),

  // 东南亚 - ESAsia
  new Region('1428', SuggestionItemCategory.City, '河内', 'Hanoi', 'ESAsia', order++),
  new Region('178269', SuggestionItemCategory.City, '雅加达', 'Jakarta', 'ESAsia', order++),
  new Region('178282', SuggestionItemCategory.City, '马尼拉', 'Manila', 'ESAsia', order++),
  new Region('2975', SuggestionItemCategory.City, '暹粒', 'Siem Reap', 'ESAsia', order++),
  new Region('602', SuggestionItemCategory.City, '亚庇', 'Kota Kinabalu', 'ESAsia', order++),
  new Region('6023813', SuggestionItemCategory.City, '乔治市', 'George Town', 'ESAsia', order++),
  new Region('6054439', SuggestionItemCategory.City, '芽庄', 'Nha Trang', 'ESAsia', order++),
  new Region('6056197', SuggestionItemCategory.City, '清迈', 'Chiang Mai', 'ESAsia', order++),
  new Region('6142141', SuggestionItemCategory.City, '古晋', 'Kuching', 'ESAsia', order++),
  new Region('6191801', SuggestionItemCategory.City, '金边', 'Phnom Penh', 'ESAsia', order++),
  new Region('800', SuggestionItemCategory.City, '宿务', 'Cebu', 'ESAsia', order++),

  // 大洋洲 - 6023180
  new Region('178233', SuggestionItemCategory.City, '奥克兰', 'Auckland', '6023180', order++),
  new Region('178283', SuggestionItemCategory.City, '墨尔本', 'Melbourne', '6023180', order++),
  new Region('178312', SuggestionItemCategory.City, '悉尼', 'Sydney', '6023180', order++),
  new Region('179996', SuggestionItemCategory.City, '基督城', 'Christchurch', '6023180', order++),
  new Region('180013', SuggestionItemCategory.City, '珀斯', 'Perth', '6023180', order++),
  new Region('180018', SuggestionItemCategory.City, '皇后镇', 'Queenstown', '6023180', order++),
  new Region('180064', SuggestionItemCategory.City, '黄金海岸', 'Gold Coast', '6023180', order++),
  new Region('2515', SuggestionItemCategory.City, '楠迪', 'Nadi', '6023180', order++),
  new Region('6049663', SuggestionItemCategory.City, '堪培拉', 'Canberra', '6023180', order++),
  new Region('6051330', SuggestionItemCategory.City, '阿德莱德', 'Adelaide', '6023180', order++),
  new Region('6268014', SuggestionItemCategory.City, '邦加娄岛', 'Bungalow', '6023180', order++),

  // 美洲 - 500001
  new Region('1488', SuggestionItemCategory.City, '檀香山', 'Honolulu', '500001', order++),
  new Region('178232', SuggestionItemCategory.City, '亚特兰大', 'Atlanta', '500001', order++),
  new Region('178239', SuggestionItemCategory.City, '波士顿', 'Boston', '500001', order++),
  new Region('178248', SuggestionItemCategory.City, '芝加哥', 'Chicago', '500001', order++),
  new Region('178280', SuggestionItemCategory.City, '洛杉矶', 'Los Angeles', '500001', order++),
  new Region('178285', SuggestionItemCategory.City, '墨西哥城', 'Mexico City', '500001', order++),
  new Region('178293', SuggestionItemCategory.City, '纽约', 'New York', '500001', order++),
  new Region('178301', SuggestionItemCategory.City, '里约热内卢', 'Rio de Janeiro', '500001', order++),
  new Region('178305', SuggestionItemCategory.City, '旧金山', 'San Francisco', '500001', order++),
  new Region('178307', SuggestionItemCategory.City, '西雅图', 'Seattle', '500001', order++),
  new Region('178314', SuggestionItemCategory.City, '多伦多', 'Toronto', '500001', order++),
  new Region('178315', SuggestionItemCategory.City, '温哥华', 'Vancouver', '500001', order++),
  new Region('178318', SuggestionItemCategory.City, '华盛顿', 'Washington', '500001', order++),
  new Region('602179', SuggestionItemCategory.City, '大西洋城', 'Atlantic City', '500001', order++)
];

const airportCityDict: { [key: string]: Region } = AIRPORT_CITIES.reduce((accu, region) => {
  accu[region.id] = region;
  return accu;
}, {});

let airportOrder = 0;
export const AIRPORTS_HOT_SEARCH_CITY_ID_LIST = [
  '178236', // 曼谷
  '179900', // 东京
  '179897', // 大阪
  '6046393', // 普吉岛
  // '180030', // 台北
  '6035013', // 苏梅岛
  '178279', // 伦敦
  '602651', // 巴厘岛
  '3168', // 新加坡
  '179898', // 巴黎
  // '180008', // 吉隆坡
  '553248635974547388' // 迪拜
];

export const AIRPORTS: Airport[] = [
  // 亚洲 - 6023099
  new Airport('1860', 'HKT', airportCityDict['6046393'], '普吉 (HKT)', 'Int. Airport Phuket', airportOrder++),
  new Airport('502', 'BKK', airportCityDict['178236'], '曼谷素万那普 (BKK)', 'Suvarnabhumi Int. Airport', airportOrder++),
  new Airport('1162', 'DMK', airportCityDict['178236'], '曼谷廊曼 (DMK)', 'Don Muang Int. Airport', airportOrder++),
  new Airport('1888', 'HND', airportCityDict['179900'], '东京羽田国际机场 (HND)', 'Haneda Int. Airport', airportOrder++),
  new Airport('3444', 'NRT', airportCityDict['179900'], '东京成田国际机场 (NRT)', 'Narita Int. Airport', airportOrder++),
  new Airport('2386', 'KIX', airportCityDict['179897'], '大阪关西国际机场 (KIX)', 'Kansai Int. Airport', airportOrder++),
  // new Airport('2126', 'ITM', airportCityDict['179897'], '大阪伊丹机场 (ITM)', 'Itami Int. Airport', airportOrder++),
  new Airport('5106', 'USM', airportCityDict['6035013'], '苏梅岛 (USM)', 'Int. Airport Koh Samui', airportOrder++),
  new Airport('1189', 'DPS', airportCityDict['602651'], '巴厘岛 (登巴沙) (DPS)', 'Ngurah Rai Int. Airport', airportOrder++),
  new Airport('4415', 'SIN', airportCityDict['3168'], '新加坡樟宜机场 (SIN)', 'Changi International Airport', airportOrder++),
  new Airport('2004', 'ICN', airportCityDict['178308'], '首尔仁川国际机场 (ICN)', 'Incheon Int. Airport', airportOrder++),
  new Airport('1657', 'GMP', airportCityDict['178308'], '首尔金浦国际机场 (GMP)', 'Gimpo Int. Airport', airportOrder++),
  new Airport('1856', 'HKG', airportCityDict['184245'], '香港国际机场 (HKG)', 'Hong Kong Int. Airport', airportOrder++),
  new Airport('878', 'CJU', airportCityDict['6048315'], '济州岛 (CJU)', 'Int. Airport Jeju', airportOrder++),
  new Airport('2993', 'MFM', airportCityDict['8724'], '澳门 (MFM)', 'Macao Int. Airport', airportOrder++),
  new Airport('2364', 'KHH', airportCityDict['6177725'], '高雄 (KHH)', 'Kaohsiung Int. Airport', airportOrder++),
  new Airport('3731', 'PEK', airportCityDict['178237'], '北京首都国际机场 (PEK)', 'Capital Int. Airport', airportOrder++),
  new Airport('3959', 'PVG', airportCityDict['180026'], '上海浦东 (PVG)', 'Shanghai Pudong Int. Airport', airportOrder++),
  new Airport('4386', 'SHA', airportCityDict['180026'], '上海虹桥 (SHA)', 'Hongqiao Int. Airport', airportOrder++),
  new Airport('2299', 'KBV', airportCityDict['6046393'], '甲米 (KBV)', 'Airport Krabi', airportOrder++),
  new Airport('4910', 'TPE', airportCityDict['180030'], '台北台湾桃园国际机场 (TPE)', 'Taiwan Taoyuan Int. Airport', airportOrder++),
  new Airport('4940', 'TSA', airportCityDict['180030'], '台北松山机场 (TSA)', 'Taipei Songshan Airport', airportOrder++),
  new Airport('2542', 'KUL', airportCityDict['180008'], '吉隆坡国际 (KUL)', 'Int. Airport', airportOrder++),
  // new Airport('3551', 'OKA', airportCityDict['10805'], '冲绳那霸 (OKA)', 'Naha Airport', airportOrder++),

  // 欧洲 - 6022967
  new Airport('2644', 'LCY', airportCityDict['178279'], '伦敦城市机场 (LCY)', 'London City Airport', airportOrder++),
  new Airport('2697', 'LGW', airportCityDict['178279'], '伦敦盖特威克机场 (LGW)', 'Gatwick Airport', airportOrder++),
  new Airport('2702', 'LHR', airportCityDict['178279'], '伦敦希斯罗机场 (LHR)', 'Heathrow Airport', airportOrder++),
  new Airport('2826', 'LTN', airportCityDict['178279'], '伦敦卢顿机场 (LTN)', 'London Luton Airport', airportOrder++),
  new Airport('4594', 'STN', airportCityDict['178279'], '伦敦斯坦斯特德机场 (STN)', 'Stansted Airport', airportOrder++),
  new Airport('661', 'BVA', airportCityDict['4726'], '巴黎博韦-提叶机场 (BVA)', 'Beauvais Tillé Airport', airportOrder++),
  new Airport('773', 'CDG', airportCityDict['179898'], '巴黎夏尔戴高乐国际机场 (CDG)', 'Charles de Gaulle Airport', airportOrder++),
  new Airport('3625', 'ORY', airportCityDict['179898'], '巴黎奥利机场 (ORY)', 'Orly Airport', airportOrder++),
  new Airport('376', 'BCN', airportCityDict['179992'], '巴塞罗那 (BCN)', 'El Prat Airport', airportOrder++),
  new Airport('2899', 'MAD', airportCityDict['178281'], '马德里 (MAD)', 'Adolfo Suárez Madrid-Barajas Airport', airportOrder++),
  new Airport('953', 'CPH', airportCityDict['178252'], '哥本哈根 (CPH)', 'Kastrup Copenhagen Airport', airportOrder++),
  new Airport('852', 'CIA', airportCityDict['179899'], '罗马 (CIA)', 'Ciampino Airport', airportOrder++),
  new Airport('1439', 'FCO', airportCityDict['179899'], '罗马 (FCO)', 'Leonardo da Vinci Int. Airport', airportOrder++),
  new Airport('1275', 'EDI', airportCityDict['6069845'], '爱丁堡 (EDI)', 'Edinburgh Airport', airportOrder++),
  new Airport('2792', 'LPL', airportCityDict['6046241'], '利物浦 (LPL)', 'Int. John Lennon Airport', airportOrder++),
  new Airport('1506', 'FRA', airportCityDict['179894'], '法兰克福 (FRA)', 'Frankfurt Am Main Airport', airportOrder++),
  new Airport('1836', 'HHN', airportCityDict['5203'], '法兰克福哈恩 (HHN)', 'Frankfurt Hahn Airport', airportOrder++),
  new Airport('1161', 'DME', airportCityDict['178289'], '莫斯科多莫杰多沃 (DME)', 'Moscú Domodedovo Int. Airport', airportOrder++),
  new Airport('4631', 'SVO', airportCityDict['178289'], '莫斯科谢列梅捷沃 (SVO)', 'Moscow Sheremetyevo Int. Airport', airportOrder++),
  new Airport('5194', 'VKO', airportCityDict['178289'], '莫斯科瓦努科沃 (VKO)', 'Vnukovo Int. Airport', airportOrder++),
  new Airport('450', 'BGY', airportCityDict['180012'], '米兰博加默 (BGY)', 'Orio Al Serio Airport', airportOrder++),
  new Airport('2711', 'LIN', airportCityDict['180012'], '米兰里纳特 (LIN)', 'Linate Int. Airport', airportOrder++),
  new Airport('3271', 'MXP', airportCityDict['180012'], '米兰马彭撒 (MXP)', 'Malpensa Airport', airportOrder++),

  // 中东或非州 - 6023182
  new Airport('1242', 'DXB', airportCityDict['553248635974547388'], '迪拜 (DXB)', 'Dubai Int. Airport', airportOrder++),
  new Airport('960', 'CPT', airportCityDict['6046820'], '开普敦 (CPT)', 'Cape Town Int. Airport', airportOrder++),
  new Airport('728', 'CAI', airportCityDict['178243'], '开罗 (CAI)', 'Cairo Int. Airport', airportOrder++),
  new Airport('4338', 'SDV', airportCityDict['180031'], '特拉维夫 (SDV)', 'Sde Dove Airport', airportOrder++),
  new Airport('3179', 'MRU', airportCityDict['602195'], '毛里求斯 (MRU)', 'Sir Seewoosagur Ramgoolam Int. Airport', airportOrder++),
  new Airport('2232', 'JNB', airportCityDict['6051471'], '约翰内斯堡奥利弗·雷金纳德·坦博机场 (JNB)', 'O R Tambo Int. Airport', airportOrder++),
  new Airport('4352', 'SEZ', airportCityDict['3127'], '马埃岛 (SEZ)', 'Seychelles Int. Airport', airportOrder++),

  // 东南亚 - ESAsia
  new Airport('500', 'BKI', airportCityDict['602'], '亚庇 (哥打京那巴鲁) (BKI)', 'Kota Kinabalu Int. Airport', airportOrder++),
  new Airport('2307', 'KCH', airportCityDict['6142141'], '古晋 (KCH)', 'Int. Airport Kuching', airportOrder++),
  new Airport('933', 'CNX', airportCityDict['6056197'], '清迈 (CNX)', 'Chiang Mai Int. Airport', airportOrder++),
  new Airport('821', 'CGK', airportCityDict['178269'], '雅加达苏卡莫 (CGK)', 'Soekarno Hatta Int. Airport', airportOrder++),
  new Airport('3120', 'MNL', airportCityDict['178282'], '马尼拉 (MNL)', 'Ninoy Aquino Int. Airport', airportOrder++),
  new Airport('784', 'CEB', airportCityDict['800'], '宿务 (CEB)', 'Mactán Cebú Int. Airport', airportOrder++),
  new Airport('4118', 'REP', airportCityDict['2975'], '暹粒市 (吴哥窟) (REP)', 'Angkor Int. Airport', airportOrder++),
  new Airport('3733', 'PEN', airportCityDict['6023813'], '槟城 (PEN)', 'Penang Int. Airport', airportOrder++),
  new Airport('3843', 'PNH', airportCityDict['6191801'], '金边 (PNH)', 'Phnom Penh Int. Airport', airportOrder++),
  new Airport('1784', 'HAN', airportCityDict['1428'], '河内 (HAN)', 'Noi Bai Int. Airport', airportOrder++),
  new Airport('1050', 'CXR', airportCityDict['6054439'], '芽庄 (CXR)', 'Cam Ranh Int. Airport', airportOrder++),

  // 大洋洲 - 6023180
  new Airport('4672', 'SYD', airportCityDict['178312'], '悉尼金斯福德·史密斯 (SYD)', 'Int. Airport Kingsford Smith', airportOrder++),
  new Airport('932', 'CNS', airportCityDict['6268014'], '凯恩斯 (CNS)', 'Cairns Airport', airportOrder++),
  new Airport('549', 'BNE', airportCityDict['180064'], '布里斯班 (BNE)', 'Brisbane Airport', airportOrder++),
  new Airport('833', 'CHC', airportCityDict['179996'], '克瑞斯特彻奇 (CHC)', 'Christchurch Int. Airport', airportOrder++),
  new Airport('308', 'AVV', airportCityDict['178283'], '墨尔本阿瓦隆 (AVV)', 'Avalon International Airport', airportOrder++),
  new Airport('2975', 'MEL', airportCityDict['178283'], '墨尔本泰勒马林 (MEL)', 'Tullamarine Int. Airport', airportOrder++),
  new Airport('135', 'AKL', airportCityDict['178233'], '奥克兰 (AKL)', 'Auckland Int. Airport', airportOrder++),
  new Airport('3600', 'OOL', airportCityDict['180064'], '黄金海岸 (OOL)', 'Coolangatta Airport', airportOrder++),
  new Airport('53', 'ADL', airportCityDict['6051330'], '阿德莱德 (ADL)', 'Adelaide Int. Airport', airportOrder++),
  new Airport('3319', 'NAN', airportCityDict['2515'], '楠迪 (NAN)', 'Nadi International Airport', airportOrder++),
  new Airport('3736', 'PER', airportCityDict['180013'], '珀斯 (PER)', 'Perth Int. Airport', airportOrder++),
  new Airport('5947', 'ZQN', airportCityDict['180018'], '皇后镇 (ZQN)', 'Queenstown Airport', airportOrder++),
  new Airport('750', 'CBR', airportCityDict['6049663'], '堪培拉 (CBR)', 'Canberra', airportOrder++),

  // 美洲 - 500001
  new Airport('5851', 'YYZ', airportCityDict['178314'], '多伦多皮尔逊国际 (YYZ)', 'Lester B Pearson Int. Airport', airportOrder++),
  new Airport('5806', 'YVR', airportCityDict['178315'], '温哥华国际 (YVR)', 'Vancouver Int. Airport', airportOrder++),
  new Airport('2610', 'LAX', airportCityDict['178280'], '洛杉矶国际 (LAX)', 'Los Angeles Int. Airport', airportOrder++),
  new Airport('4364', 'SFO', airportCityDict['178305'], '旧金山国际 (SFO)', 'San Francisco Int. Airport', airportOrder++),
  new Airport('4341', 'SEA', airportCityDict['178307'], '西雅图/塔科马国际 (SEA)', 'Int. Airport Tacoma', airportOrder++),
  new Airport('1410', 'EWR', airportCityDict['178293'], '纽约纽瓦克自由国际机场 (EWR)', 'Newark Liberty Int. Airport', airportOrder++),
  new Airport('2194', 'JFK', airportCityDict['178293'], '纽约约翰肯尼迪机场 (JFK)', 'John F Kennedy Int. Airport', airportOrder++),
  new Airport('2682', 'LGA', airportCityDict['178293'], '纽约拉瓜迪亚机场 (LGA)', 'La Guardia Airport', airportOrder++),
  new Airport('571', 'BOS', airportCityDict['178239'], '波士顿洛根国际 (BOS)', 'Boston Logan Int. Airport', airportOrder++),
  new Airport('1624', 'GIG', airportCityDict['178301'], '里約熱內盧 (GIG)', 'Int. Antônio Carlos Jobim Airport', airportOrder++),
  new Airport('4337', 'SDU', airportCityDict['178301'], '里約熱內盧 (SDU)', 'Santos Dumont Airport', airportOrder++),
  new Airport('1096', 'DCA', airportCityDict['178318'], '华盛顿罗纳德里根 (DCA)', 'Ronald Reagan National Airport', airportOrder++),
  new Airport('272', 'ATL', airportCityDict['178232'], '亚特兰大哈茨菲尔德-杰克逊 (ATL)', 'Hartsfield Jackson Int. Airport', airportOrder++),
  new Airport('1890', 'HNL', airportCityDict['1488'], '檀香山国际 (HNL)', 'Honolulu Int. Airport', airportOrder++),
  new Airport('2963', 'MDW', airportCityDict['178248'], '芝加哥中途 (MDW)', 'Midway Int. Airport', airportOrder++),
  new Airport('3611', 'ORD', airportCityDict['178248'], '芝加哥奥黑尔国际 (ORD)', 'O\'Hare Int. Airport', airportOrder++),
  new Airport('3765', 'PHL', airportCityDict['602179'], '费城国际 (PHL)', 'Philadelphia Int. Airport', airportOrder++),
  new Airport('2982', 'MEX', airportCityDict['178285'], '墨西哥城华雷斯国际 (MEX)', 'Benito Juárez Int. Airport', airportOrder++)
];

CONTINENTS.sort((a, b) => {
  return a.order - b.order;
});

CITIES.sort((a, b) => {
  return a.order - b.order;
});

AIRPORTS.sort((a, b) => {
  return a.order - b.order;
});
