import { ParamMap } from '@angular/router';
import { NgxHttpParamsHelper } from '../http';
import { ObjectHelper } from '@dida-shopping/dida-services/common';

export interface IKeyPathItem {
  stroke: string;
  index?: number;
  isArrayItem: boolean  ;
}

export interface IQueryParamDeserializeOption {
  autoParseDate?: boolean;
  autoParseNumber?: boolean;
  autoParseBoolean?: boolean;
}



const DEFAULT_OPTION: IQueryParamDeserializeOption = {
  autoParseDate: false,
  autoParseNumber: false,
  autoParseBoolean: false,
};

const ARRAY_KEY_STROKE_MATCHER = /\[(\d*)\]$/i;
const MATCHER_DATETIME = /^(\d{4})-(\d{1,2})-(\d{1,2})([T\s]{1}(\d{1,2}(:\d{1,2}){2}){0,1}){0,1}$/i;
const MATCHER_BOOLEAN = /^true|false$/i;

export class QueryParamHelper {

  public static toQueryString(queryParams: any): string {
    return Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
  }

  public static serialize<T>(payload: T, payloadPreProcessor: (payload: T) => void = null): { [name: string]: any } {
    if (payloadPreProcessor != null) {
      payload = ObjectHelper.clone(payload);
      payloadPreProcessor(payload);
    }
    const param = NgxHttpParamsHelper.buildHttpParams(payload);
    const result: { [name: string]: any } = {};
    param.keys().forEach(k => {
      result[k] = param.get(k);
    });
    return result;
  }

  public static deserialize<T>(map: ParamMap, activator: new () => T, option?: IQueryParamDeserializeOption): T {
    const baseObj: T = new activator();
    QueryParamHelper.deserializeInto(map, baseObj, option);
    return baseObj;
  }

  public static deserializeInto<T>(map: ParamMap, baseObj: T, option?: IQueryParamDeserializeOption) {
    option = option || DEFAULT_OPTION;
    map.keys.forEach(key => {
      const pathItems = QueryParamHelper.pathBuilder(key);
      const value = map.get(key);
      QueryParamHelper.populateValue(pathItems, baseObj, value, option);
    });
  }

  private static populateValue<T>(pathRoutes: IKeyPathItem[], baseObj: any, value: any, option: IQueryParamDeserializeOption) {
    const path = pathRoutes[0];
    if (typeof baseObj[path.stroke] === 'undefined') {
      if (path.isArrayItem) {
        baseObj[path.stroke] = [];
      } else {
        baseObj[path.stroke] = {};
      }
    }
    if (pathRoutes.length > 1) {
      const nextRoutes = pathRoutes.slice(1);
      const nextObj = baseObj[path.stroke];
      QueryParamHelper.populateValue(nextRoutes, nextObj, value, option);
    } else {
      // AUTO PARSE
      let converted = false;
      if (option.autoParseNumber) {
        if (typeof value === 'string') {
          const temp = value.trim();
          if (temp !== '' && !isNaN(temp as any) && value.length < 14) {
            if (temp.indexOf('.') === -1) {
              value = parseInt(value, 10);
            } else {
              value = parseFloat(value);
            }
            converted = true;
          }
        }
      }
      if (!converted && option.autoParseDate) {
        if (MATCHER_DATETIME.test(value)) {
          const matchResult = MATCHER_DATETIME.exec(value);
          const y = parseInt(matchResult[1], 10);
          const m = parseInt(matchResult[2], 10) - 1;
          const d = parseInt(matchResult[3], 10);
          value = new Date(y, m, d);
          converted = true;
        }
      }
      if (!converted && option.autoParseBoolean) {
        if (MATCHER_BOOLEAN.test(value)) {
          value = /^true$/i.test(value);
          converted = true;
        }
      }

      if (path.isArrayItem) {
        baseObj[path.stroke].push(value);
      } else {
        baseObj[path.stroke] = value;
      }
    }
  }

  private static pathBuilder(key: string): IKeyPathItem[] {
    let sortedPath: IKeyPathItem[] = [];
    sortedPath = key.split('.').map((keyStroke) => {
      const pathItem: IKeyPathItem = {} as any;
      if (ARRAY_KEY_STROKE_MATCHER.test(keyStroke)) {
        const matchResult = ARRAY_KEY_STROKE_MATCHER.exec(keyStroke);
        pathItem.stroke = keyStroke.substring(0, matchResult.index);
        pathItem.index = null;
        if (matchResult[1] !== '') {
          pathItem.index = parseInt(matchResult[1], 10);
        }
        pathItem.isArrayItem = true;
      } else {
        pathItem.stroke = keyStroke;
      }
      return pathItem;
    });
    return sortedPath;
  }

}
