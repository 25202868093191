export enum ComponentBehaviorCategory {
  // Component Level
  HotelSearchPanel = 'HotelSearchPanel',
  SuggestionTypeahead = 'SuggestionTypeahead',
  HotelSearchFilter = 'HotelSearchFilter',
  HotelPackageSearchFilter = 'HotelPackageSearchFilter',
  BookingSearchFilter = 'BookingSearchFilter',
  TransferBookingSearchFilter = 'TransferBookingSearchFilter',
  DateRangePicker = 'DateRangePicker',
  DateTimePicker = 'DateTimePicker',
  RatePlanTable = 'RatePlanTable',
  CarouselAndSlider = 'CarouselAndSlider',
  NavBar = 'NavBar',
  SideBar = 'SideBar',
  Breadcrumb = 'Breadcrumb',
  SpecialRequest = 'SpecialRequest',
  CancellationPolicy = 'CancellationPolicy',
  PromotionRegion = 'PromotionRegion',
  PromotionHotel = 'PromotionHotel',
  UserPageViewHistoryGallery = 'UserPageViewHistoryGallery',
  RoomOccupancyPicker = 'RoomOccupancyPicker',
  NationalityPicker = 'NationalityPicker',
  MapWindow = 'MapWindow',
  MapModal = 'MapModal',
  FavorHotel = 'FavorHotel',
  Pagination = 'Pagination',
  Swiper = 'Swiper',
  // Page Component Level
  HotelList = 'HotelList',
  HotelDetail = 'HotelDetail',
  HotelFind = 'HotelFind'
}

export enum BookingSearchFilterActionType {
  ChangeShowMode = 'BookingList_ChangeShowMode'
}

export enum BehaviorActionType {
  Click = 'Click',
  Select = 'Select',
  Download = 'Download',
  Search = 'Search',
  Sort = 'Sort',
  Order = 'Order',
  SwitchOn = 'Switch_On',
  SwitchOff = 'Switch_Off',
}

export enum SuggestionTypeaheadActionType {
  ClearKeyword = 'Clear_Keyword',
  SelectRegion = 'Select_Region',
  SelectHotel = 'Select_Hotel',
  SelectAirport = 'Select_Airport',
  SelectPOI = 'Select_POI',
  ClickRegionOption = 'Click_RegionOption',
  SwitchRegionOption = 'Switch_RegionOption',
  SelectRegionTab = 'Select_RegionTab',
  SelectHotSearchRegion = 'Select_HotSearchRegion',
}

export enum HotelSearchFilterActionType {
  SelectPriceRange = 'Select_PriceRange',
  SelectCustomPriceRange = 'Select_CustomPriceRange',
  SelectStarRating = 'Select_StarRating',
  DeselectStartRating = 'De-Select_StarRating',
  SwitchHotelPositionType = 'Switch_HotelPositionType',
  ExpandAllHotelPosition = 'Expand_AllHotelPosition',
  RemoveFilterTag = 'Remove_FilterTag',
  SelectHotelPosition = 'Select_HotelPosition',
  DeselectHotelPosition = 'Deselect_HotelPosition',
}

export enum RoomOccupancyPickerActionType {
  ShowPicker = 'ShowPicker',
  ChangeAdult = 'Change_Adult',
  ChangeChild = 'Change_Child',
  ChangeRoomCount = 'Change_RoomCount',
}

export enum HotelListActionType {
  SelectHotel = 'Select_Hotel',
  ChangeFilter = 'Change_Filter',
}

export enum HotelDetailActionType {
  ToggleBreakfastFilter = 'Toggle_BreakfastFilter',
  ToggleCancellationFilter = 'Toggle_CancellationFilter',
  ClickRecommendHotel = 'Click_RecommendHotel',
  ToggleBedTypeFilter = 'Toggle_BedTypeFilter',
  ToggleRoomTypeFilter = 'Toggle_RoomTypeFilter',
  SelectPriceRange = 'Select_PriceRange',
  ClickClearFilter = 'Click_ClearFilter',
  ToggleImmediateConfirmFilter = 'Toggle_ImmediateConfirmFilter',
}

export enum CarouselAndSliderActionType {
  ShowCarousel = 'Show_Carousel',
  HideCarousel = 'Hide_Carousel',
  MoveNext = 'Move_Next',
  MovePrev = 'Move_Prev',
  SelectSlide = 'Select_Slide',
}

export enum RatePlanTableActionType {
  ClickReserve = 'Click_Reserve',
  ViewRoomInfo = 'View_RoomInfo',
  ViewCancellationPolicy = 'View_CancellationPolicy',
  ClickRequestCancellation = 'Click_RequestCancellation',
  ClickRatePlanPrice = 'Click_RatePlanPice',
  ToggleRatePlanCollapse = 'Toggle_RatePlanCollaspe',
}

export enum NavbarActionType {
  ClickSearch = 'Click_Search'
}

export enum UserPageViewHistoryGalleryActionType {
  ClickHistoryItem = 'Click_Item',
  MoveNext = 'Move_Next',
  MovePrev = 'Move_Prev',
  ToggleClearHistorySwitch = 'Toggle_ClearHistorySwitch',
  ClearHistory = 'ClearHistory',
  ToggleHistory = 'ToggleHistory',
  DismissClearHistory = 'DismissClearHistory',
  DeleteItem = 'DeleteItem',
}

export enum SwiperActionType {
  SwitchPrev = 'SwiperSwitchPrev',
  SwitchNext = 'SwiperSwitchNext',
  SwitchItem = 'SwiperSwitchItem',
  SelectItem = 'SwiperSelectItem'
}
