import { HttpServiceBase } from '@dida-shopping/dida-services/http';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
import { IPlaceSearchResponse, IPlaceSearchResultItem } from '@dida-shopping/dida-services/maps/models';

class GoogleMapService extends HttpServiceBase {

  constructor() {
    super();
  }

  searchRegionPlaces(regionId: string, pageToken: string = null) {
    return this.httpClientWrapper.post<{
      RegionCentralLocation: GeoCoordinate,
      Response: IPlaceSearchResponse
    }>('/GoogleMapProxyApi/GetRegionPlaces', {
      regionId, pageToken
    }, {
      resultProcessor: (resp: { Response: IPlaceSearchResponse }) => {
        if (resp.Response && resp.Response.results) {
          resp.Response.results.forEach(item => this.processGooglePlaceData(item));
        }
        return resp as any;
      }
    });
  }

  searchHotelPlaces(hotelId: number, location: GeoCoordinate, type: string, radius: number) {
    return this.httpClientWrapper.post<IPlaceSearchResponse>(
      '/GoogleMapProxyApi/SearchNearby',
      {
        hotelId, location, type, radius
      }, {
        payloadProcessor: (payload) => {
          payload.location = {
            Latitude: location.Latitude,
            Longitude: location.Longitude
          };
          return payload;
        },
        resultProcessor: (resp: IPlaceSearchResponse) => {
          if (resp && resp.results) {
            resp.results.forEach(item => this.processGooglePlaceData(item));
          }
          return resp;
        }
      }
    );
  }

  private processGooglePlaceData(item: IPlaceSearchResultItem) {
    item.id = item.id || item.place_id;
  }
}

export const googleMapService = new GoogleMapService();
