import { AbstractControl, FormControl, AsyncValidatorFn } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';


export class Validator {
  static isDate(str: string) {
    if (!str || str.trim() === '') {
      return false;
    }

    const match = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
    if (match === null) {
      return false;
    }

    const date = new Date(parseInt(match[1], 10), parseInt(match[3], 10) - 1, parseInt(match[4], 10));
    if (date.getFullYear().toString() === match[1]
      && (date.getMonth() + 1).toString() === match[3]
      && date.getDate().toString() === match[4]) {
      return true;
    } else {
      return false;
    }
  }

  static stringLengthAsyncValidator(delayNum: number, maxLength: number, minLength = 0): AsyncValidatorFn {
    if (!maxLength || maxLength < 0) {
      maxLength = 0;
    }

    if (!minLength || minLength < 0) {
      minLength = 0;
    }

    return (control: AbstractControl) => {
      const checkValid = (): boolean => {
        if (!control.value) {
          return true;
        } else {
          return control.value.length >= minLength && control.value.length <= maxLength;
        }
      };
      return of(checkValid() ? null : { 'stringLength': control.value.length }).pipe(
        delay(delayNum)
      );
    };
  }
}


export function phoneValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  const phoneReg = /^[\d\s+-]{7,20}$/;
  const result = phoneReg.test(val);
  return result ? null : { 'phoneValidator': { value: control.value } };
}

/**
 * 目前只給保險用
 *
 * @export
 * @param {FormControl} control
 * @returns {*}
 */
export function idCardNoValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  /**
   * Todo: 验证逻辑不应该与业务逻辑耦合，建议在先在业务逻辑里判断，需要时再开启此项验证，
   */
    // const opt = control.root.value as Insurance;
  const opt = control.root.value as { InsuredIdType: string };
  if (opt) {
    if (opt.InsuredIdType !== '01') {
      return true;
    }
  }
  const idCardNoReg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i;
  const isValid = false;
  const formatValid = idCardNoReg.test(val);
  if (formatValid) {
    if (val.length === 18) {
      const IdNo = val.split('');
      // ∑(ai×Wi)(mod 11)
      // 加权因子
      const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 校验位
      const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      let sum = 0;
      let ai = 0;
      let wi = 0;
      for (let i = 0; i < 17; i++) {
        ai = IdNo[i];
        wi = factor[i];
        sum += ai * wi;
      }
      const last = parity[sum % 11];
      if (parity[sum % 11] !== IdNo[17]) {
        // '身份证号错误'
        return false;
      }
    }
    return true;
  }
  return isValid ? null : { 'idCardNoValidator': { value: control.value } };
}

export function nameValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  if (!val) {
    return null;
  }
  const nameReg = /^[A-Za-z'\s.]{1,100}$/;
  const result = nameReg.test(val);
  return result ? null : { 'nameValidator': { value: control.value } };
}

export function nameWithChineseValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  if (!val) {
    return null;
  }
  const nameReg = /^[\u4e00-\u9fa5A-Za-z'\s.]{1,100}$/;
  const result = nameReg.test(val);
  return result ? null : { 'nameValidator': { value: control.value } };
}

export function ASCIIValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  if (!val) {
    return null;
  }
  const nameReg = /^[A-Za-z'\s.]{1,100}$/;
  const result = nameReg.test(val);
  return result ? null : { 'ASCII': { value: control.value } };
}

export function spaceValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  const result = !(/\s+/.test(val));
  return result ? null : { 'spaceValidator': { value: control.value } };
}

export function spaceInnerValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = (control.value || '').trim();
  const result = !(/\s+/.test(val));
  return result ? null : { 'spaceValidator': { value: control.value } };
}

export function eighteenBitValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  let result = false;
  if (val && val.length === 18) {
    result = true;
  }
  return result ? null : { 'eighteenBitValidator': { value: control.value } };
}

export function numbersAndLettersValidator(control: FormControl): any {
  // 获取到输入框的值
  const val = control.value;
  const result = (/^[0-9a-zA-Z]+$/.test(val));
  return result ? null : { 'numbersAndLettersValidator': { value: control.value } };
}


