import { Injectable } from '@angular/core';
import { LoggerService } from '../../logger/logger.service';
import { HttpClient } from '../../http/http-client';
import {
  UserPageViewActionModel,
  UserPageViewHistoryViewModel
} from '../../../shared/models/user/user-page-view-history.models';
import { Observable } from 'rxjs';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { PaginationWrapper } from '../../../shared/models/common/pagination-wrapper.model';
import { ShoppingSitePageTypeEnum } from '../../../shared/enums/shopping-site-page-type';
import { UrlHelper } from '@dida-shopping/dida-services/common';
import { RateSearchService } from '../rates/rate-search.service';
import {
  HotelPriceSearchOptions,
  LowestRateFilter
} from '../../../shared/models/rates/rates.models';
import { map } from 'rxjs/operators';

/**
 * 用户浏览历史服务
 *
 * @export
 * @class UserService
 */
@Injectable()
export class UserPageViewHistoryService {
  private pageViewActions: UserPageViewActionModel[];
  private interval?: number;

  /**
   * 浏览历史保存记录
   *
   * @private
   * @type {{[key: string]: string}} - key: model key, value: url
   * @memberof UserPageViewHistoryService
   */
  private pageViewUrlRecord: { [key: string]: string };

  constructor(
    private logger: LoggerService,
    private httpClient: HttpClient,
    private rateSearchService: RateSearchService
  ) {
    this.pageViewActions = [];
    this.interval = null;
    this.pageViewUrlRecord = {};
  }

  /**
   * 获取用户浏览历史
   *
   * @returns {Observable<UserPageViewHistoryViewModel[]>}
   * @memberof UserPageViewHistoryService
   */
  get(): Observable<PaginationWrapper<UserPageViewHistoryViewModel>> {
    return this.httpClient
      .get<PaginationWrapper<UserPageViewHistoryViewModel>>(
        '/UserPageViewHistoryApi/Get'
      )
      .pipe(
        map(page => {
          page.Data = ((page && page.Data) || []).map(li => {
            li.UpdateTime = DateTimeHelper.parse(li.UpdateTime);
            if (li.ImageUrl != null) {
              li.ImageUrl = UrlHelper.toAvailableBackgroundUrl(li.ImageUrl);
            } else if (
              li.ShoppingSitePageType ===
              ShoppingSitePageTypeEnum.HotelDetailPage
            ) {
              li.ImageUrl =
                'https://dsimage.didatravel.com/v2-app/images/image-fallback.png'; // fill with fallback image;
            }
            if (li.PriceInfo != null) {
              li.PriceInfo.CheckInDate = DateTimeHelper.parse(
                li.PriceInfo.CheckInDate
              );
              li.PriceInfo.CheckOutDate = DateTimeHelper.parse(
                li.PriceInfo.CheckOutDate
              );
            }
            return li;
          });
          return page;
        })
      );
  }

  /**
   * 更新用户浏览历史
   *
   * @param {UserPageViewActionModel} action 动作细节
   * @returns {Observable<{}>}
   * @memberof UserPageViewHistoryService
   */
  // save(action: UserPageViewActionModel): Observable<{}> {
  //   return this.httpClient.post('/UserPageViewHistoryApi/Save', action);
  // }
  /**
   * 更新用户浏览历史
   *
   * @param {UserPageViewActionModel} action
   * @memberof UserPageViewHistoryService
   */
  save(action: UserPageViewActionModel): void {
    let key = this.buildRecordKey(action);
    if (
      action.UpdateUrlOnly === false ||
      this.pageViewUrlRecord[key] !== action.URL
    ) {
      this.pageViewUrlRecord[key] = action.URL;
      this.pageViewActions.push(action);
      this.doSaveAction();
    }
  }

  private doSaveAction(): void {
    if (this.interval) {
      // wait.
      this.logger.debug(`[PVHS] - waiting for previous actions`);
    } else {
      let executing = false;
      this.logger.debug(`[PVHS] - interval starting`);
      this.interval = window.setInterval(() => {
        if (!executing) {
          let actionMdl = this.pageViewActions.shift();
          if (actionMdl != null) {
            executing = true;
            this.logger.debug(`[PVHS] - send request to server`, actionMdl);
            this.httpClient
              .post('/UserPageViewHistoryApi/Save', actionMdl)
              .subscribe({
                next: () => {
                  // done
                }
              })
              .add(() => {
                this.logger.debug(`[PVHS] - action saved`, actionMdl);
                executing = false;
              });
          } else {
            // stop interval
            window.clearInterval(this.interval);
            this.interval = null;
            this.logger.debug(`[PVHS] - interval stopped`);
          }
        }
      }, 500);
    }
  }

  private buildRecordKey(action: UserPageViewActionModel): string {
    return `${action.ShoppingSitePageTypeID}_${action.EntityID}`;
  }

  /**
   * 清空用户浏览历史
   *
   * @returns {Observable<{}>}
   * @memberof UserPageViewHistoryService
   */
  clear(): Observable<{}> {
    return this.httpClient.post('/UserPageViewHistoryApi/Clear', {});
  }

  /**
   * 删除某项浏览历史
   *
   * @returns {Observable<{}>}
   * @memberof UserPageViewHistoryService
   */
  remove(entityId: string): Observable<{}> {
    return this.httpClient.post('/UserPageViewHistoryApi/Remove', { entityId });
  }
}
