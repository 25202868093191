import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthorizationGuardService } from './authorization-guard.service';
import { DeactivationGuardService } from './deactivation-guard.service';
// import { NgxDidaApplicationModule } from '../application';

@NgModule({
  imports: [
    // NgxDidaApplicationModule
  ],
  providers: [
    AuthorizationGuardService,
    DeactivationGuardService
  ]
})
export class NgxDidaGuardsModule {

  constructor(@Optional() @SkipSelf() parentModule: NgxDidaGuardsModule) {
    if (parentModule) {
      throw new Error(
        `NgxDidaGuardsModule has already been loaded. Import NgxDidaGuardsModule in the AppModule only,`
      );
    }
  }

}
