import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';
import { TemplateRef } from '@angular/core';
/**
 * 表单控件
 *
 * 根据Angular Material的Input部件删减后的实现，具体见
 *
 * @link https://github.com/angular/material2
 *
 *
 * @export
 * @abstract
 * @class DidaFormGroupControl
 * @template T
 */
export abstract class DidaFormGroupControl<T> {
  /**
   * 表单控件值
   *
   * @type {(T | null)}
   * @memberof DidaFormGroupControl
   */
  value: T | null;

  /**
   * 当所属组件值变化时触发
   *
   * @type {Observable<void>}
   * @memberof DidaFormGroupControl
   */
  readonly stateChanges: Observable<void>;

  /**
   * 分组ID
   *
   * @type {string}
   * @memberof DidaFormGroupControl
   */
  readonly id: string;

  /**
   * placeholder
   *
   * @type {string}
   * @memberof DidaFormGroupControl
   */
  readonly placeholder: string | TemplateRef<void>;

  /**
   * 当前组件的`ngControl`
   *
   * @type {(NgControl | null)}
   * @memberof DidaFormGroupControl
   */
  readonly ngControl: NgControl | null;

  /**
   * 是否`focus`
   *
   * @type {boolean}
   * @memberof DidaFormGroupControl
   */
  readonly focused: boolean;

  /**
   * 值是否为空
   *
   * @type {boolean}
   * @memberof DidaFormGroupControl
   */
  readonly empty: boolean;

  /**
   * 是否为必须项目
   *
   * @type {boolean}
   * @memberof DidaFormGroupControl
   */
  readonly required: boolean;

  /**
   * 是否可用
   *
   * @type {boolean}
   * @memberof DidaFormGroupControl
   */
  readonly disabled: boolean;

  /**
   * 当前区域的表单验证状态
   *
   * @type {boolean}
   * @memberof DidaFormGroupControl
   */
  readonly errorState: boolean;

  /**
   * 错误级别
   *
   * @type {('warning' | 'danger')}
   * @memberof DidaFormGroupControl
   */
  readonly errorLevel: 'warning' | 'danger';

  /**
   * 表单输入控制类型
   *
   * @type {string}
   * @memberof DidaFormGroupControl
   */
  readonly controlType?: string;

  // /** Sets the list of element IDs that currently describe this control. */
  // abstract setDescribedByIds(ids: string[]): void;

  /**
   * placeholder的可见性
   *
   * @type {boolean}
   * @memberof DidaFormGroupControl
   */
  abstract get shouldShowPlaceholder(): boolean;

  /**
   * 控制组件被点击时触发的事件回调
   *
   * @abstract
   * @param {MouseEvent} event
   * @memberof DidaFormGroupControl
   */
  abstract onContainerClick(event: MouseEvent): void;
}
