import { Title, Meta } from '@angular/platform-browser';
import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding, OnChanges, isDevMode } from '@angular/core';

declare var _hmt: any;
@Component({
    selector: 'app-page-meta',
    template: '<span #companyName style="display: none" i18n="@@Text_DidaTravel_CompanyName">旅赞科技</span>'
})
export class PageMetaComponent implements OnInit, OnChanges {

    @Input()
    title: string;
    @Input()
    keywords: string;
    @Input()
    description: string;

    @ViewChild('companyName', { static: true })
    companyName: ElementRef<HTMLElement>;

    constructor(
        private titleService: Title,
        private metaService: Meta) { }

    ngOnChanges(event) {

        if (event.title && event.title.currentValue) {
            this.onTitleChange();
        }
    }
    ngOnInit() {
        if (this.title) {
            this.onTitleChange();
        }
        if (this.description) {

            this.metaService.updateTag({ name: 'description', content: this.description });
        }

        if (this.keywords) {

            this.metaService.updateTag({ name: 'keywords', content: this.keywords });
        }
    }

    private onTitleChange() {

      let companyName = this.companyName.nativeElement.textContent.trim();
      if (isDevMode()) {
          companyName += 'Dev';
      }
      const documentTitle = `${this.title} - ${companyName}`
      this.titleService.setTitle(documentTitle);
    }

}
