import { UploadError, UploadErrorTypeEnum } from '../uploader/uploader.models';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Component, OnInit, Input, HostBinding, Output, EventEmitter, Optional, Self } from '@angular/core';

@Component({
    selector: 'app-img',
    templateUrl: './img.component.html',
    styleUrls: ['./img.component.scss']
})
export class ImgComponent implements OnInit, ControlValueAccessor {
    // 原生img标准属性
    @Input()
    src: string;

    @Input()
    alt = '';

    @Input()
    title = '';

    @Input()
    width: number;

    @Input()
    bgSize: string;

    @Input()
    lang: string;

    // 使用原生img标签
    @Input()
    pure = false;

    // hover时动画效果， 只在组件内没内容时生效
    @Input()
    animated = false;

    @Input()
    path = '';

    @HostBinding('class.img-animated')
    get _animate() {
        return this.animated;
    }

    @HostBinding('class.img-fixed')
    @Input()
    fixed = false;

    @Output() statusChange: EventEmitter<UploadError> = new EventEmitter<UploadError>();

    get editable() {
        return this.control !== null;
    }

    get uploadPath() {
        if (this.path && this.path.startsWith('/')) {
            return this.path;
        }
        return ('Upload/' + this.path).replace('//', '/');
    }

    get imgSrc() {
        let src = encodeURI(this.src);
        if (!this.src) {
            src = '//dsimage.didatravel.com/v2-app/images/image-fallback.png';
        } else if (this.src.indexOf('//') < 0 && !this.src.startsWith('/assets/')) {
            src = `//dsimage.didatravel.com${src}`;
        }

        if (typeof this.width !== 'undefined') {
            src += '?x-oss-process=image/resize,w_' + Math.ceil(this.width * devicePixelRatio);
        }
        if (this.lang === 'en') {
            src = src.replace(/(\.[^.]+$)/, '_en$1');
        }
        return src;
    }

    propagateChange = (_: any) => { };
    propagateTouch = (_: any) => { };

    constructor(
        @Self()
        @Optional()
        public control: NgControl,
    ) {
        if (control && !control.valueAccessor) {
            this.control.valueAccessor = this;
        }
    }

    valueChange(event) {
        let value = event.target.value;
        this.src = value;
        this.propagateChange(value);
    }

    writeValue(value: string) {
        this.src = value || null;
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    clearImg() {
        this.src = null;
        this.propagateChange(this.src);
    }

    onUploaderChange(status: UploadError) {
        this.statusChange.emit(status);
        if (status.Code === UploadErrorTypeEnum.Success) {
            this.src = status.Message;
            this.propagateChange(this.src);
        }
    }


    ngOnInit() {
    }


}
