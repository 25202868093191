import { IServiceHttpClientWrapper } from './serivice-http-client-wrapper';

const SERVICE_COUNTER: {
  [serviceName: string]: number
} = {};

export abstract class HttpServiceBase {
  static DEFAULT_SERVICE_HTTP_CLIENT_WRAPPER: IServiceHttpClientWrapper = null;

  static get defaultHttpClient() {
    return HttpServiceBase.DEFAULT_SERVICE_HTTP_CLIENT_WRAPPER;
  }

  protected instance_id: number;
  protected get httpClientWrapper(): IServiceHttpClientWrapper {
    return HttpServiceBase.defaultHttpClient;
  }

  protected constructor(serviceName?: string) {
    if(!serviceName) {
      serviceName = this.constructor.name
    }
    if (!SERVICE_COUNTER[serviceName]) {
      SERVICE_COUNTER[serviceName] = 0;
    }
    this.instance_id = SERVICE_COUNTER[serviceName]++;
    console.log(`[${serviceName}] Created: ${this.instance_id}`);
  }
}
