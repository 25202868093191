import { HttpServiceBase } from '../http';
import { IClientSideLog } from './log.models';


class AliyunLoggerService extends HttpServiceBase {
  constructor() {
    super('AliyunLoggerService');
  }

  /**
   * 将日志发送到服务器并写到阿里的日志里面去
   * 请不要滥用此方法
   * @param log
   */
  sendLog(log: IClientSideLog) {
    try {
      this.httpClientWrapper.post('/LoggerApi/Add', log).touch();
    } catch {
      // TODO
    }
  }
}

export const aliyunLoggerService = new AliyunLoggerService();
