import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {
  CampaignEventHub,
  UserCouponService,
  UserActiveCouponUpdateEvent,
  ApplicationService
} from '@dida-shopping/ngx-dida-site-core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DidaUITKNavBarCampaignModalComponent } from './campaign-modal/campaign-modal.component';

import { NzModalComponent, NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, of, timer } from 'rxjs';
import { filter, flatMap, take, delay, withLatestFrom } from 'rxjs/operators';
import {
  MessageCenterService,
  MessageCenterEventHub,
  MessageCenterUnreadMessageUpdateEvent,
  ngxHttpCall
} from '@dida-shopping/ngx-dida-site-core';
import { IMessageModel, IMessageSearchOptionModel, userMessageService } from '@dida-shopping/dida-services/message';

@Component({
  selector: 'nd-navbar-notice-modal-wrapper',
  templateUrl: './notice-modal-wrapper.component.html',
  styleUrls: ['./notice-modal-wrapper.component.scss']
})
export class DidaUITKNavBarNoticeModalWrapperComponent implements OnInit {

  // 弹窗消息
  @ViewChild('popupMessageModal')
  popupMessageModal: NzModalComponent;
  popupMessageList: IMessageModel[] = [];
  popupMsgIndex = -1;


  // 缓存已读消息id, 用于防止MessageCenter数据未及时同步
  private _readedList: number[] = [];
  private _currencUrl: string;

  showIENotice = navigator.userAgent.indexOf('Trident') > -1;

  private cacheUserId: string;
  showAccountChangeNotice = false;

  constructor(
    private campaignEventHub: CampaignEventHub,
    private router: Router,
    private userCouponService: UserCouponService,
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private messageCenterService: MessageCenterService,
    private messageCenterEventHub: MessageCenterEventHub,
    private appService: ApplicationService
  ) {
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      if (typeof this.cacheUserId !== 'undefined') {
        this.appService.reloadContext().subscribe((context) => {
          if (this.cacheUserId !== context.userID) {
            this.showAccountChangeNotice = true;
          }
        });
      }
    } else {
      this.cacheUserId = this.appService.context && this.appService.context.userID;
    }
  }

  reloadPage() {
    location.reload();
  }

  ngOnInit() {

    // 只触发一次
    // 先检查是否有需要弹出的系统通知
    // 如果有用户查看完再检查是否需要提示优惠券信息
    combineLatest([
      // this.router.events.pipe(
      //   filter(item => item instanceof NavigationEnd),
      //   tap(() => console.log('...........NavigationEnd'))
      // ),
      this.messageCenterEventHub.events.pipe(
        filter(item => item instanceof MessageCenterUnreadMessageUpdateEvent)
        // tap(() => console.log('...........MessageCenterUnreadMessageUpdateEvent'))
      ),
      this.campaignEventHub.events.pipe(
        filter(item => item instanceof UserActiveCouponUpdateEvent)
        // tap(() => console.log('...........UserActiveCouponUpdateEvent'))
      )
    ]
    ).pipe(
      take(1),
      flatMap(() => this.checkSystemNotice()),
      flatMap(hasPopopMessage => {
        if (hasPopopMessage) {
          return this.popupMessageModal.afterClose;
        }
        return of(true);
      }),
      flatMap(() => {
        return this.checkCampaignModal();
      })
    ).subscribe(() => {
      // 页面跳转是检查是否有需要弹出的系统通知
      this.router.events.pipe(
        filter(item => item instanceof NavigationEnd),
        flatMap(() => this.checkSystemNotice())
      ).subscribe();
    });
  }

  checkSystemNotice() {
    // 忽略参数变化
    if (location.pathname === this._currencUrl) {
      return of(false);
    } else {
      this._currencUrl = location.pathname;
    }

    const popupList = this.getPopupMessage();

    const messageIdList = popupList.map(item => item.MessageID);
    if (messageIdList.length === 0) {
      return of(false);
    }
    const option: IMessageSearchOptionModel = {
      Category: null,
      WithMessageContent: true,
      MessageIDList: messageIdList,
      PageSize: 100,
      PageNum: 1
    };
    return ngxHttpCall(userMessageService.get(option)).pipe(
      flatMap((resp) => {
        if (!resp.Data || !resp.Data.length) {
          return of(false);
        }
        this.popupMessageModal.open();
        // 内部用了nzCarousel, 这是需要延时赋值
        const openSub = this.popupMessageModal.afterOpen.pipe(
          delay(100)
        ).subscribe(() => {
          this.popupMsgIndex = 0;
          this.popupMessageList = resp.Data;
          openSub.unsubscribe();
        });
        return of(true);
      })
    );
  }

  checkCampaignModal() {
    if (!this.route.snapshot.queryParamMap.get('from-login')) {
      return of(false);
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        'from-login': null
      },
      replaceUrl: true,
      queryParamsHandling: 'merge'
    });

    const activeCoupons = this.userCouponService.getActiveCoupons();
    if (activeCoupons.length > 0) {

      this.modalService.create({
        nzContent: DidaUITKNavBarCampaignModalComponent,
        nzWidth: 700,
        // nzVisible: true,
        nzFooter: null,
        nzClassName: 'nd-navbar-campaign-modal',
        nzComponentParams: {
          list: activeCoupons
        }
      });
      return of(true);
    }

    return of(false);
  }


  msgSlideChange(index) {
    if (this.popupMsgIndex !== -1) {
      this.markCurMsgAsRead();
      // if(!isDevMode()) {
      // }
    }
    this.popupMsgIndex = index;
  }

  closePopupMessage() {
    this.popupMessageModal.close();
    this.markCurMsgAsRead();
  }

  private getPopupMessage() {

    const messageList = this.messageCenterService.getUnreadMessages();
    const popupList = messageList.filter(item => {
      if (item.IsRead) {
        return false;
      }
      if (!item.ExtraSetting || !item.ExtraSetting.ShouldPopup) {
        return false;
      }
      if (
        item.ExtraSetting.StartTime &&
        new Date() < new Date(item.ExtraSetting.StartTime)
      ) {
        return false;
      }
      if (
        item.ExtraSetting.ExpireTime &&
        new Date() > new Date(item.ExtraSetting.ExpireTime)
      ) {
        return false;
      }
      if (item.ExtraSetting.SpecifyPage) {
        if (!new RegExp(item.ExtraSetting.SpecifyPage, 'i').test(location.pathname)) {
          return false;
        }

        item.ExtraSetting.Order = 0;
      }

      if (this._readedList.includes(item.MessageID)) {
        return false;
      }
      return true;
    });

    return popupList.sort((a, b) => {
      return a.ExtraSetting.Order > b.ExtraSetting.Order ? 1 : -1;
    });
  }


  markCurMsgAsRead() {
    const msgIndex = this.popupMsgIndex;
    const msg = this.popupMessageList[msgIndex];
    msg.IsRead = true;
    this._readedList.push(msg.MessageID);
    this.messageCenterService.readMessagesById([msg.MessageID]);
  }
}
