import { IGoogleMapSetting } from '@dida-shopping/dida-services/maps';

export interface IServerConfigType {
  proxyEnabled: boolean;
  localDebug: boolean;
  ignoreBuildNumber: boolean;
  serviceEndPoint: string;
  oldSiteUrl: string;
  localeCookieName: string;
  googleMapSetting: IGoogleMapSetting;
  webPublicPath: string;
  enableLoginModal: boolean;
}

export const SERVER_CONFIG: IServerConfigType = {
  proxyEnabled: false,
  localDebug: false,
  ignoreBuildNumber: false,
  serviceEndPoint: null,
  oldSiteUrl: null,
  localeCookieName: null,
  googleMapSetting: {
    ApiHost: null,
    JavaScriptMap: {
      ApiKey: null,
      Libraries: ['geometry'],
    }
  },
  webPublicPath: null,
  enableLoginModal: false,
};
