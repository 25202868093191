import { Component, OnInit, Input } from '@angular/core';
/**
 * 标签组件
 * 
 * @example
 * <dida-label class="hotel-detail__heading-label" [type]="'success'" *ngIf="isPromotedHotel">
      推荐
    </dida-label>
 * 
 * @author Roman Lo
 * 
 * @export
 * @class LabelComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dida-label',
  template: `
  <div class="dida-label" [ngClass]="labelTypeClass" [class.dida-label_round]="round">
    <span class="dida-label__angle" *ngIf="!round"></span>
    <div class="dida-label__label" [class.dida-label__label_bold]="bold">
      <ng-content></ng-content>
    </div>
  </div>
  `,
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  /**
   * 标签类型 （默认为`default`）
   * 
   * 可选项目：
   * 
   * 1. `default` - 强力灰
   * 2. `primary` - 高亮粉
   * 3. `info` - 信息蓝
   * 4. `success` - 成功绿
   * 5. `warning` - 警告黄
   * 6. `danger` - 危险红
   * 7. `cancel` - 迷蒙灰
   * 
   * @type {string}
   * @memberof LabelComponent
   */
  @Input() type?: 'default' | 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'cancel';
  /**
   * 加粗
   * 
   * @type {boolean}
   * @memberof LabelComponent
   */
  @Input() bold?= false;

  /**
   * 圆润模式
   *
   * @memberof LabelComponent
   */
  @Input() round?= false;

  constructor() { }

  ngOnInit() {
  }

  get labelTypeClass(): string {
    if (this.type == null) {
      return 'dida-label_default';
    }
    return `dida-label_${this.type}`;
  }

}
