import { ExperimentID } from '@dida-shopping/dida-services/experiment';
import { HttpServiceBase } from '../http';
import { IDidaApplicationUserContextModel, IDidaToolkitConfig } from './models';
import { SigningHelper } from './signing.helper';
import { LanguageType } from '../i18n';


class ApplicationContextService extends HttpServiceBase {

  constructor() {
    super('ApplicationContextService');
  }

  /**
   * 获取应用上下文
   *
   * @returns
   * @memberof ApplicationContextService
   */
  getContext(
    appName: string,
    contextHandler: (ctx: IDidaApplicationUserContextModel) => void
  ) {
    return this.httpClientWrapper
      .get<IDidaApplicationUserContextModel>('/ApplicationContextApi/GetContext', { appName }, {
        resultProcessor: (ctx: IDidaApplicationUserContextModel) => {
          ctx.__responseTime__ = new Date();
          if(ctx.clientExperiments) {
            const expSet = new Set(ctx.clientExperiments)
            expSet.add(ExperimentID.CouponFeature_Forbidden).add(ExperimentID.ForbidUserPoints).add(ExperimentID.DataStatisticsFeature_Forbidden)
            ctx.clientExperiments = Array.from(expSet)
          }
          if (ctx.userIdentityInfo && ctx.userIdentityInfo.profile) {
            // const raw: number = ctx.userIdentityInfo.profile.Language as any;
            // let lang = LanguageType.zh_CN;
            // switch (raw) {
            //   case 1:
            //     lang = LanguageType.en_US;
            //     break;
            //   case 2:
            //     lang = LanguageType.zh_CN;
            //     break;
            // }
            ctx.userIdentityInfo.profile.Language = ctx.lang; // 用户语言设置标准化
            // ctx.userIdentityInfo.profile.EmailConfirmed = false
          }
          if (contextHandler) {
            contextHandler(ctx);
          }
          return ctx;
        }
      });
  }

  getDidaToolKitConfig(userName: string) {
    let sign = '';
    return this.httpClientWrapper
      .get<IDidaToolkitConfig>('/ApplicationContextApi/GetDidaToolKitConfig', {
        username: userName
      }, {
        payloadProcessor: (payload) => {
          const queryParam = {
            username: payload.username,
            pin: new Date().getTime()
          };
          payload['sign'] = sign = SigningHelper.generateSignature(queryParam);
          payload['pin'] = queryParam.pin;
          return payload;
        },
        resultProcessor: (r) => {
          if (r.Stamp != null) {
            const signVerified = SigningHelper.verifySignature(sign, userName, r.Stamp);
            r.IsAllow = signVerified && r.IsAllow;
            if (r.IsAllow) {
              r.sign = sign;
            }
          } else {
            r.IsAllow = false;
          }
          return r;
        }
      });
  }

  switchLanguage(languageCode: LanguageType) {
    return this.httpClientWrapper.post(
      '/ApplicationContextApi/SwitchLanguage',
      { lang: languageCode }
    );
  }

}

export const applicationContextService = new ApplicationContextService();
