import {
  LoginProcessStatusType
} from '../../../models/enums/login-process-status-type';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  HostBinding
} from '@angular/core';
import {
  BsDropdownConfig,
  BsDropdownDirective,
} from 'ngx-bootstrap/dropdown';
import {
  PopoverDirective
} from 'ngx-bootstrap/popover';
import {
  LoggerService
} from '../../../../core/logger/logger.service';
import {
  ApplicationContextUpdateEvent,
  ApplicationEventHub, ApplicationRouteDataChangeEvent,
  ApplicationService,
  BehaviorManagementService, CampaignEventHub, CampaignLoginShowCouponModalEvent, ComponentBaseWithContext, MessageCenterService, MessageCenterEventHub, MessageCenterUnreadMessageUpdateEvent, UserActiveCouponUpdateEvent, UserCouponService
} from '@dida-shopping/ngx-dida-site-core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  MessageService
} from '../../../../core/services/message/message.service';
import {
  ResourceService
} from '../../../../core/services/resource/resource.service';
import {
  AuthenticationService
} from '../../../../core/services/auth/authentication.service';
import {
  UserLoginCredential
} from '../../../models/auth/user-login-credential';
import {
  // AppMessage,
  ImageLinkModel
} from '../../../models/shared-models';
import {
  // WorkOrderControl,
  // UserPointsControl,
  // ExperimentID,
  // BookingSettlementControl,
  // InvoiceControl,
  // DidaTransferFeatureControl,
  // CouponControl,
  // DataStatisticsControl,
  // CommonDisableControl
  ExperimentControls, ExperimentID
} from '@dida-shopping/dida-services/experiment';
import {
  ExternalRoutingHelper
} from '../../../../core/router/external-routing-helper';
import {
  DidaHotelSearchPayload
} from '../../../../hotels/shared/hotel-search/hotel-search.model';
import {
  DidaSuggestionTypeaheadConfig,
  SuggestionValue,
  SuggestionItemCategory
} from '@dida-shopping/dida-services/suggestions';
import {
  Converter
} from '../../../utils/converter';
import {
  ComponentBehaviorCategory,
  NavbarActionType
} from '../../../../core/logger/behavior-management.models';
import {
  CouponService
} from '../../../../core/services/campaigns/coupon/coupon.service';
import {
  HotelCouponHelper
} from '../../../utils/hotel-coupon-helper';
import { ClientStatusTypeEnum } from '../../../models/enums/client-status-type';
import { UserService } from '../../../../core/services/user/user.service';
import { filter } from 'rxjs/operators';
import { LanguageType } from '@dida-shopping/dida-services/i18n';

const hotCurrencyList = ['CNY', 'USD', 'EUR'];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } }]
})
export class NavbarComponent extends ComponentBaseWithContext implements OnInit, AfterViewChecked {
  // 登陆表单
  loginInfo: UserLoginCredential = new UserLoginCredential();
  loginError = null;
  loginStatus = LoginProcessStatusType.INIT;
  LoginStatus = LoginProcessStatusType;
  isLoginSuccess = false;
  // route data
  isHideNav = false;
  isHomePage = false;
  isShowSearchForm = false;

  @HostBinding('class.with-search')
  get withSearchForm() {
    return this.isShowSearchForm;
  }

  @ViewChild('loginoutPopover', { static: false })
  loginoutPopover: PopoverDirective;
  @ViewChild('currencyPopover', { static: false })
  currencyPopover: PopoverDirective;
  @ViewChild('userProfilePopover', { static: false })
  userProfilePopover: PopoverDirective;
  @ViewChild('orderDropdown', { static: false })
  orderDropdown: BsDropdownDirective;
  @ViewChild('orderDropdownMenu', { static: false })
  orderDropdownMenu: ElementRef;

  // @ViewChild('hotelDetailLink')
  // hotelDetailLink: ElementRef;

  private _initialized: boolean;

  workOrderControl = ExperimentControls.WorkOrderControl;
  userPointsControl = ExperimentControls.UserPointsControl;
  activityControl = ExperimentID.DidaActivity_NewBeginning2018;
  settlementControl = ExperimentControls.BookingSettlementControl;
  invoiceControl = ExperimentControls.InvoiceControl;
  // 临时屏蔽接送机预定业务
  transferOrderControl = ExperimentControls.CommonDisableControl;
  transferFeatureControl = ExperimentControls.DidaTransferFeatureControl;
  dataStatisticsControl = ExperimentControls.DataStatisticsControl;

  showDataStatistics = false;
  activeEntry: ImageLinkModel;

  searchParams = {};
  searchPayload = new DidaHotelSearchPayload();
  suggestionTypeaheadConfig = new DidaSuggestionTypeaheadConfig();

  isShowCurrencySetting = false;

  get initialized() {
    return this._initialized && this.appContext;
  }

  get hotCurrencyList() {
    return hotCurrencyList;
  }

  get showCouponTag() {
    return this.isAllowControl(ExperimentControls.CouponControl)
      && HotelCouponHelper.allowUseCoupon(this.ctxStorage, this.lang);
  }

  private navStateDict: { [navName: string]: boolean } = {};

  unReadMessageCount = 0;
  activeCouponCount = 0;

  constructor(
    private logger: LoggerService,
    private bmService: BehaviorManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private activeRoute: ActivatedRoute,
    private appService: ApplicationService,
    private campaignEventHub: CampaignEventHub,
    private appEventHub: ApplicationEventHub,
    // private appContext: ApplicationContextService,
    private messageService: MessageCenterService,
    private messageCenterEventHub: MessageCenterEventHub,
    private resourceService: ResourceService,
    // private experimentService: ExperimentService,
    private authentication: AuthenticationService,
    private couponService: UserCouponService,
    private userService: UserService
  ) {
    super();
  }

  changeCurrency(currency) {
    this.appService
      .updateCurrencySetting(currency)
      .subscribe((currencySet: any) => {
        this.currencyPopover.hide();
      });
  }

  enterNavDropdown(name: string, $ref: BsDropdownDirective) {
    this.navStateDict[name] = true;
  }

  leaveNavDropdown(name: string, $ref: BsDropdownDirective) {
    this.navStateDict[name] = false;
    $ref.hide();
  }

  closeNavDropdown(name: string, $ref: BsDropdownDirective, timeout: number = 50) {
    this.navStateDict[name] = false;
    if (timeout) {
      setTimeout(() => {
        if (!this.navStateDict[name]) {
          $ref.hide();
        }
      }, timeout);
    } else {
      $ref.hide();
    }
  }

  login() {
    this.loginStatus = LoginProcessStatusType.LOGINING;
    // return console.log(this.loginInfo)
    this.loginError = null;
    this.authentication.login(this.loginInfo).subscribe(
      resp => {
        this.loginStatus = LoginProcessStatusType.LOGINED;

        setTimeout(
          () => (this.loginStatus = LoginProcessStatusType.INIT),
          3000
        );

        // this.campaignEventHub.broadcast(new CampaignLoginShowCouponModalEvent(
        //   `[NavbarComponent::login]`, new Date()
        // ));
        // this.actionService.emit(
        //     new AppMessage(GlobalMessageTypeEnum.LoginShowCouponModal, null)
        // );

        this.closeLoginPopover();

        if (resp.status === 6) { // RequiresEmailConfirm
          return this.router.navigateByUrl('/account/register');
        }

        if (this.appService.context.userIdentityInfo && !this.appService.context.userIdentityInfo.clientInfo) { // No Client Info
          return this.router.navigateByUrl('/account/register');
        }

        let returnUrl: string =
          this.route.snapshot.queryParams['returnUrl'] || '';
        const host = location.protocol + '//' + location.host;
        if (returnUrl && !returnUrl.startsWith(host)) {
          // external url
          location.href = returnUrl;
        } else {
          returnUrl = returnUrl.slice(host.length);
          this.router.navigate([returnUrl || '/hotel'], {
            queryParams: {
              'from-login': 1
            },
            replaceUrl: true,
          });
        }
      },
      err => {
        this.loginError = err;
        this.loginStatus = LoginProcessStatusType.INIT;
        if (
          err.errorCode === this.ErrorCode.AuthenticationError_EmailNotConfirmed
        ) {
          this.closeLoginPopover();
          return this.router.navigateByUrl('/account/register');
        }
      }
    );
  }

  logout() {
    this.authentication.logout().subscribe(
      resp => {
        if (this.userProfilePopover) {
          this.userProfilePopover.hide();
        }
        this.userIdentity.isAuthenticated = false;
        // location.href = '/app/home';
        location.reload();
      },
      err => console.log(err)
    );
  }

  getExternalUrl(location: string): string {
    return ExternalRoutingHelper.constructActualExternalUrl(location);
  }

  isShowStatistics() {
    return this.isCN;
  }

  switchLanguage(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let lang = this.isCN ? LanguageType.en_US : LanguageType.zh_CN;
    this.appService.switchLanguage(lang);
    // this.appContext.switchLanguage(lang);
  }


  swithUserLanguage() {
    this.userProfilePopover.hide();
    this.userService.updateLanguageSetting(this.isCN ? LanguageType.en_US : LanguageType.zh_CN);
  }

  closeLoginPopover() {
    if (this.loginoutPopover) {
      this.loginoutPopover.hide();
    }
  }

  destinationChange(destination: SuggestionValue) {
    if (destination && destination.id) {
      const id = parseInt(destination.id, 10);
      if (destination.category === SuggestionItemCategory.Hotel) {
        this.searchPayload.keyword = null;
        this.searchPayload.hotelID = id;
        this.searchPayload.regionID = destination.destinationID;
        const params = Converter.toQueryParam(this.searchPayload);
        this.router.navigate(['/hotel/detail', this.searchPayload.hotelID], {
          queryParams: params
        });
        // setTimeout(() => {

        //   console.log('ksjfk', this.hotelDetailLink.nativeElement.href);
        //   window.open(this.hotelDetailLink.nativeElement.href);
        // }, 0);
      } else {
        if (destination.keyword) {
          this.searchPayload.keyword = destination.keyword;
        } else {
          this.searchPayload.keyword = null;
        }
        this.searchPayload.regionID = destination.id;
        this.searchPayload.hotelID = null;
      }
      // this.suggestionChange.emit(destination);
      // this.searchOptionChange.emit({ type: 'destination', payload: this.searchPayload });
      this.searchParams = Converter.toQueryParam(this.searchPayload);
    }
  }

  dateRangeChange(stay: {
    fromDate: Date; toDate: Date
  }) {
    this.searchPayload.checkInDate = stay.fromDate;
    this.searchPayload.checkOutDate = stay.toDate;
    // this.searchOptionChange.emit({ type: 'dataRange', payload: this.searchPayload });
    this.searchParams = Converter.toQueryParam(this.searchPayload);
  }

  navFormSearch() {
    this.bmService.trackEvent({
      category: ComponentBehaviorCategory.NavBar,
      action: NavbarActionType.ClickSearch,
      label: 'Search',
      value: 1
    });
  }

  cleanKeywordInputText(isUser: boolean) {
    if (isUser) {
      this.loginInfo.LoginIdentifier = null;
    } else {
      this.loginInfo.Password = null;
    }
  }

  private _initialize() {
    if (this.userIdentity.profile) {
      this.showDataStatistics = this.isAllowControl(
        this.dataStatisticsControl
        ) && this.isCN
        && (
          this.clientInfo !== null &&
          this.clientInfo.Status === ClientStatusTypeEnum.Active
        );

      this.messageCenterEventHub.events.pipe(
        filter(event => event instanceof MessageCenterUnreadMessageUpdateEvent)
      ).subscribe(() => {
        this.unReadMessageCount = this.messageService.getUnreadMessages().length;
      })

      this.campaignEventHub.events.pipe(
        filter(event => event instanceof UserActiveCouponUpdateEvent )
      ).subscribe(() => {
        this.activeCouponCount = this.couponService.getActiveCoupons().length
      })
    }

    if(this.showCouponTag) {
      this.couponService.start()
    } else {
      this.couponService.stop()
    }

    this.appContext.currencyList.sort();

    this._initialized = true;
  }

  ngOnInit() {
    this.suggestionTypeaheadConfig.miniMode = true;

    this.subscriptions['appEventHub'] = (this.appEventHub.events.pipe(
      filter(event =>
        event instanceof ApplicationContextUpdateEvent ||
        event instanceof ApplicationRouteDataChangeEvent
      )
    )).subscribe({
      next: (event) => {
        if (event instanceof ApplicationContextUpdateEvent) {
          this._initialize();
        } else if (event instanceof ApplicationRouteDataChangeEvent) {
          const data = event.routeData;
          this.isHideNav = data.hideNav || false;
          this.isHomePage = data.homePage || false;
          this.isShowSearchForm = data.showNavSearch || false;
          this.isShowCurrencySetting = data.showCurrencySetting || false;
        }
      }
    });
    // this.appContext.subscribe(({
    //                              type: msgType,
    //                              data
    //                            }: AppMessage) => {
    //   switch (msgType) {
    //     case GlobalMessageTypeEnum.ContextUpdate:
    //       // ApplicationContextService.Context!=null
    //       this._initialize();
    //       break;
    //     case GlobalMessageTypeEnum.RouteDataChange:
    //       this.isHideNav = data.hideNav || false;
    //       this.isHomePage = data.homePage || false;
    //       this.isShowSearchForm = data.showNavSearch || false;
    //       this.isShowCurrencySetting = data.showCurrencySetting || false;
    //   }
    // });
  }

  ngAfterViewChecked() {
  }
}
