import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ComponentBaseWithContext } from '@dida-shopping/ngx-dida-site-core';
import { ExperimentControls } from '@dida-shopping/dida-services/experiment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ComponentBaseWithContext implements OnInit, OnDestroy {

  constructor(
    private router: Router
  ) {
    super();
  }

  isUnderTransfer: boolean;

  currentFullYear = new Date().getFullYear();

  ngOnInit() {
    this.detectIsUnderTransfer();
    this.subscriptions['router.events'] = (this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>).subscribe(evt => {
      this.detectIsUnderTransfer();
    });
  }

  // ngOnDestroy() {
  //   if (this.routeNavigationSubscription) {
  //     this.routeNavigationSubscription.unsubscribe();
  //   }
  // }

  private detectIsUnderTransfer() {
    this.isUnderTransfer = this.router.isActive('/transfer', false);
  }


}
