import { NgModule } from '@angular/core';
import { Route, RouterModule, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

import {
  AppModulePreloader,
  AppRoutes,
  AuthorizationGuardService,
  RestrictedDevOnlyFeature,
  UserNeedFullInfoCondition,
} from '@dida-shopping/ngx-dida-site-core';
import { ErrorComponent } from './shared/pages/error/error.component';
import { ExternalRedirectComponent } from './shared/pages/external-redirect/external-redirect.component';
import { ExperimentControls } from '@dida-shopping/dida-services/experiment';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { WeappProxyComponent } from './shared/pages/weapp-proxy/weapp-proxy.component';

// export function newPolicyPagesRouteMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
//   if (url.length > 1 && url[0].path === 'policy' && /^(PrivacyPolicy|CompanyPolicies|TermsConditions)$/.test(url[1].path)) {
//     route.redirectTo = `/home/doc/${url[1].path}`;
//     return {consumed: url};
//   }
//   return null;
// }

export const DIDA_SHOPPING_ROUTES: AppRoutes = [
  {
    path: 'hotel',
    loadChildren: () => import('./hotels/hotels.module').then((m) => m.HotelsModule), //'app/hotels/hotels.module#HotelsModule',
    data: {
      condition: UserNeedFullInfoCondition,
      showCurrencySetting: true,
      preload: true,
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule), //'app/home/home.module#HomeModule',
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule), //'app/account/account.module#AccountModule',
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'booking',
    loadChildren: () => import('./bookings/bookings.module').then((m) => m.BookingsModule), //'app/bookings/bookings.module#BookingsModule',
    data: {
      condition: UserNeedFullInfoCondition,
      theme: 'island',
      showNavSearch: true,
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule), //'app/user/user.module#UserModule',
    data: {
      condition: UserNeedFullInfoCondition,
      theme: 'island',
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'bill',
    loadChildren: () => import('./bills/bills.module').then((m) => m.BillsModule), //'app/bills/bills.module#BillsModule',
    data: {
      condition: UserNeedFullInfoCondition,
      theme: 'island',
      showNavSearch: true,
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then((m) => m.OrderModule), //'app/order/order.module#OrderModule',
    data: {
      condition: UserNeedFullInfoCondition,
      preload: true,
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'message',
    // redirectTo: '/account/message-center',
    loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule), //'app/messages/messages.module#MessagesModule',
    data: {
      condition: UserNeedFullInfoCondition,
      theme: 'island',
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'invoice',
    loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoiceModule), //'app/invoice/invoice.module#InvoiceModule',
    data: {
      condition: UserNeedFullInfoCondition,
      langRestriction: [LanguageType.zh_CN],
      theme: 'island',
      showNavSearch: true,
    },
    canActivate: [AuthorizationGuardService],
  },
  {
    path: 'ticket',
    loadChildren: () => import('./tickets/tickets.module').then((m) => m.TicketsModule), //'app/tickets/tickets.module#TicketsModule',
    data: {
      condition: UserNeedFullInfoCondition,
      theme: 'island',
      langRestriction: [LanguageType.zh_CN],
    },
    canActivate: [AuthorizationGuardService],
  },
  // {
  //   path: 'open',
  //   loadChildren: () => import('./open/open.module').then(m => m.OpenModule), //'app/open/open.module#OpenModule',
  //   canActivate: [AuthorizationGuardService]
  // },
  // {
  //   path: 'campaign',
  //   loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule), //'app/campaigns/campaigns.module#CampaignsModule',
  //   data: {
  //     condition: RestrictedDevOnlyFeature,
  //     theme: 'island'
  //   },
  //   canActivate: [AuthorizationGuardService]
  // },
  {
    path: 'app',
    redirectTo: '/hotel',
    data: {
      condition: UserNeedFullInfoCondition,
    },
    pathMatch: 'full',
  },
  {
    path: 'weapp',
    children: [
      {
        path: '**',
        component: WeappProxyComponent,
      },
    ],
  },
  // {
  //   // match new routes for policy pages
  //   matcher: newPolicyPagesRouteMatcher,
  //   redirectTo: '/error/404'
  // },
  { path: '', redirectTo: '/hotel/find', pathMatch: 'full' },
  { path: '404', redirectTo: '/error/404', pathMatch: 'full' },
  { path: 'error/:code', component: ErrorComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'external/:type', component: ExternalRedirectComponent },
  { path: '**', component: ExternalRedirectComponent },
];

/**
 * Main module routing
 *
 * Link to about module with lazy-loading, and instead to hotels component
 */
@NgModule({
  imports: [
    RouterModule.forRoot(DIDA_SHOPPING_ROUTES, {
      enableTracing: false,
      preloadingStrategy: AppModulePreloader,
      // 这两货到7.2.8为止还有坑...
      // scrollPositionRestoration: 'enabled',
      // anchorScrolling: 'enabled'
    }),
  ],
  exports: [RouterModule],
  providers: [AuthorizationGuardService, AppModulePreloader],
})
export class AppRoutingModule {}
