import { CDate } from './CDate.Model';
import { CDateHelper } from '../common/CDateHelper';
import { EnumSelectStatus } from './EnumDefine.Model';
import { ICalendarCell } from './ICalendarCell';

export class CMonth implements ICalendarCell {
    Enable: boolean;
    IsMouseOver: boolean;
    Year: number;
    Month: number; // 注意：这个Month取值是从0-11
    FrontDateList: CDate[] = []; // 点位符日期
    DateList: CDate[] = []; // { [key: string]: CDate[] } = {};
    SelectStatus: EnumSelectStatus;

    get FirstDate(): Date {
        return new Date(this.Year, this.Month, 1);
    }

    get LastDate(): Date {
        return new Date(this.Year, this.Month + 1, 0);
    }

    get Date(): Date {
        return new Date(this.Year, this.Month, 1);
    }

    // get DateList(): CDate[] {
    //     if (this.dateList.length === 0) {

    //     }
    //     return this.dateList;
    // }

    /**
     *
     *
     * @static
     * @param {number} year
     * @param {number} month 注意，month是0-11的，坑爹啊
     * @returns {CMonth}
     * @memberof CMonth
     */
    static createMonth(year: number, month: number): CMonth {
        if (month > 11) {
            month = month - 12;
            year = year + 1;
        }
        if (month === -1) {
            month = 11;
            year = year - 1;
        }
        const cMonth = new CMonth();
        cMonth.Year = year;
        cMonth.Month = month;
        cMonth.Enable = true;
        const firstDate = new Date(year, month, 1);
        const lastDate = new Date(year, month + 1, 0);
        const days = CDateHelper.getDays(lastDate, firstDate) + 1;
        const dayOfWeek = firstDate.getDay(); // 星期天是0
        for (let i = 0; i < dayOfWeek; i++) { // 这个循环是补充1号之前占位符
            const cDate = new CDate();
            cDate.Enable = false;
            cDate.Hidden = true;
            cMonth.FrontDateList.push(cDate);
        }
        for (let i = 0; i < days; i++) { // 这个是实际生成CDate
            const cDate = new CDate();
            cDate.Date = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + i);
            cDate.Enable = true;
            cMonth.DateList.push(cDate);
        }
        return cMonth;
    }
}
