import { CalendarOptions } from './CalendarOptions.Model';
import { CDateHelper } from '../../common/CDateHelper';
import { Calendar } from './Calendar.Model';
import { EventEmitter } from '@angular/core';
import {
  EnumDateSelectType,
  CalendarModeEnum
} from '../../models/EnumDefine.Model';
import { CalendarHelper } from './CalendarHelper';

export enum EnumTrianglePos {
  None = 0,
  FromDate = 1,
  ToDate = 2
}

export enum EnumAnimationPos {
  None = 0,
  FromDate = 1,
  ToDate = 2
}

export enum EnumCalendarStatus {
  ExpectFromDateStatus = 1, // 点击开始日期弹出的日历，期望选择开始日期
  FromDate2ToDateStatus = 2, // 点击开始日期弹出的日历，已经选择了开始日期，期望是选择结束日期
  ExpectToDateStatus = 3, // 点击结束日期弹出的日历，期望选择结束日期
  ToDate2FromDateStatus = 4, // 点击结束日期弹出的日历，但选择了开始日期
  FromDate2FromDateStatus = 5 // 这个没用了
}

/**
 * 动作
 * wing, 这里用状态模式改进写法
 * 1. 初始状态: ExpectFromDateStatus表示点击了开始时间弹出日历 或 ExpectToDateStatus表示点击了结束日期弹出的日历
 * 2. ExpectFromDateStatus -> FromDate2ToDateStatus -> (End) -> 验证不通过 -> ExpectFromDateStatus ...
 * 4. ExpectToDateStatus -> (End) -> 验证不通过 -> ExpectFromDateStatus ...
 * 5. ExpectToDateStatus -> ToDate2FromDateStatus -> ExpectToDateStatus -> (End) -> 验证不通过 -> ExpectFromDateStatus ...
 *
 */
export class DisplayOptions {
  trianglePos: EnumTrianglePos; // 三角形最终位置
  animationPos: EnumAnimationPos; // 动画移动的位置
  showCalendar: boolean; // 显示日历
}

export interface ICalendarStatusComponent {
  calendar: Calendar;
  options: CalendarOptions;
  display: DisplayOptions;
  calendarStatus: ICalendarStatus; // 实现状态模式
  minDays: number;

  fromDateChange: EventEmitter<Date>;
  toDateChange: EventEmitter<Date>;
  selectTypeChange: EventEmitter<EnumDateSelectType>;

  validStartDate(fromDate: Date, toDate: Date): boolean;
  validEndDate(fromDate: Date, toDate: Date): boolean;
}

export interface ICalendarStatus {
  component: ICalendarStatusComponent;
  calendarStatusType: EnumCalendarStatus;
  click(date: Date);
}

export class CalendarStatusFactory {
  static createExpectFromDateStatus(component: ICalendarStatusComponent) {
    let tmp = new ExpectFromDateStatus();
    tmp.component = component;
    tmp.calendarStatusType = EnumCalendarStatus.ExpectFromDateStatus;
    return tmp as ICalendarStatus;
  }
  // static createFromDate2FromDateStatus(component: ICalendarStatusComponent) {
  //     let tmp = new FromDate2FromDateStatus();
  //     tmp.component = component;
  //     tmp.calendarStatusType = EnumCalendarStatus.FromDate2FromDateStatus;
  //     return tmp as ICalendarStatus;
  // }
  static createFromDate2ToDateStatus(component: ICalendarStatusComponent) {
    let tmp = new FromDate2ToDateStatus();
    tmp.component = component;
    tmp.calendarStatusType = EnumCalendarStatus.FromDate2ToDateStatus;
    return tmp as ICalendarStatus;
  }
  static createToDate2FromDateStatus(component: ICalendarStatusComponent) {
    let tmp = new ToDate2FromDateStatus();
    tmp.component = component;
    tmp.calendarStatusType = EnumCalendarStatus.ToDate2FromDateStatus;
    return tmp as ICalendarStatus;
  }
  static createExpectToDateStatus(component: ICalendarStatusComponent) {
    let tmp = new ExpectToDateStatus();
    tmp.component = component;
    tmp.calendarStatusType = EnumCalendarStatus.ExpectToDateStatus;
    return tmp as ICalendarStatus;
  }
}

/**
 * 初始状态,期望click的是fromDate
 *
 * @export
 * @class ExpectFromDateStatus
 * @implements {ICalendarStatus}
 */
export class ExpectFromDateStatus implements ICalendarStatus {
  component: ICalendarStatusComponent;
  calendarStatusType: EnumCalendarStatus;
  click(date: Date) {
    // 校验
    let options = this.component.options;
    if (CalendarHelper.isDateRangPicker(options.Mode)) {
      if (options.EndDate) {
        const daySpan = CDateHelper.getDays(date, options.EndDate);
        if (daySpan > options.MaxDays - 1) {
          // 选择开始时间后，超出最大可选时间段，这下怎么处理？
        }
        if (
          CDateHelper.compareDate(date, options.EndDate) > 0 ||
          !this.component.validStartDate(date, options.EndDate)
        ) {
          // 大于EndDate或者非法的开始时间,重新选FromDate
          options.FromDate = date;
          const minDaySpan = this.component.minDays - 1;
          options.EndDate = CDateHelper.addDays(options.FromDate, minDaySpan);
          // this.component.calendar.setFromDate(date);
          this.component.calendar.resetFromDate(date);
          // 下一个状态是ExpectToDateStatus
          this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(
            this.component
          );
          // 通知更新
          this.component.fromDateChange.emit(options.FromDate);
          this.component.toDateChange.emit(options.EndDate);
        } else {
          // 合法开始时间，只更新开始时间
          options.FromDate = date;
          this.component.calendar.resetFromDate(date);
          // 下一个状态是ExpectToDateStatus
          this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(
            this.component
          );
          // 通知更新
          this.component.fromDateChange.emit(options.FromDate);
        }
      } else {
        // 没有EndDate，重新选FromDate
        if (!this.component.validStartDate(date, null)) {
          return;
        }
        if (CDateHelper.compareDate(date, options.FromDate) < 0) {
          options.FromDate = date;
          this.component.calendar.setFromDate(date);
          this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(
            this.component
          );
        } else {
          options.FromDate = date;
          this.component.calendar.setFromDate(date);
          this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(
            this.component
          );
        }
        // 通知更新
        this.component.fromDateChange.emit(options.FromDate);
        this.component.toDateChange.emit(null);
      }
      // 设置显示选项
      let display = this.component.display;
      display.trianglePos = EnumTrianglePos.ToDate;
      this.component.selectTypeChange.emit(EnumDateSelectType.EndDate);
      display.animationPos = EnumAnimationPos.ToDate;
      display.showCalendar = true;
    } else if (CalendarHelper.isSingleDatePicker(options.Mode)) {
      options.FromDate = date;
      this.component.calendar.setFromDate(date);
      // 通知更新
      this.component.fromDateChange.emit(options.FromDate);
      options.EndDate = options.FromDate;
      this.component.toDateChange.emit(options.EndDate);
      // 设置显示选项
      let display = this.component.display;
      display.trianglePos = EnumTrianglePos.None;
      this.component.selectTypeChange.emit(EnumDateSelectType.None);
      display.animationPos = EnumAnimationPos.None;
      display.showCalendar = false;
    }
  }
}

// 之所以定义这个类型，是因为在MouseOver的时候要判断
export class FromDate2ToDateStatus implements ICalendarStatus {
  component: ICalendarStatusComponent;
  calendarStatusType: EnumCalendarStatus;
  click(date: Date) {
    let options = this.component.options;
    //
    const daySpan = CDateHelper.getDays(date, options.FromDate);
    if (CDateHelper.compareDate(date, options.FromDate) < 0) {
      // 时间小于开始时间，跳转去设置开始时间
      // this.component.calendarStatus = CalendarStatusFactory.createFromDate2FromDateStatus(this.component);
      // this.component.calendarStatus.click(date);
      if (!this.component.validStartDate(date, null)) {
        return;
      }
      if (options.EndDate) {
        this.component.calendar.resetFromDate(date);
      } else {
        this.component.calendar.setFromDate(date);
      }
      // 赋值
      options.FromDate = date;
      this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(
        this.component
      );
      let display = this.component.display;
      display.trianglePos = EnumTrianglePos.ToDate;
      this.component.selectTypeChange.emit(EnumDateSelectType.EndDate);
      display.animationPos = EnumAnimationPos.None;
      display.showCalendar = true;
      // 通知更新
      this.component.fromDateChange.emit(options.FromDate);
      this.component.toDateChange.emit(options.EndDate);
    } else if (daySpan > options.MaxDays - 1) {
      // 超过最长可选天数，变成选择开始时间
      if (!this.component.validStartDate(date, null)) {
        return;
      }
      // 赋值
      options.FromDate = date;
      options.EndDate = CDateHelper.addDays(date, 1);
      this.component.calendar.setFromDate(options.FromDate);
      this.component.calendar.setEndDate(options.EndDate);
      //
      this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(
        this.component
      );
      let display = this.component.display;
      display.trianglePos = EnumTrianglePos.ToDate;
      this.component.selectTypeChange.emit(EnumDateSelectType.EndDate);
      display.animationPos = EnumAnimationPos.None;
      display.showCalendar = true;
      // 通知更新
      this.component.fromDateChange.emit(options.FromDate);
      this.component.toDateChange.emit(options.EndDate);
    } else {
      // 校验
      if (!this.component.validEndDate(options.FromDate, date)) {
        return;
      }
      // 更新日历
      this.component.calendar.setEndDate(date);
      // 赋值
      options.EndDate = date;
      // 设置显示选项
      let display = this.component.display;
      display.trianglePos = EnumTrianglePos.None;
      this.component.selectTypeChange.emit(EnumDateSelectType.None);
      display.animationPos = EnumAnimationPos.None;
      display.showCalendar = false;
      // 通知更新
      this.component.fromDateChange.emit(options.FromDate);
      this.component.toDateChange.emit(options.EndDate);
    }
  }
}

// export class FromDate2FromDateStatus implements ICalendarStatus {
//     component: ICalendarStatusComponent;
//     calendarStatusType: EnumCalendarStatus;
//     click(date: Date) {
//         // 校验
//         let options = this.component.options;
//         if (!this.component.validStartDate(date, null)) {
//             return;
//         }
//         // if (CDateHelper.compareDate(date, options.FromDate) < 0) {
//             //
//             if (options.EndDate) {
//                 this.component.calendar.resetFromDate(date);
//             } else {
//                 this.component.calendar.setFromDate(date);
//             }
//             // 赋值
//             options.FromDate = date;
//             this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(this.component);
//         // } else { }
//         // 更新日历
//         // this.component.calendar.setFromDate(date);
//         // 赋值
//         // options.FromDate = date;
//         // this.component.calendarStatus = CalendarStatusFactory.createFromDate2ToDateStatus(this.component); // 下一个状态是ExpectToDateStatus
//         // 设置显示选项
//         let display = this.component.display;
//         display.trianglePos = EnumTrianglePos.ToDate;
//         this.component.selectTypeChange.emit(EnumDateSelectType.EndDate);
//         display.animationPos = EnumAnimationPos.None;
//         display.showCalendar = true;
//         // 通知更新
//         this.component.fromDateChange.emit(options.FromDate);
//         this.component.toDateChange.emit(options.EndDate);
//     }
// }

/**
 * 初始状态或者选择开始时间后的状态
 *
 * @export
 * @class ExpectToDateStatus
 * @implements {ICalendarStatus}
 */
export class ExpectToDateStatus implements ICalendarStatus {
  component: ICalendarStatusComponent;
  calendarStatusType: EnumCalendarStatus;
  click(date: Date) {
    let options = this.component.options;
    //
    if (CDateHelper.compareDate(date, options.FromDate) < 0) {
      // 校验
      // if (!this.component.validStartDate(date, null)) {
      //     return;
      // }
      // 时间小于开始时间，跳转去设置开始时间
      this.component.calendarStatus = CalendarStatusFactory.createToDate2FromDateStatus(
        this.component
      );
      this.component.calendarStatus.click(date);
    } else {
      // 校验
      if (!this.component.validEndDate(options.FromDate, date)) {
        return;
      }
      // 更新日历
      this.component.calendar.setEndDate(date);
      // 赋值
      options.EndDate = date;
      // 设置显示选项
      let display = this.component.display;
      display.trianglePos = EnumTrianglePos.None;
      this.component.selectTypeChange.emit(EnumDateSelectType.None);
      display.animationPos = EnumAnimationPos.None;
      display.showCalendar = false;
      // 通知更新
      this.component.fromDateChange.emit(options.FromDate);
      this.component.toDateChange.emit(options.EndDate);
    }
  }
}

export class ToDate2FromDateStatus implements ICalendarStatus {
  component: ICalendarStatusComponent;
  calendarStatusType: EnumCalendarStatus;
  click(date: Date) {
    // 校验
    let options = this.component.options;
    if (!this.component.validStartDate(date, null)) {
      return;
    }
    // 更新日历
    this.component.calendar.resetFromDate(date);
    // 赋值
    options.FromDate = date;
    this.component.calendarStatus = CalendarStatusFactory.createExpectToDateStatus(
      this.component
    ); // 下一个状态是ExpectToDateStatus
    // 设置显示选项
    let display = this.component.display;
    display.trianglePos = EnumTrianglePos.ToDate;
    this.component.selectTypeChange.emit(EnumDateSelectType.EndDate);
    display.animationPos = EnumAnimationPos.None;
    display.showCalendar = true;
    // 通知更新
    this.component.fromDateChange.emit(options.FromDate);
    this.component.toDateChange.emit(options.EndDate);
  }
}
