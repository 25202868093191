

import { SuggestionItemCategory } from '../suggestions';
import { DisplayOption } from './display-option.model';

export class Destination {
    id: string;
    name: {
        [key: string]: string;
    };
    type: SuggestionItemCategory;
    order: number;
    displayOptions: {
        [key: string]: DisplayOption;
    };

}

