import {
  DateTimeHelper,
  TimeSpan,
  IDateRangeWindowModel
} from '@dida-shopping/dida-services/common';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { ITicketDetail, ITicketIssueView } from './models';
import { HotelBookingStatusType } from '../booking/enums';
import { TravelStatuses } from './enums/travel-statuses.enum';

export class TicketHelper {
  static init(ticket: ITicketDetail): void {
    if (ticket && ticket.ReplyList) {
      if (ticket.BookingInfo) {
        if (ticket.BookingInfo.CancelTime) {
          ticket.BookingInfo.CancelTime = DateTimeHelper.parse(
            ticket.BookingInfo.CancelTime,
            true
          );
        }
        ticket.BookingInfo.CheckInDate = DateTimeHelper.parse(
          ticket.BookingInfo.CheckInDate,
          true
        );
        ticket.BookingInfo.CheckOutDate = DateTimeHelper.parse(
          ticket.BookingInfo.CheckOutDate,
          true
        );
        ticket.BookingInfo.OrderDate = DateTimeHelper.parse(
          ticket.BookingInfo.OrderDate,
          true
        );
      }
      ticket.ReplyList.forEach(reply => {
        reply.Attachments = reply.AttachmentJson
          ? JSON.parse(reply.AttachmentJson)
          : [];
      });
      const clientReplyList = ticket.ReplyList.filter(
        reply => reply.IsSender
      ).sort((a, b) => {
        return (
          -1 *
          (DateTimeHelper.parse(a.CreateTime).getTime() -
            DateTimeHelper.parse(b.CreateTime).getTime())
        );
      });
      if (clientReplyList.length > 0) {
        ticket.Question = clientReplyList[0];
      }
    }
  }

  static getModalStyle(size: 'small' | 'middle' = 'small') {
    if (size === 'small') {
      return {
        width: '65wh',
        minWidth: '860px',
        maxWidth: '960px'
      };
    } else {
      return {
        width: '80wh',
        minWidth: '1000px',
        maxWidth: '1140px'
      };
    }
  }

  static getTravelStatus(travelDates: IDateRangeWindowModel) {
    const today = DateTimeHelper.today;
    if (travelDates.From > today) {
      return TravelStatuses.booked;
    } else if (travelDates.To >= today) {
      return TravelStatuses.checkIn;
    } else {
      return TravelStatuses.checkOut;
    }
  }

  static isUrgent(fromDate: Date) {
    // 距离入住日还有一天, 标记为紧急工单
    const beforeCheckIn = new TimeSpan(fromDate, DateTimeHelper.today)
      .totalDays;
    return beforeCheckIn >= 0 && beforeCheckIn <= 1;
  }

  static normalize(ticket: ITicketDetail) {
    TicketHelper.init(ticket);
    return ticket;
  }

  /**
   * 订单列表页 与 工单列表页稍稍有点不同
   * 只有工单列表页才能允许已取消的订单发起工单（在已有工单的情况下允许追加）
   */
  static canCreate(
    bookingStatus: HotelBookingStatusType,
    checkoutDate: Date,
    forTicketListPage: boolean,
    lang: LanguageType
  ): boolean {
    if (lang !== LanguageType.zh_CN) {
      return false;
    }

    if (
      bookingStatus !== HotelBookingStatusType.Confirmed &&
      bookingStatus !== HotelBookingStatusType.Canceled
    ) {
      return false;
    }

    let day = 31;
    if (bookingStatus === HotelBookingStatusType.Canceled) {
      if (forTicketListPage) {
        day = 15;
      } else {
        // 只有工单列表才能允许已取消的订单发起工单
        return false;
      }
    }

    const today = DateTimeHelper.today;
    const lastTime = DateTimeHelper.addDays(checkoutDate, day);
    return lastTime.getTime() - today.getTime() >= 0;
  }
}
