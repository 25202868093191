<div class="date-range-picker" class="{{(dateType!=0?'active':'') + ' ' + styleClass}}">
    <label i18n="时间@@Label_DatePicker_DateRange" *ngIf="showLabel && style != 'simple'">时间</label>
    <!-- 正常的格式 -->
    <ng-container *ngIf="style == 'normal' || style == 'normal-sm' || style == 'mini'">
        <div class="date-range-picker__container">
            <div class="date-range-picker__date-item">
                <span class="date-range-picker__value-holder date-range-picker__date-item_cursor" #dateCalendarZone
                    (click)="setDateType(1)"
                    [ngClass]="{'active':dateType==1, 'readonly': readonly}">{{fromDate | date: 'yyyy-MM-dd'}}</span>
            </div>
            <div class="date-range-picker__date-item">
                <span class="date-range-picker__value-holder date-range-picker__value-holder_joint">—</span>
            </div>
            <div class="date-range-picker__date-item">
                <span class="date-range-picker__value-holder date-range-picker__date-item_cursor" #dateCalendarZone
                    (click)="setDateType(2)"
                    [ngClass]="{'active':dateType==2, 'readonly': readonly}">{{toDate | date: 'yyyy-MM-dd'}}</span>
            </div>
            <div class="date-range-picker__date-item" *ngIf="showSummary && style != 'mini' && stayNights >= 0">
                <small class="date-range-picker__value-holder date-range-picker__value-holder_stay text-muted"
                    i18n="@@Label_DatePicker_StayNights">共<span class="text-highlight">{{stayNights}}</span>晚</small>
            </div>
        </div>
    </ng-container>
    <!-- 简单的格式,用在个人数据中心 -->
    <ng-container *ngIf="style == 'simple'">
        <div class="date-range-picker__simple">
            <div class="date-range-picker__date-item">
                <span class="date-range-picker__value-holder date-range-picker__date-item_cursor" #dateCalendarZone
                    (click)="setDateType(1)"
                    [ngClass]="{'active':dateType==1, 'readonly': readonly}">{{fromDate | date: 'yyyy-MM-dd'}}</span>
            </div>
            <div class="date-range-picker__date-item">
                <span class="date-range-picker__value-holder date-range-picker__value-holder_joint"
                    i18n="@@Label_DatePicker_To">至</span>
            </div>
            <div class="date-range-picker__date-item">
                <span class="date-range-picker__value-holder date-range-picker__date-item_cursor" #dateCalendarZone
                    (click)="setDateType(2)"
                    [ngClass]="{'active':dateType==2, 'readonly': readonly}">{{toDate | date: 'yyyy-MM-dd'}}</span>
            </div>
            <div class="date-range-picker__date-item">
                <small class="date-range-picker__value-holder date-range-picker__value-holder_stay text-muted"
                    i18n="@@Label_DatePicker_StayDays">共<span class="text-highlight">{{stayDays}}</span>天</small>
            </div>
        </div>
    </ng-container>
    <dida-calendar #dateCalendarZone #dateCalendar [(fromDate)]="fromDate" [(toDate)]="toDate" [minDate]="minDate" [maxDate]="maxDate"
        [minDays]="minDays" [maxDays]="maxDays" [marginLeft]="calendarStyle.MarginLeft" [marginTop]="calendarStyle.MarginTop"
        [showSriangle]="calendarStyle.ShowSriangle" [sriangleFromMarginLeft]="calendarStyle.SriangleFromMarginLeft"
        [sriangleToMarginLeft]="calendarStyle.SriangleToMarginLeft" (toDateChange)="toDateChanged($event)"
        (fromDateChange)="fromDateChanged($event)" (selectTypeChange)="selectTypeChanged($event)">
    </dida-calendar>
</div>
