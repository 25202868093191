import { EanHotelResponse } from '@dida-shopping/dida-services/ean';
import { Injectable } from '@angular/core';

import { HttpClient } from '../../http/http-client';
import { LoggerService } from '../../logger/logger.service';

import {
  HotelPriceSearchOptions,
  PriceSearchResultModel,
  HotelRatePlanPriceModel,
} from '../../../shared/models/rates/rates.models';
import { RatePlanPriceSearchOption } from '../../../shared/models/rateplan/rateplan.models';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';

export class EPS_HotelPrice_AdditionalInfo_Options {
  //key: string;
  HotelID: number;
  FromDate: string;
  ToDate: string;
  RoomCount: number;
  AdultCount: number;
  ChildCount: number;
  childAgeList: number[];
  Nationality: string;
}
export class EPS_RatePlanPrice_AdditionalInfo_Options {
  HotelID: number;
  SupplierRatePlanID: string;
  FromDate: string;
  ToDate: string;
  RoomCount: number;
  AdultCount: number;
  ChildCount: number;
  childAgeList: number[];
  Nationality: string;
}

/**
 * 价格数据相关接口
 *
 * @export
 * @class RateSearchService
 */
@Injectable()
export class RateSearchService {
  constructor(private logger: LoggerService, private httpClient: HttpClient) {}

  getEPSHotelPriceAdditionalInfo(options: EPS_HotelPrice_AdditionalInfo_Options) {
    return this.httpClient.post<EanHotelResponse>('/HotelPriceApi/EPS_HotelPrice_AdditionalInfo', options);
  }
  getEPSRatePlanPriceAdditionalInfo(options: EPS_RatePlanPrice_AdditionalInfo_Options) {
    return this.httpClient.post<EanHotelResponse>('/HotelPriceApi/EPS_RatePlanPrice_AdditionalInfo', options);
  }

  /**
   * 获取酒店价格
   *
   * @param {HotelPriceSearchOptions} options 酒店价格查询参数
   * @param {string} bid 当SupplierMask开启时必填 See: DidaShopping.Models.Auth.DidaApplicationUserContextModel --> BatchID
   * @param {string[]} sourceMaskList 当SupplierMask开启时必填
   * @param {boolean} [rec=null] 是否需要统计区域价格搜索次数（默认为`否`）
   * @returns {HttpObservable<PriceSearchResultModel>}
   * @memberof RateSearchService
   */
  searchHotelPrice(options: HotelPriceSearchOptions, bid: string, sourceMaskList: string[], rec: boolean = null) {
    let tempst: any = DateTimeHelper.format(options.CheckInDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
    return this.httpClient.post<PriceSearchResultModel>(
      '/HotelPriceAPI/Search?' + '_d=' + tempst,
      {
        data: options,
        rec: rec,
        bid: bid,
        sourceMaskList: sourceMaskList,
      },
      null,
      (mdl) => {
        mdl.HotelPriceList.forEach((price) => {
          this.normalizeHotelRatePlanPrice(price);
        });
      },
      (payload) => {
        let option: HotelPriceSearchOptions = payload.data;
        let inDateStr: any = DateTimeHelper.format(option.CheckInDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
        option.CheckInDate = inDateStr;
        let outDateStr: any = DateTimeHelper.format(option.CheckOutDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
        option.CheckOutDate = outDateStr;
        if (option.PriceSearchFilter && option.PriceSearchFilter.Location) {
          if (options.PriceSearchFilter.Location instanceof GeoCoordinate) {
            option.PriceSearchFilter.Location = options.PriceSearchFilter.Location.serviceCoordinate;
          }
        }
      }
    );
  }

  /**
   * 酒店实时查询监控
   * https://tower.im/teams/191049/todos/61481/
   */
  searchMonitor(options: HotelPriceSearchOptions) {
    let tempst: any = DateTimeHelper.format(options.CheckInDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
    return this.httpClient.post<PriceSearchResultModel>(
      '/HotelPriceAPI/SearchMonitor?' + '_d=' + tempst,
      {
        data: options,
      },
      null,
      (mdl) => {},
      (payload) => {}
    );
  }

  /**
   * 获取RatePlan实时价格
   *
   * @param {RatePlanPriceSearchOption} options 价格查询参数
   * @returns {HttpObservable<PriceSearchResultModel>}
   * @memberof RateSearchService
   */
  searchRatePlanPrice(options: RatePlanPriceSearchOption) {
    return this.httpClient.post<PriceSearchResultModel>(
      '/HotelPriceAPI/GetRatePlanPrice',
      {
        options: options,
      },
      null,
      (mdl) => {
        mdl.HotelPriceList.forEach((price) => {
          this.normalizeHotelRatePlanPrice(price);
        });
      },
      (payload: RatePlanPriceSearchOption) => {
        // in case of carrying the time zone info, use date string with `yyyy-MM-dd` format to send the request.
        if (payload.CheckIn instanceof Date) {
          payload.CheckIn = DateTimeHelper.format(
            DateTimeHelper.getDate(payload.CheckIn),
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
        }
        if (payload.CheckOut instanceof Date) {
          payload.CheckOut = DateTimeHelper.format(
            DateTimeHelper.getDate(payload.CheckOut),
            DateTimeHelper.DEFAULT_DATE_FORMAT
          );
        }
      }
    );
  }

  private normalizeHotelRatePlanPrice(price: HotelRatePlanPriceModel) {
    if (!price.RatePlanPriceList) {
      price.RatePlanPriceList = [];
      return;
    }
    price.RatePlanPriceList.forEach((ratePlanPrice) => {
      ratePlanPrice.RateList.forEach((r) => {
        r.StayDate = DateTimeHelper.parse(r.StayDate, true);
      });
      ratePlanPrice.CancellationList.forEach((c) => {
        c.FromDate = DateTimeHelper.parse(c.FromDate, true);
      });
      ratePlanPrice.CancellationList.sort((a, b) => {
        return a.FromDate.getTime() - b.FromDate.getTime();
      });
      let extraInfoString = (ratePlanPrice.RatePlan.ExtraInfoIDList as any) || '';
      ratePlanPrice.RatePlan.ExtraInfoIDList = extraInfoString.split('|').map((item) => parseInt(item, 10));
      ratePlanPrice.RatePlan.IsPackage = ['405', '407'].includes(ratePlanPrice.RatePlan.RatePlanID.split('$')[0]);
    });
  }
}
