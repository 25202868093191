import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { DidaUITKDebugLoginComponent } from './debug-login.component';
import { DIDA_UITK_AUTH_MODAL_TRIGGER_PROVIDER } from './debug-login.provider';

export * from './debug-login.component';
export * from './debug-login.provider';

@NgModule({
  declarations:[
    DidaUITKDebugLoginComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule,
  ],
  exports: [],
  providers: [
    DIDA_UITK_AUTH_MODAL_TRIGGER_PROVIDER
  ],
  entryComponents: [DidaUITKDebugLoginComponent]
})
export class DidaUITKDebugLoginModule {
  constructor(@Optional() @SkipSelf() parentModule?: DidaUITKDebugLoginModule) {
    if (parentModule) {
      throw new Error(
        'DidaUITKDebugLoginModule is already loaded. Import it in the AppModule only');
    }
  }
}
