import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import {
  DidaSuggestionTypeaheadConfig,
  RegionOption,
  RegionSearchSwithStyle,
  SuggestionItem,
  SuggestionItemCategory,
  SuggestionOption,
  SuggestionQueryPayload,
  SuggestionSearchHelper,
  suggestionService,
  SuggestionType,
  SuggestionTypeaheadDropdownTarget as DropdownTarget,
  SuggestionTypeaheadExtranInfoMode,
  SuggestionTypeaheadOptions,
  SuggestionValue
} from '@dida-shopping/dida-services/suggestions';
import { ArrayHelper, IPaginationWrapper } from '@dida-shopping/dida-services/common';

import { DidaTabsCascaderConfig, IDidaTabsCascaderOption } from '../tabs-cascader/tabs-cascader.model';
import {
  ApplicationEventHub,
  ApplicationLanguageChangeEvent,
  BehaviorManagementService,
  ComponentBaseWithContext,
  ngxHttpCall
} from '@dida-shopping/ngx-dida-site-core';
import { ComponentBehaviorCategory, SuggestionTypeaheadActionType } from '@dida-shopping/dida-services/bm';
import {
  Airport,
  AIRPORT_CITIES,
  AIRPORTS,
  AIRPORTS_HOT_SEARCH_CITY_ID_LIST,
  CITIES,
  CONTINENTS,
  HOT_SEARCH_CITY_ID_LIST,
  Region
} from '@dida-shopping/dida-services/destinations';
import { LanguageType } from '@dida-shopping/dida-services/i18n';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
import { DOCUMENT } from '@angular/common';
import { collapseMotion } from 'ng-zorro-antd/core/animation';
import { UP_ARROW, DOWN_ARROW, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'nd-suggestion-typeahead',
  templateUrl: './suggestion-typeahead.component.html',
  styleUrls: ['./suggestion-typeahead.component.scss'],
  animations: [collapseMotion],
  encapsulation: ViewEncapsulation.Emulated
})
export class DidaUITKSuggestionTypeaheadComponent extends ComponentBaseWithContext
  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  // input & output

  @Input() ndConfig: DidaSuggestionTypeaheadConfig;
  @Output() ndChange = new EventEmitter<SuggestionValue>();
  @Output() ndAfterInit = new EventEmitter<SuggestionValue>();

  // public properties

  tabsCascaderConfig: DidaTabsCascaderConfig;
  tabsCascaderSelectedOptions: Region[];

  keyword: string;
  keyword_tag_position_left: number;
  searchingSuggestion = false;

  get searchKeywordId() {
    return 'searchkeyword_' + this.instanceId;
  }

  searchMode: 'all' | 'region' = 'all';
  regionOptions: RegionOption[] = [];
  isShowRegionOptions = false;
  currentSelectedRegion: RegionOption;
  suggestionOptions: {
    type: SuggestionType;
    options: SuggestionOption[];
  }[] = [];
  currentHoverOption: SuggestionOption;
  currentSelectedOption: SuggestionOption;
  currentSuggestionTypeaheadOptions: SuggestionTypeaheadOptions;
  switchCheckboxSelected = false;
  isShowNoResult: boolean;
  // private fields

  @ViewChild('keywordControl', { read: ElementRef, static: true })
  private _keywordInputElement: ElementRef<HTMLInputElement>;

  private _keywordLengthDetector: HTMLSpanElement;

  @HostBinding('class.mini')
  get miniMode() {
    return !!this.ndConfig.miniMode;
  }

  private _dropdownTarget = DropdownTarget.none;
  private _keywordInputStatus: 'focus' | 'blur';
  private _currentValue: SuggestionValue;
  private _currentSuggestionOptions: SuggestionOption[];
  private _lastSearchKey: string;

  constructor(
    private _appEventHub: ApplicationEventHub,
    private bmService: BehaviorManagementService,
    private _element: ElementRef,
    @Inject(DOCUMENT) protected _document: Document
  ) {
    super();
  }

  // public methods
  focus() {
    this._keywordInputElement.nativeElement.focus();
    this.setKeywordInputStatus('focus');
  }

  setKeywordInputStatus(status: 'focus' | 'blur') {
    this._keywordInputStatus = status;
    if (status === 'focus') {
      if (!this.keyword && this.searchMode === 'all') {
        this.setDropdownTarget(DropdownTarget.cities);
      } else {
        this._keywordInputElement.nativeElement.select();
        if (this.keyword) {
          if (
            this._lastSearchKey === this.keyword &&
            this.suggestionOptions.length > 0
          ) {
            this.setDropdownTarget(DropdownTarget.suggestions);
          } else {
            this.searchSuggestion(this.keyword);
          }
        }
      }
    } else if (status === 'blur') {
      // const hasSelectedValue = !!this._currentValue;
      // if (hasSelectedValue) {
      //   const isEmptyInput = !this.keyword;
      //   const resetPreviousSelected = this.ndConfig.options && this.ndConfig.options.resetPreviousSelected;
      //   if (resetPreviousSelected) {
      //     this.updateKeywordValue(this._currentValue.name);
      //   } else if (isEmptyInput) {
      //     const regionSearchEnabled = this.ndConfig.enableRegionSearch;
      //     const hasRegionSelected = !!this.currentSelectedRegion;
      //     if (!regionSearchEnabled || (regionSearchEnabled && !hasRegionSelected)) {
      //       this.updateKeywordValue(this._currentValue.name);
      //     }
      //   }
      // }
    }
  }

  keywordChange($event?: any) {
    if (this.searchMode === 'region') {
      this.ndChange.emit({
        id: this.currentSelectedRegion.id,
        name: this.currentSelectedRegion.name,
        name_en: this.currentSelectedRegion.name_en,
        category: this.currentSelectedRegion.category,
        keyword: this.keyword,
        location: null,
        isSelected: false,
        isDeSelected: false
      });
    } else {
      this.ndChange.emit({
        keyword: this.keyword,
        location: null,
        isSelected: false,
        isDeSelected: false
      });
    }
  }

  initSuggestionContentById(
    entityId: string,
    docId: string,
    categories: SuggestionItemCategory[],
    fallBackContent: string = null,
    triggerValueChange = false
  ): Subscription {
    return this.searchSuggestionByIdForDisplay(
      entityId,
      docId,
      fallBackContent,
      categories
    ).subscribe(option => {
      if (triggerValueChange) {
        this.suggestionOptionSelect(option);
      }
    });
  }

  cleanKeywordInputText() {
    this.updateKeywordValue('');
    this.currentHoverOption = null;
    this.keywordChange();
    // this.currentSelectedOption = null;
    this.bmService.trackEvent({
      category: ComponentBehaviorCategory.SuggestionTypeahead,
      action: SuggestionTypeaheadActionType.ClearKeyword,
      label: 'Clear',
      value: 1
    });
    this._keywordInputElement.nativeElement.focus();
  }

  resetInput() {
    this.updateKeywordValue('');
    this.currentHoverOption = null;
    this.currentSelectedOption = null;
    this.currentSelectedRegion = null;
    this._currentValue = null;
    this.suggestionOptions = [];
    // this.keywordChange();
  }

  cityChange(regions: Region[]) {
    if (regions.length === 2) {
      const region = {
        id: regions[1].id.toString(),
        name: regions[1].name[this.ndConfig.languageType],
        name_en: regions[1].name[LanguageType.en_US],
        category: regions[1].type
      };
      this.updateKeywordValue(region.name);
      ngxHttpCall(suggestionService.searchRegion(region.id))
        .subscribe(item => {
          const option = this.convertToOption(item);
          region.name =
            this.ndConfig.languageType === LanguageType.en_US
              ? option.name_en
              : option.name;
          this.currentSelectedRegion = region;
          this.regionOptions = [region];
          this.updateKeywordValue(region.name);
          this.setDropdownTarget(DropdownTarget.none);
          const value: SuggestionValue = {
            id: region.id,
            name: region.name,
            name_en: region.name_en,
            category: region.category,
            destinationID: null,
            location: option.location,
            isSelected: true,
            isDeSelected: false
          };
          this._currentValue = value;
          this.ndChange.emit(value);
          if (this.ndConfig.autoSearchCitySuggestion) {
            this.searchSuggestion(this.keyword);
          }
        });
    }
  }

  airportChange(airports: Airport[]) {
    if (airports.length === 2) {
      const target = airports[1];
      this.setDropdownTarget(DropdownTarget.none);
      const value: SuggestionValue = {
        id: target.id,
        name: target.name[this.ndConfig.languageType],
        name_en: target.name[LanguageType.en_US],
        category: target.type,
        destinationID: null,
        airportCode: target.airportCode,
        location: null,
        isSelected: true,
        isDeSelected: false
      };
      this._currentValue = value;
      this.updateKeywordValue(target.name[this.ndConfig.languageType]);
      this.ndChange.emit(value);
      if (this.ndConfig.autoSearchAirportSuggetion) {
        this.searchSuggestionByIdForDisplay(
          null,
          `A-${target.airportCode}`,
          target.name[this.ndConfig.languageType],
          [SuggestionItemCategory.Airport]
        ).subscribe(option => {
          this.suggestionOptionSelect(option);
        });
      }
    }
  }

  suggestionOptionHover(
    option: SuggestionOption,
    status: 'mouseEnter' | 'mouseLeave'
  ) {
    if (status === 'mouseEnter' && option !== this.currentHoverOption) {
      this.currentHoverOption = option;
      console.log('mouseEnter');
    } else if (
      status === 'mouseLeave' &&
      // !this.currentSuggestionTypeaheadOptions.autoSelectHover &&
      this.currentHoverOption === option
    ) {
      this.currentHoverOption = null;
    }
  }

  suggestionOptionSelect(option: SuggestionOption) {
    if (option !== this.currentSelectedOption) {
      this.currentSelectedOption = option;
      if (
        option.category !== SuggestionItemCategory.Hotel &&
        option.category !== SuggestionItemCategory.Airport
      ) {
        const region = {
          id: option.id.toString(),
          name: option.name,

          name_en: option.name_en,
          category: option.category
        };
        this.regionOptions = [region];
      }
      this.updateKeywordValue(option.name);
      const data: SuggestionValue = {
        id: option.id.toString(),
        name: option.name,
        name_en: option.name_en,
        category: option.category,
        destinationID: null,
        airportCode: option.airportCode,
        location: option.location,
        isSelected: true,
        isDeSelected: false
      };
      if (option.destinationID) {
        data.destinationID = option.destinationID;
      }
      this._currentValue = data;

      let action = SuggestionTypeaheadActionType.SelectRegion;
      let label = `${option.id}`;

      switch (option.category) {
        case SuggestionItemCategory.Hotel:
          action = SuggestionTypeaheadActionType.SelectHotel;
          break;
        case SuggestionItemCategory.POI:
        case SuggestionItemCategory.POIS:
          action = SuggestionTypeaheadActionType.SelectPOI;
          break;
        case SuggestionItemCategory.Airport:
          action = SuggestionTypeaheadActionType.SelectAirport;
          label = `${option.airportCode}`;
          break;
      }

      if (SuggestionSearchHelper.shouldSearchInParentRegion(option)) {
        data.parentId = option.parentId;
        this.ndConfig.regionID = option.parentId;
        this.initDestination();
      }

      this.bmService.trackEvent({
        category: ComponentBehaviorCategory.SuggestionTypeahead,
        action: action,
        label: label,
        value: 1
      });

      this.ndChange.emit(data);
      this.setDropdownTarget(DropdownTarget.none);
    }
  }

  regionOptionSelect(option: RegionOption, delay = 0) {
    if (
      this.ndConfig.regionSearchSwithStyle === RegionSearchSwithStyle.checkbox
    ) {
      this.switchCheckboxSelected = option ? true : false;
    }
    setTimeout(() => {
      if (option == null) {
        this.searchMode = 'all';
        this.updateKeywordValue(this._currentValue.name);
        this.currentSuggestionTypeaheadOptions = this.ndConfig.options;
        this.suggestionOptionSelect(this.currentSelectedOption);
      } else {
        this.searchMode = 'region';
        this.updateKeywordValue('');
        this.currentSuggestionTypeaheadOptions = this.ndConfig.regionSearchOptions;
      }
      this.bmService.trackEvent({
        category: ComponentBehaviorCategory.SuggestionTypeahead,
        action: SuggestionTypeaheadActionType.SwitchRegionOption,
        label: this.searchMode,
        value: 1
      });
      this.suggestionOptions = [];
      this.isShowNoResult = false;
      this.currentSelectedRegion = option;
      this.isShowRegionOptions = false;
      this._keywordInputElement.nativeElement.focus();
    }, delay);
  }

  searchTitleClick() {
    if (
      this.ndConfig.enableRegionSearch &&
      this.regionOptions &&
      this.regionOptions.length > 0
    ) {
      this.isShowRegionOptions = !this.isShowRegionOptions;
      this.bmService.trackEvent({
        category: ComponentBehaviorCategory.SuggestionTypeahead,
        action: SuggestionTypeaheadActionType.ClickRegionOption,
        label: this.isShowRegionOptions ? 'Show' : 'Hide',
        value: 1
      });
    }
  }

  // host listener

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (
      !this._element.nativeElement
        .querySelector('.dida-suggestion-typeahead')
        .contains(event.target)
    ) {
      if (
        this.keyword &&
        this.currentSuggestionTypeaheadOptions.autoSelectHover &&
        this.currentHoverOption &&
        this.currentHoverOption !== this.currentSelectedOption
      ) {
        this.suggestionOptionSelect(this.currentHoverOption);
      } else if (this._keywordInputStatus !== 'focus') {
        this.setDropdownTarget(DropdownTarget.none);
        this.isShowRegionOptions = false;
      }
    }
  }

  ngOnInit() {
    if (!this.ndConfig) {
      this.ndConfig = new DidaSuggestionTypeaheadConfig();
    }

    if (!this.ndConfig.languageType) {
      this.ndConfig.languageType = this.lang;
    }

    this.currentSuggestionTypeaheadOptions = this.ndConfig.options;

    if (this.ndConfig.showInputRegionTag) {
      const detectorEl = this._document.createElement('span');
      detectorEl.setAttribute('style',
        'visibility: hidden; display: inline-block; font-size: 14px;');
      this._keywordLengthDetector = this._document.getElementsByTagName('body')[0].appendChild(detectorEl);
    } else {
      this._keywordLengthDetector = null;
    }

    // 初始化基本信息
    this.initDestination();

    // 初始化默认选线
    this.initDefaultOptions();

    // 初始化全局事件处理
    this.initGlobalEventHandle();
  }


  private initDefaultOptions() {

    if (this.ndConfig.showDefaultOptions) {
      if (this.ndConfig.airportMode) {
        // 初始化机场选择控件
        this.initAirports();
      } else {
        // 初始化城市选择组件
        this.initCities();
      }
    }
  }

  ngAfterViewInit() {
    this.searchTextInput(
      observableFromEvent(this._keywordInputElement.nativeElement, 'input')
    );
  }

  ngOnDestroy() {
    if (this._keywordLengthDetector) {
      this._document.getElementsByTagName('body')[0].removeChild(this._keywordLengthDetector);
      this._keywordLengthDetector = null;
    }
    super.ngOnDestroy();
  }

  // presentation logic

  get isKeywordInputOnFcous() {
    return this._keywordInputStatus === 'focus';
  }

  get isShowSuggestionDropdown() {
    return this._dropdownTarget !== DropdownTarget.none;
  }

  get isShowSuggestionOptions() {
    return this._dropdownTarget === DropdownTarget.suggestions && this.keyword;
  }

  get isShowDefaultSuggestion() {
    return !this.keyword && this.searchMode === 'all';
  }

  get isValid() {
    if (this.searchMode === 'region') {
      if (this.keyword && this.keyword.length >= this.currentSuggestionTypeaheadOptions.minKeywordLength) {
        return true;
      }
      if (this._currentValue) {
        return true;
      }
      return false;
    }
    if (!this._currentValue) {
      return false;
    } else {
      return this._currentValue.name === this.keyword;
    }
  }

  isActiveSuggestionOption(option: SuggestionOption) {
    return this.currentHoverOption && option.id === this.currentHoverOption.id;
  }

  getExtraTitle(option: SuggestionOption) {
    if (
      option.category === SuggestionItemCategory.Hotel &&
      this.currentSuggestionTypeaheadOptions.extranInfoMode ===
      SuggestionTypeaheadExtranInfoMode.default
    ) {
      return option.address.destinationName + ',' + option.address.countryName;
    } else {
      return '';
    }
  }

  searchInputKeyUp(event: KeyboardEvent) {
    if (
      this._keywordInputStatus === 'focus' &&
      this._dropdownTarget === DropdownTarget.suggestions &&
      ArrayHelper.isNullOrEmpty(this._currentSuggestionOptions)
    ) {
      let currentIndex = this.currentSuggestionTypeaheadOptions.autoSelectHover
        ? 0
        : -1;
      if (this.currentHoverOption) {
        currentIndex = this._currentSuggestionOptions.findIndex(item => {
          return this.currentHoverOption === item;
        });
      }
      if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
        if (event.keyCode === UP_ARROW) {
          currentIndex--;
        } else if (event.keyCode === DOWN_ARROW) {
          currentIndex++;
        }

        if (currentIndex < 0) {
          currentIndex = this._currentSuggestionOptions.length - 1;
        }

        if (currentIndex >= this._currentSuggestionOptions.length) {
          currentIndex = 0;
        }

        this.currentHoverOption = this._currentSuggestionOptions[currentIndex];
      }

      if (event.keyCode === ENTER && this.currentHoverOption) {
        this.suggestionOptionSelect(this.currentHoverOption);
      }
    }
  }

  searchInputKeyPaste(event: ClipboardEvent) {
    if (
      this._keywordInputStatus === 'focus' &&
      this._dropdownTarget === DropdownTarget.suggestions &&
      ArrayHelper.isNullOrEmpty(this._currentSuggestionOptions)
    ){
      let currentIndex = this.currentSuggestionTypeaheadOptions.autoSelectHover
        ? 0
        : -1;
      if (this.currentHoverOption) {
        currentIndex = this._currentSuggestionOptions.findIndex(item => {
          return this.currentHoverOption === item;
        });
      }
    }
  }
  // private methods

  private searchTextInput(observable: Observable<any>) {
    observable
      .pipe(
        map(event => event.target.value.trim()),
        filter(keyword => !!keyword),
        distinctUntilChanged(),
        debounceTime(this.ndConfig.searchDelay)
      )
      .subscribe(keyword => {
        this.searchSuggestion(keyword);
      });

    observable.subscribe(event => {
      if (!this.keyword && !this.currentSelectedRegion) {
        if (this.ndConfig.showDefaultOptions) {
          this.tabsCascaderSelectedOptions = [
            this.tabsCascaderSelectedOptions[0]
          ];
          this.setDropdownTarget(DropdownTarget.cities);
        }
      }
    });
  }

  private setDropdownTarget(target: DropdownTarget) {

    if (target === this._dropdownTarget) {
      return;
    }

    if (
      target === DropdownTarget.cities && !this.ndConfig.showDefaultOptions
    ) {
      return;
    }

    if (target === DropdownTarget.none) {

      const hasSelectedValue = !!this._currentValue;
      if (hasSelectedValue) {
        const isEmptyInput = !this.keyword;
        const resetPreviousSelected = this.ndConfig.options && this.ndConfig.options.resetPreviousSelected;
        if (resetPreviousSelected) {
          this.updateKeywordValue(this._currentValue.name);
        } else if (isEmptyInput) {
          const regionSearchEnabled = this.ndConfig.enableRegionSearch;
          const hasRegionSelected = !!this.currentSelectedRegion;
          if (!regionSearchEnabled || (regionSearchEnabled && !hasRegionSelected)) {
            this.updateKeywordValue(this._currentValue.name);
          }
        }
      }
    }
    this._dropdownTarget = target;
  }

  private searchSuggestion(keyword: string) {
    this.currentHoverOption = null;
    if (
      !keyword ||
      keyword.length < this.currentSuggestionTypeaheadOptions.minKeywordLength
    ) {
      this.setDropdownTarget(DropdownTarget.invalid);
      return;
    }
    this._lastSearchKey = keyword;
    this.isShowNoResult = false;
    this.suggestionOptions = [];
    let regionCategories: SuggestionItemCategory[];
    let parentID: string = null;
    if (
      this.searchMode === 'region' &&
      this.ndConfig.enableRegionSearch &&
      this.currentSelectedRegion
    ) {
      parentID = this.currentSelectedRegion.id;
      regionCategories = SuggestionSearchHelper.SUB_REGION_SEARCH_CATEGORIES;
    } else {
      regionCategories = SuggestionSearchHelper.REGION_SEARCH_CATEGORIES;
    }
    const queries = [];

    if ((parentID as any) > 0) {
      const maxCount =
        this.currentSuggestionTypeaheadOptions.maxRegionCount +
        this.currentSuggestionTypeaheadOptions.maxPOICount +
        this.currentSuggestionTypeaheadOptions.maxHotelCount;
      queries.push(
        SuggestionSearchHelper.createSuggestionQueryPayload(
          keyword,
          [],
          maxCount,
          null,
          parentID
        )
      );
    } else {
      if (this.ndConfig.airportMode) {
        queries.push(
          SuggestionSearchHelper.createSuggestionQueryPayload(
            keyword,
            SuggestionSearchHelper.AIRPORT_SEARCH_CATEGORIES,
            this.currentSuggestionTypeaheadOptions.maxAirportCount,
            null,
            this.ndConfig.regionSearchOptions.parentRegionId,
            null
          )
        );
      } else {
        if (this.currentSuggestionTypeaheadOptions.maxRegionCount > 0) {
          queries.push(
            SuggestionSearchHelper.createSuggestionQueryPayload(
              keyword,
              regionCategories,
              this.currentSuggestionTypeaheadOptions.maxRegionCount,
              null,
              this.ndConfig.regionSearchOptions.parentRegionId,
              null
            )
          );
        }
        if (this.currentSuggestionTypeaheadOptions.maxPOICount > 0) {
          queries.push(
            SuggestionSearchHelper.createSuggestionQueryPayload(
              keyword,
              SuggestionSearchHelper.POI_SEARCH_CATEGORIES,
              this.currentSuggestionTypeaheadOptions.maxPOICount,
              null,
              this.ndConfig.regionSearchOptions.parentRegionId,
              null
            )
          );
        }
        if (this.currentSuggestionTypeaheadOptions.maxHotelCount > 0) {
          queries.push(
            SuggestionSearchHelper.createSuggestionQueryPayload(
              keyword,
              SuggestionSearchHelper.HOTEL_SEARCH_CATEGORIES,
              this.currentSuggestionTypeaheadOptions.maxHotelCount,
              null,
              this.ndConfig.regionSearchOptions.parentRegionId,
              this.ndConfig.transferLocations
            )
          );
        }
      }
    }

    this.searchingSuggestion = true;

    ngxHttpCall(suggestionService.multiplSearch(queries)).subscribe({
      next: responses => {
        this.setSuggestiontOption(responses);
        if (!this.suggestionOptions || this.suggestionOptions.length === 0) {
          this.isShowNoResult = true;
        }
        this.setDropdownTarget(DropdownTarget.suggestions);
      },
      error: err => {
        this.suggestionOptions = [];
        this.currentHoverOption = null;
        this._currentSuggestionOptions = [];
        this.isShowNoResult = true;
        this.setDropdownTarget(DropdownTarget.suggestions);
      },
      complete: () => {
        this.searchingSuggestion = false;
      }
    });
  }

  private searchSuggestionByIdForDisplay(
    entityId: string,
    docId: string,
    keywordFallback: string,
    categories: SuggestionItemCategory[]
  ): Observable<SuggestionOption> {
    this.currentHoverOption = null;
    return new Observable(ob => {
      ngxHttpCall(suggestionService
        .search({
          DocID: docId,
          IDs: entityId == null ? null : [entityId],
          Keyword: null,
          ParentID: null,
          Categories: categories == null ? null : categories,
          TransferLocations: null,
          DestinationOnly: false,
          WithHierarchy: true,
          WithMetadata: true,
          WithItemObject: true,
          Language: this.ndConfig.languageType,
          Size: 1,
          PaginationOption: null
        }))
        .subscribe({
          next: resp => {
            this.setSuggestiontOption([resp]);
            if (
              !this.suggestionOptions ||
              this.suggestionOptions.length === 0
            ) {
              this.isShowNoResult = true;
              this.updateKeywordValue(keywordFallback);
              this.setDropdownTarget(DropdownTarget.none);
              ob.next(null);
            } else {
              const data = resp.Data[0];
              this.updateKeywordValue(data.DisplayOption.FullName);
              this.setDropdownTarget(DropdownTarget.none);
              const option = this.convertToOption(data);
              ob.next(option);
            }
            ob.complete();
          },
          error: err => {
            this.suggestionOptions = [];
            this.currentHoverOption = null;
            this._currentSuggestionOptions = [];
            this.isShowNoResult = true;
            this.updateKeywordValue(keywordFallback);
            this.setDropdownTarget(DropdownTarget.none);
            ob.next(null);
            ob.complete();
          }
        });
    }) as Observable<SuggestionOption>;
  }

  private setSuggestiontOption(
    responses: IPaginationWrapper<SuggestionItem>[]
  ): void {
    const items = [];
    const regionItems = [];
    const hotelItems = [];
    const airportItems = [];
    const poiItems = [];
    responses.forEach(response => {
      response.Data.forEach(item => {
        items.push(this.convertToOption(item));
      });
    });

    // 排序?

    items.forEach(item => {
      switch (item.category) {
        case SuggestionItemCategory.Hotel:
          hotelItems.push(item);
          break;
        case SuggestionItemCategory.Airport:
          airportItems.push(item);
          break;
        case SuggestionItemCategory.POI:
        case SuggestionItemCategory.POIS:
          poiItems.push(item);
          break;
        default:
          regionItems.push(item);

      }
    });

    if (
      items.length > 0 &&
      this.currentSuggestionTypeaheadOptions.autoSelectFirst
    ) {
      this.currentHoverOption = [...hotelItems, ...airportItems, ...poiItems, ...regionItems][0];
    }

    this.suggestionOptions = [];
    this._currentSuggestionOptions = [];

    if (hotelItems.length > 0) {
      this._currentSuggestionOptions = this._currentSuggestionOptions.concat(
        hotelItems
      );
      this.suggestionOptions.push({
        type: SuggestionType.hotel,
        options: hotelItems
      });
    }
    if (poiItems.length > 0) {
      this._currentSuggestionOptions = this._currentSuggestionOptions.concat(
        poiItems
      );
      this.suggestionOptions.push({
        type: SuggestionType.viewpoint,
        options: poiItems
      });
    }
    if (regionItems.length > 0) {
      this._currentSuggestionOptions = this._currentSuggestionOptions.concat(
        regionItems
      );
      this.suggestionOptions.push({
        type: SuggestionType.destination,
        options: regionItems
      });
    }
    if (airportItems.length > 0) {
      this._currentSuggestionOptions = this._currentSuggestionOptions.concat(
        airportItems
      );
      this.suggestionOptions.push({
        type: SuggestionType.airport,
        options: airportItems
      });
    }
  }

  private convertToOption(item: SuggestionItem): SuggestionOption {
    let destinationID: string = null;
    let airportCode: string = null;
    let location: GeoCoordinate = null;
    if (item.Category === SuggestionItemCategory.Hotel) {
      destinationID = item.ItemObject.DestinationID;
    } else if (item.Category === SuggestionItemCategory.Airport) {
      airportCode = item.ItemObject.Code;
      destinationID = item.ItemObject.DestinationID;
    }
    if (item.Metadata && item.Metadata.Location) {
      location = item.Metadata.Location;
    }
    const { ParentRegionType } = item.ItemObject;
    const ignoreDrillUp = SuggestionSearchHelper.IGNORE_DRILL_UP_CATEGORIES.includes(ParentRegionType)
      && !SuggestionSearchHelper.SPECIAL_PARENT_ID_FOR_SEARCH.has(item.ParentRegionIDString);
    return {
      id: item.ID,
      // suggestion-typeahead忽略Country级别，此处也忽略
      parentId: ignoreDrillUp ? '' : item.ParentRegionIDString,
      destinationID: destinationID,
      name: SuggestionSearchHelper.getName(item, this.lang),
      nameOld: SuggestionSearchHelper.getOldName(item, this.keyword, this.lang),
      name_en: item.DisplayOption.FullName,
      address: SuggestionSearchHelper.getAddress(item, this.appContext && this.appContext.lang),
      location: location,
      category: item.Category,
      airportCode: airportCode,
      source: item
    };
  }

  private reset() {
    this.searchMode = 'all';
    this.regionOptions = [];
    this.isShowRegionOptions = false;
    this.currentSelectedRegion = null;
    this.suggestionOptions = [];
    this.currentHoverOption = null;
    this.currentSelectedOption = null;
    this.currentSuggestionTypeaheadOptions = this.ndConfig.options;
    this.updateKeywordValue('');
    this.ndChange.emit(null);
  }

  // init
  private initDestination() {
    const payloads: SuggestionQueryPayload[] = [];
    if (this.ndConfig.regionID) {
      payloads.push(
        SuggestionSearchHelper.createSuggestionQueryPayload(
          null,
          SuggestionSearchHelper.REGION_SEARCH_BY_ID_CATEGORIES,
          1,
          [this.ndConfig.regionID]
        )
      );
    }

    if (this.ndConfig.hotelID) {
      payloads.push(
        SuggestionSearchHelper.createSuggestionQueryPayload(
          null,
          SuggestionSearchHelper.HOTEL_SEARCH_CATEGORIES,
          1,
          [this.ndConfig.hotelID]
        )
      );
    }

    if (this.ndConfig.airportCode) {
      const payload4Airport = new SuggestionQueryPayload();
      payload4Airport.Categories =
        SuggestionSearchHelper.AIRPORT_SEARCH_CATEGORIES;
      payload4Airport.DocID = `A-${this.ndConfig.airportCode}`;
      payload4Airport.Size = 1;
      payload4Airport.Language = this.ndConfig.languageType;
      payload4Airport.PaginationOption = { PageNum: -1 };
      payload4Airport.WithMetadata = true;
      payload4Airport.WithHierarchy = true;
      payload4Airport.WithItemObject = true;
      payloads.push(payload4Airport);
    }

    if (payloads.length > 0) {
      ngxHttpCall(suggestionService.multiplSearch(payloads))
        .subscribe(
          responses => {
            let hotelOption: SuggestionOption;
            let regionOption: SuggestionOption;
            let airportOption: SuggestionOption;
            responses.forEach(response => {
              response.Data.forEach(item => {
                const option = this.convertToOption(item);
                if (option.category === SuggestionItemCategory.Hotel) {
                  hotelOption = option;
                  this.currentSelectedOption = hotelOption;
                } else if (option.category === SuggestionItemCategory.Airport) {
                  airportOption = option;
                  this.currentSelectedOption = airportOption;
                } else {
                  regionOption = option;
                  const region = {
                    id: option.id.toString(),
                    name: option.name,
                    name_en: option.name_en,
                    location: option.location,
                    category: item.Category
                  };
                  this.regionOptions.push(region);
                }
              });
              if (hotelOption) {
                const value: SuggestionValue = {
                  id: hotelOption.id.toString(),
                  name: hotelOption.name,

                  name_en: hotelOption.name_en,
                  category: hotelOption.category,
                  destinationID: hotelOption.destinationID.toString(),
                  location: hotelOption.location,
                  isSelected: false,
                  isDeSelected: false
                };
                this._currentValue = value;
                this.ndAfterInit.emit(value);
                this.updateKeywordValue(this._currentValue.name);
              } else if (airportOption) {
                const value: SuggestionValue = {
                  id: airportOption.id.toString(),
                  name: airportOption.name,

                  name_en: airportOption.name_en,
                  category: airportOption.category,
                  destinationID: airportOption.destinationID,
                  location: airportOption.location,
                  isSelected: false,
                  isDeSelected: false
                };
                this._currentValue = value;
                this.ndAfterInit.emit(value);
                this.updateKeywordValue(this._currentValue.name);
              } else if (regionOption) {
                const value: SuggestionValue = {
                  id: regionOption.id.toString(),
                  name: regionOption.name,

                  name_en: regionOption.name_en,
                  category: regionOption.category,

                  location: regionOption.location,
                  keyword: null,
                  isSelected: false,
                  isDeSelected: false
                };
                let keywordToSet = '';
                if (
                  this.ndConfig.keyword &&
                  this.ndConfig.enableRegionSearch
                ) {
                  this.searchMode = 'region';
                  keywordToSet = this.ndConfig.keyword;
                  value.keyword = this.ndConfig.keyword;
                  this.currentSuggestionTypeaheadOptions = this.ndConfig.regionSearchOptions;
                  this.currentSelectedRegion = this.regionOptions[0];
                } else {
                  keywordToSet = value.name;
                }
                this._currentValue = value;
                this.updateKeywordValue(keywordToSet);
                this.ndAfterInit.emit(value);
              }
            });
          });
    }
  }

  private initCities() {
    if (!this.tabsCascaderConfig) {
      this.tabsCascaderConfig = new DidaTabsCascaderConfig();
    }

    this.tabsCascaderConfig.options = [];
    this.tabsCascaderSelectedOptions = [];

    if (this.ndConfig.languageType === 'en-US') {
      this.tabsCascaderConfig.width = 950;
    } else {
      this.tabsCascaderConfig.width = 900;
    }
    const temp: { [key: string]: any[] } = {};

    CITIES.forEach(city => {
      if (temp[city.continentID] === undefined) {
        temp[city.continentID] = [];
      }
      temp[city.continentID].push(city);
    });

    const topCityOption: IDidaTabsCascaderOption = {
      label: this.ndConfig.languageType === 'en-US' ? 'Top Cities' : '热门',
      value: new Region(
        '',
        SuggestionItemCategory.Continent,
        '热门',
        'Top Cities',
        null,
        0
      ),
      children: [],
      order: -1
    };

    this.tabsCascaderConfig.options.push(topCityOption);
    this.tabsCascaderSelectedOptions.push(topCityOption.value);

    const hotSearchCitySortDict = HOT_SEARCH_CITY_ID_LIST.reduce((a, v, i) => {
      a[v] = i;
      return a;
    }, {});

    CONTINENTS.forEach(continent => {
      const parent: IDidaTabsCascaderOption = {
        label: continent.name[this.ndConfig.languageType],
        value: continent,
        children: [],
        order: continent.order
      };
      temp[continent.id].forEach(city => {
        const child = {
          label: city.name[this.ndConfig.languageType],
          value: city,
          order: city.order
        };
        if (HOT_SEARCH_CITY_ID_LIST.includes(city.id)) {
          topCityOption.children.push({ ...child, order: hotSearchCitySortDict[city.id] });
        }
        parent.children.push(child);
      });
      this.tabsCascaderConfig.options.push(parent);
    });

    // sort
    this.tabsCascaderConfig.options = this.sortOptions(this.tabsCascaderConfig.options);
  }

  private initAirports() {
    if (!this.tabsCascaderConfig) {
      this.tabsCascaderConfig = new DidaTabsCascaderConfig();
    }
    this.tabsCascaderConfig.groupMode = true;

    this.tabsCascaderConfig.options = [];
    this.tabsCascaderSelectedOptions = [];

    if (this.ndConfig.languageType === 'en-US') {
      this.tabsCascaderConfig.width = 800;
    } else {
      this.tabsCascaderConfig.width = 680;
    }
    this.tabsCascaderConfig.height = this.tabsCascaderConfig.width * 0.37456;

    const continent2Regions: { [key: string]: Region[] } = {};
    const city2Airports: { [key: string]: Airport[] } = {};

    AIRPORTS.forEach(airport => {
      if (city2Airports[airport.region.id] === undefined) {
        city2Airports[airport.region.id] = [];
      }
      city2Airports[airport.region.id].push(airport);
    });

    AIRPORT_CITIES.forEach(city => {
      if (city2Airports[city.id] !== undefined) {
        if (continent2Regions[city.continentID] === undefined) {
          continent2Regions[city.continentID] = [];
        }
        continent2Regions[city.continentID].push(city);
      }
    });

    const hotSearchCitySortDict = AIRPORTS_HOT_SEARCH_CITY_ID_LIST.reduce((a, v, i) => {
      a[v] = i;
      return a;
    }, {});

    const topCityOption: IDidaTabsCascaderOption = {
      label: this.ndConfig.languageType === 'en-US' ? 'Top Cities' : '热门',
      value: new Region(
        '',
        SuggestionItemCategory.Continent,
        '热门',
        'Top Cities',
        null,
        0
      ),
      children: [],
      order: -1
    };

    this.tabsCascaderConfig.options.push(topCityOption);
    this.tabsCascaderSelectedOptions.push(topCityOption.value);

    CONTINENTS.forEach(continent => {
      const parent: IDidaTabsCascaderOption = {
        label: continent.name[this.ndConfig.languageType],
        value: continent,
        children: [],
        order: continent.order
      };
      if (continent2Regions[continent.id] !== undefined) {
        continent2Regions[continent.id].forEach(city => {
          const cityChildren: IDidaTabsCascaderOption[] = city2Airports[
            city.id
            ].map(airport => {
            return {
              label: airport.name[this.ndConfig.languageType],
              value: airport,
              children: null,
              order: airport.order
            };
          });

          const child: IDidaTabsCascaderOption = {
            label: city.name[this.ndConfig.languageType],
            value: city,
            children: cityChildren,
            order: (!cityChildren || cityChildren.length === 0) ?
              9999 : Math.floor(cityChildren.reduce((accu, item) => accu + item.order, 0) / cityChildren.length)
          };

          if (AIRPORTS_HOT_SEARCH_CITY_ID_LIST.includes(city.id)) {
            topCityOption.children.push({ ...child, order: hotSearchCitySortDict[city.id] });
          }

          parent.children.push(child);
        });
      }
      this.tabsCascaderConfig.options.push(parent);
    });

    // sort
    this.tabsCascaderConfig.options = this.sortOptions(this.tabsCascaderConfig.options);
  }

  private sortOptions(options: IDidaTabsCascaderOption[]): IDidaTabsCascaderOption[] {
    let sorted = options;
    if (options && options.length > 1) {
      options.forEach(o => {
        o.children = this.sortOptions(o.children);
      });
      sorted = options.sort((a, b) => a.order - b.order);
    }
    return sorted;
  }

  private initGlobalEventHandle() {
    this.subscriptions['_appEventHub'] = (this._appEventHub.events.pipe(
      filter(event => event instanceof ApplicationLanguageChangeEvent)
    ) as Observable<ApplicationLanguageChangeEvent>).subscribe({
      next: (event) => {
        this.ndConfig.languageType = event.lang;
        this.initDefaultOptions();
        this.reset();
      }
    });
    // this._contextService.subscribe(({ type: eventType, data }) => {
    //   if (eventType === GlobalMessageTypeEnum.LanguageChange) {
    //
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    const configChange = changes.ndConfig;
    if (configChange) {
      const currentValue: DidaSuggestionTypeaheadConfig = configChange.currentValue;
      const previousValue: DidaSuggestionTypeaheadConfig = configChange.previousValue;
      if ((currentValue && currentValue.airportMode) !== (previousValue && previousValue.airportMode)) {
        this.initDefaultOptions();
      }
    }
  }

  private updateKeywordValue(keyword: string) {
    this.keyword = keyword;
    this.keyword_tag_position_left = this.calculateKeywordTagPositionLeft(keyword);
  }

  private calculateKeywordTagPositionLeft(keyword: string): number {
    if (this._keywordLengthDetector && this._keywordInputElement) {
      const inputElWidth = this._keywordInputElement.nativeElement.clientWidth;
      this._keywordLengthDetector.innerHTML = keyword || '';
      const width = this._keywordLengthDetector.clientWidth;
      const positionLeft = Math.min(width + 4, inputElWidth - 56);
      console.log(`keyword length: ${width}, left: ${positionLeft}`);
      return positionLeft;
    }
    return 0;
  }

  get showSearchKeywordTag(): boolean {
    if (this.ndConfig.showInputRegionTag && this.isCN) {
      if (this._keywordInputStatus === 'focus') {
        return false;
      }
      if (this._currentValue && this._currentValue.category) {
        switch (this._currentValue.category) {
          case SuggestionItemCategory.MultiCity:
          case SuggestionItemCategory.MultiRegion:
          case SuggestionItemCategory.HighLevelRegion:
          case SuggestionItemCategory.Province:
          case SuggestionItemCategory.Country:
            return this._currentValue.name === this.keyword;
          default:
            return false;
        }
      }
    }
    return false;
  }
}
