import { Directive, Input, HostBinding } from '@angular/core';

let nextInstanceId = 0;

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'dida-form-error'
})
export class DidaFormErrorDirective {

    @Input() id = `didaFormError#${nextInstanceId++}`;
    
    @HostBinding('attr.id') _id = this.id;

    @HostBinding('class') class = 'dida-form-error';

}
