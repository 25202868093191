import { IEventModelBase } from './event-base.model';
import { FeedbackEventTypeEnum } from '../enums';
import { FeedbackSubTypeEnum, FeedbackTypeEnum } from '@dida-shopping/dida-services/cs-ticket';


export abstract class FeedbackEventBase implements IEventModelBase<FeedbackEventTypeEnum> {

  protected constructor(
    public readonly type: FeedbackEventTypeEnum,
    public readonly source: string,
    public readonly time: Date = null
  ) {
    if (!time) {
      time = new Date();
    }
  }
}

export class FeedbackOpenDialogEvent extends FeedbackEventBase {
  constructor(
    public readonly feedbackType: FeedbackTypeEnum,
    public readonly feedbackSubType: FeedbackSubTypeEnum | null,
    source: string,
    time: Date
  ) {
    super(FeedbackEventTypeEnum.OpenFeedbackDialog, source, time);
  }
}
