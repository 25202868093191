import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ComponentBase } from '@dida-shopping/ngx-dida-site-core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

export const DIDA_STATIC_DOCUMENT_DIR = '//dsimage.didatravel.com/doc/';

let ND_MARKDOWN_INST_COUNTER = 0;

@Component({
  selector: 'nd-markdown-doc',
  template: `
    <ng-container *ngIf="ndShowLoading">
      <nd-loading *ngIf="loading$ | async" [scale]="0.3"></nd-loading>
    </ng-container>
    <markdown [src]="url$ | async" [lang]="lang" (load)="onMdDocLoad($event)"></markdown>
  `,
  styleUrls: ['./md-doc.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DidaUITKMarkdownDocumentComponent extends ComponentBase implements OnInit {
  // previousTitle: string;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  url$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  version = + new Date();

  @Input() set filename(value: string) {
    this.url$.next(this.buildUrl(value));
    this.loading$.next(true);
  };

  @Input() ndSyncTitle: boolean;
  @Input() ndTitleSuffix: string;
  @Input() ndShowLoading = true;

  @Output() ndDocLoaded: EventEmitter<{ src: string, title: string }> = new EventEmitter<{src: string, title: string}>();

  @HostBinding('class') className = 'nd-markdown-doc';
  @HostBinding('attr.id') instId = `nd-markdown-doc-inst_${ND_MARKDOWN_INST_COUNTER++}`;

  constructor(
    private title: Title,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    // this.previousTitle = this.title.getTitle();
  }

  onMdDocLoad(src: string){
    const titleElement = document.querySelector('markdown title');
    let title = titleElement && titleElement.textContent.trim();
    this.ndDocLoaded.emit({ src, title });
    if (this.ndSyncTitle) {
      if (title) {
        if (this.ndTitleSuffix) {
          title = `${title}${this.ndTitleSuffix}`;
        }
        this.title.setTitle(title);
      }
    }
    this.loading$.next(false);
  }

  private buildUrl(value: string): string {
    if (value.startsWith('//') || /[\w]+:\/\//.test(value)) {
      return value;
    }
    return `${DIDA_STATIC_DOCUMENT_DIR}${value}_${this.lang}.md?${this.version}`;
  }
}
