import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

    @Input()
    max = 99;
    @Input()
    value = 0;

    @Input()
    mode: 'default' | 'round' = 'default';

    @HostBinding('class.badge-round')
    get badgeRound() {
        return this.mode === 'round';
    }

    @HostBinding('class.badge')
    hostClass = true;

    @HostBinding('class.hide')
    get hideBadge() {
        return !this.value;
    }

    ngOnInit() {

    }


    constructor() { }

    get displayText() {

        if (!this.value) {
            this.value = 0;
        }
        if (this.value > this.max) {
            return this.max + '+';
        } else if (this.value !== 0) {
            return this.value + '';
        } else {
            return '';
        }
    }

}
