import { IApplicationRouteRule } from './models';
import { ISiteRouteRuleModel } from '@dida-shopping/dida-services/config';

export class RouteRuleHelper {
  static extractApplicationRouteRules(siteRoutingRules: ISiteRouteRuleModel[]): IApplicationRouteRule[] {
    let rules: IApplicationRouteRule[] = [];
    if (siteRoutingRules) {
      rules = siteRoutingRules.map<IApplicationRouteRule>(r => {
        const item: IApplicationRouteRule = {
          pattern: r.Pattern,
          mode: r.Mode as any,
          matchNegative: r.MatchNegative,
          isExternal: !!r.IsExternal,
        };
        if (r.Type === 'regex') {
          item.pattern = new RegExp(r.Pattern, 'i');
        }
        return item;
      });
    }

    // sort routing rules, make sure external url match rules will be execute first.
    rules = rules.sort((a, b) =>  {
      if (a.isExternal === b.isExternal) {
        return 0;
      }
      return a.isExternal > b.isExternal ? -1 : 1;
    });

    // console.log(rules);

    return rules;
  }
}
