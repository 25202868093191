import { IRatePlanPriceSearchOption } from '@dida-shopping/dida-services/rateplan';
import { DateTimeHelper } from '@dida-shopping/dida-services/common';
import { GeoCoordinate } from '@dida-shopping/dida-services/geography';
import { HttpServiceBase } from '../http';
import { HotelPriceSearchOptions, PriceSearchResultModel, HotelRatePlanPriceModel, IHotelChainModel } from './models';

/**
 * 价格数据相关接口
 *
 * @export
 * @class RateSearchService
 */
class RateSearchService extends HttpServiceBase {
  constructor() {
    super();
  }

  /**
   * 获取酒店价格
   *
   * @param {HotelPriceSearchOptions} options 酒店价格查询参数
   * @param {string} bid 当SupplierMask开启时必填 See: DidaShopping.Models.Auth.DidaApplicationUserContextModel --> BatchID
   * @param {string[]} sourceMaskList 当SupplierMask开启时必填
   * @param {boolean} [rec=null] 是否需要统计区域价格搜索次数（默认为`否`）
   * @returns {IAsyncOperation<PriceSearchResultModel>}
   * @memberof RateSearchService
   */
  searchHotelPrice(options: HotelPriceSearchOptions,
                   bid: string, sourceMaskList: string[], rec: boolean = null) {
    const tempst: any = DateTimeHelper.format(
      options.CheckInDate,
      DateTimeHelper.DEFAULT_DATE_FORMAT
    );
    return this.httpClientWrapper.post<PriceSearchResultModel>(
      '/HotelPriceAPI/Search?' + '_d=' + tempst,
      {
        data: options,
        rec: rec,
        bid: bid,
        sourceMaskList: sourceMaskList
      }
      , {
        resultProcessor: (mdl: PriceSearchResultModel) => {
          mdl.HotelPriceList.forEach(price => {
            this.normalizeHotelRatePlanPrice(price);
          });
          const result = Object.assign({
            Data: mdl.HotelPriceList,
            TotalAmount: mdl.TotalCount,
            PageNum: options.PageNum,
            PageSize: options.CountPerPage
          }, mdl)
          return result;
        },
        payloadProcessor: (payload) => {
          const option: HotelPriceSearchOptions = payload.data;
          const inDateStr: any = DateTimeHelper.format(option.CheckInDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
          option.CheckInDate = inDateStr;
          const outDateStr: any = DateTimeHelper.format(option.CheckOutDate, DateTimeHelper.DEFAULT_DATE_FORMAT);
          option.CheckOutDate = outDateStr;
          if (option.PriceSearchFilter && option.PriceSearchFilter.Location) {
            if (options.PriceSearchFilter.Location instanceof GeoCoordinate) {
              option.PriceSearchFilter.Location = options.PriceSearchFilter.Location.serviceCoordinate;
            }
          }
          return payload;
        }
      }
    );
  }

  /**
   * 获取酒店品牌
   * @param {HotelPriceSearchOptions} options
   * @returns {IAsyncOperation<IHotelChainModel[]>}
   * @memberof RateSearchService
   */
  searchHotelChains(options: HotelPriceSearchOptions) {
    return this.httpClientWrapper.post<IHotelChainModel[]>(
      '/HotelApi/GetHotelChainList',
      {
        options: options
      },
      {
        payloadProcessor: (payload: { options: HotelPriceSearchOptions }) => {
          const option = payload.options;
          if (option.PriceSearchFilter && option.PriceSearchFilter.Location) {
            if (options.PriceSearchFilter.Location instanceof GeoCoordinate) {
              option.PriceSearchFilter.Location = options.PriceSearchFilter.Location.serviceCoordinate;
            }
          }
          return payload;
        }
      }
    );
  }

  /**
   * 酒店实时查询监控
   * https://tower.im/teams/191049/todos/61481/
   */
  searchMonitor(
    options: HotelPriceSearchOptions
  ) {
    const tempst: any = DateTimeHelper.format(
      options.CheckInDate,
      DateTimeHelper.DEFAULT_DATE_FORMAT
    );
    return this.httpClientWrapper.post<PriceSearchResultModel>(
      '/HotelPriceAPI/SearchMonitor?' + '_d=' + tempst,
      {
        data: options
      }
    );
  }

  /**
   * 获取RatePlan实时价格
   *
   * @param {IRatePlanPriceSearchOption} options 价格查询参数
   * @returns {HttpObservable<PriceSearchResultModel>}
   * @memberof RateSearchService
   */
  searchRatePlanPrice(options: IRatePlanPriceSearchOption) {
    return this.httpClientWrapper.post<PriceSearchResultModel>('/HotelPriceAPI/GetRatePlanPrice', {
        options: options
      }, {
        resultProcessor: (mdl) => {
          mdl.HotelPriceList.forEach(price => {
            this.normalizeHotelRatePlanPrice(price);
          });
          return mdl;
        },
        payloadProcessor: (payload: IRatePlanPriceSearchOption) => {
          // in case of carrying the time zone info, use date string with `yyyy-MM-dd` format to send the request.
          if (payload.CheckIn instanceof Date) {
            payload.CheckIn = DateTimeHelper.format(
              DateTimeHelper.getDate(payload.CheckIn),
              DateTimeHelper.DEFAULT_DATE_FORMAT
            );
          }
          if (payload.CheckOut instanceof Date) {
            payload.CheckOut = DateTimeHelper.format(
              DateTimeHelper.getDate(payload.CheckOut),
              DateTimeHelper.DEFAULT_DATE_FORMAT
            );
          }
          return payload;
        }
      }
    );
  }

  private normalizeHotelRatePlanPrice(price: HotelRatePlanPriceModel) {

    if (!price.RatePlanPriceList) {
      price.RatePlanPriceList = [];
      return;
    }
    price.RatePlanPriceList.forEach(ratePlanPrice => {
      ratePlanPrice.RateList.forEach(r => {
        r.StayDate = DateTimeHelper.parse(r.StayDate, true);
      });
      ratePlanPrice.CancellationList.forEach(c => {
        c.FromDate = DateTimeHelper.parse(c.FromDate, true);
      });
      ratePlanPrice.CancellationList.sort((a, b) => {
        return a.FromDate.getTime() - b.FromDate.getTime();
      });
      if((ratePlanPrice.RatePlan.DidaRoomTypeID || 0) <= 0) {
        ratePlanPrice.RatePlan.DidaRoomTypeID = null
      }
      const extraInfoString = ratePlanPrice.RatePlan.ExtraInfoIDList as any || '';
      ratePlanPrice.RatePlan.ExtraInfoIDList = extraInfoString.split('|').map(item => parseInt(item, 10));
    });
  }

}

export const rateSearchService = new RateSearchService();
