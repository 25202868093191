import {
  Component,
  OnInit,
  HostBinding,
  Input,
  HostListener,
  ElementRef,
  Renderer2,
  Inject,
  Self,
  Optional,
  OnDestroy,
  OnChanges,
  DoCheck,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  Directive,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  isDevMode,
  TemplateRef
} from '@angular/core';
import {
  NgControl,
  NgForm,
  FormGroupDirective,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from '@angular/forms';
import { DidaFormGroupControl } from '../dida-form-group/dida-form-group-control';
import { coerceBooleanProperty } from '../../../core/coercion/boolean-property';
import { DidaFormErrorStateMatcher, DidaSelectFormErrorStateMatcher } from '../dida-form-error-options';
import { DIDA_INPUT_VALUE_ACCESSOR } from '../dida-input/dida-input-value-accessor';
import { DidaInputDirective } from '../dida-input/dida-input.directive';
import { DidaFormGroupComponent } from '../dida-form-group/dida-form-group.component';
import {
  DIDA_SELECT_OPTION_PARENT_COMPONENT,
  DidaSelectOptionComponent,
  DidaSelectOptionSelectionChangeEvent
} from './dida-select-option/dida-select-option.component';

import { merge, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, map, startWith, takeUntil } from 'rxjs/operators';
import { BsDropdownDirective, BsDropdownMenuDirective, BsDropdownState } from 'ngx-bootstrap/dropdown';
import { LoggerService } from '../../../core/logger/logger.service';
import { GlobalSelectionEventControl } from '../../../core/controls/global-selection-event-controls';
import { StringTemplateOutletDirective } from '../../directives/directives';

let nextInstanceId = 0;

export class DidaSelectChangeEvent {
  constructor(public source: DidaSelectComponent, public value: any) {}
}

@Component({
  selector: 'dida-select',
  exportAs: 'didaSelect',
  templateUrl: './dida-select.component.html',
  styleUrls: ['./dida-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DidaFormGroupControl, useExisting: DidaSelectComponent },
    {
      provide: DIDA_SELECT_OPTION_PARENT_COMPONENT,
      useExisting: DidaSelectComponent
    }
  ],
  preserveWhitespaces: true
})
export class DidaSelectComponent
  implements
    DidaFormGroupControl<any>,
    ControlValueAccessor,
    AfterViewInit,
    AfterContentInit,
    OnDestroy,
    OnChanges,
    DoCheck,
    OnInit {
  // getter and setter storage
  protected _type = 'text';
  protected _disabled = false;
  protected _required = false;
  protected _id: string;
  protected _instanceId = `didaSelect#${nextInstanceId++}`;
  protected _prevNativeValue: any;
  protected _value: any;
  /**
   * 单选模式时的值
   */
  private _selectedOption: DidaSelectOptionComponent;
  /**
   * 多选模式时的值
   */
  private _multSelectedOptions: DidaSelectOptionComponent[] = [];

  private _readonly = false;
  private _focused = false;

  private _selectEventSubscription: Subscription;

  protected _placeholder: string | TemplateRef<void> = '';

  @HostBinding('class.size_sm')
  get bindFormGroupSizeSm() {
    return this.size === 'sm';
  }

  @HostBinding('class.size_lg')
  get bindFormGroupSizeLg() {
    return this.size === 'lg';
  }

  @Input() size = '';
  @Input() align: 'left' | 'right';

  @Input() container = 'body';

  @Input() active = false;

  @Input() border = false;

  @Input() multiple = false;

  @Input() customTemplate: TemplateRef<{
    $implicit: DidaSelectOptionComponent | DidaSelectOptionComponent[];
  }>;

  // ngControl: NgControl;

  @ViewChild(BsDropdownDirective, { static: true }) _ngxDropdownChild: BsDropdownDirective;

  @ContentChildren(DidaSelectOptionComponent, { descendants: true })
  _options: QueryList<DidaSelectOptionComponent>;

  @Output() selectionChange: EventEmitter<DidaSelectChangeEvent> = new EventEmitter<DidaSelectChangeEvent>();
  @Output() change: EventEmitter<DidaSelectChangeEvent> = new EventEmitter<DidaSelectChangeEvent>();

  @Output() valueChange = new EventEmitter<any>();

  @Output() blur = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();

  // define input props
  /**
   * 是否处于关注状态
   *
   * @memberof DidaSelectComponent
   */
  get focused(): boolean {
    return this.opened || this._focused;
  }

  /**
   *
   *
   * @memberof DidaSelectComponent
   */
  opened = false;

  /**
   * 是否处于错误状态
   *
   * @memberof DidaSelectComponent
   */
  errorState = false;

  errorLevel: 'warning' | 'danger';

  /**
   * 表单错误状态的定义
   *
   * @type {DidaSelectFormErrorStateMatcher}
   * @memberof DidaInputDirective
   */
  @Input() errorStateMatcher: DidaSelectFormErrorStateMatcher;

  /**
   * 状态变更的订阅源
   *
   * @memberof DidaSelectComponent
   */
  stateChanges = new Subject<void>();

  private _destroy = new Subject<void>();

  controlType = 'dida-select';

  /**
   * 是否为禁用状态
   *
   * @readonly
   * @memberof DidaSelectComponent
   */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  get id() {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._instanceId;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value: string | TemplateRef<void>) {
    this._placeholder = value;
  }

  // placeholder = '';

  /**
   * 是否为必填项
   *
   * @readonly
   * @memberof DidaSelectComponent
   */
  @HostBinding('class.dida-select_required')
  @Input()
  get required() {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * input的值
   *
   * @readonly
   * @type {*}
   * @memberof DidaInputDirective
   */
  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (newValue !== this._value) {
      this.writeValue(newValue);
      // this._value = newValue;
    }
  }

  /**
   * 是否为只读
   *
   * @readonly
   * @memberof DidaInputDirective
   */
  @HostBinding('class.dida-select_readonly')
  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value);
  }

  onTouched: () => any = () => {};

  private _controlValueAccessorChangeFn: (value: any) => void = () => {};

  @HostListener('blur') _onBlur() {
    this._focusChanged(false);
  }
  @HostListener('focus') _onFocus() {
    this._focusChanged(true);
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (this.multiple) {
      const selector = this._elementRef.nativeElement.querySelector('[selector="selector"]');
      if (this.opened && !selector.contains(event.target) && !this._ngxDropdownChild._contains(event)) {
        this.close();
      }
    }
  }

  constructor(
    private logger: LoggerService,
    protected _elementRef: ElementRef,
    protected _renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef,
    private _defaultErrorStateMatcher: DidaSelectFormErrorStateMatcher,
    @Optional() protected _parentForm: NgForm,
    @Optional() protected _parentFormGroup: FormGroupDirective,
    @Optional() private _parentFormField: DidaFormGroupComponent,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // 如果没有定义值存储方法，则使用原生元素作为只存储方案
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this._prevNativeValue = this.value;

    // 强制触发setter
    this.id = this.id;

    this._selectEventSubscription = GlobalSelectionEventControl.selectionEventSubject.subscribe(event => {
      if (event.id !== this.id && event.visible && this.opened) {
        this.close();
      }
    });
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    if (this._selectEventSubscription) {
      this._selectEventSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {}

  ngAfterContentInit() {
    this._options.changes
      .pipe(
        startWith(null),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this._options.forEach(o => {
          o.setMultiple(this.multiple);
        });
        this._resetOptions();
        this._initializeSelection();
      });
  }

  ngDoCheck() {
    if (this.ngControl) {
      this._updateErrorState();
    } else {
      this._dirtyCheckNativeValue();
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (this._options) {
      this._setSelectionByValue(value);
    }
  }

  onContainerClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.opened) {
      this.open();
    }
  }

  toggle(): void {
    this.opened ? this.close() : this.open();
  }

  open(): void {
    if (this.disabled || !this._options || !this._options.length) {
      return;
    }
    this._ngxDropdownChild.show();
    this._changeDetectorRef.markForCheck();
  }

  close(): void {
    this._ngxDropdownChild.hide();
    this._changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    this._ngxDropdownChild.onHidden.subscribe(() => {
      this.blur.emit({
        target: this._elementRef.nativeElement,
        type: 'blur'
      });
    });

    this._ngxDropdownChild.onShown.subscribe(() => {
      this.focus.emit({
        target: this._elementRef.nativeElement,
        type: 'focus'
      });
    });
  }

  dropdownVisibilityChange(visible: boolean) {
    this.opened = visible;
    this.logger.debug(this._ngxDropdownChild);
    this._changeDetectorRef.markForCheck();
    if (
      visible &&
      this._parentFormField &&
      (this._parentFormField.bindFormGroupSizeSm || this._parentFormField.bindFormGroupSizeLg)
    ) {
      window.setTimeout(() => {
        let sizeClass = this._parentFormField.bindFormGroupSizeLg ? 'size_lg' : 'size_sm';
        let menuEl = document.getElementById(this.dropdownMenuId);
        if (menuEl) {
          this._renderer.removeClass(menuEl, 'size_lg');
          this._renderer.removeClass(menuEl, 'size_sm');
          this._renderer.addClass(menuEl, sizeClass);
        }
      }, 0);
    }
    if (!visible) {
      this.onTouched();
    }
    GlobalSelectionEventControl.selectionEventSubject.next({
      id: this.id,
      visible: visible
    });
  }

  protected _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && !this.readonly) {
      this._focused = isFocused;
      this.stateChanges.next();
    }
  }

  protected _updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = this.errorStateMatcher || this._defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as FormControl) : null;
    const newState = matcher.isErrorState(control, parent, this.opened);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  protected _dirtyCheckNativeValue() {
    const newValue = this.value;
    if (this._prevNativeValue !== newValue) {
      this._prevNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  protected get _isBadInput() {
    let validity = (this._elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }

  private _initializeSelection(): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    Promise.resolve().then(() => {
      this._setSelectionByValue(this.ngControl ? this.ngControl.value : this._value);
    });
  }

  private _resetOptions(): void {
    this.optionSelectionChanges
      .pipe(
        takeUntil(merge(this._destroy, this._options.changes)),
        filter(event => event.isUserInput)
      )
      .subscribe(event => {
        this._onSelect(event);
        if (!this.multiple) {
          this.close();
        }
      });
  }

  private _onSelect(event: DidaSelectOptionSelectionChangeEvent): void {
    const option = event.source;
    if (this.multiple) {
      const index = this._multSelectedOptions.findIndex(x => x === option);
      if (index > -1) {
        const removeOption = this._multSelectedOptions.splice(index, 1)[0];
      } else {
        this._multSelectedOptions.push(option);
      }
      this.stateChanges.next();
      this._propagateChanges(event);
    } else {
      const wasSelected = option === this._selectedOption;
      this._clearSelection(option.value == null ? undefined : option);

      if (option.value == null) {
        this._propagateChanges(event);
      } else {
        this._selectedOption = option;
        this.stateChanges.next();
      }

      if (this._selectedOption && wasSelected !== this._selectedOption.selected) {
        this._propagateChanges(event);
      }
    }
  }

  private _clearSelection(skip?: DidaSelectOptionComponent): void {
    this._options.forEach(option => {
      if (option !== skip) {
        option.deselect();
      }
    });
    this.stateChanges.next();
  }

  private _propagateChanges(event: DidaSelectOptionSelectionChangeEvent): void {
    let valueToEmit: any = null;

    if (this.multiple) {
      if (this._multSelectedOptions && this._multSelectedOptions.length > 0) {
        valueToEmit = this._multSelectedOptions.map(o => o.value);
      }
    } else {
      valueToEmit = this._selectedOption ? this._selectedOption.value : event.source.value;
    }

    this._value = valueToEmit;
    this._controlValueAccessorChangeFn(valueToEmit);
    this.selectionChange.emit(new DidaSelectChangeEvent(this, valueToEmit));
    this.valueChange.emit(valueToEmit);
    if (event.isViaInteraction) {
      this.change.emit(new DidaSelectChangeEvent(this, valueToEmit));
    }
    this._changeDetectorRef.markForCheck();
  }

  private _setSelectionByValue(value: any | any[], isUserInput = false): void {
    this._clearSelection();
    if (this.multiple) {
      this._selectMultValue(value, isUserInput);
    } else {
      this._selectValue(value, isUserInput);
    }

    this._changeDetectorRef.markForCheck();
  }

  private _selectValue(value: any, isUserInput = false): DidaSelectOptionComponent {
    const correspondingOption = this._options.find((option: DidaSelectOptionComponent) => {
      try {
        // Treat null as a special reset value.
        return option.value != null && option.value === value;
      } catch (error) {
        if (isDevMode()) {
          // Notify developers of errors in their comparator.
          console.warn(error);
        }
        return false;
      }
    });

    if (correspondingOption) {
      isUserInput || !this.opened ? correspondingOption.selectViaInteraction(null) : correspondingOption.select();
      this._selectedOption = correspondingOption;
      this.stateChanges.next();
    }

    return correspondingOption;
  }

  private _selectMultValue(value: any[], isUserInput = false) {
    let correspondingOptions: DidaSelectOptionComponent[] = [];
    if (value && value.length > 0) {
      correspondingOptions = this._options.filter(o => {
        try {
          return value.indexOf(o.value) > -1;
        } catch (error) {
          if (isDevMode()) {
            // Notify developers of errors in their comparator.
            console.warn(error);
          }
          return false;
        }
      });
    }
    this._multSelectedOptions = correspondingOptions;
    if (correspondingOptions.length > 0) {
      correspondingOptions.forEach(correspondingOption => {
        isUserInput || !this.opened
          ? correspondingOption.selectViaInteraction(null, true)
          : correspondingOption.select();
      });
      this.stateChanges.next();
    }
    return correspondingOptions;
  }

  get dropdownMenuId(): string {
    return `dropdownMenu@${this.id}`;
  }

  get dropdownMenuWidth(): string {
    let el = this._elementRef.nativeElement as HTMLElement;
    let width = el.getBoundingClientRect().width;
    return `${width}px`;
  }

  get hasValue(): boolean {
    return this.multiple ? this._multSelectedOptions.length > 0 : this._selectedOption != null;
  }

  get selected(): DidaSelectOptionComponent | DidaSelectOptionComponent[] {
    return !this.multiple ? this._selectedOption : this._multSelectedOptions;
  }

  get showFallback(): boolean {
    return !this.multiple && !this._selectedOption && this.triggerValue === '';
  }

  get shouldShowPlaceholder(): boolean {
    return !this.customTemplate && (this.empty || !this.hasValue);
  }

  get empty(): boolean {
    const valueEmpty = this.value == null;
    const isValidInput = !this._isBadInput;
    return valueEmpty && isValidInput;
  }

  get optionSelectionChanges(): Observable<DidaSelectOptionSelectionChangeEvent> {
    return merge(...this._options.map(option => option.onSelectionChange));
  }

  get triggerValue(): string | TemplateRef<void> {
    if (this.placeholder !== '' && this.empty) {
      return this.placeholder;
    } else if (this.empty || !this.hasValue) {
      return this.placeholder;
    }
    if (this.multiple) {
      return this._multSelectedOptions.map(x => x.viewValue).join('/');
    } else {
      return this._selectedOption.viewValue;
    }
  }
}
