import {
  Directive, DoCheck, Host, OnInit, OnDestroy,
  Input, TemplateRef, ViewContainerRef,
  ElementRef, HostListener, Renderer2,
} from '@angular/core';

const STYLE_SELECTOR = 'nd-box-shadow';
const STYLE_ACTIVE_SELECT = `${STYLE_SELECTOR}_active`;

/**
 * 边框阴影指令
 *
 * 使用该指令让所在元素获取`:hover`时的动态阴影效果
 *
 * @author Roman Lo
 *
 * @example
 * 在所处元素上加上`ndBoxShadow`指令：
 * <div class="list-item" ndBoxShadow>A</div>
 */
@Directive({
  selector: '[ndBoxShadow]'
})
export class DidaUITKBoxShadowDirective implements OnInit, OnDestroy {

  @Input() showAlways: boolean;

  @Input() shadowDisabled: boolean;

  @HostListener('mouseenter') onMouseEnter() {
    this.showShadow(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.showAlways) {
      return;
    }
    this.showShadow(false);
  }

  constructor(private element: ElementRef,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.addClass(this.element.nativeElement, STYLE_SELECTOR);
    if (this.showAlways) {
      this.showShadow(true);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.element.nativeElement, STYLE_SELECTOR);
    this.renderer.removeClass(this.element.nativeElement, STYLE_ACTIVE_SELECT);
  }

  private showShadow(display: boolean) {
    if (this.shadowDisabled) {
      this.renderer.removeClass(this.element.nativeElement, STYLE_ACTIVE_SELECT);
      return;
    }
    if (display) {
      this.renderer.addClass(this.element.nativeElement, STYLE_ACTIVE_SELECT);
    } else {
      this.renderer.removeClass(this.element.nativeElement, STYLE_ACTIVE_SELECT);
    }
  }
}
