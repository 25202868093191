<nz-modal
  [nzFooter]="popupMessageModalFooter"
  [nzTitle]="popupMessageList[popupMsgIndex]?.Title"
  (nzOnCancel)="popupMessageModal.close()"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  nzClassName="nd-navbar-notice-modal"
  #popupMessageModal
>
  <nz-carousel
    #popupMessageCarousel
    [nzEnableSwipe]="false"
    [nzAutoPlay]="false"
    (nzAfterChange)="msgSlideChange($event)"
    [nzDots]="popupMessageList.length > 1"
    style="max-height: 300px;"
  >
    <div nz-carousel-content *ngFor="let item of popupMessageList">
      <!-- {{item |json}} -->
      <markdown [data]="item.Content" class="ck-content"></markdown>
    </div>
  </nz-carousel>
  <!-- <div style="height:300px">
</div> -->

  <ng-template #popupMessageModalFooter>
    <div class="nd-text-right" *ngIf="popupMessageList.length > 0">
      <ng-container *ngIf="popupMsgIndex > 0">
        <button nz-button nzType="primary" (click)="popupMessageCarousel.pre()">
          <ng-container i18n="@@Text_Btn_PrevItem">上一条</ng-container>
        </button>
      </ng-container>
      <ng-container *ngIf="popupMsgIndex < popupMessageList.length - 1">
        <button nz-button nzType="primary" (click)="popupMessageCarousel.next()">
          <ng-container i18n="@@Text_Btn_NextItem">下一条</ng-container>
        </button>
      </ng-container>
      <ng-container *ngIf="popupMsgIndex == popupMessageList.length - 1">
        <button nz-button nzType="primary" (click)="closePopupMessage()">
          <ng-container i18n="@@label_Confirm">确定</ng-container>
        </button>
      </ng-container>
    </div>
  </ng-template>
</nz-modal>

<nz-modal [nzVisible]="showIENotice" nzTitle="当前浏览器已过时或版本过低" i18n-nzTitle="@@Title_IENotice">
  <p i18n="@@Text_IENotice1">
    为了更好的用户体验和安全性，建议您使用<a href="https://www.microsoft.com/zh-cn/edge" class="active">新版Microsoft Edge浏览器</a
    >访问。
  </p>
  <p i18n="@@Text_IENotice2">如果您正在使用搜狗或360等国产浏览器，请在导航栏切换为极速模式。</p>
</nz-modal>

<nz-modal
  [nzVisible]="showAccountChangeNotice"
  [nzClosable]="false"
  [nzMaskClosable]="false"
  [nzFooter]="null"
  nzTitle="账号登录态有变化"
  i18n-nzTitle="@@Title_AccountChangeNotice">
  <p i18n="@@Text_AccountChangeNotice">检测到您账号登录态发生变化，您可能退出或登录其他账号，请刷新页面更新最新状态。</p>
  <div class="nd-text-right">
    <button nz-button nzType="primary" (click)="reloadPage()" i18n="@@Button_RefreshPage">刷新页面</button>
  </div>
</nz-modal>

<nd-feature-guide></nd-feature-guide>
