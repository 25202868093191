export enum LanguageType {
  zh_CN = 'zh-CN',
  en_US = 'en-US',
  ja_JP = 'ja-JP',
  _zh_CN = 'zh_CN',
  _en_US = 'en_US',
  _ja_JP = 'ja_JP',

  // test affected
}
