import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorCode = 500;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.params['code']) {
      this.errorCode = this.route.snapshot.params['code'];
    }

  }

}
