import { ProductInfoModel, ProductCategoryTypeEnum } from './product.models';
import { RedeemContactInfoModel } from './contactInfo.models';

export enum RedeemRecordStatusTypeEnum {
    Ordering = 0,
    PendingForConfirm = 1,
    Confirmed = 2,
    Failed = 3,
}
export class ProductRedeemOptions {
    AcceptAgreement: boolean;
    UserComments: string;
    ExpressPoints: number;
    Contact: RedeemContactInfoModel;
    Product: ProductInfoModel;
    ProductList: ProductInfoModel[];
    Quantity = 1;
    ActualCostPoints = 0;
    constructor() {
        this.Contact = new RedeemContactInfoModel();
        this.Product = new ProductInfoModel();
        this.ProductList = [];
    }

}
export class RedeemRecordInfoModel {
    UserName: string;
    RedeemTime: string;
    Phone: string;
}
export class RedeemRecordSearchOptions {
    RedeemRecordID?: number;
    UserID: string;
    ClientID: string;
    Statuses: RedeemRecordStatusTypeEnum[];
    PageSize?: number;
    PageNum?: number;
}
export class UserRedeemRecordModel {
    // Language: LanguageType;
    RedeemRecordID: number;
    UserID: string;
    ClientID: string;
    OperatorID: string;
    TransactionID: string;
    Contact: RedeemContactInfoModel;
    ProductID: number;
    ProductName: string;
    ProductNameTranslated: string;
    ProductDescriptionTranslated: string;
    ProductCategoryType?: ProductCategoryTypeEnum;
    ProductValue: number;
    RedeemCostValue: number;
    ProductImageSrc: string;
    ProductImageThumbSrc: string;
    RedeemTime: string;
    Status: RedeemRecordStatusTypeEnum;
    UserComments: string;
    AdminComments: string;
    ExpressNumber: string;
    Remarks: string;
    Quantity: number;
    LastUpdateTime: string;
}
